exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__horizontalContentCard___10kGs{display:grid;display:-ms-grid;position:relative;-ms-grid-row:1;-ms-grid-column:1}.styles__cardContainer___xolBv{display:inline-grid;display:-ms-inline-grid;grid-template-rows:1fr auto;-ms-grid-rows:1fr auto;-ms-grid-columns:auto}.styles__cardContainerFill___32IhK{width:100%;height:100%;-ms-grid-columns:1fr}", ""]);

// exports
exports.locals = {
	"horizontalContentCard": "styles__horizontalContentCard___10kGs",
	"cardContainer": "styles__cardContainer___xolBv",
	"cardContainerFill": "styles__cardContainerFill___32IhK"
};