import React from 'react';

import {useModal} from '@newsela/angelou';

const withAngelouModal = (WrappedComponent) => {
  return (props) => {
    const [modalProps, setActive, triggerRef] = useModal(false);

    return (
      <WrappedComponent
        modalProps={modalProps}
        setActive={setActive}
        triggerRef={triggerRef}
        {...props}
      />
    );
  };
};

export default withAngelouModal;
