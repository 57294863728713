import * as types from 'modulesV2/constants';

export const getLessonSparkRequest = (lessonSparkContentId) => ({
  type: types.GET_LESSON_SPARK_REQUEST,
  lessonSparkContentId
});

export const getLessonSparkSuccess = (data) => ({
  type: types.GET_LESSON_SPARK_SUCCESS,
  data
});

export const getLessonSparkFailure = (error) => ({
  type: types.GET_LESSON_SPARK_FAILURE,
  error
});
