import {uniq, concat} from 'lodash-es';

import * as types from './../constants/classroomActionTypes';

const initialState = {
  classrooms: {
    dateRequested: null,
    dateLoaded: null,
    isLoading: true,
    items: [],
    recentlyCreatedClassroom: null,
    joinedClassroom: null,
  }
};

function classrooms(state = initialState.classrooms, action) {
  switch (action.type) {
    case types.CLASSROOMS_LOAD_REQUEST:
    case types.CLASSROOM_LOAD_REQUEST:
      return {...state, dateRequested: Date.now(), isLoading: true};

    case types.CLASSROOMS_LOAD_SUCCESS:
      return {
        ...state,
        dateLoaded: Date.now(),
        isLoading: false,
        items: [...action.classrooms]
      };

    case types.CLASSROOM_LOAD_SUCCESS:
      return {
        ...state,
        items: uniq([...state.items, action.data], 'id'),
      };

    case types.CLASSROOMS_LOAD_FAILURE:
      return {...state, isLoading: false, error: action.error};

    case types.CLASSROOMS_UNLOAD:
      return initialState.classrooms;

    case types.CLASSROOM_POST_SUCCESS:
      return {
        ...state,
        items: concat(state.items, action.payload),

        // So that components can default to new classrooms.
        recentlyCreatedClassroom: action.payload,
      };

    case types.CLASSROOM_JOIN_SUCCESS:
      return {...state, joinedClassroom: action.payload.classroom_id};

    case types.CLASSROOM_JOIN_FAILURE:
      return {...state, joinedClassroom: false};
    default:
      return state;
  }
}

export default classrooms;
