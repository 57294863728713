import React from 'react';

import {Button} from '@newsela/angelou';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';


import AngelouProps from 'static/three-oh/src/propTypes/AngelouPropTypes';
import {ReactComponent as CloseIcon} from 'staticImages/icons/close-icon.svg';

import style, {choiceChip, filterChip, textStyleMax, disabledChip, selectedChip, textStyle, closeButtonStyle, buttonSurroundingClose} from './style';

export const ChipType = {
  choice: 'CHOICE',
  filter: 'FILTER',
};

const propTypes = {
  /**
   * As of right now, the __css property will only be passed to the choice chip if there is no href.
   */
  ...AngelouProps,

  /**
   * Text inside of chip
   */
  label: PropTypes.string.isRequired,

  /**
   * Type of chip. options are available in the ChipType enum above
   */
  type: PropTypes.oneOf(Object.values(ChipType)).isRequired,

  /**
   * Is the chip disabled
   */
  disabled: PropTypes.bool.isRequired,

  /**
   * Is the chip selected on reload
   */
  selected: PropTypes.bool,

  /**
   * OnClick event to be fired when the chip is clicked
   */
  onClick: PropTypes.func,

  /**
   * href for chip to link to
   */
  href: PropTypes.string,

  isTextLengthMax: PropTypes.bool,
  /**
   * Contains aria properties
   *  aria={{
      'aria-label': 'an aria label',
      'aria-announce: 'announce something'
    }}
   */
  aria: PropTypes.shape({
    'aria-label': PropTypes.string,
    'aria-announce': PropTypes.string,
    'aria-expanded': PropTypes.string,
    'aria-pressed': PropTypes.boolean,
  }),
  /**
   * Accessability prop that maps directly to html attribute
   * role
   */
  role: PropTypes.string,

  /**
   * Determines if it should render a Link or an a tag
   */
  renderAnchorTag: PropTypes.bool,

  /**
   * Applies different classes to different parts of the chip
   * takes the properties root, chipBackground, chipLabel, chipBtn, closeBtn
   */
  classes: PropTypes.shape({
    root: PropTypes.string,
    chipBackground: PropTypes.string,
    chipLabel: PropTypes.string,
    chipBtn: PropTypes.string,
    closeBtn: PropTypes.string,
  })
};

const Chip = React.forwardRef((props, ref) => {
  const {
    type,
    href,
    selected,
    label,
    disabled,
    onClick,
    aria,
    role,
    renderAnchorTag,
    classes,
    isTextLengthMax,
  } = props;

  const chipStyle = type === 'FILTER' ? filterChip : choiceChip;
  const disabledStyles = disabled ? disabledChip(type) : '';
  const selectedStyles = selected ? selectedChip(type) : '';
  const textMaxSet = isTextLengthMax ? textStyleMax : '';

  const buttonAria = aria || {};

  const renderLink = (renderAnchorTag) => {
    return renderAnchorTag
      ?
        <a
          className={`${style} ${chipStyle} ${textStyle} ${disabledStyles} ${selectedStyles} ${classes.chipBackground} ${classes.chipLabel}`}
          role={role}
          href={href}
          aria-disabled={disabled}
          {...aria}
          tabIndex={disabled ? '-1' : '0'}
          ref={ref}
        >
          {label}
        </a>
      :
        <Link
          to={href}
          className={`${style} ${chipStyle} ${textStyle} ${disabledStyles} ${selectedStyles} ${classes.chipBackground} ${classes.chipLabel}`}
          aria-disabled={disabled}
          {...aria}
          ref={ref}
        >
          {label}
        </Link>;
  };

  return (
    <div style={{display: 'inline-block'}} className={classes.root}>
      {(href || type === ChipType['filter']) ?
        <div>
          {href ? renderLink(renderAnchorTag)
            :
          <div
            className={`${style} ${chipStyle} ${textStyle} ${disabledStyles} ${selectedStyles} ${classes.chipBackground}`}
            role={role}
          >
            <label className={`${textStyle} ${classes.chipLabel} ${textMaxSet}`} {...aria}>{label}</label>
            {!disabled &&
              <Button
                /* AUTOGENERATED TODO: update angelou to new flavor.
                  see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
                  for migration guide. */
                legacy_flavor={Button.legacy_flavor.incognito}
                ariaProps={buttonAria}
                onClick={onClick} {...aria}
                __classNameFor={{
                  root: buttonSurroundingClose
                }}
                ref={ref}
              >
                <CloseIcon className={`${closeButtonStyle} ${classes.closeBtn}`}/>
              </Button>}
          </div>}
        </div>
        :
        <Button
          /* AUTOGENERATED TODO: update angelou to new flavor.
            see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
            for migration guide. */
          legacy_statusColor={selected ? Button.legacy_statusColor.secondary : Button.legacy_statusColor.primary}
          __cssFor={{
            root: [
              {borderRadius: '100px', border: '1px solid rgba(0, 82, 196, 0.15);'},
              selected ? {borderColor: 'rgba(0, 82, 196, 0.65'} : null
            ]
          }}
          __classNameFor={{
            root: `${chipStyle} ${selectedStyles} ${disabledStyles} ${textStyle} ${classes.chipBtn}`
          }}
          /* AUTOGENERATED TODO: update angelou to new flavor.
            see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
            for migration guide. */
          legacy_flavor={selected ? Button.legacy_flavor.solid : Button.legacy_flavor.flat}
          disabled={disabled}
          ariaProps={{...aria}}
          role={role}
          onClick={onClick}
          ref={ref}
        >
          {label}
        </Button>}
    </div>
  );
});

Chip.propTypes = propTypes;
Chip.type = ChipType;

Chip.defaultProps = {
  disabled: false,
  selected: false,
  renderAnchorTag: true,
  classes: {
    root: '',
    chipBackground: '',
    chipLabel: '',
    chipBtn: '',
    closeBtn: '',
  },
};

export default Chip;
