import APIFetcher from '../APIFetcher';

class ArticleHeaderApi {
  static getById(articleHeaderId, params = {}) {
    const queryParams = APIFetcher.stringifyParams(params);
    return APIFetcher.get(`articleheader/${articleHeaderId}/?${queryParams.toString()}`);
  }

  static getMultiple(ids = [], params = {include_article_text: false}) {
    if (ids.length === 0) {
      throw new Error('Should pass article header ids.');
    }
    const additionalParams = APIFetcher.stringifyParams(params);
    return APIFetcher.get(`articleheader/?ids=${ids.join(',')}&${additionalParams.toString()}`);
  }

  static getBySlug(articleHeaderSlug, params = {}) {
    const defaultParams = {
      include_quizzes: true,
    };
    params = {...defaultParams, ...params};
    const query = APIFetcher.stringifyParams(params);
    return APIFetcher.get(`articleheader/${articleHeaderSlug}/?${query}`);
  }
}

export default ArticleHeaderApi;
