export const TEXT_SET_LOAD_REQUEST = 'TEXT_SET_LOAD_REQUEST';
export const TEXT_SET_LOAD_SUCCESS = 'TEXT_SET_LOAD_SUCCESS';
export const TEXT_SET_LOAD_FAILURE = 'TEXT_SET_LOAD_FAILURE';

export const TEXT_SET_SAVE_REQUEST = 'TEXT_SET_SAVE_REQUEST';
export const TEXT_SET_SAVE_SUCCESS = 'TEXT_SET_SAVE_SUCCESS';
export const TEXT_SET_SAVE_FAILURE = 'TEXT_SET_SAVE_FAILURE';

export const ADD_HEADER_TO_TEXT_SET_REQUEST = 'ADD_HEADER_TO_TEXT_SET_REQUEST';
export const ADD_HEADER_TO_TEXT_SET_SUCCESS = 'ADD_HEADER_TO_TEXT_SET_SUCCESS';
export const REMOVE_HEADER_FROM_TEXT_SET_REQUEST = 'REMOVE_HEADER_FROM_TEXT_SET_REQUEST';
export const REMOVE_HEADER_FROM_TEXT_SET_SUCCESS = 'REMOVE_HEADER_FROM_TEXT_SET_SUCCESST';
