import {constants, typographyStyles} from '@newsela/angelou';
import {css, cx} from 'emotion';

import breakpoints from 'constants/BreakPoints';
import ScreenSize from 'constants/ScreenSize';

import infoIcon from './assets/fill-1.png';

const greyLight = constants.colors.ui.neutral[500];
const lightBlue = constants.colors.ui.core[100];
const darkBlue = constants.colors.ui.core[700];

const brickedOffDoor = `
  text-align: center;
`;

const contentWrapperUnauth = `
  display: flex;
  justify-content: center;
`;

const borderlessContainer = `
  width: 810px;
  margin-top: 50px;
  padding-left: 35px;
  padding-bottom: 30px;
  padding-right: 35px;
  @media (max-width: ${breakpoints[ScreenSize.xsmall]}px){
    width: 100%;
    margin-top: 2em;
  }
`;

const container = cx([css(borderlessContainer), css`
border: 2px solid ${lightBlue};
`]);

const borderlessErrorPanelContainer = cx([css(borderlessContainer), css`
  @media (max-width: ${breakpoints[ScreenSize.xsmall]}px){
    width: 100%;
    margin-top: 2em;
    margin-left: 3%;
    margin-right: 3%;
  }
`]);


const errorPanelContainer = cx([css(container), css`
  @media (max-width: ${breakpoints[ScreenSize.xsmall]}px){
    width: 100%;
    margin-top: 2em;
    margin-left: 3%;
    margin-right: 3%;
  }
`]);

const messageHeader = cx([css`
    &:before {
      display: inline-block;
      content: " ";
      background-image: url(${infoIcon});
      height: 23px;
      width: 23px;
      margin-right: 8px;
    }
  `,
css(typographyStyles.heading2Obj)
]);

const messageHeaderNoIcon = `
  ${typographyStyles.heading2Obj}
`;

const textWrapper = `
    width: 100%;
    padding-bottom: 10px;
`;

const messageBody = cx([css`
    color: ${greyLight};
  `,
css(typographyStyles.heading5Obj)
]);

const ctaWrapper = `
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
`;

export const ctaDesign = `
  ${typographyStyles.heading5Obj}
  font-weight: unset;
  text-decoration: none;
  margin-left: 25px;
  padding: 0;
  color: ${darkBlue};
  &:hover, &:focus {
    text-decoration: underline;
    color: ${darkBlue};
`;

export const brickedOffDoorStyle = css(brickedOffDoor);
export const borderlessErrorPanelContainerStyle = css(borderlessErrorPanelContainer);
export const errorPanelContainerStyle = css(errorPanelContainer);
export const containerStyle = css(container);
export const textWrapperStyle = css(textWrapper);
export const ctaWrapperStyle = css(ctaWrapper);
export const contentWrapperUnauthStyle = css(contentWrapperUnauth);
export const messageHeaderStyle = css(messageHeader);
export const messageHeaderNoIconStyle = css(messageHeaderNoIcon);
export const messageBodyStyle = css(messageBody);
export const ctaStyle = css(ctaDesign);
