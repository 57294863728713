import {
  get,
  forEach,
  max,
  cloneDeep,
  uniqBy
} from 'lodash-es';
import {createSelector} from 'reselect';

import {classroomSelectors} from 'static/three-oh/src/modules/selectors';
import 'array.prototype.flat';
import {getAllAssignmentGroupStudents, hasAssignmentGroups} from 'static/three-oh/src/modules/utils/assignmentHelpers';

const getArticleHeaderClassrooms = (state) => get(state, 'binderWork.articleHeaderClassrooms.items', null);

const getNotifications = (state) => get(state, 'binderWork.notifications.items', null);

const deepLinkWasHandled = (state) => {
  return get(state, 'binderWork.deepLinkWasHandled', false);
};

const articleHeaderClassroomsWorkIsLoading = (state) => {
  return get(state, 'binderWork.articleHeaderClassrooms.isLoading', true) ||
         get(state, 'binderWork.headerReportingData.isLoading', true);
};

const assignmentsWorkIsLoading = (state) => {
  return get(state, 'binderWork.assignments.isLoading', true) ||
         get(state, 'binderWork.headerReportingData.isLoading', true);
};

const getNotificationMaxDateCreatedByHeader = createSelector(
  getNotifications,
  (notifications) => {
    const mostRecentByHeader = {};
    forEach(notifications, (n) => {
      const headerId = get(n, 'data.header.id');
      if (headerId) {
        mostRecentByHeader[headerId] = max([mostRecentByHeader[headerId], n.date_created]);
      }
    });
    return mostRecentByHeader;
  }
);

/**
 * Stitch together Assignment and Reporting API responses into a single array of
 * objects that the AssignmentsTab component will consume. Each object of work in the array
 * is keyed by "AssignmentId".
 *
 *    [{
 *      "key": "2813088",
 *      "assignment": {
 *        "id": 2306160,
 *        "date_assigned": "2015-10-27T18:27:26Z",
 *        "instructions": "hi",
 *        "studentCount": 4,
 *        "classrooms": [
            {
 *             "grade": 8,
 *             "name": "Horton Math",
 *             ....
 *           },
 *           {
 *             "grade": 6,
 *             "id": 2998033,
 *             "name": "Humanities",
 *             ....
 *           },
 *          ],
 *        "headers": [
 *          {
 *            "id": 2616,
 *            "slug": "michelleobama-health",
 *          ...
 *          },
 *          {
 *            "id": 26165,
 *            "slug": "blackpanther-superhero",
 *          ...
 *          },
 *        ]
 *        ...
 *      },
 *      "reportRow": {
 *        "avg:grade_level": 6.777777777777778,
 *        "count:header_views": 1,
 *        "avg:quiz_score": 50,
 *        "avg:write_response_score": null,
 *        "count:annotations": 4,
 *      }
 *    }, ...]
 */
const getWorkViewModelForAssignments = createSelector(

  (state) => classroomSelectors.getStudentsByClassroom(state),
  (state, assignmentsApiCallState) => assignmentsApiCallState?.data,

  (studentsGroupedByClassroom, assignments) => {
    const workViewModel = [];
    forEach(assignments, (assignment) => {
      const assignmentClassrooms = get(assignment, 'assignment_classrooms');
      const assignmentGroups = get(assignment, 'assignment_groups');
      const isSmallGroupAssignment = hasAssignmentGroups(assignmentGroups);

      const model = {
        key: assignment.content_id,
        assignment: cloneDeep(assignment), // Cloned so we can add studentCount without mutating state.
      };

      // Include the student count on the entire assignment.
      let uniqueStudents = [];
      if (isSmallGroupAssignment) {
        const sgaStudents = getAllAssignmentGroupStudents(assignmentGroups);
        uniqueStudents = uniqBy(sgaStudents, 'unique_id');
      } else {
        const classroomStudents = assignmentClassrooms.reduce((students, classroom) => {
          students.push(get(studentsGroupedByClassroom, classroom.classroom.id));

          return students;
        }, []);
        uniqueStudents = uniqBy(classroomStudents.flat(), 'id');
      }

      model.assignment.studentCount = uniqueStudents.length;

      // Only include student view records for students currently assigned (SGA or class)
      const uniqueStudentIds = uniqueStudents.map((student) => student?.unique_id);
      const studentViewRecords = model.assignment?.student_assignment_view_records?.filter(
        (record) => uniqueStudentIds.includes(record.student?.user_id)
      );
      model.assignment.studentViewRecords = studentViewRecords;

      workViewModel.push(model);
    });

    return workViewModel;
  }
);

export {
  deepLinkWasHandled,
  getArticleHeaderClassrooms,
  getNotificationMaxDateCreatedByHeader,
  getWorkViewModelForAssignments,
  articleHeaderClassroomsWorkIsLoading,
  assignmentsWorkIsLoading,
};
