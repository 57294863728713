export const CONTENT_ROW_REQUEST = 'CONTENT_ROW_REQUEST';
export const CONTENT_ROW_LOAD_SUCCESS = 'CONTENT_ROW_LOAD_SUCCESS';
export const CONTENT_ROW_LOAD_FAILURE = 'CONTENT_ROW_LOAD_FAILURE';

export const USER_CONTENT_ROW_PERSONALIZATIONS_REQUEST = 'USER_CONTENT_ROW_PERSONALIZATIONS_REQUEST';
export const USER_CONTENT_ROW_PERSONALIZATIONS_LOAD_SUCCESS = 'USER_CONTENT_ROW_PERSONALIZATIONS_LOAD_SUCCESS';
export const USER_CONTENT_ROW_PERSONALIZATIONS_LOAD_FAILURE = 'USER_CONTENT_ROW_PERSONALIZATIONS_LOAD_FAILURE';

export const USER_CONTENT_ROW_PERSONALIZATION_POST = 'USER_CONTENT_ROW_PERSONALIZATION_POST';
export const USER_CONTENT_ROW_PERSONALIZATION_POST_SUCCESS = 'USER_CONTENT_ROW_PERSONALIZATION_POST_SUCCESS';
