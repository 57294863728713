import {
  sortBy,
  uniqBy,
  filter,
  groupBy,
  meanBy,
  round,
  map,
  sumBy,
  sum,
  minBy,
  maxBy,
  get,
  first,
  find
} from 'lodash-es';
import moment from 'moment';

import {formatReadingTime} from 'static/three-oh/src/modules/utils/time';

/*
These functions compute various calculations over lists of notifications.
They are used on the Assignment pages and the Read page.
*/

const median = (array) => {
  // A helper function to calculate medians.
  const values = sortBy(array);
  if (values.length === 0) return null;

  const half = Math.floor(values.length / 2);
  if (values.length % 2) {
    return values[half];
  } else {
    return (values[half - 1] + values[half]) / 2.0;
  }
};

export const getStudentViews = (notifications) => {
  // Count the distinct students who have viewed the article.
  return uniqBy(
    filter(notifications, {type: 'reading-session'}),
    'data.user.unique_id'
  ).length;
};

export const getArticleHeaderViews = (notifications) => {
  // Count the distinct article headers that a student has viewed.
  const notificationsGroupedByHeader = groupBy(notifications, 'data.header.id');
  const views = Object.keys(notificationsGroupedByHeader).length;
  return views === 0 ? 'No data' : views;
};

export const getAverageQuizScore = (notifications) => {
  // Average the quiz scores across all quizzes.
  const value = meanBy(
    filter(notifications, {type: 'student-quiz-submission'}),
    'data.quiz.score'
  );
  if (!isNaN(value)) {
    return round(value) + '%';
  } else {
    return value;
  }
};

export const getStudentsWithQuizzes = (notifications) => {
  // Count distinct students who have completed a quiz.
  return uniqBy(
    filter(notifications, {type: 'student-quiz-submission'}),
    'data.user.unique_id'
  ).length || 'No data';
};

export const getArticlesWithQuizzes = (notifications) => {
  // Count the distinct articles the student has completed a quiz activity
  // for.
  const quizNotifications = filter(notifications, {type: 'student-quiz-submission'});
  const notificationsGroupedByArticle = groupBy(quizNotifications, 'data.article.id');
  const articlesWithQuizzes = Object.keys(notificationsGroupedByArticle).length;
  return articlesWithQuizzes === 0 ? 'No data' : articlesWithQuizzes;
};

export const getArticlesWithWriteResponses = (notifications) => {
  // Count the distinct articles the student has completed a write activity
  // for.
  const writeNotifications = filter(notifications, {type: 'student-write-response'});
  const notificationsGroupedByArticle = groupBy(writeNotifications, 'data.article.id');
  const articlesWithWriteActivity = Object.keys(notificationsGroupedByArticle).length;

  return articlesWithWriteActivity === 0 ? 'No data' : articlesWithWriteActivity;
};

export const getArticlesWithAnnotations = (notifications) => {
  // Count the distinct articles the student has annotated.
  const annotationNotifications = filter(notifications, {type: 'student-annotation'});
  const notificationsGroupedByArticle = groupBy(annotationNotifications, 'data.article.id');
  const articlesWithAnnotations = Object.keys(notificationsGroupedByArticle).length;
  return articlesWithAnnotations === 0 ? 'No data' : articlesWithAnnotations;
};

export const getArticlesWithPowerWords = (notifications) => {
  // Count the distinct articles the student has completed a vocab activity for.
  const vocabNotifications = filter(notifications, {type: 'student-vocabulary-completion'});
  const notificationsGroupedByArticle = groupBy(vocabNotifications, 'data.article.id');
  const articlesWithVocab = Object.keys(notificationsGroupedByArticle).length;
  return articlesWithVocab === 0 ? 'No data' : articlesWithVocab;
};

export const getStudentsWithWriteResponses = (notifications) => {
  // Count distinct students who have completed a write response.
  return uniqBy(
    filter(notifications, {type: 'student-write-response'}),
    'data.user.unique_id'
  ).length || 'No data';
};

export const getStudentsWithAnnotations = (notifications) => {
  // Count distinct students who have completed an annotation.
  return uniqBy(
    filter(notifications, {type: 'student-annotation'}),
    'data.user.unique_id'
  ).length || 'No data';
};

export const getAveragePowerWordsScore = (notifications) => {
  // Average the scores across all vocab activities.
  const value = meanBy(
    filter(notifications, {type: 'student-vocabulary-completion'}),
    (n) => n.data.activity.correct_answer_count / n.data.activity.answer_count
  );
  if (!isNaN(value)) {
    return round(value * 100.0) + '%';
  } else {
    return value;
  }
};

export const getStudentsWithPowerWords = (notifications) => {
  // Count distinct students who have completed a vocabulary activity.
  return uniqBy(
    filter(notifications, {type: 'student-vocabulary-completion'}),
    'data.user.unique_id'
  ).length || 'No data';
};

export const getMedianArticleLevel = (notifications) => {
  // Compute the median article level for all notifications received about
  // activity.
  return median(map(notifications, 'data.article.grade_level')) || 'No data';
};

const readingTimes = (notifications) => {
  // An internal helper function that returns a list of reading times. Each
  // data point is the sum of all sessions for one student reading one article.
  // First, select out the Reading Session notifications.
  const sessionNotifications = filter(
    notifications,
    {type: 'reading-session'}
  );

  // Group the notifications by student-article so we can get
  // the total reading time for each student on each article. Note that
  // Reading Sessions are keyed on Users, not Students.
  const notificationsByStudentArticle = groupBy(
    sessionNotifications,
    (n) => n.data.user.unique_id + ',' + n.data.article.id
  );

  // Sum the reading time for each group of notifications.
  return map(
    notificationsByStudentArticle,
    (group) => {
      // Sum and round to the nearest minute.
      const total = sumBy(group, (n) => n.data.time_in_session);
      return Math.round(total / 60) * 60;
    }
  );
};

export const getTotalReadingTime = (notifications) => {
  // Compute the total reading time for all notifications received about
  // Reading Sessions. There may be multiple reading sesssions for articles.
  const times = readingTimes(notifications);
  if (!times.length) {
    return 'No data';
  } else {
    return formatReadingTime(sum(times));
  }
};

export const getMedianReadingTime = (notifications) => {
  // Compute the median reading time for all notifications received about
  // Reading Sessions. There may be multiple reading sesssions for articles.
  const times = readingTimes(notifications);
  if (!times.length) {
    return 'No data';
  } else {
    return formatReadingTime(median(times));
  }
};

export const getArticleViewDate = (notifications) => {
  // Get the earliest article view date.
  const firstActivity = minBy(notifications, 'date_created');
  const date = get(firstActivity, 'date_created');
  const today = moment().format('MM/DD/YYYY');
  const yesterday = moment().subtract(1, 'days').format('MM/DD/YYYY');

  if (moment(date).format('MM/DD/YYYY') === today) {
    return 'Today';
  } else if (moment(date).format('MM/DD/YYYY') === yesterday) {
    return 'Yesterday';
  } else {
    return moment(date).format('MM/DD/YYYY');
  }
};

export const getArticleLevel = (notifications, missingLevel = '-') => {
  // Get the article level.
  const article = first(notifications).data.article;
  const levelTitle = article.is_original ? 'MAX' : article.grade_level;
  return levelTitle || missingLevel;
};

export const getQuizScore = (notifications, missingScore = '-') => {
  // Get the student's quiz score.
  const quizScore = get(
    find(notifications, {type: 'student-quiz-submission'}), 'data.quiz.score'
  );

  if (isNaN(quizScore)) {
    return missingScore;
  } else {
    return round(quizScore) + '%';
  }
};

export const getWriteScore = (notifications, assignmentId, missingScore = '-') => {
  // Get the student's write activity score.
  let writeNotification;

  const writeNotifications = filter(notifications, {type: 'student-write-response'});

  if (writeNotifications.length) {
    if (writeNotifications.length > 1) {
      const scoredAndReviewedAnswer = writeNotifications.find((notification) => notification.data.response?.review && notification.data.response?.review.score !== null);

      if (scoredAndReviewedAnswer) {
        writeNotification = scoredAndReviewedAnswer;
      } else {
        const notReviewedAnswer = writeNotifications.filter((notification) => !notification.data.response?.review);
        if (notReviewedAnswer) {
          writeNotification = maxBy(notReviewedAnswer, 'date_created');
        }
      }

      if (!writeNotification) {
        writeNotification = maxBy(writeNotifications, 'date_created');
      }
    }

    if (writeNotifications.length === 1) {
      writeNotification = writeNotifications[0];
    }
  }

  if (!writeNotification) {
    return missingScore;
  } else {
    const writeReview = get(writeNotification.data.response, 'review');
    if (!writeReview) {
      return 'Score Now';
    } else {
      const score = get(writeReview, 'score');
      return score !== null ? `${score}` : 'Student Revising';
    }
  }
};

export const getNumberOfStudentAnnotations = (notifications, missingAnnotations = '-') => {
  // Get the number of annotations a student has completed.
  const numberOfAnnotations = (
    filter(notifications, {type: 'student-annotation'}).length
  );

  // Return the values as string literals so that the column is sortable.
  return numberOfAnnotations === 0 ? missingAnnotations : `${numberOfAnnotations}`;
};

export const getPowerWordsScore = (notifications, missingScore = '-') => {
  // Get the student's power words score.
  const powerWordsActivity = find(notifications, {type: 'student-vocabulary-completion'});

  if (!powerWordsActivity) {
    return missingScore;
  } else {
    const questions = powerWordsActivity.data.activity.answer_count;
    const questionsCorrect = powerWordsActivity.data.activity.correct_answer_count;
    return `${round(questionsCorrect / questions * 100)}%`;
  }
};
