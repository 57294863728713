import * as types from './../constants/articleActionTypes';

export function requestArticles(articleId) {
  return {type: types.ARTICLE_LOAD_REQUEST, articleId};
}

export function requestArticlesSuccess(articles) {
  return {type: types.ARTICLE_LOAD_SUCCESS, articles};
}

export function requestArticlesFailure(error) {
  return {type: types.ARTICLE_LOAD_FAILURE, error};
}
