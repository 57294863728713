import APIFetcher from '../APIFetcher';

class SessionApi {
  static signIn(credentials) {
    return APIFetcher.post('session/', {payload: credentials});
  }

  static signOut() {
    return APIFetcher.remove('session/');
  }

  static verifyCredentials(credentials) {
    return APIFetcher.post('session/verify-credentials/', {payload: credentials});
  }

  static refreshSession() {
    return APIFetcher.get('session/refresh');
  }
}

export default SessionApi;
