import React from 'react';

import {get, includes, isEmpty} from 'lodash-es';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Route} from 'react-router-dom';
import {bindActionCreators} from 'redux';

import {studiesActions} from 'static/three-oh/src/modules/actions';
import {studiesSelectors} from 'static/three-oh/src/modules/selectors';
import {isNonProdEnvironment} from 'utils/environment';

import NoRouteInStudyError from './NoRouteInStudyError';

const propTypes = {
  actions: PropTypes.shape({
    requestStudies: PropTypes.func,
  }),
  awaitUserStudies: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  default: PropTypes.bool,
  location: PropTypes.object,
  slug: PropTypes.string.isRequired,
  studies: PropTypes.object,
  studiesAreLoading: PropTypes.bool,
  style: PropTypes.object
};

const defaultProps = {
  awaitUserStudies: true,
};

/**
* The Study component accepts a study name as a prop and checks
* which segment of that study the current user belongs to.
* It expects to have Segment components as child elements.
* The child segment with a prop value that matches the
* user's segment will be rendered.
* If there is no matching segment then the child marked with
* the optional prop 'default' will be rendered.

* @example
* <Study slug={"my-study"}>
*   <Segment value={"A"}>
*     <h1>Component for Segment A</h1>
*   </Segment>
*   <Segment value={"B"}>
*     <h1>Component for Segment B</h1>
*   </Segment>
*   <Segment default={true} value={"C"}>
*     <h1>Component for Segment C</h1>
*   </Segment>
* </Study>

* You can also pass an array to segment's value. This is useful if the
* component you're rendering has multiple studies inside it
* @example
* <Study slug={"my-study"}>
*   <Segment value={["A", "B"]}>
*     <h1>Component that has segments for A and B</h1>
*   </Segment>
*   <Segment default={true} value={"C"}>
*     <h1>Component for Segment C</h1>
*   </Segment>
* </Study>

*/
class StudyComponent extends React.Component {
  componentDidMount() {
    this.props.actions.requestStudies();
  }

  extractStudySegmentFromLocation = () => {
    const {slug} = this.props;
    const parsedQueryString = new URLSearchParams(window.location.search);
    const studyParameter = `ss_${slug}`;
    return parsedQueryString.get(studyParameter);
  }

  getUserSegment() {
    const segment = get(this.props.studies, this.props.slug, {value: 'null'});
    return segment.value;
  }

  determineChild() {
    const segmentOverride = this.extractStudySegmentFromLocation();
    const userSegment = segmentOverride || this.getUserSegment();
    let inSegmentChild = this.props.children.find((element) => {
      if (typeof element.props.value === 'string') {
        return element.props.value === userSegment;
      } else {
        return includes(element.props.value, userSegment);
      }
    });

    // If none of the segments match the user, display the default child.
    if (inSegmentChild === undefined) {
      inSegmentChild = this.props.children.find((element) => {
        return element.props.default === true;
      });
    }

    // The study framework doesn't work with react-router's Route component, so if
    // any of the Segment's children are of type Route, return an error message
    // to the developer.
    if (inSegmentChild && isNonProdEnvironment()) {
      let children = inSegmentChild.props.children;
      if (!Array.isArray(children)) {
        children = [children];
      }
      if (children.some((child) => child.type === Route)) {
        return <NoRouteInStudyError />;
      }
    }

    return inSegmentChild;
  }

  render() {
    if (
      this.props.awaitUserStudies && isEmpty(this.props.studies) && this.props.studiesAreLoading
    ) {
      // Don't render any segment yet; wait until we know which segment the user belongs to.
      return null;
    }
    return (
      <div style={this.props.style}>
        {this.determineChild()}
      </div>
    );
  }
}

StudyComponent.propTypes = propTypes;
StudyComponent.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
    studies: studiesSelectors.userStudies(state),
    studiesAreLoading: studiesSelectors.studiesAreLoading(state),
  };
};

const actions = {
  ...studiesActions,
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

const Study = connect(mapStateToProps, mapDispatchToProps)(StudyComponent);
export default Study;
