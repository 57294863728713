import {constants} from '@newsela/angelou';
import {css} from 'emotion';

import {
  NAVBAR_BREAKPOINTS
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';

export const expandedSuggestionsPopoutVisibilityCss = css({
  display: 'none',
  flexDirection: 'column',
  background: constants.colors.ui.white[50],
  borderRadius: '0.5rem',
  boxShadow: '0px 2px 6px 0px rgba(29, 29, 29, 0.16)',
  padding: '1rem 0 1.25rem 0',

  [`@media (min-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
    display: 'flex',
  },
});

export const condensedSuggestionsPopoutVisibilityCss = css({
  display: 'flex',
  flexDirection: 'column',
  background: constants.colors.ui.white[50],
  borderRadius: '0 0 0.5rem 0.5rem',
  boxShadow: '0px 2px 6px 0px rgba(29, 29, 29, 0.16)',
  padding: '1rem 0',

  [`@media (min-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
    display: 'none',
  },
});

export const flexColumnCss = css({
  display: 'flex',
  flexDirection: 'column',
});

export const flexRowCss = css({
  display: 'flex',
  flexDirection: 'row',
  textWrap: 'wrap',
});
