import {STORE_BINDER_IMPERSONATED_USER} from '../constants/binderActionTypes';

function binderImpersonatedUserReducer(state = null, action) {
  switch (action.type) {
    case STORE_BINDER_IMPERSONATED_USER:
      return {...action.payload};

    default:
      return state;
  }
}

export default binderImpersonatedUserReducer;
