import {useSelector} from 'react-redux';

import {NAVBAR_BREAKPOINTS} from './constants';
import {getUserProperties} from '../../../../../modules/selectors/userSelectors';

export const MIN_LICENSE_WIDTH = 90;

export const useGetProductsVisibilityBreakpoint = () => {
  const {
    isStaff,
    hasFeature,
    activeELALicense,
    activeSSLicense,
    activeScienceLicense,
    activeEssentialsLicense,
  } = useSelector((state) => getUserProperties(state));
  const canViewAdminConsole = hasFeature('can_view_admin_console');

  const canSeeAdminMenu = canViewAdminConsole || isStaff;
  const hiddenMenus = [
    activeELALicense,
    activeEssentialsLicense,
    activeScienceLicense,
    activeSSLicense,
    canSeeAdminMenu
  ].filter((menu) => !menu).length;
  return NAVBAR_BREAKPOINTS.lg - (hiddenMenus * MIN_LICENSE_WIDTH);
};
