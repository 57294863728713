import React, {useState, useMemo} from 'react';

import {Button, CloseSVG, Icon, DottedMenuSVG} from '@newsela/angelou';
import {useSelector} from 'react-redux';

import {
  menuButtonCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/AppSwitcherMenu/AppSwitcherMenu.styles';
import {
  AppSwitcherPopout
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/AppSwitcherMenu/AppSwitcherPopout';
import {
  NavButtonWithPopout
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/NavButtonWithPopout';

import {getUserProperties} from '../../../../../modules/selectors/userSelectors';
import {getNavMenuButtonAriaProps} from '../shared/NavButtonWithPopout/NavButtonWithPopout.utils';

const MENU_ID = 'global-nav-app-switcher';

const AppSwitcherMenu = () => {
  const {
    canSeeFormative,
    canSeeEverwrite,
  } = useSelector((state) => getUserProperties(state));

  const [isOpen, setIsOpen] = useState(false);

  const buttonAriaProps = useMemo(
    () => getNavMenuButtonAriaProps({
      isOpen,
      id: MENU_ID,
      ariaLabel: 'App Switcher Menu'
    }), [isOpen]);

  return (
    <NavButtonWithPopout
      id={MENU_ID}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      position={{left: 0, top: '3.8rem'}}
      ButtonComponent={(
        <Button
          __cssFor={menuButtonCss}
          ariaProps={buttonAriaProps}
          flavor={Button.flavors.incognito}
          disabled={!canSeeFormative && !canSeeEverwrite}
          onClick={() => setIsOpen((isOpen) => !isOpen)}
          dataProps={{'data-qa-selector': 'global-nav-app-switcher', tabIndex: 0}}
        >
          {isOpen ? <Icon SvgComponent={CloseSVG} /> : <Icon SvgComponent={DottedMenuSVG} />}
        </Button>
      )}
      PopoutComponent={<AppSwitcherPopout setIsOpen={setIsOpen} />}
    />
  );
};

AppSwitcherMenu.displayName = 'AppSwitcherMenu';
export {AppSwitcherMenu};
