import React from 'react';

import {Anchor} from '@newsela/angelou';

const SIGN_IN = 'Sign in';
const JOIN = 'Join';

const loginRequired = {
  messageHeader: 'Account required',
  messageBody: 'Log in to your Newsela account (or sign up for free) to access reading content, videos, and more.',
  cta: '/signin',
  ctaText: 'Log in',
  ctaSecondary: '/join',
  ctaSecondaryText: 'Sign up',
  shouldUseNextUrl: true,
};

const permissionRequired = {
  messageHeader: 'This content requires a special license.',
  messageBody: 'Please sign in or create an account to see if you have access to this content.',
  cta: '/signin',
  ctaText: SIGN_IN,
  ctaSecondary: '/join',
  ctaSecondaryText: JOIN,
  shouldUseNextUrl: true,
};

const permissionRequiredForStudent = {
  messageHeader: 'Are you on the right page?',
  messageBody: <>We're sorry! This article needs a teacher's permission. You can have your teacher read this <Anchor isInline href="https://support.newsela.com/article/elementary-classes-content/#maturity-level">article</Anchor> to learn more.</>,
  cta: '/',
  ctaText: 'Visit homepage',
};

const contentNotFoundMessage = {
  messageHeader: 'This page doesn’t exist',
  messageBody: 'We’re sorry to interrupt your learning. You can go back and try something else — or start fresh from the homepage.',
  cta: '/',
  ctaText: 'Visit homepage',
};

const permissionRequiredForTeacher = {
  messageHeader: 'Content not available',
  messageBody: "Visit the homepage to view recommended content. If you believe you're here in error, contact your Newsela representative for support.",
  cta: '/',
  ctaText: 'Visit homepage',
  ctaSecondary: '/about/solutions/',
  ctaSecondaryText: 'Learn more',
};

const permissionRequiredForStudentAssignment = {
  messageHeader: 'Are you in the right spot?',
  messageBody: 'You’re not in the class this content was assigned to. Ask a teacher for help or visit your homepage to find your assignments.',
  cta: '/',
  ctaText: 'Visit homepage',
};

const permissionRequiredForTeacherAssignment = {
  messageHeader: 'Permission required.',
  messageBody: 'View your Binder to see your assignments.',
  cta: '/binder',
  ctaText: 'Visit Binder',
};

const hiddenContentErrorMessage = {
  messageHeader: '',
  messageBody: 'Due to state legislation, this content has been hidden from use in your district',
  cta: '/',
  ctaText: 'Visit homepage',
};

const hiddenContentErrorMessageStudent = {
  messageHeader: '',
  messageBody: "This content isn't available. Ask a teacher for help or visit the homepage to find approved content.",
  cta: '/',
  ctaText: 'Visit homepage',
};

const defaultErrorMessage = {
  messageHeader: 'Something went wrong',
  messageBody: 'Please go back and try again. Contact Customer Support if the problem continues.',
  cta: 'https://support.newsela.com/contact/',
  ctaText: 'Contact Customer Support',
  shouldUseNextUrl: false,
};

export {
  JOIN,
  SIGN_IN,
  loginRequired,
  permissionRequired,
  permissionRequiredForStudent,
  permissionRequiredForTeacher,
  permissionRequiredForStudentAssignment,
  permissionRequiredForTeacherAssignment,
  hiddenContentErrorMessage,
  hiddenContentErrorMessageStudent,
  defaultErrorMessage,
  contentNotFoundMessage,
};
