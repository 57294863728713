import * as types from './../constants/articleWordActionTypes';

// Action creators that dispatch actions to fetch the article words
// of one header.
export function requestArticleWordsByHeaderId(headerId) {
  return {
    headerId,
    type: types.FETCH_ARTICLE_WORDS_BY_HEADER_ID_LOAD_REQUEST,
    requestedAt: Date.now(),
  };
}

export function requestArticleWordsByHeaderIdSuccess(data, headerId) {
  return {
    headerId,
    type: types.FETCH_ARTICLE_WORDS_BY_HEADER_ID_LOAD_SUCCESS,
    articleWords: data,
    receivedAt: Date.now()
  };
}

export function requestArticleWordsFailure(error, headerId) {
  return {
    error,
    headerId,
    type: types.FETCH_ARTICLE_WORDS_BY_HEADER_ID_LOAD_FAILURE,
    articleWords: [],
    receivedAt: Date.now(),
  };
}

// Action creators that dispatch actions to fetch the article words
// of multiple headers all at once.

export function requestArticleWordsByHeaderIds(headerIds) {
  return {
    headerIds,
    type: types.FETCH_ARTICLE_WORDS_BY_HEADER_IDS_LOAD_REQUEST,
    requestedAt: Date.now(),
  };
}

export function requestArticleWordsByHeaderIdsSuccess(data, headerIds) {
  return {
    headerIds,
    type: types.FETCH_ARTICLE_WORDS_BY_HEADER_IDS_LOAD_SUCCESS,
    articleWords: data,
    receivedAt: Date.now()
  };
}

export function requestArticleWordsByHeaderIdsFailure(error, headerIds) {
  return {
    error,
    headerIds,
    type: types.FETCH_ARTICLE_WORDS_BY_HEADER_IDS_LOAD_FAILURE,
    articleWords: [],
    receivedAt: Date.now(),
  };
}
