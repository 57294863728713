import React from 'react';

import {Button, ChevronLeftSVG, Icon} from '@newsela/angelou';
import PropTypes from 'prop-types';

import {
  backToMenuAnchorCss,
  backToMenuIconCss,
  homeMenuSubItemPopoutContainerCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/HomeMenu/HomeMenu.styles';
import {
  PopoutMenuSection
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/PopoutMenuSection';
import {
  PopoutSectionItem
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/PopoutSectionItem';

import {useGetReportsMenuSectionProps} from '../../shared/hooks/useGetReportsMenuSectionProps';

const propTypes = {
  onClickBack: PropTypes.func.isRequired,
};

const HomeMenuReportsPopout = ({onClickBack}) => {
  const {
    reportsMenuSectionTitle,
    reportsMenuSectionItems,
  } = useGetReportsMenuSectionProps();

  return (
    <div className={homeMenuSubItemPopoutContainerCss} data-testid="home-classes-popout">
      <Button onClick={onClickBack} __cssFor={backToMenuAnchorCss} flavor={Button.flavors.incognito}>
        <Icon SvgComponent={ChevronLeftSVG} __cssFor={backToMenuIconCss} />
        Back to menu
      </Button>

      <PopoutMenuSection width="15.625rem" title={reportsMenuSectionTitle}>
        {reportsMenuSectionItems.map(({title, href, SvgComponent, dataQASelector}, index) => (
          <PopoutSectionItem key={index} href={href} title={title} SvgComponent={SvgComponent} dataQASelector={dataQASelector} />
        ))}
      </PopoutMenuSection>
    </div>
  );
};

HomeMenuReportsPopout.propTypes = propTypes;
HomeMenuReportsPopout.displayName = 'HomeMenuReportsPopout';
export {HomeMenuReportsPopout};
