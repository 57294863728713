import {css} from 'emotion';

const pillLevel = (backgroundColor, textColor) => {
  const pillStyles = `
    color: ${textColor};
    border-radius: 12px;
    display: inline-block;
    padding: 2px 6px;
    font-family: circular;
    font-size: 14px;
    background-color: ${backgroundColor};
  `;

  return css`
    &{${pillStyles}}
  `;
};

const pillLevelCss = (color, textColor) => {
  return pillLevel(color, textColor);
};

export {
  pillLevel,
  pillLevelCss,
};
