import {takeLatest, call, put} from 'redux-saga/effects';

import {ArticleHeaderClassroomApi} from 'static/three-oh/src/modules/apis';

import * as actions from './../actions/articleHeaderClassroomActions';
import * as types from './../constants/articleHeaderClassroomActionTypes';

function * getArticleHeaderClassrooms(action) {
  try {
    const data = yield call(ArticleHeaderClassroomApi.get, action.queryParams);
    yield put(actions.loadArticleHeaderClassroomsSuccess(data));
  } catch (error) {
    yield put(actions.loadArticleHeaderClassroomsFailure(error));
  }
}

export function * watchGetArticleHeaderClassrooms() {
  yield takeLatest(types.ARTICLE_HEADER_CLASSROOMS_LOAD_REQUEST, getArticleHeaderClassrooms);
}

