import React from 'react';

import {LoadingSpinner} from '@newsela/angelou';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';

import {readRoot} from 'static/three-oh/src/modules/constants/routePaths';

export const READ_PATH = `${readRoot}/:slug/(id)?/:articleId(\\d+)?/:activityType?/:activityId(\\d+)?/(question)?/:activityIndex?`;

const LazyReadContainer = Loadable({
  loader: () => import(
    /* webpackChunkName: "ReadContainer" */
    './containers/ReadContainer/ReadContainer'
  ),
  delay: 1000,
  // eslint-disable-next-line
  loading: (props) => (props.pastDelay ? <LoadingSpinner /> : null)
});

const ReadRoute = (
  <Route
    key="ReadArticle"
    path={READ_PATH}
    component={LazyReadContainer}
  />
);

export default ReadRoute;
