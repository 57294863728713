export const getAuxImage = (content_id) => {
  const lastChar = content_id.slice(content_id.length - 1);
  const imageMap = {
    a: 'https://media.newsela.com/article_media/extra/Balloon_Girl_Skintone_2_-_Yellow-min.png',
    b: 'https://media.newsela.com/article_media/extra/Girl_with_lightbulb-Skintone_1-Orange-min.png',
    c: 'https://media.newsela.com/article_media/extra/Girl_with_lightbulb-Skintone_1-blue-min.png',
    d: 'https://media.newsela.com/article_media/extra/Balloon_Girl_Skintone_2_-_Purple-min.png',
    e: 'https://media.newsela.com/article_media/extra/Balloon_Girl_Skintone_1_-_Blue2x-min.png',
    f: 'https://media.newsela.com/article_media/extra/Blue-Lightbulb-Globe-min.png',
    g: 'https://media.newsela.com/article_media/extra/Balloon_Girl_Skintone_2_-_Yellow-min.png',
    h: 'https://media.newsela.com/article_media/extra/blue-paper-illustration-min.png',
    i: 'https://media.newsela.com/article_media/extra/Balloon_Girl_Skintone_2_-_Purple-min.png',
    j: 'https://media.newsela.com/article_media/extra/Girl_with_lightbulb-Skintone_1-Pink-min.png',
    k: 'https://media.newsela.com/article_media/extra/blue-paper-illustration-min.png',
    l: 'https://media.newsela.com/article_media/extra/Balloon_Girl_Skintone_2_-_Purple-min.png',
    m: 'https://media.newsela.com/article_media/extra/Balloon_Girl_Skintone_2_-_Blue-min.png',
    n: 'https://media.newsela.com/article_media/extra/blue-paper-illustration-min.png',
    o: 'https://media.newsela.com/article_media/extra/Balloon_Girl_Skintone_2_-_Purple-min.png',
    p: 'https://media.newsela.com/article_media/extra/Balloon_Girl_Skintone_1_-_Blue2x-min.png',
    q: 'https://media.newsela.com/article_media/extra/blue-paper-illustration-min.png',
    r: 'https://media.newsela.com/article_media/extra/Balloon_Girl_Skintone_1_-_Yellow2x-min.png',
    s: 'https://media.newsela.com/article_media/extra/Balloon_Girl_Skintone_1_-_Orange2x-min.png',
    t: 'https://media.newsela.com/article_media/extra/blue-paper-illustration-min.png',
    u: 'https://media.newsela.com/article_media/extra/Balloon_Girl_Skintone_2_-_Blue-min.png',
    v: 'https://media.newsela.com/article_media/extra/blue-paper-illustration-min.png',
    w: 'https://media.newsela.com/article_media/extra/Balloon_Girl_Skintone_2_-_Purple-min.png',
    x: 'https://media.newsela.com/article_media/extra/Balloon_Girl_Skintone_1_-_Blue2x-min.png',
    y: 'https://media.newsela.com/article_media/extra/blue-paper-illustration-min.png',
    z: 'https://media.newsela.com/article_media/extra/Balloon_Girl_Skintone_1_-_Yellow2x-min.png',
    0: 'https://media.newsela.com/article_media/extra/Balloon_Girl_Skintone_1_-_Orange2x-min.png',
    1: 'https://media.newsela.com/article_media/extra/blue-paper-illustration-min.png',
    2: 'https://media.newsela.com/article_media/extra/Balloon_Girl_Skintone_2_-_Yellow-min.png',
    3: 'https://media.newsela.com/article_media/extra/Girl_with_lightbulb-Skintone_1-Orange-min.png',
    4: 'https://media.newsela.com/article_media/extra/Girl_with_lightbulb-Skintone_1-blue-min.png',
    5: 'https://media.newsela.com/article_media/extra/Balloon_Girl_Skintone_2_-_Purple-min.png',
    6: 'https://media.newsela.com/article_media/extra/Balloon_Girl_Skintone_1_-_Blue2x-min.png',
    7: 'https://media.newsela.com/article_media/extra/blue-paper-illustration-min.png',
    8: 'https://media.newsela.com/article_media/extra/Balloon_Girl_Skintone_2_-_Purple-min.png',
    9: 'https://media.newsela.com/article_media/extra/Balloon_Girl_Skintone_1_-_Blue2x-min.png',
  };
  const img = imageMap[lastChar];
  return img;
};
