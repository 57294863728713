import React from 'react';

import {isFunction} from 'lodash-es';
import PropTypes from 'prop-types';

import {
  FocusableCard,
  FocusableCardSet,
} from 'static/three-oh/src/components';
import BREAKPOINTS from 'static/three-oh/src/constants/BreakPoints';
import SCREENSIZE from 'static/three-oh/src/constants/ScreenSize';

import cloudImportLogo from './assets/cloud-import.png';
import newselaLogo from './assets/newsela-logo-128x128.png';
import CreateClassForm from './components/CreateClassForm/CreateClassForm';
import IntegrationCard from './components/IntegrationCard/IntegrationCard';
import withWindowSize from '../../components/with/WithWindowSize';
import UnfocusedCardDefaultLayout from '../FocusableCard/components/UnfocusedCardDefaultLayout/UnfocusedCardDefaultLayout';

import styles from './styles.scss';




// Prop Types & Defaults
const propTypes = {
  createClassroom: PropTypes.func.isRequired,
  hideHeader: PropTypes.bool,
  next: PropTypes.func,
  close: PropTypes.func,
  size: PropTypes.string,
  compact: PropTypes.bool,
  newselaClassrooms: PropTypes.bool,
  hideFooter: PropTypes.bool,
  user: PropTypes.object,
};

const defaultProps = {
  compact: false,
  hideFooter: false,
};

/**
 * 'UNSTABLE' prefix signifies that this component will be updated or removed once
 * we get rid of Angular on the settings page.
 */
class UNSTABLE_CreateClassWrapper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.createClassroom = this.createClassroom.bind(this);
    this.getFooter = this.getFooter.bind(this);
    this.getHeader = this.getHeader.bind(this);
    this.getIntegrationCard = this.getIntegrationCard.bind(this);
    this.getNewselaClassroomCard = this.getNewselaClassroomCard.bind(this);

    this.state = {
      iconHover: false,
    };
  }

  getFooter() {
    if (this.props.hideFooter) {
      return null;
    }

    return (
      <div className={styles.footer}>
        <div className={styles.text}>
          Newsela is secure and will never share your students&apos; personal information with any person or business.
          See our&nbsp;
          <a
            className={styles.link}
            href="/privacy-policy"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy Policy
            &nbsp;<i className={`fa fa-lock ${styles.lock}`} /> .
          </a>
        </div>
      </div>
    );
  }

  // TODO: Refactor classroom creation logic to use Redux. This may require UI changes
  // to represent classroom loading state.
  createClassroom(classroomName, grade, classroomIsElementary, subjects) {
    // On the server side, elementary is called `is_all_ages`. To avoid
    // breaking a v1 API contract, let's change the property name as we're
    // pushing it up to the server.
    const classroom = ({
      name: classroomName,
      grade: grade,
      is_all_ages: classroomIsElementary,
      subject_slugs: subjects,
    });

    // Update the left nav with the newly created classroom, and send an event
    // to any legacy code that a classroom was created. Moving forward, this
    // event legacy code shouldn't be necessary, as the 'createClassroom'
    // function passed in via props should ideally make the Redux store aware
    // of the newly created classroom. That function should trigger the onset
    // of posting the new classroom to the database *and* the Redux store.

    this.props.createClassroom(classroom);
    const classroomCreatedEvent = document.createEvent('Event');
    classroomCreatedEvent.initEvent('classroomCreated', false, true);
    window.dispatchEvent(classroomCreatedEvent);
    if (isFunction(this.props.next)) {
      this.props.next(classroom);
    }
    if (isFunction(this.props.close)) {
      this.props.close(classroom);
    }
  }

  getHeader() {
    if (this.props.hideHeader) {
      return null;
    }
    return (
      <div
        className={styles.title}
        data-qa-selector="create_class_title"
      >
        Give your students access to NEWSELA
        {this.getIcon()}
      </div>
    );
  }

  getIcon() {
    if (!this.props.close) {
      return null;
    }
    return (
      <i
        aria-expanded="true"
        className={`fa fa-times ${styles.closeIcon}`}
        onClick={this.props.close}
        data-qa-selector="close_create_class_popup_button"
      />
    );
  }

  getNewselaClassroomCard() {
    const unfocusedCard = (
      <UnfocusedCardDefaultLayout
        title="Create a Class"
        logo={<img src={newselaLogo} alt="Newsela Logo" />}
      />
    );

    const createClassForm = (
      <CreateClassForm
        createClassroomFunc={this.createClassroom}
        onClose={this.props.close}
      />
    );

    return (
      <FocusableCard
        id="newsela-classroom"
        unfocusedView={unfocusedCard}
        focusedView={createClassForm}
      />
    );
  }

  getIntegrationCard() {
    const unfocusedCard = (
      <UnfocusedCardDefaultLayout
        title="Import a Class"
        logo={<img src={cloudImportLogo} alt="Import a Class" />}
      />
    );

    const integrationCard = (
      <IntegrationCard
        authProvider={this.props.user.auth_type}
      />
    );
    return (
      <FocusableCard
        id="integrations"
        unfocusedView={unfocusedCard}
        focusedView={integrationCard}
      />
    );
  }

  getBody() {
    const shouldHideCreateClass = !(BREAKPOINTS[this.props.size] >= BREAKPOINTS[SCREENSIZE.small]);
    if (shouldHideCreateClass) {
      return (
        <p>Please use a larger device to create a new class</p>
      );
    } else {
      return (
        <FocusableCardSet
          leftCard={this.getNewselaClassroomCard()}
          rightCard={this.getIntegrationCard()}
        />
      );
    }
  }

  compactBody() {
    if (this.props.newselaClassrooms) {
      return (
        <CreateClassForm
          createClassroomFunc={this.createClassroom}
          onClose={this.props.close}
        />
      );
    }
  }

  render() {
    const {compact} = this.props;
    return (
      <div
        className={compact ? styles.compactContainer : styles.createClassContainer}
        data-qa-selector="create_class_form"
      >
        {this.getHeader()}
        {compact ? this.compactBody() : this.getBody()}
        {this.getFooter()}
      </div>
    );
  }
}

UNSTABLE_CreateClassWrapper.propTypes = propTypes;
UNSTABLE_CreateClassWrapper.defaultProps = defaultProps;

const CreateClassWithSize = withWindowSize(UNSTABLE_CreateClassWrapper);
export {CreateClassWithSize as CreateOrSyncClass};
