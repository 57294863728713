import React from 'react';

import {
  faCaretDown as buttonCaret
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames/bind';
import {map} from 'lodash-es';
import PropTypes from 'prop-types';

import {ButtonWithTrackEvent} from 'static/three-oh/src/components/Button';
import TrackEventProps from 'static/three-oh/src/components/with/WithTrackEventConstants';
import AngelouProps from 'static/three-oh/src/propTypes/AngelouPropTypes';
import {toSnakeCase} from 'static/three-oh/utils/stringUtils';

import styles from './styles.scss';

const propTypesButtonSplit = {
  ...AngelouProps,

  /**
   * There are three
   * types of button: Standard,
   * Rounded, and Flat
   */
  standard: PropTypes.bool,
  rounded: PropTypes.bool,
  flat: PropTypes.bool,

  /**
  * A button of any type
  * can take on a primary style,
  * or a secondary style. A user
  * can also pass in a className
  * for additional customized
  * styles
  * */
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,

  /**
  * A button can have an icon,
  * a label, or both
  * */
  icon: PropTypes.node,
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(['before', 'after']),

  /**
   * The options to fill the dropdown.
   * This is what adds the dropdown button menu.
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      // label can be anything. even another component
      label: PropTypes.string,
      // onclick will be passed the option and the index in the list
      onClick: PropTypes.func,
      // a function that takes the current option and
      // returns a jsx element that will be rendered
      // in the menu
      __renderOption: PropTypes.func,
      // prevent closing the menu when you click an item
      __preventCloseOnClick: PropTypes.bool,
    })
  ).isRequired,

  /**
   * a function that fires when the main button is clicked.
   */
  onClick: PropTypes.func,

  /**
   * a function that fires after the menu closes. this will only fire
   * if the menu is closed without selecting a option, for example by
   * clicking away from the menu
   */
  onClose: PropTypes.func,

  /**
   * a function that fires when the menu closes
   */
  onExit: PropTypes.func,

  /**
   * a function that fires before the menu opens
   */
  onEnter: PropTypes.func,

  ...TrackEventProps,
};

const generateCSSClassName = classNames.bind(styles);

/**
 */
class ButtonSplit extends React.Component {
  state = {
    menuState: 'closed',
    anchorEl: null
  };

  handleMainClick = (event) => {
    event.preventDefault();
    this.props.onClick(event);
  };

  handleSubClick = (event) => {
    event.preventDefault();
    this.setState({anchorEl: event.currentTarget, menuState: 'open'});
  };

  setMenuState = (menuState) => {
    const newState = {menuState};
    if (menuState === 'closed') {
      newState.anchorEl = null;
    }
    this.setState(newState);
  };

  /**
   * @param {} event
   * @param {string} args
   * essentially just fire off the optional actions
   * the consumer passes in, onClose, onExit, onEnter
   */
  onEvent = (event, args = []) => {
    if (this.props[event]) {
      this.props[event](...args);
    }
  };

  createMenuItem = (option, defaultOnClick, index) => {
    const label = option.__renderOption
      ? option.__renderOption(option)
      : option.label;
    return (
      <MenuItem
        key={`${option.value}_${index}`}
        value={`dropDown_${index}`}
        onClick={(e) => {
          e.preventDefault();
          if (!option.__preventCloseOnClick) {
            defaultOnClick(closed);
          }
          if (option.onClick) {
            option.onClick(option, index, e);
          }
        }}
        data-qa-selector={toSnakeCase(label)}
      >
        {label}
      </MenuItem>
    );
  };

  getButtonType() {
    const btnType = {
      standard: this.props.standard,
      rounded: this.props.rounded,
      flat: this.props.flat,
      primary: this.props.primary,
      secondary: this.props.secondary,
      disabled: this.props.disabled,
    };
    return btnType;
  }

  getSplitType() {
    const className = generateCSSClassName(
      styles.split,
      this.getCSSClassForButtonType(),
      this.getCSSClassForButtonSubType(),
    );

    return className;
  }

  getCSSClassForButtonType() {
    if (this.props.flat) {
      return styles.flat;
    } else if (this.props.rounded) {
      return styles.rounded;
    } else {
      return styles.standard;
    }
  }

  getCSSClassForButtonSubType() {
    if (this.props.secondary) {
      return styles.secondary;
    } else {
      return styles.primary;
    }
  }

  render() {
    const type = this.getCSSClassForButtonType();
    const subType = this.getCSSClassForButtonSubType();
    const showMenu = this.props.contentType !== 'textset';

    const className = generateCSSClassName(
      styles.button,
      type,
      subType,
    );

    const options = map(this.props.options, (option, index) =>
      this.createMenuItem(option, this.setMenuState, index)
    );

    // eventProperties handled individually for main and dropdown buttons.
    const trackingProps = {
      legacyEventName: this.props.legacyEventName,
      streamProperties: this.props.streamProperties,
      actionName: this.props.actionName,
      actionPrefix: this.props.actionPrefix,
      objectType: this.props.objectType,
      trackView: this.props.trackView,
      trackHover: this.props.trackHover,
      trackClick: this.props.trackClick,
    };

    return (
      <div {...this.props.buttonElementProps} className={showMenu ? styles.buttonSplit : ''}>
        <ButtonWithTrackEvent
          {...this.getButtonType()}
          className={`${className} ${styles.splitLeft}`}
          onClick={(e) => this.handleMainClick(e)}
          label={this.props.label}
          {...trackingProps}
          eventProperties={{
            ...this.props.eventProperties,
            description: 'Main button'
          }}
        />
        {showMenu ? (
          <>
            <div className={`${this.getSplitType()}`} />
            <ButtonWithTrackEvent
              ariaLabel="Expand menu"
              {...this.getButtonType()}
              icon={<FontAwesomeIcon icon={buttonCaret}/>}
              className={`${className} ${styles.splitRight}`}
              onClick={(e) => this.handleSubClick(e)}
              dataQaSelector="expand_menu_button"
              {...trackingProps}
              eventProperties={{
                ...this.props.eventProperties,
                description: 'Menu dropdown'
              }}
            />
            <Menu
              className={styles.dropDown}
              anchorEl={this.state.anchorEl}
              open={this.state.menuState === 'open'}
              onClose={() => {
                this.onEvent('onClose');
                this.setMenuState('closed');
              }}
              onEnter={() => {
                this.onEvent('onEnter');
              }}
              onExit={() => {
                this.onEvent('onExit');
              }}
            >
              {options}
            </Menu>
          </>
        ) : null}
      </div>
    );
  }
}

ButtonSplit.propTypes = propTypesButtonSplit;
ButtonSplit.defaultProps = {};
ButtonSplit.displayName = 'ButtonSplit';

export default ButtonSplit;
