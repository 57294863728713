import {takeEvery, call, put} from 'redux-saga/effects';

import {SearchServiceApi} from 'static/three-oh/src/modules/apis';

import * as actions from './../actions/searchActions';
import * as types from './../constants/searchActionTypes';

function * getSearch(action) {
  try {
    const response = yield call(SearchServiceApi.get, action.queryString);
    yield put(actions.requestSearchSuccess(response.data, action.slug));
  } catch (error) {
    yield put(actions.requestSearchFailure(error, action.slug));
  }
}

export function * watchGetSearch() {
  yield takeEvery(types.SEARCH_LOAD_REQUEST, getSearch);
}
