import React from 'react';

import PropTypes from 'prop-types';

import GlobalLayoutV2 from './GlobalLayoutV2/GlobalLayoutV2';

const propTypes = {
  history: PropTypes.object,
  isPresentation: PropTypes.bool,
  isViewingFromAssignment: PropTypes.bool,
  main: PropTypes.node,
  skipToId: PropTypes.string,
};

const GlobalLayout = ({
  history,
  isPresentation,
  isViewingFromAssignment,
  main,
  skipToId,
}) => {
  return (
    <GlobalLayoutV2
      history={history}
      isPresentation={isPresentation}
      isViewingFromAssignment={isViewingFromAssignment}
      main={main}
      skipToId={skipToId}
    />
  );
};

GlobalLayout.propTypes = propTypes;
export default GlobalLayout;
