import {constants, mediaQueryStyles, typographyStyles} from '@newsela/angelou';
import {css, cx} from 'emotion';

const {mediaQuery: mq} = mediaQueryStyles;
const {heading3Obj, textMObj, textSObj} = typographyStyles;
const {BreakpointNames: {sm, lg, md, xs}} = constants.breakpoints;
const {brand, status, ui} = constants.colors;

const grey = ui.neutral[500];
const neutral = ui.neutral[700];
const red = status.danger[600];
const white = ui.white[500];
const purple = brand.aqua[50];

export const baseStyles = cx([css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  margin: 0,

  [mq.downTo(sm)]: {
    flexDirection: 'row',
    maxHeight: '694px',
    margin: '16px',
    borderRadius: '16px',
  },

  [mq.between(sm, md)]: {
    maxWidth: '640px',
  },

  [mq.downTo(md)]: {
    width: 'calc(100% - 386px)',
    maxWidth: '896px',
  },

  [mq.downTo(lg)]: {
    maxHeight: '620px',
  }
}), css({...textMObj})]);

export const svgContainer = css({
  display: 'none',

  '@media(min-width: 321px)': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: purple,
    maxHeight: '240px',
  },

  [mq.downTo(xs)]: {
    maxHeight: '307px',
  },

  [mq.downTo(sm)]: {
    maxHeight: 'none',
    padding: '0 40px',
    borderTopLeftRadius: 'inherit',
    borderBottomLeftRadius: 'inherit',
  },

  [mq.between(sm, md)]: {
    maxWidth: '256px',
  },

  [mq.downTo(md)]: {
    flex: '4 0',
    width: '100%',
    maxWidth: '402px',
    padding: '0 40px',
  },
});

export const heroSvgCss = css({
  width: '42%',
  height: '100%',
  maxWidth: '242px',
  margin: '30px',

  [mq.downTo(xs)]: {
    margin: '40px',
  },

  [mq.downTo(sm)]: {
    width: '100%',
    maxWidth: 'none'
  },
});

export const textContainer = css({
  display: 'flex',
  flexDirection: 'column',
  height: 'inherit',
  margin: 0,
  padding: '28px 16px 24px',
  backgroundColor: white,
  color: grey,
  overflow: 'hidden',

  [mq.downTo(xs)]: {
    padding: '28px 32px 24px',
  },

  [mq.downTo(sm)]: {
    flexBasis: 0,
    flexGrow: 6,
    padding: '30px 32px 24px',
    borderTopRightRadius: 'inherit',
    borderBottomRightRadius: 'inherit',
  },

  [mq.between(sm, md)]: {
    maxWidth: '384px',
  },

  [mq.downTo(md)]: {
    maxWidth: '494px',
  },

  [mq.downTo(lg)]: {
    padding: '28px 32px 24px',
  },
});

export const scrollableContainer = css({
  overflow: 'auto',
  '--webkit-overflow-scrolling': 'touch',
});

export const listCss = css({
  listStyleType: 'none',
  margin: '24px 0',
  padding: 0
});

export const listItemCss = css({
  display: 'flex',
  marginBottom: '16px',
  color: grey,
});

export const iconCss = {
  width: '56px',
  height: '56px',
  marginRight: '16px',
  padding: '12px',
  backgroundColor: purple,
  borderRadius: '50%',
  overflow: 'visible',
};

export const headerContainer = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '24px'
});

export const headingCss = css({
  ...heading3Obj,
  margin: '0 0 16px',
  color: neutral,
});

export const footerContainer = css({
  display: 'block',
  width: '100%',
  minHeight: '72px',
  marginTop: 'auto',
  backgroundColor: 'inherit'
});

export const ctaButtonContainer = css({
  [mq.downTo(sm)]: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
  }
});

export const ctaButtonCss = {
  [mq.upTo(sm)]: {
    display: 'block',
    width: '100%'
  }
};

export const errorContainer = css({
  ...textSObj,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: red,

  [mq.downTo(sm)]: {
    justifyContent: 'flex-end'
  }
});

export const errorIconCss = {
  width: '16px',
  height: '16px',
  marginRight: '6px',
  path: {
    fill: red
  },
  'path:first-of-type': {
    stroke: red
  }
};

export const closeButtonCss = {
  alignItems: 'baseline',
  display: 'flex',
  justifyContent: 'center',
  margin: 0,
  width: '24px',
  height: '24px',

  svg: {
    width: '24px',
    height: '24px',
    path: {
      strokeWidth: '3px'
    }
  }
};
