import * as types from 'modulesV2/constants';

export const getContentRequest = (contentId, queryParams) => {
  return {
    type: types.GET_CONTENT_REQUEST,
    contentId,
    queryParams
  };
};

export const getContentSuccess = (data) => {
  return {
    type: types.GET_CONTENT_SUCCESS,
    data
  };
};


export const getContentFailure = (error) => {
  return {
    type: types.GET_CONTENT_FAILURE,
    error
  };
};
