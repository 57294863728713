import * as types from 'modulesV2/constants';

export default function students(state = {}, action) {
  switch (action.type) {
    case types.CANVAS_APP_LOAD_STUDENT_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.id]: action.data,
        }
      };
    default:
      return state;
  }
}
