import {ifvisible} from 'ifvisible.js';
import {size} from 'lodash-es';
import {delay} from 'redux-saga';
import {takeLatest, fork, call, takeEvery, put, select} from 'redux-saga/effects';

import {NotificationApi} from 'static/three-oh/src/modules/apis';
import {
  prependNotificationsToLocalStorage,
} from 'static/three-oh/src/modules/utils/notificationsLocalStorageUtils';

import {notificationsActions as actions} from '../actions';
import {notificationsActionTypes as types} from '../constants';
import {notificationsSelectors, userSelectors} from '../selectors';
import {getNamespaceForNotificationRequest} from '../utils/notificationHelpers';


function saveToLocalStorage(notifications, userId) {
  if (size(notifications) > 0) {
    prependNotificationsToLocalStorage(userId, notifications);
  }
}

/**
 * This generator works with all notifications available to the user.
 *
 * Note: If localstorage doesn't work, user would have to fetch all the notifications
 *  after every page refresh.
 *
 * @generator
 * @param {number} userId
 * @param {Object} filters
 * @param {string} filters.streamName
 * @param {number} filters.pageSize
 * @param {moment} [filters.modifiedSince]
 * @private
 */
export function * fetchAllUserNotifications(userId, filters) {
  // Relying only on the local storage saved date, if it doesn't exist, use redux Modified data
  const serializationFilters = {};
  const allNotificationsNamespace = getNamespaceForNotificationRequest(serializationFilters);
  filters.modifiedSince = yield select(
    notificationsSelectors.getMostRecentDateCreated,
    allNotificationsNamespace
  );
  // Fetch notifications from the API
  yield put(actions.getNotificationsRequest(serializationFilters));
  const notifications = yield call(NotificationApi.getNotifications, {...filters});
  yield put(actions.getNotificationsSuccess(notifications, serializationFilters));
  yield notifications;
}

/**
 * Fetches notifications that match selected headerSlug, header IDs,
 * assignmentIds, or classroom ID.
 *
 * @generator
 * @param {array} assignmentIds
 * @param {array} headerIds
 * @param {string} headerSlug
 * @param {string} classroomId
 * @param {string} classroomIds
 */

export function * queryForNotifications({headerSlug, assignmentIds, classroomId, classroomIds, headerIds, since, userId, pageSize, excludeActivities}) {
  // Launch a request to the notifications API resource to check for new
  // notifications since the most recent notification `date_created`. If
  // `date_created` is `undefined` then all notifications are returned.
  // These will be used to namespace notification responses on state. I'm keeping
  // them separate from filters even though they need to go to the API as well
  // because the rest of the filters either change on every request or change on
  // no requests and so won't be useful for caching. Just add another parameter
  // like classroom_id or assignment_id here and it will be used to cache notifications
  // for that request.
  const serializationFilters = {};
  if (headerSlug) {
    serializationFilters.headerSlug = headerSlug;
  }
  if (assignmentIds) {
    serializationFilters.assignmentIds = assignmentIds;
  }
  if (classroomId) {
    serializationFilters.classroomId = classroomId;
  }
  if (classroomIds) {
    serializationFilters.classroomIds = classroomIds;
  }
  if (headerIds) {
    serializationFilters.headerIds = headerIds;
  }
  if (since) {
    serializationFilters.since = since;
  }
  if (userId) {
    serializationFilters.userId = userId;
  } else {
    userId = yield select(userSelectors.getUserId);
  }
  if (excludeActivities) {
    serializationFilters.excludeActivities = excludeActivities;
  }

  const namespace = getNamespaceForNotificationRequest(serializationFilters);
  const modifiedSince = yield select(notificationsSelectors.getMostRecentDateCreated, namespace);
  const filters = {
    streamName: `user:${userId}`,
    modifiedSince,
    pageSize: pageSize || 10000,
    withArchivedClassrooms: true
  };
  try {
    // Specific notifications.
    yield put(actions.getNotificationsRequest(serializationFilters));
    const notifications = yield call(NotificationApi.getNotifications, {...serializationFilters, ...filters});
    yield put(actions.getNotificationsSuccess(notifications, serializationFilters));
    if (namespace === 'all') {
      yield call(saveToLocalStorage, notifications.data, userId, serializationFilters);
    }
  } catch (error) {
    yield put(actions.getNotificationsFailure(error, serializationFilters));
  }
}

function * pollForNotifications(action) {
  // Don't poll if the notification success action came from local storage
  if (action.fromLocalStorage) {
    return null;
  }
  yield call(delay, 36000);
  // If we're not visible, keep delaying.
  while (!ifvisible.now()) {
    yield call(delay, 1000);
  }
  yield fork(queryForNotifications, action.serializationFilters);
}

// --- Watchers ---- //

export function * watchInitNotifications() {
  yield takeLatest(types.INITIALIZE_NOTIFICATIONS, queryForNotifications);
}


export function * watchPollForNotifications() {
  yield takeEvery(types.GET_NOTIFICATIONS_SUCCESS, pollForNotifications);
}
