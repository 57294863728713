import * as types from 'modulesV2/constants';

const defaultState = {
  isMainContentAriaHidden: false
};

export default function ariaAttributesReducer(state = defaultState, action) {
  switch (action.type) {
    case types.SET_ARIA_HIDDEN_MAIN_CONTENT:
      return {
        ...state,
        isMainContentAriaHidden: action.isMainContentAriaHidden
      };
    default:
      return state;
  }
}
