import {setMastheadHeight, setMastheadHeightToDefault, setContentOffset} from 'utils/mastheadHeight';

export const setMastheadOffset = (mastHeadHeight, indicatorHeight) => {
  if (mastHeadHeight !== undefined) {
    setMastheadHeight(mastHeadHeight);
    if (indicatorHeight) {
      setContentOffset(`${parseInt(mastHeadHeight) + parseInt(indicatorHeight)}px`);
    } else {
      setContentOffset(mastHeadHeight);
    }
  } else {
    setMastheadHeightToDefault();
  }
};
