import {
  get,
  isNil,
  maxBy,
  some,
} from 'lodash-es';

import {lyceumOrigin} from 'static/three-oh/src/constants/lyceum';
import {GRADING_MODE, GRADING_PAGE} from 'static/three-oh/src/routes/Read/components/GradingMode/constants';


const {GRADING_ACTIVITY_TYPES, GRADING_QUERY_PARAMS} = GRADING_PAGE;

/**
 * Return the activity type based on the string that's passed in
 * @param {string} type - The type of activity. Sometimes this has a different format from case to case
 * @return {string} - The activity type based on the substring of type
 */
const getActivityType = (type) => {
  if (typeof type !== 'string') return type;
  type = type.toLowerCase();

  let activityType;
  if (type.includes('quiz')) {
    activityType = GRADING_ACTIVITY_TYPES.quiz;
  } else if (type.includes('write') || type.includes('prompt')) {
    activityType = GRADING_ACTIVITY_TYPES.writePrompts;
  } else if (type.includes('annotation')) {
    activityType = GRADING_ACTIVITY_TYPES.annotations;
  } else if (type.includes('vocabulary') || type.includes('power')) {
    activityType = GRADING_ACTIVITY_TYPES.powerWords;
  }
  return activityType;
};

const hasCustomPrompt = (prompts) => {
  return some(prompts, (prompt) => !isNil(get(prompt, 'teacher_id')));
};

export const buildAssignmentHeaderUrlByType = (
  measure,
  articleHeader,
  classroom,
  assignment,
) => {
  const headerId = articleHeader?.id;
  const assignmentId = assignment?.content_id;
  const contentId = articleHeader?.story?.content_id;
  const classroomId = classroom?.id;
  const returnUrl = encodeURIComponent(window.location.href);
  const gradingPageUrl = new URL(
    `grading/${contentId}/`,
    window.location.origin
  );
  const sortedArticlesByLexileLevel = articleHeader?.articles?.sort((a, b) => {
    return b.lexile_level - a.lexile_level;
  });
  const selectedArticle = sortedArticlesByLexileLevel[0];
  const lexileLevel = sortedArticlesByLexileLevel[0]?.content_id;
  const params = {
    [GRADING_QUERY_PARAMS.assignmentId]: assignmentId,
    [GRADING_QUERY_PARAMS.classroomId]: classroomId,
    [GRADING_QUERY_PARAMS.lexileLevel]: lexileLevel,
    [GRADING_QUERY_PARAMS.returnUrl]: returnUrl,
  };

  if (headerId) {
    params[GRADING_QUERY_PARAMS.headerId] = headerId;
  }

  const [collectionTitle] = measure?.label;
  if (measure.type.includes('quiz')) {
    // Add collection ID to URL tdo be more specific
    if (measure.label.length > 1) {
      const collectionMatch = selectedArticle.quiz?.collections.find(
        (collection) => {
          return collection.title === collectionTitle;
        }
      );
      params[GRADING_QUERY_PARAMS.collection] = collectionMatch?.id;
    }
    params[GRADING_QUERY_PARAMS.activityId] = selectedArticle?.quiz?.id;
    params[GRADING_QUERY_PARAMS.activityType] = GRADING_ACTIVITY_TYPES.quiz;
  } else if (measure.type.includes('write')) {
    if (measure.label.length > 1) {
      // Find matching prompt by collection title
      const collectionTitle = measure.label[0];
      let prompts = selectedArticle.prompts.filter((prompt) => {
        return prompt.collections.find((collection) => {
          return collection.title === collectionTitle;
        });
      });

      if (hasCustomPrompt(prompts)) {
        prompts = prompts.filter((prompt) => prompt.teacher_id);
      }

      const prompt = maxBy(prompts, 'date_modified');

      if (prompt?.collections?.[0]?.id) {
        params[GRADING_QUERY_PARAMS.collection] = prompt?.collections?.[0]?.id;
      }
      if (prompt?.id) {
        params[GRADING_QUERY_PARAMS.activityId] = prompt?.id;
      }
    }
    params[GRADING_QUERY_PARAMS.activityType] = GRADING_ACTIVITY_TYPES.writePrompts;
  } else if (measure.type.includes('annotations')) {
    params[GRADING_QUERY_PARAMS.activityType] = GRADING_ACTIVITY_TYPES.annotations;
  } else if (measure.type.includes('power-words')) {
    params[GRADING_QUERY_PARAMS.activityType] = GRADING_ACTIVITY_TYPES.powerWords;
  } else {
    return null;
  }

  Object.keys(params).forEach((key) => {
    gradingPageUrl.searchParams.set(key, params[key]);
  });

  return `${lyceumOrigin}${gradingPageUrl.pathname}${gradingPageUrl.search}`;
};

export const buildStudentGradingPageUrl = (gradingData, isInReviewMode = false) => {
  const {data, type: cellType} = gradingData?.cellNotification;
  const assignmentId = gradingData.assignmentId;
  const contentId = gradingData?.articleHeader?.story?.content_id;
  const assignmentGroupId = gradingData?.assignmentGroupId;
  const classroomId = gradingData?.classroomId;
  const levelId = data?.article?.content_id;
  const headerId = gradingData?.headerId;
  const currentUrl = window.location.href;
  const returnUrl = encodeURIComponent(currentUrl);
  const selectedArticleByLevel = gradingData?.articleHeader?.articles?.find(
    (article) => {
      return article?.content_id === data?.article?.content_id;
    }
  );

  let activityType;
  let activityId;
  let collectionId;
  // Annotations must be first because annotation was not included in the notification object
  if (cellType === 'student-annotation') {
    activityType = GRADING_ACTIVITY_TYPES.annotations;
  } else if (cellType === 'checks-for-understanding-activity') {
    activityType = GRADING_ACTIVITY_TYPES.checksForUnderstanding;
  } else if (data?.quiz) {
    activityId = selectedArticleByLevel?.quizzes[0]?.id;
    activityType = GRADING_ACTIVITY_TYPES.quiz;
    collectionId = selectedArticleByLevel?.quizzes[0]?.collections?.[0]?.id;
  } else if (data?.response) {
    activityId = gradingData?.cellNotification?.data?.response?.prompt?.id;
    activityType = GRADING_ACTIVITY_TYPES.writePrompts;
    collectionId = selectedArticleByLevel?.prompts?.find((writePrompt) => {
      return writePrompt?.id.toString() === activityId.toString();
    })?.collections?.[0]?.id;
  } else if (data?.activity) {
    activityType = GRADING_ACTIVITY_TYPES.powerWords;
  }

  const params = {
    [GRADING_QUERY_PARAMS.assignmentId]: assignmentId,
    [GRADING_QUERY_PARAMS.activityType]: activityType,
    [GRADING_QUERY_PARAMS.lexileLevel]: levelId,
    [GRADING_QUERY_PARAMS.headerId]: headerId,
    [GRADING_QUERY_PARAMS.returnUrl]: returnUrl,
  };

  if (assignmentGroupId) {
    params[GRADING_QUERY_PARAMS.assignmentGroupId] = assignmentGroupId;
  }

  if (classroomId) {
    params[GRADING_QUERY_PARAMS.classroomId] = classroomId;
  }

  if (isInReviewMode) {
    params[GRADING_QUERY_PARAMS.isCanvasMode] = isInReviewMode;
    params[GRADING_QUERY_PARAMS.studentUniqueId] = data?.student?.unique_id;
  }

  if (activityId) {
    params[GRADING_QUERY_PARAMS.activityId] = activityId;
  }

  if (collectionId) {
    params[GRADING_QUERY_PARAMS.collection] = collectionId;
  }

  // generate grading url
  const gradingPageUrl = new URL(
    `grading/${contentId}/`,
    window.location.origin
  );

  Object.keys(params).forEach((key) => {
    gradingPageUrl.searchParams.set(key, params[key]);
  });

  return `${lyceumOrigin}${gradingPageUrl.pathname}${gradingPageUrl.search}`;
};

/**
 * Builds the grading page url for the binder table. Currently, the url goes to the read page with the activity panel open but no activity selected. Here, we update
 * the url's to be a bit more specific and include any additional query params needed to go to the
 * correct grading page url.
 * @param {string} url - The existing url before we append the query params
 * @param {object} column - The row object provided by the binder table
 * @param {string} type - The activity type string
 * @returns {string} - The url with the query params appended
 */
export const buildGradingPageUrlForBinder = (
  url,
  column,
  type
) => {
  const activityType = getActivityType(type);
  const legacyArticleId = column?.articleId;
  const additionalGradingData = {};

  additionalGradingData['type'] = activityType;
  additionalGradingData[GRADING_QUERY_PARAMS.legacyArticleId] = legacyArticleId;
  additionalGradingData[GRADING_QUERY_PARAMS.headerId] = column?.headerId;
  if (activityType === 'quiz') {
    const quiz = column?.studentQuiz?.quiz;
    additionalGradingData[GRADING_QUERY_PARAMS.collection] = quiz?.collections?.[0]?.id;
    additionalGradingData[GRADING_QUERY_PARAMS.activityId] = quiz?.id;
  } else if (activityType === 'write_prompt') {
    const writePrompt = column?.writeResponse;
    additionalGradingData[GRADING_QUERY_PARAMS.collection] = writePrompt?.collections?.[0]?.id;
    additionalGradingData[GRADING_QUERY_PARAMS.activityId] = writePrompt?.id;
  }


  return appendGradingModeParams(
    url,
    additionalGradingData
  );
};

/**
 * Generate a grading page url for a specific student by activity type, level, and classroom.
 * Only users in the experiment will be redirected to the grading page.
 * @param {string} url - relative or absolute url
 * @param {Object} cellNotification - relevantNotifications object
 * @param {Object} articleHeader - article header objects
 * @param {Boolean} isGradingPageEnabled - Optional parameter. We could pass this param for scenarios where the grading feature option is checked multiple times, avoiding application crashes.
 * @param {Boolean} isInReviewMode - Whether the review table is accessed from Canvas Speedgrader
 */
export const buildGradingPageUrlForAssignmentTable = (
  url,
  gradingData,
  isInReviewMode = false
) => {
  const isGradingPageEnabled = true;

  // If the user is not in the experiment, return check if the user is in the grading mode experiment
  if (!isGradingPageEnabled) return appendGradingModeParams(url, isGradingPageEnabled);

  // If the user is not in the grading mode experiment, return the url
  if (!gradingData?.cellNotification) {
    return url;
  }

  const gradingPageUrl = buildStudentGradingPageUrl(gradingData, isInReviewMode);

  return gradingPageUrl;
};

/**
 * Append grading mode specific parameters to the return url.
 * @param {string} url - relative or absolute url
 * @param {object} data - additional data to append to the url
 * @param {Boolean} isGradingPageEnabled - Optional parameter. We could pass this param for scenarios where the grading feature option is checked multiple times, avoiding application crashes.
 * @returns {string} URL with grading mode parameters appended
 */
export const appendGradingModeParams = (url, data = {}, isGradingPageEnabled = true

) => {
  const {
    activityId,
    assignmentId,
    collectionId,
    gradeLevel,
    headerId,
    legacyArticleId,
    lexileLevel,
    type,
  } = data;

  // If the user is not in the experiment, return the original URL
  if (!isGradingPageEnabled) {
    const redirectUrl = new URL(url, window.location.origin);
    const params = new URLSearchParams(redirectUrl.search);
    params.set('preventRedirect', 'true');
    return `${redirectUrl}?${params.toString()}`;
  }

  let activityType;
  if (isGradingPageEnabled) {
    activityType = getActivityType(type);
  }

  const gradingModeUrl = new URL(url, window.location.origin);
  const params = new URLSearchParams(gradingModeUrl.search);

  if (isGradingPageEnabled) {
    // Append params specifically for the grading page
    if (assignmentId) params.set(GRADING_QUERY_PARAMS.assignmentId, assignmentId);
    if (activityType) params.set(GRADING_QUERY_PARAMS.activityType, activityType);
    if (lexileLevel) params.set(GRADING_QUERY_PARAMS.lexileLevel, lexileLevel);
    if (gradeLevel) params.set(GRADING_QUERY_PARAMS.gradeLevel, gradeLevel);
    if (activityId) params.set(GRADING_QUERY_PARAMS.activityId, activityId);
    if (collectionId) params.set(GRADING_QUERY_PARAMS.collection, collectionId);
    if (legacyArticleId) params.set(GRADING_QUERY_PARAMS.legacyArticleId, legacyArticleId);
    if (headerId) params.set(GRADING_QUERY_PARAMS.headerId, headerId);
    // SS redirect requires this param to be set to true
    params.set('gradingPageRedirect', isGradingPageEnabled);
  }

  params.set(GRADING_MODE.key, 'true');
  params.set(GRADING_MODE.returnUrlKey, encodeURIComponent(window.location.href));
  return `${gradingModeUrl.pathname}?${params.toString()}`;
};
