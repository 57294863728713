import {
  COLLECTIONS_SLUGS
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';

const MONOLITH_ORIGIN = '';

export const hasCollectionPathNameParams = (collection) => collection?.subject && collection?.root_text_set?.id;

export const getCollectionPathname = (collection) => {
  if (!collection?.subject || !collection?.root_text_set?.id) return;
  const formattedSubject = collection.subject.replace('_', '-');
  return `/subject/${formattedSubject}/${collection.root_text_set.id}`;
};

export const getCustomCollectionsWithHref = (collections) => collections
  .filter((collection) => hasCollectionPathNameParams(collection) && collection.is_custom)
  .map((customCollection) => ({
    ...customCollection,
    href: `${MONOLITH_ORIGIN}${getCollectionPathname(customCollection) || ''}`
  }));

export const getPersonalizedCollectionsWithHref = (collections) => collections
  .filter((collection) => COLLECTIONS_SLUGS.includes(collection.slug))
  .map((personalizedCollection) => {
    personalizedCollection.href = `${MONOLITH_ORIGIN}${getCollectionPathname(personalizedCollection) || ''}`;
    return personalizedCollection;
  });
