import {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';

export const MODIFICATION_WARNING_BANNER_BODY = 'Your admin has flagged content in an existing assignment with a district advisory label.';

export const DEFAULT_MESSAGE = {
  conSet: {
    body: 'Your admin has flagged this content with an advisory label. It may be in violation of district policies or curricular goals. Please contact your admin for more information.',
    title: 'Important Message',
    hyperlink: null
  }
};

export const EMPTY_MESSAGE = {
  conSet: {
    body: '',
    title: '',
    hyperlink: null
  }
};

export const CUSTOM_MESSAGE = {
  conSet: {
    body: '',
    title: 'A message from your district',
    hyperlink: {
      text: 'Learn more',
      url: ''
    }
  }
};

/**
 * This function was extracted from the hook to be able to validate the business logic
 * in unit tests.
 *
 * It is not expected to use this function outside this scope.
 * */
export const _determineAdvisoryMessage = ({response, customAdvisoryMessage}) => {
  if (response.ok && !customAdvisoryMessage.date_archived) {
    return {
      conSet: {
        body: customAdvisoryMessage.text,
        title: CUSTOM_MESSAGE.conSet.title,
        hyperlink: {
          url: customAdvisoryMessage.url,
          text: CUSTOM_MESSAGE.conSet.hyperlink.text
        }
      }
    };
  } else {
    return DEFAULT_MESSAGE;
  }
};

export const getContentAdvisoryMessage = async(user) => {
  const isAuthorizedDistrictAdmin = user?.district_admin?.date_accepted_terms_and_conditions;
  const district = isAuthorizedDistrictAdmin ? user?.district_admin?.district : user?.schools[0].districts[0];

  try {
    const response = await fetch(`/api/v2/district/${district.unique_id}/content-advisory-configuration`);
    const customAdvisoryMessage = await response.json();
    return _determineAdvisoryMessage({response, customAdvisoryMessage});
  } catch (e) {
    return DEFAULT_MESSAGE;
  }
};

export const useContentAdvisoryMessage = () => {
  const user = useSelector(({user}) => user);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [advisoryMessage, setAdvisoryMessage] = useState(EMPTY_MESSAGE);
  useEffect(() => {
    const load = async() => {
      try {
        setLoading(true);
        const conSet = await getContentAdvisoryMessage(user);
        setAdvisoryMessage(conSet);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };
    load();
  }, []);
  return {
    data: advisoryMessage.conSet,
    error,
    loading
  };
};
