import React from 'react';

import {LoadingSpinner} from '@newsela/angelou';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';

import {standardRoot} from 'static/three-oh/src/modules/constants/routePaths';

const LazyStandardContainer = Loadable({
  loader: () => import(
    /* webpackChunkName: "StandardContainer" */
    './containers/StandardContainer'
  ),
  loading: LoadingSpinner,
});

const StandardRoute = (
  <Route
    key="standard"
    path={`${standardRoot}/:standardId/`}
    component={LazyStandardContainer}
  />
);

export default StandardRoute;
