import {cloneDeep} from 'lodash-es';

import * as types from 'modulesV2/constants';


const initializeActiveActicle = ({questionId, selectedAnswer}) => {
  const activity = {};
  if (questionId) {
    activity.quizQuestions = {};
    activity.quizQuestions[questionId] = {
      selectedAnswer: selectedAnswer || null,
    };
  }
  return activity;
};
/**
 * SHAPE OF STATE
 * {
 *   <articleId>: {
 *     quizQuestions:{
 *       <questionId> : {
 *         selectedAnswer,
 *       }
 *     }
 *  }
 * }
 */
function activeArticlesActivities(state = {}, action) {
  const {type, ...payload} = action;
  switch (type) {
    case types.ADD_ACTIVE_ARTICLE_ACTIVITY:
      if (!state[payload.articleId]) {
        const {articleId, ...rest} = payload;
        return {
          ...state,
          [articleId]: {
            ...state[articleId],
            ...rest,
          }
        };
      } else {
        return state;
      }

    case types.UPDATE_ACTIVE_ARTICLE_QUIZ: {
      const {articleId, quizQuestion} = payload;
      if (articleId) {
        const article = state[articleId];
        const newState = cloneDeep(state);
        if (article && quizQuestion) {
          const question = article.quizQuestions[quizQuestion.questionId];
          if (question) {
            // question exists. update in place.
            newState[articleId]
              .quizQuestions[quizQuestion.questionId]
              .selectedAnswer = quizQuestion.selectedAnswer;
          } else {
            // question does not exist, add it to the state
            newState[articleId].quizQuestions[quizQuestion.questionId] = {
              selectedAnswer: quizQuestion.selectedAnswer,
            };
          }
          return newState;
        } else {
          // article id is not on the state for some reason so we add it
          newState[articleId] = initializeActiveActicle(quizQuestion);
          return newState;
        }
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}

export default activeArticlesActivities;
