import {omitBy, isNil} from 'lodash-es';

import {buildReadUrl} from 'static/three-oh/src/modules/constants/routePaths';

export const buildStudentActivityReviewUrl = ({
  articleHeaderSlug,
  articleId,
  studentIntegerId,
  studentUniqueId,
  assignmentId,
  assignmentGroupId,
  classroomId,
  assignmentUserId,
  activityType,
  collectionId,
  activityId,
  returnUrl,
}) => {
  // These params are necessary for building the read url
  if (!articleHeaderSlug || !articleId) return undefined;

  const searchParams = omitBy({
    assignment: assignmentId,
    classroom: classroomId,
    group: assignmentGroupId,
    student: studentIntegerId,
    studentUUID: studentUniqueId,
    collection: collectionId,
    userId: assignmentUserId,
    return_url: returnUrl,
  }, isNil);
  const search = new URLSearchParams(searchParams).toString();

  const cellUrl = {
    pathname: buildReadUrl({
      slug: articleHeaderSlug,
      articleId,
      activityType,
      activityId
    }),
    search
  };
  return cellUrl;
};
