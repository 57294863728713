import {css} from 'emotion';

import {NAVBAR_BREAKPOINTS} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';

export const condensedPopoutSearchBarVisibilityCss = css({
  position: 'relative',
  width: '100%',

  [`@media (min-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
    display: 'none',
  },
});

export const condensedInputContainerCss = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '0.5rem',
  padding: '0.5rem',
  alignItems: 'center',

  [`@media (min-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
    display: 'none',
  },
});

export const condensedCloseButtonCss = {
  root: {
    borderRadius: '0.5rem',
    padding: '0.25rem',

    ':hover': {
      background: 'rgba(29, 29, 29, 0.08)'
    },
  }
};

export const condensedSearchCloseIconCss = {
  root: {
    width: '2rem',
    height: '2rem',
  }
};
