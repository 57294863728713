import React from 'react';

import {css, cx} from 'emotion';
import PropTypes from 'prop-types';

import TrackEventProps from 'static/three-oh/src/components/with/WithTrackEventConstants';

import ELEVATIONS from '../../constants/Elevations';
import AngelouProps from '../../propTypes/AngelouPropTypes';
import {
  gridContainerCss,
} from '../../styles/gridCss';
import Card, {CardWithTrackEvent} from '../Card';

import style from './styles.scss';

const ElevationValues = Object.values(ELEVATIONS).slice(0, -1);

const propTypes = {
  ...AngelouProps,
  ...TrackEventProps,

  /**
   * aria props passed down to the card component
   */
  aria: PropTypes.object,

  /**
   * Determines if the card expands to fill its container or
   * fits the contents given
   */
  fitContent: PropTypes.bool,
  /**
   * elevation of the card. defaults to flat
   */
  elevation: PropTypes.oneOf(ElevationValues),
  /**
   * The top row. used for gridTemplateRow
   */
  topHeight: PropTypes.string,
  /**
   * The bottom row. used for gridTemplateRow
   */
  bottomHeight: PropTypes.string,
  /**
   * css applied to the topElement container
   */
  topCss: PropTypes.string,
  /**
   * The element to go on the left
   */
  topElement: PropTypes.element.isRequired,
  /**
   * css applied to the bottomElement container
   */
  botCss: PropTypes.string,
  /**
   * The element to go on the right.
   */
  bottomElement: PropTypes.element.isRequired,
  /**
   * css applied to the ctaElement container
   */
  ctaCss: PropTypes.string,
  /**
   * call to action section. goes below the bottomElement
   */
  cta: PropTypes.element,
  /**
   * on Click function that fires when the card is clicked
   */
  onClick: PropTypes.func,

  /**
   * if the card acts as a link, this is where it links to
   */
  href: PropTypes.string,

  /**
   * if there is an href do you want it rendered as a
   * Link tag or an anchor tag? defaults to rendering a Link
   * component
   */
  renderAnchorTag: PropTypes.bool,

  /**
   * an element to lay over the card image, passed in conditionally if content is new to the user
   */
  newBadge: PropTypes.element,
};

class VerticalContentCard extends React.Component {
  renderCardContent() {
    let classNames = [style.verticalContentCard];
    const topRow = this.props.topHeight;
    const botRow = this.props.bottomHeight;
    const gridTemplateRows = gridContainerCss({rows: `${topRow} ${botRow}`});

    classNames = cx(...classNames, gridTemplateRows);

    // we need to explicitly set rows and cols for elements
    const getBaseCss = (row, col = 1) => css`
      grid-row: ${row};
      grid-column: ${col};
      -ms-grid-column: ${col};
      -ms-grid-row: ${row};
      -ms-grid-columns: ${col}fr;
      overflow: hidden;
      border-radius: 4px;
    `;
    const topCss = cx(getBaseCss(1), this.props.topCss);
    const botCss = cx(getBaseCss(2), this.props.botCss);
    const ctaCss = cx(getBaseCss(3), this.props.ctaCss);
    return (
      <div
        className={classNames}
      >
        {this.props.newBadge}
        <div
          className={topCss}
        >
          {this.props.topElement}
        </div>
        <div
          className={botCss}
        >
          {this.props.bottomElement}
        </div>
        {
          this.props.cta &&
            <div className={ctaCss}>{this.props.cta}</div>
        }
      </div>
    );
  }

  render() {
    const cardClassNames = [
      style.cardContainer,
      ...this.props.additionalClassNames
    ];
    if (!this.props.fitContent) {
      cardClassNames.push(style.cardContainerFill);
    } else {
      cardClassNames.push(style.cardFitContent);
    }

    if (this.props.actionName) {
      return (
        <CardWithTrackEvent
          aria={this.props.aria}
          objectType={this.props.objectType}
          actionName={this.props.actionName}
          legacyEventName={this.props.legacyEventName}
          additionalClassNames={cardClassNames}
          elevation={this.props.elevation}
          eventProperties={this.props.eventProperties}
          onClick={this.props.onClick}
          trackView={this.props.trackView}
          href={this.props.href}
          renderAnchorTag={this.props.renderAnchorTag}
        >
          {this.renderCardContent()}
        </CardWithTrackEvent>
      );
    } else {
      return (
        <Card
          aria={this.props.aria}
          additionalClassNames={cardClassNames}
          elevation={this.props.elevation}
          onClick={this.props.onClick}
          href={this.props.href}
          renderAnchorTag={this.props.renderAnchorTag}
        >
          {this.renderCardContent()}
        </Card>
      );
    }
  }
}

VerticalContentCard.propTypes = propTypes;

VerticalContentCard.defaultProps = {
  additionalClassNames: [],
  topHeight: 'auto',
  bottomHeight: 'auto',
};

export default VerticalContentCard;
