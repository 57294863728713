import {includes, endsWith} from 'lodash-es';

import ApiFetcher2 from 'static/three-oh/src/modules/apis/ApiFetcher2';
import {
  FETCH_RECORD, FETCH_COLLECTION, CREATE, UPDATE, DELETE
} from 'static/three-oh/src/modules/ducks/objectApiDucks';


const isObjectApiNetworkAction = (action) => {
  return includes([FETCH_RECORD, FETCH_COLLECTION, CREATE, UPDATE, DELETE], action.type);
};

/**
 * Perform an API fetch and dispatch either a success or failure action based on the response.
 * Additionally, directly return the result of the fetch as a promise.
 */

const newselaObjectApiMiddleware = (store) => (next) => (action) => {
  next(action);

  if (isObjectApiNetworkAction(action)) {
    let {method, path, params, data} = action.payload;
    if (!endsWith(path, '/')) {
      path += '/';
    }
    const {success, failure} = action.meta;

    const meta = {...action.meta, method, path, params, fetchTime: Date.now()};

    return ApiFetcher2[method](path, {params, data}).then((response) => {
      store.dispatch({type: success, meta, payload: response.data});
      return Promise.resolve(response);
    }).catch((response) => {
      store.dispatch({type: failure, meta, payload: response.error, error: true});
      return Promise.reject(response);
    });
  }
};

export default newselaObjectApiMiddleware;
