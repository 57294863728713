import React from 'react';

import {Toaster} from '@newsela/angelou';
import PropTypes from 'prop-types';
import {createPortal} from 'react-dom';

import {NavigationBar} from './NavigationBar/NavigationBar';
import {NavigationBarProvider} from './NavigationBar/NavigationBar.provider';
import {TrialBanner} from './NavigationBar/PremiumTrial/TrialBanner';
import globalToasterRef from '../../../constants/globalToasterRef';
import {TOASTER_Z_INDEX} from '../../../constants/globalZIndexes';

const propTypes = {
  history: PropTypes.object,
  isPresentation: PropTypes.bool,
  isViewingFromAssignment: PropTypes.bool,
  main: PropTypes.node,
  skipToId: PropTypes.string,
};

const BaseLayout = ({
  history,
  isPresentation,
  isViewingFromAssignment,
  main,
  skipToId,
}) => {
  const mastheadDOM = document.getElementById('react-masthead');

  return (
    <>
      {!window.SHOULD_HIDE_MASTHEAD && mastheadDOM ?
        createPortal(
          <NavigationBarProvider>
            <NavigationBar />
          </NavigationBarProvider>
          , mastheadDOM
        ) : (
          <Toaster
            ref={globalToasterRef}
            __cssFor={{root: {zIndex: TOASTER_Z_INDEX}}}
          />
        )}

      <TrialBanner />

      <main id="main-contents">
        {main}
      </main>
    </>
  );
};

BaseLayout.propTypes = propTypes;
export default BaseLayout;
