const GRADING_MODE = {
  key: 'gradingMode',
  returnUrlKey: 'gradingModeReturnUrl',
};

/**
 * Lyceum grading page constants and types. Must be in sync with lyceum
 * https://github.com/newsela/lyceum/blob/main/src/grading/constants/index.js
 * @typedef {Object} GRADING_PAGE
 * @property {Object} GRADING_ACTIVITY_TYPES
 * @property {string} GRADING_ACTIVITY_TYPES.quiz
 */
const GRADING_PAGE = {
  GRADING_ACTIVITY_TYPES: {
    checksForUnderstanding: 'checks_for_understanding',
    quiz: 'quiz',
    powerWords: 'power_words',
    annotations: 'annotations',
    writePrompts: 'write_prompt',
  },
  GRADING_QUERY_PARAMS: {
    activityId: 'activityId',
    activityType: 'activityType',
    assignmentId: 'assignmentId',
    classroomId: 'classroom',
    collection: 'collectionId',
    legacyArticleId: 'legacyArticleId',
    lexileLevel: 'level',
    returnUrl: 'returnUrl',
    headerId: 'headerId',
    isCanvasMode: 'isCanvasMode',
    studentUniqueId: 'studentUniqueId',
    assignmentGroupId: 'assignmentGroupId',
  },
};

export {GRADING_MODE, GRADING_PAGE};

