import {get, includes, map} from 'lodash-es';

// TODO: This shim should be temporary, and exists because ArticleCards
// expect the legacy search format, and other dependencies need to be
// switched over first.

const alternateLanguages = (header) => {
  const obj = get(header, 'object', false);
  const translations = obj ? get(obj, 'translations', []) : get(header, 'translations', []);
  return map(translations, (translation) => {
    return translation.language;
  });
};

const convertToArticle = (header) => {
  // The search API can return both a 'basic' and 'full' format. Some of the
  // properties here we want to convert aren't available on the 'basic'
  // format, so let's check to make sure they exist and set some sane
  // defaults if we don't have them available.
  const features = get(header.object, 'features', []);
  const fullFormatProperties = {
    anchor_standards_tested: get(header.object, 'reading_skills', []),
    availableInSpanish: includes(alternateLanguages(header), 'es') || get(header.object, 'language') === 'es',
    collections: get(header.object, 'collections', []),
    features: features,
    hasLessonSparks: includes(features, 'lesson_sparks'),
    hasPowerWords: includes(features, 'power_words'),
    id: get(header.object, 'user_level_article_id'),
    content_id: get(header, 'content_id'),
    short_title: get(header.object, 'short_title'),
  };

  return {
    category: header.display_category,
    date_published: header.date_published,
    header_id: header.id,
    image: header.image,
    title: header.title,
    type: header.type,
    url: header.url,
    ...fullFormatProperties
  };
};

export {alternateLanguages, convertToArticle};
