import React from 'react';

import {
  get,
  find
} from 'lodash-es';
import PropTypes from 'prop-types';

import NewselaLogo from './assets/newsela-logo.png';
import Explainer from './components/Explainer/Explainer';
import {nonArticleContentStyle} from './styles';

import styles from './styles.scss';

const propTypes = {
  gradeLevelLock: PropTypes.number,
  noArticleContent: PropTypes.bool,
  isReadingLevelLocked: PropTypes.bool,
  defaultReadingLevel: PropTypes.number
};

export const READING_LEVELS = [
  {
    gradeLabel: 'College',
    lexileLabel: 'Original Level',
    gradeNumber: 13,
  },
  {
    gradeLabel: 'Grade 12',
    lexileLabel: 'Original Level',
    gradeNumber: 12,
  },
  {
    gradeLabel: 'Grade 11',
    lexileLabel: 'Original Level',
    gradeNumber: 11,
  },
  {
    gradeLabel: 'Grade 10',
    lexileLabel: '1181 - 1260 L',
    gradeNumber: 10,
  },
  {
    gradeLabel: 'Grade 9',
    lexileLabel: '1181 - 1260 L',
    gradeNumber: 9,
  },
  {
    gradeLabel: 'Grade 8',
    lexileLabel: '1110 - 1180 L',
    gradeNumber: 8,
  },
  {
    gradeLabel: 'Grade 7',
    lexileLabel: '1011 - 1109 L',
    gradeNumber: 7,
  },
  {
    gradeLabel: 'Grade 6',
    lexileLabel: '921 - 1010 L',
    gradeNumber: 6,
  },
  {
    gradeLabel: 'Grade 5',
    lexileLabel: '781 - 920 L',
    gradeNumber: 5,
  },
  {
    gradeLabel: 'Grade 4',
    lexileLabel: '621 - 780 L',
    gradeNumber: 4,
  },
  {
    gradeLabel: 'Grade 3',
    lexileLabel: '501 - 620 L',
    gradeNumber: 3,
  },
  {
    gradeLabel: 'Grade 2',
    lexileLabel: '300 - 500 L',
    gradeNumber: 2,
  },
];

const LevelLockingSection = ({
  gradeLevelLock,
  noArticleContent,
  isReadingLevelLocked,
  defaultReadingLevel
}) => {
  const assignedReadingLevel = gradeLevelLock || defaultReadingLevel;

  const renderAdjustLevelView = () => {
    return (
      <div className={styles.body}>
        <img alt="newsela logo" className={styles.newselaLogo} src={NewselaLogo}/>
        <span className={styles.newselaRecommendedText}>Newsela recommended</span>
      </div>
    );
  };

  const renderExplainer = () => {
    return (
      <Explainer
        gradeLevelLock={gradeLevelLock}
        isReadingLevelLocked={isReadingLevelLocked}
        defaultReadingLevel={defaultReadingLevel}
      />
    );
  };

  const renderLockedView = () => {
    const selection = find(READING_LEVELS, ['gradeNumber', assignedReadingLevel]);
    return (
      <div className={styles.selectionViewContainer}>
        <div
          className={styles.selection}
        >
          <i className={`fa fa-lg fa-lock ${styles.lockIcon}`} />
          <div data-qa-selector="grade_label">{get(selection, 'gradeLabel')}</div>
          <div data-qa-selector="lexile_label">{get(selection, 'lexileLabel')}</div>
        </div>
      </div>
    );
  };

  const renderContentWithNoArticles = () => {
    return (
      <div className={styles.body}>
        <span className={nonArticleContentStyle}>Content in this assignment only contain a single level</span>
      </div>
    );
  };

  return (
    <div className={styles.levelLockingContainer}>
      <label>Lexile Level</label>
      {
        assignedReadingLevel ? renderLockedView() : noArticleContent ?
          renderContentWithNoArticles() : renderAdjustLevelView()
      }
      {!noArticleContent && renderExplainer()}
    </div>
  );
};

LevelLockingSection.propTypes = propTypes;

export default LevelLockingSection;
