import {get} from 'lodash-es';

const ModalStoreKey = 'v2.ui.modal';

/**
 * @param {Object} state
 * @returns {String}
 */
export const getModalId = (state) => get(
  state, `${ModalStoreKey}.modalId`, ''
);

/**
 * @param {Object} state
 * @returns {Object} - this can take any shape according to the
 * specific modal.
 */
export const getModalOptions = (state) => get(
  state, `${ModalStoreKey}.options`, {}
);
