import * as types from 'modulesV2/constants';

const initialState = {
  isLoading: false,
  byId: {},
};

function assignments(state = initialState, action) {
  switch (action.type) {
    case types.CANVAS_APP_LOAD_ASSIGNMENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case types.CANVAS_APP_LOAD_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        byId: {
          ...state.byId,
          // Map assignments to both assignment id and content id
          // for backwards compatibility with existing Canvas assignments.
          [action.data.assignment_id]: action.data,
          [action.data.content_id]: action.data,
        },
      };

    case types.CANVAS_APP_LOAD_ASSIGNMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };

    default:
      return state;
  }
}

export default assignments;
