import {forEach, get, uniq, size} from 'lodash-es';
import {put} from 'redux-saga/effects';

import {genericModelActions} from 'static/three-oh/src/modules/actions';

/*
 * Notifications are scanned for StudentQuiz and ConstructedResponseAnswer IDs,
 * which are then sent to the GenericModelSaga for retrieval and later view model enrichment.
*/
export default function * enrichStudentWork(notifications) {
  let writeResponseIdsToEnrich = [];
  let studentQuizIdsToEnrich = [];
  let vocabularyActivityIdsToEnrich = [];

  // For each of the notifications, find the primary keys for StudentQuiz,
  // WriteResponse, and vocab.
  forEach(notifications, (notification) => {
    const writeResponseId = get(notification, 'data.response.id');
    if (writeResponseId) {
      writeResponseIdsToEnrich.push(writeResponseId);
    }
    const quizId = get(notification, 'data.quiz.id');
    if (quizId) {
      studentQuizIdsToEnrich.push(quizId);
    }
    const activityId = get(notification, 'data.activity.id');
    if (activityId && notification.type === 'student-vocabulary-completion') {
      vocabularyActivityIdsToEnrich.push(activityId);
    }
  });

  // Enrich WriteResponses
  writeResponseIdsToEnrich = uniq(writeResponseIdsToEnrich);
  if (size(writeResponseIdsToEnrich)) {
    const fetchWriteResponses = genericModelActions.fetchModelsByPrimaryKey({
      modelName: 'constructedResponseAnswer',
      primaryKeys: writeResponseIdsToEnrich
    });
    yield put(fetchWriteResponses);
  }

  // Enrich StudentQuizzes
  studentQuizIdsToEnrich = uniq(studentQuizIdsToEnrich);
  if (size(studentQuizIdsToEnrich)) {
    const fetchQuizzes = genericModelActions.fetchModelsByPrimaryKey({
      modelName: 'studentQuiz',
      primaryKeys: studentQuizIdsToEnrich
    });
    yield put(fetchQuizzes);
  }

  // Enrich VocabularyActivities
  vocabularyActivityIdsToEnrich = uniq(vocabularyActivityIdsToEnrich);
  if (size(vocabularyActivityIdsToEnrich)) {
    const fetchActivities = genericModelActions.fetchModelsByPrimaryKey({
      modelName: 'studentVocabularyActivity',
      primaryKeys: vocabularyActivityIdsToEnrich
    });
    yield put(fetchActivities);
  }
}
