import * as types from 'modulesV2/constants';

// TODO move this out of quiz and up a level
const initialState = {
  isRevealAnswer: false,
};

function quiz(state = initialState, action) {
  switch (action.type) {
    case types.SET_REVEAL_ANSWER:
      return {...state, isRevealAnswer: action.isRevealAnswer};
    default:
      return state;
  }
}

export default quiz;
