import React from 'react';

import {Anchor, ExternalLinkSVG, Icon} from '@newsela/angelou';
import PropTypes from 'prop-types';

import {
  descriptionCss,
  externalLinkIconCss,
  relativeContainerCss,
  rowAnchorCss,
  rowContainerCss,
  titleFlexRowCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/AppSwitcherMenu/PopoutRow/PopoutRow.styles';

const propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  dataQASelector: PropTypes.string.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  TitleComponent: PropTypes.node.isRequired,
};

const PopoutRow = ({TitleComponent, href, description, onKeyDown, label, dataQASelector}) => {
  return (
    <li className={relativeContainerCss}>
      <Anchor
        href={href}
        openInNewTab
        __cssFor={rowAnchorCss}
        ariaProps={{'aria-label': label}}
        dataProps={{'data-qa-selector': dataQASelector}}
        // @ts-expect-error typescript complaining about a generic usage of KeyboardEvent
        onKeyDown={onKeyDown}
      />

      <div className={rowContainerCss}>
        <div className={titleFlexRowCss}>
          {TitleComponent}
          <Icon SvgComponent={ExternalLinkSVG} __cssFor={externalLinkIconCss} />
        </div>

        <div className={descriptionCss}>{description}</div>
      </div>
    </li>
  );
};

PopoutRow.propTypes = propTypes;
PopoutRow.displayName = 'PopoutRow';
export {PopoutRow};
