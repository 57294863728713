import React from 'react';

import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';

export const SPECIALS_ROOT = '/specials';

const LazySpecialsContainer = Loadable({
  loader: () => import(
    /* webpackChunkName: "subject" */
    '../Subjects/Container'
  ),
  loading: () => null
});

const SpecialsRoute = (
  <Route
    key="Specials"
    path={`${SPECIALS_ROOT}/:subjectSlug/:rootTextSetId`}
    component={LazySpecialsContainer}
  />
);

export default SpecialsRoute;
