import {css} from 'emotion';

export const bookmarkButtonWrapperCss = css`
    display: inline-flex;
`;

export const minWidthCss = css`
    min-width: 84px;
`;


