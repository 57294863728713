import {constants} from '@newsela/angelou';
import {css} from 'emotion';

export const libraryMenuPopoutContainer = css({
  display: 'flex',
  flexDirection: 'row',
  gap: '0.5rem',
  borderRadius: '0.5rem',
  background: constants.colors.ui.white[50],
  boxShadow: '0px 2px 6px 0px rgba(29, 29, 29, 0.16)',
});

export const rightContainerCss = css({
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem 0 1.25rem 0',
  gap: '1rem',
});

export const leftContainerCss = css({
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem 0 1.25rem 0',
  maxHeight: '500px',
  gap: '1rem',
  overflowY: 'scroll',
});

export const newselaProductsVisibilityCss = (isTrialOrLite, visibilityBreakpoint) => {
  if (isTrialOrLite) {
    return css({
      display: 'flex',
    });
  }

  return css({
    [`@media (min-width: ${visibilityBreakpoint}px)`]: {
      display: 'none',
    },
  });
};
