import React from 'react';

import PropTypes from 'prop-types';

import withTrackEvent from 'static/three-oh/src/components/with/WithTrackEvent';

import AngelouProps from '../../propTypes/AngelouPropTypes';

import style from './styles.scss';

const propTypes = {
  /**
   * The Default PropTypes
   */
  ...AngelouProps,

  /**
   * Icon element to be rendered. Required for the icon
   * to appear.
   * If the icon does not show up you may want to try adding
   * a css class targeting the svg wrapper and setting a
   * width or height.
   */
  iconElement: PropTypes.element.isRequired,

  /**
   * If true there will be no tooltip on the icon
   */
  disableToolTip: PropTypes.bool,

  /**
   * Text for the tooltip
   */
  toolTipText: PropTypes.string,

  /**
   * Click event that will fire when the icon is clicked
   */
  onClick: PropTypes.func,

  /**
   * Determines if button or div will be rendered. Button is better
   * for accessibility.
   */
  renderAsButton: PropTypes.bool,

  /**
   * araia prop passed down to the icon/div
   */
  ariaLabel: PropTypes.string,

  /**
   * Give the icon a circular border.
   */
  border: PropTypes.bool,

  /**
   * this maps directly to the material ui tooltip css classes api
   * https://material-ui.com/api/tooltip/#css
   */
  toolTipClasses: PropTypes.shape({
    popper: PropTypes.string,
    popperInteractive: PropTypes.string,
    tooltip: PropTypes.string,
    touch: PropTypes.string,
    tooltipPlacementLeft: PropTypes.string,
    tooltipPlacementRight: PropTypes.string,
    tooltipPlacementTop: PropTypes.string,
    tooltipPlacementBottom: PropTypes.string,
  })
};

class Icon extends React.Component {
  state={
    mounted: false,
  }

  componentDidMount() {
    this.setMounted();
  }

  setMounted = () => {
    if (!this.state.mounted) {
      this.setState({mounted: true});
    }
  }

  render() {
    const {
      iconElement,
      onClick,
      additionalClassNames = [],
      renderAsButton,
    } = this.props;

    return onClick && renderAsButton ?
        (
          <button
            aria-label={this.props.ariaLabel || null}
            onClick={(e) => {
              onClick(e);
            }}
            className={`
            ${style.iconContainer}
            ${this.props.border ? style.border : ''}
            ${style.iconButton}
            ${additionalClassNames.join(' ')}
          `}
          >
            {iconElement}
          </button>
        ) : (
          <div
            aria-label={this.props.ariaLabel || null}
            onClick={this.props.onClick ? (e) => onClick(e) : null}
            className={`
            ${style.iconContainer}
            ${this.props.border ? style.border : ''}
            ${additionalClassNames.join(' ')}
          `}
          >
            {iconElement}
          </div>
        );
  }
}

Icon.propTypes = propTypes;
Icon.defaultProps = {
  disableToolTip: false,
  renderAsButton: false,
};

const IconWithTrackEvent = withTrackEvent(Icon);
export {Icon as default, IconWithTrackEvent};
