import React, {useMemo, useState} from 'react';

import {Button, ChevronDownSVG, ChevronUpSVG, Icon} from '@newsela/angelou';
import {useSelector} from 'react-redux';

import {getAdminButtonCss} from './AdminMenu.styles';
import {AdminMenuPopout} from './AdminMenuPopout';
import {getUserProperties} from '../../../../../../modules/selectors/userSelectors';
import {NavButtonWithPopout} from '../../shared/NavButtonWithPopout/NavButtonWithPopout';
import {getNavMenuButtonAriaProps} from '../../shared/NavButtonWithPopout/NavButtonWithPopout.utils';

const MENU_ID = 'nav-admin-menu';

const AdminMenu = () => {
  const {
    isStaff,
    hasFeature,
  } = useSelector((state) => getUserProperties(state));
  const canViewAdminConsole = hasFeature('can_view_admin_console');

  const [isOpen, setIsOpen] = useState(false);

  const buttonAriaProps = useMemo(
    () => getNavMenuButtonAriaProps({
      isOpen,
      id: MENU_ID,
    }), [isOpen]);

  if (!canViewAdminConsole && !isStaff) return null;

  const onClickButton = () => setIsOpen((isOpen) => !isOpen);

  return (
    <NavButtonWithPopout
      id={MENU_ID}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      position={{top: '4rem', right: 8}}
      rootCustomCss={{display: 'flex'}}
      ButtonComponent={(
        <Button
          onClick={onClickButton}
          flavor={Button.flavors.incognito}
          dataProps={{'data-qa-selector': 'global-nav-admin-menu'}}
          ariaProps={buttonAriaProps}
          __cssFor={getAdminButtonCss(isOpen)}
          __classNameFor={{root: MENU_ID}}
        >
          Admin

          {isOpen ? (
            <Icon SvgComponent={ChevronUpSVG} />
          ) : (
            <Icon SvgComponent={ChevronDownSVG} />
          )}
        </Button>
      )}
      PopoutComponent={<AdminMenuPopout />}
    />
  );
};

AdminMenu.displayName = 'AdminMenu';
export {AdminMenu};
