import {takeLatest, call, put} from 'redux-saga/effects';

import {LicenseApi} from 'static/three-oh/src/modules/apis';

import * as actions from '../actions/licenseActions';
import * as types from '../constants/licenseActionTypes';

function * getLicenses() {
  try {
    const data = yield call(LicenseApi.getForCurrentUser);
    yield put(actions.getLicenseSuccess(data));
  } catch (error) {
    yield put(actions.getLicenseFailure(error));
  }
}

export function * watchGetLicenses() {
  yield takeLatest(types.LICENSE_LOAD_REQUEST, getLicenses);
}
