import {constants, typographyStyles} from '@newsela/angelou';
import {css} from 'emotion';

import {
  NAVBAR_BREAKPOINTS
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';

const getPopOutContainerBackgroundColor = (isLite, isImpersonated) => {
  if (isImpersonated) {
    return constants.colors.ui.red[700];
  }

  if (isLite) {
    return constants.colors.ui.black[700];
  }

  return constants.colors.ui.core[500];
};

export const userPopoutContainerCss = (isLite, isImpersonated) => css({
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem',
  gap: '0.25rem',
  alignItems: 'flex-start',
  borderRadius: '0.5rem 0.5rem 0 0',
  background: getPopOutContainerBackgroundColor(isLite, isImpersonated),

  [`@media (max-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
    borderRadius: 0,
  },
});

export const userNameTextCss = css({
  color: constants.colors.ui.white[50],
  ...typographyStyles.fontSize.heading4,
  fontWeight: 700,
  lineHeight: '1.5rem',
  textAlign: 'left',
  margin: 0,
});

export const userEmailText = css({
  lineHeight: '1.2rem',
  textAlign: 'left',
  wordBreak: 'break-all',
  color: constants.colors.ui.white[50],
  ...typographyStyles.fontSize.textM,
  ...typographyStyles.fontFamily.circular,
});
