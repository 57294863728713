import {combineReducers} from 'redux';

import currentEstimatedGrades from './currentEstimatedGradesReducer';
import studentList from './studentListReducer';
import studentReadingLevels from './studentReadingLevelsReducer';

const binderStudentsReducer = combineReducers({
  currentEstimatedGrades,
  studentList,
  studentReadingLevels
});

export default binderStudentsReducer;
