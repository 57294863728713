import APIFetcher from '../APIFetcher';

class ReadingSessionApi {
  static get(queryParams) {
    const params = APIFetcher.stringifyParams(queryParams);
    return APIFetcher.get(`reading-session/?${params}`);
  }

  static post(data) {
    const payload = {
      article: {
        id: data.articleId,
      },
      platform: 'web',
      session_ended: data.dateViewed,
      session_started: data.dateViewed,
      timeline: [],
    };
    return APIFetcher.post('reading-session/', {payload});
  }
}

export default ReadingSessionApi;
