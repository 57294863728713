import {takeEvery, call, put} from 'redux-saga/effects';

import {
  getContentSuccess,
  getContentFailure
} from 'modulesV2/actions';
import * as types from 'modulesV2/constants';
import {ContentApi} from 'static/three-oh/src/modules/apis';

function * getContent(action) {
  try {
    const contentResponse = yield call(ContentApi.get, action.contentId, action.queryParams);
    const contentData = contentResponse.data;
    yield put(getContentSuccess(contentData));
  } catch (error) {
    yield put(getContentFailure(error));
  }
}

export function * watchGetContent() {
  yield takeEvery(types.GET_CONTENT_REQUEST, getContent);
}
