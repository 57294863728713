import {keyBy} from 'lodash-es';

import * as types from 'modulesV2/constants';

export default function students(state = {}, action) {
  switch (action.type) {
    case types.CANVAS_APP_LOAD_ARTICLE_HEADER_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(action.data, 'id'),
        }
      };
    default:
      return state;
  }
}
