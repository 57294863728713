import {typographyStyles} from '@newsela/angelou';
import {css, cx} from 'emotion';

import {menuItemCss, menuItemLinkCss} from '../DropDownMenuItem/styles';


const hoverColor = menuItemCss[':hover'].backgroundColor;

export const rootStyle = css`
  &&{
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 0;
    :hover {
      background-color: transparent;
    }
    :focus {
      background-color: transparent;
      >*:nth-child(2) {
        background-color: ${hoverColor};
      }
    }
    >* {
      box-sizing: ${menuItemCss.boxSizing};
      padding: ${menuItemCss.padding};

    }
  }
`;

export const menuLabelStyle = cx([
  css`
    &&{
      :hover {
        cursor: default;
      }
      font-family: Circular;
      width: 100%;
      color: #6b6d70;
      // Reset margin below because we currently have a global rule for labels, see:
      // https://github.com/newsela/newsela/blob/1b40520cbe/newsela/static/three-oh/src/routes/Assignment/components/TeacherAssignment/styles.scss#L97
      margin-bottom: 0px;
    }
  `,
  css(typographyStyles.heading6Obj)
]);

export const firstSectionItemStyle = cx([
  css`
    width: 100%;
    :hover {
      background-color: #E7F1FF;
    }
  `,
  css(typographyStyles.heading5Obj)
]);

export const rootLinkStyle = css({
  '&&': {
    ':focus': {
      '>*:nth-child(2)': menuItemLinkCss[':focus']
    }
  }
});
