import {constants, typographyStyles} from '@newsela/angelou';
import {css} from 'emotion';

export const suggestionsUnorderedListCss = css({
  listStyleType: 'none',
  margin: 0,
  padding: 0,
});

export const searchSuggestionAnchorCss = {
  root: {
    margin: 0,
    padding: '0.5rem 1rem',
    textDecoration: 'none',
    color: constants.colors.ui.neutral[700],
    ...typographyStyles.lineHeight.textMLineHeights,
    width: '100%',
    whiteSpace: 'pre-wrap',

    ':hover, :focus': {
      background: '#F1F7FF'
    },

    '> div': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '0.5rem',
      textWrap: 'nowrap',
    }
  }
};

export const essentialQuestionTextCss = css({
  ...typographyStyles.fontSize.textXS,
  fontWeight: 400,
  lineHeight: '0.9975rem',
  color: constants.colors.ui.neutral[700],
});

export const searchSuggestionIconCss = {
  root: {
    width: '1.5rem',
    height: '1.5rem',
  }
};
