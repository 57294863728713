import {takeLatest, call, fork, put, take, select, all} from 'redux-saga/effects';

import {UserApi, SessionApi, TeacherApi, PrincipalApi, DistrictAdminApi, StudentApi, StudentClassroomApi} from 'static/three-oh/src/modules/apis';

import {createNewUser} from '../../../../modules/actions/userActions';
import * as userActionTypes from '../../../../modules/constants/userActionTypes';
import {userSelectors} from '../../../../modules/selectors';
import {signUpActions as actions} from '../actions';
import {signUpActionTypes} from '../constants';

export function * createTeacher(user, role, additionalUserData) {
  const teacherPayload = {
    user_id: user.id,
    role: role,
  };
  if (additionalUserData?.school) {
    teacherPayload.school = additionalUserData.school;
  }
  if (additionalUserData?.invitation) {
    teacherPayload.invitation = additionalUserData.invitation;
  }
  return yield call(TeacherApi.createTeacher, teacherPayload);
}

export function * createPrincipal(user, role, additionalUserData) {
  const principalPayload = {
    user_id: user.id,
    role: role,
  };
  if (additionalUserData?.school) {
    principalPayload.school_id = additionalUserData.school.id;
  }
  if (additionalUserData?.invitation) {
    principalPayload.invitation = additionalUserData.invitation;
  }
  // all principals are also teachers
  return yield all([
    call(PrincipalApi.createPrincipal, principalPayload),
    call(createTeacher, user, 'teacher', additionalUserData),
  ]);
}

export function * createDistrictAdmin(user, role, additionalUserData) {
  const districtadminPayload = {
    user_id: user.id,
    role: role,
  };
  if (additionalUserData?.district) {
    districtadminPayload.district_id = additionalUserData.district.id;
  }
  if (additionalUserData?.invitation) {
    districtadminPayload.invitation = additionalUserData.invitation;
  }
  return yield call(DistrictAdminApi.createDistrictAdmin, districtadminPayload);
}

export function * createCaregiver(user) {
  const caregiverPayload = {
    user_id: user.id,
    role: 'parent',
  };
  return yield call(TeacherApi.createTeacher, caregiverPayload);
}

export function * createStudent(user, role) {
  const studentPayload = {
    user_id: user.id,
    role: role,
  };
  return yield call(StudentApi.createStudent, studentPayload);
}

export function * addStudentToClassroom(student, classroomId) {
  const studentClassroomPayload = {
    classroom_id: classroomId,
    student_id: student.id
  };

  return yield call(StudentClassroomApi.createStudentClassroom, studentClassroomPayload);
}


export function * createUser(userInfo, role, additionalUserData) {
  try {
    yield put(actions.createUserRequest());

    yield put(createNewUser(userInfo, role, additionalUserData));

    // Wait for user fetch response.
    const response = yield take([
      userActionTypes.USER_LOAD_SUCCESS,
      userActionTypes.USER_LOAD_FAILURE
    ]);

    /* This old redux-saga version doesn't return the `yield take` mocked response in Jest,
      so we need to keep this condition just for safety until we get rid of redux-saga or update it
    */
    if (response) {
      const {type, data} = response;

      // Detects when the API call fails and yields the USER_LOAD_FAILURE action
      if (type === userActionTypes.USER_LOAD_FAILURE) {
        yield put(actions.getSignUpFailure(data));
        return;
      }
    }

    // now get user
    const user = yield select(userSelectors.getUser);

    const credentials = {
      username: user.username,
      password: userInfo.password
    };

    // authenticate user
    yield call(SessionApi.signIn, credentials);

    if (role === 'teacher') {
      yield call(createTeacher, user, role, additionalUserData);
    } else if (role === 'principal') {
      yield call(createPrincipal, user, role, additionalUserData);
    } else if (role === 'district_admin') {
      yield call(createDistrictAdmin, user, role, additionalUserData);
    } else if (role === 'caregiver') {
      // in order to add address, user must exist and have a userProfile
      const updateUser = {
        ...user,
        ...additionalUserData
      };
      yield put(createNewUser(updateUser));

      yield take(userActionTypes.USER_LOAD_SUCCESS);

      yield call(createCaregiver, user);
    } else if (role === 'student') {
      // in order to add address, user must exist and have a userProfile
      const updateCurrentUser = {
        ...user,
      };
      updateCurrentUser.address = additionalUserData.address;
      if (additionalUserData.birthdate) {
        updateCurrentUser.birthdate = additionalUserData.birthdate;
      }
      yield put(createNewUser(updateCurrentUser));

      yield take(userActionTypes.USER_LOAD_SUCCESS);

      const student = yield call(createStudent, user, role);

      if (additionalUserData.classroomId) {
        yield call(addStudentToClassroom, student, additionalUserData.classroomId);
      }
    }

    yield put(actions.getSignUpSuccess());
  } catch (error) {
    yield put(actions.getSignUpFailure(error));
  }
}

export function * sendVerificationEmail(user) {
  try {
    yield put(actions.createEmailRequest());

    user.send_verification_email = true;
    yield call(UserApi.persistUser, user);

    yield put(actions.getSignUpSuccess());
  } catch (error) {
    yield put(actions.getSignUpFailure(error));
  }
}

export function * createUserRole(user, role, additionalUserData) {
  try {
    yield put(actions.createRoleCreationRequest());

    if (role === 'teacher') {
      yield call(createTeacher, user, role, additionalUserData);
    } else if (role === 'principal') {
      yield call(createPrincipal, user, role, additionalUserData);
    } else if (role === 'district_admin') {
      yield call(createDistrictAdmin, user, role, additionalUserData);
    } else if (role === 'caregiver') {
      yield call(createCaregiver, user);
    } else if (role === 'student') {
      let student = null;
      if (!user[role]) {
        student = yield call(createStudent, user, role);
      } else {
        student = user[role];
      }

      if (additionalUserData?.classroomId) {
        yield call(addStudentToClassroom, student, additionalUserData.classroomId);
      }
    }
    yield put(actions.getSignUpRoleCreationSuccess());
  } catch (error) {
    yield put(actions.getSignUpFailure(error));
  }
}

export function * updateUser(user, additionalUserData) {
  try {
    yield put(actions.createUpdateUserRequest());

    if (user?.teacher?.role === 'parent') {
      // in order to add address, user must exist and have a userProfile
      const updateUser = {
        ...user,
        ...additionalUserData
      };
      yield put(createNewUser(updateUser));

      yield take(userActionTypes.USER_LOAD_SUCCESS);
    } else if (user['student']) {
      // in order to add address, user must exist and have a userProfile
      const updateCurrentUser = {
        ...user,
      };
      updateCurrentUser.address = additionalUserData.address;
      if (additionalUserData.birthdate) {
        updateCurrentUser.birthdate = additionalUserData.birthdate;
      }
      yield put(createNewUser(updateCurrentUser));

      yield take(userActionTypes.USER_LOAD_SUCCESS);
    }

    yield put(actions.getSignUpSuccess());
  } catch (error) {
    yield put(actions.getSignUpFailure(error));
  }
}

export function * watchInitSignUp() {
  yield takeLatest(signUpActionTypes.INITIALIZE_USER_CREATION, function * init(action) {
    const userInfo = action.payload.userInfo;
    const role = action.payload.role;
    const additionalUserData = action.payload.additionalUserData;
    yield fork(createUser, userInfo, role, additionalUserData);
  });
}

export function * watchInitSendVerificationEmail() {
  yield takeLatest(signUpActionTypes.INITIALIZE_SEND_VERIFICATION_EMAIL, function * init(action) {
    const user = action.payload.user;
    yield fork(sendVerificationEmail, user);
  });
}

export function * watchInitRoleCreation() {
  yield takeLatest(signUpActionTypes.INITIALIZE_ROLE_CREATION, function * init(action) {
    const user = action.payload.user;
    const role = action.payload.role;
    const additionalUserData = action.payload.additionalUserData;
    yield fork(createUserRole, user, role, additionalUserData);
  });
}

export function * watchInitUpdateUser() {
  yield takeLatest(signUpActionTypes.INITIALIZE_UPDATE_USER, function * init(action) {
    const user = action.payload.user;
    const additionalUserData = action.payload.additionalUserData;
    yield fork(updateUser, user, additionalUserData);
  });
}


