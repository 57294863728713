import {constants, typographyStyles} from '@newsela/angelou';
import {css} from 'emotion';


const smallText = typographyStyles.textXSObj;
const heading5 = typographyStyles.heading5Obj;

const standard = css`
  margin-bottom: 0.5rem;
  white-space: nowrap;
  margin-right: 8px;
  line-height: 1;
`;

const tooltip = css`
  font-weight: bold;
  line-height: normal;
`;

const tooltipNumber = css`
  color: ${constants.colors.ui.green[300]};
  ${heading5}
`;

const tooltipDescription = css`
  ${smallText}
`;

export default {
  standard,
  tooltip,
  tooltipNumber,
  tooltipDescription,
};
