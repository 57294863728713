import {isNull, find} from 'lodash-es';
import {delay} from 'redux-saga';
import {takeLatest, takeEvery, call, put, take} from 'redux-saga/effects';

import {RosterApi} from 'static/three-oh/src/modules/apis';
import * as actions from 'static/three-oh/src/modulesV2/actions';
import * as types from 'static/three-oh/src/modulesV2/constants';


function * pollRosterSyncInfo(id, provider) {
  try {
    const response = yield call(RosterApi.getById, id);
    if (isNull(response.data['time_completed'])) {
      // Keep polling every 5 seconds until the sync is completed.
      if (provider) {
        yield put(actions.rosterSyncInProgress(provider, id));
      }
      yield call(delay, 3000);
      yield call(pollRosterSyncInfo, id, provider);
    }
    yield call(fetchAllRosterSyncInfo);  // eslint-disable-line
    // Communicate with Angular controller via deprecated events... .
    const event = document.createEvent('Event');
    event.initEvent('rosterSyncCompleted', false, true);
    // Slap provider property right onto the event object, as there is no
    // good way to pass data.
    event.provider = response.provider;
    window.dispatchEvent(event);
  } catch (e) {
    // pass.
  }
}

/**
 * Fetches all sync info for all the roster providers available to the user.
 */
function * fetchAllRosterSyncInfo() {
  try {
    const response = yield call(RosterApi.getAll);
    yield put(actions.requestRosterSyncInfoSuccess(response.data));
    const syncingItem = find(response.data, (item) => isNull(item['time_completed']));
    if (syncingItem) {
      // If there is at least one item without time_completed,
      // start polling.
      yield call(pollRosterSyncInfo, syncingItem.id);
    } else {
      // All items are synced. Exit.
    }
  } catch (e) {
    yield put(actions.requestRosterSyncFailure('Failed fetch roster info '));
  }
}

function * markSyncEventAsSeen(action) {
  try {
    yield call(RosterApi.markAsSeen, {id: action.id});
    // Fetch updated sync info.
    yield call(fetchAllRosterSyncInfo);
  } catch (e) {
    // Ignore failures.
  }
}

function * syncRoster(action) {
  try {
    // ==== Dispatch "rosterSyncStarted" event for Angular to catch ===
    const event = document.createEvent('Event');
    event.initEvent('rosterSyncStarted', false, true);
    // Slap provider property right onto the event object, as there is no
    // good way to pass data.
    event.provider = action.authProvider;
    window.dispatchEvent(event);
    // ==== ====
    const options = {};
    if (action.classrooms) {
      options.classrooms = action.classrooms;
    }
    const response = yield call(RosterApi.sync, action.authProvider, options);
    const event_id = response.data.id;
    yield put(actions.requestRosterSyncSuccess(event_id));
    yield call(pollRosterSyncInfo, event_id, action.authProvider);
  } catch (e) {
    yield put(actions.requestRosterSyncInfoFail(`Failed to sync roster: ${action.authProvider}`));
  }
}

function * requestRosterClassrooms(action) {
  try {
    const response = yield call(RosterApi.getClassrooms, action.authProvider);
    yield put(actions.requestRosterClassroomsSuccess(action.authProvider, response.data));
  } catch (error) {
    yield put(actions.requestRosterClassroomsFailure(action.authProvider, error));
  }
}

function * requestRosterDetails(action) {
  if (!action.authProvider || action.authProvider === 'forms') {
    // Do nothing when the provider is Newsela (forms) or when provider is not present.
    return;
  }
  try {
    const response = yield call(RosterApi.getRosterDetails, action.authProvider);
    yield put(actions.requestRosterDetailsSuccess(action.authProvider, response.data));
  } catch (error) {
    yield put(actions.requestRosterDetailsFailure(action.authProvider, error));
  }
}

export function * watchRequestRosterSync() {
  yield takeLatest(types.SYNC_ROSTER_REQUEST, syncRoster);
}

export function * watchRequestRosterInfoSync() {
  yield takeLatest(types.REQUEST_ALL_ROSTER_SYNC_INFO, fetchAllRosterSyncInfo);
}

export function * watchMarkSyncEventAsSeen() {
  while (true) {
    const action = yield take(types.MARK_ROSTER_INFO_AS_SEEN);
    yield call(markSyncEventAsSeen, action);
  }
}

export function * watchRequestRosterClassrooms() {
  yield takeLatest(types.REQUEST_ROSTER_CLASSROOMS, requestRosterClassrooms);
}

export function * watchRequestRosterDetails() {
  // When a user has more than one authentication and roster providers,
  // using `take` or `takeLatest` is not going to work properly, as
  // Sagas cancels all the actions of the same type but the last one.
  // Using takeEvery doesn't have that limitation.
  yield takeEvery(types.REQUEST_ROSTER_DETAILS, requestRosterDetails);
}
