import APIFetcher from '../APIFetcher';

class ConstructedResponsePromptApi {
  static get(queryParams) {
    const params = APIFetcher.stringifyParams(queryParams);
    return APIFetcher.get(`prompt/?${params}`);
  }
}

export default ConstructedResponsePromptApi;
