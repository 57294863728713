import {combineReducers} from 'redux';

import articleHeaders from './articleHeadersReducer';
import articleWords from './articleWordsReducer';
import assignments from './assignmentsReducer';
import reviewPageStateReducer from './reviewPageStateReducer';
import studentQuizzes from './studentQuizReducer';
import students from './studentsReducer';

export default combineReducers({
  students,
  assignments,
  articleHeaders,
  articleWords,
  studentQuizzes,
  page: reviewPageStateReducer,
});
