import React, {useState} from 'react';

import {truncate} from 'lodash-es';
import moment from 'moment';
import PropTypes from 'prop-types';

import TrackedRawHtmlSection from 'static/three-oh/src/routes/Unit/components/TextSetDescription/components/TrackedRawHtmlSection/TrackedRawHtmlSection';

import styles from './styles.scss';

const propTypes = {
  bundle: PropTypes.shape({
    description: PropTypes.string,
  }),
};

const DescriptionDrawer = ({bundle}) => {
  const descriptionLength = bundle.description.length;
  const characterLimit = 400;
  const {description, descriptionText, descriptionToggle} = styles;
  const [expanded, setExpanded] = useState(descriptionLength <= characterLimit);

  const emptyDescription = (
    <div className={description}>
      {bundle.created_by && bundle.created_by.name && (
        <p>Created By: {bundle.created_by && bundle.created_by.name}</p>
      )}
      <p>Date Created: {moment(bundle.date_created).format('MM/DD/YYYY')}</p>
      <p>Date Modified: {moment(bundle.date_modified).format('MM/DD/YYYY')}</p>
      <div className={descriptionText} />
    </div>
  );

  const renderExpanded = (bundle) => {
    let seeLessLink = null;
    if (descriptionLength > characterLimit) {
      seeLessLink = (
        <button
          aria-expanded="true"
          className={descriptionToggle}
          onClick={() => setExpanded(false)}
        >
          See Less
        </button>
      );
    }
    return (
      <div className={description}>
        {bundle.created_by && bundle.created_by.name && (
          <p>Created By: {bundle.created_by && bundle.created_by.name}</p>
        )}
        <p>Date Created: {moment(bundle.date_created).format('MM/DD/YYYY')}</p>
        <p>Date Modified: {moment(bundle.date_modified).format('MM/DD/YYYY')}</p>
        <div
          className={descriptionText}
          dangerouslySetInnerHTML={// eslint-disable-line react/no-danger
            {__html: bundle.description}
          }
        />
        {seeLessLink}
      </div>
    );
  };

  const renderCollapsed = (bundle) => {
    const truncatedDescription = truncate(bundle.description, {
      length: characterLimit,
      separator: ' ',
    });
    return (
      <div className={description}>
        {bundle.created_by && bundle.created_by.name && (
          <p>Created By: {bundle.created_by && bundle.created_by.name}</p>
        )}
        <p>Date Created: {moment(bundle.date_created).format('MM/DD/YYYY')}</p>
        <p>Date Modified: {moment(bundle.date_modified).format('MM/DD/YYYY')}</p>
        <TrackedRawHtmlSection
          html={truncatedDescription}
          className={descriptionText}
        />
        <button
          aria-expanded="false"
          className={descriptionToggle}
          onClick={() => setExpanded(true)}
        >
          See More
        </button>
      </div>
    );
  };

  if (descriptionLength === 0) {
    return emptyDescription;
  } else if (expanded) {
    return renderExpanded(bundle);
  } else {
    return renderCollapsed(bundle);
  }
};

DescriptionDrawer.propTypes = propTypes;
export default DescriptionDrawer;
