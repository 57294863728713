import React from 'react';

import PropTypes from 'prop-types';

import divider from './assets/or.png';

import styles from './styles.scss';

const propTypes = {
  leftCard: PropTypes.element,
  rightCard: PropTypes.element,
};

class FocusableCardSet extends React.PureComponent {
  constructor(props) {
    super(props);
    this.setFocus = this.setFocus.bind(this);
    this.state = {
      currentlyFocusedCard: null,
    };
  }

  setFocus(id) {
    this.setState({currentlyFocusedCard: id});
  }

  render() {
    const inheritedProperties = {
      setFocus: this.setFocus,
      currentlyFocusedCard: this.state.currentlyFocusedCard
    };

    return (
      <div className={styles.container}>
        {React.cloneElement(this.props.leftCard, inheritedProperties)}
        <div className={styles.divider}>
          <img className={styles.or} src={divider} role="presentation" />
        </div>
        {React.cloneElement(this.props.rightCard, inheritedProperties)}
      </div>
    );
  }
}

FocusableCardSet.propTypes = propTypes;
export default FocusableCardSet;
