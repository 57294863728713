const determineAdvisoryState = (members = []) => {
  // utility for CAT, being deprecated
  const containsCAT = members.some((member) => member.content.veil?.reason === 'DISTRICT_ADVISORY');
  const containsUnconsentedCAT = members.some((member) => member.content.veil?.reason === 'DISTRICT_ADVISORY' && !member.content.user_content_advisory_overwrite);
  const unconsentedCATContent = members.filter((member) => member.content.veil?.reason === 'DISTRICT_ADVISORY' && !member.content.user_content_advisory_overwrite);
  const advisoryState = {
    containsCAT,
    containsUnconsentedCAT,
    unconsentedCATContent
  };
  return advisoryState;
};

export default determineAdvisoryState;
