import {
  get,
  some,
  filter,
  sortBy,
  pick,
  reduce,
  max,
  toArray,
  keyBy,
  forEach,
  keys,
  parseInt,
  map,
  difference,
  compact,
  uniq
} from 'lodash-es';
import {createSelectorCreator, defaultMemoize} from 'reselect';
import shallowequal from 'shallowequal';

import {getStudentsByClassroom} from 'static/three-oh/src/modules/selectors/classroomSelectors';
import {applyTo, applier} from 'utils/lodashHelpers';

// getNotificationsForAssignment creates a new array instance per invocation, but often times the array contains
// the exact same data over and over again. By performing a shallow comparison of the arrays contents, we can better
// determine whether those selectors which have a dependency on notifications need to re-calculate.
const createShallowEqualsSelector = createSelectorCreator(defaultMemoize, shallowequal);

const getConstructedResponseAnswersIndexedById = (state) => {
  return get(state, 'models.constructedResponseAnswer', null);
};

const getStudentQuizzesIndexedById = (state) => {
  return get(state, 'models.studentQuiz', null);
};

const getVocabularyActivitiesIndexedById = (state) => {
  return get(state, 'models.studentVocabularyActivity', null);
};

const getWorkDetailsVisibility = (state, headerId, classroomId) => {
  if (headerId && classroomId) {
    return get(state, ['binderWork', 'workDetailsVisibility', `${headerId},${classroomId}`], null);
  } else {
    return get(state, ['binderWork', 'workDetailsVisibility'], null);
  }
};

const getNotificationsForAssignment = (state, headerId, classroomId) => {
  const allNotifications = get(state, 'binderWork.notifications.items', null);

  const isHeaderClassroomMatch = (notification) => {
    const headerMatch = get(notification, 'data.header.id') === headerId;
    if (!headerMatch) { return false; }
    const classroomMatch = some(notification.classrooms, {id: classroomId});
    return headerMatch && classroomMatch;
  };

  const isStudentWork = (notification) => {
    switch (notification.type) {
      case 'student-quiz-submission':
      case 'student-write-response':
      case 'student-annotation':
      case 'student-article-view':
      case 'student-vocabulary-completion':
        return true;
      default:
        return false;
    }
  };

  const notificationsForAssignment = filter(allNotifications, (notification) => {
    return isHeaderClassroomMatch(notification) && isStudentWork(notification);
  });

  const sorted = sortBy(notificationsForAssignment, 'id');

  return sorted;
};

const getReadingTimesForAssignment = (state, headerId, classroomId) => {
  return get(state, ['binderWork', 'readingTimesByHeaderClassroom', headerId + ',' + classroomId], null);
};

const getStudentQuizzesForAssignment = createShallowEqualsSelector(
  [
    getNotificationsForAssignment,
    getStudentQuizzesIndexedById,
  ],
  (notifications, studentQuizzes) => {
    const quizIds = applyTo(notifications)(
      applier(map)('data.quiz.id'),
      applier(compact)(),
      applier(uniq)()
    );
    return pick(studentQuizzes, quizIds);
  }
);

const getWriteResponsesForAssignment = createShallowEqualsSelector(
  [
    getNotificationsForAssignment,
    getConstructedResponseAnswersIndexedById,
  ],
  (notifications, writeResponses) => {
    const responseIds = applyTo(notifications)(
      applier(map)('data.response.id'),
      applier(compact)(),
      applier(uniq)()
    );
    return pick(writeResponses, responseIds);
  }
);

const getVocabularyActivitiesForAssignment = createShallowEqualsSelector(
  [
    getNotificationsForAssignment,
    getVocabularyActivitiesIndexedById,
  ],
  (notifications, activities) => {
    const activityIds = applyTo(notifications)(
      applier(map)('data.activity.id'),
      applier(compact)(),
      applier(uniq)()
    );
    return pick(activities, activityIds);
  }
);

const rollUpNotificationsIntoWorkDetails = (notifications) => {
  // Combine notifications that match a common student + article into a single object representing the cumulative work.
  const studentWork = reduce(notifications, (accumulator, notification) => {
    const article = get(notification, 'data.article', {});
    const student = get(notification, 'data.user.student', {});
    const header = get(notification, 'data.header', {});
    const key = `${student.id},${article.id}`;

    let currentRow = accumulator[key];
    if (!currentRow) {
      currentRow = accumulator[key] = {
        student,
        article,
        header,
        viewedArticleOnly: true,
        annotationCount: 0,
      };
    }

    currentRow.lastActivity = max([currentRow.lastActivity, notification.date_created]);

    if (notification.type !== 'student-article-view') {
      currentRow.viewedArticleOnly = false;
    }

    if (notification.type === 'student-annotation') {
      currentRow.annotationCount += 1;
    } else if (notification.type === 'student-quiz-submission') {
      currentRow.studentQuiz = notification.data.quiz;
    } else if (notification.type === 'student-write-response') {
      currentRow.writeResponse = notification.data.response;
    } else if (notification.type === 'student-vocabulary-completion') {
      currentRow.vocabularyActivity = notification.data.activity;
    }

    return accumulator;
  }, {});

  return toArray(studentWork);
};

const getEnrichedWorkDetails = (
  notifications,
  studentQuizzes,
  writeResponses,
  readingTimes,
  activities,
  students
) => {
  let workDetails = rollUpNotificationsIntoWorkDetails(notifications);
  const studentsById = keyBy(students, 'id');

  // The workDetails only contains IDs of students, quizzes,
  // vocabulary activities and write responses at this juncture.
  // We need to build out or "enrich" the details for each object.
  forEach(workDetails, (row) => {
    row.student = get(studentsById, row.student.id, row.student);

    if (row.studentQuiz) {
      row.studentQuiz = get(studentQuizzes, row.studentQuiz.id, row.studentQuiz);
    }

    if (row.writeResponse) {
      row.writeResponse = get(writeResponses, row.writeResponse.id, row.writeResponse);
    }

    if (row.vocabularyActivity) {
      row.vocabularyActivity = get(activities, row.vocabularyActivity.id, row.vocabularyActivity);
    }

    // Lets' also add the reading time (if available) while we're at it.
    row.readingTime = get(readingTimes, `${row.student.id},${row.article.id}`, null);
  });

  // Add placeholder stubs for students who have not done any work towards an assignment.
  // The workDetails should list all students in the classroom for a particular assignment,
  // even the students who haven't completed any work towards the assignment.
  const studentIdsInClassroom = keys(studentsById).map(parseInt);
  const studentIdsWithCompletedWork = map(workDetails, 'student.id');
  const studentIdsWithoutWork = difference(studentIdsInClassroom, studentIdsWithCompletedWork);
  const stubbedWorkItems = map(studentIdsWithoutWork, (studentId) => {
    return {student: get(studentsById, studentId)};
  });
  workDetails = workDetails.concat(stubbedWorkItems);

  return workDetails;
};


// This function returns a new copy of a selector, each copy will be memoizied independently of the others.
const generateGetEnrichedWorkDetailsSelector = () => {
  return createShallowEqualsSelector(
    [
      getNotificationsForAssignment,
      getStudentQuizzesForAssignment,
      getWriteResponsesForAssignment,
      getReadingTimesForAssignment,
      getVocabularyActivitiesForAssignment,
      (state, headerId, classroomId) => getStudentsByClassroom(state)[classroomId],
    ],
    getEnrichedWorkDetails
  );
};

export {
  getWorkDetailsVisibility,
  generateGetEnrichedWorkDetailsSelector
};
