import {typographyStyles} from '@newsela/angelou';
import {css} from 'emotion';

export const trialBannerCss = (background) => ({
  root: {
    background,
    height: '40px',

    'div:has(svg)': {
      display: 'inline-flex',
      alignItems: 'center'
    }
  }
});

export const trialTextCss = css({
  ...typographyStyles.fontFamily.circular,

  fontSize: '16px',
  margin: 0,
  color: 'white'
});
