import React from 'react';

import {Button} from '@newsela/angelou';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import UNSTABLE_InputField from 'static/three-oh/src/components/InputField/InputField';
import {classroomActions} from 'static/three-oh/src/modules/actions';

import styles from './styles.scss';


const propTypes = {
  actions: PropTypes.object,
  formCallback: PropTypes.func,
  joinedClassroom: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null, false])]),
  studentID: PropTypes.number,
};

class StudentJoinClassForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      classroomCode: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.joinedClassroom) {
      this.props.formCallback(nextProps.joinedClassroom);
    }
  }

  errorStateMessage() {
    if (this.props.joinedClassroom === false) {
      return 'Invalid class code.';
    }
  }

  handleInputChange(e) {
    this.setState({classroomCode: e.target.value});
  }

  render() {
    const inputField = (
      // eslint-disable-next-line
      <UNSTABLE_InputField
        type="text"
        label="Input Class ID:"
        name="student-join-class-input"
        placeholder="Ex. 6LAKZM"
        value={this.state.classroomCode}
        onChange={(e) => this.handleInputChange(e)}
        errorMessage={this.errorStateMessage()}
      />
    );
    return (
      <div className={styles.joinClassForm}>
        <label>Input Class ID:</label>
        <div className={styles.inputWrapper}>
          {inputField}
        </div>
        <Button
          dataProps={{
            'data-qa-selector': 'join_class'
          }}
          ariaProps={{
            'aria-label': 'Join Class'
          }}
          onClick={() => this.props.actions.joinClassroom(this.props.studentID, this.state.classroomCode)}
        >
          Join Class
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    joinedClassroom: state.classrooms.joinedClassroom,
    studentID: state.user.student.id,
  };
};

const actions = {
  joinClassroom: classroomActions.joinClassroom,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

StudentJoinClassForm.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(StudentJoinClassForm);
