export const SIX_MONTH_INDIVIDUAL_TRIAL_CAMPAIGN_SLUG = 'ela-sci-ss-h1-2025';

export const PREMIUM_TRIAL_CAMPAIGN_SLUG = 'ela-sci-ss-trial-2024';

export const PREMIUM_TRIAL_CAMPAIGN_SLUG_LAST_YEAR = 'ela-sci-ss-trial-2023';

export const NCE_ALL_ACCESS_TRIAL_CAMPAIGN_TITLE = 'NCE-2024-180-AllAccess';
export const NCE_TRIAL_CAMPAIGN_TITLES = [
  'NCE-2024-180-Science',
  'NCE-2024-180-ELA',
  'NCE-2024-180-SS',
  NCE_ALL_ACCESS_TRIAL_CAMPAIGN_TITLE,
];

export const ACTIVE_TRIAL_LICENSES = [
  PREMIUM_TRIAL_CAMPAIGN_SLUG,
  PREMIUM_TRIAL_CAMPAIGN_SLUG_LAST_YEAR,
  SIX_MONTH_INDIVIDUAL_TRIAL_CAMPAIGN_SLUG,
  ...NCE_TRIAL_CAMPAIGN_TITLES
];

