import {constants} from '@newsela/angelou';

export const getContentLibraryButtonCss = (isOpen, isStudent) => ({
  root: {
    width: isStudent ?
      '92px' :
      '154px',
    flexDirection: 'row',
    padding: '0.25rem 0.5rem',
    gap: '0.5rem',
    color: isOpen
      ? constants.colors.ui.core[700]
      : constants.colors.ui.neutral[600],
    fontWeight: isOpen ? 700 : 400,

    ':hover': {
      color: constants.colors.ui.core[700],
    },

    svg: {
      width: '1rem',
      height: '1rem',

      path: {
        strokeWidth: isOpen
          ? '4px'
          : '2px'
      }
    },
  }
});
