import {map} from 'lodash-es';
import {all, spawn} from 'redux-saga/effects';

import modulesV2Sagas from 'modulesV2/sagas';

import commonSagas from '../modules/sagas';
import binderRouteSagas from '../routes/Binder/modules/sagas';
import readRouteSagas from '../routes/Read/modules/sagas';
import signUpSagas from '../routes/SignUp/modules/sagas';
import studentBinderRouteSagas from '../routes/StudentBinder/modules/sagas';

// The root saga is responsible for starting all the sagas in the app.
function * rootSaga() {
  const sagas = [
    ...commonSagas,
    ...readRouteSagas,
    ...binderRouteSagas,
    ...studentBinderRouteSagas,
    ...signUpSagas,
    ...modulesV2Sagas,
  ];

  // Run all sagas in parallel and detached from the root saga.
  // An exception in one saga should not bubble up and abort the root saga.
  const detachedTasks = map(sagas, (saga) => spawn(saga));
  yield all(detachedTasks);
}

export default rootSaga;
