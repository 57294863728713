import {signUpActionTypes as types} from '../constants';

export function initializeUserCreation(userPayload, role, additionalUserData = {}) {
  return {type: types.INITIALIZE_USER_CREATION, payload: {userInfo: userPayload, role: role, additionalUserData: additionalUserData}};
}

export function initializeSendVerificationEmail(user) {
  return {type: types.INITIALIZE_SEND_VERIFICATION_EMAIL, payload: {user: user}};
}

export function initializeRoleCreation(user, role, additionalUserData) {
  return {type: types.INITIALIZE_ROLE_CREATION, payload: {user: user, role: role, additionalUserData: additionalUserData}};
}

export function initializeUpdateUser(user, additionalUserData) {
  return {type: types.INITIALIZE_UPDATE_USER, payload: {user: user, additionalUserData: additionalUserData}};
}

export function createUserRequest() {
  return {type: types.SIGN_UP_CREATE_USER_REQUEST};
}

export function createEmailRequest() {
  return {type: types.SIGN_UP_CREATE_EMAIL_REQUEST};
}

export function createRoleCreationRequest() {
  return {type: types.SIGN_UP_CREATE_ROLE_CREATION_REQUEST};
}

export function createUpdateUserRequest() {
  return {type: types.SIGN_UP_CREATE_UPDATE_USER_REQUEST};
}

export function getSignUpSuccess() {
  return {type: types.SIGN_UP_LOAD_SUCCESS};
}

export function getSignUpRoleCreationSuccess() {
  return {type: types.SIGN_UP_LOAD_ROLE_CREATION_SUCCESS};
}

export function getSignUpFailure(error) {
  return {type: types.SIGN_UP_LOAD_FAILURE, error};
}
