import {reportingActionTypes as types} from './../constants';

export function loadReportingDataFetchSuccess(data) {
  return {
    type: types.REPORTING_DATA_LOAD_SUCCESS,
    reportingData: data,
    receivedAt: Date.now()
  };
}

export function loadReportingDataFetchFailure() {
  return {
    type: types.REPORTING_DATA_LOAD_FAILURE,
    reportingData: [],
    receivedAt: Date.now()
  };
}
