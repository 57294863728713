import {get} from 'lodash-es';
import {takeEvery, call, put} from 'redux-saga/effects';

import {ConstructedResponseReviewApi, StudentQuizApi} from 'static/three-oh/src/modules/apis';

import {assignmentsTabActions, studentActivityActions, studentDetailsActions} from '../actions';
import {studentActivityActionTypes} from '../constants';


/**
 * Remove (Reset) A Student Quiz
 */
function * watchRemoveStudentQuiz() {
  yield takeEvery(studentActivityActionTypes.STUDENT_QUIZ_REMOVE_REQUEST, function * removeStudentQuiz(action) {
    const headerId = get(action.studentQuiz, 'quiz.article.header.id');
    const studentId = get(action.studentQuiz, 'student.id');
    const studentQuizId = get(action.studentQuiz, 'id');
    const binderFilters = action.binderFilters;

    try {
      yield call(StudentQuizApi.remove, studentQuizId);
      yield put(studentActivityActions.studentQuizRemoveRequestSuccess(studentId, studentQuizId));
      yield put(assignmentsTabActions.slimHeaderReportingDataFetchRequest(headerId));
      yield put(studentDetailsActions.fetchStudentDetails(studentId, binderFilters));
    } catch (error) {
      // TODO: Something with the error. No response expected currently.
    }
  });
}

// Create a Review with a Revision Request
function * watchSaveRevisionRequest() {
  yield takeEvery(studentActivityActionTypes.STUDENT_WRITE_REVISION_REQUEST, function * saveRevisionRequest(action) {
    const writeResponseId = get(action.payload, 'writeResponse.id');
    const studentId = get(action.payload, 'writeResponse.student.id');
    const headerId = get(action.payload, 'writeResponse.article.header.id');
    const payload = {
      answerId: writeResponseId,
      dateRevisionRequested: action.payload.dateRevisionRequested,
      dateShared: action.payload.dateShared,
      score: action.payload.score,
      text: action.payload.text,
    };
    try {
      yield call(ConstructedResponseReviewApi.post, payload);
      yield put(studentActivityActions.studentWriteRevisionRequestSuccess(studentId, writeResponseId));
      yield put(assignmentsTabActions.slimHeaderReportingDataFetchRequest(headerId));
      yield put(studentDetailsActions.fetchStudentDetails(studentId, action.payload.binderFilters));
    } catch (error) {
      // Nothing, currently. Sad.
    }
  });
}

export {
  watchRemoveStudentQuiz,
  watchSaveRevisionRequest,
};
