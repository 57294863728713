import * as types from 'modulesV2/constants';

export default function externalToolContextReducer(state = null, action) {
  switch (action.type) {
    case types.EXTERNAL_TOOL_UPDATE_SUCCESS: {
      return {
        ...action.payload,
      };
    }
    case types.CLEAR_EXTERNAL_TOOL_CONTEXT:
      return null;
    default:
      return state;
  }
}
