import React from 'react';

import {
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {get} from 'lodash-es';
import PropTypes from 'prop-types';

import Button from 'static/three-oh/src/components/Button/Button';
import {ProBadge} from 'static/three-oh/src/components/ProBadge';
import withTrackEvent from 'static/three-oh/src/components/with/WithTrackEvent';
import TrackEventProps from 'static/three-oh/src/components/with/WithTrackEventConstants';

import styles from './styles.scss';


/*
* CallToActionCard communicates to the user that they
* should take action by clicking the button specified in the props.
* */


const propTypes = {
  /* Props from HOC */
  ...TrackEventProps,

  /* The CTA Card component can optionally render content passed as children */
  children: PropTypes.node,

  /* High-level value proposition of this call to action */
  header: PropTypes.node,

  /* A more detailed description of the value proposition */
  description: PropTypes.node,

  /* Whether a button should be disabled by default */
  shouldDisableButton: PropTypes.bool,

  /* Make displaying the button optional */
  renderButton: PropTypes.bool,

  /* The button label for the primary button */
  primaryButtonLabel: PropTypes.node,

  /* The click handler for the primary button */
  primaryButtonClickHandler: PropTypes.func,

  /* The button label for the secondary button */
  secondaryButtonLabel: PropTypes.node,

  /* The click handler for the secondary button */
  secondaryButtonClickHandler: PropTypes.func,

  /* A theme identifier used to modify styles */
  theme: PropTypes.string,

  /* Allow the user to dismiss the card */
  dismissable: PropTypes.bool,

  /* Click handler for the dismiss button */
  onDismiss: PropTypes.func,

  /* Information about the current user */
  /* Use: information about the user is sent to Kinesis when the component is viewed */
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    is_authenticated: PropTypes.bool,
    isTrialUser: PropTypes.bool,
    licenses: PropTypes.array,
    student: PropTypes.object,
    teacher: PropTypes.object,
  }),

  /* The CTACard has the option to render a badge */
  withBadge: PropTypes.bool,
};


class CallToActionCard extends React.Component {
  renderSecondaryButton = () => {
    const theme = this.props.theme;
    let secondaryButton = null;
    if (this.props.secondaryButtonLabel && this.props.secondaryButtonClickHandler) {
      secondaryButton = (
        <Button
          standard
          secondary
          additionalClassNames={[
            styles[theme],
            styles.secondaryButton,
            styles.CTASecondaryDisabled,
            styles.specificityUtilityClassOne,
            styles.specificityUtilityClassTwo,
          ]}
          disabled={this.props.shouldDisableButton}
          label={this.props.secondaryButtonLabel}
          onClick={this.props.secondaryButtonClickHandler}
        />
      );
    }
    return secondaryButton;
  };

  addAdditionalStyle = () => {
    if (get(this.props.eventProperties, 'is_veiled')) {
      return `${styles.containerBadgePadding}`;
    } else {
      return null;
    }
  }

  render() {
    const theme = this.props.theme;

    return (
      <div className={`${styles[theme]} ${styles.container} ${this.addAdditionalStyle()}`}>
        {this.props.withBadge &&
          <div className={styles.badgeWrapper}>
            <ProBadge
              width="4em"
            />
          </div>}
        <div className={styles.textWrapper}>
          <div className={styles.header}>
            {this.props.header}
          </div>
          {(this.props.description || this.props.children) &&
            <div className={styles.description}>
              {this.props.description ? this.props.description : this.props.children}
            </div>}
          {this.props.renderButton &&
            <div className={styles.buttonWrapper}>
              <div className={styles.button}>
                {this.renderSecondaryButton()}
                {this.props.primaryButtonLabel &&
                  <Button
                    additionalClassNames={[styles.CTADisabled, styles.specificityUtilityClassOne, styles.specificityUtilityClassTwo]}
                    disabled={this.props.shouldDisableButton}
                    label={this.props.primaryButtonLabel}
                    onClick={this.props.primaryButtonClickHandler}
                  />}
              </div>
            </div>}
        </div>
        {this.props.dismissable &&
          <Button
            additionalClassNames={[styles.dismiss, styles.specificityUtilityClassOne, styles.specificityUtilityClassTwo]}
            icon={<FontAwesomeIcon icon={faTimes} />}
            onClick={this.props.onDismiss}
            disableToolTip
          />}
      </div>
    );
  }
}

CallToActionCard.defaultProps = {
  shouldDisableButton: false,
  renderButton: true,
};


CallToActionCard.propTypes = propTypes;
const CallToActionCardWithTrackEvent = withTrackEvent(CallToActionCard);
export {CallToActionCard as default, CallToActionCardWithTrackEvent};
