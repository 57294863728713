import {constants, typographyStyles} from '@newsela/angelou';
import {css} from 'emotion';

import {
  NAVBAR_BREAKPOINTS
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';

export const getExpandedUserButtonCss = (isOpen, isLite) => ({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  height: '1.75rem',
  padding: '0.5rem 0.5rem 0.5rem 2rem',
  alignItems: 'center',
  background: isOpen
    ? isLite ? constants.colors.ui.neutral[700] : constants.colors.ui.core[500]
    : isLite ? constants.colors.ui.neutral[100] : constants.colors.ui.core[25],
  borderRadius: '1.88rem',
  gap: '0.5rem',
  border: 0,
  cursor: 'pointer',

  ':hover': {
    background: isOpen
      ? isLite ? constants.colors.ui.neutral[700] : constants.colors.ui.core[500]
      : isLite ? constants.colors.ui.neutral[100] : constants.colors.ui.core[25],
  },

  [`@media (max-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
    display: 'none',
  },
});

export const baseButtonCss = css({
  root: {
    borderRadius: '1rem',
  },
});

export const closeMenuIconCss = {
  width: '1.5rem',
  height: '1.5rem',
};

export const closeMenuButtonCss = {
  display: 'none',
  padding: '0.25rem',

  ':hover': {
    borderRadius: '0.5rem',
    background: 'rgba(29, 29, 29, 0.08)'
  },

  svg: {
    width: '1.5rem',
    height: '1.5rem',
  },

  [`@media (max-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
    display: 'flex',
  },
};

export const condensedUserButtonVisibilityCss = (isLite) => ({
  display: 'none',
  borderRadius: '1.88rem',
  cursor: 'pointer',
  background: isLite ? constants.colors.ui.neutral[700] : constants.colors.ui.core[500],
  border: 0,
  color: constants.colors.ui.white[50],
  width: '1.75rem',
  height: '1.75rem',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '0.625rem',
  fontWeight: 700,
  letterSpacing: '0.0625rem',

  [`@media (max-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
    display: 'flex',
  },
});

export const getUserInitialsCircleCss = (isOpen, isLite, isImpersonated) => ({
  ...typographyStyles.fontFamily.circular,
  position: 'absolute',
  left: 0,
  display: 'flex',
  borderRadius: '1.88rem',
  background: (function(isOpen, isLite, isImpersonated) {
    if (isImpersonated) {
      return constants.colors.ui.red[400];
    }

    if (isOpen) {
      return constants.colors.ui.white[50];
    } else {
      if (isLite) {
        return constants.colors.ui.neutral[700];
      }

      return constants.colors.ui.core[500];
    }
  })(isOpen, isLite, isImpersonated),
  border: isOpen
    ? `2px solid ${isLite ? constants.colors.ui.neutral[700] : constants.colors.ui.core[500]}`
    : 0,
  color: (function(isOpen, isLite, isImpersonated) {
    if (isImpersonated) {
      return constants.colors.ui.white[50];
    }

    if (isOpen) {
      if (isLite) {
        return constants.colors.ui.neutral[700];
      }

      return constants.colors.ui.core[500];
    } else {
      return constants.colors.ui.white[50];
    }
  })(isOpen, isLite, isImpersonated),
  width: '1.75rem',
  height: '1.75rem',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '0.625rem',
  fontWeight: 700,
  letterSpacing: '0.0625rem',
});

export const getLicensesTextCss = (isOpen) => ({
  ...typographyStyles.fontFamily.circular,
  color: isOpen
    ? constants.colors.ui.white[50]
    : constants.colors.ui.neutral[600],
  fontSize: '0.625rem',
  textWrap: 'nowrap',
  fontWeight: 700,
});
export const getChevronIconCss = (isOpen) => ({
  root: {
    width: '0.75rem',
    height: '0.75rem',
    marginTop: '-1px',
    ...typographyStyles.fontSize.textM,

    path: {
      stroke: isOpen
        ? constants.colors.ui.white[50]
        : constants.colors.ui.neutral[600],
    },
  }
});
