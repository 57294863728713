import React from 'react';

import {LoadingSpinner} from '@newsela/angelou';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';


const LazyExternalEmbedContainer = Loadable({
  loader: () => import(
    /* webpackChunkName: "StandaloneExternalEmbed" */
    './components/StandaloneExternalEmbed'
  ),
  delay: 1000,
  // eslint-disable-next-line
  loading: (props) => (props.pastDelay ? <LoadingSpinner /> : null)
});

const ExternalEmbedRoute = (
  <Route
    path="/external-embeds/:slug"
    component={LazyExternalEmbedContainer}
  />
);

export default ExternalEmbedRoute;
