import {call, put, takeLatest} from 'redux-saga/effects';

import {SessionApi} from 'static/three-oh/src/modules/apis';

import * as actions from '../actions/sessionActions';
import * as types from '../constants/sessionActionTypes';


function * requestUserSignOut() {
  try {
    const response = yield call(SessionApi.signOut);
    yield put(actions.deleteSessionSuccess(response));
    window.location.reload();
  } catch (error) {
    yield put(actions.deleteSessionFailure(error));
  }
}

export function * watchRequestUserSignOut() {
  yield takeLatest(types.DELETE_SESSION_REQUEST, requestUserSignOut);
}
