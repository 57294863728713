import {
  watchGetSlimReportingData,
  workPollingManager,
  watchToggleWorkDetails
} from './assignmentsTabSagas';
import {
  watchRequestInitialBinderData,
  watchUnloadBinderData,
} from './binderSagas';
import {watchInitCcAnchorStandards} from './ccAnchorStandardsSagas';
import {
  watchRemoveStudentQuiz,
  watchSaveRevisionRequest,
} from './studentActivitySagas';
import {watchBinderStudentDetails} from './studentDetailsSagas';
import {watchInitStudentTab, watchRequestStudentReadingLevels} from './studentTabSagas';

const binderSagas = [
  watchBinderStudentDetails,
  watchGetSlimReportingData,
  watchInitStudentTab,
  watchRequestStudentReadingLevels,
  watchRemoveStudentQuiz,
  watchRequestInitialBinderData,
  watchSaveRevisionRequest,
  watchToggleWorkDetails,
  watchUnloadBinderData,
  workPollingManager,
  watchInitCcAnchorStandards,
];

export default binderSagas;
