import React from 'react';

import {BookmarkButton} from '@newsela/angelou';
import {cx} from 'emotion';
import {find, isEmpty, get} from 'lodash-es';
import PropTypes from 'prop-types';

import {withTrackEvent} from 'static/three-oh/src/components/with';
import {displaySigninModal} from 'static/three-oh/src/modules/utils/authenticationHelper';

import {bookmarkButtonWrapperCss, minWidthCss} from './styles';

const BookmarkButtonWithTrackEvent = withTrackEvent(BookmarkButton);

// Lite users have the `is_lite_user` feature
const isLiteUser = (user) => {
  const result = user?.features?.is_lite_user ?? 'off';
  return result.toLowerCase() === 'on';
};

const isStudent = (user) => {
  const roles = user?.roles ?? [];
  return roles.includes('student');
};

const isLiteStudent = ({user}) =>
  isLiteUser(user) && isStudent(user);

const propTypes = {
  actions: PropTypes.object,
  contentId: PropTypes.string,
  eventProperties: PropTypes.object,
  label: PropTypes.object,
  likedContent: PropTypes.array,
  user: PropTypes.object,
  userStudies: PropTypes.object.isRequired,
  __classNameFor: PropTypes.string,
};

class SaveContentButton extends React.Component {
  findContentLike() {
    const likedContent = this.props.likedContent;
    return find(likedContent, {content_id: this.props.contentId});
  }

  isSaved() {
    return !isEmpty(this.findContentLike());
  }

  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!this.props.user.is_authenticated) {
      displaySigninModal(e);
    } else if (this.isSaved()) {
      const like = this.findContentLike();
      this.props.actions.deleteLikedContentRequest(like.id);
    } else {
      this.props.actions.createLikedContentRequest(
        this.props.contentId,
      );
    }
  }

  render() {
    const isSaved = this.isSaved();
    const maintainMinWidth = this.props.label ? minWidthCss : null;

    if (isLiteStudent(this.props)) { return null; }

    return (
      <div data-qa-selector="save" className={cx(bookmarkButtonWrapperCss, maintainMinWidth)}>
        <BookmarkButtonWithTrackEvent
          active={isSaved}
          onClick={this.handleClick}
          legacyEventName="Content-Save"
          objectType="ContentBookmark"
          actionName={isSaved ? 'Delete' : 'Create'}
          eventProperties={this.props.eventProperties}
          streamProperties={{
            content_id: this.props.contentId,
            search_id: get(this.props.eventProperties, 'search_id'),
          }}
          dataProps={{
            'data-qa-selector': 'save'
          }}
          label={this.props.label}
        />
      </div>
    );
  }
}

SaveContentButton.propTypes = propTypes;
export default SaveContentButton;
