import React from 'react';

import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const propTypes = {
  id: PropTypes.string.isRequired,
  setFocus: PropTypes.func,
  unfocusedView: PropTypes.node,
  focusedView: PropTypes.node,
  currentlyFocusedCard: PropTypes.string,
  cardContainerStyle: PropTypes.object,
};

const cardClassNames = classNames.bind(styles);

class FocusableCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.isCurrentlyFocused = this.isCurrentlyFocused.bind(this);
    this.focusMe = this.focusMe.bind(this);
    this.unfocusMe = this.unfocusMe.bind(this);
    this.getClassName = this.getClassName.bind(this);
  }

  isCurrentlyFocused() {
    return this.props.id == this.props.currentlyFocusedCard;
  }

  focusMe() {
    this.props.setFocus(this.props.id);
  }

  unfocusMe() {
    this.props.setFocus(null);
  }

  getClassName() {
    return cardClassNames({
      card: true,
      focused: this.isCurrentlyFocused(),
      unfocused: !this.isCurrentlyFocused()
    });
  }

  render() {
    return (
      <div
        onClick={this.focusMe}
        className={this.getClassName()}
      >
        <div style={this.props.cardContainerStyle}>
          {this.isCurrentlyFocused() ?
            this.props.focusedView :
            this.props.unfocusedView}
        </div>
      </div>
    );
  }
}

FocusableCard.propTypes = propTypes;
export default FocusableCard;
