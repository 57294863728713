import * as types from './../constants/textSetActionTypes';

export function requestTextSet(queryString, slug) {
  return {type: types.TEXT_SET_LOAD_REQUEST, queryString, slug};
}

export function requestTextSetSuccess(data, slug) {
  return {
    slug,
    type: types.TEXT_SET_LOAD_SUCCESS,
    textSets: data,
    receivedAt: Date.now()
  };
}

export function requestTextSetFailure(error, slug) {
  return {
    error,
    slug,
    type: types.TEXT_SET_LOAD_FAILURE,
    textSets: [],
    receivedAt: Date.now()
  };
}

export function saveTextSet(payload) {
  return {type: types.TEXT_SET_SAVE_REQUEST, payload};
}

export function saveTextSetSuccess(payload) {
  return {type: types.TEXT_SET_SAVE_SUCCESS, payload};
}

export function saveTextSetFailure(payload) {
  return {type: types.TEXT_SET_SAVE_FAILURE, payload};
}

export function addHeaderToTextSet(payload) {
  return {type: types.ADD_HEADER_TO_TEXT_SET_REQUEST, payload};
}

export function addHeaderToTextSetSuccess(payload) {
  return {type: types.ADD_HEADER_TO_TEXT_SET_SUCCESS, payload};
}

export function removeHeaderFromTextSet(payload) {
  return {type: types.REMOVE_HEADER_FROM_TEXT_SET_REQUEST, payload};
}

export function removeHeaderFromTextSetSuccess(payload) {
  return {type: types.REMOVE_HEADER_FROM_TEXT_SET_SUCCESS, payload};
}
