import React from 'react';

import {useWindowSize} from '@newsela/angelou';
import PropTypes from 'prop-types';

import {
  CondensedSearchInput
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SearchMenu/CondensedSearchInput';
import {
  condensedSuggestionsPopoutVisibilityCss,
  expandedSuggestionsPopoutVisibilityCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SearchMenu/SearchSuggestionsPopout/SearchSuggestionsPopout.styles';
import {
  SuggestionsList
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SearchMenu/SearchSuggestionsPopout/SuggestionsList';
import {
  NAVBAR_BREAKPOINTS
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';

const propTypes = {
  inputValue: PropTypes.string.isRequired,
  suggestions: PropTypes.array.isRequired,
  resetSearchMenuToDefault: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  setShowSuggestions: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const SearchSuggestionsPopout = ({
  onChange,
  onKeyDown,
  inputValue,
  suggestions = [],
  setShowSuggestions,
  resetSearchMenuToDefault,
}) => {
  const {width: windowWidth} = useWindowSize();

  const customOnKeyDown = (event) => {
    onKeyDown(event);
    if (event.key === 'Tab') {
      setShowSuggestions(true);
    }
  };

  const hasSuggestions = !!suggestions.length;
  const showCondensedSuggestionsPopout = windowWidth < NAVBAR_BREAKPOINTS.sm;

  return (
    <div data-testid="search-suggestions-popout">
      {showCondensedSuggestionsPopout
        ? (
          <div className={condensedSuggestionsPopoutVisibilityCss}>
            <CondensedSearchInput
              inputValue={inputValue}
              onChange={onChange}
              onKeyDown={customOnKeyDown}
              setShowSuggestions={setShowSuggestions}
              onClick={resetSearchMenuToDefault}
            />

            {hasSuggestions && (
              <SuggestionsList
                inputValue={inputValue}
                suggestions={suggestions}
                setShowSuggestions={setShowSuggestions}
              />
            )}
          </div>
          )
        : hasSuggestions && (
          <div className={expandedSuggestionsPopoutVisibilityCss}>
            <SuggestionsList
              inputValue={inputValue}
              suggestions={suggestions}
              setShowSuggestions={setShowSuggestions}
            />
          </div>
        )}
    </div>
  );
};

SearchSuggestionsPopout.propTypes = propTypes;
SearchSuggestionsPopout.displayName = 'SearchSuggestionsPopout';
export {SearchSuggestionsPopout};
