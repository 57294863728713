import {combineReducers} from 'redux';

import ariaAttributesReducer from './ariaReducer';
import context from './context';
import modal from './modalReducer';
import screenSize from './screenSize';


export default combineReducers({
  ariaAttributesReducer,
  modal,
  screenSize,
  context
});
