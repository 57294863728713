import initialState from './initialState';
import {studentTabActionTypes as types} from '../constants';

function standardsList(state = initialState.binderStandards, action) {
  switch (action.type) {
    case types.BINDER_STANDARDS_LOAD_REQUEST:
      return {...state, isError: false, isLoading: true};

    case types.BINDER_STANDARDS_LOAD_SUCCESS:
      return {...state, isError: false, isLoading: false, items: action.payload.data, filters: action.payload.filters};

    case types.BINDER_STANDARDS_LOAD_FAILURE:
      return {...state, isError: true, isLoading: false, error: action.error};

    default:
      return state;
  }
}

export default standardsList;
