import APIFetcher from '../APIFetcher';

class StudentWorkReviewRecordApi {
  static get(queryParams) {
    const params = APIFetcher.stringifyParams(queryParams);
    return APIFetcher.get(`student-work-review-record/?${params}`);
  }

  static post(payload) {
    return APIFetcher.post('student-work-review-record/', {payload});
  }
}

export default StudentWorkReviewRecordApi;
