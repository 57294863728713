import {Toast} from '@newsela/angelou';

const MY_CONTENT_URL = '/my-content/?tab=saved-by-you';

export const LIKE_ERROR_TOAST_PROPS = {
  statusColor: Toast.statusColor.danger,
  children: 'Oops! We had trouble saving. Please try again.',
};

export const LIKE_SUCCESS_TOAST_PROPS = {
  showIcon: true,
  statusColor: Toast.statusColor.success,
  children: 'Saved in',
  cta: {href: MY_CONTENT_URL, text: 'Your Content'},
};

export const UNLIKE_ERROR_TOAST_PROPS = {
  statusColor: Toast.statusColor.danger,
  children: 'Oops! We had trouble removing the save. Please try again.',
};

export const UNLIKE_SUCCESS_TOAST_PROPS = {
  showIcon: true,
  statusColor: Toast.statusColor.primary,
  children: 'Removed from',
  cta: {href: MY_CONTENT_URL, text: 'Your Content'},
};
