import React from 'react';

import PropTypes from 'prop-types';

import withTrackEvent from 'static/three-oh/src/components/with/WithTrackEvent';
import TrackEventProps from 'static/three-oh/src/components/with/WithTrackEventConstants';

import {ReactComponent as BrickedOffDoor} from './assets/RawBrickedOffDoorIcon.svg';
import {
  loginRequired,
  permissionRequired,
  permissionRequiredForStudent,
  permissionRequiredForTeacher,
  permissionRequiredForStudentAssignment,
  permissionRequiredForTeacherAssignment,
  defaultErrorMessage,
  hiddenContentErrorMessage,
  hiddenContentErrorMessageStudent,
  contentNotFoundMessage
} from './constants';
import ErrorMessaging from './ErrorMessaging';
import {
  brickedOffDoorStyle,
  borderlessErrorPanelContainerStyle,
  contentWrapperUnauthStyle,
  errorPanelContainerStyle
} from './styles';
import AngelouProps from '../../propTypes/AngelouPropTypes';

const errorFlavor = {
  loginRequired: 'LOGIN_REQUIRED',
  permissionRequired: 'PERMISSION_REQUIRED',
  permissionRequiredForStudent: 'PERMISSION_REQUIRED_STUDENT',
  permissionRequiredForTeacher: 'PERMISSION_REQUIRED_TEACHER',
  permissionRequiredForStudentAssignment: 'PERMISSION_REQUIRED_STUDENT_ASSIGNMENT',
  permissionRequiredForTeacherAssignment: 'PERMISSION_REQUIRED_TEACHER_ASSIGNMENT',
  hiddenContentErrorMessage: 'HIDDEN_CONTENT',
  hiddenContentErrorMessageStudent: 'HIDDEN_CONTENT_STUDENT',
  contentNotFound: 'CONTENT_NOT_FOUND'
};

const propTypes = {
  ...AngelouProps,
  ...TrackEventProps,
  /**
  * Flavor of error. Options are available in the ErrorFlavors enum above.
  */
  flavor: PropTypes.oneOf(Object.values(errorFlavor)),
  /**
  * Data attributes used for integration tests.
  * Example:
  * <button data-qa-selector="sign_in_button_top">Sign in</button>
  *
  * Integration Tests PR: https://github.com/newsela/newsela/pull/14201
  */
  joinQaSelector: PropTypes.string,
  signInQaSelector: PropTypes.string,
  lyceumStyle: PropTypes.bool,
};

const getErrorFlavorMessaging = (flavor) => {
  switch (flavor) {
    case errorFlavor.loginRequired:
      return loginRequired;
    case errorFlavor.permissionRequired:
      return permissionRequired;
    case errorFlavor.permissionRequiredForStudent:
      return permissionRequiredForStudent;
    case errorFlavor.permissionRequiredForTeacher:
      return permissionRequiredForTeacher;
    case errorFlavor.permissionRequiredForStudentAssignment:
      return permissionRequiredForStudentAssignment;
    case errorFlavor.permissionRequiredForTeacherAssignment:
      return permissionRequiredForTeacherAssignment;
    case errorFlavor.hiddenContentErrorMessage:
      return hiddenContentErrorMessage;
    case errorFlavor.hiddenContentErrorMessageStudent:
      return hiddenContentErrorMessageStudent;
    case errorFlavor.contentNotFound:
      return contentNotFoundMessage;
  }
};

class ErrorPanel extends React.Component {
  render() {
    const {
      flavor,
      joinQaSelector,
      useLyceumStyle,
      signInQaSelector,
    } = this.props;
    const errorMessaging = getErrorFlavorMessaging(flavor);
    const containerClassName = useLyceumStyle ? borderlessErrorPanelContainerStyle : errorPanelContainerStyle;
    return (
      <div className={contentWrapperUnauthStyle}>
        <div
          className={containerClassName}
          data-qa-selector="permission_error_panel"
        >
          {useLyceumStyle ?
            <div className={brickedOffDoorStyle}>
              <BrickedOffDoor
                aria-hidden="true"
              />
            </div>
            : <></>}
          <ErrorMessaging
            {...errorMessaging}
            joinQaSelector={joinQaSelector}
            signInQaSelector={signInQaSelector}
            useLyceumStyle={useLyceumStyle}
          />
        </div>
      </div>
    );
  }
}

ErrorPanel.defaultProps = {
  errorMessaging: defaultErrorMessage,
  eventProperties: {
    errorType: null,
  },
  includeBrickedOffDoorIcon: false,
};

ErrorPanel.propTypes = propTypes;
ErrorPanel.flavor = errorFlavor;

const ErrorPanelWithTrackEvent = withTrackEvent(ErrorPanel);
ErrorPanelWithTrackEvent.flavor = errorFlavor;
export {ErrorPanel as default, ErrorPanelWithTrackEvent};
