import React from 'react';

import {Toaster} from '@newsela/angelou';

import {
  AppSwitcherMenu
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/AppSwitcherMenu';
import {HomeMenu} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/HomeMenu';
import {
  navigationBarContainerCss,
  navigationBarLeftContainerCss,
  navigationBarRightContainerCss,
  trialButtonsVisibilityCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/NavigationBar.styles';
import {NewselaLogo} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/NewselaLogo';
import {ResourceMenus} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/ResourceMenus';
import {SearchMenu} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SearchMenu';
import {
  SkipToContentButton
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SkipToContentButton';
import {SubjectItems} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SubjectItems';
import {SupportCenter} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SupportCenter';
import {
  UserProfileMenu
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/UserProfileMenu';

import {useNavigationBarV2Context} from './NavigationBar.provider';
import {TrialButtons} from './PremiumTrial/TrialButtons';
import globalToasterRef from '../../../../constants/globalToasterRef';
import {TOASTER_Z_INDEX} from '../../../../constants/globalZIndexes';
import TrialModal from '../../../TrialModal';

const NavigationBar = () => {
  const {
    trialModal: {
      trialModalProps,
    }
  } = useNavigationBarV2Context();

  return (
    <>
      <Toaster
        ref={globalToasterRef}
        __cssFor={{root: {zIndex: TOASTER_Z_INDEX}}}
      />

      <header>
        <nav className={navigationBarContainerCss}>
          <ul className={navigationBarLeftContainerCss}>
            <AppSwitcherMenu/>
            <NewselaLogo/>
            <SearchMenu/>
            <SkipToContentButton/>
          </ul>

          <ul className={navigationBarRightContainerCss}>
            <SubjectItems/>
            <ResourceMenus/>
            <HomeMenu/>
            <TrialButtons customCss={trialButtonsVisibilityCss}/>
            <UserProfileMenu/>
            <SupportCenter/>
          </ul>
        </nav>

        <TrialModal {...trialModalProps} />
      </header>
    </>
  );
};

NavigationBar.displayName = 'NavigationBar';
export {NavigationBar};
