import * as types from './../constants/articleLikeActionTypes';

export function articleLikeRequest() {
  return {
    type: types.ARTICLE_LIKE_REQUEST,
  };
}

export function articleLikeRequestSuccess(articleLikes) {
  return {
    type: types.ARTICLE_LIKE_REQUEST_SUCCESS,
    articleLikes: articleLikes,
  };
}

export function articleLikeRequestFailure(error) {
  return {
    error,
    type: types.ARTICLE_LIKE_REQUEST_FAILURE,
  };
}

export function articleLikeCreateRequest({articleId, headerId}) {
  return {
    type: types.ARTICLE_LIKE_CREATE_REQUEST,
    articleId,
    headerId
  };
}

export function articleLikeCreateRequestSuccess(articleLike) {
  return {
    type: types.ARTICLE_LIKE_CREATE_REQUEST_SUCCESS,
    articleLike: articleLike,
  };
}

export function articleLikeDeleteRequest(likeId) {
  return {
    type: types.ARTICLE_LIKE_DELETE_REQUEST,
    likeId,
  };
}

export function articleLikeDeleteRequestSuccess(likeId) {
  return {
    type: types.ARTICLE_LIKE_DELETE_REQUEST_SUCCESS,
    likeId,
  };
}
