import React from 'react';

import {get} from 'lodash-es';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Routes from '../routes/Routes';

class RoutesContainer extends React.PureComponent {
  static propTypes = {
    isMainContentAriaHidden: PropTypes.bool.isRequired,
    contentId: PropTypes.string.isRequired
  };

  render() {
    const {isMainContentAriaHidden, contentId} = this.props;
    return (
      <div id={contentId} aria-hidden={isMainContentAriaHidden}>
        <Routes/>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    isMainContentAriaHidden: get(state, 'v2.ui.ariaAttributesReducer.isMainContentAriaHidden')
  };
};

export default connect(mapStateToProps)(RoutesContainer);
