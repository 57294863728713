import ApiFetcher3 from 'static/three-oh/src/modules/apis/ApiFetcher3';
import {formatAssignmentPayload} from 'static/three-oh/utils/withV3AssignmentApiGet/withV3AssignmentGetWithoutPagination';

class AssignmentApi {
  static getListV3(query) {
    const paramsStr = new URLSearchParams(query);
    return ApiFetcher3.get('assignment/', paramsStr);
  }

  static getById(id) {
    return ApiFetcher3.get(`assignment/${id}`);
  }

  static post(assignment, canvas_lti_assignment_id) {
    const payload = formatAssignmentPayload(assignment, null);
    payload.canvas_lti_assignment_id = canvas_lti_assignment_id;
    const url = `assignment/${assignment?.content_id ? `${assignment.content_id}/` : ''}`;
    return ApiFetcher3.post(url, {
      data: payload
    });
  }
}

export default AssignmentApi;
