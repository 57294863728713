import React from 'react';

import PropTypes from 'prop-types';

import styles from './styles.scss';


const propTypes = {
  /*
    This prop should be a list of image url's. The component will decide what
    layout to return based on how many there are. Currently we support up to 4.
  */
  imagePaths: PropTypes.array.isRequired,
};


const HeaderImageDisplay = ({imagePaths}) => {
  const getImageChildren = (n) => {
    const children = [];
    for (let i = 0; i < n; i++) {
      children.push(
        <img
          key={imagePaths[i]}
          alt="presentational"
          className={styles.imageTile}
          src={imagePaths[i]}
        />
      );
    }
    return children;
  };

  switch (imagePaths.length) {
    case 0:
      return (
        <div className={styles.container}>
          <div className={styles.sleepCloud} />
        </div>
      );
    case 1:
      return (
        <div className={styles.container}>
          {getImageChildren(1)}
        </div>
      );
    case 2:
      return (
        <div className={styles.twoImages}>
          {getImageChildren(2)}
        </div>
      );
    case 3:
      return (
        <div className={styles.threeImages}>
          {getImageChildren(3)}
        </div>
      );
    default:
      return (
        <div className={styles.nImages}>
          {getImageChildren(4)}
        </div>
      );
  }
};

HeaderImageDisplay.propTypes = propTypes;

export default HeaderImageDisplay;
