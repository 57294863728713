import React, {Children, useState, useMemo} from 'react';

import PropTypes from 'prop-types';

import usePrevious from 'utils/usePrevious';
import useResizeObserver from 'utils/useResizeObserver';

/**
 * This component only exists for its width to be measured,
 * so that the `DropDownMenu` button can have width equal
 * to that of the largest width child in the menu.
 * It is a doppelganger of the `Menu` rendered by `DropDownMenu`,
 * with two important differences: it is always open, so that it
 * is measurable, and always hidden, so that it does not actually
 * display.
 */

const HiddenDoppelgangerMenuForMeasuring = ({menuElement, onWidthChange, children}) => {
  // Using state here instead of an "actual" ref (e.g. `useRef`)
  // due to weird async rendering issues (possibly related to sortable-HOC)
  const [stateRefHiddenDiv, setStateRefHiddenDiv] = useState({current: undefined});
  const [width] = useResizeObserver(stateRefHiddenDiv);
  const previousWidth = usePrevious(width);
  if (width !== previousWidth) {
    onWidthChange(width);
  }
  // Using a memo here to avoid infinite re-render cycle
  const hiddenDivToMeasure = useMemo(() => (
    <div
      key="hidden-div"
      ref={(element) => setStateRefHiddenDiv({current: element})}
    />
  ), []);
  return React.cloneElement(
    menuElement, {
      open: true,
      style: {visibility: 'hidden'},
    },
    Children.toArray(children).concat(hiddenDivToMeasure)
  );
};

HiddenDoppelgangerMenuForMeasuring.propTypes = {
  menuElement: PropTypes.node,
  onWidthChange: PropTypes.func,
  children: PropTypes.node
};

export default HiddenDoppelgangerMenuForMeasuring;
