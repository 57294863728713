import React, {useState, useMemo} from 'react';

import {Button, ChevronDownSVG, ChevronUpSVG, Icon} from '@newsela/angelou';

import {
  getReportsButtonCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/ResourceMenus/ReportsMenu/ReportsMenu.styles';
import {
  ReportsMenuPopout
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/ResourceMenus/ReportsMenu/ReportsMenuPopout/ReportsMenuPopout';
import {
  NavButtonWithPopout
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/NavButtonWithPopout/NavButtonWithPopout';

import {useGetReportsMenuSectionProps} from '../../shared/hooks/useGetReportsMenuSectionProps';
import {getNavMenuButtonAriaProps} from '../../shared/NavButtonWithPopout/NavButtonWithPopout.utils';

const MENU_ID = 'nav-classes-menu';

const ReportsMenu = () => {
  const {
    reportsMenuSectionTitle,
  } = useGetReportsMenuSectionProps();

  const [isOpen, setIsOpen] = useState(false);

  const buttonAriaProps = useMemo(
    () => getNavMenuButtonAriaProps({
      isOpen,
      id: MENU_ID,
    }), [isOpen]);

  const onClickButton = () => setIsOpen((isOpen) => !isOpen);

  return (
    <NavButtonWithPopout
      id={MENU_ID}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      position={{top: '4rem', left: 0}}
      rootCustomCss={{display: 'flex'}}
      ButtonComponent={(
        <Button
          onClick={onClickButton}
          ariaProps={buttonAriaProps}
          flavor={Button.flavors.incognito}
          dataProps={{'data-qa-selector': 'global-nav-classes-menu'}}
          __cssFor={getReportsButtonCss(isOpen)}
        >
          {reportsMenuSectionTitle}

          {isOpen ? (
            <Icon SvgComponent={ChevronUpSVG} />
          ) : (
            <Icon SvgComponent={ChevronDownSVG} />
          )}
        </Button>
      )}
      PopoutComponent={<ReportsMenuPopout />}
    />
  );
};

ReportsMenu.displayName = 'ReportsMenu';
export {ReportsMenu};
