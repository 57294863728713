import {forEach} from 'lodash-es';

import APIFetcher from '../APIFetcher';

class StudentQuizApi {
  static get(queryParams) {
    const params = APIFetcher.stringifyParams(queryParams);
    return APIFetcher.get(`studentquiz/?${params}`);
  }

  static getWithStudentQuizIds(queryParams) {
    const studentQuizIds = queryParams.studentQuizIds;
    let params = '';
    forEach(studentQuizIds, (id) => {
      params += `id=${id}&`;
    });
    return APIFetcher.get(`studentquiz/?${params}`);
  }

  static remove(studentQuizId) {
    const url = `studentquiz/${studentQuizId}/`;
    return APIFetcher.remove(url);
  }

  static post({quizId, payload}) {
    /*
      Note: Each answer should be the following shape:
      {
        quiz_question_id: 1,
        value: 2,
      }
      Where `value` is an integer representing the index of a paragraph or
      the identifier of the quiz question answer model instance.
     */
    const {
      answers,
      associated_content_id,
      dateStarted,
      assignmentIds = [],
    } = payload;
    const body = {
      assignment_ids: assignmentIds,
      associated_content_id: associated_content_id,
      quiz_id: quizId,
      date_started: dateStarted || (new Date()).toISOString(),
      date_completed: (new Date()).toISOString(),
      answers: answers,
    };
    return APIFetcher.post('studentquiz/', {payload: body});
  }
}

export default StudentQuizApi;
