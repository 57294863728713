import * as types from 'modulesV2/constants';

export function requestRosterSyncInfo(roster) {
  return {
    type: types.REQUEST_ALL_ROSTER_SYNC_INFO,
    roster,
  };
}

export function requestRosterSyncInfoSuccess(data) {
  return {
    type: types.REQUEST_ALL_ROSTER_SYNC_INFO_SUCCESS,
    data,
  };
}

export function requestRosterSyncInfoFail(error) {
  return {
    type: types.REQUEST_ALL_ROSTER_SYNC_INFO_FAIL,
    error,
  };
}

export function requestRosterSync(authProvider, classrooms = null) {
  return {
    type: types.SYNC_ROSTER_REQUEST,
    authProvider,
    classrooms,
  };
}

export function requestRosterSyncFailure(error) {
  return {
    type: types.SYNC_ROSTER_REQUEST_FAIL,
    error
  };
}

export function requestRosterSyncSuccess(data) {
  return {
    type: types.SYNC_ROSTER_REQUEST_SUCCESS,
    data,
  };
}

export function markRosterInfoAsSeen(id) {
  return {
    type: types.MARK_ROSTER_INFO_AS_SEEN,
    id,
  };
}

export function clearLatestSyncProvider() {
  return {
    type: types.CLEAR_LATEST_SYNC_PROVIDER_INFO,
  };
}

export function rosterSyncInProgress(authProvider, id) {
  return {
    type: types.ROSTER_SYNC_IN_PROGRESS,
    authProvider,
    id,
  };
}

export function requestRosterClassrooms(authProvider) {
  return {
    type: types.REQUEST_ROSTER_CLASSROOMS,
    authProvider,
  };
}


export function requestRosterClassroomsFailure(authProvider, error) {
  return {
    type: types.REQUEST_ROSTER_CLASSROOMS_FAILURE,
    authProvider,
    error,
  };
}

export function requestRosterClassroomsSuccess(authProvider, data) {
  return {
    type: types.REQUEST_ROSTER_CLASSROOMS_SUCCESS,
    authProvider,
    data,
  };
}

export function requestRosterDetails(authProvider) {
  return {
    type: types.REQUEST_ROSTER_DETAILS,
    authProvider,
  };
}

export function requestRosterDetailsSuccess(authProvider, data) {
  return {
    type: types.REQUEST_ROSTER_DETAILS_SUCCESS,
    authProvider,
    data,
  };
}

export function requestRosterDetailsFailure(authProvider, error) {
  return {
    type: types.REQUEST_ROSTER_DETAILS_FAILURE,
    authProvider,
    error,
  };
}
