import React from 'react';

import {Anchor, Badge, Icon, LockHollowSVG} from '@newsela/angelou';
import {css} from 'emotion';
import PropTypes from 'prop-types';

import {
  anchorCss,
  badgeCss,
  iconAndTitleCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/PopoutMenuSection/PopoutMenuSection.styles';
import {
  menuItemIcon
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/PopoutSectionItemWithPopout/PopoutSectionItemWithPopout.styles';

const propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string,
  rootCss: PropTypes.object,
  locked: PropTypes.bool,
  badge: PropTypes.string,
  dataQASelector: PropTypes.string.isRequired,
  SvgComponent: PropTypes.node,
};

const PopoutSectionItem = ({href, title, SvgComponent, locked = false, dataQASelector = '', rootCss = {}, badge}) => {
  return (
    <li className={css(rootCss)}>
      <Anchor href={href} __cssFor={anchorCss} dataProps={{'data-qa-selector': dataQASelector}}>
        <div className={iconAndTitleCss}>
          {locked && (
            <Icon
              SvgComponent={LockHollowSVG}
              __cssFor={menuItemIcon}
              dataProps={{'data-testid': 'locked-popout-menu-item'}}
            />
          )}
          {!locked && SvgComponent && (
            <Icon
              SvgComponent={SvgComponent}
              __cssFor={menuItemIcon}
              dataProps={{'data-testid': 'unlocked-popout-menu-item'}}
            />
          )}

          {title}
        </div>

        {badge && (
          <Badge
            hideIcon
            text={badge}
            __cssFor={badgeCss}
            size={Badge.size.md}
            flavor={Badge.flavor.solid}
          />
        )}
      </Anchor>
    </li>
  );
};

PopoutSectionItem.propTypes = propTypes;
PopoutSectionItem.displayName = 'PopoutSectionItem';
export {PopoutSectionItem};
