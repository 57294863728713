import {constants} from '@newsela/angelou';
import {css} from 'emotion';
// import {NAVBAR_BREAKPOINTS} from './shared/constants';

export const navigationBarContainerCss = css({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  height: '4rem',
  minHeight: '4rem',
  padding: '0 1rem 0 0.5rem',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${constants.colors.ui.neutral[100]}`,

  ul: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,

    li: {
      margin: 0,
    }
  },
});

export const navigationBarLeftContainerCss = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '0.5rem',
  padding: 0,
});

export const navigationBarRightContainerCss = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '0.5rem',
});

export const svgDarkFillCss = {
  root: {
    path: {
      fill: constants.colors.ui.neutral[700],
    },
  },
};

export const trialButtonsVisibilityCss = css({
  display: 'none',

  [`@media (min-width: ${1140}px)`]: {
    display: 'flex',
  }
});
