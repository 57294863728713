import {takeEvery, call, put} from 'redux-saga/effects';

import * as actions from '../actions/userExperimentSegmentsActions';
import UserExperimentSegmentsApi from '../apis/UserExperimentSegmentsApi';
import * as types from '../constants/userExperimentSegmentsActionTypes';


// Keep track of which requests have already launched so that we don't
// repeat them.
let hasBeenRequested;

function * getUserExperimentSegments() {
  if (!hasBeenRequested) {
    try {
      hasBeenRequested = true;
      const data = yield call(UserExperimentSegmentsApi.get);
      yield put(actions.requestUserExperimentSegmentsSuccess(data));
    } catch (error) {
      yield put(actions.requestUserExperimentSegmentsFailure(error));
    }
  }
}

export function * watchGetUserExperimentSegments() {
  yield takeEvery(types.USER_EXPERIMENT_SEGMENTS_REQUEST, getUserExperimentSegments);
}
