import React, {useCallback, useEffect, useState} from 'react';

import {debounce} from 'lodash-es';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {
  CondensedSearchButton
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SearchMenu/CondensedSearchButton';
import {
  ExpandedSearchInput
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SearchMenu/ExpandedSearchInput';
import {
  fetchSuggestions
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SearchMenu/SearchMenu.utils';
import {
  SearchSuggestionsPopout
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SearchMenu/SearchSuggestionsPopout';
import {
  NavButtonWithPopout
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/NavButtonWithPopout';

import {getUser} from '../../../../../modules/selectors/userSelectors';

export const SEARCH_MENU_ID = 'nav-search-menu';

const SearchMenu = () => {
  const {push} = useHistory();
  const user = useSelector((state) => getUser(state));

  const [inputValue, setInputValue] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [defaultSuggestions, setDefaultSuggestions] = useState([]);

  useEffect(() => {
    const fetchDefaultSuggestions = async() => {
      const suggestions = await fetchSuggestions({fragment: ''});
      setDefaultSuggestions(suggestions);
    };
    fetchDefaultSuggestions();
  }, []);

  const debouncedFetchSuggestions = useCallback(debounce(
    async(inputValue) => {
      if (!inputValue) {
        setSuggestions([]);
        return;
      }
      const suggestions = await fetchSuggestions({fragment: inputValue});
      setSuggestions(suggestions);
    }, 500),
  []);

  if (!user?.is_authenticated) return null;

  const resetSearchMenuToDefault = () => {
    setInputValue('');
    setSuggestions([]);
    setShowSuggestions(false);
  };

  const onChange = (event) => {
    const inputValue = event?.target?.value;
    setInputValue(inputValue);
    debouncedFetchSuggestions(inputValue);

    if (!inputValue) {
      resetSearchMenuToDefault();
    } else {
      setShowSuggestions(true);
    }
  };

  const onKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      const [firstSuggestion] = document.querySelectorAll('.search-suggestion-anchor');
      firstSuggestion?.focus();
    }
    if (event.key === 'Escape') {
      setShowSuggestions(false);
      return;
    }
    if (event.key === 'Enter') {
      setShowSuggestions(false);
      push(`/search/?needle=${inputValue}`);
      return;
    }
    setShowSuggestions(true);
  };

  const onFocus = () => {
    setShowSuggestions(true);
  };

  return (
    <NavButtonWithPopout
      id="nav-search-suggestions"
      isOpen={showSuggestions}
      setIsOpen={setShowSuggestions}
      position={{top: '4rem', left: 0, right: '-19.75rem'}}
      ButtonComponent={(
        <div data-testid="nav-search-buttons">
          <CondensedSearchButton showSuggestions={showSuggestions} setShowSuggestions={setShowSuggestions} />
          <ExpandedSearchInput inputValue={inputValue} onFocus={onFocus} onChange={onChange} onKeyDown={onKeyDown} />
        </div>
      )}
      PopoutComponent={(
        <SearchSuggestionsPopout
          onChange={onChange}
          onKeyDown={onKeyDown}
          inputValue={inputValue}
          setShowSuggestions={setShowSuggestions}
          suggestions={suggestions.length ? suggestions : defaultSuggestions}
          resetSearchMenuToDefault={resetSearchMenuToDefault}
        />
      )}
    />
  );
};

SearchMenu.displayName = 'SearchInput';
export {SearchMenu};
