import React from 'react';

import {Button} from '@newsela/angelou';
import PropTypes from 'prop-types';

import withTrackEvent from 'static/three-oh/src/components/with/WithTrackEvent';


import styles from './styles';


const propTypes = {
  collapsed: PropTypes.bool,
  onClick: PropTypes.func,
};

const Toggle = (props) => {
  const label = props.collapsed ? 'More' : 'Less';
  const icon = props.collapsed ? 'fa-chevron-down' : 'fa-chevron-up';

  return (
    <div className={styles.toggle}>
      <Button
        /* AUTOGENERATED TODO: update angelou to new flavor.
          see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
          for migration guide. */
        legacy_flavor={Button.legacy_flavor.flat}
        /* AUTOGENERATED TODO: update angelou to new flavor.
          see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
          for migration guide. */
        legacy_statusColor={Button.legacy_statusColor.black}
        onClick={props.onClick}
        ariaProps={{
          'aria-label': `See ${label}`
        }}
        __cssFor={{
          root: {
            padding: '0.2rem 1rem',
          }
        }}
      >
        See {label} <i className={`fa ${icon}`} />
      </Button>
    </div>
  );
};

Toggle.propTypes = propTypes;

const ToggleWithTrackEvent = withTrackEvent(Toggle);
export {Toggle as default, ToggleWithTrackEvent};
