import {cloneElement} from 'react';

export const cloneElementWithCustomProps = (element, customProps) => cloneElement(element, {
  ...element.props,
  ...customProps,
}
);

export const getNavMenuButtonAriaProps = ({isOpen, id, ariaLabel}) => {
  const ariaProps = {
    id,
    'aria-haspopup': true,
    'aria-expanded': isOpen,
  };
  if (ariaLabel) ariaProps['aria-label'] = `Toggle ${ariaLabel} Popup`;
  return ariaProps;
};
