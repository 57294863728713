import React from 'react';

import {
  reportsMenuPopoutContainer
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/ResourceMenus/ReportsMenu/ReportsMenuPopout/ReportsMenuPopout.styles';
import {
  PopoutMenuSection
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/PopoutMenuSection';
import {
  PopoutSectionItem
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/PopoutSectionItem';

import {useGetReportsMenuSectionProps} from '../../../shared/hooks/useGetReportsMenuSectionProps';

const ReportsMenuPopout = () => {
  const {
    reportsMenuSectionTitle,
    reportsMenuSectionItems,
  } = useGetReportsMenuSectionProps();

  return (
    <div className={reportsMenuPopoutContainer}>
      <PopoutMenuSection width="20rem" title={reportsMenuSectionTitle}>
        {reportsMenuSectionItems.map(({title, href, SvgComponent, dataQASelector}, index) => (
          <PopoutSectionItem
            key={index}
            href={href}
            title={title}
            SvgComponent={SvgComponent}
            dataQASelector={dataQASelector}
          />
        ))}
      </PopoutMenuSection>
    </div>
  );
};

ReportsMenuPopout.displayName = 'ReportsMenuPopout';
export {ReportsMenuPopout};
