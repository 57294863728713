import {cloneDeep, find, reject} from 'lodash-es';

import * as types from './../constants/textSetActionTypes';

function textSet(state = {}, action) {
  switch (action.type) {
    case types.TEXT_SET_LOAD_SUCCESS:
      return {...state, [action.slug]: action.textSets};
    case types.ADD_HEADER_TO_TEXT_SET_SUCCESS: {
      // We're going to create a copy of the current slug's text sets
      const slug = action.payload.slug;
      const existingTextSets = cloneDeep(state[slug]);
      // Figure out which in the list we want to add a new TSAH to
      const matchingTextSet = find(existingTextSets, ['id', action.payload.textSetId]);
      // Then actually add it to that Text Set
      const newTSAH = {
        article_header_id: action.payload.articleHeaderId,
        text_set_id: action.payload.textSetId,
        id: action.payload.id,
      };
      matchingTextSet.text_set_article_headers.push(newTSAH);
      // And save the copy onto state!
      return {...state, [slug]: existingTextSets};
    }
    case types.REMOVE_HEADER_FROM_TEXT_SET_SUCCESS: {
      // We're going to copy the current slug's text sets
      const slug = action.payload.slug;
      const existingTextSets = cloneDeep(state[slug]);
      // Figure out which in the list we want to remove the TSAH from
      const matchingTextSet = find(existingTextSets, ['id', action.payload.textSetId]);
      // Then remove it from that list
      const updatedTSAHs = reject(
        matchingTextSet.text_set_article_headers,
        ['article_header_id', action.payload.articleHeaderId]
      );
      matchingTextSet.text_set_article_headers = updatedTSAHs;
      // And save the copy back onto state!
      return {...state, [slug]: existingTextSets};
    }
    default:
      return state;
  }
}

export default textSet;
