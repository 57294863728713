import {get, find} from 'lodash-es';

const storeKey = 'v2.services.objects.collections';

/**
 * @param {Object} state
 * @returns {Array.object}
 */
export const getCollections = (state) => get(state, `${storeKey}.items`, []);

/**
 * @param {Object} state
 * @returns {Array.object}
 */
export const getLicensedCollectionsFromUser = (state) => get(state, 'user.licensed_collections', []);

/**
 * @param {Object} state
 * @returns {String}
 */
export const getCollectionsError = (state) => get(state, `${storeKey}.error`, null);

/**
 *
 * @param {Object} state
 * @returns {Boolean}
 */
export const getAreCollectionsLoading = (state) => get(state, `${storeKey}.isLoading`, false);

/**
 * @param {Object} state
 * @param {Number} id
 * @returns {Number}
 */
export const getCollectionById = (state, id) => find(getCollections(state), {id});
