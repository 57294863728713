
import {watchInitSignUp, watchInitSendVerificationEmail, watchInitRoleCreation, watchInitUpdateUser} from './signUpSagas';

const signUpSagas = [
  watchInitSignUp,
  watchInitSendVerificationEmail,
  watchInitRoleCreation,
  watchInitUpdateUser
];

export default signUpSagas;
