import React from 'react';

import {ButtonWithPopOut, constants, useWindowSize, useButtonWithPopOut} from '@newsela/angelou';
import {
  map,
  forEach,
  isEmpty,
  toNumber,
} from 'lodash-es';
import PropTypes from 'prop-types';

import withTrackEvent from 'static/three-oh/src/components/with/WithTrackEvent';
import TrackEventProps from 'static/three-oh/src/components/with/WithTrackEventConstants';
import useUpdateEffect from 'utils/useUpdateEffect';

import styles from './styles';

const ButtonWithPopOutWithTrackEvent = withTrackEvent(ButtonWithPopOut);

const propTypes = {
  ...TrackEventProps,
  buttonContents: PropTypes.node.isRequired,
  currentOptionValue: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  menuOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    lang: PropTypes.string,
    dataQaSelector: PropTypes.string
  })).isRequired,
  onSelectedOption: PropTypes.func,
  ariaProps: PropTypes.shape({
    buttonAriaProps: PropTypes.object,
    menuOptionsProps: PropTypes.object
  }).isRequired,
  __cssFor: PropTypes.shape({
    root: PropTypes.object,
    Button: PropTypes.object,
    PopOut: PropTypes.object
  }),
  __classNameFor: PropTypes.object,
  eventProperties: PropTypes.object,
  contentId: PropTypes.string,
  skipLegacyEvent: PropTypes.bool
};

const ButtonWithPopOutMenu = (props) => {
  const {
    buttonContents,
    currentOptionValue,
    id,
    menuOptions,
    onSelectedOption,
    ariaProps: {
      buttonAriaProps,
      menuOptionsProps
    },
    __cssFor,
    __classNameFor,
    eventProperties,
    contentId,
    objectType,
    actionPrefix,
    actionName,
    streamProperties,
    skipLegacyEvent
  } = props;
  const {width: windowWidth} = useWindowSize();
  const {
    breakpoints: {
      Breakpoints: {sm},
    },
  } = constants;
  const isMobile = windowWidth <= sm;

  const buttonRef = React.useRef();
  const controllingProps = useButtonWithPopOut({buttonRef});
  // These utility selectors will be based on the unique ID prop
  const selectedMenuId = `${id}_selected`;
  const menuItemClassName = `${id}_class`;

  useUpdateEffect(() => {
    if (controllingProps.isPopOutVisible) {
      const selectedMenuItem = document.getElementById(selectedMenuId);

      // Apply focus to the selected option if the button menu is visible.
      if (selectedMenuItem) {
        selectedMenuItem.focus();
      }
    } else if (!controllingProps.isPopOutVisible) {
      // Else, if the menu is closed, apply focus to the button.
      buttonRef?.current?.focus();
    }
  }, [controllingProps.isPopOutVisible]);

  const handleKeyDownEventForButton = (event) => {
    const isArrowUpKey = event.key === 'ArrowUp';
    const isArrowDownKey = event.key === 'ArrowDown';

    if (isArrowUpKey || isArrowDownKey) {
      // Open the menu.
      controllingProps.setIsPopOutVisible(true);
    }
  };

  const handleSelection = (menuOption) => {
    controllingProps.setIsPopOutVisible(false);
    if (onSelectedOption) {
      onSelectedOption(menuOption.value);
    }
  };

  const handleKeyDownEventForMenuItem = (event, listItemIndexNumber, menuOption) => {
    const isArrowUpKey = event.key === 'ArrowUp';
    const isArrowDownKey = event.key === 'ArrowDown';
    const isEnterKey = event.key === 'Enter';
    const isSpaceKey = event.key === ' ';
    const isTabKey = event.key === 'Tab';

    if (!isTabKey) {
      event.preventDefault();
    }

    if (isArrowUpKey) {
      // Apply focus to the previous menuOption in the menu.
      applyFocusToOption(listItemIndexNumber - 1);
    } else if (isArrowDownKey) {
      applyFocusToOption(listItemIndexNumber + 1);
      // Apply focus to the next menuOption in the menu.
    } else if (isEnterKey || isSpaceKey) {
      handleSelection(menuOption);
    } else if (isTabKey) {
      controllingProps.setIsPopOutVisible(false);
    }
  };

  const applyFocusToOption = (listItemIndexNumber) => {
    const optionsAsNodes = document.querySelectorAll(`.${menuItemClassName}`);

    if (isEmpty(optionsAsNodes)) {
      return;
    }

    forEach(optionsAsNodes, (optionNode) => {
      if (toNumber(optionNode.getAttribute('data-index-number')) === listItemIndexNumber) {
        optionNode.focus();
      }
    });
  };

  const isOptionSelected = (menuOption, currentOptionValue) => {
    const menuOptionLexile = menuOption.lexile_level;

    if (currentOptionValue?.includes('MAX') && menuOption?.label?.includes('MAX')) {
      // uses includes to handle when the option is MAX and contain languages
      return true;
    }

    if (menuOptionLexile) {
      // handles for lexile selector cases both when the language is inlcuded in the label and when it is not
      return `${menuOptionLexile}L` === currentOptionValue;
    }

    return menuOption.value == currentOptionValue;
  };

  const popOutContents = (
    <ul
      className={styles.menuOptionsContainer}
      tabIndex="-1"
      role="listbox"
      {...menuOptionsProps}
    >
      {
        map(menuOptions, (menuOption, index) => {
          const label = menuOption.label;
          const selectedOption = isOptionSelected(menuOption, currentOptionValue);
          const dataQaSelector = menuOption.dataQaSelector ? menuOption.dataQaSelector : null;
          const dataValue = menuOption.dataValue ? menuOption.dataValue : null;
          return (
            <li
              className={`${styles.menuOption(selectedOption)} ${menuItemClassName}`}
              onClick={() => {
                handleSelection(menuOption);
              }}
              role="option"
              key={label}
              aria-selected={Boolean(selectedOption)}
              data-index-number={index}
              id={selectedOption ? selectedMenuId : `${menuOption.lexile_level}L`}
              tabIndex={-1}
              onKeyDown={(event) => handleKeyDownEventForMenuItem(event, index, menuOption)}
              value={dataValue}
            >
              {selectedOption && <div className={styles.selectedBar}/>}
              <span data-qa-selector={dataQaSelector} className={styles.label(selectedOption)} lang={menuOption.lang}>{label}</span>
            </li>
          );
        })
      }
    </ul>
  );

  return (
    <ButtonWithPopOutWithTrackEvent
      buttonContents={buttonContents}
      popOutContents={popOutContents}
      onKeyDown={(event) => handleKeyDownEventForButton(event)}
      id={id}
      ref={buttonRef}
      {...controllingProps}
      ariaProps={buttonAriaProps}
      __cssFor={__cssFor}
      horizontalPopOutPosition={isMobile ? 'RIGHT' : 'LEFT'}
      __classNameFor={__classNameFor}
      eventProperties={eventProperties}
      content_id={contentId}
      actionPrefix={actionPrefix}
      actionName={actionName}
      objectType={objectType}
      streamProperties={streamProperties}
      skipLegacyEvent={skipLegacyEvent}
    />
  );
};

ButtonWithPopOutMenu.propTypes = propTypes;
ButtonWithPopOutMenu.displayName = 'ButtonWithPopOutMenu';

export default ButtonWithPopOutMenu;
