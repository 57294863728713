import {get} from 'lodash-es';
import {takeLatest, call, fork, put, select} from 'redux-saga/effects';

import {ReportingApi} from 'static/three-oh/src/modules/apis';
import ApiFetcher3 from 'static/three-oh/src/modules/apis/ApiFetcher3';
import {toReadingSummaryReportingFilters} from 'static/three-oh/src/routes/Binder/modules/selectors/filterSelectors';

import {studentTabActions as actions} from '../actions';
import {studentTabActionTypes} from '../constants';
import {studentTabSelectors} from '../selectors';


function * getStudentData(binderFilters, showAssignedWorkOnly) {
  const reportingFilters = toReadingSummaryReportingFilters(binderFilters, showAssignedWorkOnly);

  // Measures with well behaved filters:
  const studentQueryParams = {
    measures: [
      'count:header_views',
      'sum:reading_time',
      'avg:grade_level',
      'avg:quiz_score',
      'count:quizzes',
      'avg:write_response_score',
      'count:write_response_score',
      'count:annotations',
      'count:vocabulary_questions_correct',
      'count:vocabulary_questions',
    ],
    dimensions: [
      'student_id',
    ],
    filters: reportingFilters,
  };

  try {
    yield put(actions.getBinderStudentsRequest());
    const [students] = yield [
      call(ReportingApi.getReport, studentQueryParams),
    ];
    yield put(actions.getBinderStudentsSuccess({data: students.data, filters: reportingFilters}));
  } catch (error) {
    yield put(actions.getBinderStudentsFailure(error));
  }
}

export function * getStudentReadingLevels(classrooms, binderFilters) {
  try {
    yield put(actions.getStudentReadingLevelsRequest());

    const classroomId = get(binderFilters, 'classroomUUIDs.value[0]');
    const selectedClassroom = (classroomId === 'all') ? classrooms[0].unique_id : classroomId;
    const loadedReadingLevels = yield select(studentTabSelectors.getBinderStudentLoadedReadingLevels);

    if (loadedReadingLevels[selectedClassroom] !== undefined) {
      return yield put(actions.getStudentReadingLevelsSuccess({data: loadedReadingLevels[selectedClassroom], classroomId: selectedClassroom}));
    }

    const classroomReadingLevels = yield call(async() => {
      const response = await ApiFetcher3.get('reading-level/', {classroom_id: selectedClassroom});
      const {data: {results}} = response;
      return results;
    });
    yield put(actions.getStudentReadingLevelsSuccess({data: classroomReadingLevels, classroomId: selectedClassroom}));
  } catch (error) {
    yield put(actions.getStudentReadingLevelsFailure(error));
  }
}


export function * watchInitStudentTab() {
  yield takeLatest(studentTabActionTypes.INITIALIZE_STUDENT_TAB, function * init(action) {
    const {binderFilters, showAssignedWorkOnly} = action.payload;
    yield fork(getStudentData, binderFilters, showAssignedWorkOnly);
  });
}

export function * watchRequestStudentReadingLevels() {
  yield takeLatest(studentTabActionTypes.REQUEST_READING_LEVELS, function * init(action) {
    const {classrooms, binderFilters} = action.payload;
    yield fork(getStudentReadingLevels, classrooms, binderFilters);
  });
}
