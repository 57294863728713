export const getTrialBannerDaysHoursText = ({
  daysToExpiration,
  hoursToExpiration,
}) => {
  const hasHalfDayPassed = (hoursToExpiration % 24) > 12;
  const daysDisplayed = hasHalfDayPassed ? daysToExpiration + 1 : daysToExpiration;

  if (daysToExpiration === 0) {
    if (hoursToExpiration === 0) return 'less than an hour ';
    if (hoursToExpiration === 1) return '2 hours ';
    if (hoursToExpiration > 1) return `${hoursToExpiration + 1} hours `;
  }
  if (daysDisplayed === 1) return '1 day ';
  return `${daysDisplayed} days `;
};
