import * as types from 'modulesV2/constants';

export const fetchEmails = () => ({type: types.FETCH_USER_EMAILS_REQUEST});

export const fetchEmailsSuccess = (data) => ({
  type: types.FETCH_USER_EMAILS_SUCCESS,
  data,
});

export const fetchEmailsError = (error) => ({
  type: types.FETCH_USER_EMAILS_ERROR,
  error,
});

export const updateEmail = (id, data) => ({
  type: types.UPDATE_EMAIL_REQUEST,
  id,
  data,
});

export const updateEmailSuccess = (data) => ({
  type: types.UPDATE_EMAIL_SUCCESS,
  data,
});

export const updateEmailError = (error) => ({
  type: types.UPDATE_EMAIL_ERROR,
  error,
});

export const createEmail = (address) => ({
  type: types.CREATE_EMAIL_REQUEST,
  data: {
    address,
  }
});

export const createEmailSuccess = (data) => ({
  type: types.CREATE_EMAIL_SUCCESS,
  data,
});

export const updateEmailsInStore = (data) => ({
  type: types.UPDATE_EMAILS_IN_STORE_ONLY,
  data,
});
