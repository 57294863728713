import React from 'react';

import {Combobox} from '@newsela/angelou';
import PropTypes from 'prop-types';

import {US_STATES} from 'constants/locations';

const propTypes = {
  additionalClassName: PropTypes.object,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onSelectedItemChange: PropTypes.func,
  selectedItem: PropTypes.object,
  touched: PropTypes.bool,
  __css: PropTypes.any,
  dataQaSelector: PropTypes.string,
};

const defaultProps = {
  label: 'State',
  name: 'state',
  dataQaSelector: 'state'
};

/*
 * This component is a state combobox using Angelou's combobox.
 * This can be used in html forms and can also be used with Formik.
 */
const StateCombobox = (props) => {
  const {additionalClassName, selectedItem, touched, error, onBlur, onSelectedItemChange, __css, name, label, dataQaSelector} = props;

  const itemToString = (item) => item ? item.name : '';

  return (
    <Combobox
      __classNameFor={{
        root: additionalClassName
      }}
      items={US_STATES}
      label={label}
      itemToString={itemToString}
      onSelectedItemChange={(e) => {
        onSelectedItemChange && onSelectedItemChange(e);
      }}
      selectedItem={selectedItem}
      createInputProps={() => ({
        onBlur: () => {
          onBlur && onBlur();
        },
        name: name,
        'data-qa-selector': dataQaSelector
      })}
      isRequired
      touched={touched}
      error={error}
      __cssFor={{
        root: __css
      }}
    />
  );
};

StateCombobox.propTypes = propTypes;
StateCombobox.defaultProps = defaultProps;

export default StateCombobox;
