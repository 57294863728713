import {takeEvery, call, put} from 'redux-saga/effects';

import {ArticleWordApi} from './../apis';
import * as types from './../constants/articleWordActionTypes';
import * as actions from '../actions/articleWordActions';

function * getArticleWords(action) {
  try {
    if (action.headerId) {
      const data = yield call(ArticleWordApi.getByHeaderId, action.headerId);
      yield put(actions.requestArticleWordsByHeaderIdSuccess(data, action.headerId));
    } else if (action.headerIds) {
      const data = yield call(ArticleWordApi.getByHeaderIds, action.headerIds);
      yield put(actions.requestArticleWordsByHeaderIdsSuccess(data, action.headerIds));
    }
  } catch (error) {
    const object = action.headerId || action.headerIds;
    yield put(actions.requestArticleWordsFailure(error, object));
  }
}

export function * watchGetArticleWords() {
  yield takeEvery(types.FETCH_ARTICLE_WORDS_BY_HEADER_ID_LOAD_REQUEST, getArticleWords);
  yield takeEvery(types.FETCH_ARTICLE_WORDS_BY_HEADER_IDS_LOAD_REQUEST, getArticleWords);
}
