import {notificationsActionTypes as types} from '../constants';

export function initializeNotifications(serializationFilters) {
  return {type: types.INITIALIZE_NOTIFICATIONS, ...serializationFilters};
}

export function getNotificationsRequest(serializationFilters) {
  return {type: types.GET_NOTIFICATIONS_REQUEST, serializationFilters};
}

export function getNotificationsSuccess(notifications, serializationFilters, fromLocalStorage = false) {
  const pathName = window.location.pathname;
  return {type: types.GET_NOTIFICATIONS_SUCCESS, notifications, serializationFilters, fromLocalStorage, pathName};
}

export function getNotificationsFailure(error, serializationFilters) {
  return {type: types.GET_NOTIFICATIONS_FAILURE, error, serializationFilters};
}

export function updateStudentWriteNotification(serializationFilters, data) {
  return {type: types.UPDATE_STUDENT_WRITE_NOTIFICATION, serializationFilters, data};
}

export function markNotificationsRead() {
  const serializationFilters = {};
  return {type: types.MARK_NOTIFICATIONS_AS_READ, serializationFilters};
}
