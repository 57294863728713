import {constants} from '@newsela/angelou';
import {css} from 'emotion';

import {NAVBAR_BREAKPOINTS} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';

export const resourceItemsVisibilityBreakpointsCss = css({
  display: 'none',

  [`@media (min-width: ${NAVBAR_BREAKPOINTS.md}px)`]: {
    display: 'flex',
  }
});

export const resourceItemsContainerCss = css({
  display: 'flex',
  alignItems: 'center,',
  gap: '0.5rem',
});

export const assignmentsAnchorCss = {
  root: {
    textDecoration: 'none',
    color: constants.colors.ui.neutral[600],

    ':hover': {
      color: constants.colors.ui.core[700]
    }
  }
};
