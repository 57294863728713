import {get, filter} from 'lodash-es';
import {createSelector} from 'reselect';

import {isObjectArchived} from 'static/three-oh/src/modules/utils/dateHelpers';


const getAllAssignments = (state) => {
  return get(state, 'assignments.itemsV3', null);
};

export const assignmentsLoading = (state) => get(state, 'assignments.isLoading');

export const getAssignedAssignments = createSelector(
  getAllAssignments,
  (assignments) => {
    return filter(assignments, {is_draft: false});
  }
);

export const getAssignmentsForActiveClassrooms = createSelector(
  getAssignedAssignments,
  (assignments) => {
    return filter(assignments, (assignment) => {
      return assignment.classrooms.some((c) => {
        return !isObjectArchived(c);
      });
    });
  }
);

export const getAssignmentsWithActiveAssignees = (state) => {
  const assignments = get(state, 'assignments.itemsV3', []);
  return filter(assignments, (assignment) => {
    const hasActiveClassroom = assignment?.assignment_classrooms?.some((assignmentClassroom) => !isObjectArchived(assignmentClassroom.classroom));
    const hasActiveSmallGroup = assignment?.assignment_groups?.length > 0;
    return hasActiveClassroom || hasActiveSmallGroup;
  });
};
