/* eslint-disable */
import React, {useCallback, useMemo} from 'react';

import {Button, ChevronDownSVG, ChevronUpSVG, CloseSVG, Icon} from '@newsela/angelou';
import {css} from 'emotion';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {
  baseButtonCss,
  closeMenuButtonCss,
  closeMenuIconCss,
  condensedUserButtonVisibilityCss,
  getChevronIconCss,
  getExpandedUserButtonCss,
  getLicensesTextCss,
  getUserInitialsCircleCss,
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/UserProfileMenu/UserProfileButton/UserProfileButton.styles';
import {
  getUserInitials
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/UserProfileMenu/UserProfileMenu.utils';

import {getUser, getUserProperties} from '../../../../../../modules/selectors/userSelectors';
import {getNavMenuButtonAriaProps} from '../../shared/NavButtonWithPopout/NavButtonWithPopout.utils';
import {USER_PROFILE_MENU_ID} from '../UserProfileMenu';
import {getLicensesLists} from '../UserProfilePopout/PopoutSubscriptions/PopoutSubscriptions.utils';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

const UserProfileButton = ({isOpen, setIsOpen}) => {
  const user = useSelector((state) => getUser(state));

  const {
    activeScienceLicense,
    activeSSLicense,
    activeELALicense,
    activeEssentialsLicense,
    hasAccessToLite,
    hasNceTrialCampaign,
    isImpersonated,
  } = useSelector((state) => getUserProperties(state));

  const isLoadingLicenses = !user.licenses;
  if (isLoadingLicenses) return null;

  const [trials, subscriptions] = getLicensesLists(hasNceTrialCampaign, user?.licenses);
  const isLite = hasAccessToLite && !subscriptions?.length && !trials?.length;

  const licenceShorthands = [
    activeELALicense && 'ELA',
    activeSSLicense && 'SS',
    activeScienceLicense && 'SCI',
    activeEssentialsLicense && 'ESS',
    isLite && 'Lite',
  ]
    .filter((shorthand) => shorthand)
    .join(' / ');

  const userInitials = getUserInitials(user.name, user.last_name);
  const toggleShowPopout = useCallback(() => setIsOpen((isOpen) => !isOpen), []);

  const buttonAriaProps = useMemo(
    () => getNavMenuButtonAriaProps({
      isOpen,
      id: USER_PROFILE_MENU_ID,
      ariaLabel: 'User Profile Menu'
    }), [isOpen]);

  return (
    <Button
      className={baseButtonCss}
      onClick={toggleShowPopout}
      flavor={Button.flavors.incognito}
      dataProps={{
        ...buttonAriaProps,
        'data-qa-selector': 'global-nav-profile-menu',
      }}
    >
      {isOpen
        ? (
          <div className={`${css(closeMenuButtonCss)} ${USER_PROFILE_MENU_ID}`}>
            <Icon SvgComponent={CloseSVG} __cssFor={closeMenuIconCss} />
          </div>
          )
        : (
          <div className={`${USER_PROFILE_MENU_ID} ${css(condensedUserButtonVisibilityCss(isLite))}`}>
            {userInitials}
          </div>
          )}

      <div className={`${css(getExpandedUserButtonCss(isOpen, isLite))} ${USER_PROFILE_MENU_ID}`}>
        <div className={`${USER_PROFILE_MENU_ID} ${css(getUserInitialsCircleCss(isOpen, isLite, isImpersonated))}`}>
          {userInitials}
        </div>

        <div className={`${USER_PROFILE_MENU_ID} ${css(getLicensesTextCss(isOpen))}`}>
          {licenceShorthands}
        </div>

        {isOpen
          ? <Icon SvgComponent={ChevronUpSVG} __cssFor={getChevronIconCss(isOpen)}/>
          : <Icon SvgComponent={ChevronDownSVG} __cssFor={getChevronIconCss(isOpen)} />}
      </div>
    </Button>
  );
};

UserProfileButton.propTypes = propTypes;
UserProfileButton.displayName = 'UserProfileButton';
export {UserProfileButton};
