import {get, find} from 'lodash-es';
import {createSelector} from 'reselect';

const userEmailsState = (state) => state.v2.services.objects.userEmails;

/**
 * @param {Object} state
 * @returns {Array.object}
 */
export const getEmails = createSelector(
  [userEmailsState],
  (userEmailsState) => {
    return get(userEmailsState, 'byId', {});
  }
);

/**
 * @param {Object} state
 * @returns {String}
 */
export const getError = createSelector(
  [userEmailsState],
  (userEmailsState) => {
    return get(userEmailsState, 'error', null);
  }
);

/**
 *
 * @param {Object} state
 * @returns {Boolean}
 */
export const getIsLoading = createSelector(
  [userEmailsState],
  (userEmailsState) => {
    return get(userEmailsState, 'isLoading', false);
  }
);

/**
 * @param {Object} state
 * @returns {Object}
 */
export const getPrimaryEmail = createSelector(
  [getEmails],
  (emails) => {
    return find(emails, (email) => email.is_primary) || {};
  }
);

/**
 * @param {Object} state
 * @param {String} id
 * @returns {Object|null}
 */
export const getEmailById = createSelector(
  [getEmails],
  (emails, id) => {
    return get(emails, id, null);
  }
);
