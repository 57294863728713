import React, {Fragment} from 'react';

import {css, cx} from 'emotion';
import PropTypes from 'prop-types';

import TrackEventProps from 'static/three-oh/src/components/with/WithTrackEventConstants';

import ELEVATIONS from '../../constants/Elevations';
import AngelouProps from '../../propTypes/AngelouPropTypes';
import {gridContainerCss} from '../../styles/gridCss';
import Card, {CardWithTrackEvent} from '../Card';

import style from './styles.scss';

const ElevationValues = Object.values(ELEVATIONS).slice(0, -1);

const propTypes = {
  ...AngelouProps,
  ...TrackEventProps,

  /**
   * aria props passed down to the card component
   */
  aria: PropTypes.object,

  /**
   * Determines if the card expands to fill its container or
   * fits the contents given
   */
  fitContent: PropTypes.bool,
  /**
   * elevation of the card. defaults to flat
   */
  elevation: PropTypes.oneOf(ElevationValues),
  /**
   * The left column. used for gridTemplateColumn
   */
  leftWidth: PropTypes.string,
  /**
   * The right column. used for gridTemplateColumn
   */
  rightWidth: PropTypes.string,
  /**
   * The element to go on the left
   */
  leftElement: PropTypes.element.isRequired,
  /**
   * The element to go on the right.
   */
  rightElement: PropTypes.element.isRequired,
  /**
   * on Click function that fires when the card is clicked
   */
  onClick: PropTypes.func,

  /**
   * if the card acts as a link, this is where it links to
   */
  href: PropTypes.string,

  /**
   * if there is an href do you want it rendered as a
   * Link tag or an anchor tag? defaults to rendering a Link
   * component
   */
  renderAnchorTag: PropTypes.bool,

  /**
   * an element to lay over the card image, passed in conditionally if content is new to the user
   */
  newBadge: PropTypes.element,
};

export class HorizontalContentCard extends React.Component {
  renderCardContent() {
    let classNames = [style.horizontalContentCard];
    const leftColSpan = this.props.leftWidth;
    const rightColSpan = this.props.rightWidth;
    const gridTemplateCols = gridContainerCss({columns: `${leftColSpan} ${rightColSpan}`});
    classNames = cx(
      classNames.join(' '),
      gridTemplateCols,
    );

    // we need to explicitly set rows and cols for elements
    const getBaseCss = (row, col, colSpan = 1) => css`
      grid-row: ${row};
      grid-column: ${col}/${col + colSpan};
      -ms-grid-column: ${col};
      -ms-grid-row: ${row};
      column-span: ${colSpan};
      -ms-grid-columns: ${colSpan}fr;
      display: inline-grid;
      display: -ms-inline-grid;
      overflow: hidden;
    `;
    const leftCss = cx(getBaseCss(1, 1), this.props.leftCss);
    const rightCss = cx(getBaseCss(1, 2), this.props.rightCss);
    const ctaCss = cx(getBaseCss(2, 1, 2), this.props.ctaCss);

    return (
      <>
        <div
          className={classNames}
        >
          {this.props.newBadge}
          <div
            className={leftCss}
          >
            {this.props.leftElement}
          </div>
          <div
            className={rightCss}
          >
            {this.props.rightElement}
          </div>
          {
            this.props.cta &&
              <div className={ctaCss}>{this.props.cta}</div>
          }
        </div>
      </>
    );
  }

  render() {
    const cardClassNames = [
      style.cardContainer,
      ...this.props.additionalClassNames
    ];
    if (!this.props.fitContent) {
      cardClassNames.push(style.cardContainerFill);
    }


    if (this.props.actionName) {
      return (
        <CardWithTrackEvent
          objectType={this.props.objectType}
          actionPrefix={this.props.actionPrefix}
          actionName={this.props.actionName}
          eventProperties={this.props.eventProperties}
          trackView={this.props.trackView}
          legacyEventName={this.props.legacyEventName}
          streamProperties={this.props.streamProperties}
          aria={this.props.aria}
          additionalClassNames={cardClassNames}
          elevation={this.props.elevation}
          onClick={this.props.onClick}
          href={this.props.href}
          renderAnchorTag={this.props.renderAnchorTag}
        >
          {this.renderCardContent()}
        </CardWithTrackEvent>
      );
    } else {
      return (
        <Card
          aria={this.props.aria}
          additionalClassNames={cardClassNames}
          elevation={this.props.elevation}
          onClick={this.props.onClick}
          href={this.props.href}
          renderAnchorTag={this.props.renderAnchorTag}
        >
          {this.renderCardContent()}
        </Card>
      );
    }
  }
}

HorizontalContentCard.propTypes = propTypes;

HorizontalContentCard.defaultProps = {
  additionalClassNames: [],
  leftWidth: 'auto',
  rightWidth: 'auto',
};

export default HorizontalContentCard;
