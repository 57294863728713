import {get} from 'lodash-es';

const readPageMode = 'v2.pages.read.readPageMode';

/**
 * @param {Object} state
 * @returns {Object}
 */
export const getReadPageMode = (state) => get(
  state, `${readPageMode}`
);

/**
 * @param {Object} state
 * @param {String} mode
 * @returns {Boolean}
 * Return a boolean indicating if the page mode matches.
 */
export const isSpecifiedMode = (state, mode) => {
  const modeName = get(state, `${readPageMode}.name`);
  return modeName === mode;
};

/**
 * @param {Object} state
 * @returns {Object}
 */
export const getReadPageContext = (state) => get(
  state, 'v2.pages.read.context'
);
