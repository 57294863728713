import {css} from 'emotion';

const standardsContainer = css`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
`;

const toggleContainer = css`
  margin-top: 5px;
  display: flex;
  flex-direction: row;
`;

const collapsed = css`
  overflow-y: hidden;
  height: 36px;
  align-items: center;
`;

const listItem = css`
  list-style-type: none;
`;


export default {
  toggleContainer,
  standardsContainer,
  collapsed,
  listItem,
};
