import ApiFetcher2 from '../ApiFetcher2';

class UserEmailApi {
  /**
   * Fetch list of user emails
   * @returns {Promise}
   */
  static getEmails() {
    const params = {};
    const config = {};
    return ApiFetcher2.get('user/me/email/', {config, params});
  }

  /**
   * Update an existing email
   * @param {Object|null} data
   * @param {Number} emailId
   * @returns {Promise}
   */
  static updateEmail(emailId, data = {}) {
    const params = {};
    return ApiFetcher2.post(`user/me/email/${emailId}/`, {params, data});
  }

  /**
   * Create a new email in the database
   * @param {Object} data
   * @returns {Promise}
   */
  static createEmail(data = {}) {
    const params = {};
    return ApiFetcher2.post('user/me/email/', {params, data});
  }
}

export default UserEmailApi;
