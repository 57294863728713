import * as types from 'modulesV2/constants/';

const initialState = {};

export default function content(state = initialState, action) {
  const {type} = action;
  switch (type) {
    case types.GET_CONTENT_SUCCESS:
      return action.data;
    case types.GET_CONTENT_FAILURE:
      return {
        error: action.error
      };
    default:
      return state;
  }
}
