exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__cardRaised___10DQD,.styles__cardFlat___HWEnK,.styles__cardFlush___FrbKa{width:fit-content;display:flex;background-color:#fff;z-index:0}.styles__cardRaised___10DQD:hover::after,.styles__cardFlat___HWEnK:hover::after,.styles__cardFlush___FrbKa:hover::after,.styles__cardRaised___10DQD:focus::after,.styles__cardFlat___HWEnK:focus::after,.styles__cardFlush___FrbKa:focus::after{opacity:1}.styles__cardRaised___10DQD::after,.styles__cardFlat___HWEnK::after,.styles__cardFlush___FrbKa::after{content:\"\";position:absolute;z-index:-1;top:0;left:0;width:100%;height:100%;opacity:0;transition:all .6s cubic-bezier(0.165, 0.84, 0.44, 1)}a.styles__cardFlush___FrbKa{text-decoration:none}.styles__cardFlush___FrbKa{position:relative;box-shadow:0px 0px 0px rgba(0,0,0,.15)}.styles__cardFlush___FrbKa::after{box-shadow:1px 1px 3px rgba(0,0,0,.15)}a.styles__cardFlat___HWEnK{text-decoration:none}.styles__cardFlat___HWEnK{position:relative;box-shadow:1px 1px 3px rgba(0,0,0,.15)}.styles__cardFlat___HWEnK::after{box-shadow:3px 3px 6px rgba(0,0,0,.15)}a.styles__cardRaised___10DQD{text-decoration:none}.styles__cardRaised___10DQD{position:relative;box-shadow:3px 3px 6px rgba(0,0,0,.15)}.styles__cardRaised___10DQD::after{box-shadow:10px 10px 16px rgba(0,0,0,.15)}", ""]);

// exports
exports.locals = {
	"cardRaised": "styles__cardRaised___10DQD",
	"cardFlat": "styles__cardFlat___HWEnK",
	"cardFlush": "styles__cardFlush___FrbKa"
};