import React from 'react';

import {Anchor, Button} from '@newsela/angelou';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {isSpecifiedMode} from 'modulesV2/selectors';
import withTrackEvent from 'static/three-oh/src/components/with/WithTrackEvent';
import {getUserProperties} from 'static/three-oh/src/modules/selectors/userSelectors';

import {trialButtonCss} from './TrialButtons.styles';
import {useNavigationBarV2Context} from '../../NavigationBar.provider';

const ButtonWithTrackEvent = withTrackEvent(Button);
const AnchorWithTrackEvent = withTrackEvent(Anchor);

const TRIAL_BUTTON_EXPERIMENT_TEXT_MAP = {
  req_school: {
    copy: 'Request school access',
    href: 'https://go.newsela.com/request-upgrade.html',
  },
  req_school_trial: {
    copy: 'Request school access',
    href: 'https://go.newsela.com/REF-2023-04-POC-Request-Upgrade_03-Referral---Version-B.html',
  },
};

const TrialButtons = ({customCss = {}}) => {
  const isPresentation = useSelector((state) =>
    isSpecifiedMode(state, 'PRESENTATION')
  );
  const isViewingFromAssignment = useSelector((state) =>
    isSpecifiedMode(state, 'VIEW')
  );
  const hideNavigation = isPresentation || isViewingFromAssignment;

  const {
    trialModal: {
      trialModalRef,
      setIsTrialModalOpen,
    }
  } = useNavigationBarV2Context();

  const {
    isLiteUser,
    canStartPremiumTrial,
    isTeacher,
    hasActivePremiumTrial,
    isAuthenticated,
    isAdmin,
    getExperimentSegment,
    hasPremiumLicense,
  } = useSelector((state) => getUserProperties(state));

  const canRequestSchoolTrial =
    (isTeacher || isAdmin) &&
    (isLiteUser || hasActivePremiumTrial) &&
    !hasPremiumLicense;

  const referralExperimentVariant = getExperimentSegment('referral_cta_text_3');
  const requestAccessTrialButtonDefinitions = TRIAL_BUTTON_EXPERIMENT_TEXT_MAP[referralExperimentVariant] || TRIAL_BUTTON_EXPERIMENT_TEXT_MAP['req_school'];

  if (!isAuthenticated) return null;

  return (
    <li className={customCss}>
      {!hideNavigation && canStartPremiumTrial && (
        <ButtonWithTrackEvent
          objectType="User"
          actionPrefix="Initiate"
          actionName="Start"
          streamProperties={{description: 'Start free trial modal overlay.'}}
          __cssFor={trialButtonCss}
          onClick={() => setIsTrialModalOpen(true)}
          ref={trialModalRef}
        >
          Start free trial
        </ButtonWithTrackEvent>
      )}
      {!hideNavigation &&
        !canStartPremiumTrial &&
        canRequestSchoolTrial && (
          <AnchorWithTrackEvent
            href={requestAccessTrialButtonDefinitions.href}
            dataProps={{'data-testid': 'requestSchoolAccess'}}
            objectType="School"
            actionPrefix="Initiate"
            actionName="Start"
            streamProperties={{
              description: 'Open school trial request page.',
            }}
            __cssFor={trialButtonCss}
          >
            {requestAccessTrialButtonDefinitions.copy}
          </AnchorWithTrackEvent>
      )}
    </li>
  );
};

TrialButtons.propTypes = {
  customCss: PropTypes.object,
};

TrialButtons.displayName = 'TrialButtons';

export {TrialButtons};
