import * as types from 'modulesV2/constants/';
import BREAKPOINTS from 'static/three-oh/src/constants/BreakPoints';
const breakpointKeys = Object.keys(BREAKPOINTS);

// should size just be a number? we dont want to
// encourage people to pass around strings....?
const getSize = (innerWidth) => {
  let size;
  if (innerWidth <= BREAKPOINTS.xsmall) {
    size = breakpointKeys[0];
  } else if (innerWidth <= BREAKPOINTS.small) {
    size = breakpointKeys[1];
  } else if (innerWidth <= BREAKPOINTS.medium) {
    size = breakpointKeys[2];
  } else {
    size = breakpointKeys[3];
  }
  return size;
};

/**
 * check if window is defined in case of server side rendering
 * default to large size
*/
const initialState = {
  size: getSize(window ? window.innerWidth : 1441),
  width: window ? window.innerWidth : 1441,
};

export default (state = initialState, action) => {
  const {innerWidth, type} = action;
  switch (type) {
    case types.SCREEN_RESIZED:
      return {
        innerWidth: innerWidth,
        size: getSize(innerWidth)
      };
    default:
      return state;
  }
};
