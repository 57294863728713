/* eslint-disable */
import React from 'react';

import {Button, CloseSVG, Icon, SearchSVG} from '@newsela/angelou';
import PropTypes from 'prop-types';

import {
  condensedCloseButtonCss, condensedInputContainerCss,
  condensedPopoutSearchBarVisibilityCss, condensedSearchCloseIconCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SearchMenu/CondensedSearchInput/CondensedSearchInput.styles';
import {
  absoluteSearchIconCss, inputFieldCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SearchMenu/SearchMenu.styles';

const propTypes = {
  inputValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  setShowSuggestions: PropTypes.func.isRequired,
};

const CondensedSearchInput = ({inputValue, onChange, onKeyDown, onClick, setShowSuggestions}) => {
  return (
    <div className={condensedInputContainerCss}>
      <div className={condensedPopoutSearchBarVisibilityCss}>
        <Icon SvgComponent={SearchSVG} __cssFor={absoluteSearchIconCss} />
        <input
          autoComplete="off"
          value={inputValue}
          data-qa-selector="global-nav-search"
          onChange={(event) => {
            onChange(event);
            setShowSuggestions(true);
          }}
          // @ts-expect-error typescript is complaining about the KeyboardEvent
          onKeyDown={onKeyDown}
          className={inputFieldCss}
          placeholder="Search for texts and more"
        />
      </div>

      <Button
        onClick={onClick}
        ariaProps={{'aria-label': 'Close search'}}
        flavor={Button.flavors.incognito}
        __cssFor={condensedCloseButtonCss}
      >
        <Icon SvgComponent={CloseSVG} __cssFor={condensedSearchCloseIconCss} />
      </Button>
    </div>
  );
};

CondensedSearchInput.propTypes = propTypes;
CondensedSearchInput.displayName = 'CondensedSearchInput';
export {CondensedSearchInput};
