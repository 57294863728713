import React from 'react';

import {Combobox} from '@newsela/angelou';
import {map} from 'lodash-es';
import moment from 'moment';
import PropTypes from 'prop-types';

const propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onSelectedItemChange: PropTypes.func,
  selectedItem: PropTypes.object,
  touched: PropTypes.bool,
  __css: PropTypes.any,
  dataQaSelector: PropTypes.string,
};

const defaultProps = {
  label: 'Month',
  name: 'month',
  dataQaSelector: 'month'
};

const MONTHS = map(moment.months(), (name, index) => {
  return {name, value: ++index};
});

/*
 * This component is a month combobox using Angelou's combobox.
 * This can be used in html forms and can also be used with Formik.
 */
const MonthCombobox = (props) => {
  const {selectedItem, touched, error, onBlur, onSelectedItemChange, __css, name, label, dataQaSelector} = props;

  const itemToString = (item) => item ? item.name : '';

  return (
    <Combobox
      items={MONTHS}
      label={label}
      itemToString={itemToString}
      onSelectedItemChange={(e) => {
        onSelectedItemChange && onSelectedItemChange(e);
      }}
      selectedItem={selectedItem}
      createInputProps={() => ({
        onBlur: () => {
          onBlur && onBlur();
        },
        name: name,
        'data-qa-selector': dataQaSelector,
      })}
      isRequired
      touched={touched}
      error={error}
      __cssFor={{
        root: __css
      }}
    />
  );
};

MonthCombobox.propTypes = propTypes;
MonthCombobox.defaultProps = defaultProps;

export default MonthCombobox;
