import React from 'react';

import {LoadingSpinner} from '@newsela/angelou';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';


const LazyAssignmentsContainer = Loadable({
  loader: () => import(
    /* webpackChunkName: "AssignmentsContainer" */
    './containers/AssignmentsContainer'
  ),
  loading: LoadingSpinner,
});


const AssignmentsRoute = (
  <Route
    component={LazyAssignmentsContainer}
    key="assignments"
    path="/assignments"
  />
);

export default AssignmentsRoute;
