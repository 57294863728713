import {isEmpty} from 'lodash-es';
import moment from 'moment';

import {TourEventApi} from 'static/three-oh/src/modules/apis';

export function completeTourEvent(tourName, user) {
  if (!user) return;
  const payload = {};
  payload.step = 'complete';
  payload.user = user;
  payload.tour_name = tourName;
  payload.date_created = moment.utc().format('YYYY-MM-DD HH:mm:ss');
  if (!isEmpty(payload)) {
    payload.name = tourName;
    TourEventApi.post(payload);
  }
}

export function updateTourEvent(tourName, maxSteps, user, userProperties) {
  if (!tourName || !user || !userProperties || !maxSteps) return;


  const initialStepValue = userProperties.getTourEvent(tourName).step;


  const step = initialStepValue === 'complete' || initialStepValue >= maxSteps ? 'complete' : parseInt(initialStepValue) + 1;

  const payload = {};
  payload.user = user;
  payload.tour_name = tourName;
  payload.step = step;
  payload.date_created = moment.utc().format('YYYY-MM-DD HH:mm:ss');

  if (!isEmpty(payload) && initialStepValue !== 'complete') {
    payload.name = tourName;
    TourEventApi.post(payload);
  }
}
