import {css} from 'emotion';

export const hyperlinkStyle = css({
  color: 'white',
  textDecoration: 'none',
  marginLeft: '5px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'white',
  padding: '5px',
  borderRadius: '5px'
});

export const textStyle = css({
  fontFamily: 'Circular',
  fontSize: '16px',
  margin: 0,
  color: 'white'
});

export const rootStyle = {
  height: '40px',
  'div:has(svg)': {
    display: 'inline-flex',
    alignItems: 'center'
  }
};
