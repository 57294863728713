/*
Font families and font sizes.
See official Angelou documentation in Google Drive.

Ensure that this is always consistent with /angelou/styles/fonts.scss
**/


const helvetica = {
  normal: '"Segoe UI", "Helvetica Neue", "Helvetica", Roboto, Arial, sans-serif',
  bold: '"Segoe UI", "HelveticaNeue-Bold", "Helvetica Neue Bold", Helvetica, Roboto, Arial, sans-serif',
};

const roboto = {
  normal: 'Roboto, sans-serif',
  bold: 'Roboto-Bold, sans-serif',
};

const circular = {
  normal: 'Circular, Helvetica, Arial, sans-serif',
};

const sizes = {
  xxl: '2.369rem', // ~38px
  xl: '1.777rem', // ~28px
  l: '1.33rem', // ~21px
  m: '1rem', // 16px
  s: '.875rem', // ~16px
  xs: '.75rem', // ~12px
  base: '16px' // Default browser text size
};

const fonts = {
  helvetica,
  roboto,
  circular,
  sizes
};

export default fonts;
