import {
  CLEAR_READ_PAGE_MODE,
  SET_READ_PAGE_MODE,
  SET_READ_PAGE_ELEMENTS,
  modes,
} from 'modulesV2/constants/';

const defaultState = {name: '', pageElements: {}};

/**
 * @param {Object} state
 * @param {Object} action
 * The purpose of this reducer is to set the mode of the Read Page.
 * Modes modify the functionality and visual state of the page and its elements.
 */
export default function readPageMode(state = defaultState, action) {
  switch (action.type) {
    // TODO: eventually extend this to include the attributes of the mode.
    // Ex. {hideNav: true, customIcon: 'image'}
    // This could be populated from the API to support customer customizations.
    case SET_READ_PAGE_MODE:
      return {
        name: action.modeName,
        pageElements: modes[action.modeName],
      };
    case SET_READ_PAGE_ELEMENTS:
      return {
        ...state,
        pageElements: {
          ...state.pageElements,
          ...action.pageElements,
        },
      };
    case CLEAR_READ_PAGE_MODE:
      return defaultState;

    default:
      return state;
  }
}
