import {map, indexOf, truncate, get} from 'lodash-es';
import moment from 'moment';

import ApiFetcher3 from '../ApiFetcher3';
import ApiFetcher4 from '../ApiFetcher4';


class ReportingApi {
  /*
  Interact with the Newsela Reporting service, which provides an interface
  for aggregating and grouping data instead of working with single objects
  at a time. See https://github.com/newsela/newsela-reporting/.
  */

  static getReport(reportParameters, options = {}) {
    /*
    Usage:

    ReportingApi.getReport({
      measures: ['quiz_score', 'count:annotations'],
      dimensions: ['student_id'],
      filters: {
        student_id: ['c1dc290bd8ff4d60b46ef40c939bbb15', '043550ab22c444dcaa3b8faa15b9f874'],
        classroom_id: 'fd1c369c74cf4aaca866e6432c6af634',
        'header_id:ne': 4
        'date_completed:gt': '2016-07-01'
        'date_completed:le': '2016-08-01'
      }
    });
    */
    // Send the query to the reporting service.
    const params = ReportingApi.buildUrlSearchParamsFromObject(reportParameters);
    return ApiFetcher3.fetcher('GET', 'reporting/', params, null, options).then((resp) => resp.data);
  }

  static getReportPowerWords(reportParameters, options = {}) {
    /*
    Usage:

    ReportingApi.getReport({
      filters: {
        membership_classroom_id: 'fd1c369c74cf4aaca866e6432c6af634',
        date_completed_ge: '2016-07-01'
        date_completed_lt: '2016-08-01'
      }
    });
    */
    const params = ReportingApi.buildUrlSearchParamsFromObject(reportParameters);
    return ApiFetcher4.fetcher('GET', 'reporting/power-words/', params, null, options).then((resp) => resp.data);
  }

  static getReportStudentPowerWords(reportParameters, options = {}) {
    /*
    Usage:

    ReportingApi.getReport({
      filters: {
        student_id: ['c1dc290bd8ff4d60b46ef40c939bbb15', '043550ab22c444dcaa3b8faa15b9f874'],
        date_completed_ge: '2016-07-01'
        date_completed_lt: '2016-08-01'
      }
    });
    */
    const params = ReportingApi.buildUrlSearchParamsFromObject(reportParameters);
    return ApiFetcher4.fetcher('GET', 'reporting/student-power-words/', params, null, options).then((resp) => resp.data);
  }

  static getNoDataCsvReportAsText(students) {
    const header = [
      'Last Name',
      'First Name',
      'Username',
      'Reading Level',
      'Articles Viewed',
      'Average Time in Article',
      'Average Article Reading Level',
      'Count of Quizzes',
      'Average Quiz Score',
      'Count of Write Responses',
      'Average Write Score',
      'Count of Annotations',
      'Average Annotations Per Article',
      'Count of Power Word questions',
      'Average Power Word Score'
    ];

    const rows = [header];

    students.forEach((student) => {
      const lastName = student.student.user.last_name;
      const firstName = student.student.user.first_name;
      const username = student.student.user.username;
      const studentRow = new Array(15);
      studentRow.fill(lastName, 0, 1);
      studentRow.fill(firstName, 1, 2);
      studentRow.fill(username, 2, 3);
      // reading lvl default
      studentRow.fill('No data', 3, 4);
      // articles viewed default
      studentRow.fill(0, 4, 5);
      // avg time in article default
      studentRow.fill('No data', 5, 6);
      // avg article reading lvl default
      studentRow.fill('No data', 6, 7);
      // count of quizzes default
      studentRow.fill(0, 7, 8);
      // avg quiz score default
      studentRow.fill('No data', 8, 9);
      // count of write responses default
      studentRow.fill(0, 9, 10);
      // avg write score default
      studentRow.fill('No data', 10, 11);
      // count of annotations default
      studentRow.fill(0, 11, 12);
      // avg annotations per article
      studentRow.fill('No data', 12, 13);
      // count of power word questions
      studentRow.fill(0, 13, 14);
      // avg power word score
      studentRow.fill('No data', 14, 15);
      rows.push(studentRow);
    });

    let csvContent = '';

    rows.forEach((rowArray) => {
      const row = rowArray.join(',');
      csvContent += row + '\r\n';
    });

    return {data: csvContent};
  }

  static async getCsvReportAsText(reportType, reportParameters) {
    const url = ReportingApi.buildCsvDownloadUrl(reportType, reportParameters);
    const options = {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'text/csv',
        Accept: 'text/csv'
      }
    };

    const response = await fetch(url, options);
    const responseText = await response.text();
    if (!response.ok) {
      return {
        error: response,
      };
    } else {
      return {
        data: responseText
      };
    }
  }

  static buildCsvDownloadUrl(reportType, reportParameters) {
    const parameters = ReportingApi.buildUrlSearchParamsFromObject(reportParameters);
    const filename = ReportingApi.buildFilenameFromObject(reportType, reportParameters);
    parameters.append('type', reportType);
    parameters.append('filename', filename);
    parameters.append('format', 'csv');
    return `/api/v3/reporting/?${parameters}`;
  }

  static buildUrlSearchParamsFromObject(reportParameters) {
    let parameters = null;

    if (typeof reportParameters === 'string') {
      // Allow the query to be specified precisely in case the parameters
      // below cannot express it.
      parameters = new URLSearchParams(reportParameters);
    } else {
      // Assume the report parameters have been defined with an object.
      parameters = new URLSearchParams();
      const defaultParameters = {
        measures: [],
        dimensions: [],
        filters: {}
      };
      reportParameters = {...defaultParameters, ...reportParameters};

      if (reportParameters.measures.length) {
        // Measures are comma-separated.
        parameters.append('measures', reportParameters.measures.join(','));
      }
      if (reportParameters.dimensions.length) {
        // Dimensions are comma-separated.
        parameters.append('dimensions', reportParameters.dimensions.join(','));
      }

      // Filter values are comma-separated to represent a logical OR.
      for (const filter of Object.keys(reportParameters.filters)) {
        let values = reportParameters.filters[filter];
        if (values === undefined) {
          // Drop the parameter. Undefined mean 'don't filter on this'.
          continue;
        }

        if (!Array.isArray(values)) {
          values = [values];
        }

        // Convert any date values to the expected string format.
        values = map(values, (value) => {
          if (moment.isDate(value) || moment.isMoment(value)) {
            return moment(value).toISOString();
          } else {
            return value;
          }
        });

        parameters.append(filter, values.join(','));
      }
    }
    return parameters;
  }

  static buildFilenameFromObject(reportType, reportParameters) {
    let filename = null;
    const args = {};
    if (reportType === 'student_work') {
      args['type'] = 'students';
    } else if (reportType === 'work') {
      args['type'] = 'assignments';
    }
    if (typeof reportParameters !== 'string') {
      const filenameFilters = [
        'date_assigned:ge', 'date_completed:ge',
        'date_assigned:lt', 'date_completed:lt'];
      // Get values of filters relevant to the filename.
      for (const filter of Object.keys(reportParameters.filters)) {
        if (indexOf(filenameFilters, filter) > -1) {
          if (filter === 'date_assigned:ge' || filter === 'date_completed:ge') {
            args['start'] = truncate(reportParameters.filters[filter], {length: 10, omission: ''});
          } else if (filter === 'date_assigned:lt' || filter === 'date_completed:lt') {
            args['end'] = truncate(reportParameters.filters[filter], {length: 10, omission: ''});
          }
        }
      }
      const filename_args = ['type', 'start', 'end'];
      for (const arg of filename_args) {
        const value = get(args, arg, null);
        if (value) {
          filename = filename ? [filename, value].join('_') : value;
        }
      }
    }
    filename = (filename === '') ? 'data.csv' : (filename + '.csv');
    return filename;
  }
}

export default ReportingApi;
