import {takeEvery, call, put} from 'redux-saga/effects';

import {
  getLessonSparkSuccess,
  getLessonSparkFailure
} from 'modulesV2/actions';
import * as types from 'modulesV2/constants';
import {LessonSparkApi} from 'static/three-oh/src/modules/apis';

function * getLessonSpark(action) {
  try {
    const lessonSparkData = yield call(LessonSparkApi.get, action.lessonSparkContentId);
    yield put(getLessonSparkSuccess(lessonSparkData));
  } catch (error) {
    yield put(getLessonSparkFailure(error));
  }
}

export function * watchGetLessonSpark() {
  yield takeEvery(types.GET_LESSON_SPARK_REQUEST, getLessonSpark);
}
