import APIFetcher from '../APIFetcher';

class StudentVocabularyAnswerApi {
  static get(queryParams) {
    const params = APIFetcher.stringifyParams(queryParams);
    return APIFetcher.get(`student-vocabulary-answer/?${params}`);
  }
}

export default StudentVocabularyAnswerApi;
