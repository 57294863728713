import {get} from 'lodash-es';

const userStudies = (state) => {
  const studiesState = get(state, 'studies.studies', []);
  const shape = {};

  studiesState.forEach((study) => {
    const studyShape = {};
    studyShape.slug = study.study.slug;
    studyShape.value = study.value;

    shape[studyShape.slug] = studyShape;
  });
  return shape;
};

const studiesAreLoading = (state) => {
  return state.studies.isLoading;
};

/**
 *
 * @param {object} state
 * @param {string} study
 * @param {string} segment
 */
const userIsInSegementOfStudy = (state, study, segment) => {
  const theStudy = get(state, study);
  return get(theStudy, 'value') === segment;
};

export {
  studiesAreLoading,
  userStudies,
  userIsInSegementOfStudy,
};
