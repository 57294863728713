import {takeLatest, call, put} from 'redux-saga/effects';

import {StudentQuizApi} from 'static/three-oh/src/modules/apis';

import * as actions from './../actions/studentQuizActions';
import * as types from './../constants/studentQuizActionTypes';

function * getStudentQuizzes(action) {
  try {
    let data;
    if (action.queryParams.studentQuizIds) {
      data = yield call(
        StudentQuizApi.getWithStudentQuizIds, action.queryParams
      );
    } else {
      data = yield call(StudentQuizApi.get, action.queryParams);
    }
    yield put(actions.loadStudentQuizzesSuccess(data));
  } catch (error) {
    yield put(actions.loadStudentQuizzesFailure(error));
  }
}

export function * watchGetStudentQuizzes() {
  yield takeLatest(types.STUDENT_QUIZZES_LOAD_REQUEST, getStudentQuizzes);
}
