exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__color-quiz-correct___cbtrR{background-color:#52A821}.styles__color-quiz-incorrect___s3kHm{background-color:#D93F31}.styles__color-science___mEHot{background-color:#55bca6}.styles__color-kids___UyCv1{background-color:#5908f7}.styles__color-money___48C6K{background-color:#fa0045}.styles__color-law___kKlJQ{background-color:#002764}.styles__color-health___XRLg-{background-color:#03a43c}.styles__color-arts___cqnBn{background-color:#E2C925}.styles__color-war-and-peace___4EHl6{background-color:#E76A0E}.styles__color-sports___HBlTZ{background-color:#000000}.styles__color-opinion___vzIrt{background-color:#0027f5}.styles__color-text-set___YVom0{background-color:#106FF3}.styles__color-object-gray___N-Rmw{background-color:#878787}.styles__color-light-gray___FKCG3{background-color:#F8F8F8}.styles__color-middle-gray___J-Y2y{background-color:#BCBCBE}.styles__color-newsela-black___EBsuA{background-color:#333333}.styles__color-dark-bg___8nQ25{background-color:#031E3E}.styles__color-newsela-blue___kjTVH{background-color:#0768fd}.styles__count___Exanr{background-color:#fff;bottom:0;color:#333;font-family:Circular,\"Segoe UI\",Helvetica,Arial,sans-serif;font-size:14px;padding-bottom:8px;position:absolute;text-align:center;width:100%}.styles__number___ZxJJS{font-weight:bold}", ""]);

// exports
exports.locals = {
	"color-quiz-correct": "styles__color-quiz-correct___cbtrR",
	"color-quiz-incorrect": "styles__color-quiz-incorrect___s3kHm",
	"color-science": "styles__color-science___mEHot",
	"color-kids": "styles__color-kids___UyCv1",
	"color-money": "styles__color-money___48C6K",
	"color-law": "styles__color-law___kKlJQ",
	"color-health": "styles__color-health___XRLg-",
	"color-arts": "styles__color-arts___cqnBn",
	"color-war-and-peace": "styles__color-war-and-peace___4EHl6",
	"color-sports": "styles__color-sports___HBlTZ",
	"color-opinion": "styles__color-opinion___vzIrt",
	"color-text-set": "styles__color-text-set___YVom0",
	"color-object-gray": "styles__color-object-gray___N-Rmw",
	"color-light-gray": "styles__color-light-gray___FKCG3",
	"color-middle-gray": "styles__color-middle-gray___J-Y2y",
	"color-newsela-black": "styles__color-newsela-black___EBsuA",
	"color-dark-bg": "styles__color-dark-bg___8nQ25",
	"color-newsela-blue": "styles__color-newsela-blue___kjTVH",
	"count": "styles__count___Exanr",
	"number": "styles__number___ZxJJS"
};