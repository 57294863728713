import {assignmentActionTypes as types} from './../constants';

// Assignments REQUEST and Loading Actions

export function requestAssignments(queryParams, isPolling = false) {
  return {
    type: types.ASSIGNMENTS_LOAD_REQUEST,
    queryParams,
    isPolling,
  };
}

export function requestAssignmentsV3(queryParams, isPolling = false) {
  return {
    type: types.ASSIGNMENTS_LOAD_REQUEST_V3,
    queryParams,
    isPolling,
  };
}

export function initialAssignmentLoadingRequest() {
  return {
    type: types.INITIAL_ASSIGNMENT_LOADING_REQUEST,
  };
}

export function loadAssignmentsSuccess(data) {
  return {
    type: types.ASSIGNMENTS_LOAD_SUCCESS,
    assignments: data,
    receivedAt: Date.now()
  };
}

export function loadAssignmentsSuccessV3(data) {
  return {
    type: types.ASSIGNMENTS_LOAD_SUCCESS_V3,
    assignments: data,
    receivedAt: Date.now()
  };
}

export function loadAssignmentsFailure() {
  return {
    type: types.ASSIGNMENTS_LOAD_FAILURE,
    assignments: [],
    receivedAt: Date.now()
  };
}

export function startAssignmentsPolling(delay, params) {
  return {
    type: types.START_ASSIGNMENTS_POLLING,
    delay,
    params,
  };
}
