import {constants} from '@newsela/angelou';
import {css} from 'emotion';

import {
  NAVBAR_BREAKPOINTS
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';

export const appSwitcherPopoutContainerCss = css({
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem 1rem 1rem 0.5rem !important',
  gap: '0.25rem',
  background: constants.colors.ui.neutral[700],
  borderRadius: '0.5rem',
  boxShadow: '0px 2px 6px 0px rgba(29, 29, 29, 0.16)',
  width: '18.75rem',

  [`@media (max-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
    width: 'inherit',
    borderRadius: '0 0 0.5rem 0.5rem'
  },
});
