import React from 'react';

import PropTypes from 'prop-types';

import {toSnakeCase} from 'static/three-oh/utils/stringUtils';

import uiColors from '../../components/Colors/colors.js';

import {pillLevelCss} from './styles.jsx';

const propTypesPill = {
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  text: PropTypes.string,
};

class Pill extends React.Component {
  render() {
    return (
      <div
        className={pillLevelCss(this.props.backgroundColor, this.props.textColor)}
        data-qa-selector={`${toSnakeCase(this.props.text)}`}
      >
        {this.props.text}
      </div>
    );
  }
}

Pill.propTypes = propTypesPill;
Pill.defaultProps = {
  backgroundColor: uiColors.yellow[1],
  textColor: uiColors.grey_dark[1],
};

export {Pill as default};
