import React from 'react';

import {cx} from 'emotion';
import PropTypes from 'prop-types';

import {FREE_UNLOCK_INSIGHTS_CTA} from 'constants/messages';

import ArticlesTabVeil from './assets/articlesTabVeil.png';
import StudentsTabVeil from './assets/studentsTabVeil.png';
import {
  veilContainer,
  studentsTabVeil,
  firstSentence,
  learnMoreText,
  copySection,
  mockDataTable
} from './styles';

const propTypes = {
  tab: PropTypes.string,
};


const AssignmentSummaryVeil = (props) => {
  const photoOfMockDataTable = (
    props.tab === 'articles' ?
      ArticlesTabVeil :
      StudentsTabVeil
  );

  return (
    <div className={cx(veilContainer, {[studentsTabVeil]: props.tab === 'students'})}>
      <div className={copySection}>
        <div className={firstSentence}>
          <a className={learnMoreText} href={FREE_UNLOCK_INSIGHTS_CTA.url}>{FREE_UNLOCK_INSIGHTS_CTA.message}</a>
        </div>
      </div>
      <img alt="Mock data table." className={mockDataTable} src={photoOfMockDataTable}/>
    </div>
  );
};

AssignmentSummaryVeil.propTypes = propTypes;
export default AssignmentSummaryVeil;
