import {takeEvery, call, put} from 'redux-saga/effects';

import ContentRowApi from 'static/three-oh/src/modules/apis/ContentRow/ContentRowApi';

import * as actions from './../actions/contentRowActions';
import * as types from './../constants/contentRowActionTypes';

/**
 * Watch for the loading of a Content Row (by slug).
 */
function * getContentRow(action) {
  try {
    const data = yield call(ContentRowApi.get, action.slug);
    yield put(actions.requestContentRowSuccess(data));
  } catch (error) {
    yield put(actions.requestContentRowFailure(error));
  }
}

export function * watchGetContentRow() {
  yield takeEvery(types.CONTENT_ROW_REQUEST, getContentRow);
}
