import {css} from 'emotion';


const datePresets = css`
  width: 295px;
  box-sizing: border-box;
`;

const customDates = css`
  padding: 0px 16px;
`;

const dateRangeDivider = css`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, .50);
  line-height: 0.1em;
  margin: 10px 0 20px;
`;

const inputContainer = css`
  height: 48px;
`;

const dateContainerCss = css`
  padding: 0 16px 16px 16px
`;

const filterDateContentCss = css`
  margin-bottom: 16px;

  & div > label {
    color: white;
  }
`;

const dateFieldsetCss = css`
  border: none;
`;

const endDateErrorCss = css`
  font-size: 12px;
  background-color: rgba(255, 0, 0, 0.3);
  border-radius: 3px;
  padding: 2px;
  margin-top: -2px;
  text-align: center;
`;

export default {
  datePresets,
  customDates,
  dateRangeDivider,
  inputContainer,
  dateContainerCss,
  filterDateContentCss,
  dateFieldsetCss,
  endDateErrorCss
};
