import React from 'react';

import {Anchor} from '@newsela/angelou';
import {useHistory} from 'react-router-dom';

import {
  joinNowAnchorCss,
  signInAnchorCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/UserProfileMenu/UserProfileUnauthenticatedItems/UserProfileUnauthenticatedItems.styles';


const UserProfileUnauthenticatedItems = () => {
  const history = useHistory();
  const asPath = `${history.location.pathname}${history.location.search}`;

  return (
    <>
      <Anchor
        __cssFor={joinNowAnchorCss}
        href={`/join?next=${asPath}`}
        dataProps={{'data-qa-selector': 'global-nav-user-join-now'}}
      >
        Join now
      </Anchor>

      <Anchor
        href={`/signin?next=${asPath}`}
        __cssFor={signInAnchorCss}
        dataProps={{'data-qa-selector': 'global-nav-user-sign-in'}}
      >
        Sign in
      </Anchor>
    </>
  );
};

UserProfileUnauthenticatedItems.displayName = 'UserProfileUnauthenticatedItems';
export {UserProfileUnauthenticatedItems};
