import {constants} from '@newsela/angelou';
import {css} from 'emotion';

export const iconAndLabelContainerCss = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '0.5rem',
});

export const supportCenterAnchorCss = {
  root: {
    width: '100%',
    padding: '0.25rem 0.5rem',
    textDecoration: 'none',
    borderRadius: '0.5rem',
    display: 'flex',

    '> div': {
      fontSize: '1rem',
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '0.5rem',
      justifyContent: 'space-between',
      color: constants.colors.ui.neutral[700],

      '> svg': {
        '--icon-stroke-width': 0,
      },
    },

    ':hover': {
      background: '#F1F7FF',
    },

    svg: {
      minWidth: '1.5rem',
      maxWidth: '1.5rem',

      path: {
        stroke: constants.colors.ui.neutral[500],
      },
    },
  },
};
