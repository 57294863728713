import APIFetcher from '../APIFetcher';

class TourEventApi {
  static get(queryParams) {
    const params = APIFetcher.stringifyParams(queryParams);
    return APIFetcher.get(`tour-event/?${params}`);
  }

  static post(payload) {
    return APIFetcher.post('tour-event/', {payload});
  }
}

export default TourEventApi;
