const SCREENSIZE = {
  xsmall: 'xsmall',
  small: 'small',
  medium: 'medium',
  large: 'large',
};

const screenSizeEnum = {};

// TODO swap the default export and change it so
// the screenSizeEnum has breakpoint values instead
// of index
Object.keys(SCREENSIZE).forEach((size, index) => {
  screenSizeEnum[size] = index;
});


export default SCREENSIZE;
export {
  screenSizeEnum
};
