import * as types from './../constants/studentQuizActionTypes';

const initialState = {
  studentQuizzes: {
    dateRequested: null,
    dateLoaded: null,
    isLoading: false,
    items: null,
  },
};

function studentQuizzes(state = initialState.studentQuizzes, action) {
  switch (action.type) {
    case types.STUDENT_QUIZ_POST_SUCCESS:
      return {
        ...state,
        dateRequested: Date.now(),
        isLoading: false,
        dateLoaded: Date.now(),
        items: [
          ...state.items,
          action.data,
        ],
      };

    case types.STUDENT_QUIZ_POST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case types.STUDENT_QUIZZES_LOAD_REQUEST:
      return {
        ...state,
        dateRequested: Date.now(),
        isLoading: true,
        dateLoaded: null
      };

    case types.STUDENT_QUIZZES_LOAD_SUCCESS:
      return {
        ...state,
        dateLoaded: Date.now(),
        isLoading: false,
        items: action.studentQuizzes,
      };

    case types.STUDENT_QUIZZES_LOAD_FAILURE:
      return {...state, isLoading: false, error: action.error};

    default:
      return state;
  }
}

export default studentQuizzes;
