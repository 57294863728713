import {genericModelActionTypes as types} from '../constants';

export function fetchModelsByPrimaryKey({modelName, primaryKeys}) {
  return {
    type: types.FETCH_MODELS_BY_PK,
    payload: {modelName, primaryKeys},
  };
}

export function storeModels({modelName, items}) {
  return {
    type: types.STORE_MODELS,
    payload: {modelName, items},
  };
}
