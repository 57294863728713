import React from 'react';

import {Button} from '@newsela/angelou';
import {css} from 'emotion';
import {includes} from 'lodash-es';
import PropTypes from 'prop-types';

import LazyImage from 'static/three-oh/src/components/LazyImage';
import TrackedLink from 'static/three-oh/src/components/TrackedLink';
import {getCardEventProperties} from 'static/three-oh/src/modules/utils/contentCardHelpers';
import {toSnakeCase} from 'static/three-oh/utils/stringUtils';

import CategoryIndicator from '../CategoryIndicator';
import ContentImpression from '../ContentImpression/ContentImpression';
import LanguageIndicator from '../LanguageIndicator';
import PowerWordsIndicator from '../PowerWordsIndicator';

import styles from './styles.scss';

const propTypes = {
  article: PropTypes.object.isRequired,
  eventSource: PropTypes.string.isRequired,
  eventProperties: PropTypes.object,
  hero: PropTypes.bool,
  searchParameters: PropTypes.string,
  themeName: PropTypes.string,
  useReactRouter: PropTypes.bool,
  visibleByDefault: PropTypes.bool,
  viewArticle: PropTypes.bool,
};

const defaultProps = {
  hero: false,
  eventProperties: {},
  themeName: 'default',
  viewArticle: false,
};


class ArticleCard extends React.Component {
  renderImage = () => {
    return (
      <LazyImage
        alt={this.props.article.image_caption}
        src={this.props.article.image}
        visibleByDefault={this.props.visibleByDefault}
      />
    );
  }

  renderIndicators = () => {
    const hasPowerWords = includes(this.props.article.features, 'power_words');
    return (
      <div className={styles.icons}>
        <LanguageIndicator translations={this.props.article.translations}/>
        <PowerWordsIndicator hasPowerWords={hasPowerWords}/>
      </div>
    );
  }

  render() {
    const article = this.props.article;
    const titleCopySize = this.props.hero ? styles.titleCopyLarge : styles.titleCopyRegular;
    const {eventProperties, contentImpressionEventProperties} =
    getCardEventProperties(this.props.eventProperties, article);
    const attributesClass = this.props.themeName == 'textset' ? styles.attributesTextSet : styles.attributes;
    const searchParameters = this.props.searchParameters || '';

    const linkProps = {};
    if (this.props.useReactRouter) {
      linkProps['to'] = article.url + searchParameters;
    } else {
      linkProps['href'] = article.url + searchParameters;
    }

    return (
      <TrackedLink
        className={styles.titleCopy + ' ' + titleCopySize}
        eventName={this.props.eventSource + '_article_card_click'}
        eventProperties={eventProperties}
        {...linkProps}
      >
        <ContentImpression
          model={this.props.hero ? 'article-header' : 'article-level'}
          id={article.id}
          format={'article-card' + (this.props.hero ? '-hero' : '')}
          eventSource={this.props.eventSource}
          eventProperties={contentImpressionEventProperties}
        >
          <div
            className={styles.card}
            data-qa-selector="text_set_article_card"
          >
            {this.renderImage()}
            <div className={styles.description}>
              <div className={attributesClass}>
                {this.renderIndicators()}
                <CategoryIndicator
                  article={this.props.article}
                  eventSource={this.props.eventSource}
                />
              </div>
              <h3
                className={styles.title}
                data-qa-selector={toSnakeCase(this.props.article.title)}
              >
                {this.props.article.title}
              </h3>
            </div>
            {this.props.viewArticle ? (
              <div
                className={css`
                  display: flex;
                  justify-content: flex-end;
                  padding: 16px;
                `}
              >
                <Button
                  /* AUTOGENERATED TODO: update angelou to new flavor.
                    see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
                    for migration guide. */
                  legacy_flavor={Button.legacy_flavor.link}
                  /* AUTOGENERATED TODO: update angelou to new flavor.
                    see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
                    for migration guide. */
                  legacy_statusColor={Button.legacy_statusColor.black}
                  __cssFor={{
                    root: `
                      text-decoration: none;
                    `
                  }}
                > View Article </Button>
              </div>
            ) : null}
          </div>
        </ContentImpression>
      </TrackedLink>
    );
  }
}

ArticleCard.defaultProps = defaultProps;
ArticleCard.propTypes = propTypes;
ArticleCard.displayName = 'ArticleCard';

export default ArticleCard;
