import {useState, useEffect} from 'react';

export const useFetchUserCollections = () => {
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    const fetchCollections = async() => {
      const response = await fetch('/api/v2/collection');
      const collections = await response?.json();
      setCollections(collections);
    };
    fetchCollections();
  }, []);

  return collections;
};
