import React from 'react';

import {size, first, get} from 'lodash-es';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const propTypes = {
  additionalClassNames: PropTypes.arrayOf(PropTypes.string),
  translations: PropTypes.array,
};

const defaultProps = {
  additionalClassNames: [],
  translations: [],
};

const LanguageIndicator = (props) => {
  if (size(props.translations)) {
    const translation = first(props.translations);
    const translationDisplay = (
      get(translation, 'language_display') || get(translation, 'display_language')
    );
    const classNames = props.additionalClassNames.join(' ');
    return (
      <span
        className={classNames}
        title={`This article is available in ${translationDisplay}.`}
      >
        <span className={styles.language} />
      </span>
    );
  }
  return null;
};

LanguageIndicator.defaultProps = defaultProps;
LanguageIndicator.propTypes = propTypes;

export default LanguageIndicator;
