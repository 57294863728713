import {isFunction} from 'lodash-es';
import {takeEvery, takeLatest, call, put, all} from 'redux-saga/effects';

import {ContentHideApi} from 'static/three-oh/src/modules/apis';

import * as actions from './../actions/contentHideActions';
import * as types from './../constants/contentHideActionTypes';

function * getContentHides(action) {
  try {
    const resp = yield call(ContentHideApi.get, action.queryParams);
    yield put(actions.loadContentHideSuccess(resp?.data || []));
  } catch (error) {
    yield put(actions.loadContentHideFailure(error));
  }
}

export function * watchGetContentHides() {
  yield takeLatest(types.CONTENT_HIDE_LOAD_REQUEST, getContentHides);
}

function * toggleContentHide({
  content_id,
  classroom_id,
  user_id,
  callback,
} = {}) {
  try {
    const resp = yield ContentHideApi.get({
      content_id,
      classroom_id,
      created_by: user_id
    });
    const result = resp.data || [];
    const isHiding = !result?.length;
    if (isHiding) {
      const newContentHide = yield call(ContentHideApi.save, {
        content_id,
        classroom_id,
      });
      result.push(newContentHide.data);
      yield put(actions.toggleContentHideSuccess(result));
    } else {
      yield all(result.map((contentHide) =>
        call(ContentHideApi.delete, {
          contentHideId: contentHide.id,
        })
      ));
      yield all(result.map((contentHide) =>
        put(actions.toggleContentUnhideSuccess(contentHide.id))
      ));
    }
  } catch (error) {
    yield put(actions.toggleContentHideFailure(error));
  } finally {
    if (isFunction(callback)) {
      yield call(callback);
    }
  }
}

export function * watchToggleContentHide() {
  yield takeEvery(types.CONTENT_HIDE_TOGGLE_REQUEST, toggleContentHide);
}
