import React from 'react';

import {typographyStyles} from '@newsela/angelou';
import {css, cx} from 'emotion';
import PropTypes from 'prop-types';

import styles from './styles';


const propTypes = {
  children: PropTypes.node,
  /**
   * `__css` configures the styles of the root `div` element.
   */
  __css: PropTypes.any,
};

/**
 * This is a reusable header component for the registration flow.
 * It includes a reset for the h1 tag and allows for custom
 * css on the root div
 * */
const RegistrationHeaderText = ({children, __css}) => {
  if (!children) {
    return null;
  }

  return (
    <div className={cx(styles.defaultStyles, css(__css))}>
      <h1 className={css(typographyStyles.heading3Obj)}>
        {children}
      </h1>
    </div>
  );
};

RegistrationHeaderText.propTypes = propTypes;

export default RegistrationHeaderText;
