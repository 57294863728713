import React from 'react';

import {css, cx} from 'emotion';
import {isFunction, memoize} from 'lodash-es';
import PropTypes from 'prop-types';

import styles from './styles.scss';

// Placeholder image is an empty, transparent rectangle
const DEFAULT_PLACEHOLDER_IMAGE = `
  data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D
  'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'
  width%3D'{{w}}' height%3D'{{h}}' viewBox%3D'0 0 {{w}} {{h}}'%2F%3E
`;

const getCompressedUrl = memoize((src, compression) => {
  try {
    const imageUrl = new URL(src);
    const imageParams = imageUrl.searchParams;
    imageParams.set('compression', compression);
    return `${imageUrl.origin}${imageUrl.pathname}?${imageParams}`;
  } catch (_) {
    return src;
  }
});


/**
 * ImagePreLoader renders a placeholderImage image {props.placeholder}
 * until the target image {props.src} successfully loaded.
 *
 * placeholderImage image {props.placeholder} will remain rendered
 * if the target image {props.src} failed to load.
 */
class ImagePreLoader extends React.Component {
  static propTypes = {
    compression: PropTypes.number,
    src: PropTypes.string,
    placeholderImage: PropTypes.string,
    onError: PropTypes.func,
    alt: PropTypes.string,
    ariaLabel: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    div: PropTypes.bool,
  };

  static defaultProps = {
    alt: '',
    compression: 100,
    div: false,
    style: {},
    src: DEFAULT_PLACEHOLDER_IMAGE,
    placeholderImage: DEFAULT_PLACEHOLDER_IMAGE,
  };

  state = {
    loaded: false,
  };

  componentDidMount() {
    const src = getCompressedUrl(this.props.src, this.props.compression);
    this.image = new Image();
    /* Leverage browser image loading mechanism */
    this.image.src = src || DEFAULT_PLACEHOLDER_IMAGE;
    this.image.onload = () => {
      this.setState({loaded: true});
    };
    this.image.onerror = () => {
      if (isFunction(this.props.onError)) {
        this.props.onError();
      }
    };
  }

  componentWillUnmount() {
    // This should 'cancel' image loading when a component is being
    // unmounted before the target image fully loaded, thus
    // preventing state change warnings on unmounted component.
    this.image.src = '';
    this.image = null;
  }

  image = null;

  render() {
    const {placeholderImage, div, className} = this.props;
    const src = getCompressedUrl(this.props.src, this.props.compression);
    const loadedClass = this.state.loaded ? styles.loaded : '';
    if (div) {
      // Render a div with the image as a background. Size the div to cover its parent container.
      const imageClassName = cx(
        className,
        loadedClass,
        styles.imageDiv,
        css`background-image: url(${src});`
      );
      return (
        <div
          alt={this.props.alt}
          aria-label={this.props.ariaLabel ? this.props.ariaLabel : this.props.alt}
          style={this.props.style}
          className={imageClassName}
          role="img"
        />
      );
    }
    return (
      <img
        src={this.state.loaded ? src : placeholderImage}
        alt={this.props.alt}
        style={this.props.style}
        className={`${styles.image} ${className} ${loadedClass}`}
      />
    );
  }
}

export default ImagePreLoader;
