import React, {useState} from 'react';

import {
  faExternalLinkAlt
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {Button, constants} from '@newsela/angelou';
import {css} from 'emotion';
import {map, toString, get} from 'lodash-es';
import Divider from 'material-ui/Divider';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';



import StyledModal from 'static/three-oh/src/components/Modal/StyledModal/StyledModal';
import withTrackEvent from 'static/three-oh/src/components/with/WithTrackEvent';
import {READING_LEVELS} from 'static/three-oh/src/routes/Assignment/components/LevelLockingSection/LevelLockingSection';
import {toSnakeCase} from 'static/three-oh/utils/stringUtils';


import styles from './styles';

const ButtonWithTrackEvent = withTrackEvent(Button);
const LinkWithTrackEvent = withTrackEvent(Link);

const propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  togglePrintModal: PropTypes.func.isRequired,
  contentId: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

const defaultProps = {
  isOpen: false
};

/**
 * **classes prop does not work on radio button.**
 * In order for custom styles to work for the radio button,
 * we have to add this pseudo class '&$checked' in the root object AND
 * the key 'checked' in order for the data to pass through.
 * See Material UI v3 demo here: https://v3.material-ui.com/demos/selection-controls/#standalone-radio-buttons
 */
const NewselaRadio = withStyles({
  root: {
    '&$checked': {
      color: constants.colors.ui.core[500],
    },
  },
  checked: {}
})(Radio);

const StyledFormControlLabel = withStyles({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingRight: '12px',
    cursor: 'pointer',
    color: constants.colors.ui.neutral[600]
  }
})(FormControlLabel);

const PrintContentModal = (props) => {
  const [gradeLevel, setGradeLevel] = useState('12');

  const handleNewGradeSelection = (event) => {
    setGradeLevel(event.target.value);
  };

  const renderReadingLevels = () => {
    return (
      <FormControl
        aria-labelledby="reading-level"
        classes={{
          root: css(styles.formControl)
        }}
      >
        <RadioGroup
          id="reading-level"
          aria-label="Reading Level"
          name="reading-levels"
          value={gradeLevel}
          onChange={handleNewGradeSelection}
        >
          {map(READING_LEVELS, (level) => {
            const rowContent = (
              <div
                className={styles.gradeAndLexileLevels}
                key={level.gradeNumber}
              >
                <div
                  data-qa-selector={toSnakeCase(level.gradeLabel)}
                >
                  {level.gradeLabel}
                </div>
                <div>{level.lexileLabel}</div>
              </div>
            );

            return (
              <StyledFormControlLabel
                value={toString(level.gradeNumber)}
                control={<NewselaRadio />}
                label={rowContent}
                key={level.gradeNumber}
                className={css(styles.focusedTabbing)}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  const getPrintLink = () => {
    return `/content/${props.contentId}/print/?grade_level=${gradeLevel}&format=pdf`;
  };

  const renderFooter = () => {
    const additionalEventProperties = {
      content_id: props.contentId,
      content_type: props.contentType,
      search_id: get(props.location.state, 'search_id'),
    };

    return (
      <>
        <ButtonWithTrackEvent
          eventProperties={additionalEventProperties}
          objectType="Content"
          actionPrefix="Cancel"
          actionName="Print"
          legacyEventName="TextSet-ExitPrintModal"
          streamProperties={{
            description: 'Close print modal.',
            ...additionalEventProperties,
          }}
          ariaProps={{
            'aria-label': 'Cancel print'
          }}
          /* AUTOGENERATED TODO: update angelou to new flavor.
           see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
           for migration guide. */
          legacy_flavor={Button.legacy_flavor.solid}
          /* AUTOGENERATED TODO: update angelou to new flavor.
           see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
           for migration guide. */
          legacy_statusColor={Button.legacy_statusColor.tertiary}
          onClick={props.togglePrintModal}
        >
          Cancel
        </ButtonWithTrackEvent>
        <LinkWithTrackEvent
          trackView={false}
          trackHover={false}
          eventProperties={additionalEventProperties}
          objectType="Content"
          actionName="Print"
          legacyEventName="TextSet-PrintBundle"
          streamProperties={{
            description: 'Print content.',
            ...additionalEventProperties
          }}
          to={getPrintLink()}
          rel="noopener noreferrer"
          target="_blank"
          onClick={props.togglePrintModal}
          className={styles.linkButtonStyles}
          aria-label="Print. Opens in a new window."
        >
          <FontAwesomeIcon aria-hidden icon={faExternalLinkAlt} className={styles.externalLinkIcon} />
          <span className={styles.linkButtonTextStyles}>Print</span>
        </LinkWithTrackEvent>
      </>
    );
  };

  const renderBody = () => {
    return (
      <div className={styles.body}>
        <div className={styles.middle}>
          All of the articles in this {props.contentType} will be printed at the closest
          available reading level selected below.
        </div>
        <Divider />
        {renderReadingLevels()}
      </div>
    );
  };

  return (
    <StyledModal
      open={props.isOpen}
      onClose={props.togglePrintModal}
      title="Print"
      body={renderBody()}
      scrollBody
      footer={renderFooter()}
      centered
      disableFocusTrap={false}
      data-qa-selector="print_bundle_modal"
    />
  );
};

PrintContentModal.propTypes = propTypes;
PrintContentModal.defaultProps = defaultProps;

export default PrintContentModal;
