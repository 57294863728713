import {get, every, size, each, isEmpty, groupBy, map} from 'lodash-es';
import moment from 'moment';

import {APIFetcher} from 'static/three-oh/src/modules/apis/';
import {applyTo, applier} from 'utils/lodashHelpers';

/**
 * All available notification types Enum
 *
 * @readonly
 * @enum {string}
 */
export const NOTIFICATION_TYPE = {
  READING_SESSION: 'reading-session',
  STUDENT_QUIZ_SUBMISSION: 'student-quiz-submission',
  STUDENT_VOCABULARY_COMPLETION: 'student-vocabulary-completion',
  STUDENT_ARTICLE_VIEW: 'student-article-view',
  STUDENT_CLASSROOM: 'student-classroom',
  STUDENT_WRITE_RESPONSE: 'student-write-response',
  STUDENT_ANNOTATION: 'student-annotation',
  TEACHER_JOINED: 'teacher-joined',
  TEACHER_COLLEAGUES: 'teacher-colleagues',
};

/**
 * NotificationData type definition.
 * Used to store additional data on student notification objects.
 *
 * @typedef {Object} NotificationData
 * @property {Object} header
 * @property {String} thumbnail_url
 * @property {Object} user
 * @property {Object} article
 * @property {string} display_title
 *
 * Optional properties, presence of these properties depends on the annotation type
 * @property {Object} [quiz]
 * @property {Object} [activity]
 * @property {Object} [annotation]
 * @property {Object} [response]
 * @property {Object} [article]
 */

/**
 * Notification Type entity definition
 *
 * @typedef {Object} Notification
 * @property {Array.<Object>} assignments
 * @property {Array.<Object>} classrooms
 * @property {string} verb
 * @property {NotificationData} data
 * @property {string} date_created
 * @property {string} object
 * @property {NOTIFICATION_TYPE} type
 * @property {string} id
 * @property {string} subject
 */

/**
 * @typedef {Object} Group
 * @property {Array.<Object>} notifications
 */

/**
 * User based grouping definition
 *
 * @typedef {Object} UserGroup
 * @extends {Group}
 * @property {string} subject
 * @property {string} subjectModifier
 * @property {string} subjectType
 */

/**
 * Notification type based grouping
 *
 * @typedef {Object} TypeGroup
 * @extends {Group}
 * @property {string} type
 * @property {string} verb
 * @property {string} object
 * @property {Boolean} isViewed
 * @property {Array.<UserGroup>} userGroups
 * @property {Array.<Notification>} notifications
 */

/**
 * @typedef {Object} NotificationGroup
 * @extends {Group}
 * @property {string} date
 * @property {Array.<TypeGroup>}
 */

/**
 * @typedef {Object} DateGroup
 * @extends {Group}
 * @property {string} date
 */

/**
 * @description
 * Groups given notifications by notification creation date
 *
 * @param {Array}notifications
 * @return {Array.<DateGroup>}
 * @private
 */
export function _groupByCreationDate(notifications) {
  return applyTo(notifications)(
    applier(groupBy)((notification) => moment(notification.date_created).startOf('day').toString()),
    applier(map)((group) => ({
      date: moment(get(group, '[0].date_created')),
      notifications: group,
    }))
  );
}

/**
 * @description
 * Groups all notifications within the passed group by following fields:
 * - type
 * - verb
 * - object
 * - response.id
 * - article.id
 * - user.unique_id
 *
 * @param group {Group}
 * @param lastViewedStream {moment}
 * @return {Array.<TypeGroup>}
 * @private
 */
export function _groupTypesFromNotificationGroup(group, lastViewedStream) {
  return applyTo(group.notifications)(
    applier(groupBy)((notification) => {
      const {type, verb, object} = notification;
      const classroom = get(notification, 'classrooms[0].id', '');
      const header = get(notification, 'data.header.id', '');
      const response = get(notification, 'data.response.id', '');
      const groupByKeys = [type, verb, object, classroom, header, response];

      if (type === 'student-annotation') {
        const annotationArticle = get(notification, 'data.article.id', '');
        const student = get(notification, 'data.user.unique_id', '');
        groupByKeys.push(annotationArticle, student);
      }

      return groupByKeys.join('|');
    }),
    applier(map)((group) => {
      const item = get(group, '0', {});
      return {
        type: item.type,
        verb: item.verb,
        object: item.object,
        header: get(item, 'data.header.id'),
        notifications: group,
        isViewed: every(group, (n) => (
          lastViewedStream.isAfter(n.date_created) || lastViewedStream.isSame(n.date_created))
        ),
      };
    })
  );
}

/**
 * Groups notifications by student, subject, modifier and type.
 *
 * @param {Group} group
 * @return {Array.<UserGroup>}
 * @private
 */
export function _getUserGroupedNotifications(group) {
  return applyTo(group.notifications)(
    applier(groupBy)((notification) => {
      const student = get(notification, 'data.user.unique_id', '');
      const subject = notification.subject;
      const subjectModifier = notification.subject_modifier;
      const subjectType = (notification.subject_type ? notification.subject_type : 'student');
      return [student, subject, subjectModifier, subjectType].join('|');
    }),
    applier(map)((group) => ({
      subject: group[0].subject,
      subjectModifier: group[0].subject_modifier,
      subjectType: (group[0].subject_type ? group[0].subject_type : 'student'),
      notifications: group
    }))
  );
}

/**
 * modifies a group of teacher notifications
 *
 * @param {Group} group
 * @return undefined
 * @private
 */
export function _applyTeacherColleguesGrouping(group) {
  if (size(group.userGroups) !== 1) {
    each(group.notifications, (notification) => {
      if (notification.type === NOTIFICATION_TYPE.TEACHER_COLLEAGUES) {
        notification.verb = notification.verb.replace(/\bis\b/, 'are');
      } else if (notification.type === NOTIFICATION_TYPE.TEACHER_JOINED) {
        notification.subject_modifier = 'Your colleagues ';
      }
    });
  }
}

/**
 *  Index the stream by day and notification type.
 *  So multiple users who did the same thing on the same day are grouped together.
 *
 *  @param {Array.<Notification>} notifications - list of server formatted notification
 *  @param {moment} lastViewedStream
 *
 *  @return {Array.<NotificationGroup>}
 */
export function groupNotifications(notifications, lastViewedStream) {
  // Grouping level 1: group notification that occur on the same date
  const notificationsByDate = _groupByCreationDate(notifications);

  // Grouping level 2: group notifications that
  // are the same type + assignment + classroom + header
  // Eg: multiple students read the same article in the same class
  return each(notificationsByDate, (group) => {
    // {Group} group
    group.typeGroups = _groupTypesFromNotificationGroup(group, lastViewedStream);

    // Grouping level 3, group by a student or a teacher (eg:
    // the same student annotated the same article multiple times)
    each(group.typeGroups, (group) => {
      group.userGroups = _getUserGroupedNotifications(group);
      // Group for teacher-colleagues and teacher-joined verb/subjects.
      // This is 1:1 to the masthead's grouping.
      _applyTeacherColleguesGrouping(group);
    });
  });
}

/**
 * Generates a work URL based on the given notification group and user
 *
 * @param {TypeGroup} notificationGroup
 * @return {string} URL
 * @private
 */
function _studentWorkURL(notificationGroup) {
  const data = get(notificationGroup, 'notifications[0].data');
  const type = get(notificationGroup, 'notifications[0].type');
  const slug = get(data, 'header.slug');
  const studentId = get(data, 'user.student.id');
  const studentUUID = get(data, 'user.student.unique_id');
  const articleId = get(data, 'article.id');
  const assignment = get(notificationGroup, 'notifications[0].assignments[0]');
  const assignmentId = get(assignment, 'id');
  const groupId = get(assignment, 'groups[0].id');
  const classroomId = get(assignment, 'classroom.id') ||
    get(notificationGroup, 'notifications[0].classrooms[0].id');

  let activity;
  switch (type) {
    case NOTIFICATION_TYPE.STUDENT_QUIZ_SUBMISSION:
      activity = 'activities/';
      break;
    case NOTIFICATION_TYPE.STUDENT_VOCABULARY_COMPLETION:
      activity = 'activities/';
      break;
    case NOTIFICATION_TYPE.STUDENT_WRITE_RESPONSE:
      activity = 'activities/';
      break;
    case NOTIFICATION_TYPE.STUDENT_ANNOTATION:
      activity = '';
      break;
  }
  const classroomParam = classroomId ? `classroom=${classroomId}&` : '';
  const groupParam = groupId ? `group=${groupId}&` : '';
  const assignmentParam = assignmentId ? `&assignment=${assignmentId}&` : '';
  const urlPathName = `/read/${slug}/id/${articleId}/${activity}`;
  const urlSearch = `?${classroomParam}${groupParam}${assignmentParam}student=${studentId}&studentUUID=${studentUUID}`;

  return `${urlPathName}${urlSearch}`;
}

/**
 *
 * @param {string} notification group url
 */
export function getNotificationGroupURL(group) {
  const studentWork = _studentWorkURL(group);
  const facultySettings = '/account/faculty/';
  const studentSettings = '/account/students/';
  const groupType = get(group, 'type');

  const TYPE_URL_MAPPING = {
    [NOTIFICATION_TYPE.READING_SESSION]: studentWork,
    [NOTIFICATION_TYPE.STUDENT_QUIZ_SUBMISSION]: studentWork,
    [NOTIFICATION_TYPE.STUDENT_VOCABULARY_COMPLETION]: studentWork,
    [NOTIFICATION_TYPE.STUDENT_ARTICLE_VIEW]: studentWork,
    [NOTIFICATION_TYPE.STUDENT_CLASSROOM]: studentSettings,
    [NOTIFICATION_TYPE.STUDENT_WRITE_RESPONSE]: studentWork,
    [NOTIFICATION_TYPE.STUDENT_ANNOTATION]: studentWork,
    [NOTIFICATION_TYPE.TEACHER_JOINED]: facultySettings,
    [NOTIFICATION_TYPE.TEACHER_COLLEAGUES]: facultySettings,
  };

  return TYPE_URL_MAPPING[groupType];
}


/**
 * DON't use this function to create Request URLs, as it's used only
 * to constructs Redux URL-like  store keys.
 *
 * @param {Object} [serializationFilters] - optional filters
 * @returns {string}
 */
export function getNamespaceForNotificationRequest(serializationFilters) {
  if (isEmpty(serializationFilters)) {
    // Namespace under which all available notifications are stored.
    return 'all';
  }
  const notificationNamespace = APIFetcher.stringifyParams(serializationFilters);
  return notificationNamespace.toString();
}

/**
 * Determine if the the notification group is student work
 * @param {string} notification group type
 * @return {boolean}
 */
export function isStudentWorkNotificationGroup(groupType) {
  const studentWorkTypes = [
    NOTIFICATION_TYPE.STUDENT_QUIZ_SUBMISSION,
    NOTIFICATION_TYPE.STUDENT_VOCABULARY_COMPLETION,
    NOTIFICATION_TYPE.STUDENT_WRITE_RESPONSE,
    NOTIFICATION_TYPE.STUDENT_ARTICLE_VIEW,
    NOTIFICATION_TYPE.STUDENT_ANNOTATION,
  ];
  return studentWorkTypes.includes(groupType);
}
