import moment from 'moment';

export function startOfSchoolYear() {
  const currentMonth = moment().month() + 1;
  const currentYear = moment().year();
  const yearStart = currentMonth < 7 ? currentYear - 1 : currentYear;
  return moment().month(6).startOf('month').year(yearStart); // July 1st
}

export function startOfLastSchoolYear() {
  return startOfSchoolYear().subtract(1, 'year'); // July 1st previous year
}

export function endOfSchoolYear() {
  const currentMonth = moment().month() + 1;
  const currentYear = moment().year();
  const yearEnd = currentMonth < 7 ? currentYear : currentYear + 1;
  return moment().month(5).endOf('month').year(yearEnd); // June 30th
}

export function endOfLastSchoolYear() {
  return endOfSchoolYear().subtract(1, 'year'); // June 30th previous year
}

export function isDateNowOrEarlier(object_date) {
  if (object_date == null) {
    return false;
  }

  const today = moment();
  const when = moment(object_date);
  return when.isBefore(today) || when.isSame(today);
}

export function isObjectArchived(thing) {
  return thing?.date_archived ? isDateNowOrEarlier(thing.date_archived) : false;
}

export function laborDay() {
  const firstWeekOfSeptember = startOfSchoolYear()
    .month('September')
    .startOf('month')
    .startOf('week');
  const monday = firstWeekOfSeptember.day('Monday');
  let laborDay;
  // Months are zero indexed
  if (monday.month() === 9 - 1) {
    laborDay = monday;
  } else {
    laborDay = monday.add(1, 'week').day('Monday');
  }
  return laborDay;
}
