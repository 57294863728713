import React, {createContext, useContext} from 'react';

import {useModal} from '@newsela/angelou';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {useFetchUserCollections} from './NavigationBar.hooks';
import {getCustomCollectionsWithHref, getPersonalizedCollectionsWithHref} from './NavigationBar.utils';

const navigationBarV2ContextInitialValue = {
  getters: {
    hasCollections: false,
    hasPDPassCollection: false,
    customCollections: [],
    personalizedCollections: [],
  },
  trialModal: {
    trialModalRef: {current: null},
    setIsTrialModalOpen: () => undefined,
    trialModalProps: {
      isActive: false,
      onEsc: () => undefined,
      closeModal: () => undefined,
      triggerRef: {current: null},
    },
  },
};

const NavigationBarV2Context = createContext(navigationBarV2ContextInitialValue);
const useNavigationBarV2Context = () => useContext(NavigationBarV2Context);

const propTypes = {
  children: PropTypes.node.isRequired,
};

const NavigationBarProvider = ({children}) => {
  const collections = useFetchUserCollections();

  const [trialModalProps, setIsTrialModalOpen, trialModalRef] = useModal(false);

  const customCollectionsWithHref = getCustomCollectionsWithHref(collections);
  const personalizedCollectionsWithHref = getPersonalizedCollectionsWithHref(collections);

  const hasPDPassCollection = !!(collections).find((collection) => collection.slug === 'pd-pass-stream');

  const licenses = useSelector((state) => state.license)?.data || [];
  const hasCollections = !!(licenses).find((license) => license.product === 'collections');

  const getters = {
    hasCollections,
    hasPDPassCollection,
    customCollections: customCollectionsWithHref,
    personalizedCollections: personalizedCollectionsWithHref,
  };

  const trialModal = {
    trialModalRef,
    trialModalProps,
    setIsTrialModalOpen,
  };

  return (
    <NavigationBarV2Context.Provider value={{getters, trialModal}}>
      {children}
    </NavigationBarV2Context.Provider>
  );
};

NavigationBarProvider.propTypes = propTypes;
NavigationBarProvider.displayName = 'NavigationBarProvider';
export {NavigationBarProvider, useNavigationBarV2Context};
