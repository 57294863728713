import ApiFetcher2 from '../ApiFetcher2';

class RosterChoiceApi {
  static get() {
    return ApiFetcher2.get('roster-choice/');
  }

  /**
   * @param {String} provider
   * @returns {Promise}
   */
  static create(provider) {
    return ApiFetcher2.post('roster-choice/', {data: {provider}});
  }

  /**
   *
   * @param {Number} id
   * @param {String} authProvider
   * @returns {Promise}
   */
  static update(id, authProvider) {
    return ApiFetcher2.post(`roster-choice/${id}/`, {data: {provider: authProvider}});
  }
}

export default RosterChoiceApi;
