import {
  keyBy,
  parseInt,
  isNaN,
  has,
  isNil,
  map,
  castArray,
  find,
  filter
} from 'lodash-es';
import moment from 'moment';
import {createSelector} from 'reselect';

import {CC_ANCHOR_STANDARDS} from 'static/three-oh/src/modules/constants/standards';
import {getClassrooms} from 'static/three-oh/src/modules/selectors/classroomSelectors';
import {getLicensedCollectionsFromUser} from 'static/three-oh/src/modulesV2/selectors/collectionsSelectors';
import {thisYearRange} from 'static/three-oh/src/routes/Binder/components/LeftRail/components/DateFilter/DateFilter';
import {applyTo, applier} from 'utils/lodashHelpers';

const STANDARDS_BY_SLUG = keyBy(CC_ANCHOR_STANDARDS, 'slug');

const getQueryParameter = (paramName, queryString) => {
  const queryParams = new URLSearchParams(queryString);
  return queryParams.get(paramName);
};

const parseDateOrDefault = (dateString, defaultDate) => {
  const parsedDate = moment(dateString, 'YYYY-MM-DD');
  if (parsedDate.isValid()) {
    return parsedDate.format('YYYY-MM-DD');
  }
  return defaultDate.format('YYYY-MM-DD');
};

const {since: startOfSchoolYear, until: endOfSchoolYear} = thisYearRange;

const parseSinceFilter = (queryString) => {
  // Fallback to the beginning of the school year if no since filter is specified.
  const since = getQueryParameter('since', queryString);
  return parseDateOrDefault(since, startOfSchoolYear);
};

const parseUntilFilter = (queryString) => {
  // Fallback to the end of the school year if no until filter specified.
  const until = getQueryParameter('until', queryString);
  return parseDateOrDefault(until, endOfSchoolYear);
};

const parseClassroomFilter = (queryString) => {
  const classroomIdString = getQueryParameter('classroom_id', queryString);
  const parsedClassroomId = parseInt(classroomIdString);
  if (isNaN(parsedClassroomId)) {
    return 'all';
  }
  return parsedClassroomId;
};

const parseSkillFilter = (queryString) => {
  const skillFilter = getQueryParameter('skill', queryString);
  if (has(STANDARDS_BY_SLUG, skillFilter)) {
    return skillFilter;
  }
  return 'all';
};

const parseActivityTypeFilter = (queryString) => {
  const activityType = getQueryParameter('activity_type', queryString);
  return (activityType == 'assigned' ? 'assigned' : 'all');
};

const parseReadingSummarySubjectFilter = (queryString) => {
  const subjects = getQueryParameter('subjects', queryString);
  return isNil(subjects) ? ['all'] : subjects.split(',');
};

const parseReadingSummaryTabFilter = (queryString) => {
  const type = getQueryParameter('type', queryString);
  return type || 'summary';
};

const getBinderFilters = createSelector(
  [
    (state, queryString) => parseSinceFilter(queryString),
    (state, queryString) => parseUntilFilter(queryString),
    (state, queryString) => parseClassroomFilter(queryString),
    (state, queryString) => parseSkillFilter(queryString),
    (state, queryString) => parseReadingSummarySubjectFilter(queryString),
    (state) => getClassrooms(state),
    (state) => getLicensedCollectionsFromUser(state)
  ],
  (
    sinceFilter,
    untilFilter,
    classroomFilter,
    skillFilter,
    subjectFilter,
    classrooms,
    collections,
  ) => {
    const filters = {};

    // The selected filter dates are presumably for the local timezone, convert them to UTC.
    filters.since = {
      label: sinceFilter,
      value: moment(sinceFilter, 'YYYY-MM-DD').startOf('day').toISOString(),
    };

    filters.until = {
      label: untilFilter,
      value: moment(untilFilter, 'YYYY-MM-DD').endOf('day').toISOString(),
    };

    // Unpack "All" classrooms into an array of classroom IDs.
    // The classroom filter value will be an array with zero or more classroom IDs.
    filters.classroom = {
      label: classroomFilter,
      value: (classroomFilter === 'all' ? map(classrooms, 'id') : castArray(classroomFilter)),
    };

    // Unpack "All" classrooms into an array of classroom UUIDs.
    // The classroomUUIDs filter value will be an array with zero or more classroom UUIDs.
    filters.classroomUUIDs = {
      label: classroomFilter,
      value: (classroomFilter === 'all' ? map(classrooms, 'unique_id') :
        castArray(find(classrooms, {id: classroomFilter})?.unique_id)
      ),
    };

    // The skill filter label will be either a TEK or CC slug. For its value, look up the
    // corresponding CC standard number since that is what the ReportingAPI will be expecting.
    filters.skill = {
      label: skillFilter
    };

    if (skillFilter === 'all') {
      filters.skill.value = null; // No filtering
    } else {
      filters.skill.value = applyTo(CC_ANCHOR_STANDARDS)(
        applier(filter)((standard) => {
          return standard.slug == skillFilter;
        }),
        applier(map)('number')
      );
    }

    filters.subject = {
      label: subjectFilter.toString(),
    };

    if (subjectFilter.includes('all')) {
      filters.subject.value = '';
    } else {
      filters.subject.value = applyTo(collections)(
        applier(filter)((collection) => {
          return subjectFilter.includes(collection.slug);
        }),
        applier(map)('id')
      ).toString();
    }

    return filters;
  }
);

// Each of the binder routes interpret the binder filters a little bit differently.
const toAssignmentReportingFilters = (binderFilters) => {
  const reportingFilters = {
    'date_assigned:ge': binderFilters.since.value,
    'date_assigned:lt': binderFilters.until.value,
    assignment_classroom_id: binderFilters.classroomUUIDs.value,
  };

  if (binderFilters.skill.value) {
    reportingFilters['cc_anchor_standard'] = binderFilters.skill.value;
  }

  return reportingFilters;
};

const toReadingSummaryReportingFilters = (binderFilters, showAssignedWorkOnly) => {
  const reportingFilters = {
    'date_completed:ge': binderFilters.since.value,
    'date_completed:lt': binderFilters.until.value,
    collection_id: binderFilters.subject.value,
  };

  if (showAssignedWorkOnly) {
    reportingFilters['assignment_classroom_id'] = binderFilters.classroomUUIDs.value;
  } else {
    reportingFilters['membership_classroom_id'] = binderFilters.classroomUUIDs.value;
  }

  if (binderFilters.skill.value) {
    reportingFilters['cc_anchor_standard'] = binderFilters.skill.value;
  }

  return reportingFilters;
};

const toPowerWordsReportingFilters = (binderFilters) => {
  return {
    date_completed_ge: binderFilters.since.value,
    date_completed_lt: binderFilters.until.value,
    membership_classroom_id: binderFilters.classroomUUIDs.value,
  };
};

const toStudentDetailsReportingFilters = (binderFilters, studentId) => {
  const reportingFilters = {
    student_id: studentId,
    'date_completed:ge': binderFilters.since.value,
    'date_completed:lt': binderFilters.until.value,
  };

  if (binderFilters.skill.value) {
    reportingFilters['cc_anchor_standard'] = binderFilters.skill.value;
  }

  return reportingFilters;
};

export {
  getBinderFilters,
  toAssignmentReportingFilters,
  toReadingSummaryReportingFilters,
  toPowerWordsReportingFilters,
  toStudentDetailsReportingFilters,
  parseActivityTypeFilter,
  parseReadingSummarySubjectFilter,
  parseReadingSummaryTabFilter,
};
