import React from 'react';

import {LoadingSpinner} from '@newsela/angelou';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';


const LazyHome = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Home" */
      './Home/'
    ),
  loading: LoadingSpinner,
});

const LazyMergeApproval = Loadable({
  loader: () => import(
    /* webpackChunkName: "MergeApproval" */
    './MergeApproval/'
  ),
  loading: LoadingSpinner,
});

const LazyMergeRequestList = Loadable({
  loader: () => import(
    /* webpackChunkName: "OpenMergeRequests" */
    './MergeRequestList/'
  ),
  loading: LoadingSpinner,
});

const LazyMergeRequest = Loadable({
  loader: () => import(
    /* webpackChunkName: "MergeRequest" */
    './MergeRequest/'
  ),
  loading: LoadingSpinner,
});

const LazyProvisionSchools = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ProvisionSchools" */
      './ProvisionSchools/'
    ),
  loading: LoadingSpinner,
});

const LazyPygmalion = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Pygmalion" */
      './Pygmalion/'
    ),
  loading: LoadingSpinner,
});

const LazyUserDash = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "UserDash" */
      './UserDash/'
    ),
  loading: LoadingSpinner,
});

const LazyBulkRoster = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "BulkRoster" */
      './BulkRoster/'
    ),
  loading: LoadingSpinner,
});

const LazyOneRosterOrgs = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "OneRosterOrgs" */
      './OneRosterOrgs/'
    ),
  loading: LoadingSpinner,
});

const LazyContentAccessLookup = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ContentAccessLookup" */
      './ContentAccessLookup/'
    ),
  loading: LoadingSpinner,
});


const ChironRoutes = [
  <Route
    key="Home"
    exact path={['/chiron/']}
    component={LazyHome}
  />,
  <Route
    key="mergeApproval"
    path={['/chiron/merge-approval/:mergeRequestId/']}
    component={LazyMergeApproval}
  />,
  <Route
    key="mergeRequestlList"
    path={['/chiron/merge-approval/']}
    component={LazyMergeRequestList}
  />,
  <Route
    key="mergeRequest"
    path={['/chiron/merge-request/']}
    component={LazyMergeRequest}
  />,
  <Route
    key="provisionSchools"
    path={['/chiron/provision-schools/']}
    component={LazyProvisionSchools}
  />,
  <Route
    key="pygmalion"
    path={['/chiron/pygmalion/']}
    component={LazyPygmalion}
  />,
  <Route
    key="userDash"
    path={['/chiron/user-dash/:userId?/']}
    component={LazyUserDash}
  />,
  <Route
    key="bulkRoster"
    path={['/chiron/bulk-roster/']}
    component={LazyBulkRoster}
  />,
  <Route
    key="oneRosterOrgs"
    path={['/chiron/oneroster/orgs/']}
    component={LazyOneRosterOrgs}
  />,
  <Route
    key="contentAccessLookup"
    path={['/chiron/content-access/']}
    component={LazyContentAccessLookup}
  />,
];

export default ChironRoutes;
