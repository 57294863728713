import ApiFetcher2 from 'static/three-oh/src/modules/apis/ApiFetcher2';

class TrialCampaignApi {
  static post(payload) {
    const {
      slug
    } = payload;

    return ApiFetcher2.post(`trial-campaign/${slug}/user/me/`);
  }
}

export default TrialCampaignApi;
