import {keyBy} from 'lodash-es';

import * as types from './../constants/articleHeaderClassroomActionTypes';

const initialState = {
  articleHeaderClassrooms: {
    dateRequested: null,
    dateLoaded: null,
    isLoading: false,
    items: {},
  },
};

function articleHeaderClassrooms(state = initialState.articleHeaderClassrooms, action) {
  switch (action.type) {
    case types.ARTICLE_HEADER_CLASSROOMS_LOAD_REQUEST:
      return {
        ...state,
        dateRequested: Date.now(),
        isLoading: true,
        dateLoaded: null
      };

    case types.ARTICLE_HEADER_CLASSROOMS_LOAD_SUCCESS:
      return {
        ...state,
        dateLoaded: Date.now(),
        isLoading: false,
        items: {
          ...state.items,
          ...keyBy(action.articleHeaderClassrooms, 'id')
        },
      };

    case types.ARTICLE_HEADER_CLASSROOMS_LOAD_FAILURE:
      return {...state, isLoading: false, error: action.error};

    default:
      return state;
  }
}

export default articleHeaderClassrooms;
