import * as types from 'modulesV2/constants';

export default function rosterChoiceReducer(state = {}, action) {
  switch (action.type) {
    case types.REQUEST_ROSTER_CHOICE_SUCCESS:
      return {
        ...state,
        ...action.data,
        fetching: false,
        error: false,
      };
    case types.REQUEST_ROSTER_CHOICE:
      return {
        ...state,
        fetching: true,
      };
    case types.REQUEST_ROSTER_CHOICE_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}
