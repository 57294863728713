import {map} from 'lodash-es';

import * as types from '../constants/recentNewsActionTypes';
import {convertToArticle} from '../utils/searchApiHelpers';

export function recentNewsRequest(excludeId) {
  return {type: types.RECENT_NEWS_LOAD_REQUEST, excludeId};
}

export function recentNewsRequestSuccess(data) {
  return {
    receivedAt: Date.now(),
    recentNews: map(data.results, convertToArticle),
    type: types.RECENT_NEWS_LOAD_SUCCESS,
  };
}

export function recentNewsRequestFailure() {
  return {
    receivedAt: Date.now(),
    recentNews: [],
    type: types.RECENT_NEWS_LOAD_FAILURE,
  };
}
