import React from 'react';

import {
  Button,
  constants
} from '@newsela/angelou';
import {css} from 'emotion';
import {isEmpty, isFinite, isFunction, map, range, size} from 'lodash-es';
import PropTypes from 'prop-types';

import {MIN_GRADE_LEVEL, MAX_GRADE_LEVEL} from 'constants/gradeLevels';
import {getGradeLabel} from 'utils/gradeLevels';

import {
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Select,
  ErrorSelect,
  validationErrorColor,
} from './FormElements';
import ContentFilterToggle from '../ContentFilterToggle/ContentFilterToggle';

import styles from './styles.scss';

// TODO: Remove constant in favor of API when it's implemented.
export const SUBJECTS = {
  'english-arts-reading': 'English/Language Arts/Reading',
  'english-language-learner': 'English Language Learners',
  'foreign-language': 'Foreign Language',
  'general-elementary': 'General Elementary',
  math: 'Math',
  science: 'Science',
  'social-studies': 'Social Studies/Social Science',
  'special-education': 'Special Education',
  other: 'Other',
};

export const propTypes = {
  createClassroomFunc: PropTypes.func,
  withCta: PropTypes.bool.isRequired,
  setIsReadyToSubmit: PropTypes.func,
  setClassroomProperties: PropTypes.func,
  onClose: PropTypes.func,
  shouldRenderCreateButton: PropTypes.bool.isRequired,
  onBackButtonClick: PropTypes.func,
};

class CreateClassForm extends React.Component {
  state = {
    classroomName: '',
    grade: null,
    subject: '',
    classroomIsElementary: false,
    nameErrorMessage: 'Classroom name cannot be empty.',
    gradeErrorMessage: 'Please select classroom grade.',
    subjectErrorMessage: 'Please select classroom subject.',
    attemptedSubmission: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const {setClassroomProperties} = this.props;
    const shouldUpdateClassProps = (
      prevState.classroomName !== this.state.classroomName ||
      prevState.grade !== this.state.grade ||
      prevState.is_all_ages !== this.state.is_all_ages ||
      prevState.subject !== this.state.subject
    );
    if (isFunction(setClassroomProperties) && shouldUpdateClassProps) {
      setClassroomProperties({
        name: this.state.classroomName,
        grade: this.state.grade,
        is_all_ages: this.state.classroomIsElementary,
        subject_slugs: [this.state.subject],
      });
    }
  }

  handleClassNameChange = (event) => {
    const classroomName = event.target.value;
    let nameErrorMessage = null;
    if (size(classroomName) > 50) {
      nameErrorMessage = 'Classroom name should be 50 characters or less.';
    } else if (size(classroomName) === 0) {
      nameErrorMessage = 'Classroom name cannot be empty.';
    }
    this.setState({
      classroomName,
      nameErrorMessage,
    });
  };

  handleGradeChange = (event) => {
    const selectedGrade = event.target.value;
    // Don't update unless there's actually a change.
    const {gradeErrorMessage, grade} = this.state;
    if (selectedGrade !== grade) {
      const newState = {
        grade: selectedGrade,
        classroomIsElementary: selectedGrade <= 5
      };
      if (!isFinite(selectedGrade)) {
        newState.gradeErrorMessage = 'Please select classroom grade.';
      } else if (gradeErrorMessage) {
        newState.gradeErrorMessage = null;
      }
      this.setState(newState);
    }
  };

  handleSubjectChange = (event) => {
    const selectedSubject = event.target.value;
    const {subject, subjectErrorMessage} = this.state;
    if (selectedSubject !== subject) {
      const newState = {
        subject: selectedSubject,
      };
      if (subjectErrorMessage && selectedSubject) {
        newState.subjectErrorMessage = null;
      }
      if (!subjectErrorMessage && !selectedSubject) {
        newState.subjectErrorMessage = 'Please select classroom subject.';
      }
      this.setState(newState);
    }
  };

  submitButtonDisabled = () => {
    const {classroomName} = this.state;
    const isDisabled = (
      this.state.grade === null ||
      isEmpty(classroomName) ||
      size(classroomName) > 50 ||
      !this.state.subject
    );
    const {setIsReadyToSubmit} = this.props;
    if (isFunction(setIsReadyToSubmit)) {
      setIsReadyToSubmit(!isDisabled);
    }
    return isDisabled;
  };

  getGradeItems() {
    return map(range(MIN_GRADE_LEVEL, MAX_GRADE_LEVEL + 1), (grade) => {
      const grade_label = getGradeLabel(grade);
      return (
        <MenuItem
          key={grade}
          value={grade}
          data-qa-selector={grade}
        >{grade_label}
        </MenuItem>
      );
    });
  }

  getSubjectItems = () => {
    return map(SUBJECTS, (subject, subject_slug) => {
      return (
        <MenuItem
          key={subject_slug}
          value={subject_slug}
          data-qa-selector={subject_slug}
        >
          {subject}
        </MenuItem>
      );
    });
  };

  createClassroom = () => {
    const {createClassroomFunc} = this.props;
    const {
      gradeErrorMessage,
      subjectErrorMessage,
      nameErrorMessage,
    } = this.state;
    if (gradeErrorMessage || subjectErrorMessage || nameErrorMessage) {
      this.setState({attemptedSubmission: true});
      // Validation errors prevent form submission;
      return;
    }

    if (!this.submitButtonDisabled() && isFunction(createClassroomFunc)) {
      const {classroomName, grade, classroomIsElementary, subject} = this.state;
      createClassroomFunc(
        classroomName, grade, classroomIsElementary, [subject]
      );
    }
  };

  getElementaryWidget() {
    return (
      <div
        className={css`
          margin-top: 10px;
        `}
      >
        <FormControl
          style={{
            marginTop: 20,
          }}
        >
          <InputLabel
            shrink
          >
            Elementary Content Filter
          </InputLabel>
          <ContentFilterToggle
            checked={this.state.classroomIsElementary}
            onChange={(toggle) => this.setState({classroomIsElementary: toggle})}
            data-qa-selector="elementary_toggle"
            classes={{iOSIcon: css`&&&& {bottom: 14px; left: -15px;}`}}
          />
          <p
            className={css`
              font-size: 14px;
              margin-top: 10px;
              color: ${this.state.classroomIsElementary ? constants.colors.brand.neutral[700] : constants.colors.ui.neutral[400]};
            `}
          >
            Students will only see articles Newsela recommends for elementary school readers based on subject matter and
            required background knowledge.&nbsp;
            <a
              className={css`
              color: ${constants.colors.ui.core[500]};
              text-decoration: underline;
            `}
              href="https://support.newsela.com/item/supportArticle/classes-tab#set-a-class-to-elementary"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more.
            </a>
          </p>
        </FormControl>
      </div>
    );
  }

  renderActionButtons = () => {
    return (
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
        `}
      >
        {isFunction(this.props.onBackButtonClick) ? (
          <Button
            flavor={Button.flavors.tertiary}
            __cssFor={{
              root: `
                border: none;
                border-radius: 4px;
              `
            }}
            onClick={this.props.onBackButtonClick}
          > Back </Button>
        ) : <div/>}
        <div
          className={css`
            display: flex;
          `}
        >
          <Button
            flavor={Button.flavors.tertiary}
            __cssFor={{
              root: `
                border-radius: 4px;
                border: solid 1px #d9d9d9;
              `
            }}
            onClick={() => {
              this.props.onClose();
            }}
          > Cancel </Button>
          <Button
            flavor={Button.flavors.primary}
            // TODO: ensure events are tracked.
            eventName="create_newsela_class_modal_click"
            type="submit"
            onClick={this.createClassroom}
            __cssFor={{
              root: `
                margin-left: 10px;
                border-radius: 4px;
              `
            }}
            // TODO: ensure QA selector is there.
            qaSelector="create_class_button"
          > Create Class </Button>
        </div>
      </div>
    );
  };

  render() {
    this.submitButtonDisabled();
    const {
      attemptedSubmission,
      nameErrorMessage,
      gradeErrorMessage,
      subjectErrorMessage,
    } = this.state;

    const getValidationErrorMessage = (message) => (
      attemptedSubmission && (
        <div
          className={css`
            color: ${validationErrorColor};
            font-size: 13px;
            line-height: 24px;
            margin-top: 5px;
          `}
        >
          {message}
        </div>
      )
    );
    const GradeSelect = gradeErrorMessage && attemptedSubmission ? ErrorSelect : Select;
    const SubjectSelect = subjectErrorMessage && attemptedSubmission ? ErrorSelect : Select;
    return (
      <div className={styles.form}>
        <FormControl style={{width: '100%'}}>
          <InputLabel
            id="classname-input-label"
            style={{
              // This fixes a known TextField limitation:
              // https://material-ui.com/components/text-fields/#limitations
              marginTop: -12,
            }}
          >
            Classroom Name
          </InputLabel>
          <Input
            type="text"
            name="classname"
            error={nameErrorMessage && attemptedSubmission}
            disableUnderline
            autoFocus
            data-qa-selector="classroom_name_field"
            onChange={this.handleClassNameChange}
            value={this.state.classroomName}
          />
        </FormControl>
        {nameErrorMessage ? getValidationErrorMessage(nameErrorMessage) : null}
        <div
          className={css`
            display: flex;
            margin-top: 30px;
            width: 100%;
            justify-content: space-between;
         `}
        >
          <FormControl
            style={{
              width: '41.2%',
              justifyContent: 'flex-end'
            }}
          >
            <InputLabel
              shrink
              id="grade-select-input-label"
            >
              Grade
            </InputLabel>
            <GradeSelect
              error={gradeErrorMessage && attemptedSubmission}
              disableUnderline
              variant="outlined"
              onChange={this.handleGradeChange}
              value={this.state.grade}
              data-qa-selector="grade_dropdown"
              classes={{root: css`&&& {padding: 6px 0 7px 8px}`}}
            >
              {this.getGradeItems()}
            </GradeSelect>
            {getValidationErrorMessage(gradeErrorMessage)}
          </FormControl>
          <FormControl
            style={{
              width: '53.7%',
            }}
          >
            <InputLabel
              shrink
              id="subjects-select-input-label"
            >
              Subject
            </InputLabel>
            <SubjectSelect
              error={subjectErrorMessage && attemptedSubmission}
              placeholder="Select Subject"
              disableUnderline
              onChange={this.handleSubjectChange}
              value={this.state.subject}
              data-qa-selector="subjects_dropdown"
            >
              {this.getSubjectItems()}
            </SubjectSelect>
            {getValidationErrorMessage(subjectErrorMessage)}
          </FormControl>
        </div>
        {this.getElementaryWidget()}
        {this.props.shouldRenderCreateButton ? this.renderActionButtons() : null}
      </div>
    );
  }
}

CreateClassForm.propTypes = propTypes;
CreateClassForm.defaultProps = {
  withCta: true,
  shouldRenderCreateButton: true,
};
export default CreateClassForm;
