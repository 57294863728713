import React from 'react';

export const getHighlightedSuggestionText = (suggestion, inputValue) => {
  if (!inputValue) return suggestion;
  const cleanedInputValue = inputValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const highlightingRegex = new RegExp(`(${cleanedInputValue})`, 'gi');
  const splitSuggestionString = suggestion.split(highlightingRegex);

  return splitSuggestionString.map((suggestionSegment, index) => {
    if (suggestionSegment.toLowerCase() === inputValue.toLowerCase()) {
      return <>{suggestionSegment}</>;
    } else {
      return <strong key={index}>{suggestionSegment}</strong>;
    }
  });
};
