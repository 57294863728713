import initialState from './initialState';
import {binderActionTypes as types} from '../constants';


export default function binderFiltersReducer(
  state = initialState.binderEnabledFilters, action) {
  /*
  This action and reducer provides a mechanism for changing which filters
  are active. Tabs should dispatch this action with an empty payload to
  enable all filters, or pass a payload the disables them selectively.
  */
  switch (action.type) {
    case types.SET_ENABLED_FILTERS:
      if (action.payload) {
        return {...initialState.binderEnabledFilters, ...action.payload};
      } else {
        return initialState.binderEnabledFilters;
      }

    default:
      return state;
  }
}
