import ApiFetcher2 from '../ApiFetcher2';

class ArticleMediaApi {
  static get() {
    return ApiFetcher2.get('articlemedia/');
  }

  static getAltTextByImageURL(imageURL) {
    if (!imageURL) {
      return null;
    }

    /*
     * Clip the URL so that we query just the path.
     * That is, exclude the query string and leading slash.
     */
    let urlPathClip;
    try {
      urlPathClip = new URL(imageURL).pathname.slice(1);
    } catch (error) {
      urlPathClip = '/broken.jpg';
      if (window.Raven !== undefined) window.Raven.captureException(error);
    }

    const params = {
      media_url: urlPathClip
    };

    return ApiFetcher2.get('articlemedia', {params});
  }
}

export default ArticleMediaApi;
