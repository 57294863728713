import {get, startCase} from 'lodash-es';

export const formatStudentName = (student) => {
  const userObject = student.user || student;
  const studentFirstName = get(userObject, 'first_name');
  const studentLastName = get(userObject, 'last_name');
  return startCase(`${studentFirstName} ${studentLastName}`);
};

export const getLastNameFirstName = (student) => {
  const {first_name, last_name, user} = student || {};
  const firstName = user?.first_name || first_name;
  const lastName = user?.last_name || last_name;

  const lastNameFirstName = [lastName, firstName].filter(Boolean).join(', ');

  return lastNameFirstName.length ? lastNameFirstName : null;
};
