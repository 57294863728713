import React from 'react';

import PropTypes from 'prop-types';


import {toSnakeCase} from 'static/three-oh/utils/stringUtils';

import {contentImpressionFull} from './styles.scss';


const propTypes = {
  // the model type, such as 'article' or 'article-header'
  model: PropTypes.string.isRequired,
  // the primary key of the object, such as an Article ID, or ArticleHeader ID
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  // a description of the format in which the content is presented, which here
  // should be based on the name of the surround component,
  // such as 'article-card'
  format: PropTypes.string,
  // the location or context of the content impression (which should use
  // underscores to be consistent with `eventSource` used to track other kinds
  // of event), such as 'inset_search_results' or 'recent_news_content_row'
  eventSource: PropTypes.string,
  eventProperties: PropTypes.object,
  children: PropTypes.node,
};

const defaultProps = {
  eventProperties: {},
};

export default class ContentImpression extends React.Component {
  /*
  Anything wrapped in this component will be registered with the tracking
  system as a bit of content. Any content that is visible on the screen is
  recorded as an impression.

  This class does not actually track visibility. Rather, it just alerts
  the tracker to its presence using React's `ref` functionality.
  */
  constructor(props) {
    super(props);
    this.deregisterElement = null;
    this.element = null;
    this.updateRef = this.updateRef.bind(this);
  }

  componentDidMount() {
    this.registerElement(this.props);
  }

  componentWillReceiveProps(nextProps) {
    // Occassionally content is initialized late or changed in-place. But we
    // expect everything except the ID of the content to stay the same.
    // Check to see if the ID has changed and track a new impression if so.
    if (nextProps.id && (nextProps.id != this.props.id)) {
      this.registerElement(nextProps);
    }
  }

  componentWillUnmount() {
    if (this.deregisterElement) {
      this.deregisterElement();
      this.element = null;
    }
  }

  registerElement({model, id, format, eventSource, eventProperties}) {
    if (window.tracker && id) {
      window.tracker.register_content_element(
        this.element,
        model, id, format, eventSource, eventProperties, false
      );
    }
  }

  updateRef(element) {
    if (element && (this.element !== element)) {
      // There is a new element.
      if (this.deregisterElement) {
        // And the element was previously registered. Deregister it.
        this.deregisterElement(this.element);
      }

      // All hail the new element.
      this.element = element;

      // Start tracking the visibility of the new element using the global tracker.
      // It returns a function to deregister the element later.
      if (window.tracker) {
        this.deregisterElement = window.tracker.register_content_element(
          this.element,
          this.props.model, this.props.id,
          this.props.format, this.props.eventSource, this.props.eventProperties, false
        );
      }
    } else if (this.deregisterElement) {
      this.deregisterElement();
      this.element = null;
    }
  }

  render() {
    return (
      <div
        ref={this.updateRef}
        className={contentImpressionFull}
        data-qa-selector={toSnakeCase(`${this.props.model}_${this.props.id}`)}
      >
        {this.props.children}
      </div>
    );
  }
}


ContentImpression.defaultProps = defaultProps;
ContentImpression.propTypes = propTypes;
