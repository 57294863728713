import React from 'react';

import {isEmpty} from 'lodash';
import {every} from 'lodash-es';
import {useSelector} from 'react-redux';

import {
  subscriptionsContainerCss,
  subscriptionTitleCss,
  subscriptionUnorderedListCss,
  daysLeftCss,
  expirationDateCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/UserProfileMenu/UserProfilePopout/PopoutSubscriptions/PopoutSubscriptions.styles';
import {getUserProperties} from 'static/three-oh/src/modules/selectors/userSelectors';

import {getLicensesLists, getSubscriptionsInformation} from './PopoutSubscriptions.utils';

const PopoutSubscriptions = () => {
  const {
    hasPremiumLicense,
    hasNceTrialCampaign,
    hasAccessToLite,
    canViewTheirLicenses,
    getDaysLeftInTrial,
    activeEssentialsLicense
  } = useSelector((state) => getUserProperties(state));

  const licenses = useSelector((state) => state.license)?.data || [];

  if (!canViewTheirLicenses) return null;

  const licensesLists = getLicensesLists(hasNceTrialCampaign, licenses);
  const [trials, subscriptions] = licensesLists;

  const subscriptionsInformation = getSubscriptionsInformation(
    hasPremiumLicense || activeEssentialsLicense ? subscriptions : trials
  );

  if (isEmpty(subscriptionsInformation) && !hasAccessToLite) return null;

  const daysLeftInTrial =
    !hasPremiumLicense &&
    !activeEssentialsLicense &&
    getDaysLeftInTrial(licenses, hasNceTrialCampaign);

  return (
    <div className={subscriptionsContainerCss}>
      <div className={subscriptionTitleCss}>
        My subscriptions
        {daysLeftInTrial && daysLeftInTrial < 90 && (
          <span className={daysLeftCss}>{daysLeftInTrial} days left</span>
        )}
      </div>

      <ul className={subscriptionUnorderedListCss}>
        {hasAccessToLite && every(licensesLists, isEmpty) && (
          <li>Newsela Lite</li>
        )}
        <>
          {subscriptionsInformation?.map((subscription, index) => (
            <li key={index}>
              {subscription.product}
              {!hasPremiumLicense &&
                !activeEssentialsLicense &&
                subscription.expirationDate && !hasPremiumLicense && (
                <p className={expirationDateCss}>Expires on <strong>{subscription.expirationDate}</strong></p>
              )}
            </li>
          ))}
        </>
      </ul>
    </div>
  );
};

PopoutSubscriptions.displayName = 'PopoutSubscriptions';
export {PopoutSubscriptions};
