import {combineReducers} from 'redux';

import collections from './collectionsReducer';
import content from './contentReducer';
import eventRecordReducer from './eventRecordReducer';
import externalToolContextReducer from './externalToolContextReducer';
import lessonSpark from './lessonSparksReducer';
import likedContent from './likedContentReducer';
import rosterChoiceReducer from './rosterChoiceReducer';
import rosterClassrooms from './rosterClassroomsReducer';
import rosterDetailsReducer from './rosterDetailsReducer';
import rosterSyncReducer from './rosterSyncReducer';
import userEmailsReducer from './userEmailsReducer';

export default combineReducers({
  userEmails: userEmailsReducer,
  rosterSync: rosterSyncReducer,
  rosterChoice: rosterChoiceReducer,
  externalToolContext: externalToolContextReducer,
  eventRecords: eventRecordReducer,
  rosterDetails: rosterDetailsReducer,
  lessonSpark,
  likedContent,
  rosterClassrooms,
  collections,
  content
});
