import React from 'react';

import {CollectionSVG} from '@newsela/angelou';
import {useSelector} from 'react-redux';

import {
  useNavigationBarV2Context
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/NavigationBar.provider';
import {
  leftContainerCss,
  libraryMenuPopoutContainer,
  newselaProductsVisibilityCss,
  rightContainerCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/ResourceMenus/ContentLibraryMenu/ContentLibraryPopout/ContentLibraryPopout.styles';
import {
  ESSENTIALS_MENU_ITEM,
  EXPLORE_CONTENT_ITEMS,
  MY_CONTENT_ITEMS,
  ProductLicenseKeysEnum,
  SUBJECT_AREA_ITEMS
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';
import {
  PopoutMenuSection
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/PopoutMenuSection';
import {
  PopoutSectionItem
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/PopoutSectionItem';
import {
  USER_FEATURE_FOR_PROMOTED_AT_YOUR_DISTRICT_PAGE,
} from 'static/three-oh/src/constants/contentPromotion';

import {getUserProperties} from '../../../../../../../modules/selectors/userSelectors';
import {useGetProductsVisibilityBreakpoint} from '../../../shared/utils';

const ContentLibraryPopout = () => {
  const {
    getters: {
      customCollections,
      personalizedCollections,
    },
  } = useNavigationBarV2Context();

  const {
    isLiteUser,
    isStudent,
    activeELALicense,
    activeSSLicense,
    hasActivePremiumTrial,
    activeScienceLicense,
    activeEssentialsLicense,
    hasFeature,
    getExperimentSegment,
  } = useSelector((state) => getUserProperties(state));

  const visibilityBreakpoint = useGetProductsVisibilityBreakpoint();

  const showLockIconsExperiment = getExperimentSegment('show_lock_icons');
  const showLicenseItemWithLock = showLockIconsExperiment === 'on';

  const hasUserFeatureToAccessPromotedAtYourDistrictPage = hasFeature(USER_FEATURE_FOR_PROMOTED_AT_YOUR_DISTRICT_PAGE);
  const displayPromotedAtYourDistrictMenuItem = hasUserFeatureToAccessPromotedAtYourDistrictPage;

  const licensesMap = {
    [ProductLicenseKeysEnum.SS]: activeSSLicense,
    [ProductLicenseKeysEnum.ELA]: activeELALicense,
    [ProductLicenseKeysEnum.SCI]: activeScienceLicense,
  };

  const hideProductsSection =
    !activeELALicense &&
    !activeSSLicense &&
    !activeScienceLicense &&
    !activeEssentialsLicense &&
    !showLicenseItemWithLock;

  const isTrialOrLite = isLiteUser || hasActivePremiumTrial;

  return (
    <div className={libraryMenuPopoutContainer}>
      <div className={leftContainerCss}>
        {!hideProductsSection && (
          <div className={newselaProductsVisibilityCss(isTrialOrLite, visibilityBreakpoint)}>
            <PopoutMenuSection title="Newsela Products" width="14.375rem">
              {activeEssentialsLicense && (
                <PopoutSectionItem
                  href={ESSENTIALS_MENU_ITEM.href}
                  title={ESSENTIALS_MENU_ITEM.title}
                  SvgComponent={ESSENTIALS_MENU_ITEM.SvgComponent}
                  locked={false}
                  dataQASelector={ESSENTIALS_MENU_ITEM.dataQASelector}
                />
              )}
              {SUBJECT_AREA_ITEMS
                .filter(({license}) => licensesMap[license] || (!isStudent && showLicenseItemWithLock))
                .map(({title, href, SvgComponent, license, dataQASelector}, index) => (
                  <PopoutSectionItem
                    key={index}
                    href={href}
                    title={title}
                    SvgComponent={SvgComponent}
                    locked={!licensesMap[license]}
                    dataQASelector={dataQASelector}
                  />
                ))}
            </PopoutMenuSection>
          </div>
        )}

        <PopoutMenuSection title="My content" width="14.375rem">
          {MY_CONTENT_ITEMS
            .filter((item) => !isStudent || item.showForStudents)
            .filter((item) => !(item.title === 'Promoted at your district' &&
          !displayPromotedAtYourDistrictMenuItem))
            .map(({title, href, SvgComponent, dataQASelector}) => (
              <PopoutSectionItem
                key={title}
                href={href}
                title={title}
                SvgComponent={SvgComponent}
                dataQASelector={dataQASelector}
              />
            ))}
          {personalizedCollections.map(({title, href}) => (
            <PopoutSectionItem
              key={title}
              href={href}
              title={title}
              SvgComponent={CollectionSVG}
            />
          ))}
          {customCollections.map(({title, href}) => (
            <PopoutSectionItem
              key={title}
              href={href}
              title={title}
              SvgComponent={CollectionSVG}
            />
          ))}
        </PopoutMenuSection>
      </div>

      <div className={rightContainerCss}>
        <PopoutMenuSection title="Explore content" width="14.375rem">
          {EXPLORE_CONTENT_ITEMS
            .filter((item) => !isStudent || item.showForStudents)
            .map(({title, href, SvgComponent, dataQASelector}, index) => (
              <PopoutSectionItem
                key={index}
                href={href}
                title={title}
                SvgComponent={SvgComponent}
                dataQASelector={dataQASelector}
              />
            ))}
        </PopoutMenuSection>
      </div>
    </div>
  );
};

ContentLibraryPopout.displayName = 'ContentLibraryPopout';
export {ContentLibraryPopout};
