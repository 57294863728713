import {get} from 'lodash-es';
// Read page "Ducks" File: All the Redux things!

const namespace = 'read-page';
// ACTIONS / ACTION TYPES
const INITIALIZE_READ_PAGE = `${namespace}/INITIALIZE_READ_PAGE`;
const SAVE_WRITE_REVIEW = `${namespace}/SAVE_WRITE_REVIEW`;
const STUDENT_QUIZ_POST_REQUEST = `${namespace}/STUDENT_QUIZ_POST_REQUEST`;
const STUDENT_WRITE_RESPONSE_POST_REQUEST = `${namespace}/STUDENT_WRITE_RESPONSE_POST_REQUEST`;
const STUDENT_WRITE_RESPONSE_POST_SUCCESS = `${namespace}/STUDENT_WRITE_RESPONSE_POST_SUCCESS`;
const STUDENT_WRITE_RESPONSE_POST_FAILURE = `${namespace}/STUDENT_WRITE_RESPONSE_POST_FAILURE`;
const STORE_STUDENT_WORK_REVIEW_RECORDS = `${namespace}/STORE_STUDENT_WORK_REVIEW_RECORDS`;

// ACTION CREATORS
function initializeReadPage(readUser, {headerSlug, headerArticleId}) {
  return {type: INITIALIZE_READ_PAGE, readUser, headerSlug, headerArticleId};
}

// READ PAGE: STUDENT QUIZ SUBMISSION FUNCTIONALITY
function postStudentQuiz(quizId, payload) {
  return {type: STUDENT_QUIZ_POST_REQUEST, quizId, payload};
}

// READ PAGE: STUDENT WRITE RESPONSE SUBMISSION FUNCTIONALITY
function postStudentWriteResponse(payload) {
  return {type: STUDENT_WRITE_RESPONSE_POST_REQUEST, payload};
}

function postStudentWriteResponseSuccess(payload) {
  return {type: STUDENT_WRITE_RESPONSE_POST_SUCCESS, payload};
}

function postStudentWriteResponseFailure(payload) {
  return {type: STUDENT_WRITE_RESPONSE_POST_FAILURE, payload};
}

// READ PAGE: SAVE A TEACHER'S WRITE REVIEW
function saveWriteReview(payload) {
  return {type: SAVE_WRITE_REVIEW, payload};
}

function storeStudentWorkReviewRecords(records = []) {
  return {
    type: STORE_STUDENT_WORK_REVIEW_RECORDS,
    payload: {
      records,
      dateLoaded: new Date()
    }
  };
}

// SELECTORS
const DEFAULT_WRITE_OBJECT = {};
const getStudentWriteResponses = (state) => {
  return get(state, 'models.constructedResponseAnswer', DEFAULT_WRITE_OBJECT);
};

const getStudentWorkReviewRecords = (state) => {
  return state.read.studentWorkReviewRecordsRequest;
};

// EXPORTS
export {
  INITIALIZE_READ_PAGE,
  SAVE_WRITE_REVIEW,
  STUDENT_QUIZ_POST_REQUEST,
  STUDENT_WRITE_RESPONSE_POST_FAILURE,
  STUDENT_WRITE_RESPONSE_POST_REQUEST,
  STUDENT_WRITE_RESPONSE_POST_SUCCESS,
  STORE_STUDENT_WORK_REVIEW_RECORDS,
  getStudentWriteResponses,
  getStudentWorkReviewRecords,
  initializeReadPage,
  postStudentQuiz,
  postStudentWriteResponse,
  postStudentWriteResponseFailure,
  postStudentWriteResponseSuccess,
  saveWriteReview,
  storeStudentWorkReviewRecords,
};
