import React from 'react';

import {Anchor} from '@newsela/angelou';

import {
  skipToAnchorCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SkipToContentButton/SkipToContentButton.styles';

const SkipToContentButton = () => {
  return (
    <li>
      <Anchor
        __cssFor={skipToAnchorCss}
        href="#main-contents"
        dataProps={{tabIndex: 1}}
      >
        Skip to content
      </Anchor>
    </li>
  );
};

SkipToContentButton.displayName = 'SkipToContentButton';
export {SkipToContentButton};
