import * as types from './../constants/articleHeaderActionTypes';

export function requestArticleHeader(identifier, params, headerArticleId) {
  return {type: types.ARTICLE_HEADER_LOAD_REQUEST, identifier, params, headerArticleId};
}

export function requestArticleHeaderSuccess(articleHeader) {
  return {type: types.ARTICLE_HEADER_LOAD_SUCCESS, articleHeader};
}

export function requestArticleHeaderFailure(error) {
  return {type: types.ARTICLE_HEADER_LOAD_FAILURE, error};
}
