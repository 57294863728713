import React from 'react';

import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

const propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  rel: PropTypes.string,
  target: PropTypes.string,
  to: PropTypes.string,
  qaSelector: PropTypes.string,
  disabled: PropTypes.bool,
};

const defaultProps = {
  href: '',
  rel: '',
  target: '',
};

const TrackedLink = (props) => {
  const linkProps = {};
  if (props.ariaLabel) {
    linkProps['aria-label'] = props.ariaLabel;
  }
  if (props.qaSelector) {
    linkProps['data-qa-selector'] = props.qaSelector;
  }
  if (props.disabled) {
    return <a>{props.children}</a>;
  }
  if (props.to) {
    return (
      <Link
        className={props.className}
        onClick={props.onClick}
        onKeyDown={props.onKeyDown}
        rel={props.rel}
        target={props.target}
        to={props.to}
        {...linkProps}
      >
        {props.children}
      </Link>
    );
  }
  return (
    <a
      className={props.className}
      href={props.href}
      onClick={props.onClick}
      onKeyDown={props.onKeyDown}
      rel={props.rel}
      target={props.target}
      {...linkProps}
    >
      {props.children}
    </a>
  );
};

TrackedLink.defaultProps = defaultProps;
TrackedLink.propTypes = propTypes;

export default TrackedLink;
