import React from 'react';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {
  appSwitcherPopoutContainerCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/AppSwitcherMenu/AppSwitcherPopout/AppSwitcherPopout.styles';
import {
  PopoutRow
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/AppSwitcherMenu/PopoutRow';
import {
  FormativeLogo
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/AppSwitcherMenu/PopoutRow/assets/FormativeLogo';
import {
  titleCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/AppSwitcherMenu/PopoutRow/PopoutRow.styles';

import {getUserProperties} from '../../../../../../modules/selectors/userSelectors';

const defaultTitleProps = {title: PropTypes.string.isRequired};
const DefaultTitleComponent = ({title}) => <div className={titleCss}>{title}</div>;
DefaultTitleComponent.propTypes = defaultTitleProps;


const appSwitcherPropTypes = {
  setIsOpen: PropTypes.func.isRequired,
};
const AppSwitcherPopout = ({setIsOpen}) => {
  const {
    canSeeFormative,
    canSeeEverwrite,
    activeEssentialsLicense,
    isLiteUser,
    hasPremiumLicense
  } = useSelector((state) => getUserProperties(state));

  const onKeyDown = ({key}) => {
    if (key === 'Escape') {
      setIsOpen(false);
    }
  };

  const everwriteUrl = (activeEssentialsLicense || isLiteUser) && !hasPremiumLicense
    ? 'https://newsela.com/about/products/writing/'
    : 'http://everwrite.newsela.com';

  return (
    <ul className={appSwitcherPopoutContainerCss} data-testid="app-switcher-menu">
      {canSeeFormative && (
        <PopoutRow
          label="Go to Formative"
          onKeyDown={onKeyDown}
          dataQASelector="global-nav-app-switcher-formative"
          href="https://app.formative.com/home"
          TitleComponent={<FormativeLogo id="formative" ariaLabel="Formative" />}
          description="An interactive and collaborative assessments app with real-time feedback and progress tracking."
        />
      )}

      {canSeeEverwrite && (
        <PopoutRow
          label="Go to Newsela Writing"
          onKeyDown={onKeyDown}
          dataQASelector="global-nav-go-to-everwrite"
          href={everwriteUrl}
          TitleComponent={<DefaultTitleComponent title="Newsela Writing" />}
          description="Your assistant to help students become confident writers."
        />
      )}
    </ul>
  );
};

AppSwitcherPopout.propTypes = appSwitcherPropTypes;
AppSwitcherPopout.displayName = 'AppSwitcherPopout';
export {AppSwitcherPopout};
