import * as types from 'modulesV2/constants';

export function setModalId(modalId, options = {}) {
  return {type: types.MODAL_SET, modalId, options};
}

export function clearModal() {
  return {type: types.MODAL_SET};
}

export function setAriaHiddenMainContent(isMainContentAriaHidden) {
  return {
    type: types.SET_ARIA_HIDDEN_MAIN_CONTENT,
    isMainContentAriaHidden
  };
}

/**
 * Set context for user interactions. This value represents
 * the textset that a user is currently exploring, or has navigated from.
 */
export function setContext(data) {
  return {type: types.SET_CONTEXT, data};
}
