import React from 'react';

import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  rel: PropTypes.string,
  target: PropTypes.string,
  qaSelector: PropTypes.string,
};

const defaultProps = {
  href: '',
  rel: '',
  target: '',
};

const TrackedDiv = (props) => {
  const divProps = props.qaSelector ? {'data-qa-selector': props.qaSelector} : {};
  return (
    <div
      className={props.className}
      href={props.href}
      onClick={props.onClick}
      rel={props.rel}
      target={props.target}
      {...divProps}
    >
      {props.children}
    </div>
  );
};

TrackedDiv.defaultProps = defaultProps;
TrackedDiv.propTypes = propTypes;

export default TrackedDiv;
