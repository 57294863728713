import React, {useRef} from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import {cx} from 'emotion';
import {get} from 'lodash-es';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import {
  rootStyle,
  rootLinkStyle,
  menuLabelStyle,
  firstSectionItemStyle
} from './styles';
import {isLink} from '../DropDownMenuItem';
import {menuItemLinkStyle, menuItemSelectedStyle} from '../DropDownMenuItem/styles';

const propTypes = {
  label: PropTypes.string.isRequired,
  __renderLabel: PropTypes.func,
  child: PropTypes.element,
  classes: PropTypes.shape({
    menuItem: PropTypes.object,
    root: PropTypes.string,
    label: PropTypes.string,
  }),
  selected: PropTypes.bool,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  // specific data-qa selector for the section label
  sectionLabelQaSelector: PropTypes.string,
  // specific data-qa selector for the section
  sectionLiQaSelector: PropTypes.string,
};

const DropDownLabelItem = (props) => {
  const {
    label,
    child,
    classes = {},
    selected,
    minWidth,
    maxWidth,
    __renderLabel,
    sectionLabelQaSelector,
    sectionLiQaSelector,
    ...rest
  } = props;

  const childRef = useRef(null);

  const ChildWithRef = React.cloneElement(child, {
    ref: childRef,
  });

  const childLink = isLink(childRef);

  const labelStyle = cx([menuLabelStyle, classes.label]);

  const labelOnClick = (e) => e.stopPropagation();

  return (
    <MenuItem
      {...rest}
      data-qa-selector={sectionLiQaSelector || null}
      style={{minWidth, maxWidth}}
      disableRipple
      classes={{
        root: cx([
          childLink ? rootLinkStyle : '',
          rootStyle,
          classes.root
        ]),
      }}
      onClick={(e) => {
        const domNode = ReactDOM.findDOMNode(childRef.current);
        if (domNode.click) {
          domNode.click();
        }
        rest.onClick(e);
      }}
    >
      {
        __renderLabel ?
          __renderLabel(labelStyle, labelOnClick, sectionLabelQaSelector) :
            (
              <label
                data-qa-selector={sectionLabelQaSelector || null}
                className={labelStyle}
                onClick={labelOnClick}
              >
                {label}
              </label>
            )
      }
      <div
        className={cx([
          childLink ? menuItemLinkStyle : '',
          firstSectionItemStyle,
          get(classes, 'menuItem.root'),
          selected ? get(classes, 'menuItem.selected') : '',
          selected ? menuItemSelectedStyle : '',
        ])}
      >
        {ChildWithRef}
      </div>
    </MenuItem>
  );
};

DropDownLabelItem.propTypes = propTypes;

export default DropDownLabelItem;


