import * as types from '../constants/userActionTypes';

/**
 User Actions
 */

export function requestCurrentUser() {
  return {type: types.USER_LOAD_REQUEST};
}

export function refreshCurrentUser() {
  return {type: types.USER_REFRESH_REQUEST};
}

export function createNewUser(userPayload, role, additionalUserData = {}) {
  return {type: types.USER_CREATE_REQUEST, payload: {userInfo: userPayload, role, additionalUserData}};
}

export function loadUserSuccess(data) {
  return {
    type: types.USER_LOAD_SUCCESS,
    user: data,
    receivedAt: Date.now()
  };
}

export function loadUserFailure(data) {
  return {
    type: types.USER_LOAD_FAILURE,
    user: {isAuthenticated: false},
    receivedAt: Date.now(),
    data
  };
}

export function logOutUser() {
  return {type: types.USER_LOG_OUT};
}
