import React from 'react';

import {LoadingSpinner} from '@newsela/angelou';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';

import {assignmentRoot} from 'static/three-oh/src/modules/constants/routePaths';

const LazyAssignmentContainer = Loadable({
  loader: () => import(
    /* webpackChunkName: "AssignmentContainer" */
    './containers/AssignmentContainer'
  ),
  loading: LoadingSpinner,
});

const AssignmentRoute = [
  <Route
    key="assignment"
    path={`${assignmentRoot}/:assignmentId`}
    component={LazyAssignmentContainer}
  />,
  <Route
    key="assignment"
    path={`${assignmentRoot}/create/`}
    component={LazyAssignmentContainer}
  />,
];

export default AssignmentRoute;
