// `buildImgixUrl` is a temporary solution to avoid using direct s3 links for images
// thus serving the original, unoptimized image which can be as large as 25mb.
// In the future the API should return those image urls right in the format we need,
// Rewritting a S3 URL to use imgix URL passing the desired width and height
export function buildImgixUrl(imageUrl, imgProps = {}) {
  const AWS_STORAGE_BUCKET_NAME = window.AWS_STORAGE_BUCKET_NAME;
  const NEWSELA_MEDIA_HOST = window.NEWSELA_MEDIA_HOST;
  const IMGIX_HOST = window.IMGIX_HOST;
  const S3_BUCKET_NEWSELA_MEDIA = `${AWS_STORAGE_BUCKET_NAME}.s3.amazonaws.com`;
  const directAssetMediaDomains = [S3_BUCKET_NEWSELA_MEDIA, NEWSELA_MEDIA_HOST];
  try {
    const url = new URL(imageUrl);
    // Rewrite url for direct s3 asset to use Imgix
    if (directAssetMediaDomains.find((domain) => url.host === domain)) {
      for (const key of Object.keys(imgProps)) {
        url.searchParams.set(key, imgProps[key]);
      }
      const imgixUrl = new URL(IMGIX_HOST);
      url.host = imgixUrl.host;
      return decodeURIComponent(url.toString());
    }
  } catch (_) {
    // In case of a relative url, or any potential problem with the given url,
    // return it back to avoid a potential broken image in UI
    return imageUrl;
  }
  return imageUrl;
}
