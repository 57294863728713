import * as types from '../constants/licenseActionTypes';

function license(state = {}, action) {
  switch (action.type) {
    case types.LICENSE_LOAD_REQUEST:
      return {
        ...state,
        dateRequested: action.receivedAt,
        isLoading: true
      };
    case types.LICENSE_LOAD_SUCCESS:
      return {
        ...state,
        dateLoaded: action.receivedAt,
        isLoading: false,
        data: action.data?.data,
      };
    case types.LICENSE_LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        dateLoaded: action.receivedAt,
        error: action.error,
        data: []
      };
    default:
      return state;
  }
}

export default license;
