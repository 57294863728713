import APIFetcher from '../APIFetcher';

class UserApi {
  static getCurrentUser(isReducedUserShape = false) {
    const userMeQueryParam = new URLSearchParams();
    if (isReducedUserShape) {
      userMeQueryParam.append('include_licenses', 'false');
      userMeQueryParam.append('include_schools', 'false');
    }
    return APIFetcher.get(`user/me/?${userMeQueryParam.toString()}`);
  }

  static getById(userId) {
    return APIFetcher.get(`user/${userId}`);
  }

  static getByQuery(query) {
    if (typeof (query) === 'object') {
      query = `?${APIFetcher.stringifyParams(query)}`;
    }
    return APIFetcher.get('user/' + query);
  }

  static persistUser(payload) {
    return APIFetcher.post('user/', {payload});
  }

  // We don't allow the client to actually _delete_ user accounts since we would
  // lose _all_ of that user's data because of Django's cascasde delete. This method
  // maps to "is_active = false" on the server.
  static deactivateUser(userId) {
    return APIFetcher.remove('user/' + userId);
  }
}

export default UserApi;
