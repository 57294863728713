import PropTypes from 'prop-types';

import ScreenSize from 'static/three-oh/src/constants/ScreenSize';
const AngelouProps = {
  /**
     * additional classes to be passed to the wrapper component
     */
  additionalClassNames: PropTypes.arrayOf(PropTypes.string),
  /**
     * size is usually passed in from the WithWindowSize HOC
     * and is either xsmall, small, medium, or large
     */
  size: PropTypes.oneOf(Object.keys(ScreenSize)),

  /**
     * a function that is passed the props of the component, and
     * the current screen size, passed via withWindowSize
     */
  __mapSizeToProps: PropTypes.func,
};

export default AngelouProps;
