import {snakeCase} from 'lodash-es';

/**
 * Convert given 'str' to snake case
 *
 * @param {String|Array} str
 * @return {String}
 */
export const toSnakeCase = function(str) {
  if (!str) return str;
  return snakeCase(str);
};
