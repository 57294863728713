import ApiFetcher2 from './ApiFetcher2';

const config = {
  basePath: window.SEARCH_SERVICE_URL + '/v1/',
  credentials: 'include',
};

const suggestionsConfig = {
  basePath: window.SEARCH_SERVICE_URL + '/v2/suggestions/',
  credentials: 'include',
};

class SearchServiceApi {
  static config = config;
  static suggestionsConfig = suggestionsConfig;

  static get(params) {
    return ApiFetcher2.get('', {config, params});
  }

  static getArticleHeadersById(articleHeaderIds = [], includeUnpublished) {
    const params = {
      format: 'full',
      type: 'header',
      page_size: 1000,
      object_ids: articleHeaderIds,
    };
    if (includeUnpublished === true) {
      params.include_unpublished = includeUnpublished;
    }
    return ApiFetcher2.get('', {config, params});
  }
}

export default SearchServiceApi;
