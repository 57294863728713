import * as types from './../constants/textSetLikeActionTypes';

export function textSetLikeRequest() {
  return {
    type: types.TEXT_SET_LIKE_REQUEST,
  };
}

export function textSetLikeRequestSuccess(textSetLikes) {
  return {
    type: types.TEXT_SET_LIKE_REQUEST_SUCCESS,
    textSetLikes: textSetLikes,
  };
}

export function textSetLikeRequestFailure(error) {
  return {
    error,
    type: types.TEXT_SET_LIKE_REQUEST_FAILURE,
  };
}

export function textSetLikeCreateRequest(textSetId) {
  return {
    type: types.TEXT_SET_LIKE_CREATE_REQUEST,
    textSetId,
  };
}

export function textSetLikeCreateRequestSuccess(textSetLike) {
  return {
    type: types.TEXT_SET_LIKE_CREATE_REQUEST_SUCCESS,
    textSetLike: textSetLike,
  };
}

export function textSetLikeDeleteRequest(likeId) {
  return {
    type: types.TEXT_SET_LIKE_DELETE_REQUEST,
    likeId,
  };
}

export function textSetLikeDeleteRequestSuccess(likeId) {
  return {
    type: types.TEXT_SET_LIKE_DELETE_REQUEST_SUCCESS,
    likeId,
  };
}
