import React from 'react';

import PropTypes from 'prop-types';

import styles from './styles.scss';

const defaultProps = {
  count: 0
};

const propTypes = {
  count: PropTypes.number,
  shortCopy: PropTypes.bool,
};

class ArticleAssignmentCount extends React.Component {
  render() {
    return (
      <div
        className={styles.count}
        data-qa-selector="assigned_count"
      >
        {this.props.shortCopy ?
          <span>
            <span className={styles.number}>
              {this.props.count.toLocaleString()}
            </span> Assigns
          </span> :
          <span>
            Assigned <span className={styles.number}>
              {this.props.count.toLocaleString()}
            </span>
            {this.props.count === 1 ? ' time' : ' times'}
          </span>}
      </div>
    );
  }
}

ArticleAssignmentCount.propTypes = propTypes;
ArticleAssignmentCount.defaultProps = defaultProps;

export default ArticleAssignmentCount;
