import React from 'react';

import {css} from 'emotion';
import PropTypes from 'prop-types';

import styles from './styles';
import TrackedLink from '../TrackedLink/components/TrackedLink';

const propTypes = {
  /**
   * The default cta is 'See more' but a cta with more context about the destination should be provided for accessibility.
   */
  cta: PropTypes.string,
  /*
   * The URL to which the link should direct.
   */
  href: PropTypes.string.isRequired,

  /*
   * Whether to use the React Router for the link.  If set, a React <Link> element will be used for
   * the link, using the React Router.  Otherwise, an ordinary <a> is used, bypassing the React Router.
   * Generally, React Router is to be preferred to avoid full page reloads.
   */
  useReactRouter: PropTypes.bool,
  qaSelector: PropTypes.string.isRequired,
};

const SeeMoreCTA = ({cta = 'See more', href, qaSelector, useReactRouter}) => {
  return (
    <TrackedLink
      ariaLabel={cta}
      className={css(styles.textStyle)}
      href={useReactRouter ? null : href}
      to={useReactRouter ? href : null}
      qaSelector={qaSelector}
    >
      {cta}
    </TrackedLink>
  );
};

SeeMoreCTA.propTypes = propTypes;
export default SeeMoreCTA;
