import {watchGetArticleHeaderClassrooms} from './articleHeaderClassroomSagas';
import {watchGetArticleHeader} from './articleHeaderSagas';
import {watchGetArticleWords} from './articleWordSagas';
import {
  watchGetAssignmentsV3,
  watchStartAssignmentsPolling,
} from './assignmentSagas';
import {
  watchGetClassrooms,
  watchCreateClassroom,
  watchJoinClassroom,
  watchStartClassroomPolling,
} from './classroomSagas';
import {
  watchGetContentHides,
  watchToggleContentHide,
} from './contentHideSagas';
import {
  watchGetContentRow,
} from './contentRowSagas';
import {watchGetModelsByPrimaryKeys} from './genericModelSagas';
import {watchGetLicenses} from './licenseSagas';
import {
  watchPollForNotifications,
  watchInitNotifications,
} from './notificationsSagas';
import {watchGetRecentNews} from './recentNewsSagas';
import {watchGetSearch} from './searchSagas';
import {watchRequestUserSignOut} from './sessionSagas';
import {watchGetStudentAssignmentViews} from './studentAssignmentViewSagas';
import {watchGetStudentQuizzes} from './studentQuizSagas';
import {watchGetStudies} from './studiesSagas';
import {
  watchPersistTextSet,
  watchAddHeaderToTextSet,
  watchRemoveHeaderFromTextSet,
  watchGetTextSet,
} from './textSetSagas';
import {
  watchGetUserExperimentSegments
} from './userExperimentSegmentsSagas';
import {
  watchGetCurrentUser,
  watchCreateNewUser,
  watchRefreshCurrentUser,
} from './userSagas';

const commonSagas = [
  watchPollForNotifications,
  watchAddHeaderToTextSet,
  watchCreateClassroom,
  watchJoinClassroom,
  watchGetArticleHeader,
  watchGetArticleWords,
  watchGetArticleHeaderClassrooms,
  watchGetAssignmentsV3,
  watchStartAssignmentsPolling,
  watchGetClassrooms,
  watchGetContentHides,
  watchGetContentRow,
  watchCreateNewUser,
  watchGetCurrentUser,
  watchGetModelsByPrimaryKeys,
  watchGetRecentNews,
  watchGetStudentQuizzes,
  watchGetSearch,
  watchGetStudies,
  watchGetTextSet,
  watchGetUserExperimentSegments,
  watchInitNotifications,
  watchPersistTextSet,
  watchRefreshCurrentUser,
  watchRequestUserSignOut,
  watchRemoveHeaderFromTextSet,
  watchStartClassroomPolling,
  watchToggleContentHide,
  watchGetStudentAssignmentViews,
  watchGetLicenses,
];

export default commonSagas;
