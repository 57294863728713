import {studentQuizActionTypes as types} from './../constants';

// Student Quiz Request and Loading Actions

export function postStudentQuizSuccess(data) {
  return {type: types.STUDENT_QUIZ_POST_SUCCESS, data};
}

export function postStudentQuizFailure(error) {
  return {type: types.STUDENT_QUIZ_POST_FAILURE, error};
}

export function requestStudentQuizzes(queryParams) {
  return {
    type: types.STUDENT_QUIZZES_LOAD_REQUEST,
    queryParams
  };
}

export function loadStudentQuizzesSuccess(data) {
  return {
    type: types.STUDENT_QUIZZES_LOAD_SUCCESS,
    studentQuizzes: data,
    receivedAt: Date.now()
  };
}

export function loadStudentQuizzesFailure() {
  return {
    type: types.STUDENT_QUIZZES_LOAD_FAILURE,
    studentQuizzes: [],
    receivedAt: Date.now()
  };
}
