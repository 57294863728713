import * as types from './../constants/articleActionTypes';

function articles(state = {}, action) {
  switch (action.type) {
    case types.ARTICLE_LOAD_REQUEST:
      return {...state, isLoading: true};

    case types.ARTICLE_LOAD_SUCCESS:
      return {...state, isLoading: false, items: [...action.articles]};

    case types.ARTICLE_LOAD_FAILURE:
      return {...state, isLoading: false, error: action.error};

    default:
      return state;
  }
}

export default articles;
