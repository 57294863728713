import React from 'react';

export default function forwardRefToDOMElement(Component) {
  /**
     * Forwarding ref from Component to the DOM element.
     *@param {*} props
     *@param {*} ref
     */
  function forwardRef(props, ref) {
    return <Component forwardedRef={ref} {...props}/>;
  }
  // Setting displayName property to be read in DevTools
  const name = Component.displayName || Component.name || Component;
  forwardRef.displayName = `forwardRefToDOMElement(${name})`;

  // Returning the Component class with forwarded ref.
  return React.forwardRef(forwardRef);
}
