import React from 'react';

import PropTypes from 'prop-types';

import styles from './styles.scss';

const propTypes = {
  ariaLabel: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelIfChecked: PropTypes.string,
  className: PropTypes.string,
};

class TextToggleButton extends React.PureComponent {
  onClick = (event) => {
    this.props.onClick();
    event.stopPropagation();
  };

  render() {
    return (
      <div
        aria-labelledby={this.props.ariaLabel}
        className={`${this.props.checked ? styles.checked : styles.unchecked} ${this.props.className}`}
        onClick={this.onClick}
        role="button"
        title={this.props.ariaLabel}
      >
        <span>
          {this.props.label &&
          <span>{this.props.checked ? this.props.labelIfChecked : this.props.label}</span>}
        </span>
      </div>
    );
  }
}

TextToggleButton.propTypes = propTypes;
export default TextToggleButton;
