import * as types from './../constants/articleWordActionTypes';

function articleWords(state = {}, action) {
  switch (action.type) {
    case types.FETCH_ARTICLE_WORDS_BY_HEADER_ID_LOAD_REQUEST:
      return {
        ...state,
        [action.headerId]: {
          ...state[action.headerId],
          isLoading: true,
          requestedAt: action.requestedAt,
        },
      };

    case types.FETCH_ARTICLE_WORDS_BY_HEADER_ID_LOAD_SUCCESS:
    case types.FETCH_ARTICLE_WORDS_BY_HEADER_ID_LOAD_FAILURE:
      return {
        ...state,
        [action.headerId]: {
          ...state[action.headerId],
          isLoading: false,
          items: action.articleWords,
          receivedAt: action.receivedAt,
          error: action.error
        },
      };

    case types.FETCH_ARTICLE_WORDS_BY_HEADER_IDS_LOAD_REQUEST:
      return {
        ...state,
        headerIds: action.headerIds,
        isLoading: true,
        requestedAt: action.requestedAt,
      };

    case types.FETCH_ARTICLE_WORDS_BY_HEADER_IDS_LOAD_SUCCESS:
    case types.FETCH_ARTICLE_WORDS_BY_HEADER_IDS_LOAD_FAILURE:
      return {
        ...state,
        headerIds: action.headerIds,
        isLoading: false,
        items: action.articleWords,
        receivedAt: action.receivedAt,
        error: action.error
      };

    default:
      return state;
  }
}

export default articleWords;
