import {groupBy, mapValues, filter, isNull, reduce} from 'lodash-es';
import {takeLatest, call, fork, put} from 'redux-saga/effects';

import {ReportingApi} from 'static/three-oh/src/modules/apis';
import {toReadingSummaryReportingFilters} from 'static/three-oh/src/routes/Binder/modules/selectors/filterSelectors';

import {studentTabActions as actions} from '../actions';
import {studentTabActionTypes} from '../constants';


function * getCcAnchorStandardsData(binderFilters, showAssignedWorkOnly) {
  const reportingFilters = toReadingSummaryReportingFilters(binderFilters, showAssignedWorkOnly);

  // remove collection filtering if requesting reading skills data
  delete reportingFilters.collection_id;

  const anchorStandardsByStudentParams = {
    measures: [
      'count:quiz_questions',
    ],
    dimensions: [
      'student_id',
      'is_correct',
      'cc_anchor_standard',
    ],
    filters: reportingFilters,
  };

  try {
    yield put(actions.getBinderStandardsRequest());
    const [standards] = yield [
      call(ReportingApi.getReport, anchorStandardsByStudentParams),
    ];

    // Group the quizzes up into students, then map and reduce them into
    // cc_anchor_standards with counts for totals, correct, and incorrect.
    const anchorsByStudent = groupBy(standards.data, 'student_id');

    const mappedAnchorData = mapValues(anchorsByStudent, (anchors) => {
      // Remove CC Anchors that are null on is_correct or cc_anchor_standard
      const cleanAnchors = filter(anchors, (ccAnchor) => {
        return !isNull(ccAnchor.is_correct) && !isNull(ccAnchor.cc_anchor_standard);
      });

      // Group the clean anchor standards
      const groupedAnchors = groupBy(cleanAnchors, 'cc_anchor_standard');

      // Map the grouped anchors and reduce them into a single object with
      // count:correct, count:incorrect, and count:total
      const mappedGroupedAnchors = mapValues(groupedAnchors, (ccAnchor) => {
        const reduced = reduce(ccAnchor, (result, value) => {
          if (value.is_correct === 0) {
            result['numIncorrect'] = value['count:quiz_questions'];
          }
          if (value.is_correct === 1) {
            result['numCorrect'] = value['count:quiz_questions'];
          }
          return result;
        }, {});
        return reduced;
      });
      return mappedGroupedAnchors;
    });

    yield put(actions.getBinderStandardsSuccess({data: mappedAnchorData, filters: reportingFilters}));
  } catch (error) {
    yield put(actions.getBinderStandardsFailure(error));
  }
}


export function * watchInitCcAnchorStandards() {
  yield takeLatest(studentTabActionTypes.INITIALIZE_STANDARDS, function * init(action) {
    const {binderFilters, showAssignedWorkOnly} = action.payload;
    yield fork(getCcAnchorStandardsData, binderFilters, showAssignedWorkOnly);
  });
}
