import {keyBy, omit} from 'lodash-es';

import * as types from './../constants/contentHideActionTypes';

const initialState = {
  contentHides: {
    dateRequested: null,
    dateLoaded: null,
    isLoading: false,
    items: {},
  },
};

function contentHides(state = initialState.contentHides, action) {
  switch (action.type) {
    case types.CONTENT_HIDE_LOAD_REQUEST:
    case types.CONTENT_HIDE_TOGGLE_REQUEST:
      return {
        ...state,
        dateRequested: Date.now(),
        isLoading: true,
        dateLoaded: null
      };

    case types.CONTENT_HIDE_LOAD_SUCCESS:
    case types.CONTENT_HIDE_TOGGLE_SUCCESS:
      return {
        ...state,
        dateLoaded: Date.now(),
        isLoading: false,
        items: {
          ...state.items,
          ...keyBy(action.contentHides, 'id'),
        },
      };
    case types.CONTENT_UNHIDE_TOGGLE_SUCCESS:
      return {
        ...state,
        dateLoaded: Date.now(),
        isLoading: false,
        items: omit(state.items, action.contentHideId),
      };

    case types.CONTENT_HIDE_LOAD_FAILURE:
    case types.CONTENT_HIDE_TOGGLE_FAILURE:
      return {...state, isLoading: false, error: action.error};

    default:
      return state;
  }
}

export default contentHides;
