import React from 'react';

import {isEmpty} from 'lodash';
import {every} from 'lodash-es';
import {useSelector} from 'react-redux';

import {
  userEmailText,
  userNameTextCss,
  userPopoutContainerCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/UserProfileMenu/UserProfilePopout/PopoutUserInformation/PopoutUserInformation.styles';
import {getUser, getUserProperties} from 'static/three-oh/src/modules/selectors/userSelectors';

import {getLicensesLists} from '../PopoutSubscriptions/PopoutSubscriptions.utils';

const PopoutUserInformation = () => {
  const user = useSelector((state) => getUser(state));
  const userProperties = useSelector((state) => getUserProperties(state));
  const {hasAccessToLite, hasNceTrialCampaign, isImpersonated} = userProperties;

  const licensesList = getLicensesLists(hasNceTrialCampaign, user?.licenses || []);

  return (
    <div className={userPopoutContainerCss(hasAccessToLite && every(licensesList, isEmpty), isImpersonated)}>
      <div className={userNameTextCss}>
        {isImpersonated ? 'Impersonating: ' : ''}
        {user.name}
      </div>
      <div className={userEmailText}>{user.email}</div>
    </div>
  );
};

PopoutUserInformation.displayName = 'PopoutUserInformation';
export {PopoutUserInformation};
