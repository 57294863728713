import {filter, pickBy, values} from 'lodash-es';
import {createSelector} from 'reselect';

const likedContentState = (state) => state.v2.services.objects.likedContent;

export const getLikedContent = createSelector(
  [likedContentState],
  (likedContentState) => likedContentState
);

/**
 * @param {Object} state
 * @param {String} contentId - The cuid of any piece of content.
 */
export const getLikedContentByContentId = (state, contentId) => {
  return values(
    pickBy(getLikedContent(state), (likedContent) => likedContent.content_id === contentId)
  )[0];
};

/**
 * @param {Object} state
 * @param {Number} likeId - The primary key of a contentLike data base row.
 */
export const getLikedContentByLikeId = (state, likeId) => {
  return values(
    pickBy(getLikedContent(state), (likedContent) => likedContent.id === likeId)
  )[0];
};

/**
 * @param {Object} state
 * @param {String} contentType - 'articleheader', 'textset', 'standard', etc.
 */
export const getLikedContentByContentType = (state, contentType) => {
  return filter(
    getLikedContent(state), function(liked) {
      return liked.content_type === contentType;
    }
  );
};
