import ApiFetcher2, {BASE_CONFIG} from 'static/three-oh/src/modules/apis/ApiFetcher2';

const config = {
  ...BASE_CONFIG,
  basePath: window.AGATHA_URL + '/api/',
  credentials: 'include',
};

class AgathaServiceApi {
  static config = config;

  static _get(endpoint, params) {
    return ApiFetcher2.get(endpoint, {config, params});
  }

  static getContent(params = {}) {
    return this._get('search/', params);
  }

  static getSchema(params = {}) {
    return this._get('schema/', params);
  }

  static getSuggestions(params = {}) {
    return this._get('suggestions/', params);
  }
}

export default AgathaServiceApi;
