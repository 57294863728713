import {
  get,
  isFinite,
  range,
  forEach,
  isNil,
  groupBy,
  mapValues,
  parseInt,
  keyBy,
  first,
} from 'lodash-es';
import {takeLatest, call, put, select, all} from 'redux-saga/effects';

import {
  ClassroomApi,
  ConstructedResponseAnswerApi,
  ReportingApi,
  StudentQuizApi,
} from 'static/three-oh/src/modules/apis';
import ApiFetcher3 from 'static/three-oh/src/modules/apis/ApiFetcher3';
import {classroomSelectors} from 'static/three-oh/src/modules/selectors';

import {studentDetailsActions as actions} from '../actions';
import {studentDetailsActionTypes as types} from '../constants';
import {toStudentDetailsReportingFilters} from '../selectors/filterSelectors';
import {getStudent} from '../selectors/studentDetailsSelectors';

/**
 * Fetches the classrooms a student is actively enrolled in.
 */
function * getClassroomsForStudent(studentId) {
  let classrooms = [];

  // We have a Student ID but need a User ID for the Classroom endpoint.
  // Look up this student from the list of classrooms previously loaded for the teacher.
  const teachersStudents = yield select(classroomSelectors.getStudentsFromClassrooms);
  const userId = get(teachersStudents, [studentId, 'user', 'id']);
  // Load the classrooms for the student.
  if (isFinite(userId)) {
    classrooms = yield call(ClassroomApi.getClassrooms, userId, {
      hide_archived: true,
    });
  }

  return classrooms;
}


function shapeSkillsMasteryReportingData(reportingData) {
  /**
   * The Reporting data comes out as an array of objects looking like:
   *   {cc_anchor_standard: 2, is_correct: 0, count:quiz_questions: 2, assignment_classroom_id: 1166233}
   *
   * Shape it into something broken down per classroom ID and include an overall tally:
   *    { '1166233':
   *       { '1': { numCorrect: 0, numIncorrect: 1 },
   *         '2': { numCorrect: 2, numIncorrect: 0 },
   *         '3': { numCorrect: 1, numIncorrect: 3 },
   *         ...
   *       },
   *      'overall':
   *       { '1': { numCorrect: 0, numIncorrect: 1 },
   *         '2': { numCorrect: 2, numIncorrect: 0 },
   *         '3': { numCorrect: 1, numIncorrect: 3 },
   *         ...
   *       }
   *    }
   */

  // Tally up the correct and incorrect quiz questions per anchor standard.
  const tallyUpQuestions = (classroomReportData) => {
    // Start the tally at zero for each of the anchor standards
    const questionTallyBySkill = {};
    range(1, 9).forEach((i) => {
      questionTallyBySkill[i] = {numCorrect: null, numIncorrect: null};
    });

    // Look at each row from the reporting API,
    // Each report row will indicate a number of questions answered and whether they were correct or incorrect
    forEach(classroomReportData, (reportRow) => {
      // Skip bogus data
      if (isNil(reportRow.is_correct) || isNil(reportRow.cc_anchor_standard)) {
        return;
      }

      // Update our running tally of correct/incorrect questions for the indicated anchor standard
      const tally = questionTallyBySkill[reportRow.cc_anchor_standard];
      if (reportRow.is_correct) {
        tally.numCorrect += reportRow['count:quiz_questions'];
      } else {
        tally.numIncorrect += reportRow['count:quiz_questions'];
      }
    });

    return questionTallyBySkill;
  };

  // TODO: more efficiency, get the overall and classroom tallies in single pass of the reporting data
  const overallTally = tallyUpQuestions(reportingData);
  const reportingDataByClassroomId = groupBy(reportingData, 'assignment_classroom_id');
  const tallyPerClassrooms = mapValues(reportingDataByClassroomId, tallyUpQuestions);

  const result = {overall: overallTally, ...tallyPerClassrooms};
  return result;
}


function * getStudentQuizzes(studentId, binderFilters) {
  // Fetch the StudentQuizzes for the current student
  // using the binder filters and the student id.

  const parameters = {
    student_id: studentId,
    date_start: binderFilters.since?.value,
    date_end: binderFilters.until?.value
  };

  const studentQuizzes = yield call(StudentQuizApi.get, parameters);

  return studentQuizzes;
}


function * getStudentWriteResponses(studentId) {
  // Fetch the Write Responses for the current student.
  // TODO: Eventually, we should be limiting the request so that it gets only
  // unfetched write responses.
  return yield call(ConstructedResponseAnswerApi.get, {student_id: studentId});
}

const shapeEstimatedGrades = (results) => {
  const gradesWithDateKeys = {};
  for (const grade of results) {
    gradesWithDateKeys[grade.date_calculated] = {
      ...grade
    };
  }

  const mostRecentlyCalculatedDate = Object.keys(gradesWithDateKeys).pop();

  const shapedData = {
    current: gradesWithDateKeys[mostRecentlyCalculatedDate],
    byDate: gradesWithDateKeys,
  };

  return shapedData;
};

function * getEstimatedGrades(reportingFilters) {
  const {
    student_id: studentUUID,
    'date_completed:ge': since,
    'date_completed:lt': until,
  } = reportingFilters;
  try {
    const estimatedGrades = yield call(async() => {
      const response = await ApiFetcher3.get('reading-level/', {
        user_id: studentUUID,
        start_date: since,
        end_date: until
      });
      const {data} = response;
      const {results} = data;
      return results;
    });
    return shapeEstimatedGrades(estimatedGrades);
  } catch (e) {
    return {};
  }
}

function * getOverallAggregates(reportingFilters) {
  const parameters = {
    measures: [
      'count:header_views',
      'sum:reading_time',
      'avg:quiz_score',
      'count:quizzes',
      'avg:write_response_score',
      'count:write_response_score',
      'count:annotations',
    ],
    dimensions: [
      'student_id',
    ],
    filters: {
      ...reportingFilters
    },
  };

  const report = yield call(ReportingApi.getReport, parameters);

  return report.data || [];
}


function * getClassroomAggregates(reportingFilters) {
  const parameters = {
    measures: [
      'avg:grade_level',
      'count:header_views',
      'sum:reading_time',
      'avg:quiz_score',
      'count:quizzes',
      'avg:write_response_score',
      'count:write_response_score',
      'count:annotations',
    ],
    dimensions: [
      'assignment_classroom_id',
    ],
    filters: {
      ...reportingFilters,
    },
  };

  const report = yield call(ReportingApi.getReport, parameters);

  return report.data;
}


function * getStudentWork(reportingFilters) {
  /* Important:
   * The article_views + header_views measures aren't used anywhere on the front end, but having them included in the
   * measures list tips the balance of the reporting API plane traversal. Without the article/header_views the reporting
   * API will join from constructedresponse to article and fail to return some article measures (eg: grade_level) where
   * there is no constructed response.
   */

  const parameters = {
    measures: [
      'count:article_views',
      'count:header_views',
      'sum:reading_time',
      'min:quiz_score',
      'avg:write_response_score',
      'count:annotations',
      'max:date_completed',
      'max:date_article_read',
      'max:date_quiz_completed',
      'max:date_write_response_submitted',
      'max:date_annotated',
    ],
    dimensions: [
      'assignment_classroom_id',
      'article_id',
      'article_title',
      'header_id',
      'header_slug',
      'article_grade_level'
    ],
    filters: {
      ...reportingFilters,
    },
  };

  const report = yield call(ReportingApi.getReport, parameters);

  return report.data || [];
}


function * getSkillMastery(reportingFilters) {
  const parameters = {
    measures: [
      'count:quiz_questions',
    ],
    dimensions: [
      'assignment_classroom_id',
      'is_correct',
      'cc_anchor_standard',
    ],
    filters: {
      ...reportingFilters
    },
  };

  const overallSkillMastery = yield call(ReportingApi.getReport, parameters);

  return overallSkillMastery.data || [];
}


/**
 * This is the main StudentDetails saga watching for FETCH_STUDENT_DETAILS events to fire.
 * This saga handles kicking off *all* the necessary data requests to build the StudentDetails component.
 */
function * watchBinderStudentDetails() {
  yield takeLatest(types.FETCH_STUDENT_DETAILS, function * (action) {
    const studentId = parseInt(action.payload.studentId);

    const studentDetail = yield select(getStudent, studentId);
    const reportingFilters = yield call(
      toStudentDetailsReportingFilters,
      action.payload.binderFilters,
      studentDetail?.unique_id,
    );

    const binderFilters = action.payload.binderFilters;

    // Tell the state we are loading details for this student.
    yield put(actions.storeStudentDetails(studentId, {
      isLoading: true
    }));

    // Request data streams in parallel.
    const [
      classrooms,
      studentQuizzes,
      estimatedGrades,
      overallAggregates,
      skillMastery,
      classroomAggregates,
      studentWork,
      writeResponses
    ] = yield all([
      call(getClassroomsForStudent, studentId),
      call(getStudentQuizzes, studentId, binderFilters),
      call(getEstimatedGrades, reportingFilters),
      call(getOverallAggregates, reportingFilters),
      call(getSkillMastery, reportingFilters),
      call(getClassroomAggregates, reportingFilters),
      call(getStudentWork, reportingFilters),
      call(getStudentWriteResponses, studentId)
    ]);

    // Once all the data comes back, do one big batch update to the store.
    const payload = {
      studentId: studentId,
      isLoading: false,
      classrooms: keyBy(classrooms, 'id'),
      dateLastLoaded: (new Date()).toISOString(),
      studentQuizzes: keyBy(studentQuizzes, 'id'),
      estimatedGrades: estimatedGrades.byDate,
      currentReadingLevel: estimatedGrades.current,
      overallAggregates: first(overallAggregates) || {},
      classroomAggregates: keyBy(classroomAggregates, 'assignment_classroom_id'),
      skillMastery: {...shapeSkillsMasteryReportingData(skillMastery)},
      studentWork: studentWork,
      writeResponses: keyBy(writeResponses, 'id'),
    };

    yield put(actions.storeStudentDetails(studentId, payload));
  });
}

export {watchBinderStudentDetails, shapeEstimatedGrades};
