exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__color-quiz-correct___hinBS{background-color:#52A821}.styles__color-quiz-incorrect___BkGO9{background-color:#D93F31}.styles__color-science___ThuTa{background-color:#55bca6}.styles__color-kids___Hi-uC{background-color:#5908f7}.styles__color-money___nDdsl{background-color:#fa0045}.styles__color-law___j-Mla{background-color:#002764}.styles__color-health___3d0fq{background-color:#03a43c}.styles__color-arts___9LQH1{background-color:#E2C925}.styles__color-war-and-peace___PD-7R{background-color:#E76A0E}.styles__color-sports___I6i6-{background-color:#000000}.styles__color-opinion___fmBIj{background-color:#0027f5}.styles__color-text-set___mJauH{background-color:#106FF3}.styles__color-object-gray___B9Cqp{background-color:#878787}.styles__color-light-gray___gu-Hk{background-color:#F8F8F8}.styles__color-middle-gray___ACI-A{background-color:#BCBCBE}.styles__color-newsela-black___xwFhF{background-color:#333333}.styles__color-dark-bg___tuvuw{background-color:#031E3E}.styles__color-newsela-blue___bzWEQ{background-color:#0768fd}.styles__collapsedSideBar___KwFNp{width:110px;margin-top:30px;margin-left:10px;border-top-left-radius:10px;border-bottom-left-radius:10px}.styles__collapsedSideBar___KwFNp button{height:100px;width:100%;display:flex;justify-content:center;background-color:#fff;border:solid 1px #333;border-right:none;border-top-left-radius:10px;border-bottom-left-radius:10px;box-shadow:-4px 5px 4px 0 rgba(144,144,144,.29)}.styles__collapsedSideBar___KwFNp button:hover{background-color:#bcbcbe;cursor:pointer}.styles__buttonContents___ga609{display:flex;flex-direction:column;justify-content:space-evenly;height:100%}.styles__buttonContents___ga609 i{font-size:1.33rem;align-self:stretch;color:#106ff3;text-align:left}.styles__buttonContents___ga609 .styles__label___lInvy{font-family:Circular,\"Segoe UI\",Helvetica,Arial,sans-serif;font-size:1rem}", ""]);

// exports
exports.locals = {
	"color-quiz-correct": "styles__color-quiz-correct___hinBS",
	"color-quiz-incorrect": "styles__color-quiz-incorrect___BkGO9",
	"color-science": "styles__color-science___ThuTa",
	"color-kids": "styles__color-kids___Hi-uC",
	"color-money": "styles__color-money___nDdsl",
	"color-law": "styles__color-law___j-Mla",
	"color-health": "styles__color-health___3d0fq",
	"color-arts": "styles__color-arts___9LQH1",
	"color-war-and-peace": "styles__color-war-and-peace___PD-7R",
	"color-sports": "styles__color-sports___I6i6-",
	"color-opinion": "styles__color-opinion___fmBIj",
	"color-text-set": "styles__color-text-set___mJauH",
	"color-object-gray": "styles__color-object-gray___B9Cqp",
	"color-light-gray": "styles__color-light-gray___gu-Hk",
	"color-middle-gray": "styles__color-middle-gray___ACI-A",
	"color-newsela-black": "styles__color-newsela-black___xwFhF",
	"color-dark-bg": "styles__color-dark-bg___tuvuw",
	"color-newsela-blue": "styles__color-newsela-blue___bzWEQ",
	"collapsedSideBar": "styles__collapsedSideBar___KwFNp",
	"buttonContents": "styles__buttonContents___ga609",
	"label": "styles__label___lInvy"
};