import React from 'react';

import {faCaretDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Menu from '@material-ui/core/Menu';
import {cx} from 'emotion';
import {get, map, mergeWith, reduce} from 'lodash-es';
import PropTypes from 'prop-types';

import Button, {ButtonWithTrackEvent} from 'static/three-oh/src/components/Button';
import withTrackEvent from 'static/three-oh/src/components/with/WithTrackEvent';
import AngelouProps from 'static/three-oh/src/propTypes/AngelouPropTypes';

import DropDownMenuItem from './DropDownMenuItem';
import DropDownMenuLabelItem from './DropDownMenuLabelItem';
import HiddenDoppelgangerMenuForMeasuring from './HiddenDoppelgangerMenuForMeasuring';

import styles from './styles.scss';


const propTypes = {
  /**
   * default angelou props
   */
  ...AngelouProps,
  /**
   * The text that will appear when the menu is collapsed.
   * can be a react element
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

  /**
   * Props to assign to the drop down label. See
   * the Button component to get a comprehensive list
   */
  buttonProps: PropTypes.object,

  classes: PropTypes.shape({
    root: PropTypes.string,
    // class applied to the button that opens the drop down
    button: PropTypes.string,
    // applied to the top level material ui menu
    menu: PropTypes.string,
    // passed to menuLabelItem. see that component for details
    menuLabelItem: PropTypes.object,
    // passed to the menuItems. see DropDownMenuItem for details
    menuItems: PropTypes.object
  }),

  /**
   * List of options to be passed to the drop down.
   * The shape of the object must be {label, onCLick}
   * Note, that the label can be a react component itself, and onClick
   * is optional
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      // The shape of the children object should be the same general
      // shape of the options. Used for sections
      children: PropTypes.arrayOf(PropTypes.object),
      // dataQaSelector specific data-qa selector
      dataQaSelector: PropTypes.string,
      // label can be anything. even another component
      label: PropTypes.string,
      // onclick will be passed the option and the index in the list
      onClick: PropTypes.func,
      // The label for the section if there is one
      sectionLabel: PropTypes.string,
      // Used to apply a QA selector to the top level `li` element that
      // wraps the section label and the first child of the section.
      sectionLiQaSelector: PropTypes.string,
      // QA selector for the section label
      sectionLabelQaSelector: PropTypes.string,
      // selected will determine whether it gets displayed as currently selected
      selected: PropTypes.bool,
      // a function that takes the current option and
      // returns a jsx element that will be rendered
      // in the menu
      __renderOption: PropTypes.func,
      // prevent closing the menu when you click an item
      __preventCloseOnClick: PropTypes.bool,
      // Event properties to attach to each specific option
      eventProperties: PropTypes.object,
    })
  ).isRequired,

  /**
   * a function that fires after the menu closes. this will only fire
   * if the menu is closed without selecting a option, for example by
   * clicking away from the menu
   */
  onClose: PropTypes.func,

  /**
   * a function that fires when the menu closes
   */
  onExit: PropTypes.func,

  /**
   * a function that fires before the menu opens
   */
  onEnter: PropTypes.func,

  /**
   * an optional function that will replace the rendering of the
   * default angelou button component. It is recomended you dont use
   * this and instead pass in css overrides or style with classnames
   */
  __renderLabel: PropTypes.func,

  /**
   * This is a callback from the change reading level button.
   * It is used here to toggle the button's aria-label.
   */
  onMenuStateChange: PropTypes.func,

  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  autoButtonWidth: PropTypes.bool,
  sectionLiQaSelector: PropTypes.string,
  sectionLabelQaSelector: PropTypes.string,
};

const open = 'open';
const closed = 'closed';

class DropDown extends React.Component {
  state = {
    menuState: closed,
    anchorEl: null,
    autoCalculatedWidth: 0
  };

  componentDidUpdate(prevProps, prevState) {
    const {menuState} = this.state;
    const {onMenuStateChange} = this.props;
    if (prevState.menuState !== menuState) {
      onMenuStateChange();
    }
  }

  handleClick = (event) => {
    this.setState({anchorEl: event.currentTarget, menuState: open});
  };

  setMenuState = (menuState) => {
    const newState = {menuState};

    if (menuState === closed) {
      newState.anchorEl = null;
    }
    this.setState(newState);
  };

  /**
   *
   * @param {} event
   * @param {string} args
   * essentially just fire off the optional actions
   * the consumer passes in, onClose, onExit, onEnter
   */
  onEvent = (event, args = []) => {
    if (this.props[event]) {
      this.props[event](...args);
    }
  };

  createSection = (section, defaultOnClick, index) => {
    const {classes} = this.props;
    return map(section.children, (child, i) => {
      const newIndex = i + index;
      if (i === 0) {
        const onClick = (e) => {
          if (!child.__preventCloseOnClick) {
            defaultOnClick(closed);
          }
          if (child.onClick) {
            child.onClick(child, newIndex, e);
          }
        };
        const subChild = child.__renderOption ? child.__renderOption(child)
          : (
            <span
              data-qa-selector={child.dataQaSelector ? child.dataQaSelector : null}
              onClick={onClick}
              aria-label={child.ariaLabel}
            >
              {child.label}
            </span>
            );
        const {
          minWidth,
          maxWidth,
          eventProperties
        } = this.props;
        const menuLabelClasses = {
          menuItem: get(classes, 'menuItems', {}),
          ...get(classes, 'menuLabelItem', {}),
        };
        const DDMLIProps = {
          classes: menuLabelClasses,
          key: `sectionLabel_${newIndex}`,
          label: section.sectionLabel,
          sectionLiQaSelector: section.sectionLiQaSelector,
          sectionLabelQaSelector: section.sectionLabelQaSelector,
          __renderLabel: section.__renderLabel,
          child: subChild,
          onClick: onClick,
          selected: child.selected,
          minWidth: minWidth,
          maxWidth: maxWidth
        };
        if (eventProperties) {
          const DropDownMenuLabelItemWithTracking = withTrackEvent(DropDownMenuLabelItem);
          return (
            <DropDownMenuLabelItemWithTracking
              {...DDMLIProps}
              eventProperties={{...eventProperties}}
              trackView={false}
            />
          );
        } else {
          return (
            <DropDownMenuLabelItem
              {...DDMLIProps}
            />
          );
        }
      } else {
        return this.createMenuItem(child, defaultOnClick, newIndex);
      }
    });
  }

  createMenuItem = (option, defaultOnClick, index) => {
    let child;
    if (!option.__renderOption) {
      child = (
        <span
          style={{width: '100%'}}
          aria-label={option.ariaLabel}
          data-qa-selector={option.dataQaSelector ? option.dataQaSelector : null}
        >
          {option.label}
        </span>
      );
    } else {
      child = option.__renderOption(option);
    }
    const {
      eventProperties,
      streamProperties,
      indexOfSelected,
      minWidth,
      maxWidth,
      classes
    } = this.props;

    const DropDownMenuItemWithTracking = withTrackEvent(DropDownMenuItem);
    const relevantClasses = get(classes, 'menuItems', {});
    if (eventProperties) {
      return (
        <DropDownMenuItemWithTracking
          classes={relevantClasses}
          key={`option_${index}`}
          minWidth={minWidth}
          maxWidth={maxWidth}
          objectType="Content"
          actionName="Read"
          legacyEventName="ArticleLevel-Change"
          streamProperties={{
            ...streamProperties,
            ...option.eventProperties || ({}),
          }}
          eventProperties={{
            ...eventProperties,
            ...option.eventProperties || ({}),
          }}
          trackView={false}
          child={child || null}
          value={`dropDown_${index}`}
          selected={index === indexOfSelected}
          onClick={(e) => {
            if (!option.__preventCloseOnClick) {
              defaultOnClick(closed);
            }
            if (option.onClick) {
              option.onClick(option, index, e);
            }
          }}
        />
      );
    }

    return (
      <DropDownMenuItem
        classes={relevantClasses}
        key={`option_${index}`}
        minWidth={minWidth}
        maxWidth={maxWidth}
        child={child}
        value={`dropDown_${index}`}
        selected={option.selected}
        onClick={(e) => {
          if (!option.__preventCloseOnClick) {
            defaultOnClick(closed);
          }
          if (option.onClick) {
            option.onClick(option, index, e);
          }
        }}
      />
    );
  };

  render() {
    const {
      buttonProps,
      additionalClassNames,
      dataQaSelector,
      classes
    } = this.props;

    // create the options
    let options = [];
    this.props.options.forEach((option, index) => {
      if (!option.children) {
        options.push(this.createMenuItem(option, this.setMenuState, index));
      } else {
        options = [...options, ...this.createSection(option, this.setMenuState, index)];
      }
    });

    // final button props that define what the dropdown looks like
    let finalButtonProps = {
      onClick: this.handleClick,
      label: this.props.label,
      labelPosition: 'before',
      additionalClassNames: [get(classes, 'button')],
      objectType: this.props.objectType,
      actionName: this.props.actionName,
      actionPrefix: this.props.actionPrefix,
      legacyEventName: this.props.legacyEventName,
      eventProperties: {
        search_id: get(this.props.eventProperties, 'search_id'),
      },
      streamProperties: this.props.streamProperties,
    };

    if (this.props.autoButtonWidth) {
      finalButtonProps.buttonElementProps = {
        style: {width: this.state.autoCalculatedWidth}
      };
    }

    // If the user passes in button props they will be used to
    // override and / or augment the default button props.
    if (buttonProps) {
      finalButtonProps = mergeWith(
        finalButtonProps,
        buttonProps,
        (objValue, srcValue, key) => {
          if (key === 'className') {
            return `${objValue} ${srcValue}`;
          }
          if (key === 'onClick') {
            return (e) => {
              this.handleClick(e);
              srcValue(e);
            };
          }
        }
      );
    }

    // If the finalButtonProps doesn't have an icon key, add a default.
    // We can't do this in mergeWith, as it throws errors when Icons
    // have different type values.
    if (finalButtonProps.icon === undefined) {
      finalButtonProps.icon = (<FontAwesomeIcon icon={faCaretDown}/>);
    }

    const classNames = reduce(
      additionalClassNames,
      (result, value) => `${result} ${value}`
    );
    const menuElement = (
      <Menu
        data-qa-selector={this.props.menuDataQaSelector}
        className={cx([styles.dropDown, get(classes, 'menu')])}
        anchorEl={this.state.anchorEl}
        open={this.state.menuState === open}
        onClose={() => {
          this.onEvent('onClose');
          this.setMenuState(closed);
        }}
        onEnter={() => {
          this.onEvent('onEnter');
        }}
        onExit={() => {
          this.onEvent('onExit');
        }}
      >
        {options}
      </Menu>
    );

    if (this.props.autoButtonWidth) {
      // If the hidden doppelganger were to re-render when the width changes it
      // would cause it to reset its width, thus triggering the cycle all over again.
      // `this._rerenderDueToWidthChange` guards from such infinite re-render cycles
      this.hiddenDoppelgangerMenuForMeasuring = (
        this.hiddenDoppelgangerMenuForMeasuring && this._rerenderDueToWidthChange
          ? this.hiddenDoppelgangerMenuForMeasuring
          : (
            <HiddenDoppelgangerMenuForMeasuring
              menuElement={menuElement}
              onWidthChange={(width) => {
                this.setState({autoCalculatedWidth: width});
                this._rerenderDueToWidthChange = true;
              }}
            >
              {options}
            </HiddenDoppelgangerMenuForMeasuring>
            )
      );
      this._rerenderDueToWidthChange = false;
    }

    const renderButtonWithOrWithoutTracking = (props) => {
      const {__renderLabel, renderButtonWithoutTrackEvent} = props;
      if (__renderLabel) {
        return __renderLabel(this.handleClick);
      } else if (renderButtonWithoutTrackEvent) {
        return (
          <Button
            {...finalButtonProps}
          />
        );
      } else {
        return (
          <ButtonWithTrackEvent
            {...finalButtonProps}
            trackView={false}
          />
        );
      }
    };

    return (
      <div className={cx([classNames, get(classes, 'root')])} data-qa-selector={dataQaSelector}>
        {renderButtonWithOrWithoutTracking(this.props)}
        {menuElement}
        {this.hiddenDoppelgangerMenuForMeasuring}
      </div>
    );
  }
}

DropDown.propTypes = propTypes;
export default DropDown;
