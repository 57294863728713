import React from 'react';

import {useOnClickOff} from '@newsela/angelou';
import {css} from 'emotion';
import PropTypes from 'prop-types';

import {
  absoluteContainerCss,
  subNavMenuPositionCss
  , getContainerPositionCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/NavButtonWithPopout/NavButtonWithPopout.styles';

const propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  isSubNavMenu: PropTypes.bool,
  rootCustomCss: PropTypes.object,
  position: PropTypes.object,
  ButtonComponent: PropTypes.node.isRequired,
  PopoutComponent: PropTypes.node.isRequired,
};

const NavButtonWithPopout = ({
  id,
  isOpen,
  setIsOpen,
  position,
  isSubNavMenu,
  rootCustomCss = {},
  ButtonComponent,
  PopoutComponent,
}) => {
  const closeMenu = (e) => {
    const isEventFromSelf =
      e.target.id === id ||
      e.target.classList.contains(id);
    if (isEventFromSelf) return;
    setIsOpen(false);
  };
  const clickOffRef = useOnClickOff(closeMenu);

  const onPressEscape = (event) => {
    if (event.key === 'Escape') {
      event.stopPropagation();
      document.getElementById(id)?.focus();
      setIsOpen(false);
    }
  };

  const {
    top,
    right,
    bottom,
    left
  } = position;

  let popoutStyle = {
    ...absoluteContainerCss,
    top,
    right,
    bottom,
    left,
  };

  if (isSubNavMenu) {
    popoutStyle = {
      ...popoutStyle,
      ...subNavMenuPositionCss,
    };
  }

  return (
    <li
      onKeyDown={onPressEscape}
      className={css({...getContainerPositionCss(isSubNavMenu), ...rootCustomCss})}
    >
      {ButtonComponent}

      {isOpen && (
        <div
          data-testid="navmenupopout-container"
          ref={clickOffRef}
          aria-labelledby={id}
          className={css(popoutStyle)}
        >
          {PopoutComponent}
        </div>
      )}
    </li>
  );
};

NavButtonWithPopout.propTypes = propTypes;
NavButtonWithPopout.displayName = 'NavButtonWithPopout';
export {NavButtonWithPopout};
