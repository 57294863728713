exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root{--global_header_height:79.5px;--masthead_height: 79.5px;--utility_nav_height: 79.5px;--indicator_height: 50px;--content_offset_top: var(--global_header_height);--global_nav_height: 73.2px;--content_offset_bottom: var(--global_nav_height);--inner_height: 100vh}@media(min-width: 769px){:root{--masthead_height: 64px;--utility_nav_height: 64px;--global_nav_height: 73.2px;--global_header_height:64px;--content_offset_bottom: 0px}}.styles__explainer___gT84R{font-size:.875rem;max-width:350px;margin-top:1em}.styles__boldCopy___5-SHa{font-weight:bold}", ""]);

// exports
exports.locals = {
	"explainer": "styles__explainer___gT84R",
	"boldCopy": "styles__boldCopy___5-SHa"
};