// one constants file to ensure that we dont have any duplicate
// action types floating around. We can keep this pattern until
// it doesnt make sense then try and come up with a prettier
// solution. Constants should be listed in alphabetical order
// and seperated by function
export const ADD_ACTIVE_ARTICLE_ACTIVITY = 'ADD_ACTIVE_ARTICLE_ACTIVITY';
export const CLEAR_READ_PAGE_MODE = 'CLEAR_READ_PAGE_MODE';
export const SET_ARIA_HIDDEN_MAIN_CONTENT = 'SET_ARIA_HIDDEN_MAIN_CONTENT';
export const SET_READ_PAGE_ELEMENTS = 'SET_READ_PAGE_ELEMENTS';
export const SET_READ_PAGE_MODE = 'SET_READ_PAGE_MODE';
export const SET_READ_PAGE_CONTEXT = 'SET_READ_PAGE_CONTEXT';
export {pageElements, modes} from './readPageModeConstants';
export const MODAL_SET = 'MODAL_SET';
export const MODAL_CLEAR = 'MODAL_CLEAR';
export const SCREEN_RESIZED = 'SCREEN_RESIZED';
export const SET_REVEAL_ANSWER = 'REVEAL_ANSWER';
export const UPDATE_ACTIVE_ARTICLE_QUIZ = 'UPDATE_ACTIVE_ARTICLE_QUIZ';

// user email actions
export const FETCH_USER_EMAILS_REQUEST = 'FETCH_USER_EMAILS_REQUEST';
export const FETCH_USER_EMAILS_SUCCESS = 'FETCH_USER_EMAILS_SUCCESS';
export const FETCH_USER_EMAILS_ERROR = 'FETCH_USER_EMAILS_FAILURE';
export const UPDATE_EMAIL_REQUEST = 'UPDATE_EMAIL_REQUEST';
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';
export const UPDATE_EMAIL_ERROR = 'UPDATE_EMAIL_FAILURE';
export const CREATE_EMAIL_REQUEST = 'CREATE_EMAIL_REQUEST';
export const CREATE_EMAIL_ERROR = 'CREATE_EMAIL_FAILURE';
export const CREATE_EMAIL_SUCCESS = 'CREATE_EMAIL_SUCCESS';

export const UPDATE_EMAILS_IN_STORE_ONLY = 'UPDATE_EMAILS_IN_STORE_ONLY';

export const GET_CONTENT_REQUEST = 'GET_CONTENT_REQUEST';
export const GET_CONTENT_SUCCESS = 'GET_CONTENT_SUCCESS';
export const GET_CONTENT_FAILURE = 'GET_CONTENT_FAILURE';

// liked content like (save/bookmark) actions
export const CREATE_LIKED_CONTENT_ERROR = 'CREATE_LIKED_CONTENT_ERROR';
export const CREATE_LIKED_CONTENT_REQUEST = 'CREATE_LIKED_CONTENT_REQUEST';
export const CREATE_LIKED_CONTENT_SUCCESS = 'CREATE_LIKED_CONTENT_SUCCESS';

export const DELETE_LIKED_CONTENT_ERROR = 'DELETE_LIKED_CONTENT_ERROR';
export const DELETE_LIKED_CONTENT_REQUEST = 'DELETE_LIKED_CONTENT_REQUEST';
export const DELETE_LIKED_CONTENT_SUCCESS = 'DELETE_LIKED_CONTENT_SUCCESS';

export const GET_LIKED_CONTENT_ERROR = 'GET_LIKED_CONTENT_ERROR';
export const GET_LIKED_CONTENT_REQUEST = 'GET_LIKED_CONTENT_REQUEST';
export const GET_LIKED_CONTENT_SUCCESS = 'GET_LIKED_CONTENT_SUCCESS';

// Roster sync actions
export const REQUEST_ALL_ROSTER_SYNC_INFO = 'REQUEST_ALL_ROSTER_SYNC_INFO';
export const REQUEST_ALL_ROSTER_SYNC_INFO_SUCCESS = 'REQUEST_ALL_ROSTER_SYNC_INFO_SUCCESS';
export const REQUEST_ALL_ROSTER_SYNC_INFO_FAIL = 'REQUEST_ALL_ROSTER_SYNC_INFO_FAIL';
export const SYNC_ROSTER_REQUEST = 'SYNC_ROSTER_REQUEST';
export const SYNC_ROSTER_REQUEST_SUCCESS = 'SYNC_ROSTER_REQUEST_SUCCESS';
export const SYNC_ROSTER_REQUEST_FAIL = 'SYNC_ROSTER_REQUEST_FAIL';
export const MARK_ROSTER_INFO_AS_SEEN = 'MARK_ROSTER_INFO_AS_SEEN';
export const CLEAR_LATEST_SYNC_PROVIDER_INFO = 'CLEAR_LATEST_SYNC_PROVIDER_INFO';
export const ROSTER_SYNC_IN_PROGRESS = 'ROSTER_SYNC_IN_PROGRESS';

// Roster provider Classrooms
export const REQUEST_ROSTER_CLASSROOMS = 'REQUEST_ROSTER_CLASSROOMS';
export const REQUEST_ROSTER_CLASSROOMS_SUCCESS = 'REQUEST_ROSTER_CLASSROOMS_SUCCESS';
export const REQUEST_ROSTER_CLASSROOMS_FAILURE = 'REQUEST_ROSTER_CLASSROOMS_FAILURE';

// Roster selection actions
export const REQUEST_ROSTER_CHOICE = 'REQUEST_ROSTER_CHOICE';
export const REQUEST_ROSTER_CHOICE_SUCCESS = 'REQUEST_ROSTER_CHOICE_SUCCESS';
export const REQUEST_ROSTER_CHOICE_FAILURE = 'REQUEST_ROSTER_CHOICE_FAILURE';
export const ROSTER_CHOICE_UPDATE = 'ROSTER_CHOICE_UPDATE';
export const ROSTER_CHOICE_CREATE_AND_SYNC = 'ROSTER_CHOICE_CREATE_AND_SYNC';
export const ROSTER_CHOICE_CREATE = 'ROSTER_CHOICE_CREATE';

export const REQUEST_ROSTER_DETAILS = 'REQUEST_ROSTER_DETAILS';
export const REQUEST_ROSTER_DETAILS_SUCCESS = 'REQUEST_ROSTER_DETAILS_SUCCESS';
export const REQUEST_ROSTER_DETAILS_FAILURE = 'REQUEST_ROSTER_DETAILS_FAILURE';

// External tool context actions
export const CLEAR_EXTERNAL_TOOL_CONTEXT = 'CLEAR_EXTERNAL_TOOL_CONTEXT';
export const SET_EXTERNAL_TOOL_CONTEXT = 'SET_EXTERNAL_TOOL_CONTEXT';
export const EXTERNAL_TOOL_UPDATE_SUCCESS = 'EXTERNAL_TOOL_UPDATE_SUCCESS';
export const GET_EXTERNAL_TOOL_CONTEXT = 'GET_EXTERNAL_TOOL_CONTEXT';


// Canvas Assignment Review actions
export const CANVAS_APP_LOAD_REVIEW_PAGE = 'CANVAS_APP_LOAD_REVIEW_PAGE';
export const CANVAS_APP_LOAD_REVIEW_PAGE_SUCCESS = 'CANVAS_APP_LOAD_REVIEW_PAGE_SUCCESS';
export const CANVAS_APP_LOAD_REVIEW_PAGE_FAILED = 'CANVAS_APP_LOAD_REVIEW_PAGE_FAILED';
export const CANVAS_APP_LOAD_STUDENT_SUCCESS = 'CANVAS_APP_LOAD_STUDENT_SUCCESS';
export const CANVAS_APP_LOAD_ASSIGNMENTS_REQUEST = 'CANVAS_APP_LOAD_ASSIGNMENTS_REQUEST';
export const CANVAS_APP_LOAD_ASSIGNMENTS_SUCCESS = 'CANVAS_APP_LOAD_ASSIGNMENTS_SUCCESS';
export const CANVAS_APP_LOAD_ASSIGNMENTS_FAILURE = 'CANVAS_APP_LOAD_ASSIGNMENTS_FAILURE';
export const CANVAS_APP_LOAD_ARTICLE_HEADER_SUCCESS = 'CANVAS_APP_LOAD_ARTICLE_HEADER_SUCCESS';
export const CANVAS_APP_LOAD_ARTICLE_WORDS_SUCCESS = 'CANVAS_APP_LOAD_ARTICLE_WORDS_SUCCESS';
export const CANVAS_APP_LOAD_STUDENT_QUIZZ_SUCCESS = 'CANVAS_APP_LOAD_STUDENT_QUIZZ_SUCCESS';
export const CANVAS_APP_LOAD_ANNOTATIONS_SUCCESS = 'CANVAS_APP_LOAD_ANNOTATIONS_SUCCESS';

// Event Record actions
export const EVENT_RECORD_REQUEST = 'EVENT_RECORD_REQUEST';
export const EVENT_RECORD_REQUEST_SUCCESS = 'EVENT_RECORD_REQUEST_SUCCESS';
export const EVENT_RECORD_REQUEST_FAILURE = 'EVENT_RECORD_REQUEST_FAILURE';
export const EVENT_RECORD_CREATE = 'EVENT_RECORD_CREATE';

// Collections actions
export const COLLECTIONS_FETCH_REQUEST = 'COLLECTIONS_FETCH_REQUEST';
export const COLLECTIONS_FETCH_SUCCESS = 'COLLECTIONS_FETCH_SUCCESS';
export const COLLECTIONS_FETCH_FAILURE = 'COLLECTIONS_FETCH_FAILURE';

// Lesson Sparks Actions
export const GET_LESSON_SPARK_REQUEST = 'GET_LESSON_SPARK_REQUEST';
export const GET_LESSON_SPARK_SUCCESS = 'GET_LESSON_SPARK_SUCCESS';
export const GET_LESSON_SPARK_FAILURE = 'GET_LESSON_SPARKS_FAILURE';

export const SET_CONTEXT = 'SET_CONTEXT';

// The 'Your Content' Page Actions
export const INITIALIZE_THE_YOUR_CONTENT_PAGE = 'INITIALIZE_THE_YOUR_CONTENT_PAGE';
export const BOOKMARKED_ARTICLE_HEADERS_FETCH_REQUEST = 'BOOKMARKED_ARTICLE_HEADERS_FETCH_REQUEST';
export const BOOKMARKED_ARTICLE_HEADERS_FETCH_SUCCESS = 'BOOKMARKED_ARTICLE_HEADERS_FETCH_SUCCESS';
export const BOOKMARKED_ARTICLE_HEADERS_FETCH_FAILURE =
'BOOKMARKED_ARTICLE_HEADERS_FETCH_FAILURE';
export const TEXT_SETS_FETCH_REQUEST = 'TEXT_SETS_FETCH_REQUEST';
export const BOOKMARKED_TEXT_SETS_FETCH_SUCCESS = 'BOOKMARKED_TEXT_SETS_FETCH_SUCCESS';
export const BOOKMARKED_TEXT_SETS_FETCH_FAILURE = 'BOOKMARKED_TEXT_SETS_FETCH_FAILURE';
export const USER_TEXT_SETS_FETCH_SUCCESS = 'USER_TEXT_SETS_FETCH_SUCCESS';
export const USER_TEXT_SETS_FETCH_FAILURE = 'USER_TEXT_SETS_FETCH_FAILURE';
export const SCHOOL_TEXT_SETS_FETCH_SUCCESS = 'SCHOOL_TEXT_SETS_FETCH_SUCCESS';
export const SCHOOL_TEXT_SETS_FETCH_FAILURE = 'SCHOOL_TEXT_SETS_FETCH_FAILURE';
export const BOOKMARKED_STANDARDS_FETCH_REQUEST = 'BOOKMARKED_STANDARDS_FETCH_REQUEST';
export const BOOKMARKED_STANDARDS_FETCH_SUCCESS =
'BOOKMARKED_STANDARDS_FETCH_FAILURE';
export const BOOKMARKED_STANDARDS_FETCH_FAILURE =
'BOOKMARKED_STANDARDS_FETCH_FAILURE';
