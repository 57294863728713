import {forEach} from 'lodash-es';

import {
  HEADER_FACETS,
  TEXT_SET_FACETS,
  STANDARD_FACETS
} from 'static/three-oh/src/modulesV2/utils/searchUtils';

/**
 * given a img src string return a object with horizontal and vertical
 * focal points
 *
 * @param {String} urlString
 * @return {Object}
 */
export const getImageFocalPoints = (urlString) => {
  const parsedQueryString = new URLSearchParams(urlString);
  const horiz_focal_point = parsedQueryString.get('horizontal_focal_point');
  const vert_focal_point = parsedQueryString.get('vertical_focal_point');
  return {
    horizontal_focal_point: horiz_focal_point || 'center',
    vertical_focal_point: vert_focal_point || 'center',
  };
};

/**
 * Construct the search params that will be included in a request sent to the
 * the Search Api.
 * @param {string} content_type
 * @param {string} rule
 * @param {integer} page_size
 */
export const buildSearchParamsForSearchApiRequest = (content_type, rule, page_size) => {
  const finalSearchParams = new URLSearchParams({
    facets: true,
    format: 'full',
    objects: [content_type],
    rule: rule,
    page_size: page_size
  });
  let filters;
  if (content_type === 'header') {
    filters = HEADER_FACETS;
  } else if (content_type === 'textSet') {
    filters = TEXT_SET_FACETS;
  } else if (content_type === 'standard') {
    filters = STANDARD_FACETS;
  }

  // Construct the request's search-params based on the content_type's filters.
  // These search params will communicate how the Search Api should filter this
  // content_type.
  const currentSearchParams = new URLSearchParams(location.search);
  forEach(filters, (filter) => {
    const filterValue = currentSearchParams.get(filter);
    if (filterValue) {
      finalSearchParams.append(filter, filterValue);
    }
  });
  return finalSearchParams;
};
