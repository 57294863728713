const namespace = 'binder/work';

export const START_POLLING = `${namespace}/START_POLLING`;
export const STOP_POLLING = `${namespace}/STOP_POLLING`;

export const TOGGLE_WORK_DETAILS = `${namespace}/TOGGLE_WORK_DETAILS`;
export const OPEN_WORK_DETAILS = `${namespace}/OPEN_WORK_DETAILS`;
export const CLOSE_WORK_DETAILS = `${namespace}/CLOSE_WORK_DETAILS`;
export const WORK_DETAILS_FINISHED_LOADING = `${namespace}/WORK_DETAILS_FINISHED_LOADING`;

export const ARTICLE_HEADER_CLASSROOMS_FETCH_REQUEST = `${namespace}/ARTICLE_HEADER_CLASSROOMS_FETCH_REQUEST`;
export const ARTICLE_HEADER_CLASSROOMS_FETCH_SUCCESS = `${namespace}/ARTICLE_HEADER_CLASSROOMS_FETCH_SUCCESS`;
export const ARTICLE_HEADER_CLASSROOMS_FETCH_FAILURE = `${namespace}/ARTICLE_HEADER_CLASSROOMS_FETCH_FAILURE`;

export const ASSIGNMENTS_FETCH_REQUEST = `${namespace}/ASSIGNMENTS_FETCH_REQUEST`;
export const ASSIGNMENTS_FETCH_SUCCESS = `${namespace}/ASSIGNMENTS_FETCH_SUCCESS`;
export const ASSIGNMENTS_FETCH_FAILURE = `${namespace}/ASSIGNMENTS_FETCH_FAILURE`;

export const NOTIFICATIONS_FETCH_REQUEST = `${namespace}/NOTIFICATIONS_FETCH_REQUEST`;
export const NOTIFICATIONS_FETCH_SUCCESS = `${namespace}/NOTIFICATIONS_FETCH_SUCCESS`;
export const NOTIFICATIONS_FETCH_FAILURE = `${namespace}/NOTIFICATIONS_FETCH_FAILURE`;

export const HEADER_REPORTING_DATA_FETCH_REQUEST = `${namespace}/HEADER_REPORTING_DATA_FETCH_REQUEST`;
export const HEADER_REPORTING_DATA_FETCH_SUCCESS = `${namespace}/HEADER_REPORTING_DATA_FETCH_SUCCESS`;
export const HEADER_REPORTING_DATA_FETCH_FAILURE = `${namespace}/HEADER_REPORTING_DATA_FETCH_FAILURE`;

export const SLIM_HEADER_REPORTING_DATA_FETCH_REQUEST = `${namespace}/SLIM_HEADER_REPORTING_DATA_FETCH_REQUEST`;
export const SLIM_HEADER_REPORTING_DATA_FETCH_SUCCESS = `${namespace}/SLIM_HEADER_REPORTING_DATA_FETCH_SUCCESS`;

export const READING_TIME_FETCH_SUCCESS = `${namespace}/READING_TIME_FETCH_SUCCESS`;

export const DEEP_LINK_WAS_HANDLED = `${namespace}/DEEP_LINK_WAS_HANDLED`;
