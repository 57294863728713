import {generateMediaQueries} from 'static/three-oh/utils/generateMediaQueries';

import SCREENSIZE from './ScreenSize';

const BREAKPOINTS = {};

/**
 * These numbers represent ranges.
 * xsmall = 0-425
 * small = 426-768
 * medium = 739-1023
 * large = 1024-1440 and up
 */
BREAKPOINTS[SCREENSIZE.xsmall] = 425;
BREAKPOINTS[SCREENSIZE.small] = 767;
BREAKPOINTS[SCREENSIZE.medium] = 1023;
BREAKPOINTS[SCREENSIZE.large] = 1440;

/**
 * create media queries
 */
const bpValues = Object.values(BREAKPOINTS);
const bpKeys = Object.keys(BREAKPOINTS);
const mediaQueries = {};

generateMediaQueries(bpValues).map((mq, index) => {
  // associate the media queries with the screensize properties
  if (bpKeys[index]) {
    mediaQueries[bpKeys[index]] = mq;
  } else {
    // for our largest media query
    mediaQueries.larger = mq;
  }
});

export {
  mediaQueries,
};

export default BREAKPOINTS;
