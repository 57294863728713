exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__color-quiz-correct___cQRCr{background-color:#52A821}.styles__color-quiz-incorrect___oTMlf{background-color:#D93F31}.styles__color-science___OLJ-u{background-color:#55bca6}.styles__color-kids___OkdAC{background-color:#5908f7}.styles__color-money___obD84{background-color:#fa0045}.styles__color-law___13TPh{background-color:#002764}.styles__color-health___OgwZi{background-color:#03a43c}.styles__color-arts___UWVFs{background-color:#E2C925}.styles__color-war-and-peace___HErPa{background-color:#E76A0E}.styles__color-sports___IustU{background-color:#000000}.styles__color-opinion___HHH-g{background-color:#0027f5}.styles__color-text-set___ULVPu{background-color:#106FF3}.styles__color-object-gray___nR2r0{background-color:#878787}.styles__color-light-gray___1uxZJ{background-color:#F8F8F8}.styles__color-middle-gray___NAy37{background-color:#BCBCBE}.styles__color-newsela-black___8Jn9y{background-color:#333333}.styles__color-dark-bg___c55uQ{background-color:#031E3E}.styles__color-newsela-blue___Rmysi{background-color:#0768fd}.styles__transitionEnter___Cu3Tz,.styles__transitionUp___OzNVo,.styles__transitionDown___HmEnX{position:relative;z-index:0}.styles__transitionDown___HmEnX{transform:translateY(-100%)}.styles__transitionUp___OzNVo{transform:translateY(100%)}.styles__transitionEnter___Cu3Tz{transform:translateY(0%);transition:transform 700ms ease-in}.styles__messageContainer___W0A0i{font-family:\"Circular\";display:flex;justify-content:space-between;font-size:1rem}.styles__successMsg___R1gkf{display:flex;align-items:center}.styles__successMsg___R1gkf svg{font-size:1.33rem;margin-right:10px}.styles__successMsg___R1gkf a{font-weight:400;margin-left:10px;text-decoration:underline}", ""]);

// exports
exports.locals = {
	"color-quiz-correct": "styles__color-quiz-correct___cQRCr",
	"color-quiz-incorrect": "styles__color-quiz-incorrect___oTMlf",
	"color-science": "styles__color-science___OLJ-u",
	"color-kids": "styles__color-kids___OkdAC",
	"color-money": "styles__color-money___obD84",
	"color-law": "styles__color-law___13TPh",
	"color-health": "styles__color-health___OgwZi",
	"color-arts": "styles__color-arts___UWVFs",
	"color-war-and-peace": "styles__color-war-and-peace___HErPa",
	"color-sports": "styles__color-sports___IustU",
	"color-opinion": "styles__color-opinion___HHH-g",
	"color-text-set": "styles__color-text-set___ULVPu",
	"color-object-gray": "styles__color-object-gray___nR2r0",
	"color-light-gray": "styles__color-light-gray___1uxZJ",
	"color-middle-gray": "styles__color-middle-gray___NAy37",
	"color-newsela-black": "styles__color-newsela-black___8Jn9y",
	"color-dark-bg": "styles__color-dark-bg___c55uQ",
	"color-newsela-blue": "styles__color-newsela-blue___Rmysi",
	"transitionEnter": "styles__transitionEnter___Cu3Tz",
	"transitionUp": "styles__transitionUp___OzNVo",
	"transitionDown": "styles__transitionDown___HmEnX",
	"messageContainer": "styles__messageContainer___W0A0i",
	"successMsg": "styles__successMsg___R1gkf"
};