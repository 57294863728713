import {size, camelCase} from 'lodash-es';

import {genericModelActionTypes as types} from '../constants';

const initialState = {};

function genericModelReducer(state = initialState, action) {
  const {type, payload} = action;

  if (type != types.STORE_MODELS || !payload || !size(payload.items)) {
    return state;
  }

  const modelName = camelCase(payload.modelName);
  const items = payload.items;

  return {
    ...state,
    [modelName]: {...state[modelName], ...items},
  };
}

export default genericModelReducer;
