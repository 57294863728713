import * as types from '../constants/userExperimentSegmentsActionTypes';

function userExperimentSegments(state = {}, action) {
  switch (action.type) {
    case types.USER_EXPERIMENT_SEGMENTS_SUCCESS:
      return {
        ...state,
        ...action.userExperimentSegments.data,
        is_loading: false,
      };
    case types.USER_EXPERIMENT_SEGMENTS_REQUEST:
      return {
        ...state,
        is_loading: true,
      };
    case types.USER_EXPERIMENT_SEGMENTS_FAILURE:
      return {
        ...state,
        is_loading: false,
        isError: true,
      };
    default:
      return state;
  }
}

export default userExperimentSegments;
