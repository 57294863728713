import {isEmpty, reject, merge, omit, union, uniqBy} from 'lodash-es';
import {combineReducers} from 'redux';

import {applyTo, applier} from 'utils/lodashHelpers';

import initialState from './initialState';
import {assignmentsTabActionTypes as types, studentActivityActionTypes} from '../constants';

function articleHeaderClassroomReducer(state = initialState.binderWork.articleHeaderClassrooms, action) {
  switch (action.type) {
    case types.ARTICLE_HEADER_CLASSROOMS_FETCH_REQUEST:
      return {...state, isLoading: true};

    case types.ARTICLE_HEADER_CLASSROOMS_FETCH_SUCCESS:
      return {...state, isLoading: false, items: action.payload};

    case types.ARTICLE_HEADER_CLASSROOMS_FETCH_FAILURE:
      return {...state, isLoading: false, error: action.error};

    default:
      return state;
  }
}

function assignmentReducer(state = initialState.binderWork.assignments, action) {
  switch (action.type) {
    case types.ASSIGNMENTS_FETCH_REQUEST:
      return {...state, isLoading: true};

    case types.ASSIGNMENTS_FETCH_SUCCESS:
      return {...state, isLoading: false, items: action.payload};

    case types.ASSIGNMENTS_FETCH_FAILURE:
      return {...state, isLoading: false, error: action.error};

    default:
      return state;
  }
}

function headerReportingDataReducer(state = initialState.binderWork.headerReportingData, action) {
  switch (action.type) {
    case types.HEADER_REPORTING_DATA_FETCH_REQUEST:
      return {...state, isLoading: true};

    case types.HEADER_REPORTING_DATA_FETCH_SUCCESS:
      return {...state, isLoading: false, items: action.payload};

    case types.HEADER_REPORTING_DATA_FETCH_FAILURE:
      return {...state, isLoading: false, error: action.error};

    case types.SLIM_HEADER_REPORTING_DATA_FETCH_REQUEST:
      return state;

    case types.SLIM_HEADER_REPORTING_DATA_FETCH_SUCCESS: {
      if (isEmpty(action.payload)) {
        return state;
      }
      const items = reject(state.items, {header_id: action.headerId});
      return {...state, items: [...items, ...action.payload]};
    }

    default:
      return state;
  }
}

function notificationsReducer(state = {isLoading: false, items: [], error: null}, action) {
  switch (action.type) {
    case types.NOTIFICATIONS_FETCH_REQUEST: {
      return {isLoading: true, items: state.items, error: state.error};
    }
    case types.NOTIFICATIONS_FETCH_SUCCESS: {
      if (isEmpty(action.payload)) {
        return state;
      }
      const newItems = applyTo(state.items)(
        applier(union)(action.payload),
        applier(uniqBy)('id')
      );
      return {isLoading: false, items: newItems, error: null};
    }
    case types.NOTIFICATIONS_FETCH_FAILURE: {
      return {...state, isLoading: false, error: action.error};
    }
    case studentActivityActionTypes.STUDENT_QUIZ_REMOVE_SUCCESS: {
      const studentQuizNotificationId = `StudentQuiz:${action.studentQuizId}`;
      const items = reject(state.items, ['id', studentQuizNotificationId]);
      return {isLoading: false, items, error: state.error};
    }
    default: {
      return state;
    }
  }
}

function readingTimesByHeaderClassroomReducer(state = {}, action) {
  switch (action.type) {
    case types.READING_TIME_FETCH_SUCCESS: {
      if (isEmpty(action.payload)) {
        return state;
      }
      const compositeKey = action.headerId + ',' + action.classroomId;
      const oldState = state[compositeKey] || {};
      return {
        ...state,
        [compositeKey]: {...oldState, ...action.payload}
      };
    }
    default: {
      return state;
    }
  }
}

function workDetailsVisibilityReducer(state = {}, action) {
  switch (action.type) {
    case types.OPEN_WORK_DETAILS: {
      const {key} = action.payload;
      const newState = merge({}, state, {[key]: {isOpen: true, isLoading: true}});
      return newState;
    }
    case types.CLOSE_WORK_DETAILS: {
      const {key} = action.payload;
      const newState = omit(state, key);
      return newState;
    }
    case types.WORK_DETAILS_FINISHED_LOADING: {
      const {key} = action.payload;
      const newState = merge({}, state, {[key]: {isLoading: false}});
      return newState;
    }
    default: {
      return state;
    }
  }
}

function deepLinkWasHandledReducer(state = false, action) {
  switch (action.type) {
    case types.DEEP_LINK_WAS_HANDLED: {
      return true;
    }
    default: {
      return state;
    }
  }
}

const binderWorkReducer = combineReducers({
  articleHeaderClassrooms: articleHeaderClassroomReducer,
  assignments: assignmentReducer,
  deepLinkWasHandled: deepLinkWasHandledReducer,
  headerReportingData: headerReportingDataReducer,
  notifications: notificationsReducer,
  readingTimesByHeaderClassroom: readingTimesByHeaderClassroomReducer,
  workDetailsVisibility: workDetailsVisibilityReducer,
});

export default binderWorkReducer;
