import React from 'react';

import {
  faCopy
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  FormControl as BaseFormControl,
  Input as BaseInput,
  InputLabel as BaseInputLabel,
  MenuItem as BaseMenuItem,
  Select as BaseSelect,
  Checkbox as BaseCheckbox,
  InputAdornment as BaseInputAdornment,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {constants} from '@newsela/angelou';

export const validationErrorColor = '#e20000';
const itemHoverColor = '#ecf3ff';
const inputTextColor = '#444444';
const inputBorderColor = '#d9d9d9';
const checkBoxBlue = '#0768fd';

const defaultSelectStyles = {
  select: {
    color: '#000',
    paddingLeft: '8px',
    border: `1px solid ${inputBorderColor}`,
    borderRadius: '4px',
    '&:focus': {
      borderColor: constants.colors.ui.core[500],
      borderRadius: '4px',
      backgroundColor: constants.colors.ui.white[700],
    },
    '&:hover': {
      borderColor: constants.colors.ui.core[500],
    },
  },
};

// I don't have a better solution as MUI doesn't provide error state option for Select elements.
const errorSelectStyles = {
  select: {
    ...defaultSelectStyles.select,
    border: `1px solid ${validationErrorColor}`,
  }
};

export const Select = withStyles(defaultSelectStyles)(BaseSelect);
export const ErrorSelect = withStyles(errorSelectStyles)(BaseSelect);

export const Input = withStyles({
  root: {
    paddingLeft: '8px',
    paddingRight: '8px',
    borderRadius: '4px',
    border: `1px solid ${inputBorderColor}`,
    backgroundColor: constants.colors.ui.white[700],
    '&:hover': {
      borderColor: constants.colors.ui.core[500],
    },
    '&$error': {
      '&:hover': {
        borderColor: validationErrorColor,
      },
    }
  },
  focused: {
    borderColor: constants.colors.ui.core[500],
  },
  error: {
    borderColor: validationErrorColor,
  }
})(BaseInput);

const inputStyles = {
  transform: 'scale(1)',
  fontSize: 14,
  fontWeight: 'normal',
  color: inputTextColor,
  marginTop: -8,
};

export const InputLabel = withStyles({
  root: inputStyles,
})(({style, ...rest}) => (
  <BaseInputLabel
    style={{
      ...style,
      color: inputTextColor,
    }}
    {...rest}
  />
));

export const MenuItem = withStyles({
  root: {
    paddingTop: 4,
    paddingBottom: 4,
    color: inputTextColor,
    '&:hover': {
      color: constants.colors.brand.neutral[700],
      backgroundColor: itemHoverColor,
    },
    '&:active': {
      backgroundColor: itemHoverColor,
    }
  }
})(BaseMenuItem);

const InputAdornment = withStyles({
  root: {
    // Have to set height, to make it work with the AngelouTooltip, otherwise it breaks visually.
    height: '34px',
    borderLeft: `1px solid ${inputBorderColor}`,
    paddingLeft: '10px',
    paddingRight: '10px',
    marginRight: '-10px',
    color: '#595959',
    cursor: 'pointer',
    '&:hover': {}
  }
})(BaseInputAdornment);

export const InputCopyButton = (props) => (
  <InputAdornment
    {...props}
  >
    <FontAwesomeIcon icon={faCopy}/>
  </InputAdornment>
);

export const FormControl = BaseFormControl;

export const Checkbox = withStyles({
  root: {
    color: inputBorderColor,
  },
  checked: {
    color: `${checkBoxBlue} !important`,
  },
  colorPrimary: {
    '&$checked': {
      color: checkBoxBlue,
    }
  }
})((props) => (
  <BaseCheckbox color="primary" {...props}/>
));
