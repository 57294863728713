import React from 'react';

import {
  PopoutAccountOptions
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/UserProfileMenu/UserProfilePopout/PopoutAccountOptions';
import {
  PopoutSubscriptions
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/UserProfileMenu/UserProfilePopout/PopoutSubscriptions';
import {
  PopoutUserInformation
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/UserProfileMenu/UserProfilePopout/PopoutUserInformation';
import {
  popoutContainerCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/UserProfileMenu/UserProfilePopout/UserProfilePopout.styles';

const UserProfilePopout = () => {
  return (
    <div className={popoutContainerCss} data-testid="user-profile-popout">
      <PopoutUserInformation />
      <PopoutSubscriptions />
      <PopoutAccountOptions />
    </div>
  );
};

UserProfilePopout.displayName = 'UserProfilePopout';
export {UserProfilePopout};
