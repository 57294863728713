import {get} from 'lodash-es';
import {createSelector} from 'reselect';

import {getUser} from 'static/three-oh/src/modules/selectors/userSelectors';


const getImpersonatedUser = (state) => get(state, 'binderImpersonatedUser', null);

// This is the date when the new jrrl (2RL) was deployed in production.
// It is needed to decide which formula to use when calculating the confidence interval.
const RRL_DEPLOYMENT_DATE = '2021-07-27T14:35:55Z';

// For reading level values generated before RRL deployment,
// keeps the old formula for obtaining the confidential interval.
const getReadingLevelConfidence = (readingLevel) => {
  const creationDate = new Date(readingLevel?.date_created);
  const deploymentDate = new Date(RRL_DEPLOYMENT_DATE);
  const deviation = readingLevel?.extra_data?.deviation ?? 0;
  return creationDate.getTime() < deploymentDate.getTime() ? 2 * deviation : 1.65 * deviation;
};

// Returns the effective binder user. This is typically the user who is currently logged in, but principals and district
// administrators may access other teacher's binders via user impersonation.
const getAuthenticatedOrImpersonatedUser = createSelector(
  [
    getUser,
    getImpersonatedUser
  ],
  (authenticatedUser, impersonatedUser) => {
    if (impersonatedUser) {
      return {...impersonatedUser, isImpersonated: true};
    } else if (authenticatedUser) {
      return {...authenticatedUser, isImpersonated: false};
    }
    return null;
  }
);

const initialBinderDataIsPresent = (state) => {
  const classroomsFinishedLoading = get(state, 'classrooms.dateLoaded');

  return Boolean(
    classroomsFinishedLoading
  );
};


const initialBinderDataHasError = (state) => {
  const impersonationError = get(getImpersonatedUser(state), 'isError', false);
  const classroomError = get(state, 'classrooms.error');
  return impersonationError || !!classroomError;
};

export {
  getAuthenticatedOrImpersonatedUser,
  getImpersonatedUser,
  getReadingLevelConfidence,
  initialBinderDataIsPresent,
  initialBinderDataHasError,
};
