import React from 'react';

import {Anchor} from '@newsela/angelou';
import {useSelector} from 'react-redux';

import {
  assignmentsAnchorCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/ResourceMenus/ResourceMenus.styles';

import {getUserProperties} from '../../../../../../modules/selectors/userSelectors';
import {ASSIGNMENTS_MENU_ITEM_STUDENT, ASSIGNMENTS_MENU_ITEM_TEACHER} from '../../shared/constants';

const AssignmentsLink = () => {
  const {
    isStudent,
  } = useSelector((state) => getUserProperties(state));

  const assignmentsHref = isStudent
    ? ASSIGNMENTS_MENU_ITEM_STUDENT.href
    : ASSIGNMENTS_MENU_ITEM_TEACHER.href;

  return (
    <li>
      <Anchor href={assignmentsHref} dataProps={{'data-qa-selector': 'global-nav-assignments'}} __cssFor={assignmentsAnchorCss}>
        Assignments
      </Anchor>
    </li>
  );
};

AssignmentsLink.displayName = 'AssignmentsLink';
export {AssignmentsLink};
