import * as types from 'modulesV2/constants';

export default function articleWords(state = {}, action) {
  switch (action.type) {
    case types.CANVAS_APP_LOAD_ARTICLE_WORDS_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
