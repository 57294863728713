import React, {useState} from 'react';

import {useSelector} from 'react-redux';

import {
  HomeMenuContentLibraryPopout
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/HomeMenu/HomeMenuContentLibraryPopout';
import {
  getHomeMenuPopoutContainerCss,
  supportItemVisibilityBreakpointCss,
  fullWidthButtonCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/HomeMenu/HomeMenuPopout/HomeMenuPopout.styles';
import {
  HomeMenuReportsPopout
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/HomeMenu/HomeMenuReportsPopout';
import {
  HomeMenuSupportPopout
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/HomeMenu/HomeMenuSupportPopout';
import {
  PopoutMenuSection
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/PopoutMenuSection';
import {
  PopoutSectionItem
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/PopoutSectionItem';
import {
  PopoutSectionItemWithPopout
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/PopoutSectionItemWithPopout';

import {getUserProperties} from '../../../../../../modules/selectors/userSelectors';
import {TrialButtons} from '../../PremiumTrial/TrialButtons';
import {ASSIGNMENTS_MENU_ITEM_STUDENT, ASSIGNMENTS_MENU_ITEM_TEACHER, HOME_MENU_ITEMS} from '../../shared/constants';
import {useGetReportsMenuSectionProps} from '../../shared/hooks/useGetReportsMenuSectionProps';
import {HomeMenuAdminPopout} from '../HomeMenuAdminPopout';

const HomeMenuPopout = () => {
  const {
    isStaff,
    isStudent,
    hasFeature,
    activeSSLicense,
    activeELALicense,
    activeScienceLicense,
    activeEssentialsLicense,
    getExperimentSegment,
  } = useSelector((state) => getUserProperties(state));

  const {
    reportsMenuSectionTitle,
  } = useGetReportsMenuSectionProps();

  const [isContentLibraryOpen, setIsContentLibraryOpen] = useState(false);
  const [isReportsOpen, setIsReportsOpen] = useState(false);
  const [isSupportOpen, setIsSupportOpen] = useState(false);
  const [isAdminOpen, setIsAdminOpen] = useState(false);

  const canViewAdminConsole = hasFeature('can_view_admin_console');

  const showLockIconsExperiment = getExperimentSegment('show_lock_icons');
  const showLicenseItemWithLock = showLockIconsExperiment === 'on';

  const hasOpenSubMenu = isContentLibraryOpen || isReportsOpen || isSupportOpen || isAdminOpen;
  const canSeeAdminMenu = isStaff || canViewAdminConsole;

  const assignmentsHref = isStudent
    ? ASSIGNMENTS_MENU_ITEM_STUDENT.href
    : ASSIGNMENTS_MENU_ITEM_TEACHER.href;
  const contentLibrarySubMenuText = isStudent ?
    'Library' :
    'Content library';

  return (
    <div className={getHomeMenuPopoutContainerCss(hasOpenSubMenu)}>
      <PopoutMenuSection width="12.5rem">
        <TrialButtons customCss={fullWidthButtonCss} />

        {activeEssentialsLicense && (
          <PopoutSectionItem
            key={HOME_MENU_ITEMS.ESSENTIALS.title}
            href={HOME_MENU_ITEMS.ESSENTIALS.href}
            title={HOME_MENU_ITEMS.ESSENTIALS.title}
            locked={false}
            SvgComponent={HOME_MENU_ITEMS.ESSENTIALS.SvgComponent}
            dataQASelector={HOME_MENU_ITEMS.ESSENTIALS.dataQASelector}
          />
        )}

        {(activeELALicense || (!isStudent && showLicenseItemWithLock)) && (
          <PopoutSectionItem
            key={HOME_MENU_ITEMS.ELA.title}
            href={HOME_MENU_ITEMS.ELA.href}
            title={HOME_MENU_ITEMS.ELA.title}
            locked={!activeELALicense}
            SvgComponent={HOME_MENU_ITEMS.ELA.SvgComponent}
            dataQASelector={HOME_MENU_ITEMS.ELA.dataQASelector}
          />
        )}

        {(activeSSLicense || (!isStudent && showLicenseItemWithLock)) && (
          <PopoutSectionItem
            key={HOME_MENU_ITEMS.SOCIAL_STUDIES.title}
            href={HOME_MENU_ITEMS.SOCIAL_STUDIES.href}
            title={HOME_MENU_ITEMS.SOCIAL_STUDIES.title}
            locked={!activeSSLicense}
            SvgComponent={HOME_MENU_ITEMS.SOCIAL_STUDIES.SvgComponent}
            dataQASelector={HOME_MENU_ITEMS.SOCIAL_STUDIES.dataQASelector}
          />
        )}

        {(activeScienceLicense || (!isStudent && showLicenseItemWithLock)) && (
          <PopoutSectionItem
            key={HOME_MENU_ITEMS.SCIENCE.title}
            href={HOME_MENU_ITEMS.SCIENCE.href}
            title={HOME_MENU_ITEMS.SCIENCE.title}
            locked={!activeScienceLicense}
            SvgComponent={HOME_MENU_ITEMS.SCIENCE.SvgComponent}
            dataQASelector={HOME_MENU_ITEMS.SCIENCE.dataQASelector}
          />
        )}

        <PopoutSectionItemWithPopout
          id="nav-menu-content-library-popout"
          buttonText={contentLibrarySubMenuText}
          isOpen={isContentLibraryOpen}
          setIsOpen={setIsContentLibraryOpen}
          position={{right: '13rem', top: '0'}}
          PopoutComponent={<HomeMenuContentLibraryPopout
            onClickBack={() => setIsContentLibraryOpen(false)}
                           />}
        />

        <PopoutSectionItemWithPopout
          id="nav-menu-classes-popout"
          buttonText={reportsMenuSectionTitle}
          isOpen={isReportsOpen}
          setIsOpen={setIsReportsOpen}
          position={{right: '13rem', top: '0'}}
          PopoutComponent={<HomeMenuReportsPopout onClickBack={() => setIsReportsOpen(false)} />}
        />

        <PopoutSectionItem
          key={HOME_MENU_ITEMS.ASSIGNMENTS.title}
          href={assignmentsHref}
          title={HOME_MENU_ITEMS.ASSIGNMENTS.title}
          dataQASelector={HOME_MENU_ITEMS.ASSIGNMENTS.dataQASelector}
        />

        <PopoutSectionItemWithPopout
          id="nav-menu-support-popout"
          buttonText="Support"
          isOpen={isSupportOpen}
          setIsOpen={setIsSupportOpen}
          position={{right: '13rem', top: '0'}}
          rootCustomCss={supportItemVisibilityBreakpointCss}
          PopoutComponent={<HomeMenuSupportPopout onClickBack={() => setIsSupportOpen(false)} />}
        />

        {canSeeAdminMenu && (
          <PopoutSectionItemWithPopout
            id="nav-menu-admin-popout"
            buttonText="Admin"
            isOpen={isAdminOpen}
            setIsOpen={setIsAdminOpen}
            position={{right: '13rem', top: '0'}}
            PopoutComponent={<HomeMenuAdminPopout onClickBack={() => setIsAdminOpen(false)} />}
          />
        )}
      </PopoutMenuSection>
    </div>
  );
};

HomeMenuPopout.displayName = 'HomeMenuPopout';
export {HomeMenuPopout};
