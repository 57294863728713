import lscache from 'lscache';

const contextKey = 'externalToolContext';

window.lscache = lscache;

export const setContext = ({provider, courseTitle, courseId, externalLtiAssignmentId, message, classroomSourceId, action}) => {
  try {
    return lscache.set(contextKey, {
      provider,
      courseTitle,
      courseId,
      externalLtiAssignmentId,
      classroomSourceId,
      message,
      action,
    });
  } catch (e) {
    // Do not fail if cannot set context.
    // Fallback to default behavior.
  }
};

export const clearContext = () => lscache.set(contextKey, null);
export const getContext = () => lscache.get(contextKey);
