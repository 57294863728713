import {each, filter, find, forEach, get, omit, reduce, toString} from 'lodash-es';
import moment from 'moment';
import {createSelector} from 'reselect';

import {startOfSchoolYear} from 'static/three-oh/src/modules/utils/dateHelpers';


const DEFAULT_EMPTY_LIST = [];

export const getClassrooms = (state) => get(state, 'classrooms.items', DEFAULT_EMPTY_LIST);

export const getClassroomsCreatedThisSchoolYear = createSelector(
  getClassrooms,
  (classrooms) => {
    return filter(classrooms, (classroom) => {
      return moment(classroom.date_created).isAfter(startOfSchoolYear());
    });
  }
);

export const getClassroomById = (state, courseId) => {
  return find(getClassrooms(state), (cls) => toString(courseId) === toString(cls.id));
};

export const isClassroomApiCallInProgress = (state) => get(state, 'classrooms.isLoading');

export const getStudentsByClassroom = createSelector(
  getClassrooms,
  (classrooms) => {
    return reduce(classrooms, (accumulator, classroom) => {
      accumulator[classroom.id] = classroom.students;
      return accumulator;
    }, {});
  }
);

export const getStudentsFromClassrooms = createSelector(
  getClassrooms,
  (classrooms) => {
    return reduce(classrooms, (accumulator, classroom) => {
      each(classroom.students, (student) => {
        accumulator[student.id] = student;
      });
      return accumulator;
    }, {});
  }
);

/*
return object with students from all classrooms with two entries per student like:
[student.user.id]:student;
[student.user.unique_id]: student
*/
export const getStudentsFromClassroomsGroupedByIdOrUniqueID = createSelector(
  getClassrooms,
  (classrooms) => {
    return reduce(classrooms, (accumulator, classroom) => {
      each(classroom.students, (student) => {
        if (student?.user?.unique_id && student?.user?.id) {
          accumulator[student.user.id] = student;
          accumulator[student.user.unique_id] = student;
        }
      });
      return accumulator;
    }, {});
  }
);

// Returns each classroom a student belongs to.
export const getClassroomsByStudentId = createSelector(
  getClassrooms,
  (classrooms) => {
    const classroomsByStudentId = {};
    forEach(classrooms, (classroom) => {
      const classroomWithoutStudents = omit(classroom, 'students');
      forEach(classroom.students, (student) => {
        classroomsByStudentId[student.id] = classroomsByStudentId[student.id] || [];
        classroomsByStudentId[student.id].push(classroomWithoutStudents);
      });
    });
    return classroomsByStudentId;
  }
);

export const getClassroomByProviderId = (state, uniqueId) => {
  return find(getClassrooms(state), (cls) => uniqueId === cls.provider_unique_id) || {};
};

export const getStandards = (state) => get(state, 'binderStandards.items', DEFAULT_EMPTY_LIST);

export const getStudents = (state) => get(state, 'binderStudents.studentList.items', []);
