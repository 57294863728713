import React from 'react';

import {cx} from 'emotion';
import PropTypes from 'prop-types';

import {toSnakeCase} from 'static/three-oh/utils/stringUtils';

import styles from './styles.scss';

const getCurrentYear = () => {
  return new Date().getFullYear();
};

const navItems = [
  {
    path: 'https://learn.newsela.com',
    label: 'Help & Educator Center',
  },
  {
    path: '/pages/terms-of-use/',
    label: 'Terms of Use',
  },
  {
    path: '/privacy-policy/',
    label: 'Privacy Policy',
  },
  {
    path: '/company/partners/',
    label: 'Partners',
  },
  {
    path: 'https://support.newsela.com/article/accessibility-on-newsela/',
    label: 'Accessibility',
  }
];
const propTypes = {
  additionalClassName: PropTypes.string
};
const Footer = ({additionalClassName}) => {
  return (
    <footer
      className={cx(styles.footer, additionalClassName)}
      data-qa-selector="footer"
      id="site-footer"
    >
      <ul className={styles.list}>
        {navItems.map((navItem) => {
          return (
            <li key={navItem.label} className={styles.navItem}>
              <a
                className={styles.atag}
                href={navItem.path}
                data-qa-selector={`${toSnakeCase(navItem.label)}_footer`}
              >
                {navItem.label}
              </a>
            </li>
          );
        })}
        <li className={styles.item}>
          <span data-qa-selector="newsela_copyright">
            ©{getCurrentYear()} Newsela
          </span>
        </li>
      </ul>
    </footer>
  );
};

Footer.propTypes = propTypes;
export default Footer;
