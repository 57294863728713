import React from 'react';

import PropTypes from 'prop-types';

import FeatureIndicator from '../FeatureIndicator/FeatureIndicator';


const propTypes = {
  additionalClassNames: PropTypes.arrayOf(PropTypes.string),
  hasPowerWords: PropTypes.bool.isRequired,
};

const defaultProps = {
  additionalClassNames: [],
  hasPowerWords: false,
};

const PowerWordsIndicator = (props) => {
  return (
    <FeatureIndicator
      additionalClassNames={props.additionalClassNames}
      enabled={props.hasPowerWords}
      iconName="bolt"
      label="Power Words"
      title="This article has Power Words."
      qaSelector="power_words_indicator"
    />
  );
};

PowerWordsIndicator.defaultProps = defaultProps;
PowerWordsIndicator.propTypes = propTypes;

export default PowerWordsIndicator;
