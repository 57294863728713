exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__color-quiz-correct___HiLCV{background-color:#52A821}.styles__color-quiz-incorrect___hX5vI{background-color:#D93F31}.styles__color-science___mOwD8{background-color:#55bca6}.styles__color-kids___pM8cP{background-color:#5908f7}.styles__color-money___7Xj8e{background-color:#fa0045}.styles__color-law___01UDz{background-color:#002764}.styles__color-health___oHdi0{background-color:#03a43c}.styles__color-arts___QH6ph{background-color:#E2C925}.styles__color-war-and-peace___wBSNW{background-color:#E76A0E}.styles__color-sports___CBnzP{background-color:#000000}.styles__color-opinion___vWuf9{background-color:#0027f5}.styles__color-text-set___iorRP{background-color:#106FF3}.styles__color-object-gray___ogcxF{background-color:#878787}.styles__color-light-gray___pGPWU{background-color:#F8F8F8}.styles__color-middle-gray___gn3s4{background-color:#BCBCBE}.styles__color-newsela-black___B-i3H{background-color:#333333}.styles__color-dark-bg___ec8Jx{background-color:#031E3E}.styles__color-newsela-blue___xpBs4{background-color:#0768fd}.styles__imageWrapper___GEeRz{position:relative;width:100%}.styles__imageWrapper___GEeRz:before{background-color:#dfdfdf;content:\"\";display:block;padding-top:56%}", ""]);

// exports
exports.locals = {
	"color-quiz-correct": "styles__color-quiz-correct___HiLCV",
	"color-quiz-incorrect": "styles__color-quiz-incorrect___hX5vI",
	"color-science": "styles__color-science___mOwD8",
	"color-kids": "styles__color-kids___pM8cP",
	"color-money": "styles__color-money___7Xj8e",
	"color-law": "styles__color-law___01UDz",
	"color-health": "styles__color-health___oHdi0",
	"color-arts": "styles__color-arts___QH6ph",
	"color-war-and-peace": "styles__color-war-and-peace___wBSNW",
	"color-sports": "styles__color-sports___CBnzP",
	"color-opinion": "styles__color-opinion___vWuf9",
	"color-text-set": "styles__color-text-set___iorRP",
	"color-object-gray": "styles__color-object-gray___ogcxF",
	"color-light-gray": "styles__color-light-gray___pGPWU",
	"color-middle-gray": "styles__color-middle-gray___gn3s4",
	"color-newsela-black": "styles__color-newsela-black___B-i3H",
	"color-dark-bg": "styles__color-dark-bg___ec8Jx",
	"color-newsela-blue": "styles__color-newsela-blue___xpBs4",
	"imageWrapper": "styles__imageWrapper___GEeRz"
};