import {combineReducers} from 'redux';

import {
  bookmarkedArticleHeaders,
  bookmarkedTextSets,
  bookmarkedStandards,
  userTextSets,
  schoolTextSets,
} from './yourContentReducers';

export default combineReducers({
  bookmarkedArticleHeaders,
  bookmarkedTextSets,
  bookmarkedStandards,
  userTextSets,
  schoolTextSets,
});
