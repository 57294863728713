import {constants, typographyStyles} from '@newsela/angelou';
import {css} from 'emotion';

export const relativeContainerCss = css({
  position: 'relative',
  borderRadius: '0.5rem',

  ':hover': {
    background: constants.colors.ui.neutral[500],
  },
});

export const rowContainerCss = css({
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem',
  gap: '0.25rem',
});

export const rowAnchorCss = {
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    textDecoration: 'none',
  }
};

export const titleFlexRowCss = css({
  display: 'flex',
  flexDirection: 'row',
  gap: '0.5rem',
  alignItems: 'flex-end',

  'div + svg': {
    marginBottom: '0.225rem',
  }
});

export const titleCss = css({
  ...typographyStyles.fontFamily.circular,
  fontSize: '1.25rem',
  lineHeight: '1rem',
  fontWeight: 700,
  color: constants.colors.ui.white[50]
});

export const externalLinkIconCss = {
  root: {
    width: '1rem',
    height: '1rem',

    path: {
      stroke: constants.colors.ui.white[50],
    },
  }
};

export const descriptionCss = css({
  ...typographyStyles.fontSize.textM,
  ...typographyStyles.fontFamily.circular,
  lineHeight: '1.25rem',
  color: constants.colors.ui.white[50],
});
