import * as types from '../constants/userActionTypes';

/*
The User Redux reducers.
 */

function user(state = {}, action) {
  switch (action.type) {
    case types.USER_LOAD_SUCCESS:
      return {
        ...action.user,
        state,
        is_loading: false,
      };
    case types.USER_LOAD_REQUEST:
      return {
        ...(window.user || {}),
        is_loading: true,
      };
    case types.USER_CREATE_REQUEST:
      return {
        ...state,
        is_loading: true,
      };
    case types.USER_LOG_OUT:
      return {is_loading: false};
    default:
      return state;
  }
}

export default user;
