import React from 'react';

import {Button, Icon, WarningFilledSVG, useModal} from '@newsela/angelou';
import PropTypes from 'prop-types';

import {CatModal} from 'components/CatModal/CatModal';

import styles from './styles';

export const DistrictModificationAlert = ({reassignButton, assignmentMember, assignment, pinToContent, updateAssignmentAndApiCallState, isUpdating}) => {
  const {content} = assignmentMember || {};
  const [catModalProps, setCatModalActive, triggerRef] = useModal(false);
  return (
    <div className={`${styles.districtModContainer} ${!pinToContent ? styles.containerMargin : ''}`}>
      <div className={styles.colorWarning} />
      <div className={styles.alertContainer}>
        <div className={styles.modTextContainer}>
          <Icon SvgComponent={WarningFilledSVG} __cssFor={{root: styles.iconCss}} />
          <div className={styles.modText}>
            {
              pinToContent
                ?
                'Modified by district & not visible to students'
                :
                `"${content.short_title || content.title}" is not visible to students`
            }
          </div>
        </div>
        {
          reassignButton ?
            <>
              <Button
                ref={triggerRef}
                /* AUTOGENERATED TODO: update angelou to new flavor.
                  see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
                  for migration guide. */
                legacy_flavor={Button.legacy_flavor.hollow}
                primary
                onClick={() => setCatModalActive(true)}
                disabled={isUpdating}
                dataProps={{
                  'data-qa-selector': 'cat-reassign-btn'
                }}
              >
                Choose to re-assign
              </Button>
              <CatModal
                contentId={assignmentMember.content.content_id}
                postCatAssignment={() => {
                  setCatModalActive(false);
                  updateAssignmentAndApiCallState(assignmentMember, assignment);
                }}
                setCatModalActive={setCatModalActive}
                catModalProps={catModalProps}
                conSetVisibilityMarkers={assignmentMember.content?.visibility_markers}
              />
            </>
            :
            null
        }
      </div>
      <hr className={styles.modDivider} />
    </div>
  );
};

DistrictModificationAlert.propTypes = {
  assignment: PropTypes.object,
  assignmentMember: PropTypes.object,
  pinToContent: PropTypes.boolean,
  reassignButton: PropTypes.boolean,
  updateAssignmentAndApiCallState: PropTypes.function,
  isUpdating: PropTypes.boolean
};
