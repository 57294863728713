// For our smallest breakpoint, we add a max-width media query string
// For all other breakpoints we do a min-width with the previous breakpoint + 1 and a max-width of that breakpoint for the query string
// After our largest breakpoint, we do a min-width query string of that breakpoint + 1.

// Returns an array of media query strings
export const generateMediaQueries = (breakpoints) => {
  const mediaQueries = breakpoints.map(
    (bp, index) => {
      let queryString;

      /*
       * Assign querystring to the appropriate media query. If smallest
       * breakpoint, breakpoint range is not needed
       */

      if (index === 0) {
        queryString = `@media (max-width: ${bp}px)`;
      } else {
        queryString = `@media (min-width: ${breakpoints[index - 1] + 1}px) and (max-width: ${bp}px)`;
      }

      /*
       * Return the query string
       */

      return queryString;
    }
  );

  /*
   * we need a breakpoint for everything above our largest breakpoint.
   */

  mediaQueries.push(`@media (min-width: ${breakpoints[breakpoints.length - 1] + 1}px)`);

  return mediaQueries;
};
