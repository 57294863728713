import {get} from 'lodash-es';

import * as types from 'static/three-oh/src/modulesV2/constants';

/*
  The 'yourContent' reducer infuses the Redux store with content that
  will displayed on the 'Your Content' page. That content includes:

  (1) bookmarked content (the actual instances of Textset.model, ArticleHeader.model, Standards.model and all of the other content-types
  that are bookmark-able). Notably distinction: the 'likedContent' reducer
  infuses instances of the ContentBookmark.model to the store. Both are used
  to execute bookmarking, unbookmarking and other functionality.
  (2) content created by the user
  (3) content at the user's school
*/

const initialState = {
  isLoading: false,
  items: [],
};

const getRequestState = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const getSuccessState = (state, action, fetchedFromSearchApi = false) => {
  const newState = {
    ...state,
    isLoading: false,
    items: get(action.data, 'results'),
  };
  if (fetchedFromSearchApi) {
    newState['searchFacets'] = get(action.data, 'aggregations.facets');
  }
  return newState;
};

const getErrorState = (state, action) => {
  return {
    ...state,
    isLoading: false,
    error: action.error,
  };
};

const bookmarkedArticleHeaders = (state = initialState, action) => {
  switch (action.type) {
    case types.BOOKMARKED_ARTICLE_HEADERS_FETCH_REQUEST:
      return getRequestState(state);

    case types.BOOKMARKED_ARTICLE_HEADERS_FETCH_SUCCESS:
      return getSuccessState(state, action, true);

    case types.BOOKMARKED_ARTICLE_HEADERS_FETCH_FAILURE:
      return getErrorState(state);

    default:
      return state;
  }
};

const bookmarkedTextSets = (state = initialState, action) => {
  switch (action.type) {
    case types.TEXT_SETS_FETCH_REQUEST:
      return getRequestState(state);

    case types.BOOKMARKED_TEXT_SETS_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: [...action.textSets]
      };

    case types.BOOKMARKED_TEXT_SETS_FETCH_FAILURE:
      return getErrorState(state);

    default:
      return state;
  }
};

const bookmarkedStandards = (state = initialState, action) => {
  switch (action.type) {
    case types.BOOKMARKED_STANDARDS_FETCH_REQUEST:
      return getRequestState(state);

    case types.BOOKMARKED_STANDARDS_FETCH_SUCCESS:
      return getSuccessState(state, action, true);

    case types.BOOKMARKED_STANDARDS_FETCH_FAILURE:
      return getErrorState(state);

    default:
      return state;
  }
};

const userTextSets = (state = initialState, action) => {
  switch (action.type) {
    case types.TEXT_SETS_FETCH_REQUEST:
      return getRequestState(state);

    case types.USER_TEXT_SETS_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: [...action.textSets]
      };

    case types.USER_TEXT_SETS_FETCH_FAILURE:
      return getErrorState(state, action);

    default:
      return state;
  }
};

const schoolTextSets = (state = initialState, action) => {
  switch (action.type) {
    case types.TEXT_SETS_FETCH_REQUEST:
      return getRequestState(state);

    case types.SCHOOL_TEXT_SETS_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: [...action.textSets]
      };

    case types.SCHOOL_TEXT_SETS_FETCH_FAILURE:
      return getErrorState(state, action);

    default:
      return state;
  }
};

export {
  bookmarkedArticleHeaders,
  bookmarkedTextSets,
  bookmarkedStandards,
  userTextSets,
  schoolTextSets,
};
