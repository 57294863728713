import React from 'react';

import PropTypes from 'prop-types';

import withWindowSize from 'components/with/WithWindowSize';
import {AngelouTooltipWithTrackEvent} from 'static/three-oh/src/components/AngelouTooltip';
import Chip from 'static/three-oh/src/components/Chip';


import styles from './styles';

const propTypes = {
  setStandardChipRef: PropTypes.objec,
  size: PropTypes.string,
  tooltipPosition: PropTypes.string,
  tooltipVerticalOffset: PropTypes.number,
  standard: PropTypes.shape({
    content_id: PropTypes.string,
    number_enhanced: PropTypes.string,
    statement_combined_description: PropTypes.string,
  }),
};

/**
 * Display information pertaining to a single alignment standard.
 */
const StandardChip = (props) => {
  const {size, tooltipPosition, tooltipVerticalOffset, setStandardChipRef} = props;
  const {
    content_id,
    number_enhanced,
    statement_combined_description,
  } = props.standard;

  if (!number_enhanced) { return null; }
  // Labels on all screen sizes currently have a 50 max character limit.
  let label = number_enhanced.length <= 50 ? number_enhanced : `${number_enhanced.slice(0, 50)}...`;
  if (size === 'xsmall') {
    // This is further limited to 20 characters for "xsmall" screens
    label = number_enhanced.length <= 20 ? number_enhanced : `${number_enhanced.slice(0, 20)}...`;
  }

  const choiceChip = (
    <Chip
      ref={setStandardChipRef}
      type="CHOICE"
      label={label}
      href={{
        pathname: `/standards/${content_id}`,
        state: {
          referredFrom: 'article',
        }
      }}
      renderAnchorTag={false}
      classes={{root: styles.standard}}
    />
  );

  const __renderTooltipContent = () => {
    return (
      <div className={styles.tooltip}>
        <div className={styles.tooltipNumber}>{number_enhanced}</div>
        <div className={styles.tooltipDescription}>{statement_combined_description}</div>
      </div>
    );
  };

  return (
    <AngelouTooltipWithTrackEvent
      position={tooltipPosition}
      tooltipAlign="center"
      tooltipVerticalOffset={tooltipVerticalOffset}
      dismissOnMouseout
      __renderTooltipContent={__renderTooltipContent}
      isDark
      hasRoundedCorners
      legacyEventName="Standard-Preview"
      objectType="Content"
      actionName="Read"
      streamProperties={{
        content_id,
        description: 'Preview standard information.',
        content_type: 'Standard',
      }}
      eventProperties={{
        componentLabel: number_enhanced,
        contentId: content_id,
        componentType: 'StandardChip'
      }}
    >
      <span>{choiceChip}</span>
    </AngelouTooltipWithTrackEvent>
  );
};

StandardChip.propTypes = propTypes;
StandardChip.defaultProps = {
  size: 'small',
  tooltipVerticalOffset: 27,
  tooltipPosition: 'top'
};

const StandardChipWithWindowSize = withWindowSize(StandardChip);
export default StandardChip;
export {StandardChipWithWindowSize};
