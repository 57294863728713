import uiColors from '../../components/Colors/colors.js';

const sideDistanceCssValue = '0.7rem;';

// Needs to be 700 colors from Angelou for accessibility.
const statusColors700 = {
  primary: '#0052C4',
  secondary: '#545454',
  info: '#007AC2',
  success: '#3A870F',
  danger: '#C32113',
  warning: '#BFA302',
};

const messageLevel = (level) => {
  const universal = {
    color: uiColors.white[1],
    padding: `${sideDistanceCssValue}`,
  };

  return {
    ...universal,
    backgroundColor: statusColors700[level],
  };
};

const closeCssObject = {
  marginLeft: `${sideDistanceCssValue};`,
  display: 'flex;',
  alignItems: 'center;',
};

export {
  messageLevel,
  closeCssObject,
  sideDistanceCssValue,
};
