import {studentActivityActionTypes as types} from '../constants';

export function studentQuizRemoveRequest(studentQuiz, binderFilters) {
  return {type: types.STUDENT_QUIZ_REMOVE_REQUEST, studentQuiz, binderFilters};
}

export function studentQuizRemoveRequestSuccess(studentId, studentQuizId) {
  return {type: types.STUDENT_QUIZ_REMOVE_SUCCESS, studentId, studentQuizId};
}

export function studentWriteRevisionRequest(payload) {
  return {type: types.STUDENT_WRITE_REVISION_REQUEST, payload};
}

export function studentWriteRevisionRequestSuccess(studentId, writeResponseId) {
  return {type: types.STUDENT_WRITE_REVISION_SUCCESS, studentId, writeResponseId};
}
