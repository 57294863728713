import React from 'react';

import PropTypes from 'prop-types';

import styles from './styles.scss';

const propTypes = {
  /**
   * The label to display on the expand button.
   */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  /**
   * The id to attach, useful for selecting, e.g. with tours.
   */
  id: PropTypes.string,
  onClick: PropTypes.func,
  /**
   * Hide when the screen is smaller than given size.
   */
  hideAtSize: PropTypes.string,
  /**
   * Ref to forward to its button
   */
  buttonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.any})
  ]),
};

/**
 * The Toolbar has primary and secondary zones for displaying
 * child components. The zones behave differently according
 * to screen size. It expects to have a single child of each kind.
 */
const CollapsedSideBar = ({id, onClick, label, buttonRef}) => {
  return (
    <aside
      id={id}
      className={styles.collapsedSideBar}
      data-qa-selector="activities"
      elementtiming="activities"
    >
      <button ref={buttonRef} onClick={onClick} aria-expanded="false">
        <div className={styles.buttonContents}>
          <i
            className="fa fa-chevron-left"
            aria-hidden="true"
          />
          <span className={styles.label}>
            {label}
          </span>
        </div>
      </button>
    </aside>
  );
};

CollapsedSideBar.propTypes = propTypes;

export default CollapsedSideBar;
