exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__dropDownOption___qPLJH{display:flex;align-items:center}.styles__dropDownLabel___TpnCe{margin-left:1rem}", ""]);

// exports
exports.locals = {
	"dropDownOption": "styles__dropDownOption___qPLJH",
	"dropDownLabel": "styles__dropDownLabel___TpnCe"
};