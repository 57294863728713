import {css} from 'emotion';

// TODO replace this with angelou green 300 when we start importing it
const green = '#64CD28';

// TODO replace this with angelou UI grey dark when we start importing it
const grey = '#333333';

// TODO replace this with angelou UI grey light 300 when we start importing it
const sliderGrey = '#BCBCBE';

const fontSize = 0.75; // rem,
const sliderSize = fontSize * 0.75; // rem
const containerHeight = fontSize * 1.4; // rem
const borderWidth = '0.125rem';

const dataSectionsSelectors = {
  slider: '[data-toggle-section="slider"]',
  sliderContainer: '[data-toggle-section="sliderContainer"]',
  text: '[data-toggle-section="text"]',
  sliderPlaceholder: '[data-toggle-section="sliderPlaceholder"]',
  activeText: '[data-toggle-section="activeText"]',
  inactiveText: '[data-toggle-section="inactiveText"]',
};

const wrapper = css`
  width:100%;
  border: ${borderWidth} solid ${grey};
  height: ${containerHeight}rem;
  border-radius: ${fontSize}rem;
  display: flex;
  align-items: center;
  position:relative;
  box-sizing: border-box;
`;

const sliderContainer = css`
  display: flex;
  width:100%;
  height: 100%;
  position: absolute;
  align-items: center;
`;

const slider = css`
  height:${sliderSize}rem;
  border-radius: ${sliderSize}rem;
  margin: ${borderWidth};
  width: ${sliderSize}rem;
  z-index: 1;
  background-color: ${sliderGrey};
`;

const sliderPlaceholder = css`
  display: inline-block;
  width: ${sliderSize}rem;
`;

const text = (checked, reverse) => css`
  font-size: ${fontSize}rem;
  font-weight: bold;
  display: flex;
  justify-content: ${checked ? 'flex-start' : 'flex-end'};
  flex-flow: ${reverse && checked ? 'row-reverse' : 'row'};
  width: 100%;
  padding: 0 5px;
  color: ${checked ? 'white' : grey};
  align-items: center;
`;

const animationSeconds = 0.15;

const slideKeyFrames = `
  0% {
    width: ${sliderSize}rem;
  }
  50% {
    width: 100%;
  }
  100% {
    width: ${sliderSize}rem;
  }
`;

const checkbox = (state) => css`

  z-index: 2;
  position: absolute;
  width: 100%;
  height:100%;
  margin: 0;
  box-sizing: border-box;
  opacity: 0;

.user-is-tabbing &:focus + div ${dataSectionsSelectors.slider} {
    outline: rgb(59, 153, 252) auto 5px;
  }

  &:not(:checked) {
    + div {
      background-color: white;
      transition: background-color ${animationSeconds}s ease;
    }
    + div ${dataSectionsSelectors.text} {
      color: ${grey};
      transition: color ${animationSeconds}s ease;
    }
    &:hover, .user-is-tabbing &:focus {
    + div ${dataSectionsSelectors.text} {
        color: white;
      }
      + div {
        background-color: ${grey};
      }
    }
  }

  /* checked  */
  &:checked + div {
    background-color: ${grey};
  }


  &:checked + div ${dataSectionsSelectors.slider} {
    animation: slide ${animationSeconds}s, colorOn ${animationSeconds}s;
    background-color: ${green};
  }

  &:checked + div ${dataSectionsSelectors.sliderContainer} {
    animation: justifyContentEnd ${animationSeconds}s;
    justify-content: flex-end;
  }

  &:checked + div ${dataSectionsSelectors.text} ${dataSectionsSelectors.activeText} {
    animation: showOpacity ${animationSeconds}s;
    opacity: 1;
  }

&:checked + div [data-toggle-section="text"] ${dataSectionsSelectors.inactiveText}{
    animation: hideOpacity ${animationSeconds}s;
    opacity: 0;
  }


  ${
    state.initialRender &&
    `&:not(:checked) + div ${dataSectionsSelectors.slider} {
      animation: slideIn ${animationSeconds}s;
      background-color: ${sliderGrey};
    }

    &:not(:checked) + div ${dataSectionsSelectors.sliderContainer} {
      animation: justifyContentStart ${animationSeconds}s;
      justify-content: flex-start;
    }

    &:not(:checked) + div ${dataSectionsSelectors.text} ${dataSectionsSelectors.inactiveText}{
      animation: showOpacity ${animationSeconds}s;
      opacity: 1;
    }

  &:not(:checked) + div ${dataSectionsSelectors.text} ${dataSectionsSelectors.activeText}{
    animation: hideOpacity ${animationSeconds}s;
    opacity: 0;
  }
    `
  }


  /* animation */
  @keyframes slide {
    ${slideKeyFrames}
  }

  /**
  * for some reason we need to rename it
  */
  @keyframes slideIn {
    ${slideKeyFrames}
  }
  @keyframes colorOn {
    0% {
      background-color: ${sliderGrey};
    }
    99%{
      background-color: ${sliderGrey};;
    }
    100% {
      background-color: ${green};
    }
  }
  @keyframes colorOff {
    0% {
      background-color: ${green};
    }
    100% {
      background-color: ${sliderGrey};
    }
  }

  @keyframes justifyContentEnd {
    0% {
      justify-content: flex-start;
    }
    100% {
      justify-content: flex-end;
    }
  }

  @keyframes justifyContentStart {
    0% {
      justify-content: flex-end;
    }
    100% {
      justify-content: flex-start;
    }
  }

  @keyframes showOpacity {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    60%{
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes hideOpacity {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    60%{
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default {
  checkbox,
  slider,
  sliderContainer,
  sliderPlaceholder,
  text,
  wrapper,
};
