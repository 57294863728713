import {constants, typographyStyles} from '@newsela/angelou';
import {css} from 'emotion';
const uiColors = constants.colors.ui;

const rootModalCss = css`
  z-index: 10
`;

const modalCss = css({
  maxWidth: '800px !important',
  maxHeight: '420px !important',
});

const modalFooterCss = css({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '12px'
});

const rootCheckboxCss = css({
  background: uiColors.neutral[25],
  borderRadius: '7px',
  marginTop: '10px',
});

const checkboxCss = css({

  height: '1.5em', paddingRight: '0px !important'
});

const checkboxLabelTextCss = css({
  ...typographyStyles.fontFamily.circular,
  background: uiColors.neutral[25],
  color: uiColors.neutral[700],
  fontSize: '14px',
  fontWeight: '400',
  borderRadius: '7px',
  marginLeft: '10px'
});

const modalBodyText = css({
  ...typographyStyles.fontFamily.circular,
  fontWeight: '400',
  fontSize: '16px',
  color: uiColors.neutral[700],
});

export default {
  checkboxCss,
  checkboxLabelTextCss,
  modalBodyText,
  modalCss,
  modalFooterCss,
  rootCheckboxCss,
  rootModalCss,
};
