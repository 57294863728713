import React from 'react';

import {css} from 'emotion';


const NoRouteInStudyError = () => {
  return (
    <div className={css`padding: 16px;`}>
      <p>
        Hey there, Newsela developer! It appears like you've tried to use the
        study framework with a Segment component containing a react-router Route
        component as one of its children. That functionality currently isn't
        supported by the study framework, as it breaks any Route components
        registered after it within react-router.
      </p>
      <p>
        If you need to change the entire page layout for a particular study,
        consider one of the following:
        <ul>
          <li>
            Manually retrieve the segment from the Redux store, and change the component
            name and location programmatically.
          </li>
          <li>
            Create a small component simply to handle which page-level component/container
            is displayed, and register that as the component for your Route.
          </li>
          <li>
            Do some diagnosis as to why Routes don't work with Study/Segment,
            submit a PR, and remove this handy warning!
          </li>
        </ul>
      </p>
    </div>
  );
};

export default NoRouteInStudyError;
