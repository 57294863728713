import React from 'react';

import PropTypes from 'prop-types';
import {LazyLoadComponent} from 'react-lazy-load-image-component';

import ImagePreLoader from 'static/three-oh/src/components/ImagePreLoader/ImagePreLoader';

import styles from './styles.scss';


export default class LazyImage extends React.PureComponent {
  static propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
    compression: PropTypes.number,
    src: PropTypes.string,
    threshold: PropTypes.number,
    visibleByDefault: PropTypes.bool,
  };

  static defaultProps = {
    alt: '',
    compression: 85,
    src: '',
    threshold: 250,
    visibleByDefault: false,
  };

  render() {
    const {src, alt, className, compression, threshold, visibleByDefault} = this.props;
    return (
      <div className={styles.imageWrapper}>
        <LazyLoadComponent
          style={{display: 'block'}}
          threshold={threshold}
          visibleByDefault={visibleByDefault}
        >
          <ImagePreLoader
            alt={alt}
            className={className}
            compression={compression}
            div
            src={src}
          />
        </LazyLoadComponent>
      </div>
    );
  }
}
