import APIFetcher from '../APIFetcher';
import ApiFetcher2 from '../ApiFetcher2';
import ApiFetcher3 from '../ApiFetcher3';

const v3 = {
  archiveClassroom(classroomId) {
    return ApiFetcher3.delete(`classroom/${classroomId}`);
  }
};

class ClassroomApi {
  static v3 = v3

  static getClassrooms(userId, params = {}) {
    const defaultParams = {
      hide_archived: true,
    };
    params = {...defaultParams, ...params};
    const query = APIFetcher.stringifyParams(params);
    return APIFetcher.get(`user/${userId}/classroom/?${query}`);
  }

  static getClassroomById(classroomId, params = {}) {
    const defaultParams = {
      hide_archived: true,
    };
    params = {...defaultParams, ...params};
    const query = APIFetcher.stringifyParams(params);
    return APIFetcher.get(`classroom/${classroomId}/?${query}`);
  }

  static createClassroom(payload) {
    return APIFetcher.post('classroom/', {payload});
  }

  static joinClassroom(studentID, classroomId) {
    const payload = {
      student_id: studentID,
      classroom_id: classroomId,
    };
    return APIFetcher.post('student-classroom/', {payload});
  }

  static getClassroomFromCode(classroomCode) {
    const params = {
      code: classroomCode,
      hide_archived: true
    };
    return ApiFetcher2.get('classroom', {params});
  }

  static archiveClassroom(classroomId, params = {}) {
    const defaultParams = {
      archive: true,
      hide_archived: true,
    };
    params = {...defaultParams, ...params};
    return APIFetcher.remove(`classroom/${classroomId}`, {params});
  }
}



export default ClassroomApi;
