import React from 'react';

import {Button, ChevronLeftSVG, Icon} from '@newsela/angelou';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {
  USER_FEATURE_FOR_CRUDING_CONTENT_PROMOTION_CONFIGURATION
} from 'static/three-oh/src/constants/contentPromotion';

import {getUserProperties} from '../../../../../../modules/selectors/userSelectors';
import {
  ADMIN_TOOLS_MENU_ADMIN_CONSOLE_ITEM,
  ADMIN_TOOLS_MENU_CONTENT_PROMOTION_ITEM,
  ADMIN_TOOLS_MENU_CONTENT_RESTRICTION_ITEM, ADMIN_TOOLS_MENU_IMPLEMENTATION_GUIDE_ITEM, STAFF_INTERNAL_TOOLS_ITEMS
} from '../../shared/constants';
import {PopoutMenuSection} from '../../shared/PopoutMenuSection/PopoutMenuSection';
import {PopoutSectionItem} from '../../shared/PopoutSectionItem/PopoutSectionItem';
import {backToMenuAnchorCss, backToMenuIconCss, homeMenuSubItemPopoutContainerCss} from '../HomeMenu.styles';


const propTypes = {
  onClickBack: PropTypes.func.isRequired,
};

const HomeMenuAdminPopout = ({onClickBack}) => {
  const {
    isStaff,
    hasFeature,
    isDistrictAdmin,
  } = useSelector((state) => getUserProperties(state));

  const canViewAdminConsole = hasFeature('can_view_admin_console');
  const canAccessImplementationGuide = hasFeature('can_access_implementation_guide');

  const hasUserFeatureToCrudContentPromotionConfigurations = hasFeature(USER_FEATURE_FOR_CRUDING_CONTENT_PROMOTION_CONFIGURATION);

  const canSeeImplementationGuide =
    canAccessImplementationGuide &&
    (isDistrictAdmin || isStaff);

  const canSeeContentPromotion = hasUserFeatureToCrudContentPromotionConfigurations;

  return (
    <div className={homeMenuSubItemPopoutContainerCss} data-testid="home-admin-popout">
      <Button onClick={onClickBack} __cssFor={backToMenuAnchorCss} flavor={Button.flavors.incognito}>
        <Icon SvgComponent={ChevronLeftSVG} __cssFor={backToMenuIconCss} />
        Back to menu
      </Button>

      <PopoutMenuSection width="275px" title="Admin tools">
        {canViewAdminConsole && (
          <PopoutSectionItem
            key={ADMIN_TOOLS_MENU_ADMIN_CONSOLE_ITEM.dataQASelector}
            href={ADMIN_TOOLS_MENU_ADMIN_CONSOLE_ITEM.href}
            title={ADMIN_TOOLS_MENU_ADMIN_CONSOLE_ITEM.title}
            SvgComponent={ADMIN_TOOLS_MENU_ADMIN_CONSOLE_ITEM.SvgComponent}
            dataQASelector={ADMIN_TOOLS_MENU_ADMIN_CONSOLE_ITEM.dataQASelector}
          />
        )}

        {canSeeImplementationGuide && (
          <PopoutSectionItem
            key={ADMIN_TOOLS_MENU_IMPLEMENTATION_GUIDE_ITEM.dataQASelector}
            href={ADMIN_TOOLS_MENU_IMPLEMENTATION_GUIDE_ITEM.href}
            title={ADMIN_TOOLS_MENU_IMPLEMENTATION_GUIDE_ITEM.title}
            SvgComponent={ADMIN_TOOLS_MENU_IMPLEMENTATION_GUIDE_ITEM.SvgComponent}
            dataQASelector={ADMIN_TOOLS_MENU_IMPLEMENTATION_GUIDE_ITEM.dataQASelector}
          />
        )}

        {canSeeContentPromotion && (
          <PopoutSectionItem
            badge="New"
            key={ADMIN_TOOLS_MENU_CONTENT_PROMOTION_ITEM.dataQASelector}
            href={ADMIN_TOOLS_MENU_CONTENT_PROMOTION_ITEM.href}
            title={ADMIN_TOOLS_MENU_CONTENT_PROMOTION_ITEM.title}
            SvgComponent={ADMIN_TOOLS_MENU_CONTENT_PROMOTION_ITEM.SvgComponent}
            dataQASelector={ADMIN_TOOLS_MENU_CONTENT_PROMOTION_ITEM.dataQASelector}
          />
        )}

        <PopoutSectionItem
          key={ADMIN_TOOLS_MENU_CONTENT_RESTRICTION_ITEM.dataQASelector}
          href={ADMIN_TOOLS_MENU_CONTENT_RESTRICTION_ITEM.href}
          title={ADMIN_TOOLS_MENU_CONTENT_RESTRICTION_ITEM.title}
          SvgComponent={ADMIN_TOOLS_MENU_CONTENT_RESTRICTION_ITEM.SvgComponent}
          dataQASelector={ADMIN_TOOLS_MENU_CONTENT_RESTRICTION_ITEM.dataQASelector}
        />
      </PopoutMenuSection>

      {isStaff && (
        <PopoutMenuSection title="Internal staff tools">
          {STAFF_INTERNAL_TOOLS_ITEMS.map(({title, href, SvgComponent, dataQASelector}) => (
            <PopoutSectionItem
              key={dataQASelector}
              href={href}
              title={title}
              SvgComponent={SvgComponent}
              dataQASelector={dataQASelector}
            />
          ))}
        </PopoutMenuSection>
      )}
    </div>
  );
};

HomeMenuAdminPopout.propTypes = propTypes;
HomeMenuAdminPopout.displayName = 'HomeMenuAdminPopout';
export {HomeMenuAdminPopout};
