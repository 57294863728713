const namespace = 'sign-up/';

export const INITIALIZE_USER_CREATION = `${namespace}INITIALIZE_USER_CREATION`;
export const INITIALIZE_SEND_VERIFICATION_EMAIL = `${namespace}INITIALIZE_SEND_VERIFICATION_EMAIL`;
export const INITIALIZE_ROLE_CREATION = `${namespace}INITIALIZE_ROLE_CREATION`;
export const INITIALIZE_UPDATE_USER = `${namespace}INITIALIZE_UPDATE_USER`;

export const SIGN_UP_CREATE_USER_REQUEST = `${namespace}SIGN_UP_CREATE_USER_REQUEST`;
export const SIGN_UP_CREATE_EMAIL_REQUEST = `${namespace}SIGN_UP_CREATE_EMAIL_REQUEST`;
export const SIGN_UP_CREATE_ROLE_CREATION_REQUEST = `${namespace}SIGN_UP_CREATE_ROLE_CREATION_REQUEST`;
export const SIGN_UP_CREATE_UPDATE_USER_REQUEST = `${namespace}SIGN_UP_CREATE_UPDATE_USER_REQUEST`;

export const SIGN_UP_LOAD_SUCCESS = `${namespace}SIGN_UP_LOAD_SUCCESS`;
export const SIGN_UP_LOAD_ROLE_CREATION_SUCCESS = `${namespace}SIGN_UP_LOAD_ROLE_CREATION_SUCCESS`;
export const SIGN_UP_LOAD_FAILURE = `${namespace}SIGN_UP_LOAD_FAILURE`;
