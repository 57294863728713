import React from 'react';

import {
  AnnotateSVG,
  ArticleHeaderSVG,
  BookmarkHollowSVG,
  BooksSVG,
  ChecklistSVG,
  DistrictHollowSVG,
  SubjectNewsSVG,
  FilterFilledSVG,
  FolderHollowSVG,
  HeartHollowSVG,
  ScrollOldSVG,
  SchoolHollowSVG,
  Icon,
  NewselaSVG,
  InsightsSVG,
  PaperHollowSVG,
  PowerWordHollowSVG,
  PreviewCardSVG,
  SelectSVG,
  ScienceSVG,
  SpanishHollowSVG,
  SubjectElaSVG,
  SubjectSocialStudiesSVG,
  DetailsSVG,
  VideoControlsSVG,
  FloppyHollowSVG,
  WireSVG,
  VideoSVG,
  UserHollowSVG,
  SubjectEssentialsSVG,
  ClipboardCheckSVG,
  StopSVG
} from '@newsela/angelou';

import {svgDarkFillCss} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/NavigationBar.styles';

import {TEACHER_BINDER_CONSTANTS} from '../../../GlobalLayoutV1/GlobalNav/DrawerContents/constants.js';

const PROMOTED_AT_YOUR_DISTRICT_PATHNAME = '/your-content/promoted-at-your-district';

export const NAVBAR_BREAKPOINTS = {
  xxl: 9999,
  lg: 1560,
  md: 1140,
  sm: 717,
  xs: 320,
};

export const COLLECTIONS_SLUGS = ['social-emotional-learning', 'lgbtqia-ss'];

export const ProductLicenseKeysEnum = {
  ELA: 'activeELALicense',
  SS: 'activeSSLicense',
  SCI: 'activeScienceLicense',
};

export const ESSENTIALS_MENU_ITEM = {
  title: 'Essentials',
  license: 'activeEssentialsLicense',
  href: '/content/browse/news-browse-page/ckd4tly7100003dmt4gfi3fw6/',
  SvgComponent: SubjectEssentialsSVG,
  dataQASelector: 'global-nav-essentials-product'
};

export const ELA_MENU_ITEM = {
  title: 'ELA',
  license: ProductLicenseKeysEnum.ELA,
  href: '/content/browse/ela-browse-page/ckd51x1pr00023hoalzrknglr/',
  SvgComponent: SubjectElaSVG,
  dataQASelector: 'global-nav-ela-product'
};

export const SS_MENU_ITEM = {
  title: 'Social Studies',
  license: ProductLicenseKeysEnum.SS,
  href: '/content/browse/browse-social-studies/ckd0kn7y000013hqqp3k7t2yx/',
  SvgComponent: SubjectSocialStudiesSVG,
  dataQASelector: 'global-nav-ss-product'
};

export const SCIENCE_MENU_ITEM = {
  title: 'Science',
  license: ProductLicenseKeysEnum.SCI,
  href: '/content/browse/browse-science/ckd0jvn3z00023jn8mkcw1jjd/',
  SvgComponent: ScienceSVG,
  dataQASelector: 'global-nav-science-product'
};

export const ASSIGNMENTS_MENU_ITEM_TEACHER = {
  title: 'Assignments',
  href: '/binder/assignments',
  dataQASelector: 'global-nav-assignments'
};

export const ASSIGNMENTS_MENU_ITEM_STUDENT = {
  title: 'Assignments',
  href: '/assignments',
  dataQASelector: 'global-nav-assignments'
};

export const ADMIN_CONSOLE_MENU_ITEM = {
  title: 'Admin console',
  href: '/admin/faculty',
  SvgComponent: FilterFilledSVG,
  dataQASelector: 'global-nav-admin-console'
};

export const SUBJECT_AREA_ITEMS = [
  ELA_MENU_ITEM,
  SS_MENU_ITEM,
  SCIENCE_MENU_ITEM,
];

export const HOME_MENU_ITEMS = {
  ESSENTIALS: ESSENTIALS_MENU_ITEM,
  ELA: ELA_MENU_ITEM,
  SOCIAL_STUDIES: SS_MENU_ITEM,
  SCIENCE: SCIENCE_MENU_ITEM,
  ASSIGNMENTS: ASSIGNMENTS_MENU_ITEM_TEACHER,
};

export const MY_CONTENT_ITEMS = [
  {
    title: 'Saved content',
    href: '/your-content/?saved-by-you=&content_type=allcontenttypes',
    SvgComponent: BookmarkHollowSVG,
    dataQASelector: 'global-nav-content-library-saved-content',
    showForStudents: true,
  },
  {
    title: 'Created by me',
    href: '/your-content/?created-by-you',
    SvgComponent: AnnotateSVG,
    dataQASelector: 'global-nav-content-library-created-by-me',
    showForStudents: false,
  },
  {
    title: 'Promoted at your district',
    href: PROMOTED_AT_YOUR_DISTRICT_PATHNAME,
    SvgComponent: DistrictHollowSVG,
    dataQASelector: 'global-nav-content-library-promoted-at-your-district',
    showForStudents: false,
    badge: 'New',
  },
  {
    title: 'From my school',
    href: '/your-content/?at-your-school',
    SvgComponent: SchoolHollowSVG,
    dataQASelector: 'global-nav-content-library-from-my-school',
    showForStudents: false,
  },
];

export const EXPLORE_CONTENT_ITEMS = [
  {
    title: 'News',
    href: '/content/browse/news-browse-page/ckd4tly7100003dmt4gfi3fw6/',
    SvgComponent: SubjectNewsSVG,
    dataQASelector: 'global-nav-content-library-news',
    showForStudents: true,
  },
  {
    title: 'En Español',
    href: '/content/browse/spanish-browse-page/ckd6jq6g600063fs7qgekq2p5/',
    SvgComponent: SpanishHollowSVG,
    dataQASelector: 'global-nav-content-library-created-in-spanish',
    showForStudents: true,
  },
  {
    title: 'Articles and texts',
    href: '/search/?content_types=articleheader',
    SvgComponent: ArticleHeaderSVG,
    dataQASelector: 'global-nav-content-library-articles-and-texts',
    showForStudents: true,
  },
  {
    title: 'Videos',
    href: '/search/?content_types=interactivevideolink',
    SvgComponent: VideoSVG,
    dataQASelector: 'global-nav-content-library-videos',
    showForStudents: true,
  },
  {
    title: 'Text sets',
    href: '/search/?content_types=textset',
    SvgComponent: FolderHollowSVG,
    dataQASelector: 'global-nav-content-library-text-sets',
    showForStudents: false,
  },
  {
    title: 'Standards',
    href: '/search/?content_types=standard',
    SvgComponent: ChecklistSVG,
    dataQASelector: 'global-nav-content-library-standards',
    showForStudents: false,
  },
  {
    title: 'Worksheets',
    href: '/search/?content_types=externallink',
    SvgComponent: PaperHollowSVG,
    dataQASelector: 'global-nav-content-library-worksheets',
    showForStudents: false,
  },
];

export const SUPPORT_CENTER_PROFESSIONAL_RESOURCES_ITEM = {
  title: 'Professional learning resources',
  href: '/content/browse/educator-center-root',
  ItemIcon: <Icon SvgComponent={BooksSVG} />,
  openInNewTab: false,
  showForStudents: false,
};

export const SUPPORT_CENTER_ITEMS = [
  {
    title: 'Get started with Newsela',
    href: 'https://support.newsela.com/category/getting-started/',
    ItemIcon: <Icon SvgComponent={NewselaSVG} __cssFor={svgDarkFillCss} />,
    openInNewTab: true,
    showForStudents: true,
  },
  SUPPORT_CENTER_PROFESSIONAL_RESOURCES_ITEM,
  {
    title: 'Give feedback or share ideas',
    href: 'https://newselamarketing.typeform.com/to/wikwNZul',
    ItemIcon: <Icon SvgComponent={HeartHollowSVG} />,
    openInNewTab: true,
    showForStudents: true,
  },
  {
    title: 'Support center',
    href: 'https://support.newsela.com/',
    ItemIcon: <Icon SvgComponent={PreviewCardSVG} />,
    openInNewTab: true,
    showForStudents: true,
  },
];

export const ADMIN_TOOLS_MENU_ADMIN_CONSOLE_ITEM = {
  title: 'Admin console',
  href: '/admin/faculty',
  SvgComponent: UserHollowSVG,
  dataQASelector: 'global-nav-admin-tools-console'
};

export const ADMIN_TOOLS_MENU_IMPLEMENTATION_GUIDE_ITEM = {
  title: 'Implementation guide',
  href: '/admin/implementation-guide',
  SvgComponent: ClipboardCheckSVG,
  dataQASelector: 'global-nav-admin-tools-implementation-guide'
};

export const ADMIN_TOOLS_MENU_CONTENT_PROMOTION_ITEM = {
  title: 'Content promotion',
  href: '/admin/content-promotion',
  SvgComponent: DistrictHollowSVG,
  dataQASelector: 'global-nav-admin-tools-content-promotion'
};

export const ADMIN_TOOLS_MENU_CONTENT_RESTRICTION_ITEM = {
  title: 'Content restriction',
  href: '/admin/content-restriction/',
  SvgComponent: StopSVG,
  dataQASelector: 'global-nav-admin-tools-content-restriction'
};

export const STAFF_INTERNAL_TOOLS_ITEMS = [
  {
    title: 'Chiron',
    href: '/chiron/',
    SvgComponent: DetailsSVG,
    dataQASelector: 'global-nav-staff-tools-chiron'
  },
  {
    title: 'Django',
    href: '/41fccbbef792859306e06585c6186718',
    SvgComponent: FloppyHollowSVG,
    dataQASelector: 'global-nav-staff-tools-django'
  },
  {
    title: 'Alexandria',
    href: 'https://alexandria.newsela.com/',
    SvgComponent: BooksSVG,
    dataQASelector: 'global-nav-staff-tools-alexandria'
  },
  {
    title: 'Studio',
    href: 'https://studio.newsela.com/home',
    SvgComponent: VideoControlsSVG,
    dataQASelector: 'global-nav-staff-tools-studio'
  },
  {
    title: 'The Wire',
    href: 'https://newsela.com/wire/board/',
    SvgComponent: WireSVG,
    dataQASelector: 'global-nav-staff-tools-wire'
  },
  {
    title: 'Taxonomy Manager',
    href: '/alexandria/taxonomy',
    SvgComponent: SelectSVG,
    dataQASelector: 'global-nav-staff-tools-taxonomy-manager',
  },
];

export const MY_PROGRESS_ITEM = {
  title: 'My progress',
  href: '/student-binder/assignments',
  SvgComponent: InsightsSVG,
  dataQASelector: 'global-nav-my-progress-my-progress'
};

export const MY_PROGRESS_WORD_WALL_ITEM = {
  title: 'Word wall',
  href: '/student-binder/word-wall',
  SvgComponent: PowerWordHollowSVG,
  dataQASelector: 'global-nav-my-progress-word-wall'
};

export const REPORTS_MENU_CLASSROOM_DATA_EXPERIMENT_ITEM = {
  title: 'Classroom data',
  href: TEACHER_BINDER_CONSTANTS.CLASSROOM_DATA.slug,
  SvgComponent: InsightsSVG,
  dataQASelector: 'global-nav-classes-reading-summary'
};

export const REPORTS_MENU_CLASSROOM_DATA_ITEM = {
  title: 'Classroom data',
  href: '/binder/students?classroom_id=2006457029',
  SvgComponent: InsightsSVG,
  dataQASelector: 'global-nav-classes-reading-summary'
};

export const REPORTS_MENU_POWER_WORDS_ITEM = {
  title: 'Power words',
  href: '/binder/power-words?classroom_id=all',
  SvgComponent: PowerWordHollowSVG,
  dataQASelector: 'global-nav-classes-power-words'
};

export const REPORTS_MENU_USAGE_REPORT_ITEM = {
  title: 'Usage report',
  href: '/reports/admin/usage-report',
  SvgComponent: ScrollOldSVG,
  dataQASelector: 'global-nav-classes-usage-report'
};
