import React from 'react';

import {
  kebabCase as _kebabCase,
  forIn as _forIn,
} from 'lodash-es';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import withTrackEvent from 'static/three-oh/src/components/with/WithTrackEvent';
import TrackEventProps from 'static/three-oh/src/components/with/WithTrackEventConstants';

import ELEVATIONS from '../../constants/Elevations';
import AngelouProps from '../../propTypes/AngelouPropTypes';

import style from './styles.scss';

const ElevationValues = Object.values(ELEVATIONS).slice(0, -1);

const propTypes = {
  ...AngelouProps,
  ...TrackEventProps,

  /**
   * aria object which will be converted to kebab case
   */
  aria: PropTypes.object,

  /**
   * Elements to be rendered inside the card
   */
  children: PropTypes.element.isRequired,
  /**
   * The Elevation of a card. use the Elevation
   * constant defined in Angelou/constants/Elevations
   */
  elevation: PropTypes.oneOf(ElevationValues),
  /**
   * A function to be called on click
   */
  onClick: PropTypes.func,

  /**
   * a href for the card to redirect to
   */
  href: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),

  /**
   * determines if it should render a Link or an a tag
   */
  renderAnchorTag: PropTypes.bool
};

class Card extends React.PureComponent {
  generateAria = (props) => {
    const aria = {};
    if (props.aria) {
      _forIn(props.aria, (value, key) => {
        aria[`aria-${_kebabCase(key)}`] = value;
      });
    }
    return aria;
  }

  getClassName = (level) => {
    let className;
    switch (level) {
      case ELEVATIONS.flush:
        className = style.cardFlush;
        break;
      case ELEVATIONS.flat:
        className = style.cardFlat;
        break;
      case ELEVATIONS.raised:
        className = style.cardRaised;
        break;
      default:
        className = style.cardFlat;
        break;
    }
    return className;
  };

  handleClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  renderLink = (props, click, classNames) => {
    const aria = this.generateAria(this.props);
    return (
      props.renderAnchorTag ?
        <a
          {...aria}
          href={props.href}
          className={classNames}
          onClick={click}
        >
          {props.children}
        </a> :
        <Link
          to={props.href}
          className={classNames}
          onClick={click}
          {...aria}
        >
          {props.children}
        </Link>
    );
  }

  render() {
    let classNames = this.getClassName(this.props.elevation);
    if (this.props.additionalClassNames) {
      classNames = `${this.props.additionalClassNames.join(' ')} ${classNames}`;
    }
    let result;
    if (this.props.href) {
      result = this.renderLink(
        this.props,
        this.handleClick.bind(this),
        classNames
      );
    } else {
      const aria = this.generateAria(this.props);
      result = (
        <div
          className={classNames}
          onClick={this.handleClick.bind(this)}
          {...aria}
        >
          {this.props.children}
        </div>
      );
    }
    return result;
  }
}

Card.propTypes = propTypes;
Card.defaultProps = {
  elevation: ELEVATIONS.flat,
  renderAnchorTag: false
};

const CardWithTrackEvent = withTrackEvent(Card);
export {Card as default, CardWithTrackEvent};
