import React from 'react';

import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';

export const LIBRARY_ROOT = '/library';

const LazyLibraryContainer = Loadable({
  loader: () => import(
    /* webpackChunkName: "subject" */
    '../Subjects/Container'
  ),
  loading: () => null
});

const LibraryRoute = (
  <Route
    key="Library"
    path={`${LIBRARY_ROOT}/:rootTextSetId`}
    component={LazyLibraryContainer}
  />
);

export default LibraryRoute;
