const namespace = 'binder/student/';

export const BINDER_STUDENTS_LOAD_REQUEST = `${namespace}BINDER_STUDENTS_LOAD_REQUEST`;
export const BINDER_STUDENTS_LOAD_SUCCESS = `${namespace}BINDER_STUDENTS_LOAD_SUCCESS`;
export const BINDER_STUDENTS_LOAD_FAILURE = `${namespace}BINDER_STUDENTS_LOAD_FAILURE`;

export const CURRENT_ESTIMATED_GRADE_LOAD_REQUEST = `${namespace}CURRENT_ESTIMATED_GRADE_LOAD_REQUEST`;
export const CURRENT_ESTIMATED_GRADE_LOAD_SUCCESS = `${namespace}CURRENT_ESTIMATED_GRADE_LOAD_SUCCESS`;
export const CURRENT_ESTIMATED_GRADE_LOAD_FAILURE = `${namespace}CURRENT_ESTIMATED_GRADE_LOAD_FAILURE`;

export const INITIALIZE_STUDENT_TAB = `${namespace}INITIALIZE_STUDENT_TAB`;

export const BINDER_STANDARDS_LOAD_REQUEST = `${namespace}BINDER_STANDARDS_LOAD_REQUEST`;
export const BINDER_STANDARDS_LOAD_SUCCESS = `${namespace}BINDER_STANDARDS_LOAD_SUCCESS`;
export const BINDER_STANDARDS_LOAD_FAILURE = `${namespace}BINDER_STANDARDS_LOAD_FAILURE`;

export const INITIALIZE_STANDARDS = `${namespace}INITIALIZE_STANDARDS`;

export const REQUEST_READING_LEVELS = `${namespace}REQUEST_READING_LEVELS`;

export const STUDENT_READING_LEVELS_LOAD_REQUEST = `${namespace}STUDENT_READING_LEVELS_LOAD_REQUEST`;
export const STUDENT_READING_LEVELS_LOAD_SUCCESS = `${namespace}STUDENT_READING_LEVELS_LOAD_SUCCESS`;
export const STUDENT_READING_LEVELS_LOAD_FAILURE = `${namespace}STUDENT_READING_LEVELS_LOAD_FAILURE`;
