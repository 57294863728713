exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__contentImpressionFull___YM8dP{height:100%;width:100%}", ""]);

// exports
exports.locals = {
	"contentImpressionFull": "styles__contentImpressionFull___YM8dP"
};