
/**
 * Encodes the unfortunate fact that, depending on context, 'article_header' and 'header' both mean
 * that article refers to an article header.
 */
export const isArticleHeader = (article) => article.type === 'article_header' || article.type === 'header';

/**
 * Deals with the unfortunate fact that, depending on context, the article or header ID may be
 * in either article.header_id or article.id, and that if article.header_id is not defined, then
 * article.id may be either a header ID or an article ID.
 */
export const getHeaderOrArticleId = (content) => content.header_id || content.id;

/**
 * Gathers properties about an article or article header for use in events sent to Ser Davos.
 *
 * @param {*} baseEventProperties
 * @param {*} content
 * @param {*} isVeiled
 */
export const getCardEventProperties = (
  baseEventProperties,
  content,
  isVeiled,
  eventSource) => {
  const eventProperties = {
    ...baseEventProperties,
    objectId: getHeaderOrArticleId(content),
    is_veiled: (baseEventProperties && baseEventProperties.is_veiled) || isVeiled || false,
    eventSource: (baseEventProperties && baseEventProperties.eventSource) || eventSource || false,
  };

  const contentImpressionEventProperties = {
    is_veiled: eventProperties.is_veiled,
  };

  if (eventProperties.row_slug !== undefined) {
    contentImpressionEventProperties.row_slug =
      eventProperties.row_slug;
  }
  if (eventProperties.layout_index !== undefined) {
    contentImpressionEventProperties.layout_index =
      eventProperties.layout_index;
  }
  if (content.recommendation_set_id != undefined) {
    eventProperties.recommendation_set_id = content.recommendation_set_id;
    contentImpressionEventProperties.recommendation_set_id = content.recommendation_set_id;
  }
  if (eventProperties.search_id !== undefined) {
    contentImpressionEventProperties.search_id =
      eventProperties.search_id;
  }

  return {
    eventProperties,
    contentImpressionEventProperties,
  };
};
