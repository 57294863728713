import React from 'react';

import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';

export const SUBJECT_ROOT = '/subject';

const LazySubjectContainer = Loadable({
  loader: () => import(
    /* webpackChunkName: "subject" */
    './Container'
  ),
  loading: () => null
});

const SubjectRoute = (
  <Route
    key="subject"
    path={`${SUBJECT_ROOT}/:subjectSlug/:rootTextSetId`}
    component={LazySubjectContainer}
  />
);

export default SubjectRoute;
