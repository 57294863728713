import * as types from 'modulesV2/constants';

export function setReadPageMode(modeName) {
  return {type: types.SET_READ_PAGE_MODE, modeName, pageElements: types.pageElements[modeName]};
}

export function setReadPageElements(elementsObject) {
  return {type: types.SET_READ_PAGE_ELEMENTS, pageElements: elementsObject};
}

export function clearReadPageMode() {
  return {type: types.CLEAR_READ_PAGE_MODE};
}

export function setReadPageContext(context) {
  return {type: types.SET_READ_PAGE_CONTEXT, context};
}
