// the possible heights. the values correspond to the x and y offset
const ELEVATIONS = {
  flush: 0,
  flat: 1,
  raised: 3,
  elevated: 10,
};

// The color for the box shadow
const color = 'rgba(0 0 0 0.15)';
/**
 * The css values for box shadows with height. In preparation of
 * css in js
 */
const ElevationBoxShadow = {
  flush: `${ELEVATIONS.flush}px ${ELEVATIONS.flush}px 0px ${color}`,
  flat: `${ELEVATIONS.flat}px ${ELEVATIONS.flat}px 3px ${color}`,
  raised: `${ELEVATIONS.raised}px ${ELEVATIONS.raised}px 6px ${color}`,
  elevated: `${ELEVATIONS.elevated}px ${ELEVATIONS.elevated}px 16px ${color}`,
};

export default ELEVATIONS;
export {ElevationBoxShadow};
