exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__color-quiz-correct___ytBvt{background-color:#52A821}.styles__color-quiz-incorrect___Qgdbr{background-color:#D93F31}.styles__color-science___yTBUO{background-color:#55bca6}.styles__color-kids___bJm1r{background-color:#5908f7}.styles__color-money___T0E6i{background-color:#fa0045}.styles__color-law___PI1jm{background-color:#002764}.styles__color-health___6imsL{background-color:#03a43c}.styles__color-arts___tRaZ5{background-color:#E2C925}.styles__color-war-and-peace___cxQkt{background-color:#E76A0E}.styles__color-sports___Q0Cse{background-color:#000000}.styles__color-opinion___an4IN{background-color:#0027f5}.styles__color-text-set___4Qths{background-color:#106FF3}.styles__color-object-gray___RoCEx{background-color:#878787}.styles__color-light-gray___FJQ-S{background-color:#F8F8F8}.styles__color-middle-gray___NZ7wr{background-color:#BCBCBE}.styles__color-newsela-black___QOB7m{background-color:#333333}.styles__color-dark-bg___GOqsn{background-color:#031E3E}.styles__color-newsela-blue___onYS9{background-color:#0768fd}.styles__dropDownForm___N1qaL{width:100%}", ""]);

// exports
exports.locals = {
	"color-quiz-correct": "styles__color-quiz-correct___ytBvt",
	"color-quiz-incorrect": "styles__color-quiz-incorrect___Qgdbr",
	"color-science": "styles__color-science___yTBUO",
	"color-kids": "styles__color-kids___bJm1r",
	"color-money": "styles__color-money___T0E6i",
	"color-law": "styles__color-law___PI1jm",
	"color-health": "styles__color-health___6imsL",
	"color-arts": "styles__color-arts___tRaZ5",
	"color-war-and-peace": "styles__color-war-and-peace___cxQkt",
	"color-sports": "styles__color-sports___Q0Cse",
	"color-opinion": "styles__color-opinion___an4IN",
	"color-text-set": "styles__color-text-set___4Qths",
	"color-object-gray": "styles__color-object-gray___RoCEx",
	"color-light-gray": "styles__color-light-gray___FJQ-S",
	"color-middle-gray": "styles__color-middle-gray___NZ7wr",
	"color-newsela-black": "styles__color-newsela-black___QOB7m",
	"color-dark-bg": "styles__color-dark-bg___GOqsn",
	"color-newsela-blue": "styles__color-newsela-blue___onYS9",
	"dropDownForm": "styles__dropDownForm___N1qaL"
};