import React from 'react';

import {
  Switch,
} from '@material-ui/core';
import {
  withStyles,
} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const propTypes = {
  classes: PropTypes.object,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  dataQaSelector: PropTypes.string,
};

const switchStyles = (theme) => ({
  iOSSwitchBase: {
    '&$iOSChecked': {
      color: theme.palette.common.white,
      '& + $iOSBar': {
        backgroundColor: '#00c221',
      },
    },
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
    paddingTop: 0,
    left: -14
  },
  iOSChecked: {
    transform: 'translateX(24px)',
    '& + $iOSBar': {
      opacity: 1,
      border: 'none',
    },
  },
  iOSBar: {
    borderRadius: 15,
    width: 56,
    height: 32,
    marginTop: -16,
    marginLeft: -23,
    border: 'solid 1px',
    borderColor: '#999999',
    backgroundColor: '#999999',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  iOSIcon: {
    width: 24,
    height: 24,
  },
  iOSIconChecked: {
    boxShadow: theme.shadows[1],
  },
  root: {
    marginLeft: 8,
    marginTop: 20,
    overflow: 'inherit'
  }
});


const ContentFilterToggle = (props) => {
  const {
    classes,
    checked,
    onChange,
    dataQaSelector,
  } = props;
  return (
    <Switch
      {...props}
      classes={{
        root: classes.root,
        switchBase: classes.iOSSwitchBase,
        track: classes.iOSBar,
        thumb: classes.iOSIcon,
        iconChecked: classes.iOSIconChecked,
        checked: classes.iOSChecked,
      }}
      disableRipple
      checked={checked}
      onChange={(e, toggle) => onChange(toggle)}
      data-qa-selector={dataQaSelector}
    />
  );
};

ContentFilterToggle.propTypes = propTypes;
export default withStyles(switchStyles)(ContentFilterToggle);
