import {Button, constants} from '@newsela/angelou';

export const signInAnchorCss = {
  root: {
    textDecoration: 'none',
    borderRadius: '0.5rem',
    padding: '0.5rem',
    color: constants.colors.ui.white[50],
    background: constants.colors.ui.core[700],
    ':hover': {
      background: Button.flavors.primary,
    },
  },
};

export const joinNowAnchorCss = {
  root: {
    textDecoration: 'none',
    borderRadius: '0.5rem',
    padding: '0.5rem',
    color: constants.colors.ui.core[700],
    ':hover': {
      background: '',
    },
  },
};
