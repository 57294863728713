import React from 'react';

import {MarketingBanner, constants} from '@newsela/angelou';
import PropTypes from 'prop-types';

import {ReactComponent as MapSvg} from 'staticImages/trial-banner/map.svg';
import {ReactComponent as WarningSvg} from 'staticImages/trial-banner/warning.svg';

import {/* hyperlinkStyle, */ textStyle, rootStyle} from './styles';

const {colors} = constants;

const propTypes = {
  daysLeft: PropTypes.number.isRequired,
  showTrialBanner: PropTypes.bool,
  isSchoolTrial: PropTypes.bool,
};

const TrialBanner = ({
  daysLeft,
  showTrialBanner,
  isSchoolTrial
}) => {
  const color = daysLeft > 5 ? colors.ui.purple[500] : colors.ui.red[600];
  const svg = daysLeft > 5 ? MapSvg : WarningSvg;

  if (!showTrialBanner) return null;

  return (
    <MarketingBanner
      isCenter
      __cssFor={{root: {backgroundColor: color, ...rootStyle}}}
      __renderBody={() =>
        <p className={textStyle}>
          You have <b>{daysLeft} days</b> left on your {isSchoolTrial ? 'school-wide ' : ''}trial.
          {/* <a className={hyperlinkStyle} href="#">Learn More</a> */}
        </p>}
      SvgComponent={svg}
    />
  );
};

TrialBanner.propTypes = propTypes;
export default TrialBanner;
