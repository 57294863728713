import * as types from 'modulesV2/constants';

function eventRecordReducer(state = {}, action) {
  const eventName = action.name;
  switch (action.type) {
    case types.EVENT_RECORD_REQUEST:
      return {
        ...state,
        [eventName]: {
          ...state[eventName],
          isLoading: true,
        },
      };
    case types.EVENT_RECORD_REQUEST_SUCCESS:
      return {
        ...state,
        [eventName]: {
          ...state[eventName],
          isLoading: false,
          error: null,
          items: action.data,
        }
      };
    case types.EVENT_RECORD_REQUEST_FAILURE:
      return {
        ...state,
        [eventName]: {
          ...state[eventName],
          error: action.error,
          isLoading: false,
        }
      };
    default:
      return state;
  }
}

export default eventRecordReducer;
