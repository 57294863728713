import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';

import {isNonProdEnvironment} from 'utils/environment';

import newselaObjectApiMiddleware from './newselaObjectApiMiddleware';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import sagaMonitor from './sagaMonitor';


function configureStore(initialState) {
  // Middleware Configuration
  const sagaOptions = {};
  if (isNonProdEnvironment()) {
    sagaOptions.sagaMonitor = sagaMonitor;
  }
  const sagaMiddleware = createSagaMiddleware(sagaOptions);
  const middleware = [
    sagaMiddleware,
    newselaObjectApiMiddleware,
  ];

  // Store Enhancers
  let composeEnhancers = compose;
  if (isNonProdEnvironment() && typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }

  // Store Instantiation
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(...middleware)
    )
  );

  // Start Sagas
  sagaMiddleware.run(rootSaga);
  store.sagaMiddleware = sagaMiddleware;

  return store;
}

export default configureStore;
