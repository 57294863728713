import {get} from 'lodash-es';
import {createSelector} from 'reselect';

const rosterChoiceState = (state) => state.v2.services.objects.rosterChoice;

/**
 * @param {Object} rosterChoiceState
 * @returns {String}
 */
export const getUserRosterChoice = createSelector(
  [rosterChoiceState],
  (rosterChoiceState) => {
    return rosterChoiceState;
  }
);

/**
 * @param {Object} getUserRosterChoice
 * @returns {Boolean}
 */
export const isRosterChoiceLoading = createSelector(
  [getUserRosterChoice],
  (getUserRosterChoice) => {
    return get(getUserRosterChoice, 'fetching', false);
  }
);
