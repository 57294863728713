import {reportingActionTypes as types} from '../constants';

const initialState = {
  reportingData: {
    isError: false,
    isLoading: false,
    items: [],
  }
};

function reporting(state = initialState.reportingData, action) {
  switch (action.type) {
    case types.REPORTING_DATA_LOAD_SUCCESS:
      return {
        ...state,
        isError: false,
        isLoading: false,
        items: [
          ...state.items,
          ...action.reportingData.data,
        ]
      };

    case types.REPORTING_DATA_LOAD_FAILURE:
      return {
        ...state,
        isError: true,
        isLoading: false,
        items: [],
      };

    default:
      return state;
  }
}

export default reporting;
