import {isEmpty, size, parseInt, isFinite, includes} from 'lodash-es';

const root = '/';
const binderRoot = '/binder';
const companyRoot = '/company';
// This is a temporary root for signup until we're prepared to sunset the current implementation
const signUpRoot = '/join';
const readRoot = '/read';
const reviewRoot = '/review';
const adminTermsOfUseRoot = '/terms-and-conditions';
const studentBinderRoot = '/student-binder';
const studentHomeRoot = '/student-home';
const socialAuthErrorRoot = '/social-auth-error';
const unitRoot = '/units';
const textSetRoot = '/text-sets';
const assignmentRoot = '/assignment';
const assignmentsRoot = '/assignments';
const standardRoot = '/standards';
const myContentRoot = '/my-content';
const lessonSparkRoot = '/lesson-sparks';
const searchRoot = '/search';

const buildReviewUrl = ({
  slug, classroomId, articleId, studentId, workType, impersonatedUserId, activityId
}) => {
  let url = `${reviewRoot}/${slug}/classroom/${classroomId}/`;
  if (articleId && studentId) {
    url += `article/${articleId}/student/${studentId}/`;
    if (workType) {
      url += `${workType}/`;
      if (activityId) {
        url += `${activityId}/`;
      }
    }
  }
  if (impersonatedUserId) {
    url += `?userId=${impersonatedUserId}`;
  }
  return url;
};

const buildUnitUrl = ({
  unitId, subtopicId, textSetId
}) => {
  let url = '';
  if (unitId) {
    url += `/units/${unitId}`;
  }
  if (subtopicId) {
    url += `/subtopics/${subtopicId}`;
  }
  if (textSetId) {
    url += `/text-sets/${textSetId}`;
  }
  return url;
};


const getReadPageRootUrl = () => {
  // Read page could be loaded within Canvas speed-grader. In which case,
  // URL should be adjusted
  let root = '';
  const {pathname} = window.location;
  // Regex matching /apps/canvas/assignment-review/12221/student/332434
  const regex = /\/apps\/canvas\/assignment-review\/\d+\/student\/\d+/gi;
  const matched = pathname.match(regex);
  if (!isEmpty(matched)) {
    root = matched[0].substring(0, size(matched[0]));
  }
  root += '/read';
  return root;
};


/**
 * Build Canvas assignment review page URL.
 *
 * @param {string} assignmentId
 * @param {string} studentId
 * @returns {string}
 */
const getCanvasAssignmentReviewUrl = (assignmentId, studentId) => {
  return `/apps/canvas/assignment-review/${assignmentId}/student/${studentId}`;
};

/*
  Build the Read page URL.
  @param {string} slug
  @param {string|number} [articleId]
  @param {string} [activityType
  @param {string|number} [activityId]
  @param {string|number} [activityIndex]
*/
const buildReadUrl = ({slug, articleId, activityType, activityId, activityIndex}) => {
  const root = getReadPageRootUrl();
  let url = `${root}/${slug}/`;
  if (articleId) {
    url += `id/${articleId}/`;
    if (activityType) {
      url += `${activityType}/`;
      if (activityId) {
        url += `${activityId}/`;
      }
      const indexParsed = parseInt(activityIndex);
      const hasValidIndex = activityIndex === 'summary' || isFinite(indexParsed);
      if (includes(['quiz', 'lesson-sparks'], activityType) && hasValidIndex) {
        url += `question/${activityIndex}/`;
      }
    }
  }
  return url;
};

const buildSignUpUrl = (route) => {
  return `${signUpRoot}/${route}/`;
};



export {
  adminTermsOfUseRoot,
  assignmentRoot,
  assignmentsRoot,
  binderRoot,
  buildReadUrl,
  buildReviewUrl,
  buildSignUpUrl,
  buildUnitUrl,
  getCanvasAssignmentReviewUrl,
  companyRoot,
  lessonSparkRoot,
  readRoot,
  reviewRoot,
  root,
  searchRoot,
  standardRoot,
  studentBinderRoot,
  studentHomeRoot,
  signUpRoot,
  socialAuthErrorRoot,
  textSetRoot,
  unitRoot,
  myContentRoot,
};
