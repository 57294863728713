import {unset, mergeWith, includes} from 'lodash-es';

import initialState from './initialState';
import {studentDetailsActionTypes as types, studentActivityActionTypes} from '../constants';

// TODO: This is a little gross, but it's quicker than writing a bunch of different reducers
const mergeHandler = (objValue, srcValue, key) => {
  // The data coming back from the reporting API should *not* be merged into any pre-existing state.
  // Rather, new reporting data outright replaces whatever was previously stored on state.

  const keysToOverwrite = ['overallAggregates', 'overallSkillMastery', 'classroomAggregates', 'studentWork'];

  if (includes(keysToOverwrite, key)) {
    // don't merge, just take the latest data
    return srcValue;
  }
  // fall through to default deep merge behavior
  return undefined;
};

const updateDetails = (existingState, payload) => {
  return mergeWith({}, existingState, payload, mergeHandler);
};

function binderStudentDetailsReducer(state = initialState.binderStudentDetails, action) {
  if (action.type === types.STORE_STUDENT_DETAILS) {
    const studentId = action.studentId;
    return {
      ...state,
      [studentId]: updateDetails(state[studentId], action.payload)
    };
  }
  if (action.type == studentActivityActionTypes.STUDENT_QUIZ_REMOVE_SUCCESS) {
    unset(state, [action.studentId, 'studentQuizzes', action.studentQuizId]);
    return state;
  }
  return state;
}

export default binderStudentDetailsReducer;
