import {constants} from '@newsela/angelou';
import {css} from 'emotion';

import {
  NAVBAR_BREAKPOINTS
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';

export const popoutButtonListItemCss = css({
  button: {
    paddingLeft: '0.5rem',
  },
});

export const buttonCss = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    height: '2rem',
    alignItems: 'center',
    width: '100%',
    paddingLeft: '0.5rem',

    ':hover': {
      background: '#F1F7FF',
    },

    [`@media (max-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
      justifyContent: 'space-between',
    },
  },
};

export const chevronLeftCss = {
  root: {
    width: '1.5rem !important',
    height: '1.5rem !important',

    [`@media (max-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
      display: 'none',
    },
  }
};

export const menuItemIcon = {
  root: {
    minWidth: '1.5rem',
    width: '1.5rem !important',
    height: '1.5rem !important',
  }
};

export const chevronRightCss = {
  root: {
    width: '1.5rem !important',
    height: '1.5rem !important',
    marginRight: '0.25px',

    [`@media (min-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
      display: 'none',
    },
  }
};

export const openedSubMenuPopoutIndicatorCss = {
  position: 'absolute',
  left: '-0.25rem',
  top: 0,
  background: constants.colors.ui.core[700],
  width: '0.25rem',
  height: '2rem',
  borderRadius: '0.13rem',
};
