import {includes, set, defaultsDeep} from 'lodash-es';

// Convert a string, object, or array into a querystring for a URL.
export const stringifyParams = (params) => {
  const searchParams = new URLSearchParams(params);
  if (!searchParams) {
    return '';
  }
  // Our IE/Safari polyfill for URLSearchParams doesn't properly handle arrays.
  // We should probably fix this upstream but for now, this is a workaround.
  for (const key in params) {
    if (Array.isArray(params[key])) {
      const searchParamValue = searchParams.get(key);
      if (searchParamValue.charAt(0) == '[' && searchParamValue.charAt(searchParamValue.length - 1) == ']') {
        searchParams.set(key, searchParamValue.substring(1, searchParamValue.length - 1));
      }
    }
  }

  // Browsers are bad. It seems like if you run history.push('/path/?x=%2C'),
  // the browser silently unescapes the comma (%2C) and updates the location to
  // '/path/x=,'. If you do that more than once, escaping commas along the way,
  // that comma-auto-decoding trickery breaks the expected behavior of the back
  // button. Navigating from item to item in a colleciton often generates urls
  // like '?text_set_ids=1,2,3', which means if the commas are left encoded,
  // users cannot navigate backwards in a collection... So let's decode the
  // commas ourselves.
  const searchParamsString = `?${searchParams}`.replace(/%2C/gi, ',');

  return searchParamsString;
};

export const BASE_CONFIG = {
  basePath: '/api/v3/',
  bodyEncoder: JSON.stringify,
  paramsEncoder: stringifyParams,
  credentials: 'same-origin', // sends cookies for the current domain on the request
  format: 'json',
  headers: {
    Accept: 'application/json',
  },
};

/**
 * ApiFetcher3 is a promise based HTTP client for making GET, POST, and DELETE requests via fetch().
 *
 * It is currently only being used by Redux in the newselaObjectApiMiddleware to execute API calls
 * but should ultimately replace the v1 ApiFetcher.
 *
 * TODO: port all usages of old ApiFetcher to ApiFetcher2
 */

class ApiFetcher3 {
  // Error responses don't respect the Accept request HTTP header. Inspect the error
  // response to determine what format the response is actually in.
  static errorHandler(response) {
    if (response.ok) {
      return response;
    }

    let errorFormatter;
    if (includes(response.headers.get('content-type'), 'application/json')) {
      errorFormatter = response.json().then((json) => {
        return new Error(json.error || response.statusText);
      });
    } else {
      errorFormatter = response.text().then((text) => {
        return new Error(text || response.statusText);
      });
    }

    return errorFormatter.then((parsedError) => {
      const error = new Error(response.statusText);
      Object.assign(error, {
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        ok: response.ok,
        data: null,
        error: parsedError,
      });
      throw error;
    });
  }

  // Parse the response body to an indicated format.
  static formatResponse(response, format) {
    const formattedResponse = {
      status: response.status,
      statusText: response.statusText,
      headers: response.headers,
      ok: response.ok,
      data: null,
      error: null,
    };

    if (response.status === 204) {
      // Skip parsing the response body for an HTTP 204 No-Content response.
      return formattedResponse;
    }

    return response[format]().then((parsedBody) => {
      return {
        ...formattedResponse,
        data: parsedBody
      };
    });
  }

  static fetcher(method, path, params, data, passedConfig) {
    const config = {
      ...BASE_CONFIG,
      ...passedConfig,
      headers: {
        ...BASE_CONFIG.headers,
        ...(passedConfig ? passedConfig.headers : {})
      }
    };

    if (config.basePath == BASE_CONFIG.basePath) {
      // If the BASE_CONFIG basePath hasn't been overwritten,
      // then set the `X-Requested-With` header, which tricks
      // django into believing request.is_ajax.
      set(config, 'headers.X-Requested-With', 'XMLHttpRequest');
    }

    const {basePath, headers, format, bodyEncoder, paramsEncoder, ...otherConfig} = config;

    const requestPath = basePath + path + paramsEncoder(params);
    const body = data ? bodyEncoder(data) : undefined;

    const fetchInit = {
      ...otherConfig,
      method,
      headers,
      body
    };

    return fetch(requestPath, fetchInit)
      .then(this.errorHandler)
      .then((response) => this.formatResponse(response, format));
  }

  static get(path, params) {
    return this.fetcher('GET', path, params);
  }

  static post(path, {params, data, config = {}} = {}) {
    defaultsDeep(config, {headers: {'Content-Type': 'application/json'}});
    return this.fetcher('POST', path, params, data, config);
  }

  static put(path, {params, data, config = {}} = {}) {
    defaultsDeep(config, {headers: {'Content-Type': 'application/json'}});
    return this.fetcher('PUT', path, params, data, config);
  }

  static delete(path, {params, data, config = {}} = {}) {
    return this.fetcher('DELETE', path, params, data, config);
  }
}

export default ApiFetcher3;
