import React from 'react';

import {map} from 'lodash-es';
import PropTypes from 'prop-types';

import WithWindowSize from 'components/with/WithWindowSize.jsx';
import DropDownMenu from 'static/three-oh/src/components/DropDownMenu';
import Icon from 'static/three-oh/src/components/Icon';
import screenSize, {screenSizeEnum} from 'static/three-oh/src/constants/ScreenSize';
import AngelouProps from 'static/three-oh/src/propTypes/AngelouPropTypes';

import IconBarMenuItem from './IconBarMenuItem';

import style from './styles.scss';

const propTypes = {
  /**
   * default angelou props
   */
  ...AngelouProps,
  /**
   * array of elements you want rendered in a row
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      // this will be used in the tooltip and the menu name
      label: PropTypes.string.isRequired,
      // a react element. usually an <i>
      icon: PropTypes.element.isRequired,
      // onclick will be passed the option and the index in the list
      onClick: PropTypes.func,
      // a function that takes the current option and
      // returns a jsx element that will be rendered
      // in the menu
      __renderInDropDown: PropTypes.func,
      // prevent closing the menu when you click an item
      __preventCloseOnClick: PropTypes.bool,
      // disables the tooltip.
      __disableToolTip: PropTypes.bool,
      // classes to be applied to the tooltip
      toolTipClasses: PropTypes.object,
    })
  ).isRequired,

  /**
   * The size at which the bar collapses into a drop down menu
   */
  collapseSize: PropTypes.oneOf(Object.keys(screenSizeEnum)).isRequired,

  /**
   * Determines if parent text set is veiled
   */
  isVeiled: PropTypes.bool,
  /**
   * The current size of the window. You should not have to
   * pass this in as it is provided be default by a higher
   * order component
   */
  size: PropTypes.oneOf(Object.keys(screenSize)),

  /**
   * The text that will appear when the menu is collapsed.
   * can be a react element
   */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,

  /**
   * Props to assign to the drop down label. See
   * the Button component to get a comprehensive list
   */
  buttonProps: PropTypes.object,
};

class IconBar extends React.Component {
  /**
   * render the expanded icons
   */

  renderIcons = (options) => options.map((option, index) => {
    if (option.label !== 'Copy' || option.label === 'Copy' && !this.props.isVeiled) {
      return (
        <Icon
          key={`option_icon_${index}`}
          iconElement={option.icon}
          toolTipText={option.label}
          disableToolTip={option.__disableToolTip}
          onClick={(e) => {
            if (option.onClick) {
              option.onClick(option, index, e);
            }
          }}
          additionalClassNames={[style.toolTip]}
          toolTipClasses={option.toolTipClasses}
        />
      );
    }
  });

  /**
   * generate the styled options to pass to DropDownMenu
   */
  generateDropDownOptions = (options) => (map(options, (option) => (
    {
      ...option,
      __renderOption: () => {
        return option.__renderInDropDown ?
          option.__renderInDropDown(option) :
          <IconBarMenuItem icon={option.icon} label={option.label}/>;
      },
    }
  )))

  render() {
    const {
      options,
      size,
      collapseSize,
      label,
      buttonProps,
      additionalClassNames = [],
    } = this.props;

    return (
      <div className={`${style.iconBar} ${additionalClassNames.join(' ')}`}>
        {
          screenSizeEnum[size] > screenSizeEnum[collapseSize] &&
          this.renderIcons(options)
          ||
          <DropDownMenu
            options={this.generateDropDownOptions(options)}
            label={label}
            buttonProps={buttonProps}
          />
        }
      </div>
    );
  }
}

IconBar.propTypes = propTypes;

IconBar.defaultProps = {
  collapseSize: screenSize.small,
};

export default WithWindowSize(IconBar);
export {IconBar};
