import * as types from 'modulesV2/constants';

export default function rosterClassroomsReducer(state = {}, action) {
  switch (action.type) {
    case types.REQUEST_ROSTER_CLASSROOMS:
      return {
        ...state,
        [action.authProvider]: {
          loading: true,
          error: null,
        }
      };
    case types.REQUEST_ROSTER_CLASSROOMS_FAILURE:
      return {
        ...state,
        [action.authProvider]: {
          loading: false,
          error: action.error
        },
      };
    case types.REQUEST_ROSTER_CLASSROOMS_SUCCESS:
      return {
        ...state,
        [action.authProvider]: {
          loading: false,
          error: null,
          items: action.data,
        }
      };

    default:
      return state;
  }
}
