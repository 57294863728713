import React from 'react';

import {LoadingSpinner} from '@newsela/angelou';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';

import {lessonSparkRoot} from 'static/three-oh/src/modules/constants/routePaths';


const LazyLessonSparkContainer = Loadable({
  loader: () => import(
    /* webpackChunkName: "LessonSparkContainer" */
    './containers/LessonSparkContainer'
  ),
  loading: LoadingSpinner,
});

const LessonSparkRoute = (
  <Route
    path={`${lessonSparkRoot}/:lessonSparkContentId/`}
    component={LazyLessonSparkContainer}
  />
);

export default LessonSparkRoute;
