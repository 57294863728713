import * as types from '../constants/recentNewsActionTypes';

function recentNews(state = [], action) {
  switch (action.type) {
    case types.RECENT_NEWS_LOAD_SUCCESS:
      return [...action.recentNews];
    default:
      return state;
  }
}

export default recentNews;
