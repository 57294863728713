import React from 'react';

import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import styles from './styles.scss';


const propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

class BackButton extends React.Component {
  render() {
    return (
      <Link
        className={styles.backButton}
        to={this.props.url}
      >
        <i className="fa fa-angle-left" aria-hidden="true" />
        <div className={styles.label}>
          {this.props.label}
        </div>
      </Link>
    );
  }
}

BackButton.propTypes = propTypes;

export default BackButton;
