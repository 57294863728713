import {css} from 'emotion';

export const productVisibilityBreakpointCss = (isLiteOrTrial, visibilityBreakpoint) => {
  if (isLiteOrTrial) {
    return css({display: 'none'});
  }

  return css({
    display: 'none',

    [`@media (min-width: ${visibilityBreakpoint}px)`]: {
      display: 'flex',
      flexDirection: 'row',
      gap: '0.5rem',
    }
  });
};
