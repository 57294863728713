import {NAVBAR_BREAKPOINTS} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';

export const condensedSearchButtonCss = {
  root: {
    display: 'none',
    padding: '0.5rem',
    margin: 0,
    height: '2.5rem',

    ':hover': {
      background: 'rgba(29, 29, 29, 0.08)'
    },

    [`@media (max-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
      display: 'flex',
    }
  },
};

export const searchIconCss = {
  root: {
    width: '1.5rem',
    height: '1.5rem'
  }
};
