import * as types from 'modulesV2/constants';

export const initializeTheYourContentPage = () => {
  return {type: types.INITIALIZE_THE_YOUR_CONTENT_PAGE};
};

export const bookmarkedArticleHeadersFetchRequest = () => {
  return {type: types.BOOKMARKED_ARTICLE_HEADERS_FETCH_REQUEST};
};

export const bookmarkedArticleHeadersFetchSuccess = (data) => {
  return {
    type: types.BOOKMARKED_ARTICLE_HEADERS_FETCH_SUCCESS,
    data,
  };
};

export const bookmarkedArticleHeadersFetchFailure = (error) => {
  return {
    type: types.BOOKMARKED_ARTICLE_HEADERS_FETCH_FAILURE,
    error,
  };
};

export const textSetsFetchRequest = () => {
  return {type: types.TEXT_SETS_FETCH_REQUEST};
};

export const textSetsFetchSuccess = (textSets, rule) => {
  let type;
  if (rule === 'saved-by-me') {
    type = types.BOOKMARKED_TEXT_SETS_FETCH_SUCCESS;
  } else if (rule === 'mine') {
    type = types.USER_TEXT_SETS_FETCH_SUCCESS;
  } else if (rule === 'created-in-school') {
    type = types.SCHOOL_TEXT_SETS_FETCH_SUCCESS;
  }

  return {type, textSets};
};

export const textSetsFetchFailure = (error, rule) => {
  let type;
  if (rule === 'saved-by-me') {
    type = types.BOOKMARKED_TEXT_SETS_FETCH_FAILURE;
  } else if (rule === 'mine') {
    type = types.USER_TEXT_SETS_FETCH_FAILURE;
  } else if (rule === 'created-in-school') {
    type = types.SCHOOL_TEXT_SETS_FETCH_FAILURE;
  }
  return {type, error};
};

export const bookmarkedStandardsFetchRequest = () => {
  return {type: types.BOOKMARKED_STANDARDS_FETCH_REQUEST};
};

export const bookmarkedStandardsFetchSuccess = (data) => {
  return {
    type: types.BOOKMARKED_STANDARDS_FETCH_SUCCESS,
    data,
  };
};

export const bookmarkedStandardsFetchFailure = (error) => {
  return {
    type: types.BOOKMARKED_STANDARDS_FETCH_FAILURE,
    error,
  };
};
