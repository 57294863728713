import React, {useMemo} from 'react';

import {Button, Icon, SearchSVG} from '@newsela/angelou';
import PropTypes from 'prop-types';


import {
  condensedSearchButtonCss,
  searchIconCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SearchMenu/CondensedSearchButton/CondensedSearchButton.styles';

import {getNavMenuButtonAriaProps} from '../../shared/NavButtonWithPopout/NavButtonWithPopout.utils';
import {SEARCH_MENU_ID} from '../SearchMenu';

const propTypes = {
  showSuggestions: PropTypes.func.isRequired,
  setShowSuggestions: PropTypes.func.isRequired,
};

const CondensedSearchButton = ({showSuggestions, setShowSuggestions}) => {
  const buttonAriaProps = useMemo(
    () => getNavMenuButtonAriaProps({
      isOpen: showSuggestions,
      id: SEARCH_MENU_ID,
      ariaLabel: 'Open Search Menu'
    }), [showSuggestions]);

  return (
    <Button
      ariaProps={buttonAriaProps}
      flavor={Button.flavors.incognito}
      __cssFor={condensedSearchButtonCss}
      dataProps={{'data-testid': 'condensed-search-button', tabIndex: 0}}
      onClick={() => setShowSuggestions((showSuggestions) => !showSuggestions)}
    >
      <Icon SvgComponent={SearchSVG} __cssFor={searchIconCss} />
    </Button>
  );
};

CondensedSearchButton.propTypes = propTypes;
CondensedSearchButton.displayName = 'CondensedSearchButton';
export {CondensedSearchButton};
