exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__color-quiz-correct___Jptuf{background-color:#52A821}.styles__color-quiz-incorrect___3-3rK{background-color:#D93F31}.styles__color-science___6fleh{background-color:#55bca6}.styles__color-kids___p-ZVC{background-color:#5908f7}.styles__color-money___SR2Vx{background-color:#fa0045}.styles__color-law___vu2IC{background-color:#002764}.styles__color-health___A3Cdz{background-color:#03a43c}.styles__color-arts___IK68e{background-color:#E2C925}.styles__color-war-and-peace___bFiYc{background-color:#E76A0E}.styles__color-sports___6Z6NU{background-color:#000000}.styles__color-opinion___MdedL{background-color:#0027f5}.styles__color-text-set___b25TW{background-color:#106FF3}.styles__color-object-gray___F42ZR{background-color:#878787}.styles__color-light-gray___UraBq{background-color:#F8F8F8}.styles__color-middle-gray___bpr2K{background-color:#BCBCBE}.styles__color-newsela-black___h6HFI{background-color:#333333}.styles__color-dark-bg___k-MYp{background-color:#031E3E}.styles__color-newsela-blue___ElIdh{background-color:#0768fd}.styles__iconContainer___Inutg{display:inline-flex;align-items:center;color:#333}.styles__iconContainer___Inutg.styles__border___rW-uY{border:2px solid #333;border-radius:20px;padding:4px;height:25px;align-items:center}.styles__iconContainer___Inutg.styles__border___rW-uY:hover{background-color:#333;color:#fff}.styles__iconButton___JxyaR{background:rgba(0,0,0,0);color:inherit;border:none;padding:0;font:inherit;cursor:pointer}", ""]);

// exports
exports.locals = {
	"color-quiz-correct": "styles__color-quiz-correct___Jptuf",
	"color-quiz-incorrect": "styles__color-quiz-incorrect___3-3rK",
	"color-science": "styles__color-science___6fleh",
	"color-kids": "styles__color-kids___p-ZVC",
	"color-money": "styles__color-money___SR2Vx",
	"color-law": "styles__color-law___vu2IC",
	"color-health": "styles__color-health___A3Cdz",
	"color-arts": "styles__color-arts___IK68e",
	"color-war-and-peace": "styles__color-war-and-peace___bFiYc",
	"color-sports": "styles__color-sports___6Z6NU",
	"color-opinion": "styles__color-opinion___MdedL",
	"color-text-set": "styles__color-text-set___b25TW",
	"color-object-gray": "styles__color-object-gray___F42ZR",
	"color-light-gray": "styles__color-light-gray___UraBq",
	"color-middle-gray": "styles__color-middle-gray___bpr2K",
	"color-newsela-black": "styles__color-newsela-black___h6HFI",
	"color-dark-bg": "styles__color-dark-bg___k-MYp",
	"color-newsela-blue": "styles__color-newsela-blue___ElIdh",
	"iconContainer": "styles__iconContainer___Inutg",
	"border": "styles__border___rW-uY",
	"iconButton": "styles__iconButton___JxyaR"
};