import React from 'react';

import {useSelector} from 'react-redux';

import {SUPPORT_CENTER_ITEMS} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';
import {
  SupportCenterItem
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SupportCenter/SupportCenterItem';
import {
  popoutContainerCss,
  supportCenterUnorderedListCss,
  supportTitleCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SupportCenter/SupportCenterPopout/SupportCenterPopout.styles';

import {getUserProperties} from '../../../../../../modules/selectors/userSelectors';

const SupportCenterPopout = () => {
  const {
    isStudent,
  } = useSelector((state) => getUserProperties(state));

  return (
    <div className={popoutContainerCss}>
      <div className={supportTitleCss}>Support</div>

      <ul className={supportCenterUnorderedListCss}>
        {SUPPORT_CENTER_ITEMS
          .filter((item) => !isStudent || item.showForStudents)
          .map(({title, href, openInNewTab, ItemIcon}, index) => (
            <SupportCenterItem title={title} href={href} ItemIcon={ItemIcon} openInNewTab={openInNewTab} key={index} />
          ))}
      </ul>
    </div>
  );
};

SupportCenterPopout.displayName = 'SupportCenterPopout';
export {SupportCenterPopout};
