import {ifvisible} from 'ifvisible.js';
import {
  forEach,
  get,
  reject,
  isNil,
  parseInt,
  pickBy,
  keys,
  map,
  uniq
} from 'lodash-es';
import {delay} from 'redux-saga';
import {takeEvery, takeLatest, fork, call, take, put, select, all} from 'redux-saga/effects';

import {
  NotificationApi,
  ReportingApi,
} from 'static/three-oh/src/modules/apis';
import {enrichStudentWork} from 'static/three-oh/src/modules/utils';
import {getBinderFilters, toAssignmentReportingFilters} from 'static/three-oh/src/routes/Binder/modules/selectors/filterSelectors';
import {applyTo, applier} from 'utils/lodashHelpers';

import {assignmentsTabActions} from '../actions';
import {assignmentsTabActionTypes} from '../constants';
import {getNotificationMaxDateCreatedByHeader} from '../selectors/assignmentsTabSelectors';
import {getAuthenticatedOrImpersonatedUser} from '../selectors/binderSelectors';
import {getWorkDetailsVisibility} from '../selectors/workDetailsSelectors';


const DEFAULT_WORK_REPORT_PARAMETERS = {
  measures: [
    'avg:grade_level',
    'count:header_views',
    'avg:reading_time',
    'sum:reading_time',
    'avg:quiz_score',
    'avg:write_response_score',
    'count:annotations',
    'count:vocabulary_questions',
    'count:vocabulary_questions_correct',
  ],
  dimensions: [
    'header_id',
    'assignment_classroom_id',
  ],
};

/**
 * Fetches notifications related to a particular ArticleHeader and/or Classroom. The returned
 * notifications are used to enrich the StudentQuiz and WriteResponse work.
 */
function * getNotifications({headerId, classroomId, since = '1970-01-01'}) {
  const user = yield select(getAuthenticatedOrImpersonatedUser);
  const streamName = `user:${user.id}`;

  yield put(assignmentsTabActions.notificationsFetchRequest(headerId, classroomId));
  let notifications;

  try {
    notifications = yield call(NotificationApi.getNotifications, {streamName, headerId, classroomId, since, pageSize: 2000});
    yield put(assignmentsTabActions.notificationsFetchSuccess(headerId, classroomId, notifications));

    // Look for write responses and student quizzes to enrich.
    yield enrichStudentWork(notifications);

    return notifications;
  } catch (error) {
    yield put(assignmentsTabActions.notificationsFetchFailure(headerId, classroomId, error));
  }
}

function * getTimeSpentInArticle({headerId, classroomId}) {
  const readingTimeReportParameters = {
    measures: [
      'sum:reading_time',
    ],
    dimensions: [
      'article_id',
      'student_id',
    ],
    filters: {
      header_id: headerId,
      assignment_classroom_id: classroomId,
    }
  };

  const report = yield call(ReportingApi.getReport, readingTimeReportParameters);

  /*
    Reshape the reporting data into an object keyed by student_id and article_id for easier lookups. Eg:
    report.data = [{article_id: 22778, student_id: 3612984, sum:reading_time: 182}]
        would become:
    readingTimes = {"3612984,22778": 182}
  */
  const readingTimes = {};
  forEach(report.data, (row) => {
    readingTimes[row.student_id + ',' + row.article_id] = row['sum:reading_time'];
  });

  yield put(assignmentsTabActions.readingTimeDataFetchSuccess(headerId, classroomId, readingTimes));
}

/**
 * Listens for when a work summary is clicked on. Will expand or collapse the details table
 * underneath and kick off a request to fetch the notifications needed to build the details table.
 */
function * watchToggleWorkDetails() {
  yield takeEvery(assignmentsTabActionTypes.TOGGLE_WORK_DETAILS, function * (action) {
    const {key} = action.payload;

    const workDetailsVisibility = yield select(getWorkDetailsVisibility);
    const isOpen = get(workDetailsVisibility, [key, 'isOpen'], false);
    if (isOpen) {
      yield put(assignmentsTabActions.closeWorkDetails(key));
    } else {
      const [headerId, classroomId] = key.split(',');
      yield put(assignmentsTabActions.openWorkDetails(key));

      yield all([
        call(getNotifications, {headerId, classroomId}),
        call(getTimeSpentInArticle, {headerId, classroomId})
      ]);

      yield put(assignmentsTabActions.workDetailsFinishedLoading(key));
    }
  });
}

/**
 * Refresh the reporting measures for a particular ArticleHeader. Used by pollForNewWork as well as
 * when a StudentQuiz is reset or a WriteResponse is returned for revision and we need to update the
 * average quiz/write scores measures related to the affected ArticleHeader.
 */
function * getSlimReportingData(headerId) {
  const binderFilters = yield select(getBinderFilters, window.location.search);

  const workReportParameters = {
    ...DEFAULT_WORK_REPORT_PARAMETERS,
    filters: {
      ...toAssignmentReportingFilters(binderFilters),
      header_id: headerId,
    },
  };

  try {
    const workReport = yield call(ReportingApi.getReport, workReportParameters);
    const workReportingData = reject(workReport.data, (row) => isNil(row.header_id));
    yield put(assignmentsTabActions.slimHeaderReportingDataFetchSuccess(headerId, workReportingData));
  } catch (e) {
    // no-op
  }
}

/**
 * Minimal request to update reporting data based upon a Header Id.
 */
function * watchGetSlimReportingData() {
  yield takeLatest(assignmentsTabActionTypes.SLIM_HEADER_REPORTING_DATA_FETCH_REQUEST, function * (action) {
    const headerId = action.headerId;
    yield all([
      call(getSlimReportingData, headerId),
      call(getNotifications, {headerId}) // TODO: check if this can be removed
    ]);
  });
}

function * pollForNewWork() {
  while (true) {
    yield call(delay, 12000);

    // Only poll if the page is not hidden.
    if (ifvisible.now()) {
      // The workDetailsVisibility tells us which assignments have been expanded or are loading in the assignments tab.
      const workDetailsVisibility = yield select(getWorkDetailsVisibility);

      // Determine which assignments are open and showing the expanded WorkDetails section.
      const openWorkDetailsKeys = applyTo(workDetailsVisibility)(
        applier(pickBy)('isOpen'),
        applier(keys)()
      );

      // Fetch the latest student reading times for each expanded piece of work.
      for (const key of openWorkDetailsKeys) {
        const [headerId, classroomId] = key.split(',').map(parseInt);
        yield call(getTimeSpentInArticle, {headerId, classroomId});
      }

      // Grab the related unique Header ID's of each of the open WorkDetails.
      const headerIdsToPoll = applyTo(openWorkDetailsKeys)(
        applier(map)((key) => parseInt(key.split(',')[0])),
        applier(uniq)()
      );

      // For each Header, calculate when the last notification seen was.
      // We only want to check for notifications since this time.
      const lastNotificationByHeaderId = yield select(getNotificationMaxDateCreatedByHeader);

      // Poll for new notifications for each of the open work details tables.
      for (const headerId of headerIdsToPoll) {
        const since = get(lastNotificationByHeaderId, headerId);
        yield call(getNotifications, {headerId, since});

        // Also refresh the the aggregate information of the work.
        yield call(getSlimReportingData, headerId);
      }
    }
  }
}

function * workPollingManager() {
  while (true) {
    yield take(assignmentsTabActionTypes.START_POLLING);
    const task = yield fork(pollForNewWork);
    yield take(assignmentsTabActionTypes.STOP_POLLING);
    yield task.cancel();
  }
}

export {
  pollForNewWork,
  watchGetSlimReportingData,
  workPollingManager,
  watchToggleWorkDetails,
};
