import React from 'react';

import {LoadingSpinner} from '@newsela/angelou';
import Loadable from 'react-loadable';
import {Route, Redirect} from 'react-router-dom';

import {binderRoot} from 'static/three-oh/src/modules/constants/routePaths';

/**
 * The `/binder` route renders the <BinderContainer> which does some common initial data
 * fetching. From there, the <Binder> presentational component then handles the various nested
 * routes to the sub-pages in the Binder, ie: Assignment Tab, Power Words Tab, etc...
 */

const LazyBinderContainer = Loadable({
  loader: () => import(
    /* webpackChunkName: "TeacherBinder" */
    './containers/BinderContainer/BinderContainer'
  ),
  loading: LoadingSpinner,
});


const BinderRoute = [
  <Route
    key="BinderIndexRedirect"
    path={binderRoot}
    exact
    render={() => <Redirect to={binderRoot + '/assignments'}/>}
  />,
  <Route
    key="BinderCatchAll"
    path={binderRoot}
    component={LazyBinderContainer}
  />,
];


export default BinderRoute;
