import {NAVBAR_BREAKPOINTS} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';

export const questionIconCss = {
  root: {
    padding: '0.25rem',
    width: '2rem',
    height: '2rem',
  }
};

export const supportCenterButtonCss = {
  root: {
    display: 'flex',

    ':hover': {
      background: 'rgba(29, 29, 29, 0.08)',
    }
  }
};

export const supportCenterVisibilityCss = {
  display: 'none',

  [`@media (min-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
    display: 'flex',
  }
};
