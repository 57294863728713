import React from 'react';

import {Anchor} from '@newsela/angelou';
import classNames from 'classnames/bind';
import {css} from 'emotion';
import PropTypes from 'prop-types';

import AngelouTooltip from 'static/three-oh/src/components/AngelouTooltip';

import styles from './styles.scss';
const generateClassName = classNames.bind(styles);
/*
  This Measure component represents each cell in a Summary Card (<SummaryCard/>).
*/

const propTypes = {
  articleHeader: PropTypes.object,
  dataQaSelector: PropTypes.string,
  fontAwesomeIcon: PropTypes.string,
  gradingUrl: PropTypes.string,
  key: PropTypes.number,
  label: PropTypes.array,
  needsReview: PropTypes.bool,
  noStudentActivity: PropTypes.bool,
  student: PropTypes.object,
  tooltipMessage: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const Measure = (props) => {
  const gradingUrl = props?.gradingUrl;
  const fontAwesomeIcon = props.fontAwesomeIcon ? (
    <i className={`fa ${props.fontAwesomeIcon} ${styles.icon}`} />
  ) : null;
  const measureStyle = generateClassName({
    measure: true,
    inactiveMeasure: props.noStudentActivity,
  });

  const label = props.label.map((string, index) => (
    <span key={`label-${index}`}>{string}</span>
  ));

  const renderMeasure = () => {
    return (
      <div
        className={measureStyle}
        key={props.key}
        data-qa-selector={props.dataQaSelector}
      >
        <div className={styles.valueContainer}>
          <span className={styles.valueText}>{props.value}</span>
          {fontAwesomeIcon}
        </div>
        <div className={styles.label}>
          {gradingUrl ? <Anchor href={gradingUrl}>{label}</Anchor> : label}
        </div>
      </div>
    );
  };

  return props.tooltipMessage ? (
    <AngelouTooltip
      message={props.tooltipMessage}
      key={`${props.key}-tooltip`}
      dismissOnMouseout
      hasRoundedCorners
      isDark
      position="top"
      tooltipAlign="center"
      additionalClassName={css`
        top: 35px !important;
      `}
    >
      {renderMeasure()}
    </AngelouTooltip>
  ) : (
    renderMeasure()
  );
};

Measure.propTypes = propTypes;
export default Measure;
