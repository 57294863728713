import {css} from 'emotion';

import uiColors from 'components/Colors/colors';
import {mediaQueries as mq} from 'static/three-oh/src/constants/BreakPoints';

const border = `3px solid ${uiColors.yellow[0]}`;

const calcWidth = (width) => `
  calc((${width} / 3) * 2)
`;
export const generateBreakpoints = (breakpoints) => (
  Object.keys(breakpoints).map((size) => (`
      ${mq[size]}{
        ${
          breakpoints[size].__rawCss ?
            breakpoints[size].__rawCss : ''
        }
        ${
          breakpoints[size].width &&
            `
              width: ${breakpoints[size].width};
              height: ${breakpoints[size].width};
              svg {
                width: ${calcWidth(breakpoints[size].width)};
              }
            `
        }
      }
    `)
  )
);

const style = (width, breakpoints) => css`
    display: flex;
    background-color: #131415;
    border-left: ${border};
    border-right: ${border};
    border-bottom: ${border};
    width: ${width};
    height: ${width};
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    align-items: center;
    justify-content:center;
    box-sizing: border-box;
    svg {
      width: ${calcWidth(width)};
      * {
        fill: ${uiColors.yellow[0]};
        stroke: ${uiColors.yellow[0]};
      }
    }
  ${
    breakpoints &&
    generateBreakpoints(breakpoints)
  }
`;


export default style;
