import React from 'react';

import {Anchor} from '@newsela/angelou';
import PropTypes from 'prop-types';

import {
  iconAndLabelContainerCss,
  supportCenterAnchorCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SupportCenter/SupportCenterItem/SupportCenterItem.styles';

const propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  openInNewTab: PropTypes.bool.isRequired,
  ItemIcon: PropTypes.node
};

const SupportCenterItem = ({title, href, ItemIcon, openInNewTab}) => {
  return (
    <li>
      <Anchor href={href} openInNewTab={openInNewTab} __cssFor={supportCenterAnchorCss}>
        <div className={iconAndLabelContainerCss}>
          {ItemIcon}
          {title}
        </div>
      </Anchor>
    </li>
  );
};

SupportCenterItem.propTypes = propTypes;
SupportCenterItem.displayName = 'SupportCenterItem';
export {SupportCenterItem};
