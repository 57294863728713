import * as types from 'modulesV2/constants';

export const fetchCollections = () => ({
  type: types.COLLECTIONS_FETCH_REQUEST,
});

export const fetchCollectionsSuccess = (data) => ({
  type: types.COLLECTIONS_FETCH_SUCCESS,
  data,
});

export const fetchCollectionsFailure = (error) => ({
  type: types.COLLECTIONS_FETCH_FAILURE,
  error,
});
