import {constants} from '@newsela/angelou';
import {css} from 'emotion';

import {
  NAVBAR_BREAKPOINTS
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';

export const popoutContainerCss = css({
  width: '20rem',
  top: '2rem',
  right: 0,
  gap: '1rem',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '1rem',
  borderRadius: '0.5rem',
  background: constants.colors.ui.white[50],
  boxShadow: '0px 2px 6px 0px rgba(29, 29, 29, 0.16)',

  [`@media (max-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
    borderRadius: '0 0 0.5rem 0.5rem',
    width: 'inherit',
  },
});
