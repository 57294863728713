import React from 'react';

import {Button, PrintSVG} from '@newsela/angelou';
import PropTypes from 'prop-types';


import withTrackEvent from 'static/three-oh/src/components/with/WithTrackEvent';

const propTypes = {
  eventProperties: PropTypes.obj,
  togglePrintModal: PropTypes.func,
  size: PropTypes.string,
  buttonProps: PropTypes.object,
};

const ButtonWithTrackEvent = withTrackEvent(Button);

const PrintButton = (props) => {
  return (
    <ButtonWithTrackEvent
      objectType="Content"
      actionPrefix="Initiate"
      actionName="Print"
      legacyEventName="TextSet-OpenPrintModal"
      streamProperties={{
        description: 'Open print modal.',
        ...props.eventProperties,
      }}
      /* AUTOGENERATED TODO: update angelou to new flavor.
        see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
        for migration guide. */
      legacy_flavor={Button.legacy_flavor.flat}
      /* AUTOGENERATED TODO: update angelou to new flavor.
        see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
        for migration guide. */
      legacy_statusColor={Button.legacy_statusColor.black}
      /* AUTOGENERATED TODO: update angelou to new size.
        see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
        for migration guide. */
      legacy_size={props.size}
      onClick={props.togglePrintModal}
      ariaProps={{
        'aria-label': 'Print'
      }}
      dataProps={{
        'data-qa-selector': 'Print'
      }}
      __cssFor={{
        Icon: {
          root: {
            '&&': {
              width: '1.25em',
              height: '1.25em'
            }
          }
        }
      }}
      icon={{
        SvgComponent: PrintSVG,
        alignment: Button.iconAlignments.left
      }}
      {...props?.buttonProps}
    >
      Print
    </ButtonWithTrackEvent>
  );
};

PrintButton.propTypes = propTypes;
PrintButton.size = Button.legacy_size;
export default PrintButton;
