import {filter} from 'lodash-es';

import * as types from 'modulesV2/constants/';

const initialState = {contentLiked: []};

/**
 * @param {Object[]} contentLiked
 * @param {number} contentLiked[].content_id
 * @param {number} contentLiked[].id
 * @param {string} contentLiked[].date_created
 */
const mapDataToHash = (contentLiked) => {
  return contentLiked;
};

/**
 * @param {Object} likedContent
 * @param {Object} newLike
 * @param {number} newLike.id
 */
const updateCreateLikedContent = (likedContent, newLike) => {
  return [...likedContent, newLike];
};


/**
 * @param {Object} likedContent
 * @param {Number} likeId
 */
const removeLikedArticle = (likedContent, likeId) => {
  const result = filter(likedContent, function(like) {
    return like.id !== likeId;
  });
  return result;
};

export default function likedContent(state = initialState, action) {
  const {type, data, likeId} = action;
  switch (type) {
    case types.GET_LIKED_CONTENT_SUCCESS:
      return mapDataToHash(data.data);
    case types.CREATE_LIKED_CONTENT_SUCCESS:
      return updateCreateLikedContent(state, data.data);
    case types.DELETE_LIKED_CONTENT_SUCCESS:
      return removeLikedArticle(state, likeId);
    default:
      return state;
  }
}
