import React from 'react';

import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';

export const EDUCATOR_CENTER_ROOT = '/educator-center';

const LazyEducatorCenterContainer = Loadable({
  loader: () => import(
    /* webpackChunkName: "Educator Center" */
    '../Subjects/Container'
  ),
  loading: () => null
});

const EducatorCenterRoute = (
  <Route
    key="EducatorCenter"
    path={`${EDUCATOR_CENTER_ROOT}/:rootTextSetId`}
    component={LazyEducatorCenterContainer}
  />
);

export default EducatorCenterRoute;
