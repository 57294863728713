import {constants} from '@newsela/angelou';
import {css} from 'emotion';

export const reportsMenuPopoutContainer = css({
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem 0 1.25rem 0',
  gap: '0.5rem',
  borderRadius: '0.5rem',
  background: constants.colors.ui.white[50],
  boxShadow: '0px 2px 6px 0px rgba(29, 29, 29, 0.16)',
});
