import React from 'react';

import {Button} from '@newsela/angelou';
import {cx} from 'emotion';
import PropTypes from 'prop-types';

import {ReactComponent as CloseSvg} from 'static/images/registration/close.svg';
import {ReactComponent as WarningSvg} from 'static/images/registration/warning.svg';

import styles from './styles';

const propTypes = {
  additionalClassName: PropTypes.string,
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  onClick: PropTypes.func,
  showCloseButton: PropTypes.bool,
  showWarningIcon: PropTypes.bool,
};

const defaultProps = {
  showCloseButton: true,
  showWarningIcon: true
};

const ErrorToast = (props) => {
  const defaultErrorMessage = 'Oops. Something went wrong. Please try again.';

  return (
    <div className={cx([styles.errorMsg, props.additionalClassName])}>
      <div className={styles.textContainer}>
        {props.showWarningIcon && <WarningSvg aria-hidden="true" className={styles.warningIcon}/>}
        <span className={cx([styles.text, styles.linkInsideToast])}>{props.errorMessage ? props.errorMessage : defaultErrorMessage}</span>
      </div>
      {props.showCloseButton && <Button
        /* AUTOGENERATED TODO: update angelou to new flavor.
          see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
          for migration guide. */
        legacy_flavor={Button.legacy_flavor.incognito}
        ariaProps={{
          'aria-label': 'Close message'
        }}
        __classNameFor={{
          root: styles.closeButton
        }}
        dataProps={{'data-qa-selector': 'close_button'}}
        onClick={() => {
          props.onClick();
        }}
                                >
        <CloseSvg aria-hidden="true" className={styles.closeIcon} />
      </Button>}
    </div>
  );
};

ErrorToast.propTypes = propTypes;
ErrorToast.defaultProps = defaultProps;

export default ErrorToast;
