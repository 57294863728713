import React from 'react';

import {debounce, get, isEmpty, isString, merge} from 'lodash-es';
import {connect} from 'react-redux';
import {compose} from 'redux';


import {eventStream, getEventNameForEventStream} from 'static/three-oh/utils/sessionActivity';
import {toSnakeCase} from 'static/three-oh/utils/stringUtils';
import {completeTourEvent} from 'static/three-oh/utils/tourEvents';

import propTypes from './WithTrackEventConstants';

const defaultProps = {
  trackView: false,
  trackViewOnMount: false,
  trackHover: true,
  trackClick: true,
  trackImpression: false,
};


/**
 * WithTrackEvent is a higher order component that facilitates the event
 * tracking of both legacy 'user events' and the new 'event-stream' kinesis streams.
 */
function withTrackEvent(WrappedComponent) {
  class WithTrackEvent extends React.Component {
    constructor(props) {
      super(props);

      this.hoverStartTime = null;

      /**
       * Debounce the track view method to ensure that every re-render does
       * not send another view event to the tracking service. It needs to be in
       * the constructor so that the timeout does not reset on re-render.
       */
      this.trackView = debounce(this.trackView.bind(this), 60000, {
        leading: true,
        trailing: false
      });

      /*
       * Any component that passes the trackImpression prop will be registered with the tracking
       * system as a bit of content. Any content that is visible on the screen is
       * recorded as an impression.
       * This class does not actually track visibility. Rather, it just alerts
       * the tracker to its presence using React's `ref` functionality.
       */
      this.deregisterElement = null;
      this.element = null;
      this.updateRef = this.updateRef.bind(this);
    }

    componentDidMount() {
      if (this.props.trackViewOnMount) {
        this.trackView();
      }

      const {objectType, streamProperties, eventSource} = this.props;
      if (this.props.trackImpression) {
        eventStream(this.getStreamEventProperties('impression'), {
          userId: this.getUserId(),
          name: this.getEventName(),
          type: 'impression',
          properties: this.getEventProperties()
        });
        this.registerElement({
          model: objectType,
          id: streamProperties.content_id,
          format: streamProperties.content_type,
          eventSource: eventSource,
          eventProperties: this.getStreamEventProperties('impression')
        });
      }
    }

    componentWillReceiveProps(nextProps) {
      // Occassionally content is initialized late or changed in-place. But we
      // expect everything except the ID of the content to stay the same.
      // Check to see if the ID has changed and track a new impression if so.
      if (nextProps.trackImpression && nextProps.streamProperties && (nextProps.streamProperties.content_id != this.props.streamProperties.content_id)) {
        this.registerElement({
          model: nextProps.objectType,
          id: nextProps.streamProperties.content_id,
          format: nextProps.streamProperties.content_type,
          eventSource: nextProps.eventSource,
          eventProperties: this.getStreamEventProperties('impression')
        });
      }
    }

    componentDidUpdate() {
      if (this.props.trackView) {
        this.trackView();
      }
    }

    componentWillUnmount() {
      window.removeEventListener('forceUpdate', () => this.updateWindowDimensions());

      if (this.deregisterElement) {
        this.deregisterElement();
        this.element = null;
      }
    }

    registerElement({model, id, format, eventSource, eventProperties}) {
      if (window.tracker && id) {
        window.tracker.register_content_element(
          this.element,
          model, id, format, eventSource, eventProperties, true
        );
      }
    }

    updateRef(element) {
      if (element && (this.element !== element) && this.props.trackImpression) {
        // There is a new element.
        if (this.deregisterElement) {
          // And the element was previously registered. Deregister it.
          this.deregisterElement(this.element);
        }

        // All hail the new element.
        this.element = element;

        // Start tracking the visibility of the new element using the global tracker.
        // It returns a function to deregister the element later.
        if (window.tracker) {
          const {model, id, format, eventSource, eventProperties} = this.props;

          this.deregisterElement = window.tracker.register_content_element(
            this.element, model, id, format, eventSource, eventProperties, true
          );
        }
      } else if (this.deregisterElement) {
        this.deregisterElement();
        this.element = null;
      }
    }

    trackView() {
      eventStream(this.getStreamEventProperties('view'), {
        userId: this.getUserId(),
        name: this.getEventName(),
        type: 'view',
        properties: this.getEventProperties()
      });
    }

    updateHomePageTourData(eventType) {
      const viewingHomePage = (
        window.location.pathname.includes('home')
      );

      const {parent_row} = {
        ...this.props.streamProperties
      };

      if (!viewingHomePage || !parent_row?.row_slug || eventType !== 'click' || !this.props.userId) return;
      // Fire TourEvent update to mark tour as "complete" when a row is clicked on the homepage.
      completeTourEvent(parent_row.row_slug, this.props.userId);
    }

    getEventName() {
      // Preserve former event names until new SLA is fully adopted.
      if (this.props.legacyEventName) {
        return this.props.legacyEventName;
      }
      return this.props.objectType ?
        `${this.props.objectType}-${this.props.actionName}` :
        this.props.actionName;
    }

    getStreamEventProperties = (eventType) => {
      const properties = {
        event_name: getEventNameForEventStream(this.props.objectType, this.props.actionPrefix, this.props.actionName),
        user_id: this.getUserId(),
        event_type: eventType,
        dimension_properties: {
          ...this.props.streamProperties,
          action_name: this.props.actionName,
          action_prefix: this.props.actionPrefix,
          /** Added captured_url key to store the URL object of content card at
           * time of click */
          captured_url: this.props.captured_url,
          component_type: this.getWrappedComponentName(),
          content_id: this.props.content_id || this.props.streamProperties?.content_id,
          context: this.props.context,
          legacy_event_name: this.props.legacyEventName,
          object_type: this.props.objectType,
          // Flatten the screen size property to make more readable.
          screen_size: this.props.screenSize.size,
        }
      };

      return properties;
    }

    getUserId() {
      return get(window, 'user_id', null);
    }

    getWrappedComponentName() {
      const wrappedComponentName =
        WrappedComponent.displayName ||
        WrappedComponent.name;

      /** Filter out signal from noise  */
      if (isString(wrappedComponentName) && !isEmpty(wrappedComponentName)) {
        return wrappedComponentName;
      }

      /** If no usable value is derived return generic Component String  */
      return 'Component';
    }

    getEventProperties() {
      const getEventProperties = {
        captured_url: this.props.captured_url,
        componentType: this.getWrappedComponentName(),
        objectType: this.props.objectType,
        screenSize: this.props.screenSize,
        ...this.props.eventProperties,
      };

      if (this.props.content_id) {
        getEventProperties.contentId = this.props.content_id;
      }

      return getEventProperties;
    }

    trackAction = (eventType, additionalProperties, additionalDimensionProperties) => {
      const properties = {...additionalProperties, ...this.getEventProperties()};

      this.updateHomePageTourData(eventType);


      const streamEventProperties = merge(this.getStreamEventProperties(eventType), {
        dimension_properties: additionalDimensionProperties
      });
      const legacyEvent = this.props.skipLegacyEvent ? null : {
        userId: this.getUserId(),
        name: this.getEventName(),
        type: eventType,
        properties
      };

      eventStream(streamEventProperties, legacyEvent);
    }

    handleClick = (e) => {
      const {onClick, trackClick} = this.props;
      if (trackClick) {
        this.trackAction('click', {}, {
          destination_url: e.target.href,
        });
      }

      if (onClick) {
        onClick(e);
      }
    }

    handleHoverEnter = (e) => {
      if (this.props.trackHover) {
        this.hoverStartTime = Date.now();
        if (this.props.onMouseEnter) {
          this.props.onMouseEnter(e);
        }
      }
    }

    handleHoverLeave = (e) => {
      if (this.props.trackHover) {
        const stopTime = Date.now();
        const delta = stopTime - this.hoverStartTime;
        this.trackAction('hover', {hoverTime: `${delta}ms`});
        if (this.props.onMouseLeave) {
          this.props.onMouseLeave(e);
        }
      }
    }

    render() {
      const props = {
        ...this.props,
        onClick: this.handleClick,
        onMouseEnter: this.handleHoverEnter,
        onMouseLeave: this.handleHoverLeave,
      };

      // If trackImpression is true, then wrap the component in a ref to track the impressions
      return this.props.trackImpression ?
          (
            <span
              ref={this.updateRef}
              data-qa-selector={toSnakeCase(`${this.props.model}_${this.props.id}`)}
            >
              <WrappedComponent ref={this.props.forwardedRef} {...props} />
            </span>
          ) :
            <WrappedComponent ref={this.props.forwardedRef} {...props} />;
    }
  }

  // Give each instance of the HOC a name related to its wrapped component.
  const wrappedComponentName = WrappedComponent.displayName
    || WrappedComponent.name
    || 'Component';

  WithTrackEvent.displayName = `withTrackEvent(${wrappedComponentName})`;
  WithTrackEvent.propTypes = propTypes;
  WithTrackEvent.defaultProps = defaultProps;

  return React.forwardRef((props, ref) => {
    return <WithTrackEvent {...props} forwardedRef={ref}/>;
  });
}

const mapStateToProps = (state) => {
  return {
    context: state.v2.ui?.context,
    screenSize: state.v2.ui?.screenSize,
    userId: get(state, 'user.id'),
    tourEvents: get(state, 'user.tour_events')
  };
};

const withTrackEventAndState = compose(
  connect(mapStateToProps, null, null, {forwardRef: true}),
  withTrackEvent
);

export default withTrackEventAndState;
