import React, {useState} from 'react';

import {css} from 'emotion';
import PropTypes from 'prop-types';

import styles from './styles';

const propTypes = {
  activeText: PropTypes.string,
  ariaLabel: PropTypes.string,
  checked: PropTypes.bool,
  inactiveText: PropTypes.string,
  onChange: PropTypes.func,
  useInternalState: PropTypes.bool,
  additionalClassNames: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
};

const defaultProps = {
  additionalClassNames: [],
  ariaLabel: 'toggle switch',
  checked: false,
  activeText: 'ON',
  inactiveText: 'OFF',
  id: null
};

/**
 * An interactive switch that implies an on/off status of an option.
 */
const ToggleSwitch = (props) => {
  const {
    ariaLabel,
    onChange,
    checked,
    useInternalState,
    activeText,
    inactiveText,
    additionalClassNames,
    id,
  } = props;

  const [state, setState] = useState({
    isChecked: !!checked,
    initialRender: false,
  });

  const isChecked = useInternalState ? state.isChecked : checked;

  return (
    <div className={additionalClassNames.join(' ')}>
      <div
        className={css`
          position: relative;
          display: inline-block;
          z-index: 0;
        `}
      >
        <input
          id={id}
          type="checkbox"
          checked={isChecked}
          aria-checked={isChecked}
          aria-label={ariaLabel}
          onClick={(e) => {
            let newState = {};
            if (useInternalState) {
              newState = {
                isChecked: !state.isChecked,
                initialRender: true,
              };
              setState(newState);
            } else {
              newState = {
                isChecked: checked,
                initialRender: true,
              };
              if (!state.initialRender) {
                setState(newState);
              }
            }
            if (onChange) {
              onChange(e, newState);
            }
          }}
          className={styles.checkbox(state)}
          data-qa-selector="checkbox"
        />
        <div
          className={styles.wrapper}
        >
          <div
            data-toggle-section="sliderContainer"
            className={styles.sliderContainer}
            data-qa-selector="slider"
          >
            <div
              data-toggle-section="slider"
              className={styles.slider}
            />
          </div>
          <div
            data-toggle-section="text"
            className={styles.text(
              isChecked,
              activeText.length > inactiveText.length
            )}
            data-qa-selector="slider_text"
          >
            <span
              data-toggle-section="sliderPlaceholder"
              className={styles.sliderPlaceholder}
            />
            <span
              data-toggle-section="activeText"
              className={
                css`
                  position: ${activeText.length <= inactiveText.length ?
                  'absolute' : 'relative'};
                  opacity: ${isChecked ? 1 : 0};
                `
              }
              data-qa-selector="active_text"
            >
              {props.activeText}
            </span>
            <span
              data-toggle-section="inactiveText"
              className={
                css`
                  position: ${inactiveText.length < activeText.length ?
                  'absolute' : 'relative'};
                  opacity: ${isChecked ? 0 : 1};
                `
              }
              data-qa-selector="inactive_text"
            >
              {props.inactiveText}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const useToggle = (initialState) => {
  const [isOn, setToggle] = useState(!!initialState);
  const toggle = (bool) => setToggle(!bool);
  return [isOn, toggle];
};

ToggleSwitch.propTypes = propTypes;
ToggleSwitch.defaultProps = defaultProps;

export default ToggleSwitch;
