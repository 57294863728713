import APIFetcher from '../APIFetcher';

class StudentApi {
  static getById(studentId) {
    return APIFetcher.get(`student/${studentId}/`);
  }

  static createStudent(payload) {
    return APIFetcher.post('student/', {payload});
  }
}

export default StudentApi;
