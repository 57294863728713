import {constants, typographyStyles} from '@newsela/angelou';

const coreBlue500 = constants.colors.ui.core[500];
const text2Obj = typographyStyles.textMObj;

export const generateEditorCss = ({readOnly}) => ({
  // Bump specificty with && to outcompete react-rte defaults.
  '&&': [
    text2Obj,
    {
      boxSizing: 'border-box',
      cursor: 'text',
      marginBottom: '2em',
      width: '66.666%',
      '.public-DraftEditor-content': {
        minHeight: '110px',
      },
      '.DraftEditor-editorContainer': {
        zIndex: 0
      },
      'a, a:hover': {
        color: coreBlue500,
        textDecoration: 'underline',
      }
    },
    readOnly && {
      border: 'none',
      backgroundColor: 'transparent',
      '.public-DraftEditor-content': {
        minHeight: 'auto',
        padding: 0,
      },
      '.public-DraftStyleDefault-listLTR:before': {
        paddingLeft: '12px',
        width: 'auto',
      },
      '.public-DraftStyleDefault-orderedListItem': {
        paddingLeft: '5px',
      }
    }
  ]
});
