import React from 'react';

import PropTypes from 'prop-types';

import styles from './styles.scss';

const propTypes = {
  ariaLabel: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  label: PropTypes.string,
  labelIfChecked: PropTypes.string,
  noCircle: PropTypes.bool,
  className: PropTypes.string,
};

class ToggleButton extends React.PureComponent {
  onClick = (event) => {
    this.props.onClick();
    event.stopPropagation();
  }

  render() {
    return (
      <div
        aria-labelledby={this.props.ariaLabel}
        className={`${this.props.checked ? styles.checked : styles.unchecked} ${this.props.className}`}
        onClick={this.onClick}
        role="button"
        title={this.props.ariaLabel}
      >
        <span className="fa fa-stack fa-lg">
          {!this.props.noCircle && <i className={`fa fa-stack-2x ${styles.circle}`} />}
          <i className={`fa fa-${this.props.icon} fa-stack-1x ${styles.buttonIcon}`} />
        </span>
        {this.props.label &&
          <p>{this.props.checked ? this.props.labelIfChecked : this.props.label}</p>}
      </div>
    );
  }
}

ToggleButton.propTypes = propTypes;
export default ToggleButton;
