/**
* Accessible colors.
* Ensure that this is always consistent with ./components/colors/colors.scss
**/

const uiColors = {
  // UI (THEME) COLORS - index 0 is LIGHT, index 1 is DARK
  dark_blue: ['#031E3E', '#031E3E'],
  grey_dark: ['#333333', '#333333'],
  grey_medium: ['#767676', '#878787'],
  grey_light: ['#959595', '#BCBCBE'],
  core_blue: ['#106FF3', '#2C83FB'],
  light_blue: ['#069bf4', '#28ABFA'],
  red: ['#D93F31', '#FF5E4F'],
  green: ['#52A821', '#52A821'],
  orange: ['#E76A0E', '#FE971E'],
  yellow: ['#FEDD1E', '#FEDD1E'],
  grey_extra_light: ['#F8F8F8', '#F8F8F8'],
  white: ['#FFFFFF', '#FFFFFF'],
  black: ['#000000', '#000000'],
  dark_green: ['#3A870F'],
};

const statusColors = {
  // STATUS COLORS - index 0 is LIGHT, index 1 is DARK
  primary: uiColors.core_blue,
  secondary: uiColors.grey_medium,
  info: uiColors.light_blue,
  success: uiColors.green,
  danger: uiColors.red,
  warning: uiColors.orange,
};

const brandColors = {
  // BRAND COLORS
  brand_primary: '#0768fd',
  brand_secondary: '#000000',
  brand_tertiary: '#FEDD1E',
};

const functionalColors = {
  // FUNCTION COLORS - index 0 is LIGHT, index 1 is DARK
  link: uiColors.core_blue,
  link_hover: uiColors.light_blue,

  drop_down_text: uiColors.grey_dark,
  drop_down_background: uiColors.white,
  drop_down_hover_text: uiColors.white,
  drop_down_hover_background: uiColors.light_blue,

  veil_background: uiColors.dark_blue,
  veil_text: uiColors.white,
  veil_highlighted_text: uiColors.yellow,

  // Not indexed like other functional colors.
  reading_skills_good: uiColors.green[0], // Higher than 75.
  reading_skills_fair: uiColors.orange[1], // 50 to 75.
  reading_skills_poor: uiColors.red[0], // Lower than 50.
  reading_skills_none: uiColors.grey_light[1], // No skill level.
};

export default uiColors;
export {
  statusColors,
  functionalColors,
  brandColors,
};
