import {
  includes,
  isString,
  isObject,
  isNil,
  isArray,
  forEach,
  startsWith,
  omitBy
} from 'lodash-es';

import {applyTo, applier} from 'utils/lodashHelpers';

/**
 * Use this class to run Fetch API calls.
 */
class APIFetcher {
  // Check the response for errors and reject.
  static _errorHandler(response) {
    if (!response.ok) {
      const error = new Error();
      error.statusCode = response.status;
      if (includes(response.headers.get('content-type'), 'application/json')) {
        return response.json().then((json) => {
          error.message = json.error || response.statusText;
          error.fields = json;
          return Promise.reject(error);
        });
      } else {
        return response.text().then((text) => {
          error.message = text || response.statusText;
          return Promise.reject(error);
        });
      }
    }
    return response;
  }

  static _fetcher(params) {
    let statusCode = 0;
    return fetch(params.url, params.options)
      .then(this._errorHandler)
      .then((response) => {
        statusCode = response.status;
        if (statusCode != 204) {
          return response.json();
        }
        return Promise.resolve({statusCode});
      })
      .then((result) => {
        result.statusCode = statusCode;
        return Promise.resolve(result);
      });
  }

  static _getDefaultOptions() {
    return {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
      credentials: 'same-origin'
    };
  }

  // Build query parameters from object.
  static stringifyParams(params) {
    if (isString(params)) {
      return new URLSearchParams(params);
    } else if (isObject(params)) {
      const query = new URLSearchParams();
      applyTo(params)(
        applier(omitBy)(isNil),
        applier(forEach)((value, key) => {
          if (!isNil(value)) {
            if (isArray(value)) {
              forEach(value, (subvalue) => query.append(key, subvalue));
            } else {
              query.append(key, value);
            }
          }
        })
      );
      return query;
    }
  }

  static remove(resourceUrl = '', options = {}) {
    const deleteOptions = {...options, method: 'DELETE'};
    return this.request(resourceUrl, deleteOptions);
  }

  static get(resourceUrl = '', options = {}) {
    const getOptions = {...options, method: 'GET'};
    return this.request(resourceUrl, getOptions);
  }

  static post(resourceUrl = '', {options = {}, payload} = {}) {
    /*
     The following check for a request body is not necessary to conform to
     REST specifications. For our purposes, however, it makes little sense to POST
     without data, so throw an error if the post function is missing it.
     */
    if (options.body == null && payload == null) {
      throw Error('You must include a request options body or payload.');
    }

    // Payload passed to this function within the options and/or payload parameter are merged.
    const requestBody = {
      ...options.body,
      ...payload
    };

    const postOptions = {
      ...options,
      method: 'POST',
      body: JSON.stringify(requestBody)
    };

    return this.request(resourceUrl, postOptions);
  }

  // Build and make the API request.
  static request(resourceUrl = '', options = {}) {
    let rootUrl = '';
    if (startsWith(resourceUrl, 'http')) {
      rootUrl = '';
    } else {
      rootUrl = '/api/v2/';
    }

    const context = {
      resourceUrl,
      options
    };

    context.url = rootUrl + context.resourceUrl;

    // Build the Options object by combining default and params.
    context.options = {
      ...this._getDefaultOptions(),
      ...context.options,
    };

    return this._fetcher(context);
  }
}

export default APIFetcher;
