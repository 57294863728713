import * as types from './../constants/articleHeaderActionTypes';

function articleHeader(state = {}, action) {
  switch (action.type) {
    case types.ARTICLE_HEADER_LOAD_REQUEST:
      return {...state, isLoading: true};

    case types.ARTICLE_HEADER_LOAD_SUCCESS: {
      const {articleHeader} = action;
      /**
       * We spread articleHeader onto the returned object, however we need to add some keys specifically because they do not have a default value and we don't want values persisting for all content.
       */
      return {
        ...state,
        isLoading: false,
        ...articleHeader,
        teacher_warning: articleHeader?.teacher_warning,
        teacher_warning_note: articleHeader?.teacher_warning_note,
        veil: articleHeader?.veil,
        quiz_omission_reason: articleHeader?.quiz_omission_reason
      };
    }

    case types.ARTICLE_HEADER_LOAD_FAILURE:
      return {...state, isLoading: false, error: action.error};

    default:
      return state;
  }
}

export default articleHeader;
