import * as types from '../constants/contentRowActionTypes';

function contentRow(state = {}, action) {
  switch (action.type) {
    case types.CONTENT_ROW_LOAD_SUCCESS:
      return {...state, [action.contentRow.slug]: action.contentRow};
    default:
      return state;
  }
}

export {contentRow};
