import {css} from 'emotion';

import {NAVBAR_BREAKPOINTS} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';

export const expandedSearchBarVisibilityCss = css({
  position: 'relative',
  marginLeft: '0.5rem',

  [`@media (max-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
    display: 'none',
  },
});
