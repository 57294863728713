import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.scss';

class Tooltip extends React.PureComponent {
  static propTypes = {
    icon: PropTypes.string,
    tooltipMessage: PropTypes.string,
    direction: PropTypes.oneOf(['left', 'right', 'middle']),
  };

  static defaultProps = {
    icon: 'fa-question-circle',
    direction: 'middle',
  };

  state = {
    showTooltip: false,
    isTooltipInFocus: false,
  };

  componentWillUnmount() {
    clearTimeout(this.closeTimer);
  }

  anchorMouseEnter = () => {
    clearTimeout(this.closeTimer);
    this.setState({
      showTooltip: true,
    });
  };


  anchorMouseLeave = () => {
    this.closeTimer = setTimeout(() => {
      if (!this.state.isTooltipInFocus) {
        this.setState({
          showTooltip: false,
        });
      }
    }, 400);
  };

  tooltipMouseEnter = () => {
    clearTimeout(this.closeTimer);
    this.setState({
      isTooltipInFocus: true,
    });
  };

  tooltipMouseLeave = () => {
    this.setState({
      isTooltipInFocus: false,
      showTooltip: false,
    });
  };

  render() {
    const tooltipClassNames = classNames(
      styles.body,
      styles[this.props.direction],
      {[styles.hidden]: !this.state.showTooltip},
    );
    return (
      <div className={styles.wrapper}>
        <div
          className={styles.icon}
          onMouseOver={this.anchorMouseEnter}
          onMouseOut={this.anchorMouseLeave}
        >
          <i
            className={`fa ${this.props.icon}`}
            data-qa-selector="info_icon"
          />
        </div>
        <div
          className={tooltipClassNames}
          onMouseEnter={this.tooltipMouseEnter}
          onMouseLeave={this.tooltipMouseLeave}
          data-qa-selector="tooltip"
        >
          {this.props.tooltipMessage}
        </div>
      </div>
    );
  }
}

export default Tooltip;
