import * as types from 'modulesV2/constants';

export const clearExternalToolContext = (callback) => ({
  type: types.CLEAR_EXTERNAL_TOOL_CONTEXT,
  callback
});

export const externalToolContextUpdateSuccess = (payload) => ({
  type: types.EXTERNAL_TOOL_UPDATE_SUCCESS,
  payload,
});

export const setExternalToolContext = (payload) => ({
  type: types.SET_EXTERNAL_TOOL_CONTEXT,
  payload,
});

export const requestExternalToolContext = () => ({
  type: types.GET_EXTERNAL_TOOL_CONTEXT,
});
