import {constants} from '@newsela/angelou';

const {ui} = constants.colors;

export const trialButtonCss = {
  root: {
    position: 'relative',
    display: 'inline-flex',
    height: '30px',
    whiteSpace: 'nowrap',
    borderRadius: '8px',
    padding: '8px',
    gap: '8px',
    background: `linear-gradient(92deg, ${ui.purple[600]} 6.55%, ${ui.purple[300]} 115.03%)`,
    color: ui.white[500],
    fontWeight: 400,
    fontSize: '14px !important',
    lineHeight: '14px',
    justifyContent: 'center',
    overflow: 'hidden',
    textDecoration: 'none',

    '&:hover': {
      background: `linear-gradient(92deg, ${ui.purple[600]} 6.55%, ${ui.purple[300]} 115.03%)`,
    },

    '&:hover::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: '#ffffff',
      opacity: 0.08,
      borderRadius: '8px',
    },
  }
};
