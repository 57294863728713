import * as types from 'modulesV2/constants';

export const fetchEventRecords = (name, params = {}) => ({
  type: types.EVENT_RECORD_REQUEST,
  params,
  name,
});

export const fetchEventRecordsSuccess = (name, data) => ({
  type: types.EVENT_RECORD_REQUEST_SUCCESS,
  data,
  name,
});

export const fetchEventRecordsFailure = (name, error) => ({
  type: types.EVENT_RECORD_REQUEST_FAILURE,
  error,
  name,
});

export const createEventRecord = (name, data) => ({
  type: types.EVENT_RECORD_CREATE,
  data,
  name,
});
