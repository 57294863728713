import APIFetcher from '../APIFetcher';

class ContentRowApi {
  static get(slug = '') {
    let url = 'content-row';
    if (slug) {
      url += `/${slug}`;
    }
    url += '?include_filters=true';
    return APIFetcher.get(url);
  }
}

export default ContentRowApi;
