import * as types from '../constants/studentAssignmentViewTypes';

export function studentAssignmentViewLoadRequest(queryString) {
  return {
    type: types.STUDENT_ASSIGNMENT_VIEW_LOAD_REQUEST,
    queryString,
    receivedAt: Date.now(),
  };
}

export function studentAssignmentViewLoadSuccess(data) {
  return {
    type: types.STUDENT_ASSIGNMENT_VIEW_LOAD_SUCCESS,
    studentAssignmentViews: data,
    receivedAt: Date.now()
  };
}

export function studentAssignmentViewLoadFailure(error) {
  return {
    error,
    type: types.STUDENT_ASSIGNMENT_VIEW_LOAD_FAILURE,
    studentAssignmentViews: [],
    receivedAt: Date.now()
  };
}
