export const CLASSROOMS_LOAD_REQUEST = 'CLASSROOMS_LOAD_REQUEST';
export const CLASSROOMS_LOAD_SUCCESS = 'CLASSROOMS_LOAD_SUCCESS';
export const CLASSROOMS_LOAD_FAILURE = 'CLASSROOMS_LOAD_FAILURE';
export const CLASSROOMS_UNLOAD = 'CLASSROOMS_UNLOAD';
export const CLASSROOM_POLLING_START = 'CLASSROOM_POLLING_START';

export const CLASSROOM_LOAD_REQUEST = 'CLASSROOM_LOAD_REQUEST';
export const CLASSROOM_LOAD_SUCCESS = 'CLASSROOM_LOAD_SUCCESS';
export const CLASSROOM_LOAD_FAILURE = 'CLASSROOM_LOAD_FAILURE';

export const CLASSROOM_POST_REQUEST = 'CLASSROOM_POST_REQUEST';
export const CLASSROOM_POST_SUCCESS = 'CLASSROOM_POST_SUCCESS';
export const CLASSROOM_POST_FAILURE = 'CLASSROOM_POST_FAILURE';

export const CLASSROOM_JOIN_REQUEST = 'CLASSROOM_JOIN_REQUEST';
export const CLASSROOM_JOIN_SUCCESS = 'CLASSROOM_JOIN_SUCCESS';
export const CLASSROOM_JOIN_FAILURE = 'CLASSROOM_JOIN_FAILURE';
