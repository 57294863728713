import {typographyStyles} from '@newsela/angelou';
import {css, cx} from 'emotion';


import {brandColors} from 'components/Colors/colors';

/**
 * use object styles here for easier importing in
 * menuLabelItem
 */
const hoverColor = '#E7F1FF';
export const menuItemCss = {
  ':hover': {
    backgroundColor: hoverColor,
  },
  ':focus': {
    backgroundColor: hoverColor,
  },
  padding: '8px 32px 8px 16px',
  width: '100%',
  boxSizing: 'border-box',
  fontFamily: 'Circular',
  height: 'auto',
};
export const menuItemLinkCss = {
  textDecoration: 'none',
  color: 'black',
  ':hover': {
    textDecoration: 'underline',
  },
  ':focus': {
    textDecoration: 'underline'
  }
};
export const menuItemSelectedCss = {
  color: '#fff !important', // This overrides the specificity of Material UI's menu-item styling.
  backgroundColor: `${brandColors.brand_primary} !important`, // This overrides the specificity of Material UI's menu-item styling.
  '*': {
    color: '#fff !important', // Overrides any other color, namely (at time of writing) this: https://github.com/newsela/newsela/blob/1b40520cbe/newsela/static/three-oh/src/components/SubjectContextSwitcher/styles.scss#L44
  }
};

export const menuItemLinkStyle = css({
  '&&': {
    '> *': menuItemLinkCss,
    ...menuItemLinkCss
  }
});

export const menuItemStyle = cx([
  css(typographyStyles.heading5Obj),
  css({
    '&&': menuItemCss,
  })
]);

export const menuItemSelectedStyle = css(menuItemSelectedCss);
