import {inRange, isFinite, isNil} from 'lodash-es';

import {MIN_GRADE_LEVEL, MAX_GRADE_LEVEL} from 'constants/gradeLevels';


export const getGradeLabel = (gradeNumber) => {
  if (!isFinite(gradeNumber)) {
    return 'Unknown';
  } else if (gradeNumber === 0) {
    return 'KG';
  } else {
    return gradeNumber.toString();
  }
};

export const getGradeNumber = (grade) => {
  if (isNil(grade)) {
    return null;
  } else if (grade == 'K' || grade == 'KG') {
    return 0;
  } else {
    const n = Number(grade);
    if (inRange(n, MIN_GRADE_LEVEL, MAX_GRADE_LEVEL)) {
      return n;
    }
    return null;
  }
};
