import {map} from 'lodash-es';

import * as types from './../constants/searchActionTypes';
import {convertToArticle} from '../utils/searchApiHelpers';

export function requestSearch(queryString, slug) {
  return {type: types.SEARCH_LOAD_REQUEST, queryString, slug};
}

export function requestSearchSuccess(data, slug) {
  return {
    type: types.SEARCH_LOAD_SUCCESS,
    searchResults: map(data.results, convertToArticle),
    slug,
    receivedAt: Date.now()
  };
}

export function requestSearchFailure(error, slug) {
  return {
    error,
    slug,
    type: types.SEARCH_LOAD_FAILURE,
    searchResults: [],
    receivedAt: Date.now()
  };
}
