// Global Notifications Action Types. Not to be confused with
// namespaced 'activityTabActionTypes'.
export const INITIALIZE_NOTIFICATIONS = 'INITIALIZE_NOTIFICATIONS';

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

export const UPDATE_STUDENT_WRITE_NOTIFICATION = 'UPDATE_STUDENT_WRITE_NOTIFICATION';
export const MARK_NOTIFICATIONS_AS_READ = 'MARK_NOTIFICATIONS_AS_READ';
