import {Badge} from '@newsela/angelou';

export const getBadges = (props, isLiteUser, isStudent) => {
  const badges = [];
  const visibilityMarkers = props?.visibility_markers ?? [];
  let advisoryBadgeApplied = false;

  for (const visibilityMarker of visibilityMarkers) {
    switch (visibilityMarker.reason) {
      case 'DISTRICT_ADVISORY':
      case 'SCHOOL_ADVISORY':
        if (!advisoryBadgeApplied) {
          if (visibilityMarker.flagged_at_my_admin_level) {
            badges.push({
              color: Badge.color.yellow,
              flavor: Badge.flavor.solid,
              text: 'Flagged'
            });
          } else {
            badges.push({
              color: Badge.color.yellow,
              flavor: Badge.flavor.solid,
              text: 'Advisory'
            });
          }
          // It is possible to have multiple advisory visibility markers, but we only want one badge applied.
          advisoryBadgeApplied = true;
        }
        break;
      case 'LITE_CONTENT':
        if (isLiteUser) {
          const expirationDate = visibilityMarker.date_lite_product_grant_expires;
          const isExpiredDate = new Date() > new Date(expirationDate);
          if (!!expirationDate && !isExpiredDate) {
            badges.push({
              flavor: Badge.flavor.flat,
              text: 'Read until ' + new Date(expirationDate).toLocaleDateString()
            });
          }
        }
        break;
      case 'PREMIUM_CONTENT':
        if (isLiteUser && !isStudent) {
          badges.push({
            flavor: Badge.flavor.solid,
            text: 'Premium'
          });
        }
    }
  }

  // This checks for is_lite property in the v3 content and content document APIs
  if (isLiteUser && visibilityMarkers?.length === 0) {
    const isLiteContent = props.is_lite;
    const expirationDate = props.date_lite_product_grant_expires;
    const isExpiredDate = new Date() > new Date(expirationDate);
    if (isLiteContent) {
      if (!!expirationDate && !isExpiredDate) {
        badges.push({
          flavor: Badge.flavor.flat,
          text: 'Read until ' + new Date(expirationDate).toLocaleDateString()
        });
      }
    } else if (!isStudent) {
      badges.push({flavor: Badge.flavor.solid, text: 'Premium'});
    }
  }
  return badges;
};
