import {combineReducers} from 'redux';

import activeArticlesActivities from './activeArticlesActivities';
import context from './contextReducers';
import quiz from './quizReducers';
import readPageMode from './readPageMode';

/**
 * The purpose of the Read reducer is to centralize all configurations
 * related to the Read Page in one place.
 */
export default combineReducers({
  activeArticlesActivities,
  quiz,
  readPageMode,
  context,
});
