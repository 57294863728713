import * as types from './../constants/searchActionTypes';

function search(state = {}, action) {
  switch (action.type) {
    case types.SEARCH_LOAD_SUCCESS:
      return {...state, [action.slug]: action.searchResults};
    default:
      return state;
  }
}

export default search;
