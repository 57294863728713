import React from 'react';

import DOMPurify from 'dompurify';
import {
  get,
} from 'lodash-es';
import PropTypes from 'prop-types';

import {eventStream, getEventNameForEventStream} from 'static/three-oh/utils/sessionActivity';

/*
 * TrackedRawHtmlSection intercepts clicks to figure out if the target was
 * an anchor tag. If so, it will open that link in a new tab and
 * track the click.
*/
class TrackedRawHtmlSection extends React.PureComponent {
  static propTypes = {
    currentUserId: PropTypes.number,
    eventName: PropTypes.string,
    // eventProperties can contain whatever other information is needed for tracking.
    // As of now, it is used for Lesson Sparks and Text Sets, so for those usages,
    // eventProperties currently comes in holding IDs of lesson sparks or textsets.
    eventProperties: PropTypes.object,
    html: PropTypes.string
  };

  handleClick = (event) => {
    let target = event.target;
    // If this target is an anchor tag, prevent the default and open link in a new tab.
    // If this target is not an anchor tag, check to see if a parent might be.
    // Don't bother to check parents if they are outside the bounds of this component.
    while (target) {
      if (target instanceof HTMLAnchorElement && target.href) {
        event.preventDefault();
        this.trackLinkClick(target);
        window.open(target.href);
        break;
      }
      target = target.parentNode;
      if (target === this.componentBoundary) {
        break;
      }
    }
  }

  trackLinkClick = (target) => {
    const properties = this.props.eventProperties || {};
    properties['destination_url'] = target.href;
    properties['link_text'] = target.textContext;
    try {
      const newEventProps = {
        event_name: getEventNameForEventStream('Content', 'Initiate', 'Navigate'),
        user_id: this.props.currentUserId,
        dimension_properties: {
          description: this.props.eventName,
          legacy_event_name: this.props.eventName,
          search_id: get(properties, 'search_id'),
        }
      };
      eventStream(newEventProps, {
        userId: this.props.currentUserId,
        name: this.props.eventName,
        type: 'click',
        properties
      });
    } catch (e) {
      // noop
    }
  }

  setRef = (node) => {
    this.componentBoundary = node;
  }

  render() {
    const {html, ...otherProps} = this.props;
    if (!html) {
      return null;
    }
    const sanitizedHtml = DOMPurify.sanitize(html);
    const danger = {
      __html: sanitizedHtml
    };

    // remove props that should not get passed to the DOM element (i.e. the div) directly
    delete otherProps.currentUserId;
    delete otherProps.eventName;
    delete otherProps.eventProperties;

    return (
      <div
        ref={this.setRef}
        onClick={this.handleClick}
        dangerouslySetInnerHTML={danger} // eslint-disable-line react/no-danger
        {...otherProps}
      />
    );
  }
}

export default TrackedRawHtmlSection;
