import React, {useMemo, useState} from 'react';

import {Button, ChevronDownSVG, ChevronUpSVG, Icon} from '@newsela/angelou';
import {useSelector} from 'react-redux';

import {
  getHomeMenuButtonCss
  , homeMenuVisibilityContainerCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/HomeMenu/HomeMenu.styles';
import {
  HomeMenuPopout
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/HomeMenu/HomeMenuPopout';
import {
  NavButtonWithPopout
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/NavButtonWithPopout';

import {getUser} from '../../../../../modules/selectors/userSelectors';
import {getNavMenuButtonAriaProps} from '../shared/NavButtonWithPopout/NavButtonWithPopout.utils';

const MENU_ID = 'nav-home-menu';

const HomeMenu = () => {
  const user = useSelector((state) => getUser(state));

  const [isOpen, setIsOpen] = useState(false);

  const buttonAriaProps = useMemo(
    () => getNavMenuButtonAriaProps({
      isOpen,
      id: MENU_ID,
    }), [isOpen]);

  if (!user?.is_authenticated) return null;

  return (
    <NavButtonWithPopout
      id={MENU_ID}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      position={{right: '0.5rem', top: '3.25rem'}}
      rootCustomCss={homeMenuVisibilityContainerCss}
      ButtonComponent={(
        <Button
          flavor={Button.flavors.incognito}
          onClick={() => setIsOpen(!isOpen)}
          __cssFor={getHomeMenuButtonCss(isOpen)}
          ariaProps={buttonAriaProps}
          dataProps={{'data-qa-selector': 'global-nav-home-menu'}}
        >
          Menu

          {isOpen ? (
            <Icon SvgComponent={ChevronUpSVG} />
          ) : (
            <Icon SvgComponent={ChevronDownSVG} />
          )}
        </Button>
      )}
      PopoutComponent={<HomeMenuPopout />}
    />
  );
};

HomeMenu.displayName = 'HomeMenu';
export {HomeMenu};
