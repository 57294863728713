import ApiFetcher2 from '../ApiFetcher2';

const SYNC_URL = 'roster-sync/';
const CLASSROOMS_URL = 'roster-classroom/';

class RosterApi {
  /**
   * Fetch latest RosterSync entity by given id
   * @returns {Promise}
   */
  static getById(id) {
    const config = {};
    return ApiFetcher2.get(`${SYNC_URL}${id}`, {config});
  }

  static getAll() {
    return ApiFetcher2.get(SYNC_URL);
  }

  static markAsSeen(id) {
    return ApiFetcher2.put(SYNC_URL, {data: id});
  }

  /**
   * Create a new entry in the RosterSync table that will be used to
   * track sync progress.
   * @param {Object} authProvider
   * @param {Object} [params]
   * @returns {Promise}
   */
  static sync(authProvider, params = {}) {
    if (!authProvider) {
      throw new Error('Cannot request classrooms without a valid authProvider');
    }
    const data = {provider: authProvider};
    if (params.classrooms) {
      data.classrooms = params.classrooms;
    }
    return ApiFetcher2.post(SYNC_URL, {data});
  }

  /**
   * Fetches a list of roster classrooms for a given authProvider
   * @param {String} authProvider
   * @returns {Promise}
   */
  static getClassrooms(authProvider) {
    if (!authProvider) {
      throw new Error('Cannot request classrooms without a valid authProvider');
    }
    return ApiFetcher2.get(CLASSROOMS_URL, {params: {provider: authProvider}});
  }

  /**
   * @param {String} provider
   * @returns {Promise}
   */
  static getRosterDetails(provider) {
    const url = `${SYNC_URL}provider/${provider}/`;
    return ApiFetcher2.get(url);
  }
}

export default RosterApi;
