import {constants} from '@newsela/angelou';
import {css} from 'emotion';

import {
  NAVBAR_BREAKPOINTS,
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';

export const homeMenuVisibilityContainerCss = {
  [`@media (min-width: ${NAVBAR_BREAKPOINTS.md}px)`]: {
    display: 'none',
  }
};

export const getHomeMenuButtonCss = (isOpen) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0.25rem 0.5rem',
    gap: '0.5rem',
    color: isOpen
      ? constants.colors.ui.core[700]
      : constants.colors.ui.neutral[600],
    fontWeight: isOpen ? 700 : 400,

    ':hover': {
      color: constants.colors.ui.core[700],
    },

    svg: {
      width: '1rem',
      height: '1rem',

      path: {
        strokeWidth: isOpen
          ? '4px'
          : '2px'
      }
    },
  }
});

export const homeMenuSubItemPopoutContainerCss = css({
  display: 'flex',
  maxHeight: '500px',
  overflowY: 'scroll',
  flexDirection: 'column',
  padding: '1rem 0 1.25rem 0',
  background: constants.colors.ui.white[50],
  borderRadius: '0.5rem',
  gap: '1rem',
  boxShadow: '0px 2px 6px 0px rgba(29, 29, 29, 0.16)',
});

export const backToMenuAnchorCss = {
  root: {
    textDecoration: 'none',
    padding: '0 1rem',
    color: constants.colors.ui.core[700],
    display: 'flex',
    flexDirection: 'row',
    gap: '0.25rem',

    [`@media (min-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
      display: 'none',
    },
  },
};

export const backToMenuIconCss = {
  root: {
    width: '1rem',
    height: '1rem',

    path: {
      strokeWidth: '0.25rem',
      stroke: constants.colors.ui.core[700],
    }
  }
};
