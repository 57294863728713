import moment from 'moment';
import {takeEvery, call, put} from 'redux-saga/effects';

import {
  fetchEventRecordsSuccess,
  fetchEventRecordsFailure,
  fetchEventRecords as fetchEventRecordsRequest,
} from 'modulesV2/actions';
import * as types from 'modulesV2/constants';
import EventRecordApi from 'static/three-oh/src/modules/apis/EventRecord/EventRecordApi';

/**
 * Fetch list of event records
 * @returns {Generator}
 */
function * fetchEventRecords(action) {
  try {
    const params = action.params || {};
    const response = yield call(EventRecordApi.get, {
      ...params,
      event_name: action.name,
    });
    yield put(fetchEventRecordsSuccess(action.name, response.data));
  } catch (error) {
    yield put(fetchEventRecordsFailure(action.name, error));
  }
}


function * createEventRecord(action, retryCount = 1) {
  try {
    yield call(EventRecordApi.post, action.data);
    // Update store with newly created event.
    yield put(fetchEventRecordsSuccess(action.name, [{
      ...action.data,
      event_name: action.name,
      date_created: moment(),
    }]));
    // Refresh snapshot of event records.
    yield put(fetchEventRecordsRequest(action.name));
  } catch (error) {
    yield new Promise((resolve) => setTimeout(resolve, 2 ** retryCount * 100));
    if (retryCount < 50) yield createEventRecord(action, retryCount + 1);
    else yield put(fetchEventRecordsFailure(action.name, error));
  }
}

// --- Watchers ---- //
export function * watchFetchEventRecords() {
  yield takeEvery(types.EVENT_RECORD_REQUEST, fetchEventRecords);
}

export function * watchCreateEventRecord() {
  yield takeEvery(types.EVENT_RECORD_CREATE, createEventRecord);
}
