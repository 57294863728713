import * as types from 'modulesV2/constants/';

const initialState = {};

export default function context(state = initialState, action) {
  switch (action.type) {
    case types.SET_CONTEXT: {
      return {
        ...action.data
      };
    }

    default:
      return state;
  }
}
