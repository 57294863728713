import moment from 'moment';

import {ACTIVE_TRIAL_LICENSES} from 'static/three-oh/src/modules/constants/trialLicenseCampaigns';

const getFarthestExpirationLicenseData = (licenses) => {
  if (licenses.length === 0) return {active: false};

  const sortedActiveExpirationDates = licenses
    .filter((license) => moment(license.date_ends).isAfter(moment()))
    .map((license) => moment(license.date_ends))
    .sort((endDateA, endDateB) => {
      if (endDateA.isAfter(endDateB)) {
        return -1;
      }
      return 1;
    });

  const farthestExpirationDate = sortedActiveExpirationDates[0];
  if (farthestExpirationDate) {
    const daysBetweenNowAndClosestExpirationDate = farthestExpirationDate.startOf('day').diff(moment().startOf('day'), 'days');
    const hoursBetweenNowAndClosestExpirationDate = farthestExpirationDate.startOf('day').diff(moment().startOf('day'), 'hours');
    return {
      active: daysBetweenNowAndClosestExpirationDate >= 0,
      daysToExpiration: daysBetweenNowAndClosestExpirationDate,
      hoursToExpiration: hoursBetweenNowAndClosestExpirationDate
    };
  }

  return {active: false};
};

const getTrialLicenseFarthestExpirationDate = (licenses) => {
  const pocLicenses = licenses.filter(({type}) => type === 'school_poc');

  if (pocLicenses.length > 0) {
    return {...getFarthestExpirationLicenseData(pocLicenses), isSchoolTrial: true};
  }

  return {
    ...getFarthestExpirationLicenseData(licenses.filter(({campaign}) => ACTIVE_TRIAL_LICENSES.includes(campaign))),
    isSchoolTrial: false
  };
};

export default getTrialLicenseFarthestExpirationDate;
