/**
 * fonts.js: Reusable font-family constants.
 *
 * This should be 1 to 1 with /src/styles/font_variables.scss.
 * If you make a change here, also make it there.
 */

const foco = {
  normal: 'Foco-Regular, sans-serif',
  bold: 'Foco-Bold, sans-serif',
};

const helvetica = {
  normal: '"Segoe UI", "Helvetica Neue", "Helvetica", Roboto, Arial, sans-serif',
  bold: '"Segoe UI", "HelveticaNeue-Bold", "Helvetica Neue Bold", Helvetica, Roboto, Arial, sans-serif',
};

const roboto = {
  normal: 'Roboto, sans-serif',
  bold: 'Roboto-Bold, sans-serif',
};

const circular = {
  normal: 'Circular, Helvetica, Arial, sans-serif',
};

export const sizes = {
  xxl: '2.369rem', // ~38px
  xl: '1.777rem', // ~28px
  l: '1.33rem', // ~21px
  m: '1rem', // 16px
  s: '.875rem', // ~16px
  xs: '.75rem', // ~12px
  base: '16px' // Default browser text size
};

const fonts = {
  foco,
  helvetica,
  roboto,
  circular,
  sizes
};

export default fonts;
