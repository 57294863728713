import * as types from '../constants/studiesActionTypes';

const initialState = {
  isLoading: true,
  studies: [],
};

function studies(state = initialState, action) {
  switch (action.type) {
    case types.STUDIES_SUCCESS:
      return {...state, isLoading: false, studies: action.studies.data};
    case types.STUDIES_FAILURE:
      return {...state, isLoading: false};
    default:
      return state;
  }
}

export default studies;
