import {
  get,
  map,
  find,
  defaultsDeep,
  range,
  sumBy,
  isFinite,
  round,
  flatMap,
  compact,
  uniqBy,
  pick,
  values,
} from 'lodash-es';
import {createSelector} from 'reselect';

import {CC_ANCHOR_STANDARDS} from 'static/three-oh/src/modules/constants/standards';
import {classroomSelectors} from 'static/three-oh/src/modules/selectors';
import {applyTo, applier} from 'utils/lodashHelpers';

import {getReadingLevelConfidence} from './binderSelectors';

// binderStudents and binderStandards selectors
export const getBinderStudentListData = (state) => get(state, 'binderStudents.studentList.items', []);

export const getBinderStudentReadingLevels = (state) => get(state, 'binderStudents.studentReadingLevels.item', {});
export const getBinderStudentLoadedReadingLevels = (state) => get(state, 'binderStudents.studentReadingLevels.loadedItems', {});

const _getStudentsViewModel = (binderStudents, studentsByClassroom, studentReadingLevels, classroomFilter) => {
  const measureDefaults = {
    'avg:grade_level': null,
    'count:header_views': null,
    'sum:reading_time': null,
    'avg:quiz_score': null,
    'count:quizzes': null,
    'avg:write_response_score': null,
    'count:write_responses': null,
    'count:annotations': null,
    'count:vocabulary_questions_correct': null,
    'count:vocabulary_questions': null,
  };

  const students = applyTo(classroomFilter.value)(
    applier(flatMap)((classroomId) => studentsByClassroom[classroomId]),
    applier(compact)(),
    applier(uniqBy)('id')
  );

  if (students.length > 0 && Object.keys(studentReadingLevels).length !== 0) {
    students.forEach((student) => {
      const foundStudentReadingLevelInformation = find(studentReadingLevels, ['user_id', student.unique_id]);

      if (foundStudentReadingLevelInformation) {
        student.current_reading_level = {
          ...foundStudentReadingLevelInformation
        };
      }
    });
  }


  const getReadingLevelValue = (studentReadingLevelData) => {
    const readingLevelValue = studentReadingLevelData?.source == 'nwea'
      ? studentReadingLevelData?.value
      : studentReadingLevelData?.type === 'quiz_based'
        ? studentReadingLevelData.value : '';
    return readingLevelValue;
  };
  const model = map(students, (student) => {
    const studentData = {
      student,
      student_id: student.id,
      name: `${student.user.last_name}, ${student.user.first_name}`,
      currentReadingLevel: getReadingLevelValue(student.current_reading_level),
      currentReadingLevelConfidence: getReadingLevelConfidence(student.current_reading_level)
    };

    const studentReportingResult = find(binderStudents, ['student_id', student.unique_id]);
    defaultsDeep(studentData, studentReportingResult, measureDefaults);
    if (studentData['count:header_views'] > 0) {
      studentData['annotationsPerHeader'] = (studentData['count:annotations'] / studentData['count:header_views']);
      studentData['readingTimePerHeader'] = (studentData['sum:reading_time'] / studentData['count:header_views']);
    } else {
      studentData['annotationsPerHeader'] = null;
      studentData['readingTimePerHeader'] = null;
    }

    if (studentData['count:vocabulary_questions_correct'] !== null && studentData['count:vocabulary_questions'] > 0) {
      studentData['avg:vocabulary_questions_correct'] = (studentData['count:vocabulary_questions_correct'] / studentData['count:vocabulary_questions']);
    } else {
      studentData['avg:vocabulary_questions_correct'] = null;
    }
    return studentData;
  });

  return model;
};

export const getStudentsViewModel = createSelector(
  (state) => getBinderStudentListData(state),
  (state) => classroomSelectors.getStudentsByClassroom(state),
  (state) => getBinderStudentReadingLevels(state),
  (state, binderFilters) => binderFilters.classroom,
  (binderStudents, studentsByClassroom, studentReadingLevels, classroomFilter) => {
    return _getStudentsViewModel(binderStudents, studentsByClassroom, studentReadingLevels, classroomFilter);
  }
);

export const isLoadingStudents = (state) => {
  return get(state, 'binderStudents.studentList.isLoading', true);
};

export const isErrorStudents = (state) => {
  return get(state, 'binderStudents.studentList.isError', true);
};

export const getFiltersAppliedToStudents = (state) => get(state, 'binderStudents.studentList.filters', {});

export const getBinderStandards = (state) => get(state, 'binderStandards.items', []);

export const isLoadingStandards = (state) => {
  return get(state, 'binderStandards.isLoading', true);
};

export const isErrorStandards = (state) => {
  return get(state, 'binderStandards.isError', true);
};

export const getFiltersAppliedToStandards = (state) => get(state, 'binderStandards.filters', {});

const _getStandardsViewModel = (binderStandardsByStudent, studentsByClassroom, classroomFilter) => {
  if (binderStandardsByStudent.length === 0) {
    return [];
  }
  const measureDefaults = {};

  range(1, 9).forEach((i) => {
    measureDefaults[`anchor_standard_${i}`] = null;
  });

  const selectedStandards = CC_ANCHOR_STANDARDS;

  const students = applyTo(classroomFilter.value)(
    applier(flatMap)((classroomId) => studentsByClassroom[classroomId]),
    applier(compact)(),
    applier(uniqBy)('id')
  );

  const model = map(students, (student) => {
    const studentData = {
      student,
      student_id: student.id,
      name: `${student.user.last_name}, ${student.user.first_name}`,
    };
    const studentBinderReportingResult = {anchor_standards: binderStandardsByStudent[student.unique_id]};

    const standardDefaults = {
      anchor_standards: {},
    };

    range(1, 9).forEach((i) => {
      standardDefaults.anchor_standards[i] = {
        numCorrect: null,
        numIncorrect: null,
      };
    });

    defaultsDeep(studentBinderReportingResult, standardDefaults);

    /**
   * Calculate the score average.
   * The score is based on quiz questions that have that reading skill attached. For example, a student who
   * has answered 6 quiz questions for skill 1 and answered 4 of them right has a score of 4/6=66.6%
   * If a student has answered no questions for a skill, the score will be null
   * If a student has answered all questions for a skill incorrectly, the score will be 0

   */
    const scores = map(selectedStandards, (standard) => {
      const masteryTally = applyTo(studentBinderReportingResult.anchor_standards)(
        applier(pick)(standard.number),
        applier(values)()
      );
      const totalCorrectQuestions = sumBy(masteryTally, 'numCorrect') || 0;
      const totalQuestions = sumBy(masteryTally, (tally) => tally.numCorrect + tally.numIncorrect);
      let score = null;
      if (isFinite(totalCorrectQuestions) && totalQuestions > 0) {
        score = round(totalCorrectQuestions / totalQuestions * 100, 1);
      }
      return score;
    });

    map(scores, (score, index) => {
      measureDefaults[`anchor_standard_${index + 1}`] = score;
    });

    defaultsDeep(studentData, measureDefaults);
    return studentData;
  });

  return model;
};

export const getStandardsViewModel = createSelector(
  (state) => getBinderStandards(state),
  (state) => classroomSelectors.getStudentsByClassroom(state),
  (state, binderFilters) => binderFilters.classroom,
  (binderStandardsByStudent, studentsByClassroom, classroomFilter) => {
    return _getStandardsViewModel(binderStandardsByStudent, studentsByClassroom, classroomFilter);
  }
);
