import * as types from '../constants/contentRowActionTypes';

export function requestContentRow(slug) {
  return {
    type: types.CONTENT_ROW_REQUEST,
    slug
  };
}

export function requestContentRowSuccess(data) {
  return {
    type: types.CONTENT_ROW_LOAD_SUCCESS,
    contentRow: data,
    receivedAt: Date.now()
  };
}

export function requestContentRowFailure(error) {
  return {
    type: types.CONTENT_ROW_LOAD_FAILURE,
    error
  };
}
