import APIFetcher from '../APIFetcher';

class TeacherApi {
  static getTeacher(teacherId) {
    return APIFetcher.get(`teacher/${teacherId}`);
  }

  static getTeacherSubjects(teacherId) {
    return APIFetcher.get(`teacher-subjects?teacher_id=${teacherId}`);
  }

  static getTeacherLanguages(teacherId) {
    return APIFetcher.get(`teacher-languages?teacher_id=${teacherId}`);
  }

  static postTeacherSubjects(subjects) {
    const body = {subjects: subjects};
    return APIFetcher.post('teacher-subjects/', {payload: body});
  }

  static createTeacher(payload) {
    return APIFetcher.post('teacher/', {payload});
  }

  static persistTeacher(payload) {
    const url = `teacher/${payload.id + '/' || ''}`;
    return APIFetcher.post(url, {payload});
  }
}

export default TeacherApi;
