import {get} from 'lodash-es';
import {takeLatest, call, put, select} from 'redux-saga/effects';

import * as actions from 'modulesV2/actions';
import * as types from 'modulesV2/constants';
import * as selectors from 'modulesV2/selectors';
import {RostserChoiceApi} from 'static/three-oh/src/modules/apis';

import RosterChoiceApi from '../../modules/apis/RosterChoiceApi';

function * fetchRosterSelection() {
  try {
    const response = yield call(RostserChoiceApi.get);
    yield put(actions.requestRosterChoiceSuccess(response.data));
  } catch (err) {
    yield put(actions.requestRosterChoiceFailure(err));
  }
}

function * updateRosterSelection(action) {
  const {id, authProvider} = action;
  const currentSelection = yield select(selectors.getUserRosterChoice);
  if (get(currentSelection, 'provider') === authProvider) {
    // Do not make a request if provider actually hasn't changed.
    return;
  }
  try {
    const response = yield call(RostserChoiceApi.update, id, authProvider);
    yield put(actions.requestRosterChoiceSuccess(response.data));
  } catch (err) {
    yield put(actions.requestRosterChoiceFailure(err));
  }
}

function * createRosterChoice(action) {
  try {
    yield call(RosterChoiceApi.create, action.authProvider);
    // Request roister choice to populate Redux store.
    yield call(fetchRosterSelection);
  } catch (err) {
    // pass.
  }
}

function * createRosterChoiceAndSync(action) {
  try {
    yield call(createRosterChoice, action);
    // Automatically sync selected provider
    yield put(actions.requestRosterSync(action.authProvider, action.classrooms));
    const rosterDetails = yield select(selectors.getRosterProviderDetails, action.authProvider);
    const couldActivelyRoster = get(rosterDetails, 'sync_style') === 'on_demand';
    if (couldActivelyRoster) {
      // Automatically sync selected provider but only if it's not passively rostered.
      yield put(actions.requestRosterSync(action.authProvider));
    }
  } catch (err) {
    yield put(actions.requestRosterChoiceFailure(err));
  }
}

export function * watchRequestRosterChoice() {
  yield takeLatest(types.REQUEST_ALL_ROSTER_SYNC_INFO, fetchRosterSelection);
}

export function * watchChangeRosterChoice() {
  yield takeLatest(types.ROSTER_CHOICE_UPDATE, updateRosterSelection);
}

export function * watchCreateRosterChoiceAndSync() {
  yield takeLatest(types.ROSTER_CHOICE_CREATE_AND_SYNC, createRosterChoiceAndSync);
}

export function * watchCreateRosterChoice() {
  yield takeLatest(types.ROSTER_CHOICE_CREATE, createRosterChoice);
}
