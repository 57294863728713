import React from 'react';

import {LoadingSpinner} from '@newsela/angelou';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';

import {signUpRoot} from 'static/three-oh/src/modules/constants/routePaths';

export const SIGN_UP_PATHS = [`${signUpRoot}/:step/:id?`, `${signUpRoot}/:step?`, `/apps/ios${signUpRoot}`];


const LazySignUpComponent = Loadable({
  loader: () => import('./containers/SignUpContainer'),
  loading: LoadingSpinner,
});

const SignUpRoute = [
  <Route
    key="SignUpCatchAll"
    path={SIGN_UP_PATHS}
    component={LazySignUpComponent}
  />,
];

export default SignUpRoute;
