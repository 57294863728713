import {useRef, useEffect} from 'react';

/**
 * `usePrevious` is a react hook that, given some value,
 * will return its "previous" state.
 * Taken from: https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 */
const usePrevious = (value) => {
  // Value comes in, but is not assigned.
  // "Previous state" is initialized as `undefined`.
  const refForPrevious = useRef();
  useEffect(() => {
    // Value is only assigned *after* the current render cycle.
    // Meaning the "previous state" will still be its old
    // value during the current render cycle.
    refForPrevious.current = value;
  }, []);
  // Return "previous state"
  return refForPrevious.current;
};

export default usePrevious;
