import React, {createContext, useRef, useEffect, useContext, useState} from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router';
import useImmediateEffect from 'use-immediate-effect';

const ClientSideRoutingHasOccurredRefContext = createContext({current: {}});

const propTypes = {
  children: PropTypes.node,
};

export const ProviderForUseClientSideRoutingHasOccurred = ({children}) => {
  // Because this component is intended to be rendered at the root of the
  // application, for performance reasons it'd be ideal to re-render it as
  // infrequently as possible. Below, we're doing a lot of ref-based wiring to
  // avoid unnecessary re-renders. In conjuction, the listeners below will get
  // called on the first client side routing event, which enables downstream
  // consumers (i.e. components calling `useClientSideRoutingHasOccurred`) to
  // trigger a local state change. When that happens, each individual consumer
  // will re-render, and `ProviderForUseClientSideRoutingHasOccurred` will not
  // re-render.
  const clientSideRoutingHasOccurredRef = useRef({
    value: false,
    listeners: new Set(),
  });

  const history = useHistory();
  const startingPathnameRef = useRef(history.location.pathname);
  useEffect(() => {
    const unlisten = history.listen(({pathname}) => {
      // If the pathname has not changed from its initial value, do nothing.
      if (pathname === startingPathnameRef.current) {
        return;
      }
      // Otherwise, assign the current value and call all the registered
      // listeners.
      clientSideRoutingHasOccurredRef.current.value = true;
      for (const listener of clientSideRoutingHasOccurredRef.current.listeners) {
        listener();
        // After calling a listener we can remove it, because it's only meant
        // to detect the first client side routing event.
        clientSideRoutingHasOccurredRef.current.listeners.delete(listener);
      }
      unlisten();
    });
    return unlisten;
  }, []);
  return (
    <ClientSideRoutingHasOccurredRefContext.Provider value={clientSideRoutingHasOccurredRef}>
      {children}
    </ClientSideRoutingHasOccurredRefContext.Provider>
  );
};

ProviderForUseClientSideRoutingHasOccurred.propTypes = propTypes;

const useClientSideRoutingHasOccurred = () => {
  // `useClientSideRoutingHasOccurred` expects to be called inside the context
  // of `ProviderForUseClientSideRoutingHasOccurred`. If it is not, it will
  // *always* return `undefined`.
  const clientSideRoutingHasOccurredRef = useContext(ClientSideRoutingHasOccurredRefContext);
  const {current: {value: initialValue, listeners}} = clientSideRoutingHasOccurredRef;
  const [clienSideRoutingHasOccured, setClientSideRoutingHasOccured] = useState(initialValue);

  // Register the listener immediately in case a client side routing event
  // occurs during the initial render.
  useImmediateEffect(() => {
    if (!listeners) return;
    const onFirstClientSideRouting = () => {
      setClientSideRoutingHasOccured(true);
    };
    listeners.add(onFirstClientSideRouting);
    // Make sure to remove the listener if the consuming component unmounts.
    return () => {
      listeners.delete(onFirstClientSideRouting);
    };
  }, []);
  return clienSideRoutingHasOccured;
};

export default useClientSideRoutingHasOccurred;
