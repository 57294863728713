import {map, get, max, reject, isNull, concat, uniqBy} from 'lodash-es';
import lscache from 'lscache';
import moment from 'moment';

/**
 * @param {number} userId
 * @returns {string}
 */
function notificationStorageKey(userId) {
  return `${userId}:notifications:react`;
}

/**
 * @param {number} userId
 * @returns {string}
 */
function dateStreamViewedStorageKey(userId) {
  return `${notificationStorageKey(userId)}:date_read:react`;
}

/**
 * @param {moment} dateLastViewed
 * @param {number} userId
 */
export function setDateStreamViewedToLocalStorage(dateLastViewed, userId) {
  if (!moment.isMoment(dateLastViewed)) {
    dateLastViewed = moment(dateLastViewed);
  }
  const storageKey = dateStreamViewedStorageKey(userId);
  lscache.set(storageKey, dateLastViewed.utc().format());
}

/**
 * @param {number} userId
 * @returns {Object}
 */
export function getNotificationsFromLocalStorage(userId) {
  const storageKey = notificationStorageKey(userId);
  const data = lscache.get(storageKey);
  return data;
}

/**
 * @param {number} userId
 * @param {Array.<Notification>} notifications
 */
export function writeNotificationsToLocalStorage(userId, notifications) {
  const storageKey = notificationStorageKey(userId);
  try {
    lscache.set(storageKey, notifications);
  } catch (e) {
    if (/quota/i.test(e.name)) {
      // eslint-disable-next-line no-console
      console.warn('Notifications exceeded localStorage quota.', notifications.length);
    }
  }
}

/**
 * Find and return the most recent notification create date.
 * @param {number} userId
 * @return {moment}
 */
export function getMostRecentDateCreatedFromLocalStorage(userId) {
  const notifications = getNotificationsFromLocalStorage(userId);
  const dates = map(notifications, (n) => moment(get(n, 'date_created')));
  return max(dates);
}

/**
 * Synchronously update localstorage with new  notification items. Returns
 * list of all the notifications.
 *
 * @param {string|number} userId
 * @param {Array.<Notification>} newNotifications
 * @return {Array.<Notification>}
 */
export function prependNotificationsToLocalStorage(userId, newNotifications = []) {
  let existingNotifications = getNotificationsFromLocalStorage(userId);
  existingNotifications = reject(existingNotifications, isNull);
  const allNotifications = concat(existingNotifications, newNotifications);
  writeNotificationsToLocalStorage(userId, uniqBy(allNotifications, (n) => n.id));
  return allNotifications;
}
