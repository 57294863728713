import APIFetcher from '../APIFetcher';

class StudentVocabularyActivityApi {
  static get(queryParams) {
    const params = APIFetcher.stringifyParams(queryParams);
    return APIFetcher.get(`student-vocabulary-activity/?${params}`);
  }
}

export default StudentVocabularyActivityApi;
