import * as types from '../constants/studentAssignmentViewTypes';

function studentAssignmentViewReducer(state = {}, action) {
  switch (action.type) {
    case types.STUDENT_ASSIGNMENT_VIEW_LOAD_REQUEST:
      return {
        ...state,
        dateRequested: action.receivedAt,
        isLoading: true
      };

    case types.STUDENT_ASSIGNMENT_VIEW_LOAD_SUCCESS:
      return {
        ...state,
        dateLoaded: action.receivedAt,
        isLoading: false,
        studentAssignmentViews: [...action.studentAssignmentViews],
      };

    case types.STUDENT_ASSIGNMENT_VIEW_LOAD_FAILURE:
      return {
        ...state,
        dateLoaded: action.receivedAt,
        isLoading: false,
        error: action.error,
        studentAssignmentViews: [...action.studentAssignmentViews],
      };

    default:
      return state;
  }
}

export default studentAssignmentViewReducer;
