import * as types from '../constants/userStreamTypes';

function userStream(state = {}, action) {
  switch (action.type) {
    case types.USER_STREAM_UPDATE_SUCCESS:
      return {
        ...state,
        is_loading: false,
        date_last_viewed: action.data.date_last_viewed,
      };
    case types.USER_STREAM_LOAD_SUCCESS:
      return {
        ...state,
        date_last_viewed: action.data.date_last_viewed,
        is_loading: false,
      };
    case types.USER_STREAM_LOAD_FAILURE:
      return {
        ...state,
        is_loading: true,
        error: action.error,
      };
    default:
      return state;
  }
}

export default userStream;
