import React from 'react';

import {isEmpty} from 'lodash-es';
import PropTypes from 'prop-types';

import TextEditor from 'components/TextEditor/TextEditor';

import styles from './styles.scss';

const propTypes = {
  externalToolContext: PropTypes.object,
  updateInstructions: PropTypes.func,
  currentInstructions: PropTypes.string,
  toggleInstructionsVeil: PropTypes.func,
  showInstructionsVeil: PropTypes.bool,
  userProperties: PropTypes.object,
  userId: PropTypes.number,
};

/**
 * AssignmentInstructions handles the presentation of the assignment
 * instructions within the TeacherAssignment component. This can alternate
 * between a read-only or rich text editing experience.
 */


const EmptyInstructions = () => (
  <p id="instructions" className={styles.instructionsReadOnly}>
    No instructions added
  </p>
);
class AssignmentInstructions extends React.Component {
  handleClickIntoTextField = () => {
    if (this.props.userProperties.isFreeUser) {
      this.props.toggleInstructionsVeil(true);
    }
  }

  render = () => {
    const {
      currentInstructions,
      externalToolContext,
    } = this.props;
    const instructionsElement = (
      currentInstructions ? (
        <TextEditor
          readOnly
          maxLength={5000}
          name="instructions"
          initialValue={currentInstructions}
          data-qa-selector="assignment_instruction_block"
        />
      ) : <EmptyInstructions />
    );
    return (
      <div className={styles.instructions}>
        <label
          htmlFor="instructions"
          className={styles.instructionsLabel}
        >
          <span>
            {!isEmpty(externalToolContext) ? 'Newsela Instructions' : 'Instructions'}
          </span>
        </label>
        {!isEmpty(externalToolContext) && (
          <p className={styles.externalInstructions}>Students will see these instructions on Newsela only</p>
        )}
        {instructionsElement}
      </div>
    );
  }
}


AssignmentInstructions.propTypes = propTypes;

export default AssignmentInstructions;
