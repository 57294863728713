import {isFinite, round, padStart} from 'lodash-es';
import moment from 'moment';

/**
 * Convert a number of seconds to HH:MM:SS format.
 * Second parameters causes the time to round to that number of seconds.
 * Default rounding is 60 seconds. Set rounding to falsy value to disable.
 * Seconds are hidden if rounding to 60 second increments.
 *
 *   > formatReadingTime(123)
 *   2 min

 *   > formatReadingTime(123, 15)
 *   2:00 min

  *   > formatReadingTime(123456, null)
 *   34:17:36 hr
 */

export function formatReadingTime(seconds, roundTo = 60) {
  if (!isFinite(seconds)) {
    return null;
  }
  if (roundTo) {
    seconds = Math.round(seconds / roundTo) * roundTo;
  }
  if (seconds === 0) {
    return '< 30 sec';
  }

  const duration = moment.duration(round(seconds), 'seconds');
  const hours = Math.floor(duration.asHours());
  let mins = duration.minutes();
  let secs = duration.seconds();
  secs = padStart(secs, 2, '0');

  if (hours > 0) {
    mins = padStart(mins, 2, '0');
    return `${hours}:${mins}${roundTo != 60 ? ':' + secs : ''} hr`;
  } else {
    return `${mins}${roundTo != 60 ? ':' + secs : ''} min`;
  }
}
