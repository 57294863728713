export const shapeImgixParams = (obj) => {
  return {
    crop: obj?.crop,
    fit: obj?.fit,
    'fp-x': obj?.fp_x,
    'fp-y': obj?.fp_y,
    'fp-z': obj?.fp_z,
    ar: '16:9',
    q: 50
  };
};
