export const CC_ANCHOR_STANDARDS = [
  {
    slug: 'cc1',
    number: 1,
    title: 'What the Text Says',
    short_code: 'R.1',
    type: 'Information'
  },
  {
    slug: 'cc2',
    number: 2,
    title: 'Main Idea, Key Details, & Summarization',
    short_code: 'R.2',
    type: 'Information'
  },
  {
    slug: 'cc3',
    number: 3,
    title: 'Connecting People, Events, & Ideas',
    short_code: 'R.3',
    type: 'Information'
  },
  {
    slug: 'cc4',
    number: 4,
    title: 'Word Meaning & Choice',
    short_code: 'R.4',
    type: 'Information'
  },
  {
    slug: 'cc5',
    number: 5,
    title: 'Text Structure',
    short_code: 'R.5',
    type: 'Information'
  },
  {
    slug: 'cc6',
    number: 6,
    title: 'Point of View and Purpose',
    short_code: 'R.6',
    type: 'Information'
  },
  {
    slug: 'cc7',
    number: 7,
    title: 'Interpret Multimedia',
    short_code: 'R.7',
    type: 'Information'
  },
  {
    slug: 'cc8',
    number: 8,
    title: 'Arguments & Claims',
    short_code: 'R.8',
    type: 'Information'
  },
];
