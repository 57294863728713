import React from 'react';

import {LoadingSpinner} from '@newsela/angelou';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';

import {adminTermsOfUseRoot} from 'static/three-oh/src/modules/constants/routePaths';

const LazyAdminTermsOfUseContainer = Loadable({
  loader: () => import('./containers/AdminTermsOfUseContainer'),
  loading: LoadingSpinner,
});

const AdminTermsOfUseRoute = [
  <Route
    key="AdminTermsOfUseCatchAll"
    path={adminTermsOfUseRoot}
    component={LazyAdminTermsOfUseContainer}
  />,
];

export default AdminTermsOfUseRoute;
