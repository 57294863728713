import ApiFetcher2 from 'static/three-oh/src/modules/apis/ApiFetcher2';

class EventRecordApi {
  static post(data) {
    return ApiFetcher2.post('event-record/', {data});
  }

  static get(params) {
    return ApiFetcher2.get('event-record/', {params});
  }
}

export default EventRecordApi;
