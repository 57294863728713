exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__color-quiz-correct___1VqZp{background-color:#52A821}.styles__color-quiz-incorrect___0UeBp{background-color:#D93F31}.styles__color-science___Hyybj{background-color:#55bca6}.styles__color-kids___cqqw5{background-color:#5908f7}.styles__color-money___hFoY9{background-color:#fa0045}.styles__color-law___2mwMz{background-color:#002764}.styles__color-health___xSrZL{background-color:#03a43c}.styles__color-arts___f7HZx{background-color:#E2C925}.styles__color-war-and-peace___sqpFO{background-color:#E76A0E}.styles__color-sports___ZCBOQ{background-color:#000000}.styles__color-opinion___EcJT7{background-color:#0027f5}.styles__color-text-set___zqFRg{background-color:#106FF3}.styles__color-object-gray___FF283{background-color:#878787}.styles__color-light-gray___Lw5Cf{background-color:#F8F8F8}.styles__color-middle-gray___-5IY5{background-color:#BCBCBE}.styles__color-newsela-black___9FVYy{background-color:#333333}.styles__color-dark-bg___iEA3V{background-color:#031E3E}.styles__color-newsela-blue___wD4ek{background-color:#0768fd}.styles__unchecked___U5vAT:hover{color:#0768fd;cursor:pointer}.styles__unchecked___U5vAT p{padding-left:.5em;display:inline-block}.styles__checked___lJsVp{color:#0768fd !important}.styles__circle___mVcrW{background-color:#fff;box-sizing:border-box;display:block;width:30px;height:30px;text-align:center;border-radius:100%;border:2px solid;margin:3px}.styles__buttonIcon___dmVy5{font-size:16px !important}@media(max-width: 480px){.styles__unchecked___U5vAT p{visibility:hidden;width:0 !important}}", ""]);

// exports
exports.locals = {
	"color-quiz-correct": "styles__color-quiz-correct___1VqZp",
	"color-quiz-incorrect": "styles__color-quiz-incorrect___0UeBp",
	"color-science": "styles__color-science___Hyybj",
	"color-kids": "styles__color-kids___cqqw5",
	"color-money": "styles__color-money___hFoY9",
	"color-law": "styles__color-law___2mwMz",
	"color-health": "styles__color-health___xSrZL",
	"color-arts": "styles__color-arts___f7HZx",
	"color-war-and-peace": "styles__color-war-and-peace___sqpFO",
	"color-sports": "styles__color-sports___ZCBOQ",
	"color-opinion": "styles__color-opinion___EcJT7",
	"color-text-set": "styles__color-text-set___zqFRg",
	"color-object-gray": "styles__color-object-gray___FF283",
	"color-light-gray": "styles__color-light-gray___Lw5Cf",
	"color-middle-gray": "styles__color-middle-gray___-5IY5",
	"color-newsela-black": "styles__color-newsela-black___9FVYy",
	"color-dark-bg": "styles__color-dark-bg___iEA3V",
	"color-newsela-blue": "styles__color-newsela-blue___wD4ek",
	"unchecked": "styles__unchecked___U5vAT",
	"checked": "styles__checked___lJsVp styles__unchecked___U5vAT",
	"circle": "styles__circle___mVcrW",
	"buttonIcon": "styles__buttonIcon___dmVy5"
};