import {map, unionBy} from 'lodash-es';
import moment from 'moment';
import {combineReducers} from 'redux';

import {STORE_STUDENT_WORK_REVIEW_RECORDS} from './ducks/readDucks.js';

function studentWorkReviewRecordsRequest(state = {items: [], dateLoaded: null}, action) {
  if (action.type === STORE_STUDENT_WORK_REVIEW_RECORDS) {
    // Convert strings to dates.
    const records = map(action.payload.records, (record) => {
      return {
        ...record,
        date_last_reviewed: moment(record.date_last_reviewed)
      };
    });
    // Combine any existing review record items on state with the newly passed in record payload.
    // In the event of a record ID collision, favor the new payload over the existing record on state.
    const items = unionBy(records, state.items, 'id');
    return {items, dateLoaded: action.payload.dateLoaded};
  } else {
    return state;
  }
}

const readReducer = combineReducers({
  studentWorkReviewRecordsRequest,
});

export {readReducer};
