exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__joinClassForm___XG9lt{align-items:baseline;display:flex;justify-content:space-between;padding:20px;width:550px}.styles__joinClassForm___XG9lt .styles__container___-WiUh{width:160px}", ""]);

// exports
exports.locals = {
	"joinClassForm": "styles__joinClassForm___XG9lt",
	"container": "styles__container___-WiUh"
};