import ApiFetcher2 from '../ApiFetcher2';

// I don't like that this exists, but the way we're passing parameters
// in to the NotificationApi options is different from how we're accepting
// them on the server side. Eventually, we should reconcile the two, but
// for now this exists as a way to map client side prop names to server side
// prop names.

const serverSidePropNames = {
  classroomId: 'classroom_id',
  classroomIds: 'classroom_ids',
  headerId: 'header_id',
  headerIds: 'header_ids',
  headerSlug: 'header_slug',
  assignmentId: 'assignment_id',
  assignmentIds: 'assignment_ids',
  modifiedSince: 'modified_since',
  pageSize: 'page_size',
  ccAnchorStandard: 'cc_anchor_standard_slug',
  streamName: 'stream',
  withArchivedClassrooms: 'with_archived_classrooms',
  excludeReadingSession: 'exclude_reading_session',
  excludeActivities: 'exclude_activities'
};

class NotificationApi {
  /**
   * @returns {Promise}
   */
  static getNotifications(options) {
    const params = {};
    const config = {};
    for (const key in options) {
      if (options[key] === undefined || key == 'userId') {
        continue;
      }
      if (key == 'workType') {
        params.wasAssigned = options[key] == 'assigned' ? 1 : null;
      } else {
        const serverSideKey = serverSidePropNames[key] || key;
        params[serverSideKey] = options[key];
      }
    }
    return ApiFetcher2.get('notification/', {config, params});
  }
}

export default NotificationApi;
