import React from 'react';

import {LoadingSpinner} from '@newsela/angelou';
import Loadable from 'react-loadable';
import {Route, Redirect} from 'react-router-dom';

import {studentBinderRoot} from 'static/three-oh/src/modules/constants/routePaths';

const LazyStudentBinderContainer = Loadable({
  loader: () => import('./containers/StudentBinderContainer/StudentBinderContainer'),
  loading: LoadingSpinner,
});

const LazyStudentWordWallContainer = Loadable({
  loader: () => import('./containers/StudentWordWallContainer/StudentWordWallContainer'),
  loading: LoadingSpinner,
});

const StudentBinderRoute = [
  <Route
    key="word-wall"
    exact
    path={`${studentBinderRoot}/word-wall`}
    component={LazyStudentWordWallContainer}
  />,
  <Route
    key="StudentBinderIndexRedirect"
    path={studentBinderRoot}
    exact
    render={() => <Redirect to={studentBinderRoot + '/assignments'}/>}
  />,
  <Route
    key="StudentBinderCatchAll"
    path={studentBinderRoot}
    component={LazyStudentBinderContainer}
  />,
];

export default StudentBinderRoute;
