import React from 'react';

import {Badge, Image, LoadingSpinner, typographyStyles} from '@newsela/angelou';
import classNames from 'classnames/bind';
import {css} from 'emotion';
import {
  filter,
  flatMap,
  flatten,
  flattenDeep,
  forEach,
  get,
  isEmpty,
  isEqual,
  isNil,
  isObject,
  map,
  reject,
  some,
  unionBy,
  uniqBy,
  upperFirst
} from 'lodash-es';
import PropTypes from 'prop-types';
import {Link, Route} from 'react-router-dom';

import {AssignmentSummaryVeil} from 'static/three-oh/src/components';
import Card from 'static/three-oh/src/components/Card';
import ELEVATIONS from 'static/three-oh/src/constants/Elevations';
import {notificationsMeasures} from 'static/three-oh/src/modules/utils';
import {
  findComplementaryArticleForLockedAssignment,
  getAssignmentEditURL,
  hasArchivedAssignmentGroups,
  isMVEAssignment,
  determineSRCStateForMembers
} from 'static/three-oh/src/modules/utils/assignmentHelpers';
import {isObjectArchived} from 'static/three-oh/src/modules/utils/dateHelpers';
import {DistrictModificationAlert} from 'static/three-oh/src/routes/Binder/components/AssignmentsTab/components/DistrictModificationAlert/DistrictModificationAlert';
import {buildAssignmentHeaderUrlByType} from 'static/three-oh/src/routes/Read/components/GradingMode/utils';
import {buildImgixUrl} from 'static/three-oh/utils/buildImgixUrl';
import {toSnakeCase} from 'static/three-oh/utils/stringUtils';
import {buildStudentActivityReviewUrl} from 'utils/buildStudentActivityReviewUrl';
import {getLastNameFirstName} from 'utils/studentHelpers';

import AssignmentReviewTable from './components/AssignmentReviewTable/AssignmentReviewTable';
import Measure from './components/Measure';

import styles from './styles.scss';


const generateClassName = classNames.bind(styles);

const propTypes = {
  assignment: PropTypes.object,
  assignmentGroup: PropTypes.object,
  assignmentMember: PropTypes.object,
  articleHeader: PropTypes.object,
  articleHeaders: PropTypes.array,
  classroom: PropTypes.object,
  checksForUnderstandingSubmissions: PropTypes.array,
  resetStudentQuiz: PropTypes.func,
  returnResponseForRevision: PropTypes.func,
  hasReviewChecksForUnderstandingFeature: PropTypes.bool,
  headerHasPowerWords: PropTypes.bool,
  impersonatedUser: PropTypes.object,
  isCATContent: PropTypes.bool,
  isUnconsentedCAT: PropTypes.bool,
  isSRCContent: PropTypes.bool,
  isUnconsentedSRC: PropTypes.bool,
  isUpdating: PropTypes.bool,
  isFreeUser: PropTypes.bool,
  isLiteUser: PropTypes.bool,
  notifications: PropTypes.array,
  student: PropTypes.object,
  students: PropTypes.array,
  summaryCardType: PropTypes.string,
  user: PropTypes.object,
  userHasPowerWordsLicense: PropTypes.bool,
  isInReviewMode: PropTypes.bool,
  requestWritePromptResponsesIfNecessary: PropTypes.func,
  updateAssignmentAndApiCallState: PropTypes.func,
};

const getCollectionShortName = (collection) => {
  if (!collection) return;
  if (collection.slug === 'ela') return 'ELA';
  return collection.title;
};

const activityMatchesCollection = (collection) => (activity) => {
  // An activity matches if there is no collection to select from.
  return !collection || activity.collections.some(
    ({id}) => id === collection.id
  );
};

class SummaryCard extends React.Component {
  state = {
    additionalWritePrompts: [],
    promptNotificationsNotConnectedWithArticlePrompts: []
  };

  componentDidMount() {
    const promptNotificationsNotConnectedWithArticlePrompts = this.getPromptNotificationsNotConnectedWithArticlePrompts(this.props.notifications);
    if (promptNotificationsNotConnectedWithArticlePrompts.length === 0) return;
    if (promptNotificationsNotConnectedWithArticlePrompts.length !== this.state.promptNotificationsNotConnectedWithArticlePrompts.length) this.getAdditionalPrompts(promptNotificationsNotConnectedWithArticlePrompts);
  }

  // prevents Angelou table from reloading and removing user applied table sorting
  shouldComponentUpdate(nextProps) {
    const propsToCheck = ['notifications', 'headerHasPowerWords', 'isUpdating'];

    const hasChanged = (path) => !isEqual(
      get(nextProps, path),
      get(this.props, path)
    );

    const hasCheckedPropsChanged = some(propsToCheck, hasChanged);
    return hasCheckedPropsChanged;
  }

  componentDidUpdate(prevProps) {
    const propsToCheck = ['notifications'];

    const hasChanged = (path) => !isEqual(
      get(prevProps, path),
      get(this.props, path)
    );

    const isNotificationsChanged = some(propsToCheck, hasChanged);

    // update data only when filters are updated
    if (isNotificationsChanged) {
      const promptNotificationsNotConnectedWithArticlePrompts = this.getPromptNotificationsNotConnectedWithArticlePrompts(this.props.notifications);
      if (promptNotificationsNotConnectedWithArticlePrompts.length === 0) return;

      if (promptNotificationsNotConnectedWithArticlePrompts.length !== this.state.promptNotificationsNotConnectedWithArticlePrompts.length) this.getAdditionalPrompts(promptNotificationsNotConnectedWithArticlePrompts);
    }
  }

  /*
   * retrieves any write prompt notifications that used to be associated with the article
   * to see if there was any student work against write prompts previously connected to the article
   */
  getPromptNotificationsNotConnectedWithArticlePrompts = (notifications) => {
    const allArticleAssociatedPrompts = flatMap(this.getArticles(), 'prompts');
    const allArticleAssociatedPromptIds = new Set(map(allArticleAssociatedPrompts, 'id'));
    const isAdditionalPromptNotification = (notification) => {
      const notificationPromptId = get(notification, 'data.response.prompt.id');
      return notification.type === 'student-write-response' && !allArticleAssociatedPromptIds.has(notificationPromptId);
    };
    const relevantNotifications = notifications.filter(isAdditionalPromptNotification);
    return relevantNotifications;
  }

  getAdditionalPrompts = (promptNotificationsNotConnectedWithArticlePrompts) => {
    const writePromptResponsesToRequest = [];
    forEach(promptNotificationsNotConnectedWithArticlePrompts, (notification) => {
      const promptData = {
        articleId: get(notification, 'data.article.id'),
        promptId: get(notification, 'data.response.prompt.id'),
        classroomId: get(notification, 'classrooms[0].id'),
      };
      writePromptResponsesToRequest.findIndex((item) => item.promptId === promptData.promptId) === -1 && writePromptResponsesToRequest.push(promptData);
    });

    writePromptResponsesToRequest.forEach((promptQueryParam) => {
      return this.props.requestWritePromptResponsesIfNecessary(promptQueryParam).then((writeResponseResult) => {
        this.setState((prevState) => {
          const additionalWritePrompts = prevState.additionalWritePrompts;
          writeResponseResult.length > 0 && forEach(writeResponseResult, (response) => {
            additionalWritePrompts.push(response.prompt);
          });
          return {additionalWritePrompts: uniqBy(additionalWritePrompts, 'id'), promptNotificationsNotConnectedWithArticlePrompts: promptNotificationsNotConnectedWithArticlePrompts};
        });
      });
    });
  }

  getAllUniqueCollections = () => {
    const {articleHeader, articleHeaders} = this.props;
    const headers = !articleHeader ? articleHeaders : [articleHeader];
    const activityCollections = this.getCollectionsFromActivities(headers);

    return headers.reduce((allCollections, eachArticleHeader) => {
      // Remove duplicate collections by id
      return unionBy(allCollections, eachArticleHeader.collections, 'id');
    }, [...activityCollections]);
  }

  // Retrieve all collections from write prompt and quiz activities
  getCollectionsFromActivities = (articleHeaders) => {
    const collections = [];
    const returnArrayOfCollections = (header, activityType) => {
      return get(header, activityType, []).reduce((allCollections, activity) => {
        allCollections.push(activity.collections);
        return allCollections;
      }, []);
    };

    forEach(articleHeaders, (header) => {
      forEach(get(header, 'articles', []), (article) => {
        collections.push(returnArrayOfCollections(article, 'prompts'));
        collections.push(returnArrayOfCollections(article, 'quizzes'));
      });
    });

    return flattenDeep(collections);
  }

  /*
   * Checks if there are any write prompt notifications for this article
   * where the write prompt does NOT have a collection (ELA, SS, etc)
   * If so, we will display an additional custom write prompt column
   */
  hasPromptNotificationsWithoutCollection = (articleHeader) => {
    const promptIdsWithoutCollections = new Set();

    forEach(get(articleHeader, 'articles', []), (article) => {
      forEach(get(article, 'prompts', []), (prompt) => {
        if (get(prompt, 'collections', []).length == 0) {
          promptIdsWithoutCollections.add(prompt.id);
        }
      });
    });

    const isRelevantNotification = (notification) => {
      const notificationPromptId = get(notification, 'data.response.prompt.id');
      return promptIdsWithoutCollections.has(notificationPromptId);
    };

    const {notifications} = this.props;
    const hasRelevanNotifications = notifications.some(isRelevantNotification);

    return hasRelevanNotifications;
  }

  hasSubjectProduct = () => {
    return some(this.getAllUniqueCollections(), 'is_subject_product');
  }

  cellUrlGetter = ({activityType, collectionId} = {}) => ({articleHeaderSlug, articleId, studentIntegerId, studentUniqueId, activityId}) => {
    const {assignment, assignmentGroup, classroom, impersonatedUser, user} = this.props;

    if (!articleHeaderSlug || !articleId) return undefined;
    const assignmentContentId = get(assignment, 'content_id');
    const assignmentId = get(assignment, 'assignment_id');
    const assignmentGroupId = assignmentGroup?.group_id;
    const classroomId = get(classroom, 'id');
    const assignmentUserId = (impersonatedUser) ? impersonatedUser.id : user.id;

    return buildStudentActivityReviewUrl({
      articleHeaderSlug,
      articleId,
      studentIntegerId,
      studentUniqueId,
      assignmentContentId,
      assignmentGroupId,
      assignmentId,
      classroomId,
      assignmentUserId,
      activityType,
      collectionId,
      activityId
    });
  }

  getArticles = () => {
    const {articleHeaders} = this.props;
    const articles = [];
    for (const articleHeader of articleHeaders) {
      articles.push(...get(articleHeader, 'articles', []));
    }
    return articles;
  }

  getRelevantQuizzes = (collection) => {
    const allQuizzes = flatMap(this.getArticles(), 'quizzes');
    return filter(allQuizzes, activityMatchesCollection(collection));
  }

  getQuizActivityMeasure = (collection) => {
    const {notifications, summaryCardType} = this.props;
    const relevantQuizIds = new Set(
      map(this.getRelevantQuizzes(collection), 'id')
    );

    // If there are no quizzes for the given collection, do not include a column for it.
    if (relevantQuizIds.size === 0) return;

    const isRelevantNotification = (notification) => {
      const notificationQuizId = get(notification, 'data.studentquiz.quiz.id');
      return relevantQuizIds.has(notificationQuizId);
    };
    const relevantNotifications = notifications.filter(isRelevantNotification);
    const collectionShortName = getCollectionShortName(collection);
    const labelSuffix = !collectionShortName ? '' : collectionShortName;
    const label = [labelSuffix, 'Quiz'];

    return {
      type: 'quiz-activity',
      label: label,
      summaryCardValue: (
        summaryCardType === 'content' ?
          notificationsMeasures.getStudentsWithQuizzes(relevantNotifications) :
          notificationsMeasures.getArticlesWithQuizzes(relevantNotifications)
      ),
      fontAwesomeIcon: 'fa-lightbulb-o',
      needsReview: false,
      key: `quiz-activity-${collectionShortName}`,
      dataTableColumnName: [labelSuffix, 'Quiz Score'],
      dataTableValue: notificationsMeasures.getQuizScore,
      isRelevantNotification,
      getCellUrl: this.cellUrlGetter({
        activityType: 'quiz',
        collectionId: get(collection, 'id')
      }),
      tooltipMessage: null,
    };
  }

  getQuizActivityMeasures = () => {
    const quizActivityMeasures = (!this.hasSubjectProduct()
      ? [this.getQuizActivityMeasure()]
      : map(this.getAllUniqueCollections(), this.getQuizActivityMeasure)
    );
    return filter(
      quizActivityMeasures,
      isObject
    );
  }

  hasCustomPrompts = (collection) => {
    const allPrompts = flatMap(this.getArticles(), 'prompts');
    const matchingPrompts = filter(allPrompts, activityMatchesCollection(collection));
    return some(matchingPrompts, (prompt) => !isNil(get(prompt, 'teacher_id')));
  }

  getRelevantPrompts = (collection, returnCustomPrompts, returnOtherCompletedNonCustomPrompts, additionalWritePrompts, articleHeader) => {
    const articles = articleHeader ? articleHeader.articles : this.getArticles();
    const allPrompts = flatMap(articles, 'prompts');
    let matchingPrompts = (returnOtherCompletedNonCustomPrompts)
      ? filter(additionalWritePrompts, activityMatchesCollection(collection))
      : filter(allPrompts, activityMatchesCollection(collection));

    /* Only in cases where there are custom prompts present, do we want
     * to separate the default and custom prompts into their respective categories.
     */
    if (!returnOtherCompletedNonCustomPrompts && this.hasCustomPrompts(collection)) {
      matchingPrompts = returnCustomPrompts ?
        filter(matchingPrompts, 'teacher_id') :
        reject(matchingPrompts, 'teacher_id');
    }
    return matchingPrompts;
  }

getWriteActivityMeasure = (returnCustomPrompts, returnOtherCompletedNonCustomPrompts, collection, articleHeader) => {
  const {notifications, summaryCardType} = this.props;
  const {promptNotificationsNotConnectedWithArticlePrompts, additionalWritePrompts} = this.state;

  /* If we are checking for custom prompts, but none exist,
     * do not include a column for custom prompts
     */
  if (returnCustomPrompts && !this.hasCustomPrompts(collection)) return;

  /* If we are checking for work completed against other non-custom prompts, but none exist,
     * do not include a column for other non-custom prompts
     */
  if (returnOtherCompletedNonCustomPrompts && promptNotificationsNotConnectedWithArticlePrompts.length === 0) { return; }

  const relevantPromptIds = new Set(
    map(this.getRelevantPrompts(collection, returnCustomPrompts, returnOtherCompletedNonCustomPrompts, additionalWritePrompts, articleHeader), 'id')
  );

  // If there are no write prompts for the given collection, do not include a column for it.
  if (relevantPromptIds.size === 0) return;
  const isRelevantNotification = (notification) => {
    const notificationPromptId = get(notification, 'data.response.prompt.id');
    return relevantPromptIds.has(notificationPromptId);
  };
  const relevantNotifications = notifications.filter(isRelevantNotification);
  const collectionShortName = getCollectionShortName(collection);
  const labelSuffix = !collectionShortName ? '' : collectionShortName;
  let promptType = '';
  const label = [labelSuffix, 'Write Prompt'];
  const dataTableColumnNameArray = !isEmpty(labelSuffix) ? [labelSuffix, 'Write Score'] : ['Write Score'];

  let key = `write-activity-${collectionShortName}`;

  const isCollectionInAdditionalWritePrompts = (collection) => {
    if (!collection) { return false; }
    return additionalWritePrompts.some(
      (prompt) => prompt.collections[0] && collection.id === prompt.collections[0].id
    );
  };

  if (this.hasCustomPrompts(collection) && additionalWritePrompts.length > 0) {
    if (returnCustomPrompts) {
      promptType = 'custom-';
    } else if (returnOtherCompletedNonCustomPrompts) {
      promptType = (isCollectionInAdditionalWritePrompts(collection)) ? 'default - Version 1' : 'default-';
    } else {
      promptType = (isCollectionInAdditionalWritePrompts(collection)) ? 'default - Version 2' : 'default-';
    }
    label.push(`-${upperFirst(promptType)}`);
    key += `-${promptType}`;
  } else if (this.hasCustomPrompts(collection)) {
    promptType = returnCustomPrompts ? 'custom' : 'default';
    label.push(`-${upperFirst(promptType)}-`);
    key += `-${promptType}`;
  } else if (additionalWritePrompts.length > 0) {
    promptType = returnOtherCompletedNonCustomPrompts ? 'Version 1' : (isCollectionInAdditionalWritePrompts(collection) || isNil(collection)) ? 'Version 2' : '';
    label.push(`-${upperFirst(promptType)}`);
    key += `-${promptType}`;
  }

  const tooltipMessage = 'Newsela updated the write prompt for this article. New work completed will now show up in this column';

  return {
    type: 'write-activity',
    label: label,
    summaryCardValue: (
      summaryCardType === 'content' ?
        notificationsMeasures.getStudentsWithWriteResponses(relevantNotifications) :
        notificationsMeasures.getArticlesWithWriteResponses(relevantNotifications)
    ),
    fontAwesomeIcon: 'fa-pencil',
    needsReview: false,
    key: key,
    dataTableColumnName: dataTableColumnNameArray,
    dataTableValue: notificationsMeasures.getWriteScore,
    isRelevantNotification,
    getCellUrl: this.cellUrlGetter({
      activityType: 'write',
      collectionId: get(collection, 'id')
    }),
    tooltipMessage: (key.includes('Version 2') && !this.hasCustomPrompts(collection)) ? tooltipMessage : null,
  };
}

  getWriteActivityMeasures = (articleHeader) => {
    let getWriteActivityMeasures;

    // Return an array containing both default and custom measures, and if necessary an additional measure for a previously associated prompt
    if (!this.hasSubjectProduct()) {
      getWriteActivityMeasures = [this.getWriteActivityMeasure(false, false), this.getWriteActivityMeasure(true, false), this.getWriteActivityMeasure(false, true)];
    } else {
      getWriteActivityMeasures = this.getAllUniqueCollections().map(
        (collection) => [this.getWriteActivityMeasure(false, false, collection, articleHeader), this.getWriteActivityMeasure(true, false, collection, articleHeader), this.getWriteActivityMeasure(false, true, collection, articleHeader)]
      );

      // If there are write prompt notifications without collections, include as a separate column
      if (this.hasPromptNotificationsWithoutCollection(articleHeader)) {
        getWriteActivityMeasures.push([this.getWriteActivityMeasure(true, false)]);
      }
    }

    getWriteActivityMeasures = flatten(getWriteActivityMeasures).filter((writePrompt) => !writePrompt?.label?.includes('-Default-'));

    return filter(
      flatten(getWriteActivityMeasures),
      isObject
    );
  }

  getMeasures = () => {
    const {
      articleHeader,
      assignment,
      headerHasPowerWords,
      notifications,
      summaryCardType,
      userHasPowerWordsLicense,
    } = this.props;

    // The summary cards can be grouped by either 'Articles' or 'Students'. That
    // grouping determines each measure's label and value.
    const measures = [
      {
        type: 'content-views',
        label: ['Content Views'],
        summaryCardValue: (
          summaryCardType === 'content' ?
            notificationsMeasures.getStudentViews(notifications) :
            notificationsMeasures.getArticleHeaderViews(notifications)
        ),
        fontAwesomeIcon: null,
        needsReview: false,
        key: 'content-views',
        dataTableColumnName: ['Article View', 'Date'],
        dataTableValue: notificationsMeasures.getArticleViewDate,
        getCellUrl: this.cellUrlGetter(),
        tooltipMessage: null,
      },
      {
        type: 'time-on-article',
        label: (
          summaryCardType === 'content' ?
              ['Median Time', 'on Article'] :
              ['Total', 'Read Time']
        ),
        isMedianReadTimeMeasure: true,
        summaryCardValue: (
          summaryCardType === 'content' ?
            notificationsMeasures.getMedianReadingTime(notifications) :
            notificationsMeasures.getTotalReadingTime(notifications)
        ),
        fontAwesomeIcon: null,
        needsReview: false,
        key: 'time-on-article',
        dataTableColumnName: ['Time on ', 'Article'],
        dataTableValue: notificationsMeasures.getTotalReadingTime,
        getCellUrl: this.cellUrlGetter(),
        tooltipMessage: null
      },
      {
        type: 'article-level',
        label: ['Median', 'Article Level'],
        summaryCardValue: notificationsMeasures.getMedianArticleLevel(notifications),
        fontAwesomeIcon: null,
        needsReview: false,
        key: 'article-level',
        dataTableColumnName: ['Article', 'Level'],
        dataTableValue: notificationsMeasures.getArticleLevel,
        getCellUrl: this.cellUrlGetter(),
        tooltipMessage: null,
      },
      ...this.getQuizActivityMeasures(),
      ...this.getWriteActivityMeasures(articleHeader),
      {
        type: 'annotations',
        label: ['Annotations'],
        summaryCardValue: (
          summaryCardType === 'content' ?
            notificationsMeasures.getStudentsWithAnnotations(notifications) :
            notificationsMeasures.getArticlesWithAnnotations(notifications)
        ),
        needsReview: false,
        key: 'annotations',
        dataTableColumnName: ['Annotations'],
        dataTableValue: notificationsMeasures.getNumberOfStudentAnnotations,
        getCellUrl: this.cellUrlGetter(),
        tooltipMessage: null,
      },
    ];

    const hasChecksForUnderstandingColumn =
      assignment?.assignment_config?.hide_checks_for_understanding === false && this.props.hasReviewChecksForUnderstandingFeature;

    const addPowerWordsMeasure = (
      userHasPowerWordsLicense && headerHasPowerWords
    );

    if (summaryCardType === 'student') {
      measures.unshift(
        {
          type: 'content-type',
          label: [' '],
          summaryCardValue: null,
          fontAwesomeIcon: null,
          needsReview: false,
          key: 'content-type',
          dataTableColumnName: ['Content Type'],
          tooltipMessage: null,
        }
      );
    }

    if (hasChecksForUnderstandingColumn) {
      const checksForUnderstandingLabel = 'Checks for Understanding';
      measures.push({
        type: 'checks-for-understanding-activity',
        label: [checksForUnderstandingLabel],
        needsReview: false,
        key: 'checks-for-understanding-activity',
        dataTableColumnName: ['Checks', 'For Understanding'],
        getCellUrl: this.cellUrlGetter({
          activityType: 'checks_for_understanding'
        }),
        tooltipMessage: null
      });
    }

    if (addPowerWordsMeasure) {
      const powerWordLabel = 'Power Words';
      measures.push({
        type: 'power-words-activity',
        label: [powerWordLabel],
        summaryCardValue: (
          summaryCardType === 'content' ?
            notificationsMeasures.getStudentsWithPowerWords(notifications) :
            notificationsMeasures.getArticlesWithPowerWords(notifications)
        ),
        fontAwesomeIcon: 'fa-bolt',
        needsReview: false,
        key: 'power-words-activity',
        dataTableColumnName: ['Power', 'Words Score'],
        dataTableValue: notificationsMeasures.getPowerWordsScore,
        getCellUrl: this.cellUrlGetter({
          activityType: 'vocabulary'
        }),
        tooltipMessage: null,
      });
    }

    return measures;
  }

  renderMeasure = (measure) => {
    const {
      notifications,
      articleHeader,
      student,
      classroom,
      assignment
    } = this.props;

    const gradingUrl = articleHeader ? buildAssignmentHeaderUrlByType(measure, articleHeader, classroom, assignment) : null;
    const noStudentActivity = !notifications.length;

    return (
      <Measure
        key={measure.key}
        articleHeader={articleHeader}
        dataQaSelector={measure.key}
        fontAwesomeIcon={measure.fontAwesomeIcon}
        label={measure.label}
        needsReview={measure.needsReview}
        noStudentActivity={noStudentActivity}
        student={student}
        tooltipMessage={measure.tooltipMessage}
        value={measure.summaryCardValue}
        gradingUrl={gradingUrl}
      />
    );
  }

  getArticlePageUrlPath = () => {
    const {articleHeader, assignment} = this.props;
    const lockedArticle = findComplementaryArticleForLockedAssignment(assignment, articleHeader.articles);
    let articleHeaderUrl = (
      assignment.grade_level_lock ?
        `/read/${articleHeader.slug}/id/${lockedArticle.id}` :
        articleHeader.url
    );

    // If this is an MVE assignment, route to view page w/assignment editor populated
    if (isMVEAssignment(assignment)) {
      articleHeaderUrl = getAssignmentEditURL(assignment);
    }
    return articleHeaderUrl;
  }

  renderLeftElement = () => {
    const {articleHeader, student, assignment, isLiteUser} = this.props;
    if (articleHeader) {
      const {content_id: contentId} = articleHeader;
      const currentAssignmentMember = assignment?.assignment_members?.find((member) => member.content?.content_id === contentId);
      let CATContent = null;
      const containsPremiumContent = isLiteUser && !!currentAssignmentMember?.content.visibility_markers.find(({reason}) => reason === 'PREMIUM_CONTENT');
      const {containsSRC} = determineSRCStateForMembers(currentAssignmentMember ? [currentAssignmentMember] : []);
      if (currentAssignmentMember?.content.veil?.reason === 'DISTRICT_ADVISORY') {
        // Should grade band checks be required, here is where they should take place. For now, we ignore grade band specific access
        CATContent = true;
      }

      const containsAdvisory = CATContent || containsSRC;
      let imageUrl = articleHeader.image;
      let imageAlt = 'Article Work';
      if (get(currentAssignmentMember, 'content.image.url')) {
        imageUrl = buildImgixUrl(currentAssignmentMember.content.image.url, {w: 460, q: 50, auto: 'format'});
        imageAlt = currentAssignmentMember.content.image.alt_text;
      }

      const renderLeftSideContent = () => {
        return (
          <>
            {
            containsAdvisory
              ?
                <div className={styles.badgeContainer}>
                  <Badge
                    text="Advisory" hideIcon flavor={Badge.flavor.solid} size={Badge.size.md} color={Badge.color.yellow}
                  />
                </div>
              : (containsPremiumContent ?

                <div className={styles.badgeContainer}>
                  <Badge
                    text="Premium" hideIcon flavor={Badge.flavor.solid} size={Badge.size.md} color={Badge.color.blue}
                  />
                </div> :
                  null)
            }
            <Image src={imageUrl} alt={imageAlt} />
          </>
        );
      };

      const renderArticleLink = () => isMVEAssignment(assignment) ?
        <a className={styles.leftElement} href={this.getArticlePageUrlPath()}>{renderLeftSideContent()}</a> :
        <Link className={styles.leftElement} to={this.getArticlePageUrlPath()}>{renderLeftSideContent()}</Link>;
      return renderArticleLink();
    } else if (student) {
      return (
        <h3 className={`${styles.leftElement} ${css(typographyStyles.heading5Obj)}`}>
          {getLastNameFirstName(student)}
        </h3>
      );
    }
  }

  renderRightElement = () => {
    const {
      articleHeader,
      summaryCardType,
    } = this.props;
    const shortTitle = get(articleHeader, 'short_title');

    const topRow = (
      summaryCardType === 'student' ?
        null : (
          <span
            className={styles.headerTitle}
            data-qa-selector={toSnakeCase(shortTitle)}
          >
            <span>{shortTitle}</span>
          </span>
        )
    );

    return (
      <div className={styles.rightElement}>
        {topRow}
        <div className={styles.measuresContainer}>
          {map(this.getMeasures(), this.renderMeasure)}
        </div>
      </div>
    );
  }

  showFreeTeacherVeil = () => {
    return this.props.isFreeUser && !this.props.isLiteUser;
  }

  renderDataTable = () => {
    const {
      assignment,
      assignmentGroup,
      articleHeader,
      articleHeaders,
      checksForUnderstandingSubmissions,
      classroom,
      headerHasPowerWords,
      notifications,
      students,
      user,
      userHasPowerWordsLicense,
      impersonatedUser,
      isInReviewMode,
    } = this.props;

    if (this.showFreeTeacherVeil()) {
      return null;
    }

    const hasChecksForUnderstandingColumn =
      assignment?.assignment_config?.hide_checks_for_understanding === false &&
      this.props.hasReviewChecksForUnderstandingFeature;

    return (
      <AssignmentReviewTable
        assignment={assignment}
        articleHeader={articleHeader}
        articleHeaders={articleHeaders}
        assignmentGroup={assignmentGroup}
        hasChecksForUnderstandingColumn={hasChecksForUnderstandingColumn}
        checksForUnderstandingSubmissions={checksForUnderstandingSubmissions}
        classroom={classroom}
        measures={this.getMeasures()}
        headerHasPowerWords={headerHasPowerWords}
        userHasPowerWordsLicense={userHasPowerWordsLicense}
        notifications={notifications}
        students={students}
        user={user}
        impersonatedUser={impersonatedUser}
        isInReviewMode={isInReviewMode}
        isGradingPageEnabled
      />
    );
  }

  render = () => {
    const {
      assignment,
      classroom,
      notifications,
      assignmentMember,
      summaryCardType,
      isCATContent,
      isUnconsentedCAT,
      isSRCContent,
      isUnconsentedSRC,
      updateAssignmentAndApiCallState,
      isUpdating,
    } = this.props;

    const freeTeacherVeil = (
      this.showFreeTeacherVeil() ?
        <AssignmentSummaryVeil tab="articles" /> :
        null
    );
    let summaryCard;
    if (summaryCardType === 'content') {
      summaryCard = (
        <Route>
          {() => (
            <div className={`${styles.card} ${!this.renderDataTable() && styles.noShadow}`}>
              {this.renderLeftElement()}
              {this.renderRightElement()}
            </div>
          )}
        </Route>
      );
    } else {
      const studentCardStyle = generateClassName({
        card: true,
        studentCardClosed: notifications.length === 0 || assignment.assignment_members.length === 0,
      });
      summaryCard = (
        <div className={studentCardStyle}>
          {this.renderLeftElement()}
          {this.renderRightElement()}
        </div>
      );
    }
    const classroomArchived = isObjectArchived(classroom);
    const archivedAssignmentGroup = hasArchivedAssignmentGroups(assignment?.assignment_groups, assignment);
    const isAdvisory = isCATContent || isSRCContent;
    const isUnconsented = isUnconsentedCAT || isUnconsentedSRC;
    const displayModificationAlert = !assignment?.is_draft && isAdvisory && isUnconsented && !classroomArchived && !archivedAssignmentGroup;
    return (

      <div className={styles.container}>
        {
        displayModificationAlert ?
          <DistrictModificationAlert
            assignment={assignment}
            assignmentMember={assignmentMember}
            pinToContent
            reassignButton
            updateAssignmentAndApiCallState={updateAssignmentAndApiCallState}
            isUpdating={isUpdating}
          />
          :
          null
        }
        <Card
          elevation={ELEVATIONS.flat}
          additionalClassNames={[styles.noBackground]}
        >
          <div
            className={styles.cardAmenities}
            data-qa-selector="card_amenities"
          >
            {
            isUpdating
              ?
                <div
                  className={styles.cardUpdating}
                ><LoadingSpinner /></div>
              :
              null
             }
            {summaryCard}
            {this.renderDataTable()}
            {freeTeacherVeil}
          </div>
        </Card>
      </div>
    );
  };
}

SummaryCard.propTypes = propTypes;

export default SummaryCard;
