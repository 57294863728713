import {constants} from '@newsela/angelou';
import {css} from 'emotion';

import {
  NAVBAR_BREAKPOINTS
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';

export const getHomeMenuPopoutContainerCss = (hasOpenSubMenu) => css({
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem 0',
  gap: '0.25rem',
  borderRadius: '0.5rem',
  background: constants.colors.ui.white[50],
  boxShadow: '0px 2px 6px 0px rgba(29, 29, 29, 0.16)',

  [`@media (max-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
    borderRadius: '0 0 0.5rem 0.5rem',
    height: hasOpenSubMenu ? 0 : 'auto',
    overflow: hasOpenSubMenu ? 'hidden' : 'auto',
  },
});

export const supportItemVisibilityBreakpointCss = {
  display: 'inline-block',

  [`@media (min-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
    display: 'none'
  },
};

export const fullWidthButtonCss = css({
  'button, a': {
    width: '100%',
  }
});
