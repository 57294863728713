import APIFetcher from '../APIFetcher';

class AnnotationApi {
  static get(queryParams) {
    const params = APIFetcher.stringifyParams(queryParams);
    return APIFetcher.get(`annotation/?${params}`);
  }
}

export default AnnotationApi;
