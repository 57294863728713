import React from 'react';

import {extend} from 'lodash-es';
import PropTypes from 'prop-types';

import styles from './styles.scss';


/*
Loading component. Used across the homepage to indicate
that not all data has been loaded yet and prevents the
homepage from jumping around.
*/

const propTypes = {
  dimensions: PropTypes.object.isRequired,
  style: PropTypes.object,
};



class Loading extends React.PureComponent {
  constructor(props) {
    super(props);
    this.getStyle = this.getStyle.bind(this);
  }

  getStyle() {
    if (this.props.style) {
      return extend({}, this.props.dimensions, this.props.style);
    }
    return this.props.dimensions;
  }

  render() {
    return (
      <div
        className={styles.load}
        style={this.getStyle()}
      />
    );
  }
}

Loading.propTypes = propTypes;
export default Loading;
