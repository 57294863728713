import React from 'react';

import {
  faTimes as xOut
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@newsela/angelou';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import Modal from 'static/three-oh/src/components/Modal/Modal';
import withTrackEvent from 'static/three-oh/src/components/with/WithTrackEvent';

import styles from './styles.scss';

const ButtonWithTrackEvent = withTrackEvent(Button);

const propTypesStyledModal = {
  ...Modal.propTypes,
  body: PropTypes.object,
  footer: PropTypes.object,
  header: PropTypes.object,
  scrollBody: PropTypes.bool,
  title: PropTypes.string,
  additionalFooterStyles: PropTypes.string,
  location: PropTypes.object,
  cssFor: PropTypes.string,
  aria: PropTypes.shape({
    'aria-label': PropTypes.string,
  }),
};


class StyledModal extends React.Component {
  render() {
    const trackingPropsForCloseButton = {
      objectType: 'Content',
      actionPrefix: 'Close',
      actionName: this.props.title === 'Article Preview' ? 'Preview' : 'Read',
      captured_url: this.props.location.pathname,
      trackImpression: true,
    };
    const streamEventPropsForCloseButton = {
      referrer: this.props.location.pathname,
      description: this.props.title === 'Article Preview' ? 'Action in preview modal' : null,
    };

    const {cssFor = ''} = this.props;
    const className = `${styles.container} ${cssFor}`;


    return (
      <Modal {...this.props}>
        <div
          className={className}
          data-qa-selector="styled_modal"
          role="dialog"
          aria-labelledby="styled_modal_title"
        >
          {
            this.props.title &&
            <section className={styles.title}>
              <span data-qa-selector="modal_title" id="styled_modal_title">{this.props.title}</span>
              <ButtonWithTrackEvent
                /* AUTOGENERATED TODO: update angelou to new flavor.
                  see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
                  for migration guide. */
                legacy_flavor={Button.legacy_flavor.incognito}
                onClick={(event) => this.props.onClose(event)}
                {...trackingPropsForCloseButton}
                streamProperties={streamEventPropsForCloseButton}
                ariaProps={{
                  'aria-label': 'Close',
                }}
              >
                <FontAwesomeIcon icon={xOut}/>
              </ButtonWithTrackEvent>
            </section>
          }
          {this.props.header &&
            <section className={styles.header}>
              {this.props.header}
            </section>}

          {this.props.body &&
            <section className={`${styles.body} ${this.props.scrollBody ? styles.scrollBody : ''}`}>
              {this.props.body}
            </section>}

          {this.props.footer &&
            <section
              className={`${styles.footer} ${this.props.additionalFooterStyles}`}
            >
              {this.props.footer}
            </section>}

        </div>
      </Modal>
    );
  }
}

StyledModal.propTypes = propTypesStyledModal;
StyledModal.defaultProps = {
  scrollBody: false,
};

export default withRouter(StyledModal);
