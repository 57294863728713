import {takeLatest, call, put, take, select} from 'redux-saga/effects';

import {
  fetchEmails,
  fetchEmailsSuccess,
  fetchEmailsError,
  updateEmailError,
  createEmailSuccess,
  updateEmailsInStore
} from 'modulesV2/actions';
import * as types from 'modulesV2/constants';
import {getPrimaryEmail, getEmailById} from 'modulesV2/selectors/index';
import EmailsApi from 'static/three-oh/src/modules/apis/UserEmail/UserEmailApi';


/**
 * Fetch list of user emails and save it in the store
 * @returns {Generator}
 */
function * fetchUserEmails() {
  try {
    const response = yield call(EmailsApi.getEmails);
    yield put(fetchEmailsSuccess(response.data));
  } catch (error) {
    yield put(fetchEmailsError(error));
  }
}

/**
 * update an email to a primary email using action's data
 * @param {Object} action
 */
function * makeEmailPrimary(action) {
  const {id} = action;
  const oldPrimaryEmail = yield select(getPrimaryEmail);
  const newPrimaryEmail = yield select(getEmailById, id);
  const storeActionOptions = {data: {}};
  if (oldPrimaryEmail) {
    // Update old email only if it exists in the store
    storeActionOptions.data[oldPrimaryEmail.id] = {is_primary: false};
  }
  storeActionOptions.data[newPrimaryEmail.id] = {is_primary: true};
  yield put(updateEmailsInStore(...storeActionOptions));
  try {
    // Make a server call to promote given email to primary
    yield call(EmailsApi.updateEmail, id, {is_primary: true, id});
  } catch (error) {
    // In case of error, set error status
    yield put(fetchEmailsError(error));
  } finally {
    // Re-fetch all the emails to ensure data integrity.
    yield put(fetchEmails());
  }
}

function * createNewEmail(action) {
  try {
    const response = yield call(EmailsApi.createEmail, action.data);
    yield put(createEmailSuccess(response.data));
  } catch (error) {
    yield put(updateEmailError(error));
  }
}

// --- Watchers ---- //
export function * watchFetchEmails() {
  yield takeLatest(types.FETCH_USER_EMAILS_REQUEST, fetchUserEmails);
}

export function * watchCreateNewEmail() {
  yield takeLatest(types.CREATE_EMAIL_REQUEST, createNewEmail);
}

export function * watchMakeEmailPrimary() {
  while (true) {
    const action = yield take(types.UPDATE_EMAIL_REQUEST);
    yield call(makeEmailPrimary, action);
  }
}

