import {css} from 'emotion';

const unRollRepeat = (numRepeat, value) => {
  let result = '';
  for (let i = 0; i < numRepeat; i++) {
    result = `${result} ${value}`;
  }
  return result;
};

const generateMsGridCss = (cssString) => {
  // regex looking for repeat(x, y)
  const regex = /repeat\(([1-9]+)\s*,\s*(\S+)\)/g;
  let result = '';
  let strIndex = 0;
  let match;
  while ((match = regex.exec(cssString)) != null) {
    // if there is anything before the repeat grab it
    result = `${result} ${cssString.substring(strIndex, match.index)}`;
    // update the index we're at
    strIndex = match.index + match[0].length;
    // actually perform the repeat
    const addition = unRollRepeat(parseInt(match[1]), match[2]);
    result = `${result} ${addition}`;
  }
  // get the end of the string
  result = `${result} ${cssString.substring(strIndex)}`;
  return result;
};

export const gridContainerCss = ({columns = '1fr', rows = '1fr'}) => {
  const msColumns = generateMsGridCss(columns);
  const msRows = generateMsGridCss(rows);

  return css`
    display: grid;
    display: -ms-grid;
    grid-template-rows: ${rows};
    -ms-grid-rows: ${msRows};
    grid-template-columns: ${columns};
    -ms-grid-columns: ${msColumns};
  `;
};

export const gridColumnSpan = (start, end) => css`
  grid-column: ${start}/${end};
  -ms-grid-column: ${start};
  -ms-grid-column-span: ${end - start};
`;
