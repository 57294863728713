import React from 'react';

import PropTypes from 'prop-types';

import Icon from 'static/three-oh/src/components/Icon';

import AngelouProps from '../../../propTypes/AngelouPropTypes';

import style from './styles.scss';

const propTypes = {
  /**
   * Default angelou props
   */
  ...AngelouProps,

  /**
   * An icon that will be rendered to the left
   * of the label
   */
  icon: PropTypes.element.isRequired,

  /**
   * Text that will be rendered to the right of the
   * icon
   */
  label: PropTypes.string.isRequired,
};

class IconBarMenuItem extends React.PureComponent {
  render() {
    const {
      icon,
      label,
    } = this.props;
    return (
      <div
        className={style.dropDownOption}
      >
        <Icon
          additionalClassNames={style.dropDownIcon}
          iconElement={icon}
          disableToolTip
        />
        <div className={style.dropDownLabel}>
          {label}
        </div>
      </div>
    );
  }
}

IconBarMenuItem.propTypes = propTypes;
export default IconBarMenuItem;

