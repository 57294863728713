import React from 'react';

import {Switch} from 'react-router-dom';

import AdminTermsOfUseRoute from './AdminTermsOfUse/AdminTermsOfUseRoute';
import AssignmentRoute from './Assignment/AssignmentRoute';
import AssignmentsRoute from './Assignments/AssignmentsRoute';
import BinderRoute from './Binder/BinderRoute';
import BrowseRoute from './Browse/Route';
import CanvasRoute from './Canvas/CanvasRoute';
import ChironRoutes from './Chiron/Routes';
import DashRoutes from './Dash/Routes';
import EducatorCenterRoute from './EducatorCenter/Route';
import ExternalEmbedRoute from './ExternalEmbed/ExternalEmbedRoute';
import LessonSparkRoute from './LessonSpark/LessonSparkRoute';
import LibraryRoute from './Library/Route';
import NewsRoute from './News/Route';
import NotFoundRoute from './NotFoundRoute';
import PollRoute from './Poll/PollRoute';
import ReadRoute from './Read/ReadRoute';
import SignUpRoute from './SignUp/SignUpRoute';
import SocialAuthErrorRoute from './SocialAuthError/SocialAuthErrorRoute';
import SpecialsRoute from './Specials/Route';
import StandardRoute from './Standard/StandardRoute';
import StudentBinderRoute from './StudentBinder/StudentBinderRoute';
import SubjectsRoute from './Subjects/Route';
import TextSetsRoute from './TextSets/Route';
import UnitsRoute from './Unit/UnitsRoute';
import YourContentRoute from './YourContent/YourContentRoute';

/**
 * Break up the app into separate "page" level routes.
 *
 * Each route should use dynamic imports so webpack can bundle them as a code split point.
 * This minimizes the app's initial bundle size and causes visitors to incrementally
 * download the app one route at a time via asynchronous lazy loading.
 */

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        {AdminTermsOfUseRoute}
        {AssignmentRoute}
        {AssignmentsRoute}
        {BinderRoute}
        {BrowseRoute}
        {CanvasRoute}
        {ChironRoutes}
        {DashRoutes}
        {LessonSparkRoute}
        {NewsRoute}
        {PollRoute}
        {ReadRoute}
        {StandardRoute}
        {SignUpRoute}
        {StudentBinderRoute}
        {SubjectsRoute}
        {SocialAuthErrorRoute}
        {TextSetsRoute}
        {UnitsRoute}
        {LibraryRoute}
        {SpecialsRoute}
        {YourContentRoute}
        {ExternalEmbedRoute}
        {EducatorCenterRoute}
        {NotFoundRoute}
      </Switch>
    );
  }
}

export default Routes;
