import {studentTabActionTypes as types} from '../constants';

export function getBinderStudentsRequest() {
  return {type: types.BINDER_STUDENTS_LOAD_REQUEST};
}

export function getBinderStudentsSuccess(payload) {
  return {type: types.BINDER_STUDENTS_LOAD_SUCCESS, payload};
}

export function getBinderStudentsFailure(error) {
  return {type: types.BINDER_STUDENTS_LOAD_FAILURE, error};
}

export function getCurrentEstimatedGradeRequest() {
  return {type: types.CURRENT_ESTIMATED_GRADE_LOAD_REQUEST};
}

export function getCurrentEstimatedGradeSuccess(payload) {
  return {type: types.CURRENT_ESTIMATED_GRADE_LOAD_SUCCESS, payload};
}

export function getCurrentEstimatedGradeFailure(error) {
  return {type: types.CURRENT_ESTIMATED_GRADE_LOAD_FAILURE, error};
}

export function initializeStudentTab(binderFilters, showAssignedWorkOnly) {
  return {type: types.INITIALIZE_STUDENT_TAB, payload: {binderFilters, showAssignedWorkOnly}};
}

export function requestStudentReadingLevels(classrooms, binderFilters) {
  return {type: types.REQUEST_READING_LEVELS, payload: {classrooms, binderFilters}};
}

export function getStudentReadingLevelsRequest() {
  return {type: types.STUDENT_READING_LEVELS_LOAD_REQUEST};
}

export function getStudentReadingLevelsSuccess(payload) {
  return {type: types.STUDENT_READING_LEVELS_LOAD_SUCCESS, payload};
}

export function getStudentReadingLevelsFailure(error) {
  return {type: types.STUDENT_READING_LEVELS_LOAD_FAILURE, error};
}

export function getBinderStandardsRequest() {
  return {type: types.BINDER_STANDARDS_LOAD_REQUEST};
}

export function getBinderStandardsSuccess(payload) {
  return {type: types.BINDER_STANDARDS_LOAD_SUCCESS, payload};
}

export function getBinderStandardsFailure(error) {
  return {type: types.BINDER_STANDARDS_LOAD_FAILURE, error};
}

export function initializeStandards(binderFilters, showAssignedWorkOnly) {
  return {type: types.INITIALIZE_STANDARDS, payload: {binderFilters, showAssignedWorkOnly}};
}
