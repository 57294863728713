import {articleHeaderClassroomActionTypes as types} from './../constants';

/*
  ArticleHeaderClassroom Actions
  REQUEST ArticleHeaderClassroom
*/

export function requestArticleHeaderClassrooms(queryParams) {
  return {
    type: types.ARTICLE_HEADER_CLASSROOMS_LOAD_REQUEST,
    queryParams
  };
}

export function loadArticleHeaderClassroomsSuccess(data) {
  return {
    type: types.ARTICLE_HEADER_CLASSROOMS_LOAD_SUCCESS,
    articleHeaderClassrooms: data,
    receivedAt: Date.now()
  };
}

export function loadArticleHeaderClassroomsFailure() {
  return {
    type: types.ARTICLE_HEADER_CLASSROOMS_LOAD_FAILURE,
    articleHeaderClassrooms: [],
    receivedAt: Date.now()
  };
}
