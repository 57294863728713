import React from 'react';

import {useSelector} from 'react-redux';

import {
  ESSENTIALS_MENU_ITEM,
  ProductLicenseKeysEnum,
  SUBJECT_AREA_ITEMS
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';
import {
  SubjectItem
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SubjectItems/SubjectItem';
import {
  productVisibilityBreakpointCss,
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SubjectItems/SubjectItems.styles';

import {getUser, getUserProperties} from '../../../../../modules/selectors/userSelectors';
import {useGetProductsVisibilityBreakpoint} from '../shared/utils';

const SubjectItems = () => {
  const user = useSelector((state) => getUser(state));
  const {
    isStudent,
    isLiteUser,
    activeELALicense,
    hasActivePremiumTrial,
    activeScienceLicense,
    activeSSLicense,
    activeEssentialsLicense,
    getExperimentSegment,
  } = useSelector((state) => getUserProperties(state));
  const showLockIconsExperiment = getExperimentSegment('show_lock_icons');
  const showLicenseItemWithLock = showLockIconsExperiment === 'on';

  const visibilityBreakpoint = useGetProductsVisibilityBreakpoint();

  if (!user?.is_authenticated) return null;

  const licensesMap = {
    [ProductLicenseKeysEnum.SS]: activeSSLicense,
    [ProductLicenseKeysEnum.ELA]: activeELALicense,
    [ProductLicenseKeysEnum.SCI]: activeScienceLicense,
  };

  const isLiteOrTrial = hasActivePremiumTrial || isLiteUser;

  return (
    <>
      {activeEssentialsLicense && (
        <li className={productVisibilityBreakpointCss(isLiteOrTrial, visibilityBreakpoint)}>
          <SubjectItem
            href={ESSENTIALS_MENU_ITEM.href}
            title={ESSENTIALS_MENU_ITEM.title}
            SvgComponent={ESSENTIALS_MENU_ITEM.SvgComponent}
            locked={false}
            dataQASelector={ESSENTIALS_MENU_ITEM.dataQASelector}
          />
        </li>
      )}
      {SUBJECT_AREA_ITEMS
        .filter(({license}) => licensesMap[license] || (!isStudent && showLicenseItemWithLock))
        .map(({title, href, SvgComponent, dataQASelector, license}, index) => (
          <li key={index} className={productVisibilityBreakpointCss(isLiteOrTrial, visibilityBreakpoint)}>
            <SubjectItem
              href={href}
              title={title}
              SvgComponent={SvgComponent}
              locked={!licensesMap[license]}
              dataQASelector={dataQASelector}
            />
          </li>
        ))}
    </>
  );
};

SubjectItems.displayName = 'SubjectItems';
export {SubjectItems};
