/**
 * Page elements represent deviations from the default Read Page functionality.
 * They should only be used in a mode object if they represent a deviation from the norm.
 * Ex. HIDE_WORD_COUNT because the word count is visible by default.
 * Ex. INCLUDE_MINIMAL_TOOLBAR because the minimal toolbar is not visible by default.
 */

// Page Element options
const pageElements = {
  HIDE_MASTHEAD: 'HIDE_MASTHEAD',
  HIDE_FOOTER: 'HIDE_FOOTER',
  HIDE_TOOLBAR: 'HIDE_TOOLBAR',
  INCLUDE_MINIMAL_TOOLBAR: 'INCLUDE_MINIMAL_TOOLBAR',
  HIDE_PRESENTATION_MODE_BUTTON: 'HIDE_PRESENTATION_MODE_BUTTON',
  HIDE_TEXT_SET_BUTTON: 'HIDE_TEXT_SET_BUTTON',
  HIDE_ARTICLE_LABEL: 'HIDE_ARTICLE_LABEL',
  INCLUDE_SPECIAL_NEWSELA_BRANDING: 'INCLUDE_SPECIAL_NEWSELA_BRANDING', // large centered logo.
  HIDE_NEWSELA_BRANDING: 'HIDE_NEWSELA_BRANDING',
  HIDE_ALL_ACTIVITIES: 'HIDE_ALL_ACTIVITIES',
  HIDE_LESSON_SPARKS: 'HIDE_LESSON_SPARKS',
  HIDE_ANNOTATIONS: 'HIDE_ANNOTATIONS',
  HIDE_JOIN_BUTTON: 'HIDE_JOIN_BUTTON',
  DISABLE_ARTICLE_LIMIT: 'DISABLE_ARTICLE_LIMIT', // Disable hiding full articles from non-Pro users.
  HIDE_LEFT_RAIL: 'HIDE_LEFT_RAIL',
  HIDE_CREATE_ASSIGNMENT_CTA: 'HIDE_CREATE_ASSIGNMENT_CTA',
  HIDE_RELATED_CONTENT: 'HIDE_RELATED_CONTENT',
  HIDE_WORD_COUNT: 'HIDE_WORD_COUNT',
  TRACK_READING_PROGRESS: 'TRACK_READING_PROGRESS',
  HIDE_PRO_VEILS: 'HIDE_PRO_VEILS',
  HIDE_CONTENT_BANNERS: 'HIDE_CONTENT_BANNERS',
  HIDE_ADMIN_BAR: 'HIDE_ADMIN_BAR',
  HIDE_ASSESSMENT_PANEL: 'HIDE_ASSESSMENT_PANEL',
  HIDE_ASSIGNMENT_SUMMARY: 'HIDE_ASSIGNMENT_SUMMARY',
  HIDE_ACTIVITIES_PANEL: 'HIDE_ACTIVITIES_PANEL',
  UNSTICK_MASTHEAD_MOBILE: 'UNSTICK_MASTHEAD_MOBILE',
  HIDE_ARTICLE_ACTION_LIST: 'HIDE_ARTICLE_ACTION_LIST',
  HIDE_LOADING_CONTENT: 'HIDE_LOADING_CONTENT',
};

// Mode page elements

const PRESENTATION = {
  HIDE_LESSON_SPARKS: true,
  HIDE_LEFT_RAIL: true,
  HIDE_CREATE_ASSIGNMENT_CTA: true,
  HIDE_ADMIN_BAR: true,
  HIDE_ASSESSMENT_PANEL: true,
  HIDE_ASSIGNMENT_SUMMARY: true,
  HIDE_RELATED_CONTENT: true,
  HIDE_TEXT_SET_BUTTON: true,
};

const VIEW = {
  ...PRESENTATION,
  HIDE_LESSON_SPARKS: false,
  HIDE_PRESENTATION_MODE_BUTTON: true,
};

// External Embed
// TODO: perhaps this one and other customer specific customizations should be a 'State' that can
// co-exist with 'Modes'
const MINIMAL_PAGE_LAYOUT_MODE = {
  HIDE_FOOTER: true,
  HIDE_MASTHEAD: true,
  HIDE_TOOLBAR: true,
  HIDE_ARTICLE_LABEL: true,
  INCLUDE_SPECIAL_NEWSELA_BRANDING: true,
  HIDE_ALL_ACTIVITIES: true,
  HIDE_ANNOTATIONS: true,
  HIDE_JOIN_BUTTON: true,
  DISABLE_ARTICLE_LIMIT: true,
  HIDE_LEFT_RAIL: true,
  HIDE_CREATE_ASSIGNMENT_CTA: true,
  HIDE_RELATED_CONTENT: true,
  HIDE_WORD_COUNT: true,
  DISABLE_TRACK_READING_PROGRESS: true,
  HIDE_ADMIN_BAR: true,
  HIDE_ACTIVITIES_PANEL: true,
};

const GOOGLE_CLASSROOM_ASSIGNMENT_REVIEW = {
  HIDE_MASTHEAD: true,
  HIDE_CREATE_ASSIGNMENT_CTA: true,
  HIDE_RELATED_CONTENT: true,
};

const CANVAS_ASSIGNMENT_REVIEW_MODE = {
  HIDE_FOOTER: true,
  HIDE_MASTHEAD: true,
  HIDE_TOOLBAR: true,
  INCLUDE_MINIMAL_TOOLBAR: true,
  HIDE_JOIN_BUTTON: true,
  HIDE_LEFT_RAIL: true,
  HIDE_CREATE_ASSIGNMENT_CTA: true,
  HIDE_RELATED_CONTENT: true,
  DISABLE_TRACK_READING_PROGRESS: true,
  HIDE_ADMIN_BAR: true,
  HIDE_ASSIGNMENT_SUMMARY: true,
};

const GRADING_MODE = {
  HIDE_LESSON_SPARKS: false,
  HIDE_PRESENTATION_MODE_BUTTON: true,
  HIDE_TEXT_SET_BUTTON: true,
  HIDE_TOOLBAR: true,
  HIDE_ARTICLE_ACTION_LIST: true,
  HIDE_MASTHEAD: true,
};

const VIEW_PAGE_REDIRECT_LAYOUT_MODE = {
  DISABLE_ARTICLE_LIMIT: true,
  DISABLE_TRACK_READING_PROGRESS: true,
  HIDE_ACTIVITIES_PANEL: true,
  HIDE_ADMIN_BAR: true,
  HIDE_ALL_ACTIVITIES: true,
  HIDE_ANNOTATIONS: true,
  HIDE_ARTICLE_ACTION_LIST: true,
  HIDE_ARTICLE_LABEL: true,
  HIDE_ASSESSMENT_PANEL: true,
  HIDE_ASSIGNMENT_SUMMARY: true,
  HIDE_CONTENT_BANNERS: true,
  HIDE_CREATE_ASSIGNMENT_CTA: true,
  HIDE_FOOTER: true,
  HIDE_JOIN_BUTTON: true,
  HIDE_LEFT_RAIL: true,
  HIDE_LESSON_SPARKS: true,
  HIDE_LOADING_CONTENT: true,
  HIDE_NEWSELA_BRANDING: true,
  HIDE_PRESENTATION_MODE_BUTTON: true,
  HIDE_PRO_VEILS: true,
  HIDE_RELATED_CONTENT: true,
  HIDE_TEXT_SET_BUTTON: true,
  HIDE_TOOLBAR: true,
  HIDE_WORD_COUNT: true,
};

const modes = {
  VIEW,
  GOOGLE_CLASSROOM_ASSIGNMENT_REVIEW,
  PRESENTATION,
  MINIMAL_PAGE_LAYOUT_MODE,
  CANVAS_ASSIGNMENT_REVIEW_MODE,
  GRADING_MODE,
  VIEW_PAGE_REDIRECT_LAYOUT_MODE,
};

export {
  pageElements,
  modes
};
