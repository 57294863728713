import {filter, get, isEmpty, maxBy} from 'lodash-es';
import moment from 'moment';

const rosterSyncKey = 'v2.services.objects.rosterSync';
const rosterDetailsKey = 'v2.services.objects.rosterDetails';

/**
 * @param {Object} state
 * @param {String} authProvider
 * @returns {Object}
 */
export const getRosterInfoByProvider = (state, authProvider) => {
  const rosterSyncInfo = filter(get(state, `${rosterSyncKey}.items`), (e) => e.provider === authProvider);
  return maxBy(rosterSyncInfo, (item) => moment(item.time_started));
};

export const isRosterInfoLoading = (state) => get(state, `${rosterSyncKey}.isLoading`);

/**
 * @param {Object} state
 * @returns {Array.object}
 */
export const getRosterInfo = (state) => get(state, `${rosterSyncKey}.items`);

/**
 *
 * @param {Object} state
 * @param {String} authProvider
 * @returns {boolean}
 */
export const isSyncCompleted = (state, authProvider) => {
  const providerInfo = getRosterInfoByProvider(state, authProvider);
  return isEmpty(providerInfo) || Boolean(get(providerInfo, 'time_completed'));
};


/**
 * @param {Object} state
 * @returns {String}
 */
export const getLatestInitiatedSync = (state) => get(state, `${rosterSyncKey}.latestInitiatedSync`);

/**
 * @param {Object }state
 * @param {String} provider
 * @returns {Object}
 */
export const getRosterProviderDetails = (state, provider) => get(
  state, `${rosterDetailsKey}.${provider}`, {}
);

/**
 *
 * @param {Object }state
 * @param {String} provider
 * @returns {Boolean}
 */
export const areRosterProviderDetailsLoading = (state, provider) => {
  if (provider === 'forms' || !provider) {
    return false;
  } else {
    return get(state, `${rosterDetailsKey}.${provider}.fetching`, true);
  }
};
