import {EWOTS_DISMISSAL} from '../constants/ewotsActionTypes';

function dismissedEwots(state = {}, action) {
  if (action.type === EWOTS_DISMISSAL) {
    const ewotsName = action.payload;
    return {
      ...state,
      [ewotsName]: true,
    };
  } else {
    return state;
  }
}

export default dismissedEwots;
