import React from 'react';

import './publicPath'; // the public path MUST be imported first!
import 'whatwg-fetch'; // For IE11 and older
import 'url-search-params-polyfill'; // For IE and Safari 10 and older
import {createBrowserHistory} from 'history';
import {set} from 'lodash-es';
// Material UI Theming
import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {Router} from 'react-router';

// Tracking

// Redux Store configuration & wireup
import GlobalLayout from 'components/GlobalLayout';
import globalToasterRef from 'constants/globalToasterRef';
import {getLikedContent, fetchCollections} from 'modulesV2/actions';
import Footer from 'static/three-oh/src/components/Footer/Footer';
import {ProviderForUseClientSideRoutingHasOccurred} from 'utils/useClientSideRoutingHasOccurred';

import RoutesContainer from './RoutesContainer';
import {trackPageView} from '../../utils/sessionActivity';
import CreateClassContainer from '../containers/CreateClassContainer';
import {requestStudies} from '../modules/actions/studiesActions';
import {requestCurrentUser} from '../modules/actions/userActions';
import configureStore from '../store/configureStore';

/**
 * This entrypoint for Webpack is used to allow non-React pages to consume
 * React components. We do this for things like the masthead, sign modals,
 * and banners for pages that may still be primarily driven by Angular. If
 * you need a component to renderable on ALL pages, you should add it here
 * and ensure that componentsForNonReactPages.js is in the HTML template for
 * that page.
 */


// Configure Redux store and Material UI Theme
const store = configureStore();
const muiTheme = getMuiTheme(lightBaseTheme);
store.dispatch(requestCurrentUser());
store.dispatch(getLikedContent());

// Define render functions for components
const renderGlobalNav = () => {
  /**
    * add accessibility blue ring focus if user is tabbing around
    */
  const handleFirstTab = (e) => {
    if (e.keyCode === 9 || e.keyCode === 13 || e.keyCode === 27) {
      document.body.classList.add('user-is-tabbing');

      window.removeEventListener('keydown', handleFirstTab);
      window.addEventListener('mousedown', handleFirstClick);// eslint-disable-line no-use-before-define
    }
  };

  const handleFirstClick = () => {
    document.body.classList.remove('user-is-tabbing');

    window.removeEventListener('mousedown', handleFirstClick);
    window.addEventListener('keydown', handleFirstTab);
  };

  window.addEventListener('keydown', handleFirstTab);
  window.addEventListener('mousedown', handleFirstClick);

  const masthead = document.getElementById('react-masthead');
  const editorView = document.getElementById('editor');

  if (masthead && !editorView) {
    const store = configureStore();
    store.dispatch(requestCurrentUser());
    store.dispatch(fetchCollections());
    store.dispatch(requestStudies());

    const history = createBrowserHistory();
    const contentId = 'contentContainer';

    ReactDOM.render(
      <Provider store={store}>
        <Router history={history}>
          <ProviderForUseClientSideRoutingHasOccurred>
            <GlobalLayout
              history={history}
              skipToId={contentId}
              main={<RoutesContainer contentId={contentId} />}
            />
          </ProviderForUseClientSideRoutingHasOccurred>
        </Router>
      </Provider>,
      masthead
    );
  }
};

const renderCreateClassForm = () => {
  const createClassForm = document.getElementById('createClassForm');
  if (createClassForm) {
    const store = configureStore();
    store.dispatch(requestCurrentUser());
    ReactDOM.render(
      <MuiThemeProvider muiTheme={muiTheme}>
        <Provider store={store}>
          <CreateClassContainer hideHeader/>
        </Provider>
      </MuiThemeProvider>,
      createClassForm
    );
  }
};

const renderFooter = () => {
  const indicator = document.getElementById('footer');
  if (indicator) {
    ReactDOM.render(
      <Footer />,
      indicator
    );
  }
};

// Call component render functions.
renderGlobalNav();
renderFooter();

window.renderCreateClassForm = () => {
  renderCreateClassForm();
};

// Set NewselaSessionActivity on window for non-React environments
set(window, 'newsela.sessionActivity.trackPageView', trackPageView);

window.globalToasterRef = globalToasterRef;
