import * as types from '../constants/licenseActionTypes';

export function getLicenseRequest() {
  return {
    type: types.LICENSE_LOAD_REQUEST,
    receivedAt: Date.now(),
  };
}

export function getLicenseSuccess(data) {
  return {
    type: types.LICENSE_LOAD_SUCCESS,
    data,
    receivedAt: Date.now(),
  };
}

export function getLicenseFailure(error) {
  return {
    type: types.LICENSE_LOAD_FAILURE,
    error,
    receivedAt: Date.now(),
  };
}
