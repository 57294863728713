import {times, sample} from 'lodash-es';

// List of Historical Names (Lname, Fname) for use in the dummy data.
const historicalFirstNames = [
  'Jane',
  'William',
  'Shirley',
  'Joe',
  'Mildred',
  'Amelia',
  'Alexander',
  'Dorothy',
  'Grace',
  'Bo',
  'Alan',
];

const historicalLastNames = [
  'Austen',
  'Blake',
  'Chisholm',
  'DiMaggio',
  'Dresselhaus',
  'Earhart',
  'Hamilton',
  'Height',
  'Hopper',
  'Jackson',
  'Turing',
];

export function getStudentTabDummyData() {
  // Let's fake 9 pieces of student data for the Student Tab!
  return times(9, (index) => {
    const level = sample([2, 4]);
    return {
      achievement_bucket: `level_${level}`,
      annotationsPerHeader: 1.5,
      'count:annotations': 3,
      'count:header_views': 6,
      'count:quizzes': 6,
      'count:write_response_score': 2,
      'avg:quiz_score': 25,
      'avg:write_response_score': 1.5,
      currentReadingLevel: 3.97,
      currentReadingLevelConfidence: null,
      percentile: null,
      student_id: index,
      student: {
        user: {
          last_name: historicalLastNames[index],
          first_name: historicalFirstNames[index]
        }
      }
    };
  });
}
