
import React from 'react';

import PropTypes from 'prop-types';

import {ReactComponent as KeySvg} from 'staticImages/icons/Key.svg';

import style from './style';

const proBadgeProps = {
  /**
   * additional class names to be applied to the containing div
   */
  additionalClassNames: PropTypes.arrayOf(PropTypes.string),

  /**
   * breakpoints. these map to the breakpoints defined in contants/BreakPoints
   * You can pass raw css through as a string or, you can simply specify the width
   * that you want the badge to be. 99% of the time you will want just the width.
   */
  breakpoints: PropTypes.shape({
    xsmall: PropTypes.shape({
      width: PropTypes.string,
      __rawCss: PropTypes.string,
    }),
    small: PropTypes.shape({
      width: PropTypes.string,
      __rawCss: PropTypes.string,
    }),
    medium: PropTypes.shape({
      width: PropTypes.string,
      __rawCss: PropTypes.string,
    }),
    large: PropTypes.shape({
      width: PropTypes.string,
      __rawCss: PropTypes.string,
    }),
  }),

  /**
   * The badge's width, in rem.
   * Defaults to 4.5 rem.
  */
  width: PropTypes.string,

};

export const ProBadge = ({width, additionalClassNames = [], breakpoints}) => {
  return (
    <div
      className={`${style(width, breakpoints)} ${additionalClassNames.join(' ')}`}
    >
      <KeySvg/>
    </div>
  );
};

ProBadge.defaultProps = {
  width: '4.5rem',
};
ProBadge.propTypes = proBadgeProps;
export default ProBadge;
