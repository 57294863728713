import React from 'react';

import {Button, ChevronLeftSVG, ChevronRightSVG, Icon} from '@newsela/angelou';
import PropTypes from 'prop-types';

import {
  NavButtonWithPopout
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/NavButtonWithPopout';
import {
  buttonCss,
  chevronLeftCss,
  chevronRightCss,
  openedSubMenuPopoutIndicatorCss,
  popoutButtonListItemCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/PopoutSectionItemWithPopout/PopoutSectionItemWithPopout.styles';

const propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
  rootCustomCss: PropTypes.object,
  position: PropTypes.object.isRequired,
  PopoutComponent: PropTypes.node.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

const PopoutSectionItemWithPopout = ({
  id,
  isOpen,
  setIsOpen,
  position,
  buttonText,
  rootCustomCss = {},
  PopoutComponent
}) => {
  return (
    <NavButtonWithPopout
      id={id}
      isSubNavMenu
      isOpen={isOpen}
      rootCustomCss={rootCustomCss}
      setIsOpen={setIsOpen}
      position={position}
      ButtonComponent={(
        <div className={popoutButtonListItemCss}>
          <Button
            __cssFor={buttonCss}
            flavor={Button.flavors.incognito}
            __classNameFor={{root: id}}
            onClick={() => setIsOpen((isOpen) => !isOpen)}
          >
            {isOpen && <div style={openedSubMenuPopoutIndicatorCss} />}
            <Icon
              SvgComponent={ChevronLeftSVG}
              __cssFor={chevronLeftCss}
              __classNameFor={{root: `${id} sub-nav-menu-button`}}
            />

            {buttonText}

            <Icon
              SvgComponent={ChevronRightSVG}
              __cssFor={chevronRightCss}
              __classNameFor={{root: id}}
            />
          </Button>
        </div>
      )}
      PopoutComponent={PopoutComponent}
    />
  );
};


PopoutSectionItemWithPopout.propTypes = propTypes;
PopoutSectionItemWithPopout.displayName = 'PopoutSectionItemWithPopout';
export {PopoutSectionItemWithPopout};
