import ApiFetcher3 from '../ApiFetcher3';

class ContentHideApi {
  static get({content_id, classroom_id, created_by}) {
    const params = removeFalsyParams({content_id, classroom_id, created_by});
    return ApiFetcher3.get('content-hide/', params);
  }

  static save({content_id, classroom_id}) {
    const data = {content_id, classroom_id};
    return ApiFetcher3.post('content-hide/', {data});
  }

  static delete({contentHideId}) {
    return ApiFetcher3.delete(`content-hide/${contentHideId}/`);
  }
}

function removeFalsyParams(params) {
  for (const key of Object.keys(params)) {
    if (!params[key]) {
      delete params[key];
    }
  }
  return params;
}

export default ContentHideApi;
