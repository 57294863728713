import ApiFetcher2 from '../ApiFetcher2';

class SchoolApi {
  static getById(id) {
    return ApiFetcher2.get(`school/${id}`);
  }

  static getByQuery(query) {
    return ApiFetcher2.get('school/', {
      params: query
    });
  }

  static createSchool(payload) {
    return ApiFetcher2.post('school/', {
      config: {basePath: '/api/v2/'},
      data: payload
    });
  }
}

export default SchoolApi;
