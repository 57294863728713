import {get} from 'lodash-es';

/**
 * @param {object} state
 * @returns {object}
 */
export const getUserStream = (state) => get(state, 'userStream');

/**
 * @param {object} state
 * @returns {string}
 */
export const getDateLastViewed = (state) => get(getUserStream(state), 'date_last_viewed', null);
