import React from 'react';

import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';

/**
 * Browse pages (and potentially new content pages like bundles)
 * are adopting a new url pattern: /content/:type/:slug/:content_id
 * This currently is used to ensure we can pre-determine the page
 * types for the content we'd like to load.
 *
 * PW - 07/02/2020
 */

const LazyBrowseContainer = Loadable({
  loader: () => import(
    /* webpackChunkName: "browse" */
    './containers/Container'
  ),
  loading: () => null
});

const BrowseRoute = (
  <Route
    component={LazyBrowseContainer}
    key="browse"
    path="/content/:type/:slug/:contentId"
  />
);

export default BrowseRoute;
