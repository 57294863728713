import React from 'react';

import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import PropTypes from 'prop-types';

import uiColors, {brandColors} from 'static/three-oh/src/components/Colors/colors';

import backgroundImage from './assets/rocket-dialog-background.png';

if (window && window._static_url) { // eslint-disable-line no-undef
  // Include this to appropriatly fetch images in the assets folder.
  __webpack_public_path__ = window._static_url; // eslint-disable-line no-undef
}

const propTypes = {
  buttonText: PropTypes.string,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
};

const defaultProps = {
  buttonText: 'GOT IT, THANKS',
  isOpen: false,
};

const actionStyles = {
  backgroundColor: brandColors.brand_primary,
  textAlign: 'center',
  padding: '0 .5rem 2rem',
};


const buttonStyles = {
  textAlign: 'center',
  backgroundColor: brandColors.brand_primary,
  color: uiColors.white[1],
  width: 'auto',
};

const imageStyles = {
  backgroundColor: brandColors.brand_primary,
  minHeight: '171px',
};

const contentStyles = {
  backgroundColor: brandColors.brand_primary,
  display: 'flex',
  flexDirection: 'column',
  WebkitFlexDirection: 'column',
  alignItems: 'center',
  padding: '2rem 0',
};

class RocketDialog extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      isOpen: this.props.isOpen
    };
  }

  handleClose() {
    this.setState({isOpen: false});
  }

  render() {
    const actions = [
      <FlatButton
        label={this.props.buttonText}
        primary
        onClick={this.handleClose}
        style={buttonStyles}
        key="rocketDialogCloseButton"
      />
    ];
    return (
      <Dialog
        actions={actions}
        actionsContainerStyle={actionStyles}
        open={this.state.isOpen}
        bodyStyle={{padding: 0, margin: 0}}
        onRequestClose={this.handleClose}
        contentStyle={{maxWidth: 532}}
      >
        <div style={imageStyles}>
          <img src={backgroundImage} alt="A rocketship taking off" />
        </div>
        <div style={contentStyles}>
          {this.props.children}
        </div>
      </Dialog>
    );
  }
}

RocketDialog.propTypes = propTypes;
RocketDialog.defaultProps = defaultProps;

export default RocketDialog;
