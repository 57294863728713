import React from 'react';

import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import {delay, isEmpty, includes} from 'lodash-es';
import noScroll from 'no-scroll';
import PropTypes from 'prop-types';
import {createPortal} from 'react-dom';
import CSSTransition from 'react-transition-group/CSSTransition';

import Button from 'static/three-oh/src/components/Button/Button';

import styles from './styles.scss';

const propTypes = {
  // --- REQUIRED ---

  // Fired when the modal is requested to be closed
  onClose: PropTypes.func.isRequired,

  // Controls if the modal is open or not
  open: PropTypes.bool.isRequired,

  // --- OPTIONAL ---

  // Css class for overlay
  additionalOverlayClass: PropTypes.string,

  // If true, the modal is vertically centered (looks best on modals that have a smallish height)
  centered: PropTypes.bool,

  // The contents of the modal
  children: PropTypes.node,

  // Css class name for the modal
  className: PropTypes.string,

  // If true, the modal will close by pressing the ESC key
  closeOnEsc: PropTypes.bool,

  // If true, the modal will close when user clicks anywhere outside the modal
  closeOnOverlayClick: PropTypes.bool,

  // If true, we'll disable any focus traps wrapping the modal.
  disableFocusTrap: PropTypes.bool,

  // Callback for extra actions to happen on overlay click
  handleOverlayClick: PropTypes.func,

  // A selector string, which will be passed to document.querySelector(), to find
  // the element that should automatically receive focus when the modal opens
  initialFocus: PropTypes.string,

  // Determines how the modal animates when opening
  transition: PropTypes.oneOf(['popIn', 'slideDown']),

  // If true, render clickable 'close' button.
  withCloseButton: PropTypes.bool,
};

const defaultProps = {
  centered: false,
  children: null,
  closeOnEsc: true,
  closeOnOverlayClick: true,
  disableFocusTrap: false,
  initialFocus: `.${styles.modal}`,
  transition: 'slideDown',
  withCloseButton: false,
};

const ESCAPE_KEY = 27;

class Modal extends React.Component {
  state = {
    portalIsOpen: this.props.open
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleEscKey);
  }

  componentWillReceiveProps(nextProps) {
    const isOpening = !this.props.open && nextProps.open;
    const isClosing = this.props.open && !nextProps.open;

    if (isOpening) {
      this.setState({portalIsOpen: true}, this.blockScrolling);
    } else if (isClosing) {
      // Let the portal stay open a little bit longer, allowing time for the
      // overlay fade out transition to fully complete.
      this.timeout = delay(() => {
        this.setState({portalIsOpen: false}, this.unblockScrolling);
      }, 300);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEscKey);
    this.unblockScrolling();
    clearTimeout(this.timeout);
  }

  handleEscKey = (keyboardEvent) => {
    if (keyboardEvent.keyCode === ESCAPE_KEY && this.props.closeOnEsc && this.state.portalIsOpen) {
      this.props.onClose();
    }
  };

  blockScrolling = () => {
    noScroll.on();
  };

  unblockScrolling = () => {
    const otherOpenModals = document.getElementsByClassName(styles.modal);
    if (isEmpty(otherOpenModals)) {
      noScroll.off();
    }
  };

  handleOverlayClick = (clickEvent) => {
    if (!this.props.closeOnOverlayClick) {
      return;
    }

    // Check for a direct click on the actual overlay element.
    // Ignore clicks on the modal, which is technically a child of the overlay.
    if (includes(clickEvent.target.className, styles.overlay)) {
      if (this.props.handleOverlayClick) {
        this.props.handleOverlayClick();
      }
      clickEvent.stopPropagation();
      this.props.onClose();
    }
  };

  render() {
    const {
      open,
      children,
      className,
      transition,
      centered,
      initialFocus,
      withCloseButton,
      additionalOverlayClass,
    } = this.props;
    const {portalIsOpen} = this.state;

    if (window.location.pathname.includes('/apps/')) {
      // Apps endpoint is rendered third party iframes. Make sure we don't
      // render modals in those cases.
      return null;
    }

    if (!portalIsOpen) {
      return null; // The modal is a lie.
    }

    const overlayClassName = classNames(styles.overlay, {
      [styles.centered]: centered,
    }, additionalOverlayClass);

    const modalClassName = classNames(styles.modal, className, styles[transition]);
    const modal = (
      <div
        key="overlay"
        className={overlayClassName}
        onClick={this.handleOverlayClick}
      >
        <div className={modalClassName} tabIndex="-1">
          {
            withCloseButton &&
            <div className={styles.header}>
              <Button
                secondary
                icon={<i className="fa fa-times" />}
                onClick={this.props.onClose}
                className={styles.closeButton}
              />
            </div>
          }
          {children}
        </div>
      </div>
    );

    const portal = (
      <FocusTrap
        focusTrapOptions={{initialFocus}}
        paused={this.props.disableFocusTrap}
      >
        <CSSTransition
          component="div"
          in={open}
          appear
          enter
          exit
          timeout={300}
          classNames={{
            appear: styles.transitionEnter,
            appearActive: styles.transitionEnterActive,
            enter: styles.transitionEnter,
            enterActive: styles.transitionEnterActive,
            exit: styles.transitionExit,
            exitActive: styles.transitionExitActive,
          }}
        >

          {modal}
        </CSSTransition>
      </FocusTrap>
    );

    return createPortal(portal, document.body);
  }
}

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
