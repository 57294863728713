import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {CreateOrSyncClass} from 'static/three-oh/src/components/CreateClassWidget/UNSTABLE_CreateOrSyncClass';
import {createClassroom} from 'static/three-oh/src/modules/actions/classroomActions';


class CreateClassContainer extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object,
  };

  render() {
    return (
      <CreateOrSyncClass
        {...this.props}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createClassroom,
  }, dispatch
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateClassContainer);
