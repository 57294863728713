import {get, isEmpty, merge, cloneDeep, union, uniq, orderBy} from 'lodash-es';

import {applyTo, applier} from 'utils/lodashHelpers';

import {notificationsActionTypes as types} from '../constants';
import * as objectApiTypes from '../ducks/objectApiDucks';
import {getNamespaceForNotificationRequest} from '../utils/notificationHelpers';

const initialState = {};

function mapNotifications(state, mapper) {
  // This is a helper function used above to handle transformations of
  // all notifications on the state. For example, if a notificatoin needs to be
  // updated or deleted in response to some event. In theory, the Notification
  // API should return immutable notifications that only accrete. State can
  // be calculated by accumulating the list of all notifications. In practice,
  // some of the code base is built to use single Notifications as the
  // source of truth (e.g. Student Quizz scores), so it behooves us to keep the
  // list of Notifications current as a matter of practicality. In the future,
  // it may be possible to entirely generalize interactions between the Object
  // and Notifications APIs and avoid remove the need for the kinds of
  // special-case transformations this function supports.
  //
  // state:
  //   All notification state.
  // mapper: A function that takes a notifications and returns a new one.
  //   Return `null` to remove the notification.
  //   As an efficiency, only create a new notification object if it is
  //   modified. This function will reuse state when possible.
  const newState = {};
  let isAnyNotificationChanged = false;

  // Iterate over each namespace, modifying it if any notification changes.
  for (const namespace in state) {
    let isNotificationChanged = false;

    // Iterate over each notification in the namespace, doing the same.
    const items = state[namespace].items;
    if (items) {
      const newItems = [];
      for (const i in items) {
        const notification = items[i];
        const newNotification = mapper(notification);
        if (notification === newNotification) {
          // Do not mark the state as having changed.
          newItems.push(notification);
        } else {
          // The notification reference has changed.
          isNotificationChanged = true;
          if (newNotification !== null) {
            newItems.push(newNotification);
          }
        }
      }

      if (isNotificationChanged) {
        // Create a new value for this namespace.
        isAnyNotificationChanged = true;
        newState[namespace] = {
          ...state[namespace],
          items: newItems
        };
      } else {
        // Reuse the old state to avoid unnecessary rerenders.
        newState[namespace] = state[namespace];
      }
    }
  }

  if (isAnyNotificationChanged) {
    // Return the modified state.
    return newState;
  } else {
    // Reuse the old state to avoid unnecessary rerenders.
    return state;
  }
}

export default function notifications(state = initialState, action) {
  switch (action.type) {
    case types.INITIALIZE_NOTIFICATIONS: {
      return {...state};
    }
    case types.GET_NOTIFICATIONS_REQUEST: {
      const namespace = getNamespaceForNotificationRequest(action.serializationFilters);
      const newState = {
        ...state,
        [namespace]: {
          isError: false,
          isLoading: true,
          dateRequested: Date.now(),
          items: get(state, `${namespace}.items`, []),
        },
      };
      return {...newState};
    }
    case types.GET_NOTIFICATIONS_SUCCESS: {
      const namespace = getNamespaceForNotificationRequest(action.serializationFilters);
      let notifications = get(state[namespace], 'items', []);
      if (!isEmpty(action.notifications)) {
        notifications = applyTo(notifications)(
          applier(union)(action.notifications.data),
          applier(uniq)('id'),
          applier(orderBy)(['date_created'], ['desc'])
        );
      }

      const newState = {
        ...state,
        [namespace]: {
          isError: false,
          isLoading: false,
          dateRequested: Date.now(),
          items: notifications,
        },
      };
      return newState;
    }
    case types.GET_NOTIFICATIONS_FAILURE: {
      const namespace = getNamespaceForNotificationRequest(action.serializationFilters);
      const newState = {
        ...state,
        [namespace]: {
          isError: true,
          isLoading: false,
          dateRequested: Date.now(),
          error: action.error
        },
      };
      return newState;
    }

    case objectApiTypes.DELETE_SUCCESS: {
      // Respond to objects being deleted and remove their equivalent
      // notification representation.
      switch (action.meta.model) {
        case 'studentQuiz': {
          return mapNotifications(state, (n) => {
            if (
              n.type == 'student-quiz-submission'
              && n.data.studentquiz.id == action.meta.id
            ) {
              return null;
            } else {
              return n;
            }
          });
        }

        // Add similar deletion logic for other models here.

        default: {
          // The deleted object was not of a kind that has notifications.
          return state;
        }
      }
    }

    case objectApiTypes.CREATE_SUCCESS: {
      // Respond to objects being created by the Object middleware.
      // notification representation. This function is implemented with the
      // inefficiency that all notification namespace objects are replaced
      // on every deletion. Deletions are rare, and it simplifies this code.
      switch (action.meta.model) {
        case 'constructedResponseReview': {
          return mapNotifications(state, (n) => {
            if (
              n.type == 'student-write-response'
              && n.data.response.id == action.payload['answer_id']
            ) {
              // Enrich the associated response object.
              return merge({}, n, {
                data: {
                  response: {
                    review: action.payload,
                  }
                }
              });
            } else {
              return n;
            }
          });
        }

        // Add similar creation logic for other models here.

        default: {
          // The created object was not of a kind that has notifications.
          return state;
        }
      }
    }

    case types.UPDATE_STUDENT_WRITE_NOTIFICATION: {
      const namespace = getNamespaceForNotificationRequest(action.serializationFilters);
      let newState = state;

      // Update the review in the corresponding write-activity notification.
      newState = mapNotifications(newState, (notification) => {
        if (notification.id === `ConstructedResponseAnswer:${action.data.answer_id}`) {
          const newNotification = cloneDeep(notification);
          // Only need to update the score, no need to do a whole merge.
          if (notification.data.response.review) {
            newNotification.data.response.review.score = action.data.score;
          } else {
            newNotification.data.response.review = action.data;
          }
          return newNotification;
        } else {
          return notification;
        }
      });

      newState[namespace].isError = false;
      newState[namespace].isLoading = false;
      newState[namespace].dateRequested = get(state[namespace], 'dateRequested', Date.now());

      return newState;
    }
    default:
      return state;
  }
}
