import React from 'react';

import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@newsela/angelou';
import {css, cx} from 'emotion';
import {has} from 'lodash-es';
import PropTypes from 'prop-types';
import {CSSTransition} from 'react-transition-group';

import withTrackEvent from 'static/three-oh/src/components/with/WithTrackEvent';

import {
  messageLevels,
  messageUrls,
  transitionDirections,
} from './constants';

import {
  messageLevel,
  closeCssObject,
} from './styles.jsx';
import styles from './styles.scss';





const messageLevelTypes = Object.values(messageLevels);
const messageUrlTypes = Object.values(messageUrls);
const transitionTypes = Object.values(transitionDirections);
const propTypes = {
  /**
   * classnames appended to the top level container
   */
  additionalClassnames: PropTypes.array,
  /**
   * hides the close button
   */
  hideCloseIcon: PropTypes.bool,
  /**
   * The level of urgency for the message.
   * this will control the color
   */
  level: PropTypes.oneOf(messageLevelTypes).isRequired,
  /**
   * action fired when the x is clicked
   */
  onClose: PropTypes.func,
  /**
   * text to be displayed
   */
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  /**
   * closing functionality for the message in certain
   * application contexts, like within TextSet
   */
  toggleProVeilMessage: PropTypes.func,
  /**
   * direction the component slides in.
   */
  transitionDirection: PropTypes.oneOf(transitionTypes),
  /**
   * url to use within text (optional)
   */
  url: PropTypes.oneOf(messageUrlTypes),
  /**
   * change what is rendered instead of the close action.
   * a function that is passed the classname for the right side
   * icon and an object representing that className, should the
   * consumer want access to the values there. Should return a
   * react element
   */
  __renderRightSide: PropTypes.func,
};

class Message extends React.Component {
  renderWithTransition = (transition, children) => (
    <CSSTransition
      component={React.Fragment}
      in
      appear
      timeout={1000}
      enter={false}
      exit={false}
      classNames={{
        appear: transition,
        appearActive: styles.transitionEnter,
      }}
    >
      {children}
    </CSSTransition>
  )

  handleClose() {
    if (has(this.props, 'onClose')) {
      this.props.onClose();
    }
  }

  render() {
    const {
      additionalClassnames,
      hideCloseIcon,
      level,
      text,
      transitionDirection,
      __renderRightSide,
    } = this.props;
    let classNames = `${styles.messageContainer} ${additionalClassnames.join(' ')}`;

    classNames = cx(css(messageLevel(level)), classNames);

    let transition = false;
    switch (transitionDirection) {
      case transitionDirections.UP:
        transition = styles.transitionUp;
        break;
      case transitionDirections.DOWN:
        transition = styles.transitionDown;
        break;
      default:
        break;
    }

    const closeClass = css(closeCssObject);
    const closeIcon = __renderRightSide ?
      __renderRightSide(closeClass, closeCssObject) :
        (
          <Button
            /* AUTOGENERATED TODO: update angelou to new flavor.
              see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
              for migration guide. */
            legacy_flavor={Button.legacy_flavor.incognito}
            __classNameFor={{
              root: closeClass
            }}
            onClick={() => {
              this.handleClose();
            }}
            ariaProps={{
              'aria-label': 'Close Message box'
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        );

    const textClassNames = `${styles.messageText} ${styles[level]}`;
    const message = (
      <div key="message" role="alert" aria-live="Assertive" className={classNames}>
        <div className={textClassNames}>
          {text}
        </div>
        {
          !hideCloseIcon &&
            closeIcon
        }
      </div>
    );

    return transition ? this.renderWithTransition(transition, message) :
      message;
  }
}

Message.propTypes = propTypes;
Message.defaultProps = {
  additionalClassnames: [],
  hideCLose: false,
  level: messageLevels.info,
  text: '',
};


const messageWithTracking = withTrackEvent(Message);
export default Message;
export {messageWithTracking};
