
import {constants} from '@newsela/angelou';
import {css} from 'emotion';


const angelouUI = constants.colors.ui;

const white = angelouUI.white['500'];
const greyLight = angelouUI.neutral[200];
const greyDark = angelouUI.neutral[600];
const primaryColorGreen = angelouUI.green[600];
const primaryColorBlue = angelouUI.core[100];
const hoverColorBlue = angelouUI.core[500];
const selectedColorBlue = angelouUI.core[600];

const style = css`
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  text-align: center;
  align-items: flex-start;
  transition: all 0.12s;
  box-sizing: border-box;
  border: 0.2em solid transparent;
  border-width: 0.2em;
  border-image: initial;
  display: inline-block;
  cursor: pointer;
  border-radius: 300px;
  &[disabled] {
    cursor: not-allowed;
  }`;

export const textStyle = css`&&{
    font-family: Circular, Helvetica, Arial, sans-serif;
    font-size: 0.875rem;
    font-weight: bold;
    text-decoration: none;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    &&&:hover {
      cursor: pointer;
    }
  }`;

export const textStyleMax = css`&&{
    font-family: Circular, Helvetica, Arial, sans-serif;
    font-size: 0.875rem;
    font-weight: bold;
    text-decoration: none;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    &&&:hover {
      cursor: pointer;
    }
  }`;

export const choiceChip = css`&&{
    min-width: 4rem;
    padding: 0.2rem 1rem;
    background-color: transparent;
    color: ${primaryColorGreen};
    border: solid 0.1rem ${primaryColorGreen};
    line-height: 1.5;
    &&&[disabled], &&&[disabled]:hover {
      background-color: ${primaryColorGreen};
      border-color: ${primaryColorGreen};
      color: ${white};
      opacity: 0.37;
    }
    &&&:hover, &&&:focus {
        background-color: ${primaryColorGreen};
        color: ${white};
    }
}`;

export const buttonSurroundingClose = css`
  height: 24px;
  width: 24px;
  margin-left: 1rem;
`;

export const filterChip = css`
    border-width: 0.15rem;
    height: 2rem;
    min-width: 4rem;
    padding: 0.1rem 0.5rem;
    color: ${white};
    display: flex;
    justify-content: flex-end;
    line-height: 1.7;
    background-color: ${primaryColorBlue};
    &:hover, &:focus {
        background-color: ${hoverColorBlue};
    }`;

export const disabledChip = (chipType) => {
  return css`
        ${chipType === 'FILTER'
          ?
            `&, &:hover {
              padding: 0.2rem 0.5rem;
              background-color: ${greyLight};
              color: ${greyDark};
              opacity: 0.71;
              &&&, &&&:hover {
                cursor: not-allowed;
                label {
                  cursor: not-allowed;
                }
              }
            }`
          :
            `&& {
              &&&, &&&:hover {
                background-color: ${primaryColorGreen};
                border-color: ${primaryColorGreen};
                color: ${white};
                opacity: 0.37;
                cursor: not-allowed;
                label {
                  cursor: not-allowed;
                }
              }`
        }
      }`;
};

export const selectedChip = (chipType) => {
  return css`
    ${chipType === 'FILTER'
      ?
        `&, &:hover, &:focus {
          background-color: ${selectedColorBlue};
        }`
      :
        `&&{
          &&&, &&&:hover, &&&:focus {
          background-color:${primaryColorGreen};
          color: ${white};
        }
      }`
    }
  `;
};

export const closeButtonStyle = css`
    width: 20px;
    height: 16px;
    position: relative;
    top: 0.2rem;
    &:hover, &:focus {
        width: 20px;
        height: 20px;
        top: 0.1rem;
    }`;

export default style;
