import * as types from 'modulesV2/constants';

export const getLikedContent = (data) => ({
  type: types.GET_LIKED_CONTENT_REQUEST,
  data
});

export const getLikedContentSuccess = (data) => ({
  type: types.GET_LIKED_CONTENT_SUCCESS,
  data,
});

export const getLikedContentError = (error) => ({
  type: types.GET_LIKED_CONTENT_ERROR,
  error,
});

export const createLikedContentRequest = (contentId) => ({
  type: types.CREATE_LIKED_CONTENT_REQUEST,
  contentId: contentId,
});

export const createLikedContentRequestSuccess = (data) => ({
  type: types.CREATE_LIKED_CONTENT_SUCCESS,
  data,
});

export const createLikedContentRequestError = (error) => ({
  type: types.CREATE_LIKED_CONTENT_ERROR,
  error,
});

export const deleteLikedContentRequest = (likeId) => ({
  type: types.DELETE_LIKED_CONTENT_REQUEST,
  likeId
});

export const deleteLikedContentRequestSuccess = (likeId) => ({
  type: types.DELETE_LIKED_CONTENT_SUCCESS,
  likeId,
});

export const deleteLikedContentRequestError = (error) => ({
  type: types.DELETE_LIKED_CONTENT_ERROR,
  error,
});
