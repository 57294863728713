exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__color-quiz-correct___cn4Zi{background-color:#52A821}.styles__color-quiz-incorrect___5QLQf{background-color:#D93F31}.styles__color-science___Ffx-1{background-color:#55bca6}.styles__color-kids___dRczM{background-color:#5908f7}.styles__color-money___USIx2{background-color:#fa0045}.styles__color-law___pO5UJ{background-color:#002764}.styles__color-health___EI8pt{background-color:#03a43c}.styles__color-arts___whZ9C{background-color:#E2C925}.styles__color-war-and-peace___85lIH{background-color:#E76A0E}.styles__color-sports___YPOIk{background-color:#000000}.styles__color-opinion___ZuUlm{background-color:#0027f5}.styles__color-text-set___JyITb{background-color:#106FF3}.styles__color-object-gray___jWl15{background-color:#878787}.styles__color-light-gray___HU-sJ{background-color:#F8F8F8}.styles__color-middle-gray___xBOeu{background-color:#BCBCBE}.styles__color-newsela-black___Dfqx6{background-color:#333333}.styles__color-dark-bg___4cs7M{background-color:#031E3E}.styles__color-newsela-blue___A9YGq{background-color:#0768fd}@keyframes styles__placeHolderShimmer___tKf5q{0%{background-position:-468px 0}100%{background-position:468px 0}}.styles__checked___KPtX7,.styles__unchecked___eCZGk{border:2px solid #363636;border-radius:15px;min-width:52px;padding:0 12px;cursor:pointer;font-weight:bold;text-align:center;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;-o-user-select:none;user-select:none}.styles__unchecked___eCZGk{color:#363636}.styles__checked___KPtX7{color:#fff;background-color:#363636}", ""]);

// exports
exports.locals = {
	"color-quiz-correct": "styles__color-quiz-correct___cn4Zi",
	"color-quiz-incorrect": "styles__color-quiz-incorrect___5QLQf",
	"color-science": "styles__color-science___Ffx-1",
	"color-kids": "styles__color-kids___dRczM",
	"color-money": "styles__color-money___USIx2",
	"color-law": "styles__color-law___pO5UJ",
	"color-health": "styles__color-health___EI8pt",
	"color-arts": "styles__color-arts___whZ9C",
	"color-war-and-peace": "styles__color-war-and-peace___85lIH",
	"color-sports": "styles__color-sports___YPOIk",
	"color-opinion": "styles__color-opinion___ZuUlm",
	"color-text-set": "styles__color-text-set___JyITb",
	"color-object-gray": "styles__color-object-gray___jWl15",
	"color-light-gray": "styles__color-light-gray___HU-sJ",
	"color-middle-gray": "styles__color-middle-gray___xBOeu",
	"color-newsela-black": "styles__color-newsela-black___Dfqx6",
	"color-dark-bg": "styles__color-dark-bg___4cs7M",
	"color-newsela-blue": "styles__color-newsela-blue___A9YGq",
	"checked": "styles__checked___KPtX7",
	"unchecked": "styles__unchecked___eCZGk",
	"placeHolderShimmer": "styles__placeHolderShimmer___tKf5q"
};