import React, {useCallback, useEffect, useState, useMemo, useReducer} from 'react';

import PropTypes from 'prop-types';


import {ASSIGNMENT_VIEW_TYPE, CONTENT_SETTINGS_TYPE, determineSRCStateForMembers} from 'static/three-oh/src/modules/utils/assignmentHelpers';
import determineAdvisoryState from 'utils/determineAdvisoryState';

import ApiFetcher3 from '../../src/modules/apis/ApiFetcher3';

const PAGE_SIZE = 12;

const INITIAL_PAGE_NUMBER = 1;

function reducer(viewFilterStatus, action) {
  switch (action.type) {
    case ASSIGNMENT_VIEW_TYPE.DRAFT:
      return {include_drafts: true, include_live: false, viewType: ASSIGNMENT_VIEW_TYPE.DRAFT};
    case ASSIGNMENT_VIEW_TYPE.ASSIGNED :
      return {include_drafts: false, include_live: true, viewType: ASSIGNMENT_VIEW_TYPE.ASSIGNED};
    case ASSIGNMENT_VIEW_TYPE.ALL:
      return {include_drafts: true, include_live: true, viewType: ASSIGNMENT_VIEW_TYPE.ALL};
    default:
      return viewFilterStatus;
  }
}
const withV3AssignmentApiGet = (ComponentToWrap) => {
  const propTypes = {
    id: PropTypes.string,
    lazy: PropTypes.bool,
    params: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  };
  const WrappedWithV3AssignmentsApiGet = ({
    id,
    lazy,
    params,
    ...otherProps
  }) => {
    // Component State:
    // Define the assignments API call state, and load it.
    const [assignmentsApiCallState, setAssignmentsApiCallState] = useState({isLoading: undefined, data: []});
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [hasMoreAssignments, setHasMoreAssignments] = useState(false);
    const [isAscending, setIsAscending] = useState(true);
    const [selectedClassrooms, setSelectedClassrooms] = useState(null);
    const [viewFilterStatus, setViewFilterStatus] = useReducer(reducer, {include_drafts: true, include_live: true, viewType: ASSIGNMENT_VIEW_TYPE.ALL});
    const [sortBy, setSortBy] = useState('newest');
    const handleUpdatePageNumber = (pageNumber) => {
      setCurrentPageNumber(pageNumber);
    };

    const handleResetPageNumber = () => {
      handleUpdatePageNumber(INITIAL_PAGE_NUMBER);
    };

    const loadMoreAssignments = () => {
      setIsLoadingMore(true);
      handleUpdatePageNumber((paginationState) => paginationState + 1);
    };

    const handleDateSorting = (button) => {
      if (button === 'oldest') {
        setIsAscending(false);
      } else {
        setIsAscending(true);
      }
    };

    const handleClassroomSelection = (uniqueID) => {
      setSelectedClassrooms(uniqueID);
      handleResetPageNumber();
    };

    const handleSortByView = (label) => {
      setViewFilterStatus({type: label});
      handleResetPageNumber();
    };

    const userData = otherProps.authenticatedUser || otherProps.user;
    const isStudent = userData?.student;

    // Calculating the params string helps make sure the API loading effect
    // below only re-runs if the actual param string has changed, even if the
    // input params prop references a different object.
    const paramsStr = useMemo(() => {
      const getArchived = !isStudent;
      const isClassSelected = selectedClassrooms !== null;

      // Compute the search params as a string.
      const defaultParamString = `${getArchived ? '&with_archived_classrooms=true' : ''}`;

      if (!params) { return defaultParamString; }

      if (typeof params === 'string') { return `${params}&${defaultParamString}`; }

      return new URLSearchParams({
        with_pagination: true,
        limit: PAGE_SIZE,
        page_number: currentPageNumber,
        ...(isClassSelected ? {classroom_id: selectedClassrooms} : {}),
        ...(getArchived ? {with_archived_classrooms: true} : null),
        sort_by: isAscending ? '-date_modified' : 'date_modified',
        include_drafts: viewFilterStatus.include_drafts,
        include_live: viewFilterStatus.include_live,
        ...params
      }).toString();
    }, [params, currentPageNumber, isAscending, viewFilterStatus]);

    // Component Methods:
    const retrieveAssignmentsApiCallFunc = useCallback(() => {
      let canceled = false;
      const load = async() => {
        setAssignmentsApiCallState((current) => {
          if (current.isLoading && current.data.length === 0) { return current; }
          return {...current, isLoading: true};
        });
        const idPathPortion = id ? `${id}/` : '';
        const isIndividualAssignment = idPathPortion.length > 0;
        try {
          // Make the API call.
          if (isIndividualAssignment || isStudent) {
            const {data} = await ApiFetcher3.get(`assignment/${idPathPortion}`, paramsStr);
            if (canceled) return;
            setAssignmentsApiCallState({data, isLoading: false});
            setIsLoadingMore(false);
          } else {
            const {
              data: {results: fetchedAssignments, has_next: hasMore},
            } = await ApiFetcher3.get(`assignment/${idPathPortion}`, paramsStr);
            if (canceled) return;
            setAssignmentsApiCallState({
              isLoading: false,
              data: currentPageNumber === 1 ? fetchedAssignments : assignmentsApiCallState.data.concat(fetchedAssignments)
            }
            );
            setHasMoreAssignments(hasMore);
            setIsLoadingMore(false);
          }
        } catch (error) {
          if (canceled) return;
          setAssignmentsApiCallState({error: error.message, isLoading: false});
          setIsLoadingMore(false);
        }
      };
      load();
      return () => {
        // Cancel state change, in case the component unmounts, for example.
        canceled = true;
      };
    }, [id, paramsStr, JSON.stringify(userData)]);

    const updateAssignmentAndApiCallState = async(selectedMember, assignment) => {
      // set currently selected member to loading:
      setAssignmentsApiCallState((current) => {
        return {
          ...current,
          updatingAssignmentMember: selectedMember.content.content_id
        };
      });

      const contentSettingsType = getContentSettingsType(selectedMember);
      const assignmentPayload = formatAssignmentPayload(assignment, selectedMember.content.content_id, contentSettingsType);

      try {
        const {data} = await ApiFetcher3.post(`assignment/${assignment.content_id}/`, {data: assignmentPayload});
        setAssignmentsApiCallState({
          isLoading: false,
          data,
          updatingAssignmentMember: null
        });
        setIsLoadingMore(false);
      } catch (error) {
        // error handling here. trigger error toast?
        setAssignmentsApiCallState({
          isLoading: false,
          error,
          updatingAssignmentMember: null
        });
        setIsLoadingMore(false);
      }
    };
    // Component Effects:

    useEffect(() => {
      if (!lazy && !userData?.is_loading) {
        retrieveAssignmentsApiCallFunc();
      }
    }, [id, paramsStr, JSON.stringify(userData)]);
    return (
      <ComponentToWrap
        loadMoreAssignments={loadMoreAssignments}
        isLoadingMore={isLoadingMore}
        handleDateSorting={handleDateSorting}
        handleResetPageNumber={handleResetPageNumber}
        handleClassroomSelection={handleClassroomSelection}
        hasMoreAssignments={hasMoreAssignments}
        assignmentsApiCallState={assignmentsApiCallState}
        retrieveAssignmentsApiCallFunc={retrieveAssignmentsApiCallFunc}
        updateAssignmentAndApiCallState={updateAssignmentAndApiCallState}
        determineAdvisoryState={determineAdvisoryState}
        setCurrentPageNumber={setCurrentPageNumber}
        viewFilterStatus={viewFilterStatus}
        handleSortByView={handleSortByView}
        sortBy={sortBy}
        setSortBy={setSortBy}
        {...otherProps}
      />
    );
  };

  WrappedWithV3AssignmentsApiGet.propTypes = propTypes;

  return WrappedWithV3AssignmentsApiGet;
};

export const formatAssignmentPayload = (assignment, consentedContent, contentSettingsType = null) => {
  const postMemberFormat = assignment.assignment_members.map((member) => {
    return {
      ...member,
      content_id: member.content.content_id
    };
  });
  delete assignment.assignment_members;
  assignment.assignment_members = postMemberFormat;

  const postClassroomFormat = assignment.assignment_classrooms.map((classroom) => {
    return {...classroom, classroom_id: classroom.classroom.id, date_assigned: classroom.date_assigned};
  });
  delete assignment.assignment_classrooms;
  assignment.assignment_classrooms = postClassroomFormat;

  assignment.source_content_id = assignment.source_content?.content_id;
  delete assignment.source_content;

  delete assignment.student_assignment_view_records;

  // Provide consent to override content advisory if consentedContent present:
  if (consentedContent && contentSettingsType === CONTENT_SETTINGS_TYPE.CAT) {
    assignment.district_advisory_override = consentedContent;
  }

  // Provide consent to student restricted content if consentedContent present:
  if (consentedContent && contentSettingsType === CONTENT_SETTINGS_TYPE.SRC) {
    assignment.content_settings_override = consentedContent;
  }

  // Remove null objects to prevent API post from failing.
  Object.keys(assignment).forEach((key) => {
    if (assignment[key] === null) delete assignment[key];
  });

  return assignment;
};

export const getContentSettingsType = (member = []) => {
  const {containsCAT} = determineAdvisoryState([member]) || {};
  const {containsSRC} = determineSRCStateForMembers([member]) || {};
  let contentSettingsType = null;
  if (containsCAT) {
    contentSettingsType = CONTENT_SETTINGS_TYPE.CAT;
  } else if (containsSRC) {
    contentSettingsType = CONTENT_SETTINGS_TYPE.SRC;
  }

  return contentSettingsType;
};

export default withV3AssignmentApiGet;
