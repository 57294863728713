exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__color-quiz-correct___C3ocg{background-color:#52A821}.styles__color-quiz-incorrect___xDCEy{background-color:#D93F31}.styles__color-science___ePxin{background-color:#55bca6}.styles__color-kids___TYktq{background-color:#5908f7}.styles__color-money___4tscE{background-color:#fa0045}.styles__color-law___rZ14B{background-color:#002764}.styles__color-health___DD6bL{background-color:#03a43c}.styles__color-arts___5mKNs{background-color:#E2C925}.styles__color-war-and-peace___y8U8h{background-color:#E76A0E}.styles__color-sports___e697D{background-color:#000000}.styles__color-opinion___Q9DEG{background-color:#0027f5}.styles__color-text-set___xU2i0{background-color:#106FF3}.styles__color-object-gray___95dJ2{background-color:#878787}.styles__color-light-gray___cTT0M{background-color:#F8F8F8}.styles__color-middle-gray___wgr1X{background-color:#BCBCBE}.styles__color-newsela-black___xKqVL{background-color:#333333}.styles__color-dark-bg___BRTHA{background-color:#031E3E}.styles__color-newsela-blue___ENWQ6{background-color:#0768fd}.styles__backButton___r2Dlf{color:#0768fd;display:flex;align-items:center;text-decoration:none !important}.styles__backButton___r2Dlf:hover{text-decoration:none !important}.styles__backButton___r2Dlf i{font-size:2em}.styles__backButton___r2Dlf .styles__label___lkR1B{margin-left:10px}", ""]);

// exports
exports.locals = {
	"color-quiz-correct": "styles__color-quiz-correct___C3ocg",
	"color-quiz-incorrect": "styles__color-quiz-incorrect___xDCEy",
	"color-science": "styles__color-science___ePxin",
	"color-kids": "styles__color-kids___TYktq",
	"color-money": "styles__color-money___4tscE",
	"color-law": "styles__color-law___rZ14B",
	"color-health": "styles__color-health___DD6bL",
	"color-arts": "styles__color-arts___5mKNs",
	"color-war-and-peace": "styles__color-war-and-peace___y8U8h",
	"color-sports": "styles__color-sports___e697D",
	"color-opinion": "styles__color-opinion___Q9DEG",
	"color-text-set": "styles__color-text-set___xU2i0",
	"color-object-gray": "styles__color-object-gray___95dJ2",
	"color-light-gray": "styles__color-light-gray___cTT0M",
	"color-middle-gray": "styles__color-middle-gray___wgr1X",
	"color-newsela-black": "styles__color-newsela-black___xKqVL",
	"color-dark-bg": "styles__color-dark-bg___BRTHA",
	"color-newsela-blue": "styles__color-newsela-blue___ENWQ6",
	"backButton": "styles__backButton___r2Dlf",
	"label": "styles__label___lkR1B"
};