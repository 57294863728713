import {constants} from '@newsela/angelou';
import {css} from 'emotion';

import fonts from 'static/three-oh/src/modules/utils/fonts';

const menuOption = (selectedOption) => css`
  display: flex;
  margin-bottom: .4rem;
  background-color: ${selectedOption ? constants.colors.ui.neutral[25] : 'null'};

  &:hover {
    background: ${constants.colors.ui.neutral[25]};
    cursor: pointer;
  }

  &:focus {
    background: ${constants.colors.ui.neutral[25]};
  }
`;

const menuOptionsContainer = css`
  font-family: ${fonts.circular.normal};
  font-size: 1rem;
  padding: 0;
  list-style-type: none;
`;

const selectedBar = css`
  width: .25rem;
  background-color: ${constants.colors.ui.core[600]};
  border-radius: 2px;
`;

const label = (selectedOption) => css`
  padding: 0.4rem 0 0.4rem ${selectedOption ? '0.5rem' : '.75rem'};
  color: ${selectedOption ? constants.colors.ui.neutral[700] : constants.colors.ui.neutral[500]}
`;

export default {
  label,
  selectedBar,
  menuOption,
  menuOptionsContainer,
};
