import {constants, typographyStyles} from '@newsela/angelou';
import {css} from 'emotion';

export const accountOptionsContainerCss = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0 1rem',
  gap: '0.5rem',
});

export const accountOptionsTitleCss = css({
  ...typographyStyles.fontFamily.circular,
  color: constants.colors.ui.neutral[700],
  lineHeight: '1.5rem',
  fontWeight: 700,
  fontSize: '1rem',
  margin: 0,
});

export const accountOptionsUnorderedListCss = css({
  margin: 0,
  padding: 0,
  width: '100%',
  listStyleType: 'none',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  marginLeft: '-10px !important',

  li: {
    textAlign: 'left',
    ...typographyStyles.fontSize.textM,
    ...typographyStyles.fontFamily.circular,
    color: constants.colors.ui.neutral[700],
  }
});

export const accountOptionButtonCss = {
  root: {
    width: '100%',
    padding: '0.25rem 0.5rem',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',

    ':hover': {
      background: '#F1F7FF',
    },

    svg: {
      width: '1.5rem',
      height: '1.5rem',

      path: {
        fill: constants.colors.ui.neutral[700]
      },
    },
  },
};

export const accountOptionAnchorCss = {
  root: {
    width: '100%',
    padding: '0.25rem 0.5rem',
    textDecoration: 'none',
    borderRadius: '0.5rem',

    '> div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '0.5rem',
      color: constants.colors.ui.neutral[700],
    },

    ':hover': {
      background: '#F1F7FF',
    },

    svg: {
      width: '1.5rem',
      height: '1.5rem',

      path: {
        fill: constants.colors.ui.neutral[700]
      },
    },
  },
};
