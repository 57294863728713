export const menuButtonCss = {
  root: {
    display: 'flex',
    padding: '0.25rem',

    ':hover': {
      background: 'rgba(29, 29, 29, 0.08)'
    },

    svg: {
      width: '2rem !important',
      height: '2rem !important',
    },
  }
};
