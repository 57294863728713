import React from 'react';

import PropTypes from 'prop-types';

import styles from './styles.scss';


const propTypes = {
  additionalClassNames: PropTypes.arrayOf(PropTypes.string),
  enabled: PropTypes.bool.isRequired,
  iconName: PropTypes.string.isRequired,
  label: PropTypes.string,
  title: PropTypes.string.isRequired,
  qaSelector: PropTypes.string.isRequired,
};

const defaultProps = {
  additionalClassNames: [],
  enabled: false,
  iconName: '',
  title: '',
};

const FeatureIndicator = (props) => {
  if (!props.enabled) {
    return null;
  }
  const classNames = `${styles.icon} fa fa-${props.iconName} ${props.additionalClassNames.join(' ')}`;
  return (
    <span title={props.title}>
      <i
        aria-label={props.label ? props.label : props.title}
        data-qa-selector={props.qaSelector}
        className={classNames}
      />
    </span>
  );
};

FeatureIndicator.defaultProps = defaultProps;
FeatureIndicator.propTypes = propTypes;

export default FeatureIndicator;
