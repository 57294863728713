import React, {useState} from 'react';

import {Button, DateInput, RadioButton} from '@newsela/angelou';
import {find} from 'lodash-es';
import moment from 'moment';
import PropTypes from 'prop-types';

import {toSnakeCase} from 'static/three-oh/utils/stringUtils';

import styles from './styles';

const propTypes = {
  filterStyle: PropTypes.object.isRequired,
  updateQueryParameters: PropTypes.func.isRequired,
  since: PropTypes.string.isRequired,
  until: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  handleResetPageNumber: PropTypes.func
};

const getBeginningOfJuly = () => {
  return moment().month(6).startOf('month');
};

const today = moment().endOf('day');

const isBeforeJuly = today.month() < 6;

// Assume that "This School Year" means July of this year until now.
// Note that momentjs's month() function is zero based.

const lastYearRange = {
  // If it's before July, "Last School Year" should actually
  // be two calendar years ago's July to last year's July.
  label: 'Last School Year',

  ...(isBeforeJuly
    ? {
        since: getBeginningOfJuly().subtract(2, 'year'),
        until: getBeginningOfJuly().subtract(1, 'year').subtract(1, 'day')
      }
    : {
        since: getBeginningOfJuly().subtract(1, 'year'),
        until: getBeginningOfJuly().subtract(1, 'day')
      })
};

export const thisYearRange = {
  // If it's before July, "This School Year" should actually
  // correspond to last year's July to beginning of this Year's July.
  label: 'This School Year',

  ...(isBeforeJuly
    ? {
        since: getBeginningOfJuly().subtract(1, 'year'),
        until: getBeginningOfJuly()
      }
    : {
        since: getBeginningOfJuly().startOf('month'),
        until: getBeginningOfJuly().add(1, 'year')
      })
};

const dateRanges = [
  {
    label: 'Today',
    since: moment().startOf('day'),
    until: today
  },
  {
    label: 'Last 7 days',
    since: moment().subtract(7, 'days').startOf('day'),
    until: today
  },
  {
    label: 'Last 30 days',
    since: moment().subtract(30, 'days').startOf('day'),
    until: today
  },
  {
    label: 'Last 90 days',
    since: moment().subtract(90, 'days').startOf('day'),
    until: today
  },
  thisYearRange,
  lastYearRange
];

const DateFilter = (props) => {
  const getMatchingDatePreset = (sinceDate, untilDate) => {
    const matchingDateRangePreset = find(
      dateRanges,
      (range) => {
        return (range.since.isSame(sinceDate, 'days') && range.until.isSame(untilDate, 'days'));
      }
    );

    return matchingDateRangePreset || false;
  };

  const [hasDateRadioValue, setHasDateRadioValue] = useState(false);
  const [hasCustomStartDate, setHasCustomStartDate] = useState(!getMatchingDatePreset(props.since, props.until));
  const [hasCustomEndDate, setHasCustomEndDate] = useState(!getMatchingDatePreset(props.since, props.until));
  const [radioType, setRadioType] = useState(getMatchingDatePreset(props.since, props.until).label ?? '');
  const [since, setSince] = useState(props.since ? moment(props.since).format('YYYY-MM-DD') : '');
  const [until, setUntil] = useState(props.until ? moment(props.until).format('YYYY-MM-DD') : '');
  const [customStartDate, setCustomStartDate] = useState(!getMatchingDatePreset(props.since, props.until) ? moment(props.since).format('YYYY-MM-DD') : '');
  const [customEndDate, setCustomEndDate] = useState(!getMatchingDatePreset(props.since, props.until) ? moment(props.until).format('YYYY-MM-DD') : '');
  const [endDateHasError, setEndDateHasError] = useState(null);


  const validateEnteredStartDate = (startDate) => {
    if (customEndDate === '') return true;

    const result = moment(startDate).isBefore(customEndDate, 'days');
    return result;
  };

  const validateEnteredEndDate = (endDate) => {
    if (customStartDate === '') return true;

    const result = moment(endDate).isAfter(customStartDate, 'days');
    setEndDateHasError(!result ? `Please select a date after ${moment(customStartDate).format('MM/DD/YYYY')}` : null);

    return result;
  };

  const handleStartDateSelection = (newSince) => {
    const sinceAsMoment = moment(newSince).format('YYYY-MM-DD');
    setHasCustomStartDate(true);
    setSince('');
    setRadioType(null);
    setHasDateRadioValue(false);
    setCustomStartDate(sinceAsMoment);
  };

  const handleEndDateSelection = (newUntil) => {
    const untilAsMoment = moment(newUntil).format('YYYY-MM-DD');
    setHasCustomEndDate(true);
    setUntil('');
    setRadioType(null);
    setHasDateRadioValue(false);
    setCustomEndDate(untilAsMoment);
  };

  const handleRadioChange = (datesValue) => {
    setSince(datesValue.since);
    setUntil(datesValue.until);
    setRadioType(datesValue.type);
    setCustomStartDate('');
    setCustomEndDate('');
    setHasDateRadioValue(true);
    setHasCustomStartDate(false);
    setHasCustomEndDate(false);
  };

  const handleDateSubmit = () => {
    if (hasCustomStartDate || hasCustomEndDate) {
      const isStartDateValid = validateEnteredStartDate(customStartDate);
      const isEndDateValid = validateEnteredEndDate(customEndDate);

      if (!isStartDateValid || !isEndDateValid) {
        setCustomEndDate('');
        return;
      }

      const newQueryDict = {
        since: customStartDate,
        until: customEndDate
      };

      setEndDateHasError(null);
      props.updateQueryParameters(newQueryDict);
      return;
    }

    const newQueryDict = {
      since,
      until
    };

    setEndDateHasError(null);
    props.updateQueryParameters(newQueryDict);
    props.handleResetPageNumber(1);
  };

  const minCustomStartRange = moment().subtract(2, 'years').format('YYYY-MM-DD');
  const maxCustomEndRange = moment().add(2, 'years').format('YYYY-MM-DD');

  const handleDisableStateUpdateDateButton = (hasCustomStartDate && !hasCustomEndDate)
    || (hasCustomEndDate && !hasCustomStartDate)
    || (!hasDateRadioValue && !hasCustomEndDate && !hasCustomStartDate);

  return (
    <div className={styles.dateContainerCss}>
      <div className={styles.filterDateContentCss}>
        <fieldset className={styles.dateFieldsetCss}>
          <legend>
            {dateRanges.map((type, i) => {
              return (
                <RadioButton
                  key={i}
                  label={type.label}
                  name="dateGroup"
                  data-qa-selector={`${toSnakeCase(type.label)}`}
                  __cssFor={{
                    input: {
                      '+ span': {
                        color: 'white !important',
                        borderColor: 'white !important'
                      },
                      '~ div': {
                        color: 'white'
                      }
                    }
                  }}
                  onChange={() => handleRadioChange({
                    since: type.since.format('YYYY-MM-DD'),
                    until: type.until?.format('YYYY-MM-DD'),
                    type: type.label
                  })}
                  checked={radioType === type.label}
                  inputAriaProps={{
                    'aria-hidden': true
                  }}
                />
              );
            })}
          </legend>
        </fieldset>
      </div>
      <div className={styles.filterDateContentCss}>
        <DateInput
          onChange={() => handleStartDateSelection(event.target.value)}
          min={minCustomStartRange}
          max={maxCustomEndRange}
          value={customStartDate}
          label="Start Date"
          data-qa-selector="custom_start_date"
        />
        <DateInput
          onChange={() => handleEndDateSelection(event.target.value)}
          min={minCustomStartRange}
          max={maxCustomEndRange}
          value={customEndDate}
          label="End Date"
          data-qa-selector="custom_end_date"
        />
        {endDateHasError ?
            (<p className={styles.endDateErrorCss}>{endDateHasError}</p>)
          : null}
      </div>
      <Button
        flavor={Button.flavors.secondary}
        size={Button.sizes.md}
        disabled={handleDisableStateUpdateDateButton}
        onClick={handleDateSubmit}
        __cssFor={{
          root: [
            {
              justifyContent: 'center',
              width: '100%',
              borderColor: 'white',
              color: 'white',
            }
          ]
        }}
      >
        Update Filter
      </Button>
    </div>
  );
};

DateFilter.propTypes = propTypes;

export default DateFilter;
