import {contentHideActionTypes as types} from './../constants';

/**
 * ContentHide Loading Actions
 */

export function requestContentHides(queryParams) {
  return {
    type: types.CONTENT_HIDE_LOAD_REQUEST,
    queryParams
  };
}

export function loadContentHideSuccess(data) {
  return {
    type: types.CONTENT_HIDE_LOAD_SUCCESS,
    contentHides: data,
    receivedAt: Date.now()
  };
}

export function loadContentHideFailure() {
  return {
    type: types.CONTENT_HIDE_LOAD_FAILURE,
    contentHides: [],
    receivedAt: Date.now()
  };
}

/**
 * ContentHide Toggle Actions
 */

export function toggleContentHide({
  content_id,
  classroom_id,
  user_id,
  callback
}) {
  return {
    type: types.CONTENT_HIDE_TOGGLE_REQUEST,
    content_id,
    classroom_id,
    user_id,
    callback
  };
}

export function toggleContentHideSuccess(data) {
  return {
    type: types.CONTENT_HIDE_TOGGLE_SUCCESS,
    contentHides: data,
    receivedAt: Date.now()
  };
}

export function toggleContentUnhideSuccess(contentHideId) {
  return {
    type: types.CONTENT_UNHIDE_TOGGLE_SUCCESS,
    contentHideId,
    receivedAt: Date.now()
  };
}

export function toggleContentHideFailure(error) {
  const errorState = {
    error,
    statusCode: error.statusCode,
  };

  return {
    type: types.CONTENT_HIDE_TOGGLE_FAILURE,
    error: errorState,
  };
}
