import React from 'react';

import PropTypes from 'prop-types';

import {defaultErrorMessage} from './constants';
import {
  textWrapperStyle,
  ctaWrapperStyle,
  ctaStyle,
  messageHeaderStyle,
  messageHeaderNoIconStyle,
  messageBodyStyle,
} from './styles';

const propTypes = {
  messageHeader: PropTypes.string,
  messageBody: PropTypes.string,
  /**
  * Call-to-action(CTA) path
  */
  cta: PropTypes.string,
  /**
  * Call-to-action(CTA) text
  */
  ctaText: PropTypes.string,
  /**
  * Second call-to-action(CTA) path
  */
  ctaSecondary: PropTypes.string,
  /**
  * Second call-to-action(CTA) text
  */
  ctaSecondaryText: PropTypes.string,
  /**
  * Data attributes used for integration tests.
  * Example:
  * <button data-qa-selector="sign_in_button_top">Sign in</button>
  *
  * Integration Tests PR: https://github.com/newsela/newsela/pull/14201
  */
  joinQaSelector: PropTypes.string,
  signInQaSelector: PropTypes.string,
  shouldUseNextUrl: PropTypes.bool,
  useLyceumStyle: PropTypes.bool,
};

function ErrorMessaging({
  messageHeader,
  messageBody,
  cta,
  ctaText,
  ctaSecondary,
  ctaSecondaryText,
  joinQaSelector,
  signInQaSelector,
  shouldUseNextUrl,
  useLyceumStyle,
}) {
  const ctaButtonorLink = (link, text) => {
    const url = new URL(link, window.location);
    url.searchParams.append('next', window.location.href);
    return (
      <a
        aria-label={text}
        data-qa-selector={joinQaSelector || signInQaSelector}
        href={shouldUseNextUrl ? url.href : link}
        className={ctaStyle}
      >
        {text}
      </a>
    );
  };

  const messageHeaderClassName = useLyceumStyle ? messageHeaderNoIconStyle : messageHeaderStyle;

  return (
    <>
      <div className={textWrapperStyle}>
        <h1 className={messageHeaderClassName}>{messageHeader}</h1>
        <div className={messageBodyStyle}>
          {messageBody}
        </div>
      </div>
      <div className={ctaWrapperStyle}>
        {ctaButtonorLink(cta, ctaText)}
        {ctaSecondaryText &&
          ctaButtonorLink(ctaSecondary, ctaSecondaryText)}
      </div>
    </>
  );
}

ErrorMessaging.propTypes = propTypes;

ErrorMessaging.defaultProps = {
  messageHeader: defaultErrorMessage.messageHeader,
  messageBody: defaultErrorMessage.messageBody,
  cta: defaultErrorMessage.cta,
  ctaText: defaultErrorMessage.ctaText,
  shouldUseNextUrl: defaultErrorMessage.shouldUseNextUrl,
  useLyceumStyle: false,
};

export default ErrorMessaging;
