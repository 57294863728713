import {constants, typographyStyles} from '@newsela/angelou';
import {css} from 'emotion';
const uiColors = constants.colors.ui;

const containerMargin = css`
  margin-bottom: 20px;
`;

const districtModContainer = css`
    box-shadow: 1px 1px 3px rgb(0, 0, 0, 0.15);
    background: white;
    border-radius: 6px 6px 0 0;
    overflow: hidden;
`;

const colorWarning = css`
    height: 8px;
    width: 100%;
    background-color: ${uiColors.yellow[500]};
`;

const alertContainer = css`
    display: flex;
    justify-content: space-between;
    padding: 12px 14px;
    align-items: center;
`;

const modTextContainer = css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const modText = css({
  ...typographyStyles.fontFamily.circular,
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '24px',
  marginLeft: '12px'
});

const modDivider = css`
    width: 100%;
    height: 1px;
    margin: 0;
    border: none;
    border-top: 1px solid ${uiColors.neutral[100]}
`;

const iconCss = {
  width: '22px',
  height: '22px',
  'path:first-of-type': {
    fill: uiColors.yellow[700],
    stroke: uiColors.yellow[700]
  }
};

export default {
  alertContainer,
  colorWarning,
  containerMargin,
  districtModContainer,
  iconCss,
  modText,
  modTextContainer,
  modDivider,
};
