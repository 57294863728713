import {combineReducers} from 'redux';

import canvasAssignmentReview from './canvasAssignmentReview';
import read from './read';
import yourContent from './yourContent';

export default combineReducers({
  read,
  yourContent,
  canvasApp: canvasAssignmentReview,
});
