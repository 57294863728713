import * as types from 'modulesV2/constants';

const defaultState = {modalId: null, options: null};

/**
 * The purpose of the Modal reducer is to create an app level awareness of
 * when a modal is open and what is present on that modal.
 */
export default function modalId(state = defaultState, action) {
  const obj = {modalId: action.modalId, options: action.options};
  switch (action.type) {
    case types.MODAL_SET:
      return obj;
    case types.MODAL_CLEAR:
      return defaultState;

    default:
      return state;
  }
}
