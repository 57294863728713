import React from 'react';

import {css, cx} from 'emotion';
import PropTypes from 'prop-types';

import style from './style';
import AngelouProps from '../../propTypes/AngelouPropTypes';
import {
  gridContainerCss,
  gridColumnSpan
} from '../../styles/gridCss';

const propTypes = {
  ...AngelouProps,

  /**
   * if an alt is provided at the top level we will treat
   * the collage as one image.
   */
  alt: PropTypes.string,
  /**
   * Rows of images to be placed in the collage
   * each row is an array of objects
   */
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Defines the height of the row. You can use
       * fr units
       */
      height: PropTypes.string,
      /**
       * Images to be places in the collage. Must
       * contain a source. should contain an alt as well
       */
      images: PropTypes.arrayOf(
        PropTypes.shape({
          src: PropTypes.string.isRequired,
          alt: PropTypes.string,
          onClick: PropTypes.func,
          ariaLabel: PropTypes.string,
          role: PropTypes.string,
        })
      ).isRequired
    })
  ).isRequired
};

/**
 * get the greatest common divisor.
 */
const gcd = (a, b) => (!b ? a : gcd(b, a % b));

/**
 * get the lowest common multiple
 * @param {number} a
 * @param {number} b
 */
const lcm = (a, b) => (a * b) / gcd(a, b);

/**
 * get reduce the array using lcm as the reducer
 * returns the lowest common multiple of all the arguments
 * @param {[number]} args
 */
const multipleLCM = (args) => args.reduce(lcm);

class Collage extends React.Component {
  constructor(props) {
    super(props);
    const {rows} = this.props;

    if (rows.length) {
      this.state = this.calculateGridDimensions(rows);
    }
  }

  /**
   * check if we need to update the grid dimensions
   */
  componentDidUpdate = (prevProps) => {
    // did the height change?
    const heightChange = this.props.rows.find(
      (row, index) => prevProps.rows[index].height != row.height
    );
    // did we change the amount of images in a row?
    const imgLengthChange = this.props.rows.find(
      (row, index) => prevProps.rows[index].images.length != row.images.length
    );
    // did we add or remove an entire row?
    const rowChange = this.props.rows.length != prevProps.rows.length;

    // given the above two do we need to recalculate the grid?
    const shouldRecalculateGrid = heightChange || imgLengthChange || rowChange;
    if (shouldRecalculateGrid) {
      const newState = this.calculateGridDimensions(this.props.rows);
      // eslint-disable-next-line
      this.setState(newState);
    }
  };

  /**
   * calculates the dimensions of the css grid so everything
   * can be laid out most efficiently
   */
  calculateGridDimensions = (rows) => {
    const rowDimensions = rows.map((row) => (row.height ? row.height : '1fr'));
    const gridTemplateRows = rowDimensions.join(' ');
    const rowLengths = rows.map((row) => row.images.length);
    const leastCommonMultiple = multipleLCM(rowLengths);
    const gridTemplateColumns = `repeat(${leastCommonMultiple}, 1fr)`;

    return {
      gridTemplateColumns,
      gridTemplateRows,
      leastCommonMultiple
    };
  };

  render() {
    const {rows} = this.props;
    // if there are no rows, dont do anything.
    if (!rows.length) {
      return null;
    }

    const {
      leastCommonMultiple,
      gridTemplateColumns,
      gridTemplateRows
    } = this.state;

    let classNames = [style.grid];
    if (this.props.additionalClassnames) {
      classNames = [...classNames, ...this.props.additionalClassnames];
    }
    classNames = classNames.join(' ');

    // eventually when we use css in js this will be nicer.
    // for now inline
    const cells = rows.map((row, rowIndex) => {
      let columnCount = 1;
      return row.images
        .map((img, imgIndex) => {
          const columnEnd = columnCount + (leastCommonMultiple / row.images.length);

          const xPos = img.xPos ? img.xPos : 'center';
          const yPos = img.yPos ? img.yPos : 'center';
          const className = cx(
            style.cell(xPos, yPos),
            gridColumnSpan(columnCount, columnEnd),
            css`
              -ms-grid-row:${rowIndex + 1};
              background-image: url(${img.src});
            `
          );

          const accessibilityAttr = {};
          if (!this.props.alt) {
            accessibilityAttr.role = img.role ? img.role : 'img';
            if (img.alt) {
              accessibilityAttr.alt = img.alt;
            }
            accessibilityAttr['aria-label'] = img.ariaLabel ? img.ariaLabel : img.alt;
          }
          const cell = (
            <div
              key={`${rowIndex}_${imgIndex}`}
              {...accessibilityAttr}
              className={className}
              onClick={img.onClick}
            />
          );
          columnCount = columnEnd;
          return cell;
        });
    });

    const container = gridContainerCss({
      rows: gridTemplateRows,
      columns: gridTemplateColumns
    });

    classNames = cx(classNames, container);
    const topLevelA11y = {};
    if (this.props.alt) {
      topLevelA11y.role = 'img';
      topLevelA11y.alt = this.props.alt;
      topLevelA11y['aria-label'] = this.props.alt;
    }
    return (
      <div
        className={classNames}
        {...topLevelA11y}
      >
        {cells}
      </div>
    );
  }
}

Collage.propTypes = propTypes;
Collage.defaultProps = {
  rows: [],
};

export default Collage;
