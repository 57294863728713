const messageLevels = {
  primary: 'primary',
  secondary: 'secondary',
  info: 'info',
  success: 'success',
  danger: 'danger',
  warning: 'warning',
};

const messageUrls = {
  freeUserPresentationCTA: 'https://newsela.secure.force.com/scheduler?&utm_medium=web&utm_source=newsela&utm_campaign=appts&utm_content=preso-mode',
  freeUserTextSetCreateAssignmentCTA: 'https://newsela.secure.force.com/scheduler?&utm_medium=web&utm_source=newsela&utm_campaign=appts&utm_content=textset-assign',
  freeUserAssignmentTemplateShare: 'https://newsela.secure.force.com/scheduler?&utm_medium=web&utm_source=newsela&utm_campaign=appts&utm_content=share-template',
};

const transitionDirections = {
  UP: 'UP',
  DOWN: 'DOWN',
};

export {messageLevels, messageUrls, transitionDirections};
