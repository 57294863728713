import {
  constants,
  mediaQueryStyles,
  typographyStyles,
} from '@newsela/angelou';
import {css, cx} from 'emotion';

const {mediaQuery} = mediaQueryStyles;

const errorMsg = cx([
  css`
    display: inline-flex;
    justify-content: space-between;
    font-weight: normal;
    width: 100%;
    min-width: 256px;
  `,
  css(typographyStyles.textMObj),
]);

const textContainer = css`
  display: flex;
`;

const warningIcon = css`
  height: 16px;
  width: 16px;
  min-width: 18px;

  ${mediaQuery.downTo(constants.legacy_breakpoints.Breakpoints.xs + 1)} {
    margin-right: 11px;
  } ;
`;

const text = css`
  line-height: 20px;
  text-align: left;
  vertical-align: top;
`;

const closeButton = css`
  align-self: center;
  height: 16px;
  width: 16px;

  ${mediaQuery.downTo(768)} {
    margin-left: 15px;
  } ;
`;

const closeIcon = css`
  height: 16px;
  width: 16px;
`;

const linkInsideToast = css`
  a {
    color: white;
    font-weight: bold;
    text-decoration: underline;
  }
`;

export default {
  closeButton,
  closeIcon,
  errorMsg,
  text,
  textContainer,
  warningIcon,
  linkInsideToast,
};
