import React from 'react';

import PropTypes from 'prop-types';
import {Manager, Reference, Popper} from 'react-popper';
import {CSSTransition} from 'react-transition-group';

import withTrackEvent from 'static/three-oh/src/components/with/WithTrackEvent';
import TrackEventProps from 'static/three-oh/src/components/with/WithTrackEventConstants';
import AngelouProps from 'static/three-oh/src/propTypes/AngelouPropTypes';

import {
  bubbleStyle,
  textStyle,
  left,
  right,
  above,
  below,
  arrowStyle,
  enter,
  enterActive,
  exit,
  exitActive,
  cornerStyle
} from './styles';

const propTypes = {
  ...AngelouProps,

  children: PropTypes.node.isRequired,
  /*
  * Sets the position of the tooltip top, bottom, left, or right
  */
  position: PropTypes.string,
  /*
   * Either on click or mouseout
   */
  dismissOnMouseout: PropTypes.bool,
  /*
   * Tracks if tooltip is open or not
   */
  isOpen: PropTypes.bool,
  /*
   * Determines if tooltip is light or dark
   */
  isDark: PropTypes.bool,
  /*
   * Defines tooltip message
   */
  message: PropTypes.string,
  /*
   * Determines if tooltip has rounder or sharp corners
   */
  hasRoundedCorners: PropTypes.bool,
  /*
   * Defines tooltip alignment
   */
  tooltipAlign: PropTypes.string,
  /*
   * Get parent label to associate modal with trigger
   */
  tooltipAriaLabel: PropTypes.string,
  /*
   * Optional horizontal offset amound
   */
  tooltipHorizontalOffset: PropTypes.number,
  /*
   * Optional vertical offset amound
   */
  tooltipVerticalOffset: PropTypes.number,
  /*
   * A function to override default message styles
   */
  __renderTooltipContent: PropTypes.func,
  /*
   * An object to customize aria props
   */
  aria: PropTypes.object,
  /**
   * Tracking event props passed from withTrackEvent HOC.
   */
  ...TrackEventProps,
};

const tooltipAlignStyles = {above: above, below: below, right: right, left: left};

export const TipAlign = {above: 'above', below: 'below', right: 'right', left: 'left'};
export const TipPosition = {top: 'top', bottom: 'bottom', right: 'right', left: 'left'};

const defaultProps = {
  isOpen: false,
  position: TipPosition.top,
};

class AngelouTooltip extends React.Component {
  constructor(props) {
    super(props);
    const {isOpen} = this.props;

    this.state = {
      isOpen: isOpen,
    };
  }

  addEventListeners = () => {
    window.addEventListener('keydown', this.escFunction, false);
  }

  removeEventListeners = () => {
    window.removeEventListener('keydown', this.escFunction);
  }

  openAnnouncementTooltip = () => {
    this.setState({
      isOpen: true,
    });
    this.addEventListeners();
  };

  closeAnnouncementTooltip = () => {
    this.setState({
      isOpen: false,
    });
    this.removeEventListeners();
  };

  escFunction = (e) => {
    if (e.keyCode === 27 && this.state.isOpen) {
      e.preventDefault();
      this.closeAnnouncementTooltip();
    }
  };

  render() {
    const {
      children,
      position,
      additionalClassName,
      tooltipAriaLabel,
      message,
      isDark,
      hasRoundedCorners,
      dismissOnMouseout,
      tooltipHorizontalOffset,
      tooltipVerticalOffset,
      __renderTooltipContent,
      tooltipAlign,
      aria,
    } = this.props;

    const {isOpen} = this.state;

    let firstOffset;
    let secondOffset;

    if (position === TipPosition.top || position === TipPosition.bottom) {
      firstOffset = tooltipHorizontalOffset || 0;
      secondOffset = tooltipVerticalOffset || 0;
    } else {
      firstOffset = tooltipVerticalOffset || 0;
      secondOffset = tooltipHorizontalOffset || 0;
    }
    const typeOfChild = typeof children.type;
    return (
      <>
        <Manager>
          <Reference>
            {({ref}) => (
              <span>
                {typeOfChild === 'function' ?
                  <span
                    ref={ref}
                    onMouseEnter={this.openAnnouncementTooltip}
                    onFocus={this.openAnnouncementTooltip}
                    onMouseLeave={() => {
                      if (dismissOnMouseout) {
                        this.closeAnnouncementTooltip();
                      }
                    }}
                    onBlur={this.closeAnnouncementTooltip}
                  >
                    {children}
                  </span>
                  :
                  <span>
                    {React.cloneElement(children, {
                      ref: ref,
                      onClick: this.props.onClick && this.props.onClick,
                      onMouseEnter: () => {
                        this.openAnnouncementTooltip();
                        this.props.onMouseEnter && this.props.onMouseEnter();
                      },
                      onFocus: this.openAnnouncementTooltip,
                      onMouseLeave: () => {
                        if (dismissOnMouseout) {
                          this.closeAnnouncementTooltip();
                        }
                        this.props.onMouseLeave && this.props.onMouseLeave();
                      },
                      onBlur: this.closeAnnouncementTooltip,
                      ...children.props
                    })}
                  </span>}
              </span>
            )}
          </Reference>
          <CSSTransition
            in={isOpen}
            timeout={250}
            classNames={{
              enter,
              enterActive,
              exit,
              exitActive
            }}
            unmountOnExit
          >
            <Popper
              placement={TipPosition[position]}
              modifiers={{offset: {offset: `${firstOffset}px, ${secondOffset}px`}, preventOverflow: {enabled: false}, hide: {enabled: false}}}
            >
              {({ref, style, placement, arrowProps}) => (
                <div
                  aria-label="angelou_tooltip"
                  aria-modal="true"
                  id={`${tooltipAriaLabel}_dialog`}
                  role="dialog"
                  {...aria}
                  ref={ref}
                  style={style}
                  data-placement={placement}
                  className={`${bubbleStyle(isDark)} ${cornerStyle(hasRoundedCorners)} ${additionalClassName}`}
                >
                  {__renderTooltipContent ? __renderTooltipContent(textStyle) : <p className={textStyle}>{message}</p>}
                  <div className={`${arrowStyle(isDark)} ${tooltipAlignStyles[tooltipAlign]}`} ref={arrowProps.ref} data-placement={placement}/>
                </div>
              )}
            </Popper>
          </CSSTransition>
        </Manager>
      </>
    );
  }
}

AngelouTooltip.propTypes = propTypes;
AngelouTooltip.defaultProps = defaultProps;
AngelouTooltip.tipAlign = TipAlign;
AngelouTooltip.tipPosition = TipPosition;

const AngelouTooltipWithTrackEvent = withTrackEvent(AngelouTooltip);
export {AngelouTooltip as default, AngelouTooltipWithTrackEvent};
