import {constants} from '@newsela/angelou';
import {css} from 'emotion';

const reportDownloadButton = css`
  max-height: 40px;
  padding-left: 16px !important;
  padding-right: 16px !important;
  font-size: 16px !important;
  font-weight: normal !important;
  color: ${constants.colors.ui.core[500]} !important;
  border-color: ${constants.colors.ui.neutral[300]} !important;
  border-radius: 300px;
  padding: .5em 1em;
  --angelou-Icon-size-LG-width: 1rem;
  &&&:hover {
    background-color: #EBF3FF !important;
    border-color: ${constants.colors.ui.core[500]} !important;
  }
`;

const ellipsisAnimation = css`
  span {
    animation: ellipsis-anim 720ms linear infinite;
  }

  span:nth-child(1) {
    animation-delay: 0ms;
  }
  span:nth-child(2) {
    animation-delay: 240ms;
  }

  span:nth-child(3) {
    animation-delay: 480ms
  }

  @keyframes ellipsis-anim {
   0% {
   opacity: 1;
   }
   25% {
   opacity: 0.5;
   }
   50% {
    opacity: 0;
   }
   75% {
   opacity: 0.5
   }
   100% {
   opacity: 1;
   }
  }
`;


export default {
  reportDownloadButton,
  ellipsisAnimation
};
