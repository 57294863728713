import React from 'react';

import {includes} from 'lodash-es';
import PropTypes from 'prop-types';

import {
  CategoryIndicator,
  ContentImpression,
  LanguageIndicator,
  PowerWordsIndicator,
  TrackedLink
} from 'static/three-oh/src/components';
import LazyImage from 'static/three-oh/src/components/LazyImage';
import withTrackEvent from 'static/three-oh/src/components/with/WithTrackEvent';
import {getCardEventProperties} from 'static/three-oh/src/modules/utils/contentCardHelpers';

import styles from './styles.scss';

const defaultProps = {
  eventProperties: {},
  renderArticleThumbnail: true,
  hideIcons: false
};

const propTypes = {
  article: PropTypes.object.isRequired,
  hideIcons: PropTypes.bool,
  eventSource: PropTypes.string.isRequired,
  eventProperties: PropTypes.object,
  displayShortTitles: PropTypes.bool,
  renderArticleThumbnail: PropTypes.bool
};


class ArticleCardMini extends React.Component {
  renderImage = () => {
    return (
      <LazyImage
        alt={this.props.article.image_caption}
        src={this.props.article.image}
      />
    );
  }

  renderIndicators() {
    const hasPowerWords = includes(this.props.article.features, 'power_words');
    return (
      <div className={styles.icons}>
        <LanguageIndicator translations={this.props.article.translations}/>
        <PowerWordsIndicator hasPowerWords={hasPowerWords}/>
      </div>
    );
  }

  render() {
    const article = this.props.article;
    const {eventProperties, contentImpressionEventProperties} =
    getCardEventProperties(this.props.eventProperties, article);

    return (
      <TrackedLink
        className={styles.hover}
        eventName={this.props.eventSource + '_article_card_mini_click'}
        eventProperties={eventProperties}
        href={article.url}
        eventSource={this.props.eventSource}
        trackImpression
      >
        <ContentImpression
          model="article-level" id={article.id}
          format="article-card-mini"
          eventSource={this.props.eventSource}
          eventProperties={contentImpressionEventProperties}
        >
          <div className={styles.cardContainer}>
            {this.renderImage()}
            <div className={styles.description}>
              <CategoryIndicator
                article={this.props.article}
                style={{float: 'left'}}
              />
              {!this.props.hideIcons && this.renderIndicators()}
              <div style={{clear: 'both'}} />
              {article.short_title}
            </div>
          </div>
        </ContentImpression>
      </TrackedLink>
    );
  }
}

ArticleCardMini.defaultProps = defaultProps;
ArticleCardMini.propTypes = propTypes;

export default withTrackEvent(ArticleCardMini);
