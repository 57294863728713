var escape = require("../../../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__color-quiz-correct___AVxH-{background-color:#52A821}.styles__color-quiz-incorrect___6BwMv{background-color:#D93F31}.styles__color-science___fRii0{background-color:#55bca6}.styles__color-kids___ibqLY{background-color:#5908f7}.styles__color-money___5f4Pc{background-color:#fa0045}.styles__color-law___niUB2{background-color:#002764}.styles__color-health___rda3-{background-color:#03a43c}.styles__color-arts___pORjJ{background-color:#E2C925}.styles__color-war-and-peace___-om8L{background-color:#E76A0E}.styles__color-sports___-YVIQ{background-color:#000000}.styles__color-opinion___0a2pn{background-color:#0027f5}.styles__color-text-set___cN8d-{background-color:#106FF3}.styles__color-object-gray___NFeV-{background-color:#878787}.styles__color-light-gray___eq7AY{background-color:#F8F8F8}.styles__color-middle-gray___nLZML{background-color:#BCBCBE}.styles__color-newsela-black___20ImX{background-color:#333333}.styles__color-dark-bg___Rjh2B{background-color:#031E3E}.styles__color-newsela-blue___65INh{background-color:#0768fd}.styles__language___NL5iy{padding:0;margin:0;width:18px;height:18px;background-color:#333;-webkit-mask-image:url(" + escape(require("./assets/Spanish.svg")) + ") !important;-webkit-mask-repeat:no-repeat;mask-image:url(" + escape(require("./assets/Spanish.svg")) + ") !important;mask-repeat:no-repeat;background-size:cover;display:inline-block;vertical-align:text-bottom}", ""]);

// exports
exports.locals = {
	"color-quiz-correct": "styles__color-quiz-correct___AVxH-",
	"color-quiz-incorrect": "styles__color-quiz-incorrect___6BwMv",
	"color-science": "styles__color-science___fRii0",
	"color-kids": "styles__color-kids___ibqLY",
	"color-money": "styles__color-money___5f4Pc",
	"color-law": "styles__color-law___niUB2",
	"color-health": "styles__color-health___rda3-",
	"color-arts": "styles__color-arts___pORjJ",
	"color-war-and-peace": "styles__color-war-and-peace___-om8L",
	"color-sports": "styles__color-sports___-YVIQ",
	"color-opinion": "styles__color-opinion___0a2pn",
	"color-text-set": "styles__color-text-set___cN8d-",
	"color-object-gray": "styles__color-object-gray___NFeV-",
	"color-light-gray": "styles__color-light-gray___eq7AY",
	"color-middle-gray": "styles__color-middle-gray___nLZML",
	"color-newsela-black": "styles__color-newsela-black___20ImX",
	"color-dark-bg": "styles__color-dark-bg___Rjh2B",
	"color-newsela-blue": "styles__color-newsela-blue___65INh",
	"language": "styles__language___NL5iy"
};