import {assignmentsTabActionTypes as types} from '../constants';

export function toggleWorkDetails(key) {
  return {type: types.TOGGLE_WORK_DETAILS, payload: {key}};
}

export function openWorkDetails(key) {
  return {type: types.OPEN_WORK_DETAILS, payload: {key}};
}

export function closeWorkDetails(key) {
  return {type: types.CLOSE_WORK_DETAILS, payload: {key}};
}

export function workDetailsFinishedLoading(key) {
  return {type: types.WORK_DETAILS_FINISHED_LOADING, payload: {key}};
}

export function startWorkPolling() {
  return {type: types.START_POLLING};
}

export function stopWorkPolling() {
  return {type: types.STOP_POLLING};
}

export function articleHeaderClassroomsFetchRequest() {
  return {type: types.ARTICLE_HEADER_CLASSROOMS_FETCH_REQUEST};
}

export function articleHeaderClassroomsFetchSuccess(payload) {
  return {type: types.ARTICLE_HEADER_CLASSROOMS_FETCH_SUCCESS, payload};
}

export function articleHeaderClassroomsFetchFailure(error) {
  return {type: types.ARTICLE_HEADER_CLASSROOMS_FETCH_FAILURE, error};
}

export function assignmentsFetchRequest() {
  return {type: types.ASSIGNMENTS_FETCH_REQUEST};
}

export function assignmentsFetchSuccess(payload) {
  return {type: types.ASSIGNMENTS_FETCH_SUCCESS, payload};
}

export function assignmentsFetchFailure(error) {
  return {type: types.ASSIGNMENTS_FETCH_FAILURE, error};
}

export function headerReportingDataFetchRequest() {
  return {type: types.HEADER_REPORTING_DATA_FETCH_REQUEST};
}

export function headerReportingDataFetchSuccess(payload) {
  return {type: types.HEADER_REPORTING_DATA_FETCH_SUCCESS, payload};
}

export function headerReportingDataFetchFailure(error) {
  return {type: types.HEADER_REPORTING_DATA_FETCH_FAILURE, error};
}

export function slimHeaderReportingDataFetchRequest(headerId) {
  return {type: types.SLIM_HEADER_REPORTING_DATA_FETCH_REQUEST, headerId};
}

export function slimHeaderReportingDataFetchSuccess(headerId, payload) {
  return {type: types.SLIM_HEADER_REPORTING_DATA_FETCH_SUCCESS, headerId, payload};
}

export function notificationsFetchRequest(headerId, classroomId) {
  return {type: types.NOTIFICATIONS_FETCH_REQUEST, headerId, classroomId};
}

export function notificationsFetchSuccess(headerId, classroomId, payload) {
  return {type: types.NOTIFICATIONS_FETCH_SUCCESS, headerId, classroomId, payload};
}

export function notificationsFetchFailure(headerId, classroomId, error) {
  return {type: types.NOTIFICATIONS_FETCH_FAILURE, headerId, classroomId, error};
}

export function readingTimeDataFetchSuccess(headerId, classroomId, payload) {
  return {type: types.READING_TIME_FETCH_SUCCESS, headerId, classroomId, payload};
}

export function workDeepLinkWasHandled() {
  return {type: types.DEEP_LINK_WAS_HANDLED};
}
