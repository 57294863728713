import {constants} from '@newsela/angelou';

import {NAVBAR_BREAKPOINTS} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';

export const newselaLogoCss = {
  root: {
    width: '2.5rem',
    height: '2.5rem',

    [`@media (max-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
      width: '2rem',
      height: '2rem',
    },
  }
};

export const newselaLogoAnchorCss = {
  root: {
    display: 'flex',
    padding: 0,
    height: '2.5rem',
    width: '2.5rem',

    path: {
      fill: constants.colors.ui.core[500],
    },

    [`@media (max-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
      width: '2rem',
      height: '2rem',
    },
  }
};
