import React, {useEffect} from 'react';

import {Route} from 'react-router-dom';

import useClientSideRoutingHasOccurred from 'utils/useClientSideRoutingHasOccurred';

const NotFoundView = () => {
  const clientSideRoutingHasOccurred = useClientSideRoutingHasOccurred();
  useEffect(() => {
    // If the client side is rendering the not found view due to a client-side
    // routing event, fallback to the server as a stronger source of truth by
    // reloading the page.
    if (clientSideRoutingHasOccurred) {
      window.location.reload();
    }
  }, [clientSideRoutingHasOccurred]);
  return (
    <div data-qa-selector="client_side_not_found" />
  );
};

const NotFoundRoute = (
  <Route path="*">
    <NotFoundView />
  </Route>
);

export default NotFoundRoute;
