import React from 'react';

import {useSelector} from 'react-redux';

import {AssignmentsLink} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/ResourceMenus/AssignmentsLink';
import {ContentLibraryMenu} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/ResourceMenus/ContentLibraryMenu';
import {ReportsMenu} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/ResourceMenus/ReportsMenu';
import {
  resourceItemsVisibilityBreakpointsCss,
  resourceItemsContainerCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/ResourceMenus/ResourceMenus.styles';

import {AdminMenu} from './AdminMenu';
import {getUser} from '../../../../../modules/selectors/userSelectors';

const ResourceMenus = () => {
  const user = useSelector((state) => getUser(state));

  if (!user?.is_authenticated) return null;

  return (
    <li className={resourceItemsVisibilityBreakpointsCss}>
      <ul className={resourceItemsContainerCss}>
        <ContentLibraryMenu />
        <ReportsMenu />
        <AssignmentsLink />
        <AdminMenu />
      </ul>
    </li>
  );
};

ResourceMenus.displayName = 'OtherMenuItems';
export {ResourceMenus};
