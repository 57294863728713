import cssVars from 'css-vars-ponyfill';

// Regarding the masthead height, it currently remains necessary to:
// 1. Be able to access it from CSS and / or JS
// 2. Overwrite it at runtime
// Hence, the following utility functions.

import defaultMastheadHeight from 'constants/defaultMastheadHeight';

const getCssVar = (variable, parser = (v) => v) => (
  parser(getComputedStyle(document.documentElement).getPropertyValue(variable).trim())
);

export const setGlobalHeaderHeight = (height) => {
  cssVars({
    variables: {
      global_header_height: height,
    }
  });
};

export const setUtilityNavHeight = (height) => {
  cssVars({
    variables: {
      utility_nav_height: height,
    }
  });
};

export const setMastheadHeight = (height) => {
  cssVars({
    variables: {
      masthead_height: height,
    }
  });
};



export const setContentOffset = (offset) => {
  cssVars({
    variables: {
      content_offset: offset,
    }
  });
};

export const setGlobalNavHeight = (offset) => {
  cssVars({
    variables: {
      global_nav_height: offset,
    }
  });
};

export const getGlobalHeaderHeight = () => {
  return getCssVar('--global_header_height');
};

export const getUtilityNavHeight = () => {
  return getCssVar('--utility_nav_height');
};

export const getGlobalNavHeight = () => {
  return getCssVar('--global_nav_height');
};

export const getMastheadHeight = () => {
  return getCssVar('--masthead_height');
};

export const getToolIndicatorHeight = () => {
  return getCssVar('--indicator_height');
};

export const setMastheadHeightToDefault = () => {
  setMastheadHeight(defaultMastheadHeight);
  setContentOffset(defaultMastheadHeight);
};
