import {isEmpty, isFunction} from 'lodash-es';
import {takeLatest, takeEvery, call, put, select} from 'redux-saga/effects';

import * as actions from 'modulesV2/actions';
import * as types from 'modulesV2/constants';
import {externalToolUtils, layoutAdjustmentUtils} from 'modulesV2/utils';
import {requestClassrooms} from 'static/three-oh/src/modules/actions/classroomActions';
import {getUser} from 'static/three-oh/src/modules/selectors/userSelectors';
import {getMastheadHeight, getToolIndicatorHeight} from 'utils/mastheadHeight';

function * setExternalToolContext(action) {
  externalToolUtils.setContext(action.payload);
  yield put(actions.externalToolContextUpdateSuccess(action.payload));
  // When a context is populated, update masthead offset to accommodate
  // external tool message.
  layoutAdjustmentUtils.setMastheadOffset(
    getMastheadHeight(), getToolIndicatorHeight());
}

function * clearExternalToolContext(action) {
  yield call(externalToolUtils.clearContext);
  if (!isEmpty(window.location.hash)) {
    // Angular pages don't play nice with Redux.
    // Therefore manually restore masthead to default position.
    document.querySelector('#react-masthead header').style = 'top: 0px';
  }
  // Return to the default Masthead offset.
  layoutAdjustmentUtils.setMastheadOffset(getMastheadHeight());
  if (action.callback && isFunction(action.callback)) {
    yield call(action.callback);
  }
}

function * fillContextFromLocalStorage() {
  // Localstorage is synchronous.
  const context = externalToolUtils.getContext();
  if (context) {
    yield put(actions.externalToolContextUpdateSuccess(context));
    layoutAdjustmentUtils.setMastheadOffset(getMastheadHeight(), getToolIndicatorHeight());
  }
}

function * fetchClassrooms() {
  const user = yield select(getUser);
  const state = yield select();
  if (state.classrooms.dateRequested === null) {
    yield put(requestClassrooms(user.id));
  }
}

export function * watchSetExternalToolContext() {
  yield takeLatest(types.SET_EXTERNAL_TOOL_CONTEXT, setExternalToolContext);
}

export function * watchRosterSyncSuccess() {
  yield takeEvery(types.REQUEST_ALL_ROSTER_SYNC_INFO_SUCCESS, fetchClassrooms);
}

export function * watchClearExternalToolContext() {
  yield takeLatest(types.CLEAR_EXTERNAL_TOOL_CONTEXT, clearExternalToolContext);
}

export function * watchGetExternalToolContext() {
  yield takeLatest(types.GET_EXTERNAL_TOOL_CONTEXT, fillContextFromLocalStorage);
}
