import * as types from 'modulesV2/constants';

function collectionsReducer(state = {}, action) {
  switch (action.type) {
    case types.COLLECTIONS_FETCH_REQUEST:
      return {
        isLoading: true,
        error: null,
      };
    case types.COLLECTIONS_FETCH_SUCCESS:
      return {
        isLoading: false,
        error: null,
        items: action.data,
      };
    case types.COLLECTIONS_FETCH_FAILURE:
      return {
        isLoading: false,
        error: action.error,
        items: [],
      };
    default:
      return state;
  }
}

export default collectionsReducer;
