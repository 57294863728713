import React from 'react';

import {
  faSquare as notSelected
} from '@fortawesome/free-regular-svg-icons';
import {
  faCheckSquare as selected,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Checkbox, constants, Toast, Anchor} from '@newsela/angelou';
import {map, get, orderBy, reject, has} from 'lodash-es';
import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {bindActionCreators} from 'redux';

import MessageCta from 'components/MessageCta';
import globalToasterRef from 'constants/globalToasterRef';
import {clearModal} from 'modulesV2/actions';
import {ButtonWithTrackEvent} from 'static/three-oh/src/components/Button/Button';
import uiColors from 'static/three-oh/src/components/Colors/colors.js';
import {
  messageLevels,
  transitionDirections,
} from 'static/three-oh/src/components/Message';
import StyledModal from 'static/three-oh/src/components/Modal/StyledModal/StyledModal';
import Pill from 'static/three-oh/src/components/Pill';
import TrackedLink from 'static/three-oh/src/components/TrackedLink';
import {lyceumOrigin} from 'static/three-oh/src/constants/lyceum';
import {stringifyParams} from 'static/three-oh/src/modules/apis/ApiFetcher2';
import fetcher3 from 'static/three-oh/src/modules/apis/ApiFetcher3';
import ContentApi from 'static/three-oh/src/modules/apis/Content/ContentApi.js';
import {getUserProperties} from 'static/three-oh/src/modules/selectors/userSelectors';
import {
  determineSRCState,
  CONTENT_SETTINGS_TYPE,
  getAllRestrictedBandsCopy,
  getConSetVisibilityMarkers,
  getIsContentStudentRestricted
} from 'static/three-oh/src/modules/utils/assignmentHelpers';
import {getModalId, getModalOptions} from 'static/three-oh/src/modulesV2/selectors';
import AngelouProps from 'static/three-oh/src/propTypes/AngelouPropTypes';
import {toSnakeCase} from 'static/three-oh/utils/stringUtils';
import withV3AssignmentApiGet, {formatAssignmentPayload} from 'static/three-oh/utils/withV3AssignmentApiGet/withV3AssignmentGetWithoutPagination';

import {getContentAdvisoryMessage} from '../../../utils/contentAdvisoryMessage';
import {ASSIGNMENT_VERSION_LEGACY} from '../../constants/Assignments';
import {isObjectArchived} from '../../modules/utils/dateHelpers';
import LoadingShimmerMapper from '../LoadingShimmerMapper';


import styles from './styles.scss';

const neutral25 = constants.colors.ui.neutral[25];

const propTypesAssignButtonModal = {
  ...AngelouProps,
  assignmentsApiCallState: PropTypes.object,
  clearModal: PropTypes.func,
  createdFrom: PropTypes.string,
  history: PropTypes.object,
  modalId: PropTypes.string,
  contentIds: PropTypes.arrayOf(PropTypes.string),
  isFreeUser: PropTypes.bool,
  retrieveAssignmentsApiCallFunc: PropTypes.func,
  user: PropTypes.object,
};

class AssignButtonModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      selectedAssignments: {},
      updatePending: false,
      acceptedContentAdvisory: false,
      veiledContent: [],
      assignmentsToDisplay: undefined,
      contentList: [],
    };

    this.closeModal = this.closeModal.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.toggleAssignmentSelect = this.toggleAssignmentSelect.bind(this);
    this.updateAssignmentsWithContent = this.updateAssignmentsWithContent.bind(this);
  }

  componentDidMount() {
    this.getContentAdvisoryMessage(this.props.user);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.contentIds) !== JSON.stringify(prevProps.contentIds)) {
      this.fetchContent(this.props.contentIds);
    }
    if (this.props.assignmentsApiCallState !== prevProps.assignmentsApiCallState) {
      this.assignmentsWithActiveAssigneesOrAreDrafts(this.props.assignmentsApiCallState?.data, false);
    }
    this.checkModalStatus(prevProps);
  }

  assignmentsWithActiveAssigneesOrAreDrafts(assignments, checkState = true) {
    if (!assignments) return [];
    if (checkState && this.state.assignmentsToDisplay) return this.state.assignmentsToDisplay;

    const isActiveClassroom = ({classroom}) => !isObjectArchived(classroom);
    const assignmentsToDisplay = assignments.filter(
      ({assignment_classrooms, assignment_groups, is_draft}) => (
        assignment_classrooms.some(isActiveClassroom) || assignment_groups?.length > 0 || is_draft
      )
    );

    this.setState({assignmentsToDisplay});
    return assignmentsToDisplay;
  }

  async fetchContent(contentIds) {
    const depth = 1;
    const shape = 'card';
    const member_count = 1;


    for (const contentId of contentIds) {
      const response = await ContentApi.get(contentId, {
        depth,
        shape,
        member_count,

      });

      if (response?.data) {
        this.setState({contentList: [...this.state.contentList, response.data]});
      }

      if (response?.data?.veil || response?.data?.visibility_markers) {
        this.setState({veiledContent: [...this.state.veiledContent, {content_id: contentId, veil: response?.data?.veil, visibility_markers: response?.data?.visibility_markers}]});
      }
    }
  }

  checkModalStatus(prevProps) {
    if (this.props.modalId !== prevProps.modalId) {
      const shouldBeOpen = this.props.modalId === 'AddContentToAssignmentModal';
      if (shouldBeOpen && this.props.assignmentsApiCallState.isLoading === undefined) {
        this.props.retrieveAssignmentsApiCallFunc();
      }
      this.setState({modalIsOpen: shouldBeOpen});
    }
  }

  contentWithAdvisory() {
    return (this.state.veiledContent || []).filter((content) => {
      // Content Settings Advisory
      if (getIsContentStudentRestricted(content)) { return true; }
      // CAT
      return content.veil?.reason === 'DISTRICT_ADVISORY';
    });
  }

  contentIdsWithAdvisory() {
    return this.contentWithAdvisory().reduce((acc, content) => {
      acc.push(content.content_id);
      return acc;
    }, []).join();
  }

  contentListIds() {
    return this.state.contentList.map((content) => content.content_id).join();
  }

  selectionHasDrafts() {
    const assignments = map(this.state.selectedAssignments, (assignment) => {
      return assignment.is_draft;
    });

    return assignments.includes(true);
  }

  selectionHasActiveAssignments() {
    return Object.values(this.state.selectedAssignments).some((assignment) => !assignment.is_draft);
  }

  taggedContentType() {
    if (this.contentWithAdvisory().length) {
      return CONTENT_SETTINGS_TYPE.CAT;
    }
    const {containsSRC} = determineSRCState(this.state.contentList);
    if (containsSRC) {
      return CONTENT_SETTINGS_TYPE.SRC;
    }
    return null;
  }

  shouldDisplayContentAdvisory() {
    return this.selectionHasActiveAssignments() && this.taggedContentType();
  }

  consentedContentIds() {
    const taggedContentType = this.taggedContentType();
    switch (taggedContentType) {
      case CONTENT_SETTINGS_TYPE.CAT:
        return this.contentIdsWithAdvisory();
      case CONTENT_SETTINGS_TYPE.SRC:
        return this.contentListIds();
      default:
        return '';
    }
  }

  updateAssignmentsWithContent() {
    this.setState({updatePending: true});

    // Add the content(s) to each selected assignment.
    const requests = map(this.state.selectedAssignments, async(assignment) => {
      const assignmentContentIds = assignment?.assignment_members.map((assignmentMember) => assignmentMember.content.content_id);
      this.props.contentIds.forEach((contentId, i) => {
        // Only add the content to the assignment if it is not already included
        // in the assignment, and, if the user is licensed to assign the content.
        const okToAddMemberToAssignment = !assignmentContentIds.includes(contentId);

        if (okToAddMemberToAssignment) {
          assignment.assignment_members.push({
            content: {content_id: contentId},
            display_order: assignment.assignment_members.length + i
          });
        }
      });

      // Add to assignment downgrades MVE assignment version to "legacy"
      assignment.version = ASSIGNMENT_VERSION_LEGACY;

      const assignmentPayload = formatAssignmentPayload(assignment, this.consentedContentIds(), this.taggedContentType());

      const request = await fetcher3.post(`assignment/${assignment.content_id}/`, {data: assignmentPayload});
      return request;
    });



    const handleSuccess = () => {
      return this.setState({
        modalIsOpen: false,
        updatePending: false,
        selectedAssignments: {}
      }, () => {
        globalToasterRef.current.displayToast({
          statusColor: Toast.statusColor.success,
          showIcon: true,
          children: (
            <span
              data-qa-selector="success_message"
            >
              Content Added
              <TrackedLink
                href="/binder/assignments"
                eventName="navigate-from-add-article-success"
              >
                View Assignments
              </TrackedLink>
            </span>
          )
        });
        this.props.clearModal();
      });
    };

    const handleError = () => {
      return this.setState(
        {updatePending: false},
        () => {
          globalToasterRef.current.displayToast({
            statusColor: Toast.statusColor.danger,
            children: 'Your assignment(s) failed to update. Please try again later.',
          });
        }
      );
    };

    Promise.all(requests).then(handleSuccess).catch(handleError);
  }

  async navigateToCreateAssignment() {
    const {clearModal, history} = this.props;
    const urlParams = {
      return_url: history.location.pathname + history.location.search,
      createdFrom: this.props.contentId,
      contentIds: this.props.contentIds
    };
    const params = stringifyParams(urlParams);

    clearModal();
    window.location.href = `${lyceumOrigin}/assignments/create${params}`;
  }

  toggleAssignmentSelect(assignment, isAlreadyAssigned) {
    // The assignment should still be selectable if some articles from a
    // text set are already in that assignment.
    if (isAlreadyAssigned && !this.props.createdFrom) { return; }
    if (this.state.selectedAssignments[assignment.content_id]) {
      // remove
      const removedFromList = this.state.selectedAssignments;
      delete removedFromList[assignment.content_id];
      this.setState({selectedAssignments: removedFromList});
    } else {
      // add
      const addedToList = this.state.selectedAssignments;
      addedToList[assignment.content_id] = assignment;
      this.setState({selectedAssignments: addedToList});
    }
  }

  closeModal() {
    this.setState({selectedAssignments: {}, veiledContent: []}); // Reset to initial state.
    this.props.clearModal();
  }

  renderModalButtons() {
    if (this.props.assignmentsApiCallState.isLoading || this.state.updatePending) return <></>;

    const tracking = {
      objectType: 'Assignment',
      eventProperties: {
        content_id: this.props.createdFrom,
        content_ids: this.props.contentIds,
        selectedAssignments: this.state.selectedAssignments,
        additionalObjectType: this.selectionHasDrafts() ? 'draft' : '',
        description: 'Add content to assignments'
      },
      streamProperties: {
        description: 'Add content to assignment(s).',
        additional_object_type: this.selectionHasDrafts() ? 'draft' : '',
        content_id: this.props.createdFrom,
        content_ids: this.props.contentIds,
        ...(this.shouldDisplayContentAdvisory() ? {
          component_type: 'content-advisory-assignment',
          content_advisory_override_consent: this.contentIdsWithAdvisory()
        } : {})
      }
    };

    const renderCancelButton = () => (
      <ButtonWithTrackEvent
        standard
        secondary
        key="cancel"
        label="Cancel"
        onClick={this.closeModal}
        actionPrefix="Cancel"
        actionName="Update"
        dataQaSelector={this.shouldDisplayContentAdvisory() ? 'cat_close_assignment_modal' :
          'close_assignment_modal'}
        legacyEventName="Assignment-ExitAddHeaderModal"
        {...tracking}
      />
    );

    if (this.hasAssignments()) {
      const assignmentsSelected = Object.keys(this.state.selectedAssignments).length;
      const buttonText = assignmentsSelected > 1 ? `Add to ${assignmentsSelected} Assignments` : 'Add to Assignment';
      const isDisabled = (this.shouldDisplayContentAdvisory() && !this.state.acceptedContentAdvisory) || assignmentsSelected <= 0;
      const restrictedBandsCopy = getAllRestrictedBandsCopy(getConSetVisibilityMarkers(this.state.veiledContent));
      return (
        <>
          {(this.shouldDisplayContentAdvisory()) ?
              (<div className={styles.confirmationMsg}>
                <LoadingShimmerMapper loading={this.state.catLoading}>
                  <p>{this.state.conSet.title}</p>
                  <p>{this.state.conSet.body}</p>
                  {this.state.hyperlink ? <Anchor href={this.state.hyperlink.url}>{this.state.hyperlink.text}</Anchor> : null}
                </LoadingShimmerMapper>
                <p>All students included in the assignment will be given access to this content.</p>
                {
                  restrictedBandsCopy
                    ? <p>{restrictedBandsCopy}</p>
                    : null
                }
                <Checkbox
                  label="I’ve read this message and choose to assign the content."
                  __cssFor={{
                    root: `margin-bottom: 1em;background-color: ${neutral25}; padding: 1em;
                      `,
                    checkedBox: 'height: 1.5em',
                    emptyCheckbox: 'height: 1.5em'
                  }}
                  checked={this.state.acceptedContentAdvisory}
                  onClick={() => this.setState({acceptedContentAdvisory: !this.state.acceptedContentAdvisory})}
                /></div>
              ) :
              <></>}

          <div className={styles.CTABtns}>{renderCancelButton()}
            <ButtonWithTrackEvent
              standard
              primary
              disabled={isDisabled}
              dataQaSelector={this.shouldDisplayContentAdvisory() ? 'cat_update_assignment_btn_modal' : toSnakeCase(buttonText)}
              key="add"
              label={buttonText}
              onClick={this.updateAssignmentsWithContent}
              actionName={this.shouldDisplayContentAdvisory() ? 'Consent Event' : 'Update'}
              legacyEventName="Assignment-Update"
              {...tracking}
            /></div>

        </>
      );
    } else {
      return (
        <div className={styles.CTABtns}>
          {renderCancelButton()}
          <ButtonWithTrackEvent
            standard
            primary
            key="create"
            label="Create Assignment"
            onClick={() => this.navigateToCreateAssignment()}
            actionPrefix="Initiate"
            actionName="Create"
            dataQaSelector={this.shouldDisplayContentAdvisory() ? 'cat_create_assignment_btn_modal' : 'create_assignment_btn_modal'}
            legacyEventName="Assignment-InitiateCreate"
            {...tracking}
          />
        </div>
      );
    }
  }

  hasAssignments() {
    return (
      this.assignmentsWithActiveAssigneesOrAreDrafts(this.props.assignmentsApiCallState?.data).length > 0
    );
  }

  isAlreadyAssigned(assignment) {
    // Check to see if any of the content attempting to be assigned already
    // belongs to the selected assignment.
    const assignmentContentIds = assignment?.assignment_members?.map((assignmentMember) => assignmentMember?.content?.content_id);
    const already = assignmentContentIds.some((contentId) => this.props.contentIds?.includes(contentId));
    return already;
  }

  async getContentAdvisoryMessage(user) {
    let advisoryMessage;
    try {
      this.setState({catLoading: true});
      advisoryMessage = await getContentAdvisoryMessage(user);
      this.setState(advisoryMessage);
    } catch (e) {
      this.setState({catError: e});
    } finally {
      this.setState({catLoading: false});
    }
  }

  renderAssignmentDate(isAlreadyAssigned, assignment) {
    const date = assignment?.date_assigned;
    const isDraft = assignment?.is_draft;
    if (isAlreadyAssigned && this.props.createdFrom) {
      return (
        <Pill
          backgroundColor={uiColors.grey_medium[0]}
          textColor={uiColors.white[0]}
          text="Some content may already be added"
        />
      );
    } else if (isAlreadyAssigned) {
      return (
        <Pill
          backgroundColor={uiColors.grey_medium[0]}
          textColor={uiColors.white[0]}
          text="Already Added"
        />
      );
    } else {
      if (isDraft) { // Future dates get highlighted background.
        return (
          <Pill
            backgroundColor={uiColors.grey_medium[0]}
            textColor={uiColors.white[0]}
            text="Draft"
          />
        );
      } else {
        return (
          <Pill
            backgroundColor={uiColors.grey_medium[0]}
            textColor={uiColors.white[0]}
            text={date ? `Assigned on ${moment(date).format('MM/DD/YY')}` : 'Assigned'}
          />
        );
      }
    }
  }

  renderModalBody() {
    if (this.props.assignmentsApiCallState.isLoading || this.state.updatePending) {
      return <div className={styles.loading}><i className="fa fa-spinner fa-spin" /></div>;
    }

    if (this.hasAssignments()) {
      const assignments = orderBy(this.state.assignmentsToDisplay, (assignment) => {
        return new Date(assignment.date_modified);
      }, 'desc');

      const premiumHeadersRemovedMessage = (
        <span>
          Only articles for which you have a license will be added: the others will be removed.
        </span>
      );
      const premiumHeadersRemoved = (
        this.props.isFreeUser &&
        !!reject(this.props.articleHeaders, ['requires_license', false]).length
      );

      return (
        <>
          <p
            className={styles.modalInstructions}
            data-qa-selector="modal_instructions"
          >
            Add this content to one or more of your existing assignments.
          </p>
          {
            premiumHeadersRemoved &&
            <MessageCta
              level={messageLevels.info}
              text={premiumHeadersRemovedMessage}
              ctaText={null}
              transitionDirection={transitionDirections.DOWN}
              key={`pro_headers_were_removed_${this.props.createdFrom}`}
              additionalClassnames={[styles.proHeadersRemovedMessage]}
              hideCloseIcon
            />
          }
          <ul
            className={styles.list}
            data-qa-selector="list"
          >
            {
              assignments.map((assignment, index) => {
                const isAlreadyAssigned = this.isAlreadyAssigned(assignment);
                // If articles from a text set are in the assignment, the user should still have the option to add the text set.
                const isSelected = has(this.state.selectedAssignments, assignment.content_id) || (isAlreadyAssigned && !this.props.createdFrom);
                return (
                  <li key={`assignment-${assignment.content_id}`}>
                    <div
                      className={`${styles.assignmentRow} ${(isAlreadyAssigned && !this.props.createdFrom) ? styles.already : ''}`}
                      onClick={() => this.toggleAssignmentSelect(assignment, isAlreadyAssigned)}
                      data-qa-selector={`assigned_toggle_${index}`}
                    >
                      <div><FontAwesomeIcon icon={isSelected ? selected : notSelected}/></div>
                      <div className={styles.assignmentInfo}>
                        <div
                          className={styles.assignmentTitle}
                          data-qa-selector={toSnakeCase(assignment.title)}
                        >{assignment.title}</div>
                        {this.renderAssignmentDate(isAlreadyAssigned, assignment)}
                      </div>
                    </div>
                  </li>
                );
              })
            }
          </ul>
        </>
      );
    } else {
      return (
        <p
          className={styles.modalInstructions}
          data-qa-selector="modal_instructions"
        >You currently have no assignments. Would you like to create one?</p>
      );
    }
  }

  renderModal() {
    return (
      <StyledModal
        cssFor={styles.modal}
        open={this.state.modalIsOpen}
        onClose={this.closeModal}
        title="Add content to Assignment"
        body={this.renderModalBody()}
        scrollBody
        footer={this.renderModalButtons()}
        additionalFooterStyles={styles.footer}
        centered
        disableFocusTrap={false}
      />
    );
  }

  render() {
    if (this.props.user.is_school_educator) {
      return (
        <>
          {this.renderModal()}
        </>
      );
    } else {
      return null;
    }
  }
}

AssignButtonModal.propTypes = propTypesAssignButtonModal;
AssignButtonModal.defaultProps = {
  primary: true,
  rounded: true,
};

const MapStateToProps = (state) => {
  return {
    createdFrom: get(getModalOptions(state), 'createdFrom'),
    contentIds: get(getModalOptions(state), 'contentIds'),
    modalId: getModalId(state),
    history: get(getModalOptions(state), 'history'),
    isFreeUser: getUserProperties(state).isFreeUser,
    user: state.user,
  };
};

const actions = {
  clearModal,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(actions, dispatch),
  };
}

export default connect(MapStateToProps, mapDispatchToProps)(withRouter(withV3AssignmentApiGet(AssignButtonModal)));
