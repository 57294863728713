import React from 'react';

import {
  ArticleCard,
  AudioCard,
  BiographyCard,
  ExternalLinkCard,
  InfographicCard,
  VideoCard,
} from '@newsela/angelou';
import {isNil} from 'lodash-es';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import withTrackEvent from 'static/three-oh/src/components/with/WithTrackEvent';
import {getAuxImage} from 'static/three-oh/src/containers/MultiCardContainer/ExternalLink_aux.jsx';
import {getBadges} from 'static/three-oh/src/containers/MultiCardContainer/getBadges';
import {getUserProperties} from 'static/three-oh/src/modules/selectors/userSelectors';


const propTypes = {
  content_id: PropTypes.string,
  content_provider: PropTypes.object,
  content_type: PropTypes.string,
  date_published: PropTypes.string,
  image: PropTypes.object,
  length_in_seconds: PropTypes.number,
  title: PropTypes.string,
  slug: PropTypes.string,
  url: PropTypes.string,
  user_level_article: PropTypes.object,
  is_premium: PropTypes.bool,
  story_content_id: PropTypes.string
};

/**
 * Conditional card rendering. Intended to work with
 * v3 content_type objects.
 */
const DeckOfCards = React.forwardRef((props, ref) => {
  const {isLiteUser, isStudent} = useSelector((state) => getUserProperties(state));

  const getHref = {
    articleheader: `/view/${props.story_content_id}`,
    audiotrack: `/audio-tracks/${props.content_id}`,
    biography: `/view/${props.story_content_id}`,
    externallink: props.url,
    infographic: `/view/${props.story_content_id}`,
    video: `/videos/${props.content_id}`,
    interactivevideolink: `/view/${props.content_id}`,
  };

  const fallbackImage = getAuxImage(props.content_id);
  const liteStudentPremiumArticle = isLiteUser && isStudent && props.is_premium;

  const mappedProps = {
    img: {
      src: !isNil(props.image) ? decodeURIComponent(props.image?.url) : fallbackImage,
      alt: props.image?.alt_text
    },
    title: props.title || props.user_level_article.title,
    source: props.content_provider?.name,
    datePublished: props.date_published,
    href: liteStudentPremiumArticle ? '/student-binder/assignments/' : getHref[props.content_type],
    duration: props?.length_in_seconds,
    badges: getBadges(props, isLiteUser, isStudent)
  };

  const deck = {
    articleheader: (<ArticleCard {...mappedProps} {...props} ref={ref} />),
    audiotrack: (<AudioCard {...mappedProps} {...props} ref={ref} />),
    biography: (<BiographyCard {...mappedProps} {...props} ref={ref} />),
    externallink: (<ExternalLinkCard {...mappedProps} {...props} ref={ref} />),
    infographic: (<InfographicCard {...mappedProps} {...props} ref={ref} />),
    video: (<VideoCard {...mappedProps} {...props} ref={ref} />),
    interactivevideolink: (<VideoCard {...mappedProps} {...props} ref={ref} />),
  };

  return deck[props.content_type];
});

DeckOfCards.propTypes = propTypes;

export default withTrackEvent(DeckOfCards);

