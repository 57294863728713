import * as types from 'modulesV2/constants';


export default function rosterDetailsReducer(state = {}, action) {
  switch (action.type) {
    case types.REQUEST_ROSTER_DETAILS:
      return {
        ...state,
        [action.authProvider]: {
          fetching: true,
        }
      };
    case types.REQUEST_ROSTER_DETAILS_SUCCESS:
      return {
        ...state,
        [action.authProvider]: {
          ...action.data,
          fetching: false,
          error: null,
        }
      };
    case types.REQUEST_ROSTER_DETAILS_FAILURE:
      return {
        ...state,
        [action.authProvider]: {
          fetching: false,
          error: action.error,
        }
      };
    default:
      return state;
  }
}
