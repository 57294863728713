import ApiFetcher3 from 'static/three-oh/src/modules/apis/ApiFetcher3';

class ContentLikeApi {
  static get() {
    return ApiFetcher3.get('content-bookmark/');
  }

  static save(contentId) {
    return ApiFetcher3.post('content-bookmark/', {data: {content_id: contentId}});
  }

  static delete(likeId) {
    if (likeId) {
      return ApiFetcher3.delete(`content-bookmark/${likeId}/`);
    }
  }
}

export default ContentLikeApi;
