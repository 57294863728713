import React from 'react';

import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';

export const NEWSROOM_ROOT = '/news';

const LazyNewsContainer = Loadable({
  loader: () => import(
    /* webpackChunkName: "News" */
    '../Subjects/Container'
  ),
  loading: () => null
});

const NewsRoute = (
  <Route
    key="News"
    path={`${NEWSROOM_ROOT}/:rootTextSetId`}
    component={LazyNewsContainer}
  />
);

export default NewsRoute;
