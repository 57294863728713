const POWER_WORDS = {
  name: 'Power Words',
  slug: '/binder/power-words',
};

const READING_SUMMARY = {
  name: 'Reading Summary',
  slug: '/binder/students',
};

const CLASSROOM_DATA = {
  name: 'Classroom Data',
  slug: '/reports/teacher/classroom-data'
};

const YOUR_PROGRESS = {
  name: 'Your Progress',
  slug: '/student-binder/assignments',
};

const YOUR_WORD_WALL = {
  name: 'Word Wall',
  slug: '/student-binder/word-wall',
};

export const USAGE_REPORT = {
  name: 'Usage Report',
  slug: '/reporting/usage-report',
};

export const PRINCIPAL_BINDER_CONSTANTS = {
  USAGE_REPORT,
};

export const ELA_CONSTANTS = {
  name: 'English Language Arts',
  slug: '/content/browse/ela-browse-page/',
  activeLicenseName: 'activeELALicense',
};

export const SCIENCE_CONSTANTS = {
  name: 'Science',
  slug: '/content/browse/browse-science/',
  activeLicenseName: 'activeScienceLicense',
};

export const SOCIAL_STUDIES_CONSTANTS = {
  name: 'Social Studies',
  slug: '/content/browse/browse-social-studies/',
  activeLicenseName: 'activeSSLicense',
};

export const EN_ESPANOL_CONSTANTS = {
  lang: 'es',
  name: 'En Español',
  slug: '/content/browse/spanish-browse-page/',
};

export const NEWS_CONSTANTS = {
  name: 'News',
  slug: '/content/browse/news-browse-page/',
};

export const TEACHER_BINDER_CONSTANTS = {
  POWER_WORDS,
  READING_SUMMARY,
  CLASSROOM_DATA,
};

export const STUDENT_BINDER_CONSTANTS = {
  YOUR_PROGRESS,
  YOUR_WORD_WALL,
};

export const ELA_SKILLS_REPORT = {
  name: 'ELA Skills Report',
  slug: '/reporting/ela-skills-report',
};
