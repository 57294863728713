import {get} from 'lodash-es';

const KEY = 'v2.services.objects.rosterClassrooms';
/**
 * @param {Object} state
 * @param {String} provider
 * @return {Array.<Object>}
 */
export const getRosterClassrooms = (state, provider) => get(state, `${KEY}.${provider}.items`, []);

/**
 * @param {Object} state
 * @param {String} provider
 * @return {Boolean}
 */
export const providerClassroomsLoading = (state, provider) => get(
  state, `${KEY}.${provider}.loading`, false
);

/**
 * @param {Object} state
 * @param {String} provider
 * @return {String|null}
 */
export const getProviderClassroomsError = (state, provider) => get(
  state, `${KEY}.${provider}.error`
);
