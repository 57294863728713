import React from 'react';

import {Anchor, Icon, LockHollowSVG} from '@newsela/angelou';
import PropTypes from 'prop-types';

import {
  lockedIconCss,
  subjectAreaAnchorCss,
  subjectAreaIconCss,
  titleCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SubjectItems/SubjectItem/SubjectItem.styles';

const propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  locked: PropTypes.bool,
  dataQASelector: PropTypes.string.isRequired,
  iconClass: PropTypes.oneOf(['apply-fill', 'apply-stroke']),
  SvgComponent: PropTypes.node,
};

const SubjectItem = ({title, SvgComponent, href, locked, iconClass = 'apply-fill', dataQASelector}) => {
  return (
    <Anchor __cssFor={subjectAreaAnchorCss} href={href} dataProps={{'data-qa-selector': dataQASelector}}>
      {locked
        ? (<Icon
            __cssFor={lockedIconCss}
            __classNameFor={{root: 'apply-stroke'}}
            SvgComponent={LockHollowSVG}
            dataProps={{'data-testid': 'locked-subject-item-icon'}}
           />)
        : SvgComponent && (<Icon
            __cssFor={subjectAreaIconCss}
            SvgComponent={SvgComponent}
            __classNameFor={{root: iconClass}}
            dataProps={{'data-testid': 'unlocked-subject-item-icon'}}
                           />)}
      <div style={titleCss}>{title}</div>
    </Anchor>
  );
};

SubjectItem.propTypes = propTypes;
SubjectItem.displayName = 'SubjectItem';
export {SubjectItem};
