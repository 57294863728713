import APIFetcher from '../APIFetcher';

// The "true" API name for this is ConstructedResponseAnswerReview.
class ConstructedResponseReviewApi {
  static post({
    answerId,
    dateRevisionRequested,
    dateShared,
    reviewId,
    score,
    text
  }) {
    const body = {
      answer_id: answerId,
      date_revision_requested: dateRevisionRequested,
      date_shared: dateShared,
      score: score,
      text: text,
    };
    const reviewUrl = reviewId ? reviewId + '/' : '';
    const url = `constructedresponseanswerreview/${reviewUrl}`;
    return APIFetcher.post(url, {
      payload: body
    });
  }
}

export default ConstructedResponseReviewApi;
