import * as types from '../constants/sessionActionTypes';


export function deleteSessionRequest() {
  return {type: types.DELETE_SESSION_REQUEST};
}

export function deleteSessionFailure() {
  return {type: types.DELETE_SESSION_FAILURE};
}

export function deleteSessionSuccess() {
  return {type: types.DELETE_SESSION_SUCCESS};
}
