import React, {useState} from 'react';

import {cx} from 'emotion';
import {sortBy, slice, map} from 'lodash-es';
import PropTypes from 'prop-types';

import StandardChip from 'static/three-oh/src/components/StandardChip';

import Toggle from './components/Toggle/Toggle';
import styles from './styles';

const propTypes = {
  standards: PropTypes.arrayOf(PropTypes.object),
  labelClass: PropTypes.string,
};

/**
 * A styled list of standards chips intended to be displayed as
 * meta data associated with any type of content.
 */
const StandardsList = React.memo((props) => {
  const [showAdditionalStandards, toggle] = useState(false);
  const {labelClass, standards} = props;

  // Only display component if the api has returned standards.
  if (standards.length === 0) {
    return null;
  }

  const handleClick = () => {
    // TODO: handle keyboard focus on click.
    toggle(!showAdditionalStandards);
  };

  const sortedStandards = sortBy(standards, 'number_enhanced');
  // Shorten list so that tabbing will not access not visually available standards.
  const displayedStandards = showAdditionalStandards ? sortedStandards : slice(sortedStandards, 0, 3);

  return (
    <>
      <span className={labelClass}>Standards ({standards.length}):</span>
      <div className={styles.toggleContainer}>
        <ul className={cx(styles.standardsContainer, showAdditionalStandards ? '' : styles.collapsed)}>
          {map(displayedStandards, (standard, index) => (
            <li
              key={index}
              className={styles.listItem}
            >
              <StandardChip
                key={index}
                standard={standard}
              />
            </li>
          ))}
        </ul>
        {
          sortedStandards.length > 3 &&
          <Toggle
            collapsed={!showAdditionalStandards}
            onClick={handleClick}
          />
        }
      </div>
    </>
  );
});

StandardsList.propTypes = propTypes;
StandardsList.defaultProps = {standards: []};

export default StandardsList;
