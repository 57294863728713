import React from 'react';

import {LoadingSpinner} from '@newsela/angelou';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';

import {myContentRoot} from 'static/three-oh/src/modules/constants/routePaths';

const LazyYourContentContainer = Loadable({
  loader: () => import(
    /* webpackChunkName: "YourContentContainer" */
    './containers/YourContentContainer'
  ),
  loading: LoadingSpinner,
});

const YourContentRoute = (
  <Route
    key="yourContent"
    path={`${myContentRoot}/:tab?`}
    component={LazyYourContentContainer}
  />
);

export default YourContentRoute;
