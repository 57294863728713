import React, {useEffect} from 'react';

import {constants, MarketingBanner} from '@newsela/angelou';
import {useDispatch, useSelector} from 'react-redux';

import {getUserProperties, getUser} from 'static/three-oh/src/modules/selectors/userSelectors';
import {ReactComponent as MapSvg} from 'staticImages/trial-banner/map.svg';
import {ReactComponent as WarningSvg} from 'staticImages/trial-banner/warning.svg';

import {trialBannerCss, trialTextCss} from './TrialBanner.styles';
import {getTrialBannerDaysHoursText} from './TrialBanner.utils';
import getTrialLicenseFarthestExpirationDate from '../../../../../../../utils/getTrialLicenseFarthestExpirationDate';
import {getLicenseRequest} from '../../../../../../modules/actions/licenseActions';

const TrialBanner = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => getUser(state));

  const {
    isStudent,
    hasPremiumLicense,
    needsOnboarding,
    hasPaidEssentialsLicense,
    hasActivePremiumTrialCampaign,
    hasNceTrialCampaign,
    hasSchoolPocLicense,
  } = useSelector((state) => getUserProperties(state));

  const licenses = useSelector((state) => state.license)?.data || [];

  useEffect(() => {
    dispatch(getLicenseRequest());
  }, []);

  const {
    daysToExpiration,
    hoursToExpiration,
    active: hasActiveTrial,
    isSchoolTrial: isSchoolWideTrial,
  } = getTrialLicenseFarthestExpirationDate(licenses);

  if (
    !user?.is_authenticated ||
    !hasActivePremiumTrialCampaign ||
    !hasActiveTrial ||
    isStudent ||
    needsOnboarding ||
    hasPremiumLicense ||
    hasPaidEssentialsLicense ||
    hasNceTrialCampaign ||
    hasSchoolPocLicense
  ) return null;

  const SvgComponent = daysToExpiration >= 5 ? MapSvg : WarningSvg;
  const bannerBackground = daysToExpiration >= 5 ? constants.colors.ui.purple[500] : constants.colors.ui.red[600];

  return (
    <MarketingBanner
      isCenter
      saturation="light"
      color="yellow"
      SvgComponent={SvgComponent}
      __cssFor={trialBannerCss(bannerBackground)}
      __renderBody={() => (
        <p className={trialTextCss} data-testid="trial-banner">
          You have <b>
            {getTrialBannerDaysHoursText({
              daysToExpiration,
              hoursToExpiration
            })}
          </b>left on your {isSchoolWideTrial ? 'school-wide ' : ''}trial.
        </p>
      )}
    />
  );
};

TrialBanner.displayName = 'PremiumTrialBanner';

export {TrialBanner};
