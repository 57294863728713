import React from 'react';

import PropTypes from 'prop-types';


const propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  style: PropTypes.object
};

/*
* Segment accepts a segment name as a prop, which
* allows its parent component, StudiesTestWrapper, to determine
* if that child should be rendered based on the user's segment.
*/
class Segment extends React.Component {
  render() {
    return (
      <div style={this.props.style}>{this.props.children}</div>
    );
  }
}

Segment.propTypes = propTypes;

export default Segment;
