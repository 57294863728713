import React from 'react';

import {LoadingSpinner} from '@newsela/angelou';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';


const LazyPollContainer = Loadable({
  loader: () => import(
    /* webpackChunkName: "StandalonePoll" */
    './components/StandalonePoll'
  ),
  delay: 1000,
  // eslint-disable-next-line
  loading: (props) => (props.pastDelay ? <LoadingSpinner /> : null)
});

const PollRoute = (
  <Route
    path="/polls/:pollSlug"
    component={LazyPollContainer}
  />
);

export default PollRoute;
