import React from 'react';

import PropTypes from 'prop-types';

import {TrackedLink} from 'static/three-oh/src/components';

import canvasLogo from '../../assets/canvas-logo.png';
import classlinkLogo from '../../assets/classlink-logo.png';
import cleverLogo from '../../assets/clever-logo.png';
import googleClassroomLogo from '../../assets/gc-logo.png';

import styles from './styles.scss';

if (window && window._static_url) { // eslint-disable-line no-undef
  // Include this to appropriately fetch images in the assets folder.
  __webpack_public_path__ = window._static_url; // eslint-disable-line no-undef
}


class IntegrationCard extends React.Component {
  static propTypes = {
    authProvider: PropTypes.string,
  };

  static defaultProps = {
    redirectToSettings: false,
  };

  render() {
    const {authProvider} = this.props;
    const canvasUrl = authProvider === 'canvas' ? '/account/classes/' :
      'https://support.newsela.com/article/teacher-instructions-for-canvas/';
    const cleverUrl = authProvider === 'clever' ? '/account/classes/' :
      'https://support.newsela.com/article/using-clever-for-teachers/';
    const classlinkUrl = authProvider === 'classlink' ? '/account/classes/' :
      'https://support.newsela.com/article/system-admin-instructions-for-classlink/';
    const googleUrl = authProvider === 'google-oauth2' ? '/account/classes/' :
      'https://support.newsela.com/item/supportArticle/ways-to-create-classes';
    const gridClassName = `${styles.buttonGrid}`;
    return (
      <div>
        <div className={gridClassName}>
          <div className={styles.button}>
            <TrackedLink
              href={googleUrl}
              eventName="import_google_classroom_click"
            >
              <span>Google Classroom</span>
              <img src={googleClassroomLogo} alt="Google Classroom Logo"/>
            </TrackedLink>
          </div>
          <div className={styles.button}>
            <TrackedLink
              href={canvasUrl}
              eventName="import_canvas_classroom_click"
            >
              <span>Canvas</span>
              <img src={canvasLogo} alt="Canvas Logo"/>
            </TrackedLink>
          </div>
          <div className={styles.button}>
            <TrackedLink
              href={cleverUrl}
              eventName="import_clever_classroom_click"
            >
              <span>Clever</span>
              <img src={cleverLogo} alt="Clever Logo"/>
            </TrackedLink>
          </div>
          <div className={styles.button}>
            <TrackedLink
              href={classlinkUrl}
              eventName="import_classlink_classroom_click"
            >
              <span>ClassLink</span>
              <img src={classlinkLogo} alt="Classlink Logo"/>
            </TrackedLink>
          </div>
        </div>
      </div>
    );
  }
}

export default IntegrationCard;
