import {
  filter,
  orderBy,
} from 'lodash-es';
import moment from 'moment';

import {applyTo, applier} from 'utils/lodashHelpers';

export const getAllLicenses = (user, {product, onlyActive = true} = {}) => {
  if (!user) return;
  return applyTo(user.licenses)(
    applier(filter)((lic) => {
      return (lic.product == product || !product) && (!onlyActive || lic.is_active);
    }),
    // This logic mirrors the logic in the Django License model.
    applier(orderBy)(
      ['is_active', 'is_trial', (lic) => {
        return moment(lic.date_ends);
      }],
      ['desc', 'asc', 'desc']
    )
  );
};

export const getAllActiveSubscriptions = (user) => {
  if (!user?.licenses) return [];
  const licenses = user.licenses;
  const filteredLicenses = licenses.filter((license) => {
    return license.is_active && license.is_subscription;
  });

  return filteredLicenses;
};

export const SUBJECT_PRODUCTS = ['essentials', 'ela', 'science', 'social_studies'];
export const PRODUCT_NAME_MAPPING = {
  social_studies: 'Social Studies',
  science: 'Science',
  ela: 'ELA',
  essentials: 'Essentials',
};
export const LICENSES_ABBREVIATIONS = {
  ela: 'ELA',
  social_studies: 'SS',
  science: 'SCI',
  essentials: 'ESS',
};


export const getLicensesAbbreviations = (licenses = []) => {
  const uniqueAbbreviations = licenses.reduce((obj, license) => {
    const abbreviation = LICENSES_ABBREVIATIONS[license.product];
    return abbreviation ? {...obj, [abbreviation]: license} : obj;
  }, {});

  return Object.keys(uniqueAbbreviations);
};
