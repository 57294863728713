import React from 'react';

import {size, range, get} from 'lodash-es';
import PropTypes from 'prop-types';

import {TrackedLink} from 'static/three-oh/src/components';
import {ButtonWithTrackEvent} from 'static/three-oh/src/components/Button/Button';
import LazyImage from 'static/three-oh/src/components/LazyImage';

import styles from './styles.scss';

const defaultProps = {
  eventProperties: {},
};

const propTypes = {
  eventSource: PropTypes.string.isRequired,
  eventProperties: PropTypes.object,
  showViewTextSetButton: PropTypes.bool,
  textSet: PropTypes.object.isRequired,
};

const textSetIcon = (
  <svg
    className={styles.textSetIcon}
    height="15px"
    version="1.1"
    viewBox="0 0 12 15"
    width="12px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Text Set</title>
    <g id="text-set_svg" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="text-set-default" fill="#999999">
        <path d="M3.99999473,2.08162113 L3.99999473,9.21864474 C3.99999473,10.3683279 4.60971097,10.8464189 5.71428496,10.8464189 L9.99999473,10.8464189 C11.1046003,10.8464189 12,10.0709466 12,8.92129564 L12,2.08162113 C12,0.931954083 11.1046003,-3.55271368e-15 9.99999473,-3.55271368e-15 L6,-3.55271368e-15 C4.89541019,-3.55271368e-15 3.99999473,0.931954083 3.99999473,2.08162113 L3.99999473,2.08162113 Z M0,5.65013293 L0,12.4898074 C0,13.6394745 0.895399651,14.5714286 2.00000527,14.5714286 L6,14.5714286 C6.81327654,14.5714286 7.51313369,14.0656093 7.82570123,13.3402704 C7.69733742,13.3672083 7.56456145,13.3819354 7.42855938,13.3819354 L3.14284961,13.3819354 C2.03827562,13.3819354 0.999994729,13.0222904 0.999994729,11.8725911 L0.999994729,4.46063975 C0.999994729,4.15745142 0,4.6420269 0,5.65013293 L0,5.65013293 Z M2.00000527,3.86590929 L2.00000527,11.2778606 C2.00000527,12.4275277 2.60968988,12.6306426 3.71427969,12.6306426 L8.00000527,12.6306426 C8.81328181,12.6306426 9.51310733,12.2813534 9.82572231,11.5560306 C9.69734269,11.5829685 9.56456672,11.5976634 9.42858047,11.5976634 L5.14285488,11.5976634 C4.03824926,11.5976634 3,11.2380023 3,10.0883352 L3,2.67638385 C3,2.37314713 2.00000527,2.85775487 2.00000527,3.86590929 L2.00000527,3.86590929 Z" id="text-set-card_shape" />
      </g>
    </g>
  </svg>
);

class TextSetCard extends React.Component {
  renderFooter = () => {
    const articleCount = size(this.props.textSet.text_set_article_headers);
    return (
      <div className={styles.info}>
        <div className={styles.type}>
          <span>{textSetIcon} TEXT SET</span>
        </div>
        {
          (articleCount > 0) &&
          <div className={styles.articleCount}>
            {articleCount} ARTICLES
          </div>
        }
        <div style={{clear: 'both'}} />
      </div>
    );
  }

  renderImage = (src, alt) => {
    return (
      <LazyImage
        alt={alt}
        src={src}
      />
    );
  }

  renderThumbnails = () => {
    return (
      range(3).map((index) => {
        const src = get(this.props.textSet.thumbnail_images, index);
        return (
          <div
            className={styles.thumbnail}
            data-qa-selector="text_set_card_thumbnail_image"
            key={`thumb-${this.props.textSet.id}-${index}`}
          >
            {this.renderImage(src, this.props.textSet.title)}
          </div>
        );
      })
    );
  }

  renderViewTextSetButton = () => {
    return (
      <div className={styles.viewTextSetButtonWrapper}>
        <ButtonWithTrackEvent
          className={styles.viewTextSetButton}
          label="View Text Set"
          objectType="Content"
          actionName="Read"
          legacyEventName="TextSet-View"
          streamProperties={{content_type: 'TextSet'}}
        />
      </div>
    );
  }

  render() {
    const textSet = this.props.textSet;
    const eventProperties = {...this.props.eventProperties, ...{text_set_id: textSet.id}};
    return (
      <TrackedLink
        eventName={this.props.eventSource + '_text_set_card_click'}
        eventProperties={eventProperties}
        href={textSet.url}
        className={styles.itemContainer}
      >
        <div className={styles.item}>
          <div data-qa-selector="text_set_card_image">
            {this.renderImage(textSet.image, textSet.title)}
          </div>
          {this.renderThumbnails()}
          <div style={{clear: 'both'}} />
          <div className={styles.description}>
            {textSet.title}
            {!this.props.showViewTextSetButton && this.renderFooter()}
          </div>
          {this.props.showViewTextSetButton && this.renderViewTextSetButton()}
        </div>
      </TrackedLink>
    );
  }
}

TextSetCard.defaultProps = defaultProps;
TextSetCard.propTypes = propTypes;

export default TextSetCard;
