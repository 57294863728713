import React, {useState, useMemo} from 'react';

import {Button, Icon, QuestionFilledSVG, QuestionHollowSVG} from '@newsela/angelou';
import {useSelector} from 'react-redux';

import {
  NavButtonWithPopout
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/NavButtonWithPopout';
import {
  questionIconCss,
  supportCenterButtonCss,
  supportCenterVisibilityCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SupportCenter/SupportCenter.styles';
import {
  SupportCenterPopout
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SupportCenter/SupportCenterPopout';

import {getUser} from '../../../../../modules/selectors/userSelectors';
import {getNavMenuButtonAriaProps} from '../shared/NavButtonWithPopout/NavButtonWithPopout.utils';

const MENU_ID = 'nav-support-center';

const SupportCenter = () => {
  const user = useSelector((state) => getUser(state));

  const [isOpen, setIsOpen] = useState(false);

  const buttonAriaProps = useMemo(
    () => getNavMenuButtonAriaProps({
      isOpen,
      id: MENU_ID,
      ariaLabel: 'Support Center Menu'
    }), [isOpen]);

  if (!user?.is_authenticated) return null;

  const onClickButton = () => setIsOpen((isOpen) => !isOpen);

  return (
    <NavButtonWithPopout
      id={MENU_ID}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      position={{top: '3.5rem', right: '0rem'}}
      rootCustomCss={supportCenterVisibilityCss}
      ButtonComponent={(
        <Button
          onClick={onClickButton}
          ariaProps={buttonAriaProps}
          flavor={Button.flavors.incognito}
          __cssFor={supportCenterButtonCss}
          dataProps={{'data-qa-selector': 'global-nav-support-menu'}}
        >
          {isOpen
            ? <Icon SvgComponent={QuestionFilledSVG} __cssFor={questionIconCss} />
            : <Icon SvgComponent={QuestionHollowSVG} __cssFor={questionIconCss} />}
        </Button>
        )}
      PopoutComponent={<SupportCenterPopout />}
    />
  );
};

SupportCenter.displayName = 'SupportCenter';
export {SupportCenter};
