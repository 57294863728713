import {
  closeCssObject as close
} from '../Message';

const closeCssObject = {
  ...close,
  marginRight: close.marginLeft,
  marginLeft: '0px;',
};

const leftSideCssObject = {
  display: 'flex;',
  alignItems: 'center;',
};

// TODO we should really put this in the angelou button
// as an option
const removeButtonStyleObject = {
  background: 'none;',
  color: 'inherit;',
  border: 'none;',
  padding: '0;',
  font: 'inherit;',
  cursor: 'pointer;',
};

export {
  closeCssObject,
  leftSideCssObject,
  removeButtonStyleObject
};
