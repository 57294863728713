import {useEffect, useRef} from 'react';

/**
 * Like `useEffect` but only fires on updates (re-renders).
 */
const useUpdateEffect = (effect, dependencies) => {
  const isInitialRender = useRef(true);
  useEffect(() => {
    if (isInitialRender.current) return;
    return effect();
  }, dependencies);
  useEffect(() => {
    isInitialRender.current = false;
  }, []);
};

export default useUpdateEffect;
