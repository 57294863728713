import * as types from 'modulesV2/constants';

const initialState = {};

export default function lessonSpark(state = initialState, action) {
  const {type, data, error} = action;
  switch (type) {
    case types.GET_LESSON_SPARK_SUCCESS:
      return data.data;
    case types.GET_LESSON_SPARK_FAILURE:
      return error;
    default:
      return state;
  }
}
