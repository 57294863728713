import APIFetcher from '../APIFetcher';

class ConstructedResponseAnswerApi {
  static get(queryParams) {
    const params = APIFetcher.stringifyParams(queryParams);
    params.append('hide_classroom_id_for_revision', 'false');
    return APIFetcher.get(`constructedresponseanswer/?${params}`);
  }

  static post({
    articleId,
    collection_id,
    classroomId,
    dateCompleted,
    id,
    promptId,
    text,
  }) {
    const body = {
      article_id: articleId,
      collection_id: collection_id,
      classroom_id: classroomId,
      prompt_id: promptId,
      text: text,
    };
    if (id) {
      body.id = id;
    }
    if (dateCompleted) {
      body.date_completed = dateCompleted;
    }
    const answerUrl = id ? id + '/' : '';
    const url = `constructedresponseanswer/${answerUrl}`;
    return APIFetcher.post(url, {
      payload: body
    });
  }
}

export default ConstructedResponseAnswerApi;
