export const HEADER_FACETS = [
  'collection_ids',
  'content_maturities',
  'features',
  'grade_levels',
  'languages',
  'reading_skills',
  'standard_ids',
  'tags',
];
export const TEXT_SET_FACETS = [
  'collection_ids',
];
export const STANDARD_FACETS = [];
export const VIDEO_FACETS = [];

export const ARTICLE_CONTENT_SLUG = 'articles';
export const TEXT_SET_CONTENT_SLUG = 'text_sets';
export const STANDARD_CONTENT_SLUG = 'standards';
export const VIDEO_CONTENT_SLUG = 'video';

export const ARTICLE_SEARCH_OBJECT_TYPE = 'header';
export const TEXT_SET_SEARCH_OBJECT_TYPE = 'text_set';
export const STANDARD_SEARCH_OBJECT_TYPE = 'standard';
export const VIDEO_SEARCH_OBJECT_TYPE = 'video';

export const ARTICLE_SEARCH_TYPE = {
  slug: ARTICLE_CONTENT_SLUG,
  name: 'Articles',
  contentType: 'articleheader',
  objectType: ARTICLE_SEARCH_OBJECT_TYPE,
  facets: HEADER_FACETS,
};
export const TEXT_SET_SEARCH_TYPE = {
  slug: TEXT_SET_CONTENT_SLUG,
  name: 'Text Sets',
  contentType: 'textset',
  objectType: TEXT_SET_SEARCH_OBJECT_TYPE,
  facets: TEXT_SET_FACETS,
};
export const STANDARD_SEARCH_TYPE = {
  slug: STANDARD_CONTENT_SLUG,
  name: 'Standards',
  contentType: 'standard',
  objectType: STANDARD_SEARCH_OBJECT_TYPE,
  facets: STANDARD_FACETS,
};
export const VIDEO_SEARCH_TYPE = {
  slug: VIDEO_CONTENT_SLUG,
  name: 'Videos',
  contentType: 'video',
  objectType: VIDEO_SEARCH_OBJECT_TYPE,
  facets: VIDEO_FACETS,
};

export const SEARCH_TYPES = [
  ARTICLE_SEARCH_TYPE,
  TEXT_SET_SEARCH_TYPE,
  STANDARD_SEARCH_TYPE,
  VIDEO_SEARCH_TYPE,
];

export const SEARCH_TYPES_BY_SLUG = new Map(SEARCH_TYPES.map((searchType) => [searchType.slug, searchType]));
