import initialState from './initialState';
import {studentTabActionTypes as types} from '../constants';

function studentReadingLevels(state = initialState.binderStudents.studentReadingLevels, action) {
  switch (action.type) {
    case types.STUDENT_READING_LEVELS_LOAD_REQUEST:
      return {...state, isError: false, isLoading: true};

    case types.STUDENT_READING_LEVELS_LOAD_SUCCESS:
      return {
        ...state,
        isError: false,
        isLoading: false,
        item: action.payload.data,
        loadedItems: {
          ...state.loadedItems,
          [action.payload.classroomId]: action.payload.data
        }
      };

    case types.STUDENT_READING_LEVELS_LOAD_FAILURE:
      return {...state, isError: true, isLoading: false, error: action.error};

    default:
      return state;
  }
}

export default studentReadingLevels;
