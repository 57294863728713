import {takeLatest, takeEvery, call, put} from 'redux-saga/effects';

import globalToasterRef from 'constants/globalToasterRef';
import {
  LIKE_SUCCESS_TOAST_PROPS,
  UNLIKE_SUCCESS_TOAST_PROPS,
  LIKE_ERROR_TOAST_PROPS,
  UNLIKE_ERROR_TOAST_PROPS,
} from 'constants/toastProps';
import {
  getLikedContentSuccess,
  getLikedContentError,
  createLikedContentRequestSuccess,
  createLikedContentRequestError,
  deleteLikedContentRequestSuccess,
  deleteLikedContentRequestError,
} from 'modulesV2/actions';
import * as types from 'modulesV2/constants';
import {ContentLikeApi} from 'static/three-oh/src/modules/apis';


/**
 * Fetch list of users liked articles and save it in the store
 * @returns {Generator}
 */
function * getLikedContent() {
  try {
    const response = yield call(ContentLikeApi.get);
    yield put(getLikedContentSuccess(response));
  } catch (error) {
    yield put(getLikedContentError(error));
  }
}

function * createNewLikedContent(action) {
  try {
    const data = yield call(ContentLikeApi.save, action.contentId);
    yield put(createLikedContentRequestSuccess(data));
    globalToasterRef.current.displayToast(LIKE_SUCCESS_TOAST_PROPS);
  } catch (error) {
    yield put(createLikedContentRequestError(error));
    globalToasterRef.current.displayToast(LIKE_ERROR_TOAST_PROPS);
  }
}

function * deleteLikedContent(action) {
  try {
    yield call(ContentLikeApi.delete, action.likeId);
    yield put(deleteLikedContentRequestSuccess(action.likeId));
    globalToasterRef.current.displayToast(UNLIKE_SUCCESS_TOAST_PROPS);
  } catch (error) {
    yield put(deleteLikedContentRequestError(error));
    globalToasterRef.current.displayToast(UNLIKE_ERROR_TOAST_PROPS);
  }
}

export function * watchCreateLikedContent() {
  yield takeEvery(types.CREATE_LIKED_CONTENT_REQUEST, createNewLikedContent);
}

export function * watchDeleteLikedContent() {
  yield takeEvery(types.DELETE_LIKED_CONTENT_REQUEST, deleteLikedContent);
}

export function * watchGetLikedContent() {
  yield takeLatest(types.GET_LIKED_CONTENT_REQUEST, getLikedContent);
}
