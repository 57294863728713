import {takeEvery, call, put} from 'redux-saga/effects';

import {StudiesApi} from 'static/three-oh/src/modules/apis';

import * as actions from '../actions/studiesActions';
import * as types from '../constants/studiesActionTypes';


// Keep track of which requests have already launched so that we don't
// repeat them.
let hasBeenRequested;

function * getStudies() {
  if (!hasBeenRequested) {
    try {
      hasBeenRequested = true;
      const data = yield call(StudiesApi.get);
      yield put(actions.requestStudiesSuccess(data));
    } catch (error) {
      yield put(actions.requestStudiesFailure(error));
    }
  }
}

export function * watchGetStudies() {
  yield takeEvery(types.STUDIES_REQUEST, getStudies);
}
