import {parseInt} from 'lodash-es';
import {takeLatest, call, fork, put, all} from 'redux-saga/effects';

import {
  AnnotationApi,
  ConstructedResponseAnswerApi,
  ReadingSessionApi,
  StudentQuizApi,
} from 'static/three-oh/src/modules/apis';
import ApiFetcher3 from 'static/three-oh/src/modules/apis/ApiFetcher3';
import {startOfLastSchoolYear} from 'static/three-oh/src/modules/utils/dateHelpers';

import {studentBinderActions as actions} from '../actions';
import {studentBinderActionTypes} from '../constants';


function * getStudentQuizzes(studentId, lastSchoolYearStartDate) {
  const parameters = {
    student_id: studentId,
    date_start: lastSchoolYearStartDate,
  };
  return yield call(StudentQuizApi.get, parameters);
}

function * getStudentWriteResponses(studentId, lastSchoolYearStartDate) {
  const parameters = {
    student_id: studentId,
    modified_since: lastSchoolYearStartDate,
  };
  return yield call(ConstructedResponseAnswerApi.get, parameters);
}

/**
 *
 * @param {*} assignmentData
 * @returns assignments where assignment_members only contain articlheader content
 */

export const sanitizeAssignmentData = (assignmentData) => {
  const sanitizedAssignmentMembers = assignmentData.map((assignment) => {
    return assignment.assignment_members.filter((member) => member.content.content_type === 'articleheader');
  });
  const newData = assignmentData.map((assignment, idx) => {
    return assignment = {
      ...assignment,
      assignment_members: sanitizedAssignmentMembers[idx]
    };
  });
  return newData;
};

function * getAssignments(lastSchoolYearStartDate) {
  const parameters = {
    date_start: lastSchoolYearStartDate,
  };

  const studentAssignments = yield call(async() => {
    const response = await ApiFetcher3.get('assignment/', parameters);
    const {data} = response;
    const sanitizedData = sanitizeAssignmentData(data);
    return sanitizedData;
  });

  return studentAssignments;
}

function * getReadingSessions(userId, lastSchoolYearStartDate) {
  const parameters = {
    user_id: userId,
    date_start: lastSchoolYearStartDate,
  };
  return yield call(ReadingSessionApi.get, parameters);
}

function * getAnnotations(userId, lastSchoolYearStartDate) {
  const parameters = {
    user_id: userId,
    modified_since: lastSchoolYearStartDate,
  };
  return yield call(AnnotationApi.get, parameters);
}

function * getStudentBinderData(userId, studentId) {
  try {
    // only requesting quizzes, write responses, and assignments within this or last school year
    const lastSchoolYearStartDate = startOfLastSchoolYear().toISOString();
    yield put(actions.getStudentBinderDataRequest());
    const [annotations, readingSessions, studentQuizzes, studentWriteResponses, studentAssignments] = yield all([
      call(getAnnotations, userId, lastSchoolYearStartDate),
      call(getReadingSessions, userId, lastSchoolYearStartDate),
      call(getStudentQuizzes, studentId, lastSchoolYearStartDate),
      call(getStudentWriteResponses, studentId, lastSchoolYearStartDate),
      call(getAssignments, lastSchoolYearStartDate)
    ]);

    const payload = {
      studentId: studentId,
      studentQuizzes: studentQuizzes,
      writeResponses: studentWriteResponses,
      annotations: annotations,
      studentAssignments: studentAssignments,
      readingSessions: readingSessions,
    };
    yield put(actions.getStudentBinderDataSuccess(payload));
  } catch (error) {
    yield put(actions.getStudentBinderDataFailure(error));
  }
}

export function * watchInitStudentBinder() {
  yield takeLatest(studentBinderActionTypes.INITIALIZE_STUDENT_BINDER, function * init(action) {
    const userId = parseInt(action.payload.userId);
    const studentId = parseInt(action.payload.studentId);
    const classroomIds = action.payload.classroomIds;
    yield fork(getStudentBinderData, userId, studentId, classroomIds);
  });
}
