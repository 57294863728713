import React from 'react';

import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';

const TextSetsRoute = (
  <Route
    key="TextSets"
    path="/text-sets/:textSetId"
    component={
      Loadable({
        loader: () => import(
          /* webpackChunkName: "TextSets" */
          './Container'
        ),
        loading: () => null
      })
    }
  />
);

export default TextSetsRoute;
