import React from 'react';

import {LoadingSpinner} from '@newsela/angelou';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';

import {socialAuthErrorRoot} from 'static/three-oh/src/modules/constants/routePaths';

const LazySocialAuthContainer = Loadable({
  loader: () => import('./containers/SocialAuthErrorContainer'),
  loading: LoadingSpinner,
});

const SocialAuthErrorRoute = [
  <Route
    key="SocialAuthErrorCatchAll"
    path={socialAuthErrorRoot}
    component={LazySocialAuthContainer}
  />,
];

export default SocialAuthErrorRoute;
