import {constants, typographyStyles} from '@newsela/angelou';
import {css} from 'emotion';

import {
  NAVBAR_BREAKPOINTS
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';

export const sectionContainerCss = {
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  padding: '0 0.5rem',
};

export const mobileFullWidthCss = {
  [`@media (max-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
    width: '100%',
  },
};

export const sectionTitleCss = css({
  ...typographyStyles.fontSize.textM,
  ...typographyStyles.fontFamily.circular,
  fontSize: '1rem',
  color: constants.colors.ui.neutral[700],
  padding: 0,
  fontWeight: 700,
  margin: '0 0 0 0.5rem',
});

export const unorderedListCss = css({
  padding: 0,
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  listStyleType: 'none',
});

export const iconAndTitleCss = css({
  display: 'flex',
  gap: '0.5rem',
  flexDirection: 'row',
  alignItems: 'flex-start',
});

export const badgeCss = {
  root: {
    height: '20px !important',
  }
};

export const anchorCss = {
  root: {
    textDecoration: 'none',
    display: 'flex',
    margin: 0,
    padding: '0.25rem 0.5rem',
    width: '100%',
    borderRadius: '0.5rem',
    lineHeight: '1.5rem',
    color: constants.colors.ui.neutral[700],

    ':hover': {
      background: '#F1F7FF',
    },

    '> div': {
      display: 'flex',
      gap: '0.5rem',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }
};
