import APIFetcher from '../APIFetcher';

class DistrictAdminApi {
  static createDistrictAdmin(payload) {
    return APIFetcher.post('district-admin/', {payload});
  }

  static persistDistrictAdmin(payload) {
    const url = `district-admin/${payload.id + '/' || ''}`;
    return APIFetcher.post(url, {payload});
  }
}

export default DistrictAdminApi;
