import React from 'react';

import {get, isFunction, toLower} from 'lodash-es';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {eventStream, getEventNameForEventStream} from 'static/three-oh/utils/sessionActivity';

import TrackedDiv from '../components/TrackedDiv';
import TrackedLink from '../components/TrackedLink';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  eventName: PropTypes.string.isRequired,
  eventProperties: PropTypes.object,
  href: PropTypes.string,
  noRedirect: PropTypes.bool,
  postTrackClickFunction: PropTypes.func,
  target: PropTypes.string,
  type: PropTypes.string,
  user: PropTypes.object,
  to: PropTypes.string,
};

const defaultProps = {
  eventProperties: {},
  href: '',
  user: {},
};

class Container extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  /**
   * Handle the Click of element.
   */
  handleClick(e) {
    // Track a click with the correct event name with details.
    const userId = get(this.props, 'user.id', null);
    if (!userId) {
      return;
    }

    const newEventProps = {
      event_name: getEventNameForEventStream('Content', 'Initiate', 'Navigate'),
      user_id: get(this.props, 'user.id'),
      dimension_properties: {
        description: 'Clicked a tracked link',
        legacy_event_name: this.props.eventName
      }
    };

    eventStream(newEventProps, {userId, name: this.props.eventName, type: 'click', properties: this.props.eventProperties});

    // If there's a function to call after the click tracking, do so now.
    if (isFunction(this.props.postTrackClickFunction)) {
      this.props.postTrackClickFunction(e);
    }

    if (this.props.noRedirect) {
      e.preventDefault();
      return;
    }

    // Manually perform navigation if the TrackedLink is a <div> and not an actual link.
    if (toLower(this.props.type) === 'div') {
      if (this.props.target) {
        window.open(this.props.href, this.props.target);
      } else {
        window.location.href = this.props.href;
      }
      e.preventDefault();
      return false;
    }

    // Otherwise, propigate the event, so any wrapped <a> or <Link> will perform their default navigation behavior.
    return true;
  }

  render() {
    let element = null;
    if (this.props.type == 'div') {
      element = (
        <TrackedDiv
          className={this.props.className}
          href={this.props.href}
          onClick={this.handleClick}
          {...this.props}
        >
          {this.props.children}
        </TrackedDiv>
      );
    } else {
      element = (
        <TrackedLink
          className={this.props.className}
          href={this.props.href}
          onClick={this.handleClick}
          {...this.props}
        >
          {this.props.children}
        </TrackedLink>
      );
    }
    return element;
  }
}

Container.defaultProps = defaultProps;
Container.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const TrackedLinkContainer = connect(mapStateToProps)(Container);
export default TrackedLinkContainer;
