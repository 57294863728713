import {takeLatest, put, call} from 'redux-saga/effects';

import {
  requestClassrooms,
  unloadClassrooms,
} from 'static/three-oh/src/modules/actions/classroomActions';
import UserApi from 'static/three-oh/src/modules/apis/User/UserApi';

import {storeBinderImpersonatedUser} from '../actions/binderActions';
import {
  REQUEST_INITIAL_BINDER_DATA,
  UNLOAD_BINDER_DATA,
} from '../constants/binderActionTypes';


function * watchRequestInitialBinderData() {
  yield takeLatest(REQUEST_INITIAL_BINDER_DATA, function * (action) {
    const {currentUserId, impersonatedUserId} = action;

    if (impersonatedUserId && impersonatedUserId !== currentUserId) {
      try {
        const impersonatedUser = yield call(UserApi.getById, impersonatedUserId);
        yield put(storeBinderImpersonatedUser(impersonatedUser));
        yield put(requestClassrooms(impersonatedUserId));
      } catch (error) {
        yield put(storeBinderImpersonatedUser({isError: true}));
      }
    } else {
      yield put(requestClassrooms(currentUserId));
    }
  });
}

function * watchUnloadBinderData() {
  yield takeLatest(UNLOAD_BINDER_DATA, function * () {
    yield put(storeBinderImpersonatedUser(null));
    yield put(unloadClassrooms());
  });
}

export {watchRequestInitialBinderData, watchUnloadBinderData};
