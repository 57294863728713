exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__color-quiz-correct___cqmSo{background-color:#52A821}.styles__color-quiz-incorrect___a9k4q{background-color:#D93F31}.styles__color-science___-7q-d{background-color:#55bca6}.styles__color-kids___4xBcu{background-color:#5908f7}.styles__color-money___Huq3X{background-color:#fa0045}.styles__color-law___MCkFO{background-color:#002764}.styles__color-health___AGb6R{background-color:#03a43c}.styles__color-arts___Fs4-4{background-color:#E2C925}.styles__color-war-and-peace___PHCKX{background-color:#E76A0E}.styles__color-sports___WUFBP{background-color:#000000}.styles__color-opinion___EtLB9{background-color:#0027f5}.styles__color-text-set___BIPeE{background-color:#106FF3}.styles__color-object-gray___AqSIR{background-color:#878787}.styles__color-light-gray___J8Ffd{background-color:#F8F8F8}.styles__color-middle-gray___dfSY0{background-color:#BCBCBE}.styles__color-newsela-black___U7oIE{background-color:#333333}.styles__color-dark-bg___QtNYS{background-color:#031E3E}.styles__color-newsela-blue___evavD{background-color:#0768fd}.styles__description___VWS2N{clear:left;padding-right:24px;font-size:.875rem;line-height:1.33rem}.styles__description___VWS2N .styles__descriptionText___OgWZM h1,.styles__description___VWS2N .styles__descriptionText___OgWZM h2,.styles__description___VWS2N .styles__descriptionText___OgWZM h3,.styles__description___VWS2N .styles__descriptionText___OgWZM h4{font-weight:bold;font-size:1rem}.styles__description___VWS2N .styles__descriptionText___OgWZM a{color:#0768fd;text-decoration:underline}.styles__description___VWS2N .styles__descriptionText___OgWZM a:visited{color:#609}.styles__description___VWS2N .styles__descriptionToggle___StNXX{margin-top:20px;padding-bottom:12px;color:#0768fd;font-weight:bold;font-size:.875rem;cursor:pointer}button{padding:0;border:none;font:inherit;color:inherit;background-color:rgba(0,0,0,0);cursor:pointer}", ""]);

// exports
exports.locals = {
	"color-quiz-correct": "styles__color-quiz-correct___cqmSo",
	"color-quiz-incorrect": "styles__color-quiz-incorrect___a9k4q",
	"color-science": "styles__color-science___-7q-d",
	"color-kids": "styles__color-kids___4xBcu",
	"color-money": "styles__color-money___Huq3X",
	"color-law": "styles__color-law___MCkFO",
	"color-health": "styles__color-health___AGb6R",
	"color-arts": "styles__color-arts___Fs4-4",
	"color-war-and-peace": "styles__color-war-and-peace___PHCKX",
	"color-sports": "styles__color-sports___WUFBP",
	"color-opinion": "styles__color-opinion___EtLB9",
	"color-text-set": "styles__color-text-set___BIPeE",
	"color-object-gray": "styles__color-object-gray___AqSIR",
	"color-light-gray": "styles__color-light-gray___J8Ffd",
	"color-middle-gray": "styles__color-middle-gray___dfSY0",
	"color-newsela-black": "styles__color-newsela-black___U7oIE",
	"color-dark-bg": "styles__color-dark-bg___QtNYS",
	"color-newsela-blue": "styles__color-newsela-blue___evavD",
	"description": "styles__description___VWS2N",
	"descriptionText": "styles__descriptionText___OgWZM",
	"descriptionToggle": "styles__descriptionToggle___StNXX"
};