import {classroomActionTypes as types} from './../constants';

/*
Classroom Loading Actions
 */

export function requestClassrooms(userId, params = {}) {
  return {
    type: types.CLASSROOMS_LOAD_REQUEST,
    userId,
    params
  };
}

export function requestClassroom(classroomId) {
  return {
    type: types.CLASSROOM_LOAD_REQUEST,
    classroomId,
  };
}

export function loadClassroomsSuccess(data) {
  return {
    type: types.CLASSROOMS_LOAD_SUCCESS,
    classrooms: data,
    receivedAt: Date.now()
  };
}

export function loadClassroomsFailure(error) {
  return {
    type: types.CLASSROOMS_LOAD_FAILURE,
    error,
    classrooms: [],
    receivedAt: Date.now()
  };
}

export function unloadClassrooms() {
  return {
    type: types.CLASSROOMS_UNLOAD,
  };
}

export function startClassroomPolling(userId, params) {
  return {
    type: types.CLASSROOM_POLLING_START,
    userId,
    params,
  };
}

/*
Classroom Creation Actions
 */

export function createClassroom(payload) {
  return {type: types.CLASSROOM_POST_REQUEST, payload};
}

export function createClassroomSuccess(payload) {
  return {type: types.CLASSROOM_POST_SUCCESS, payload};
}

export function createClassroomFailure(error) {
  return {type: types.CLASSROOM_POST_FAILURE, error};
}


/*
Classroom get from code Actions
 */
export function joinClassroom(studentID, classroomCode) {
  return {type: types.CLASSROOM_JOIN_REQUEST, studentID, classroomCode};
}

export function joinClassroomSuccess(payload) {
  return {type: types.CLASSROOM_JOIN_SUCCESS, payload};
}

export function joinClassroomFailure(error) {
  return {type: types.CLASSROOM_JOIN_FAILURE, error};
}
