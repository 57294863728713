import {
  forEach,
  castArray,
  omit,
  map,
  findIndex,
  isEqual,
  slice,
  filter
} from 'lodash-es';

/*
 *************************************************
 *  ACTION TYPES
 *************************************************
 */

export const FETCH_RECORD = 'newsela-object-api/FETCH_RECORD';
export const FETCH_RECORD_SUCCESS = 'newsela-object-api/FETCH_RECORD_SUCCESS';
export const FETCH_RECORD_ERROR = 'newsela-object-api/FETCH_RECORD_ERROR';
export const FETCH_COLLECTION = 'newsela-object-api/FETCH_COLLECTION';
export const FETCH_COLLECTION_SUCCESS = 'newsela-object-api/FETCH_COLLECTION_SUCCESS';
export const FETCH_COLLECTION_ERROR = 'newsela-object-api/FETCH_COLLECTION_ERROR';
export const CREATE = 'newsela-object-api/CREATE';
export const CREATE_SUCCESS = 'newsela-object-api/CREATE_SUCCESS';
export const CREATE_ERROR = 'newsela-object-api/CREATE_ERROR';
export const UPDATE = 'newsela-object-api/UPDATE';
export const UPDATE_SUCCESS = 'newsela-object-api/UPDATE_SUCCESS';
export const UPDATE_ERROR = 'newsela-object-api/UPDATE_ERROR';
export const DELETE = 'newsela-object-api/DELETE';
export const DELETE_SUCCESS = 'newsela-object-api/DELETE_SUCCESS';
export const DELETE_ERROR = 'newsela-object-api/DELETE_ERROR';

/*
 *************************************************
 *  ACTION CREATORS
 *************************************************
 */

export const fetchRecord = (model, id, path, params = {}) => {
  return {
    type: FETCH_RECORD,
    meta: {
      success: FETCH_RECORD_SUCCESS,
      failure: FETCH_RECORD_ERROR,
      model,
      id,
    },
    payload: {
      method: 'get',
      path,
      params,
    }
  };
};

export const fetchCollection = (model, path, params = {}) => {
  return {
    type: FETCH_COLLECTION,
    meta: {
      success: FETCH_COLLECTION_SUCCESS,
      failure: FETCH_COLLECTION_ERROR,
      model,
    },
    payload: {
      method: 'get',
      path,
      params,
    }
  };
};

export const createRecord = (model, path, data, params = {}) => {
  return {
    type: CREATE,
    meta: {
      success: CREATE_SUCCESS,
      failure: CREATE_ERROR,
      model,
    },
    payload: {
      method: 'post',
      path,
      data,
      params,
    }
  };
};

export const updateRecord = (model, id, path, data, params = {}) => {
  return {
    type: UPDATE,
    meta: {
      success: UPDATE_SUCCESS,
      failure: UPDATE_ERROR,
      model,
      id,
    },
    payload: {
      method: 'post',
      path,
      data,
      params,
    }
  };
};

export const deleteRecord = (model, id, path, data, params = {}) => {
  return {
    type: DELETE,
    meta: {
      success: DELETE_SUCCESS,
      failure: DELETE_ERROR,
      model,
      id,
    },
    payload: {
      method: 'delete',
      path,
      data,
      params,
    }
  };
};

/*
 *************************************************
 *  ACTION CREATORS CREATOR
 *************************************************
 */

export const createCrudActions = (model, path) => ({
  fetchRecord: (id, params) =>
    fetchRecord(model, id, `${path}/${id}`, params),

  fetchCollection: (params = {}) =>
    fetchCollection(model, path, params),

  createRecord: (data = {}) =>
    createRecord(model, path, data),

  updateRecord: (id, data = {}) =>
    updateRecord(model, id, `${path}/${id}`, data),

  deleteRecord: (id) =>
    deleteRecord(model, id, `${path}/${id}`)
});

/*
 *************************************************
 *  INITIAL STATE
 *************************************************
 */

const byIdInitialState = {};

const collectionInitialState = {
  params: {},
  ids: null,
  fetchTime: null,
  error: null
};

const collectionsInitialState = [];

const modelInitialState = {
  byId: byIdInitialState,
  collections: collectionsInitialState,
};

// holds a number of models, each of which are structured like modelInitialState
const crudInitialState = {};


/*
 *************************************************
 *  REDUCERS
 *************************************************
 */

/**
 *
 * There are two ways the crud reducer stores data for models:
 *
 * byId:
 *    Takes all models and creates a dictionary from them using their id as the key.
 *
 * collections:
 *    Deals with groups of models, such as a list classrooms returned via a search.
 *
 * The reducers provide metadata about any requests which is necessary in order to communicate
 * to the user what is happening to data, such as whether it is being fetched, or whether there was
 * an API error while fetching the model(s).
 *
 * Note: fetchTime of null means "needs fetch", fetchTime of zero (epoch) means "loading".
 *
 * The following is a example of the state created by the crudReducer:
 *
 *   state.crud: {
 *     assignment: {
 *       collections: [
 *         {
 *           params: {header_id: 8173},
 *           ids: [1200, 1201, ...],
 *           fetchTime: 1507831240390,
 *           error: null
 *         },
 *         {
 *           params: {classroom_ids: [49, 50]},
 *           ids: [1201, 1202, ...],
 *           fetchTime: 1507831240390,
 *           error: {statusCode: 500, message: 'Internal Server error'}
 *         }
 *       ],
 *       byId: {
 *         1200: {
 *           fetchTime: 1507831240390,
 *           error: {statusCode: 403, message: 'Forbidden'},
 *           record: {id: 1200, ... }
 *         },
 *         1201: {
 *           fetchTime: 1507831240390,
 *           error: null,
 *           record: {id: 1201, ...}
 *         }
 *       },
 *     },
 *     anotherModel: {
 *       // same layout as assignment...
 *       collections: [...],
 *       byId: {...}
 *     }
 *   }
 *
 */

export function byIdReducer(state = byIdInitialState, action) {
  switch (action.type) {
    case FETCH_RECORD: {
      // Indicate that a fetch has started for the record.
      return {
        ...state,
        [action.meta.id]: {
          fetchTime: 0,
          error: null,
          record: null
        }
      };
    }

    case FETCH_RECORD_SUCCESS: {
      // Store the returned record.
      return {
        ...state,
        [action.meta.id]: {
          fetchTime: action.meta.fetchTime,
          error: null,
          record: action.payload
        }
      };
    }

    case FETCH_RECORD_ERROR: {
      // Store the returned error.
      return {
        ...state,
        [action.meta.id]: {
          fetchTime: action.meta.fetchTime,
          error: action.payload,
          record: null
        }
      };
    }

    case FETCH_COLLECTION_SUCCESS: {
      // Store each of the records in the collection.
      const data = {};
      forEach(castArray(action.payload), (record) => {
        data[record.id] = {
          fetchTime: action.meta.fetchTime,
          error: null,
          record
        };
      });
      return {...state, ...data};
    }

    case CREATE_SUCCESS: {
      // Store the created record.
      return {
        ...state,
        [action.payload.id]: {
          fetchTime: action.meta.fetchTime,
          error: null,
          record: action.payload
        }
      };
    }

    case UPDATE: {
      // Indicate that a fetch has started, but preserve any existing record.
      return {
        ...state,
        [action.meta.id]: {
          fetchTime: 0,
          error: state[action.meta.id].error,
          record: state[action.meta.id].record
        }
      };
    }

    case UPDATE_SUCCESS: {
      // Blow over the existing record.
      return {
        ...state,
        [action.meta.id]: {
          fetchTime: action.meta.fetchTime,
          error: null,
          record: action.payload
        }
      };
    }

    case UPDATE_ERROR: {
      // Flag an error on the record.
      return {
        ...state,
        [action.meta.id]: {
          fetchTime: action.meta.fetchTime,
          error: action.payload,
          record: state[action.meta.id].record
        }
      };
    }

    case DELETE_SUCCESS: {
      // Remove the record.
      return omit(state, action.meta.id);
    }

    default: {
      return state;
    }
  }
}

export function collectionReducer(state = collectionInitialState, action) {
  switch (action.type) {
    case FETCH_COLLECTION: {
      // Indicate that a fetch has started for the collection.
      return {
        ...state,
        params: action.payload.params,
        fetchTime: 0,
        error: null
      };
    }
    case FETCH_COLLECTION_SUCCESS: {
      // Store only the ids associated with the collection, the byIdReducer handles the records.
      const ids = map(castArray(action.payload), 'id');
      return {
        ...state,
        params: action.meta.params,
        ids,
        error: null,
        fetchTime: action.meta.fetchTime
      };
    }
    case FETCH_COLLECTION_ERROR: {
      // Store the returned error.
      return {
        ...state,
        params: action.meta.params,
        error: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

export function collectionsReducer(state = collectionsInitialState, action) {
  switch (action.type) {
    case FETCH_COLLECTION:
    case FETCH_COLLECTION_SUCCESS:
    case FETCH_COLLECTION_ERROR: {
      const params = (action.type === FETCH_COLLECTION ? action.payload.params
        : action.meta.params);

      // The collection may or may not already be on the state. Collections are uniquely identified
      // by their fetch params. Look for a collection with matching params.
      const index = findIndex(state, (collection) => isEqual(collection.params, params));

      if (index === -1) {
        // A collection matching the given params didn't exist, create it at the end of the array.
        return [...state, collectionReducer(undefined, action)];
      }

      // An existing collection was found, update it in place.
      const before = slice(state, 0, index);
      const after = slice(state, index + 1);
      return [...before, collectionReducer(state[index], action), ...after];
    }

    case DELETE_SUCCESS: {
      return filter(state, (collection) => collection.id !== action.meta.id);
    }

    case CREATE_SUCCESS: {
      // The creation of a model invalidates any previously fetched collections for said
      // model. Set the fetchTime on the collections to null to indicate it requires re-fetching.
      return map(state, (collection) => ({...collection, fetchTime: null}));
    }

    default: {
      return state;
    }
  }
}

export function modelReducer(state = modelInitialState, action) {
  switch (action.type) {
    case FETCH_RECORD:
    case FETCH_RECORD_SUCCESS:
    case FETCH_RECORD_ERROR: {
      return {
        ...state,
        byId: byIdReducer(state.byId, action)
      };
    }
    case FETCH_COLLECTION:
    case FETCH_COLLECTION_SUCCESS:
    case FETCH_COLLECTION_ERROR: {
      return {
        ...state,
        byId: byIdReducer(state.byId, action),
        collections: collectionsReducer(state.collections, action)
      };
    }
    case CREATE_SUCCESS: {
      return {
        ...state,
        byId: byIdReducer(state.byId, action),
        collections: collectionsReducer(state.collections, action)
      };
    }
    case UPDATE:
    case UPDATE_SUCCESS:
    case UPDATE_ERROR: {
      return {
        ...state,
        byId: byIdReducer(state.byId, action)
      };
    }
    case DELETE:
    case DELETE_SUCCESS:
    case DELETE_ERROR: {
      return {
        ...state,
        byId: byIdReducer(state.byId, action),
        collections: collectionsReducer(state.collections, action)
      };
    }
    default: {
      return state;
    }
  }
}

export default function crudReducer(state = crudInitialState, action) {
  switch (action.type) {
    case FETCH_RECORD:
    case FETCH_RECORD_SUCCESS:
    case FETCH_RECORD_ERROR:
    case FETCH_COLLECTION:
    case FETCH_COLLECTION_SUCCESS:
    case FETCH_COLLECTION_ERROR:
    case CREATE:
    case CREATE_SUCCESS:
    case CREATE_ERROR:
    case UPDATE:
    case UPDATE_SUCCESS:
    case UPDATE_ERROR:
    case DELETE:
    case DELETE_SUCCESS:
    case DELETE_ERROR: {
      const {model} = action.meta;
      return {
        ...state,
        [model]: modelReducer(state[model], action)
      };
    }
    default: {
      return state;
    }
  }
}
