import {css} from 'emotion';

import uiColors from 'components/Colors/colors';

const tipText = `
    line-height: 22px;
    display: inline;
  `;

const getBubbleColor = (isDark) => {
  return isDark ? {
    backgroundColor: uiColors.grey_dark[1],
    color: uiColors.white[0],
    border: 'none',
  }
    : {
        backgroundColor: uiColors.white[0],
        color: uiColors.grey_dark[1],
        border: `solid 1px ${uiColors.grey_light[1]}`
      };
};

export const bubbleStyle = (isDark) => {
  const bubbleColor = getBubbleColor(isDark);
  return css`
    background-color: ${bubbleColor.backgroundColor};
    color: ${bubbleColor.color};
    border: ${bubbleColor.border};
    max-width: 300px;
    min-height: 24px;
    padding: 8px;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.24);
    z-index: 1;
    font-family: Circular, "Segoe UI", Helvetica, Arial, sans-serif;
    font-size: 15px;
    letter-spacing: 0;
    pointer-events : none;
    display: table;
`;
};

const tipBubbleDark = `
  background-color: ${uiColors.grey_dark[1]};
  color: ${uiColors.white[0]};
  :before {
    background-color: ${uiColors.grey_dark[1]};
  }
`;

const tipBubbleLight = `
  background-color: ${uiColors.white[0]};
  color: ${uiColors.grey_dark[1]};
  border: solid 1px ${uiColors.grey_light[1]};
  :before {
    background-color: ${uiColors.white[0]};
  }
`;

const tipPositionLeft = `
  &[data-placement*='bottom'], &[data-placement*='top'] {
    &::before {
      left: calc(10%);
    }
  }
`;

const tipPositionRight = `
  &[data-placement*='bottom'], &[data-placement*='top'] {
    &::before {
      left: calc(95% - 27px);
    }
  }
`;

const tipPositionAbove = `
  &[data-placement*='right'], &[data-placement*='left'] {
    &::before {
      top: calc(15%) !important;
    }
  }
`;

const tipPositionBelow = `
  &[data-placement*='right'], &[data-placement*='left'] {
    &::before {
      top: calc(95% - 27px) !important;
    }
  }
`;

export const arrowStyle = (isDark) => {
  const tipColor = getBubbleColor(isDark);

  return css`
  &[data-placement*='bottom'] {
    width: 18px;
    height: 0px;
    &::before {
      border-width: 9px;
      border-color: ${tipColor.backgroundColor} transparent transparent  ${tipColor.backgroundColor};
      top: -8px;
      box-shadow: -5px -3px 5px -6px #333, -3px -5px 5px -6px #333;
    }
  }
  &[data-placement*='top'] {
    width: 18px;
    height: 0px;
    &::before {
      border-width: 9px;
      bottom: -8px;
      border-color: transparent ${tipColor.backgroundColor} ${tipColor.backgroundColor} transparent;
      box-shadow: 5px 3px 5px -6px #333, 3px 5px 5px -6px #333;
    }
  }
  &[data-placement*='right'] {
    &::before {
      top: calc(50% - 12px);
      left: -6px;
      border-width: 9px;
      border-color: transparent transparent ${tipColor.backgroundColor} ${tipColor.backgroundColor};
      box-shadow: -5px 6px 8px -5px #333;
    }
  }
  &[data-placement*='left'] {
    &::before {
      top: calc(50% - 12px);
      left: calc(100% - 16px);
      border-width: 9px;
      border-color: ${tipColor.backgroundColor} ${tipColor.backgroundColor} transparent transparent;
      box-shadow: 5px -6px 8px -5px #333;
    }
  }
  &::before {
    content: '';
    margin: auto;
    display: block;
    width: 6px;
    height: 6px;
    border-style: solid;
    border-radius: 2px;
    transform: rotate(45deg);
    left: calc(50% - 12px);
    position: absolute;
  }
  `;
};

export const enter = css`
  opacity: 0.01;
`;

const transition = 'opacity 200ms linear';
export const enterActive = css`
  opacity: 1;
  transition: ${transition};
`;

export const exit = css({
  opacity: 1,
});

export const exitActive = css({
  opacity: 0,
  transition,
});


export const cornerStyle = (hasRoundedCorners) => {
  if (hasRoundedCorners) {
    return css`
      border-radius: 10px;
    `;
  }
};

export const left = css(tipPositionLeft);
export const right = css(tipPositionRight);
export const above = css(tipPositionAbove);
export const below = css(tipPositionBelow);
export const textStyle = css(tipText);
export const light = css(tipBubbleLight);
export const dark = css(tipBubbleDark);
