import {get, has, keyBy, omit} from 'lodash-es';
import {takeLatest, call, put, select, take} from 'redux-saga/effects';

import {
  articleHeaderActions,
  articleHeaderClassroomActions,
  articleLikeActions,
  assignmentActions,
  classroomActions,
  contentHideActions,
  genericModelActions,
  notificationsActions,
  studentQuizActions,
  studiesActions,
} from 'static/three-oh/src/modules/actions';
import * as searchActions from 'static/three-oh/src/modules/actions/searchActions';
import * as textSetActions from 'static/three-oh/src/modules/actions/textSetActions';
import {
  ConstructedResponseAnswerApi,
  ConstructedResponseReviewApi,
  StudentQuizApi,
  StudentWorkReviewRecordApi,
} from 'static/three-oh/src/modules/apis';
import {ARTICLE_HEADER_LOAD_SUCCESS} from 'static/three-oh/src/modules/constants/articleHeaderActionTypes';
import {fetchConstructedResponseAnswers} from 'static/three-oh/src/modules/ducks/constructedResponseAnswerDucks';
import {hasElementaryClassroom} from 'static/three-oh/src/modulesV2/utils/classroomUtils';

import {
  INITIALIZE_READ_PAGE,
  SAVE_WRITE_REVIEW,
  STUDENT_WRITE_RESPONSE_POST_REQUEST,
  postStudentWriteResponseSuccess,
  postStudentWriteResponseFailure,
  storeStudentWorkReviewRecords,
  STUDENT_QUIZ_POST_REQUEST
} from '../ducks/readDucks';

function * initializeReadPage(action) {
  const {readUser, headerSlug, headerArticleId} = action;
  // Check to see if the current Article Header on state is the one we need.
  let header = yield select((state) => get(state, 'articleHeader', null));

  if (header.slug != headerSlug) {
    const articleHeaderParams = {
      include_translations: true,
      include_prompt: true,
    };
    yield put(articleHeaderActions.requestArticleHeader(headerSlug, articleHeaderParams, headerArticleId));
    performance.mark('article-header-data-loaded');
    const success = yield take(ARTICLE_HEADER_LOAD_SUCCESS);
    header = success.articleHeader;
  }

  // If readUser is authenticated, get their relevant information. Otherwise,
  // just get the Article Header.
  // Eventually, if there is impersonation, this will need to be more robust logic.
  if (get(readUser, 'id')) {
    // Get classrooms for authenticated user, if we need to.
    const classroomsAreRequested = yield select((state) => get(state, 'classrooms.dateRequested'));
    const isTeacher = get(readUser, 'teacher.id');
    if (!classroomsAreRequested || isTeacher) {
      yield put(classroomActions.requestClassrooms(readUser.id, {}));
    }
    yield put(articleLikeActions.articleLikeRequest());

    // Get the user's studies.
    yield put(studiesActions.requestStudies());

    if (get(readUser, 'teacher.id')) {
      // Get the teacher's ArticleHeaderClassrooms.
      yield put(articleHeaderClassroomActions.requestArticleHeaderClassrooms({
        header_slugs: headerSlug
      }));

      // Get the teacher's ContentHides
      yield put(contentHideActions.requestContentHides({
        content_id: header.content_id,
      }));

      // Get the teacher's Assignments.
      // When filtering by user, it is filtering for assignments that were created by the given user
      // or associated with that user's classrooms.
      yield put(assignmentActions.requestAssignments({
        header_id: header.id,
        user_id: readUser.id,
      }));

      // Get Notifications for student activity work.
      yield put(notificationsActions.initializeNotifications({
        headerSlug,
        userId: readUser.id,
      }));

      // Snag the list of the user's text sets. We use this to populate the list
      // of text sets a user can _add_ this article to.
      const myTextSetParams = {
        rule: 'mine',
        page_size: 100,
      };
      yield put(textSetActions.requestTextSet(myTextSetParams, 'mine'));

      // Load all of the StudentWorkReviewRecords that indicate a teacher has
      // already reviewed a piece of student work. This informs whether or not
      // to display a red dot indicator.
      try {
        const reviewRecords = yield call(StudentWorkReviewRecordApi.get, {
          header_id: header.id
        });
        yield put(storeStudentWorkReviewRecords(reviewRecords));
      } catch (error) {
        // TODO: disable the green bars?
      }
    } else {
      // Get the student's ArticleHeader Classrooms.
      yield put(articleHeaderClassroomActions.requestArticleHeaderClassrooms({
        header_slugs: headerSlug
      }));

      // Get the student's Assignments from Assignments API v3.
      yield put(assignmentActions.requestAssignmentsV3({
        content_member_id: header.content_id,
      }));

      // Get the student's quizzes.
      const studentId = get(readUser, 'student.id');
      yield put(studentQuizActions.requestStudentQuizzes({
        header_id: header.id,
        student_id: studentId,
      }));
    }
  }

  // Load potential Student Write Responses.
  // We do this here because we need the header ID to get all the relevant
  // responses, and it's easiest to make that call here.
  if (has(readUser, 'student')) {
    try {
      const writeResponses = yield call(ConstructedResponseAnswerApi.get, {
        header_id: header.id,
        student_id: readUser.student.id,
      });
      const writeResponsesById = keyBy(writeResponses, 'id');
      yield put(genericModelActions.storeModels(
        {
          modelName: 'constructedResponseAnswer',
          items: writeResponsesById
        })
      );
    } catch (error) {
      // Don't do anything for now.
    }
  }

  // Retrieve content from the Jeeves Search API that is related to the
  // article-header. This content is displayed in the "Related" section of the
  // Read page. Both article-headers and text-sets are retrieved.

  const recommendedHeaderParams = {
    page_size: 3,
    objects_related_to_object_ids: header.id,
    format: 'full',
    type: 'header',
  };
  const recommendedTextSetParams = {
    exclude_mine: true,
    header_id: header.id,
    include_collections: true,
    include_article_headers: false,
    rule: 'featured',
    page_size: 3,
  };

  // If the user is an elementary student, insert search parameters into the
  // request for article-headers that will apply maturity filters to the
  // search.
  const isStudent = has(readUser, 'student');
  const classrooms = yield select((state) => get(state, 'classrooms.items', []));
  const isElementaryUser = hasElementaryClassroom(classrooms);

  if (isStudent && isElementaryUser) {
    recommendedHeaderParams['content_maturities'] = ['Lower Elementary School', 'Upper Elementary School'].join();
    recommendedHeaderParams['exclude_mature_content'] = true;
  }

  // Initiate the requests to the Jeeves Search API.
  yield put(searchActions.requestSearch(recommendedHeaderParams, 'recommendedHeaders'));
  yield put(textSetActions.requestTextSet(recommendedTextSetParams, 'recommendedTextSets'));
}

function * watchInitialization() {
  yield takeLatest(INITIALIZE_READ_PAGE, initializeReadPage);
}

function * persistStudentQuiz({quizId, payload}) {
  try {
    const data = yield call(StudentQuizApi.post, {quizId, payload});
    yield put(studentQuizActions.postStudentQuizSuccess(data));

    // Once we've posted the StudentQuiz, now there's something new to request.
    yield put(studentQuizActions.requestStudentQuizzes({
      headerId: data.quiz.article.header.id,
      studentId: data.student.id
    }));
  } catch (error) {
    yield put(studentQuizActions.postStudentQuizFailure(error));
  }
}

function * watchPostStudentQuiz() {
  yield takeLatest(STUDENT_QUIZ_POST_REQUEST, persistStudentQuiz);
}

function * saveWriteReview(action) {
  try {
    const {classroomId, studentId, articleId, headerSlug, userId} = action.payload;

    // First, persist the review to the database.
    const result = yield call(ConstructedResponseReviewApi.post, action.payload);

    // Update the notification in the Redux store.
    const data = {...action.payload, ...result};
    yield put(notificationsActions.updateStudentWriteNotification({headerSlug, userId}, data));

    // Now we can re-request the student's Write Response Answers so that it gets
    // updated in the store.
    const queryParams = {
      student_id: studentId,
      article_id: articleId,
      classroom_id: classroomId,
      hide_classroom_id_for_revision: false,
    };
    yield put(fetchConstructedResponseAnswers(queryParams));
  } catch (error) {
    // TODO: Display that an error occurred.
  }
}

function * watchSaveWriteReview() {
  yield takeLatest(SAVE_WRITE_REVIEW, saveWriteReview);
}

function * persistStudentWriteResponse({payload}) {
  try {
    const data = yield call(ConstructedResponseAnswerApi.post, {...payload});
    yield put(postStudentWriteResponseSuccess({payload: data}));
    /*
      The normal approach to using the genericModelActions.storeModels function
      would be to make a new GET request using the ConstructedResponseAnswerApi
      and then generically store the models that are returned. The following
      code does not do this. Instead, because the data that is returned from the
      POST request matches the data returned from the initial GET, we can skip
      the re-request of all the Write Responses and save a network call.
    */
    yield put(genericModelActions.storeModels(
      {
        modelName: 'constructedResponseAnswer',
        items: {[data.id]: omit(data, 'statusCode')}
      })
    );
  } catch (error) {
    yield put(postStudentWriteResponseFailure({payload: error}));
  }
}

function * watchPostStudentWriteResponse() {
  yield takeLatest(STUDENT_WRITE_RESPONSE_POST_REQUEST, persistStudentWriteResponse);
}

export {
  watchInitialization,
  watchPostStudentQuiz,
  watchPostStudentWriteResponse,
  watchSaveWriteReview,
};
