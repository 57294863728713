import React from 'react';

import {Button, ChevronLeftSVG, CollectionSVG, Icon} from '@newsela/angelou';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {
  backToMenuAnchorCss,
  backToMenuIconCss,
  homeMenuSubItemPopoutContainerCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/HomeMenu/HomeMenu.styles';
import {
  useNavigationBarV2Context
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/NavigationBar.provider';
import {
  EXPLORE_CONTENT_ITEMS,
  MY_CONTENT_ITEMS
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';
import {
  PopoutMenuSection
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/PopoutMenuSection';
import {
  PopoutSectionItem
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/PopoutSectionItem';
import {
  USER_FEATURE_FOR_PROMOTED_AT_YOUR_DISTRICT_PAGE
} from 'static/three-oh/src/constants/contentPromotion';

import {getUserProperties} from '../../../../../../modules/selectors/userSelectors';

const propTypes = {
  onClickBack: PropTypes.func.isRequired,
};

const HomeMenuContentLibraryPopout = ({onClickBack}) => {
  const {
    hasFeature,
    isStudent,
  } = useSelector((state) => getUserProperties(state));
  const hasUserFeatureToAccessPromotedAtYourDistrictPage = hasFeature(USER_FEATURE_FOR_PROMOTED_AT_YOUR_DISTRICT_PAGE);
  const {
    getters: {
      customCollections,
      personalizedCollections,
    },
  } = useNavigationBarV2Context();

  return (
    <div className={homeMenuSubItemPopoutContainerCss} data-testid="home-content-library-popout">
      <Button onClick={onClickBack} __cssFor={backToMenuAnchorCss} flavor={Button.flavors.incognito}>
        <Icon SvgComponent={ChevronLeftSVG} __cssFor={backToMenuIconCss} />
        Back to menu
      </Button>

      <PopoutMenuSection title="My content" width="15.625rem">
        {MY_CONTENT_ITEMS
          .filter((item) => !(item.title === 'Promoted at your district' && !hasUserFeatureToAccessPromotedAtYourDistrictPage))
          .filter((item) => !isStudent || item.showForStudents)
          .map(({title, href, SvgComponent, dataQASelector, badge}, index) => (
            <PopoutSectionItem
              key={index}
              href={href}
              title={title}
              badge={badge}
              SvgComponent={SvgComponent}
              dataQASelector={dataQASelector}
            />
          ))}
        {personalizedCollections.map(({title, href}) => (
          <PopoutSectionItem key={title} href={href} title={title} SvgComponent={CollectionSVG} />
        ))}
        {customCollections.map(({title, href}) => (
          <PopoutSectionItem key={title} href={href} title={title} SvgComponent={CollectionSVG} />
        ))}
      </PopoutMenuSection>

      <PopoutMenuSection title="Explore content" width="15.625rem">
        {EXPLORE_CONTENT_ITEMS
          .filter((item) => !isStudent || item.showForStudents)
          .map(({title, href, SvgComponent, dataQASelector}, index) => (
            <PopoutSectionItem key={index} href={href} title={title} SvgComponent={SvgComponent} dataQASelector={dataQASelector} />
          ))}
      </PopoutMenuSection>
    </div>
  );
};

HomeMenuContentLibraryPopout.propTypes = propTypes;
HomeMenuContentLibraryPopout.displayName = 'HomeContentLibraryPopout';
export {HomeMenuContentLibraryPopout};
