import {get, keyBy} from 'lodash-es';
import {createSelector} from 'reselect';

const DEFAULT = [];

export const getArticles = (state) => get(state, 'articles.items', DEFAULT);

export const getArticlesById = createSelector(
  getArticles, (articles) => keyBy(articles, 'id')
);
