import {css} from 'emotion';

const defaultStyles = css`
  text-align: center;
  margin-top: 24px;
  margin-bottom: 32px;

  h3 {
    margin: 0;
  }
`;

export default {
  defaultStyles
};
