import {takeEvery, call, put} from 'redux-saga/effects';

import {StudentAssignmentViewRecordApi} from 'static/three-oh/src/modules/apis';

import * as actions from '../actions/studentAssignmentViewActions';
import * as types from '../constants/studentAssignmentViewTypes';

function * getStudentAssignmentViews() {
  try {
    const data = yield call(StudentAssignmentViewRecordApi.get);
    yield put(actions.studentAssignmentViewLoadSuccess(data));
  } catch (error) {
    yield put(actions.studentAssignmentViewLoadSuccess(error));
  }
}

export function * watchGetStudentAssignmentViews() {
  yield takeEvery(types.STUDENT_ASSIGNMENT_VIEW_LOAD_REQUEST, getStudentAssignmentViews);
}
