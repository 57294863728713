const initialState = {
  binderFilters: {
    activityType: 'all',
    classroom: 'all',
    readingStandard: 'all',
    since: '',
    until: '',
  },
  binderEnabledFilters: {
    readingStandard: true,
    dates: true
  },
  binderStudents: {
    currentEstimatedGrades: {
      isError: false,
      isLoading: false,
      items: [],
    },
    studentList: {
      isError: false,
      isLoading: false,
      items: [],
    },
    studentReadingLevels: {
      isError: false,
      isLoading: false,
      item: {},
      loadedItems: {}
    }
  },
  binderStandards: {
    isError: false,
    isLoading: false,
    items: [],
  },
  binderStudentDetails: {},
  binderWork: {
    articleHeaderClassrooms: {
      isLoading: false,
      items: [],
    },
    assignments: {
      isLoading: false,
      items: [],
    },
    headerReportingData: {
      isLoading: false,
      items: [],
    },
    notificationsByHeader: {},
    readingTimes: {},
  },
};

export default initialState;
