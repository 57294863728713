import {takeLatest, call, put} from 'redux-saga/effects';

import {
  fetchCollectionsSuccess,
  fetchCollectionsFailure,
} from 'modulesV2/actions';
import * as types from 'modulesV2/constants';
import CollectionsApi from 'static/three-oh/src/modules/apis/CollectionsApi/CollectionsApi';
/**
 * Fetch list user collections
 * @returns {Generator}
 */
function * fetchCollections(action) {
  try {
    const params = action.params || {};
    const response = yield call(CollectionsApi.get, params);
    yield put(fetchCollectionsSuccess(response.data));
  } catch (error) {
    yield put(fetchCollectionsFailure(error));
  }
}

// --- Watchers ---- //
export function * watchFetchCollectionsRequest() {
  yield takeLatest(types.COLLECTIONS_FETCH_REQUEST, fetchCollections);
}
