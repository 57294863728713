import React from 'react';

import {LoadingSpinner} from '@newsela/angelou';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';


import {READ_PATH} from '../Read/ReadRoute';

const LazyAssignmentsContainer = Loadable({
  loader: () => import(
    /* webpackChunkName: "LazySearchComponent" */
    './containers/Assignments/AssignmentsContainer'
  ),
  loading: LoadingSpinner,
});

const LazyCanvasAssignmentReviewContainer = Loadable({
  loader: () => import(
    /* webpackChunkName: "LazyCanvasAssignmentReviewContainer" */
    './containers/AssignmentReviewContainer/AssignmentReviewContainer'
  ),
  loading: LoadingSpinner,
});

const LazyAssignedArticleReviewContainer = Loadable({
  loader: () => import(
    /* webpackChunkName: "LazyAssignedArticleReviewContainer" */
    './containers/AssignedArticleReviewContainer/AssignedArticleReviewContainer'
  ),
  loading: LoadingSpinner,
});

// Canvas Read route has to mimic ReadRoute path due to tight coupling between
// Read page components and URL params.
const CanvasRoute = [
  <Route
    key="CanvasAssignments"
    path="/apps/canvas/assignments/create"
    component={LazyAssignmentsContainer}
  />,
  <Route
    key="CanvasAssignments"
    path="/apps/ltiv1p3/assignments"
    component={LazyAssignmentsContainer}
  />,
  <Route
    key="CanvasAssignmentReview"
    path={`/apps/canvas/assignment-review/:assignmentId/student/:studentId${READ_PATH}`}
    component={LazyAssignedArticleReviewContainer}
  />,
  <Route
    key="CanvasAssignmentReview"
    path="/apps/canvas/assignment-review/:assignmentId/student/:studentId"
    component={LazyCanvasAssignmentReviewContainer}
  />
];

export default CanvasRoute;
