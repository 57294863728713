import {combineReducers} from 'redux';

import v2Reducers from '../modulesV2/reducers';

const commonReducers = {
  articleHeader: require('../modules/reducers/articleHeaderReducers').default,
  articleHeaderClassrooms: require('../modules/reducers/articleHeaderClassroomReducers').default,
  articles: require('../modules/reducers/articleReducers').default,
  articleWords: require('../modules/reducers/articleWordReducers').default,
  assignments: require('../modules/reducers/assignmentReducers').default,
  classrooms: require('../modules/reducers/classroomReducers').default,
  contentRow: require('../modules/reducers/contentRowReducers').contentRow,
  contentHides: require('../modules/reducers/contentHideReducers').default,
  crud: require('../modules/ducks/objectApiDucks').default,
  dismissedEwots: require('../modules/reducers/ewotsReducer').default,
  license: require('../modules/reducers/licenseReducers').default,
  models: require('../modules/reducers/genericModelReducer').default,
  notifications: require('../modules/reducers/notificationsReducer').default,
  recentNews: require('../modules/reducers/recentNewsReducers').default,
  reporting: require('../modules/reducers/reportingReducers').default,
  search: require('../modules/reducers/searchReducers').default,
  studentAssignmentView: require('../modules/reducers/studentAssignmentViewReducers').default,
  studentQuizzes: require('../modules/reducers/studentQuizReducers').default,
  studies: require('../modules/reducers/studiesReducers').default,
  textSet: require('../modules/reducers/textSetReducers').default,
  user: require('../modules/reducers/userReducers').default,
  userStream: require('../modules/reducers/userStreamReducers').default,
  userExperimentSegments: require('../modules/reducers/userExperimentSegmentsReducers').default,
};

const binderReducers = {
  binderEnabledFilters: require('../routes/Binder/modules/reducers/binderEnabledFiltersReducer').default,
  binderImpersonatedUser: require('../routes/Binder/modules/reducers/binderImpersonatedUserReducer').default,
  binderStudentDetails: require('../routes/Binder/modules/reducers/binderStudentDetailsReducer').default,
  binderStudents: require('../routes/Binder/modules/reducers/binderStudentsReducer').default,
  binderStandards: require('../routes/Binder/modules/reducers/binderStandardsReducer').default,
  binderWork: require('../routes/Binder/modules/reducers/binderWorkReducer').default,
};

const readReducers = {
  read: require('../routes/Read/modules/reducers').readReducer,
};

const studentBinderReducers = {
  studentBinderTableData: require('../routes/StudentBinder/modules/reducers/studentBinderDataReducer').default,
};

const signUpReducers = {
  signUpStatus: require('../routes/SignUp/modules/reducers/signUpReducer').default,
};

export default combineReducers({
  ...commonReducers,
  ...binderReducers,
  ...readReducers,
  ...studentBinderReducers,
  ...signUpReducers,
  v2: combineReducers(v2Reducers),
});
