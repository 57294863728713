
import {get} from 'lodash-es';

// This will eventually be moved once we have
// the service objects setup for quiz/activity data
const readPageKey = 'v2.pages.read';

/**
 * @param {Object} state
 * @returns {Object}
 */
export const getActiveActivities = (state) => get(
  state, `${readPageKey}.activeArticlesActivities`, {}
);

/**
 * @param {Object} state
 * @param {string} articleId
 * @returns {
 *   quizQuestions,
 *   powerWords,
 *   writePrompt
 * }
 */
export const getActiveArticleActivity = (state, articleId) => get(
  state, `${readPageKey}.activeArticlesActivities[${articleId}]`, {}
);
