import React, {useCallback, useEffect, useState, useMemo} from 'react';

import PropTypes from 'prop-types';

import determineAdvisoryState from 'utils/determineAdvisoryState';

import {getContentSettingsType} from './withV3AssignmentApiGet';
import ApiFetcher3 from '../../src/modules/apis/ApiFetcher3';
import {CONTENT_SETTINGS_TYPE} from '../../src/modules/utils/assignmentHelpers';

const withV3AssignmentApiGet = (ComponentToWrap) => {
  const propTypes = {
    id: PropTypes.string,
    lazy: PropTypes.bool,
    params: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  };
  const WrappedWithV3AssignmentsApiGet = ({
    id,
    lazy,
    params,
    ...otherProps
  }) => {
    // Calculating the params string helps make sure the API loading effect
    // below only re-runs if the actual param string has changed, even if the
    // input params prop references a different object.
    const paramsStr = useMemo(() => {
      const userData = otherProps.authenticatedUser || otherProps.user;
      const isStudent = userData?.student;
      const getArchived = !isStudent;

      // Compute the search params as a string.
      const defaultParamString = `use_imgix=true${getArchived ? '&with_archived_classrooms=true' : ''}`;

      if (!params) { return defaultParamString; }

      if (typeof params === 'string') { return `${params}&${defaultParamString}`; }

      return new URLSearchParams({
        use_imgix: true,
        ...(getArchived ? {with_archived_classrooms: true} : {}),
        ...params
      }).toString();
    }, [params]);

    // Component State:
    // Define the assignments API call state, and load it.
    const [assignmentsApiCallState, setAssignmentsApiCallState] = useState({isLoading: undefined});

    // Component Methods:
    const retrieveAssignmentsApiCallFunc = useCallback(() => {
      let canceled = false;
      const load = async() => {
        // Set the state to loading, unless it already is.
        setAssignmentsApiCallState((current) => {
          if (current.isLoading) return current;
          return {...current, isLoading: true};
        });
        const idPathPortion = id ? `${id}/` : '';
        try {
          // Make the API call.
          const {data} = await ApiFetcher3.get(`assignment/${idPathPortion}`, paramsStr);
          if (canceled) return;
          setAssignmentsApiCallState({data, isLoading: false});
        } catch (error) {
          if (canceled) return;
          setAssignmentsApiCallState({error, isLoading: false});
        }
      };
      load();
      return () => {
        // Cancel state change, in case the component unmounts, for example.
        canceled = true;
      };
    }, [id, paramsStr]);

    const updateAssignmentAndApiCallState = async(selectedMember, assignment) => {
      // set currently selected member to loading:
      setAssignmentsApiCallState((current) => {
        return {
          ...current,
          updatingAssignmentMember: selectedMember.content.content_id
        };
      });

      const contentSettingsType = getContentSettingsType(selectedMember);
      const assignmentPayload = formatAssignmentPayload(assignment, selectedMember.content.content_id, contentSettingsType);

      try {
        const {data} = await ApiFetcher3.post(`assignment/${assignment.content_id}/`, {data: assignmentPayload});
        setAssignmentsApiCallState({
          isLoading: false,
          data,
          updatingAssignmentMember: null
        });
      } catch (error) {
        // error handling here. trigger error toast?
        setAssignmentsApiCallState({
          isLoading: false,
          error,
          updatingAssignmentMember: null
        });
      }
    };

    // Component Effects:
    useEffect(() => {
      if (!lazy) {
        retrieveAssignmentsApiCallFunc();
      }
    }, [id, paramsStr]);

    return (
      <ComponentToWrap
        assignmentsApiCallState={assignmentsApiCallState}
        retrieveAssignmentsApiCallFunc={retrieveAssignmentsApiCallFunc}
        updateAssignmentAndApiCallState={updateAssignmentAndApiCallState}
        determineAdvisoryState={determineAdvisoryState}
        {...otherProps}
      />
    );
  };

  WrappedWithV3AssignmentsApiGet.propTypes = propTypes;

  return WrappedWithV3AssignmentsApiGet;
};

export const formatAssignmentPayload = (assignment, consentedContent, contentSettingsType = null) => {
  const postMemberFormat = assignment.assignment_members.map((member) => {
    return {
      ...member,
      content_id: member.content.content_id
    };
  });
  delete assignment.assignment_members;
  assignment.assignment_members = postMemberFormat;

  const postClassroomFormat = assignment.assignment_classrooms.map((classroom) => {
    return {...classroom, classroom_id: classroom.classroom.id, date_assigned: classroom.date_assigned};
  });
  delete assignment.assignment_classrooms;
  assignment.assignment_classrooms = postClassroomFormat;

  assignment.source_content_id = assignment.source_content?.content_id;
  delete assignment.source_content;

  delete assignment.student_assignment_view_records;

  // Provide consent to override content advisory if consentedContent present:
  if (consentedContent && contentSettingsType === CONTENT_SETTINGS_TYPE.CAT) {
    assignment.district_advisory_override = consentedContent;
  }

  // Provide consent to student restricted content if consentedContent present:
  if (consentedContent && contentSettingsType === CONTENT_SETTINGS_TYPE.SRC) {
    assignment.content_settings_override = consentedContent;
  }

  // Remove null objects to prevent API post from failing.
  Object.keys(assignment).forEach((key) => {
    if (assignment[key] === null) delete assignment[key];
  });

  return assignment;
};

export default withV3AssignmentApiGet;
