import React from 'react';

import {Combobox} from '@newsela/angelou';
import PropTypes from 'prop-types';

const propTypes = {
  additionalClassName: PropTypes.object,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onSelectedItemChange: PropTypes.func,
  selectedItem: PropTypes.object,
  touched: PropTypes.bool,
  __css: PropTypes.any,
  dataQaSelector: PropTypes.string,
};

const defaultProps = {
  label: 'Subject',
  name: 'subject',
  dataQaSelector: 'subjects_dropdown'
};

const SUBJECT_SLUG_MAPPING = [
  {subject: 'english-arts-reading', name: 'English/Language Arts/Reading'},
  {subject: 'english-language-learner', name: 'English Language Learners'},
  {subject: 'foreign-language', name: 'Foreign Language'},
  {subject: 'general-elementary', name: 'General Elementary', displayName: 'Elementary'},
  {subject: 'math', name: 'Math'},
  {subject: 'science', name: 'Science'},
  {subject: 'social-studies', name: 'Social Studies/Social Science'},
  {subject: 'special-education', name: 'Special Education'},
  {subject: 'other', name: 'Other'},
];

/*
 * This component is a ClassSubject combobox using Angelou's combobox.
 * This can be used in html forms and can also be used with Formik.
 */
const ClassSubjectCombobox = (props) => {
  const {additionalClassName, selectedItem, touched, error, onBlur, onSelectedItemChange, __css, name, label, dataQaSelector} = props;

  const itemToString = (item) => item ? item.displayName || item.name : '';

  return (
    <Combobox
      __classNameFor={{
        root: additionalClassName
      }}
      items={SUBJECT_SLUG_MAPPING}
      label={label}
      itemToString={itemToString}
      onSelectedItemChange={(e) => {
        onSelectedItemChange && onSelectedItemChange(e);
      }}
      selectedItem={selectedItem}
      createInputProps={() => ({
        onBlur: () => {
          onBlur && onBlur();
        },
        name: name,
        'data-qa-selector': dataQaSelector,
      })}
      isRequired
      touched={touched}
      error={error}
      __cssFor={{
        root: __css
      }}
    />
  );
};

ClassSubjectCombobox.propTypes = propTypes;
ClassSubjectCombobox.defaultProps = defaultProps;

export default ClassSubjectCombobox;
