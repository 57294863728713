import React from 'react';

import {CompositeDecorator} from 'draft-js';
import prependHttp from 'prepend-http';
import PropTypes from 'prop-types';


/**
 * Determines if a block of text contains any links. If so,
 * we execute our callback which will decorate them.
 * @param {object} contentBlock - immutable object containing information about a block of content
 * @param {function} callback - function to be executed for any contentBlock that
 * evaluates true in the contentBlock's findEntityRanges
 * @param {object} contentState - immutable object representing the entire document
 */
function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}

function NewselaLink(props) {
  const foundEntity = props.contentState.getEntity(props.entityKey);
  const {url} = foundEntity.getData();

  const safeUrl = prependHttp(url);

  // Merges in data so that we can apply these values
  // to the particular entity. It adds these to the
  // attributes of our generated html.
  props.contentState.mergeEntityData(props.entityKey, {
    url: safeUrl,
    target: '_blank',
    rel: 'noopener noreferrer'
  });

  return (
    <a
      href={safeUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.children}
    </a>
  );
}

NewselaLink.propTypes = {
  children: PropTypes.node,
  contentState: PropTypes.object,
  entityKey: PropTypes.string
};

/**
 * Decorator scans through contentBlock and finds any links within the text
 * through our findLinkEntities strategy.
 *
 * Documentation about decorators:
 * https://draftjs.org/docs/advanced-topics-decorators/#compositedecorator
 */
export default new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: NewselaLink,
  },
]);
