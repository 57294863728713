import {studentBinderActionTypes as types} from '../constants';

export function initializeStudentBinder(userId, studentId, classroomIds) {
  return {type: types.INITIALIZE_STUDENT_BINDER, payload: {userId: userId, studentId: studentId, classroomIds: classroomIds}};
}

export function getStudentBinderDataRequest() {
  return {type: types.STUDENT_BINDER_DATA_LOAD_REQUEST};
}

export function getStudentBinderDataSuccess(payload) {
  return {type: types.STUDENT_BINDER_DATA_LOAD_SUCCESS, payload};
}

export function getStudentBinderDataFailure(error) {
  return {type: types.STUDENT_BINDER_DATA_LOAD_FAILURE, error};
}
