import {isEmpty} from 'lodash-es';
import {delay} from 'redux-saga';
import {takeEvery, call, put} from 'redux-saga/effects';

import {ClassroomApi} from './../apis';
import * as types from './../constants/classroomActionTypes';
import * as actions from '../actions/classroomActions';


export function * watchGetClassrooms() {
  yield takeEvery(types.CLASSROOMS_LOAD_REQUEST,
    function * getClassrooms(action) {
      let data = [];
      const {userId, params} = action;
      try {
        data = yield call(
          ClassroomApi.getClassrooms, userId, params);
        yield put(actions.loadClassroomsSuccess(data));
      } catch (error) {
        yield put(actions.loadClassroomsFailure(error));
      }
    }
  );
}

export function * watchCreateClassroom() {
  yield takeEvery(types.CLASSROOM_POST_REQUEST,
    function * createClassroom(action) {
      const {payload} = action;
      try {
        const result = yield call(ClassroomApi.createClassroom, payload);
        yield put(actions.createClassroomSuccess(result));
      } catch (error) {
        yield put(actions.createClassroomFailure(error));
      }
    }
  );
}

export function * watchJoinClassroom() {
  yield takeEvery(types.CLASSROOM_JOIN_REQUEST,
    function * joinClassroom(action) {
      const {studentID, classroomCode} = action;
      try {
        const classroomPayload = yield call(ClassroomApi.getClassroomFromCode, classroomCode);
        const result = yield call(ClassroomApi.joinClassroom, studentID, classroomPayload.data.id);
        yield put(actions.joinClassroomSuccess(result));
      } catch (error) {
        yield put(actions.joinClassroomFailure(error));
      }
    }
  );
}

// This saga is only executed when a teacher opens Newsela External Tool in Canvas.
export function * watchStartClassroomPolling() {
  yield takeEvery(types.CLASSROOM_POLLING_START,
    function * pollClassroom(action) {
      const {userId, params} = action;
      try {
        const classrooms = yield call(ClassroomApi.getClassrooms, userId, params);
        if (!isEmpty(classrooms)) {
          yield put(actions.loadClassroomsSuccess(classrooms));
        } else {
          yield call(delay, 5000);
          yield call(pollClassroom, action);
        }
      } catch (error) {
        // ignore errors.
      }
    }
  );
}
