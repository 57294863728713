import {takeEvery, takeLatest, call, put} from 'redux-saga/effects';

import {TextSetApi} from 'static/three-oh/src/modules/apis';

import * as actions from './../actions/textSetActions';
import * as types from './../constants/textSetActionTypes';

function * getTextSet(action) {
  try {
    const data = yield call(TextSetApi.get, action.queryString);
    yield put(actions.requestTextSetSuccess(data, action.slug));
  } catch (error) {
    yield put(actions.requestTextSetFailure(error, action.slug));
  }
}

export function * watchGetTextSet() {
  yield takeEvery(types.TEXT_SET_LOAD_REQUEST, getTextSet);
}

function * persistTextSetArticleHeader({payload}) {
  try {
    const textSetArticleHeader = {
      parentTextSetId: payload.textSetId,
      childElementId: payload.articleHeaderId,
    };
    const data = yield call(TextSetApi.addArticle, textSetArticleHeader);
    const successData = {
      ...payload,
      id: data.id,
      slug: 'mine',
    };
    yield put(actions.addHeaderToTextSetSuccess(successData));
  } catch (e) {
    // There was a failure, but it's not going to get handled.
  }
}

function * deleteTextSetArticleHeader({payload}) {
  try {
    const textSetArticleHeader = {
      parentTextSetId: payload.textSetId,
      childElementId: payload.articleHeaderId
    };
    yield call(TextSetApi.delete, textSetArticleHeader);
    const successData = {
      ...payload,
      slug: 'mine',
    };
    yield put(actions.removeHeaderFromTextSetSuccess(successData));
  } catch (e) {
    // There was a failure, but it's not going to get handled.
  }
}

function * persistTextSet({payload}) {
  try {
    // First: Save the Text Set
    const data = yield call(TextSetApi.post, {payload});
    // Second: Add Header to new TextSet, if exists on payload
    if (payload.articleHeaderId) {
      const tsah = {
        articleHeaderId: payload.articleHeaderId,
        textSetId: data.id,
      };
      yield call(persistTextSetArticleHeader, {payload: tsah});
      // I haven't decided if this last part is goofy or not, but it matches prod.
      const url = `/subject/other/${data.id}/`;
      window.location.href = url;
    }
    yield put(actions.saveTextSetSuccess({payload: data}));
  } catch (error) {
    yield put(actions.saveTextSetSuccess({payload: error}));
  }
}

export function * watchPersistTextSet() {
  yield takeLatest(types.TEXT_SET_SAVE_REQUEST, persistTextSet);
}

export function * watchAddHeaderToTextSet() {
  yield takeLatest(types.ADD_HEADER_TO_TEXT_SET_REQUEST, persistTextSetArticleHeader);
}

export function * watchRemoveHeaderFromTextSet() {
  yield takeLatest(types.REMOVE_HEADER_FROM_TEXT_SET_REQUEST, deleteTextSetArticleHeader);
}
