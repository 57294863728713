import {constants} from '@newsela/angelou';

export const subjectAreaAnchorCss = {
  root: {
    padding: '0.25rem 0.5rem',
    textDecoration: 'none',
    display: 'flex',

    '> div': {
      display: 'flex',
      flexDirection: 'row',
      gap: '0.5rem',
    },

    svg: {
      color: constants.colors.ui.neutral[600],
    },

    '.apply-stroke': {
      path: {
        stroke: constants.colors.ui.neutral[600],
      }
    },

    '.apply-fill': {
      path: {
        fill: constants.colors.ui.neutral[600],
      }
    },

    ':hover': {
      div: {
        color: constants.colors.ui.core[700],
      },

      svg: {
        color: constants.colors.ui.core[700],
      },

      '.apply-stroke': {
        path: {
          stroke: constants.colors.ui.core[700],
        }
      },

      '.apply-fill': {
        path: {
          fill: constants.colors.ui.core[700],
        }
      },
    },
  }
};

export const titleCss = {
  color: constants.colors.ui.neutral[600],
};

export const subjectAreaIconCss = {
  root: {
    width: '1rem',
    height: '1rem',
  },
};

export const lockedIconCss = {
  root: {
    width: '1rem',
    height: '1rem',

    path: {
      stroke: constants.colors.ui.neutral[600],
    },

    circle: {
      fill: constants.colors.ui.neutral[600],
    },

    'path:nth-of-type(1), path:nth-of-type(2)': {
      strokeWidth: 3,
    }
  }
};
