import APIFetcher from '../APIFetcher';

class StudentClassroomApi {
  static createStudentClassroom(payload) {
    return APIFetcher.post('student-classroom/', {payload});
  }

  static removeStudentClassroom(payload) {
    return APIFetcher.remove(`classroom/${payload.classroomId}/student/${payload.studentId}/`);
  }
}

export default StudentClassroomApi;
