import ApiFetcher2, {BASE_CONFIG} from 'static/three-oh/src/modules/apis/ApiFetcher2';

/**
 * NOTE: The ContentApi adapter currently only supports making
 * requests against the v3 of the content API.
 */

const BASE_PATH = '/api/v3';
class ContentApi {
  /**
   * @param {string} contentId cuid used to request information from the API
   * @param {object} queryParams
   * @param {number} queryParams.depth the number of children for each member to obtain (from 0 - 3)
   * @param {string} queryParams.shape handles the configuration of information sent over. Represents
   * just enough information necessary to render cards or outlines ('shape' or 'outline')
   * @param {number} queryParams.member_limit manual limit for the amount of members returned
   */

  static get(contentId, queryParams = {}) {
    if (!contentId) {
      throw new Error('Pass a content id to fetch content using the `get` method)');
    }

    return ApiFetcher2.get(`/content/${contentId}/`, {
      params: queryParams,
      config: {
        ...BASE_CONFIG,
        basePath: BASE_PATH
      }
    });
  }

  static getWithParams(queryParams) {
    if (!queryParams || Object.keys(queryParams).length === 0) {
      throw new Error('Pass params to fetch content using the `getWithParams` method)');
    }

    return ApiFetcher2.get('/content/', {
      params: queryParams,
      config: {
        ...BASE_CONFIG,
        basePath: BASE_PATH
      }
    });
  }

  static getWithSelector(selector, queryParams = {}) {
    if (!selector) {
      throw new Error('Pass a selector to fetch content using the `getWithSelector` method)');
    }

    return ApiFetcher2.get(`/content/selector/${selector}`, {
      params: queryParams,
      config: {
        ...BASE_CONFIG,
        basePath: BASE_PATH
      }
    });
  }
}

export default ContentApi;
