import * as types from '../constants/userExperimentSegmentsActionTypes';

export function requestUserExperimentSegments() {
  return {
    type: types.USER_EXPERIMENT_SEGMENTS_REQUEST
  };
}

export function requestUserExperimentSegmentsSuccess(data) {
  return {
    type: types.USER_EXPERIMENT_SEGMENTS_SUCCESS,
    userExperimentSegments: data,
    receivedAt: Date.now()
  };
}

export function requestUserExperimentSegmentsFailure() {
  return {
    type: types.USER_EXPERIMENT_SEGMENTS_FAILURE,
    userExperimentSegments: {},
    receivedAt: Date.now()
  };
}
