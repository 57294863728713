import {constants, typographyStyles} from '@newsela/angelou';
import {css} from 'emotion';

import {
  NAVBAR_BREAKPOINTS
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';

export const absoluteSearchIconCss = {
  root: {
    position: 'absolute',
    left: '1rem',
    top: '0.75rem',
    width: '1.5rem',
    height: '1.5rem',
  }
};

export const inputFieldCss = css({
  display: 'flex',
  padding: '0.75rem 1rem 0.75rem 3rem',
  height: '3rem',
  width: '16.75rem',
  background: constants.colors.ui.neutral[25],
  color: constants.colors.ui.neutral[700],
  ...typographyStyles.textMObj,

  border: 0,
  borderRadius: '0.5rem',

  '::placeholder': {
    ...typographyStyles.textMObj,
    color: constants.colors.ui.neutral[600],
    fontWeight: 400,
  },

  [`@media (max-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
    width: '100%',
  },
});
