import {get, map, max} from 'lodash-es';

// ordinary non-memoized input selector functions
export const getNotifications = (state, namespace) => {
  return get(state, `notifications[${namespace}].items`, []);
};

export const getMostRecentDateCreated = (state, namespace) => {
  const notifications = getNotifications(state, namespace);
  const dates = map(notifications, 'date_created');

  return max(dates);
};

export const isErrorNotifications = (state, namespace) => {
  return get(state, `notifications[${namespace}].isError`, true);
};

export const isLoadingNotifications = (state, namespace) => {
  return get(state, `notifications[${namespace}].isLoading`, true);
};
