import APIFetcher from '../APIFetcher';

class ArticleWordApi {
  static getByArticleId(articleId, params = {}) {
    const defaultParams = {
      article_id: articleId,
    };
    params = {...defaultParams, ...params};
    const query = APIFetcher.stringifyParams(params);
    return APIFetcher.get(`article-word?${query}`);
  }

  static getByHeaderId(headerId, params = {}) {
    const defaultParams = {
      header_id: headerId,
    };
    params = {...defaultParams, ...params};
    const query = APIFetcher.stringifyParams(params);
    return APIFetcher.get(`article-word?${query}`);
  }

  static getByHeaderIds(headerIds) {
    const params = {
      page_size: 1000,
      header_ids: headerIds.join()
    };
    const queryParams = APIFetcher.stringifyParams(params);
    return APIFetcher.get(`article-word/?${queryParams}`);
  }
}

export default ArticleWordApi;
