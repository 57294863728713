import {get} from 'lodash-es';
import {createSelector} from 'reselect';

const reduxState = (state) => state;

export const getExternalToolContext = createSelector(
  [reduxState],
  (state) => {
    return get(state, 'v2.services.objects.externalToolContext', null);
  }
);
