import {
  watchInitializeAssignmentReview,
} from './canvasAssignmentReviewSagas';
import {
  watchFetchCollectionsRequest,
} from './collectionsSagas';
import {
  watchGetContent
} from './contentSagas';
import {
  watchFetchEmails,
  watchMakeEmailPrimary,
  watchCreateNewEmail,
} from './emailsSagas';
import {
  watchFetchEventRecords,
  watchCreateEventRecord,
} from './eventRecordSagas';
import {
  watchClearExternalToolContext,
  watchSetExternalToolContext,
  watchGetExternalToolContext,
  watchRosterSyncSuccess,
} from './externalToolSagas';
import {
  watchGetLessonSpark
} from './lessonSparksSagas';
import {
  watchGetLikedContent,
  watchCreateLikedContent,
  watchDeleteLikedContent,
} from './likedContentSagas';
import {
  watchCreateRosterChoice,
  watchChangeRosterChoice,
  watchRequestRosterChoice,
  watchCreateRosterChoiceAndSync,
} from './rosterChoiceSagas';
import {
  watchMarkSyncEventAsSeen,
  watchRequestRosterSync,
  watchRequestRosterInfoSync,
  watchRequestRosterDetails,
  watchRequestRosterClassrooms,
} from './rosterSagas';
import {
  watchInitializeYourContentPage,
  watchBookmarkedArticleHeadersFetchRequest,
  watchTextSetsFetchRequest,
  watchBookmarkedStandardsFetchRequest,
} from './yourContentPageSagas';


export default [
  watchFetchEmails,
  watchMakeEmailPrimary,
  watchCreateNewEmail,

  watchGetContent,

  watchGetLikedContent,
  watchCreateLikedContent,
  watchDeleteLikedContent,

  watchGetLessonSpark,

  watchMarkSyncEventAsSeen,
  watchRequestRosterSync,
  watchRequestRosterInfoSync,
  watchCreateRosterChoiceAndSync,
  watchCreateRosterChoice,
  watchChangeRosterChoice,
  watchRequestRosterChoice,
  watchRequestRosterClassrooms,
  watchRequestRosterDetails,
  watchClearExternalToolContext,
  watchSetExternalToolContext,
  watchGetExternalToolContext,
  watchRosterSyncSuccess,
  watchInitializeAssignmentReview,

  watchFetchEventRecords,
  watchCreateEventRecord,

  watchFetchCollectionsRequest,

  watchInitializeYourContentPage,
  watchBookmarkedArticleHeadersFetchRequest,
  watchTextSetsFetchRequest,
  watchBookmarkedStandardsFetchRequest,
];
