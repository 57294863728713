const initialState = {
  signUpStatus: {
    isError: false,
    isLoading: false,
    isSuccess: false,
    isRoleCreationSuccess: false,
    isRoleCreationInProgress: false,
  },
};

export default initialState;
