import React from 'react';

import {LoadingShimmer} from '@newsela/angelou';
import PropTypes from 'prop-types';

import {loadingShimmerCss} from './LoadingShimmerMapper.style';


const propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

const LoadingShimmerMapper = ({loading, children}) => {
  if (loading) return React.Children.map(children, () => <div style={loadingShimmerCss}><LoadingShimmer /></div>);
  return children;
};

LoadingShimmerMapper.propTypes = propTypes;

export default LoadingShimmerMapper;
