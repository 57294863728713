export const skipToAnchorCss = {
  root: {
    padding: '0.75rem 0',

    ':active, :focus': {
      '> div': {
        width: '48px',
        opacity: 1,
        fontSize: '0.85rem',
        height: '1.5rem',
        alignItems: 'center',
      }
    },

    '> div': {
      width: 0,
      fontSize: '0.85rem',
      opacity: 0,
    },

    '@media (max-width: 380px)': {
      display: 'none',
    },
  }
};
