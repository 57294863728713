import React from 'react';

import {Anchor, Icon, NewselaSVG} from '@newsela/angelou';

import {
  newselaLogoAnchorCss,
  newselaLogoCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/NewselaLogo/NewselaLogo.styles';

const NewselaLogo = () => {
  return (
    <li>
      <Anchor
        href="/home"
        __cssFor={newselaLogoAnchorCss}
        dataProps={{'data-qa-selector': 'global-nav-home', tabIndex: 0}}
        ariaProps={{'aria-label': 'Go to Home'}}
      >
        <Icon isDecorative={false} title="Go to Home" SvgComponent={NewselaSVG} __cssFor={newselaLogoCss} />
      </Anchor>
    </li>
  );
};

NewselaLogo.displayName = 'NewselaLogo';
export {NewselaLogo};
