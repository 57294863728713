import React, {useRef} from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import {cx} from 'emotion';
import {get} from 'lodash-es';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import {
  menuItemStyle,
  menuItemLinkStyle,
  menuItemSelectedStyle
} from './styles';

const propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  child: PropTypes.element.isRequired,
  selected: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
    selected: PropTypes.string
  }),
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
};

export const isLink = (ref) => {
  return ReactDOM.findDOMNode(ref.current) && ReactDOM.findDOMNode(ref.current).nodeName === 'A';
};

const DropDownMenuItem = (props) => {
  const {
    value,
    onClick,
    child,
    selected,
    classes = {},
    minWidth,
    maxWidth,
  } = props;

  const childRef = useRef(null);
  const childLink = isLink(childRef);
  const childWithRef = React.cloneElement(child, {
    ref: childRef,
  });

  const rootClasses = cx([
    childLink ? menuItemLinkStyle : '',
    menuItemStyle,
    get(classes, 'root', '')
  ]);

  const selectedClasses = cx([
    menuItemSelectedStyle,
    get(classes, 'selected', '')
  ]);

  return (
    <MenuItem
      style={{minWidth, maxWidth}}
      selected={selected}
      value={value}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
      classes={{
        root: rootClasses,
        selected: selectedClasses
      }}
    >
      {childWithRef}
    </MenuItem>
  );
};

DropDownMenuItem.propTypes = propTypes;

export default DropDownMenuItem;
