import {stringifyParams} from '../../../../../modules/apis/ApiFetcher4';

const fetchSuggestionsService = async(text = '') => {
  const resource = '/agatha/api/suggestions/';

  const queryString = stringifyParams({
    fragment: text,
    page_size: 5,
    segmented_trending: true,
    strategy: 'ranked',
  });

  try {
    const response = await fetch(`${resource}${queryString}`);
    return await response?.json();
  } catch (error) {
    return error;
  }
};

export {fetchSuggestionsService};
