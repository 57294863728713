import React from 'react';

import {get} from 'lodash-es';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import PropTypes from 'prop-types';

import withWindowSize from 'components/with/WithWindowSize';
import uiColors, {brandColors} from 'static/three-oh/src/components/Colors/colors';
import SCREENSIZE, {screenSizeEnum} from 'static/three-oh/src/constants/ScreenSize';
import fonts from 'static/three-oh/src/modules/constants/fonts';

import styles from './styles.scss';

const propTypes = {
  children: PropTypes.element,
  hasActiveLicense: PropTypes.bool,
  readPageMode: PropTypes.object,
  size: PropTypes.string,
  hideWhenSmallerThan: PropTypes.string,
  className: PropTypes.string,
};

const defaultProps = {
  hasActiveLicense: false,
  hideWhenSmallerThan: SCREENSIZE.medium,
  readPageMode: {name: '', pageElements: {}},
  className: '',
};

const customMuiTheme = {
  backgroundColor: uiColors.dark_blue[0],
  fontFamily: fonts.helvetica.normal,
  datePicker: {
    color: brandColors.brand_primary,
    selectColor: brandColors.brand_primary,
  },
  palette: {
    primaryColor: brandColors.brand_primary,
  },
};

const LeftRail = (props) => {
  const mergedMuiTheme = getMuiTheme(customMuiTheme);
  const railColor = props.hasActiveLicense ? styles.pro : styles.free;
  const isSmallEnoughToHide = !(screenSizeEnum[props.size] >= screenSizeEnum[props.hideWhenSmallerThan]);
  const shouldHideRail = isSmallEnoughToHide || get(props.readPageMode, 'pageElements.HIDE_LEFT_RAIL');
  const hideRail = shouldHideRail ? {display: 'none'} : {};
  const actionListHidden = get(props.readPageMode, 'pageElements.HIDE_ARTICLE_ACTION_LIST') ? styles.actionListHidden : null;

  return (
    <MuiThemeProvider muiTheme={mergedMuiTheme}>
      <aside style={hideRail} className={`${styles.sideMenu} ${railColor} ${props.className} ${actionListHidden}`} data-qa-selector="left_side_menu">
        {props.children}
      </aside>
    </MuiThemeProvider>
  );
};

LeftRail.propTypes = propTypes;
LeftRail.defaultProps = defaultProps;

const LeftRailWithSize = withWindowSize(LeftRail);
export {LeftRailWithSize as LeftRail};
