import {useState, useEffect} from 'react';

import {
  keyBy,
} from 'lodash-es';


/**
 * @param students
 * @param interactiveVideos
 * @returns {object[]}
 */
export const getQuizSubmissions = (students, interactiveVideos) => {
  const interactiveVideosById = keyBy(interactiveVideos, 'content_id');
  const [submissionsByContentId, setSubmissionsByContentIdData] = useState({});
  const filters = {
    interactive_video_ids: Object.keys(interactiveVideosById),
    submitted_by_users: students?.map(({user, unique_id}) => {
      return user?.id || unique_id;
    }),
  };
  const queryParams = new URLSearchParams(filters);
  const videoQuizSubmissionUrl = `/api/v3/interactive-video-link-quiz-submission/?${queryParams}`;

  useEffect(() => {
    fetch(videoQuizSubmissionUrl)
      .then((response) => response.json())
      .then((data) => setSubmissionsByContentIdData(data))
      .catch((error) => console.error(error));
  }, []);

  return submissionsByContentId;
};
