import APIFetcher from '../APIFetcher';

const textSetEndpoint = 'text-set';

class TextSetApi {
  static get(queryString = '') {
    let include_article_headers = true;
    if (queryString && (typeof (queryString) == 'object')) {
      include_article_headers = queryString.include_article_headers;
    }

    let params = {
      include_article_headers: include_article_headers,
    };

    params = APIFetcher.stringifyParams(params);
    if (queryString) {
      if (typeof (queryString) == 'object') {
        queryString = APIFetcher.stringifyParams(queryString);
      }
      params += `&${queryString}`;
    }
    return APIFetcher.get(`${textSetEndpoint}?${params}`);
  }

  static getById(id) {
    let params = {
      include_article_headers: true,
      include_images: true,
      include_child_text_sets: true,
      include_likes: true,
      maximum_depth: 2,
    };
    params = APIFetcher.stringifyParams(params);
    return APIFetcher.get(`${textSetEndpoint}/${id}/?${params}`);
  }

  static getUnit(unitId) {
    let params = {
      include_article_headers: true,
      include_child_text_sets: true,
      include_likes: true,
      maximum_depth: 2,
    };
    params = APIFetcher.stringifyParams(params);
    return APIFetcher.get(`${textSetEndpoint}/${unitId}/?${params}`);
  }

  static addArticle({
    parentTextSetId,
    childElementId,
    priority
  }) {
    // Add an article header to a text set.
    const payload = {
      text_set_id: parentTextSetId,
      display_priority: priority,
    };

    if (childElementId) {
      payload.article_header_id = childElementId;
    }

    return APIFetcher.post(`${textSetEndpoint}-article-header/`, {payload});
  }

  static update(textSet) {
    const payload = textSet;
    return APIFetcher.post(`${textSetEndpoint}/`, {payload});
  }

  static delete({parentTextSetId, childElementId}) {
    return APIFetcher.remove(`text-set/${parentTextSetId}/article/${childElementId}/`);
  }

  static post({payload}) {
    return APIFetcher.post(`${textSetEndpoint}/`, {payload});
  }
}

export default TextSetApi;
