exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__wrapper___50hIS{position:relative}.styles__hidden___fGQzS{display:none}.styles__left___JCg7h{right:-30px}.styles__left___JCg7h:after{right:27px}.styles__right___GKx0Q{left:-1px}.styles__right___GKx0Q:after{left:27px}.styles__middle___Xg4GT{left:-126px}.styles__middle___Xg4GT:after{left:50%}.styles__body___bl1nm{width:300px;padding:10px 15px;top:34px;background-color:#363636;color:#fff;position:absolute;z-index:100}.styles__body___bl1nm:after{bottom:100%;border:solid rgba(0,0,0,0);content:\" \";height:0;width:0;position:absolute;pointer-events:none}.styles__body___bl1nm:after{border-color:rgba(0,0,0,0);border-bottom-color:#363636;border-width:10px;margin-left:-12px}.styles__body___bl1nm a{text-decoration:none;color:#fff;border-bottom-width:1px;border-bottom-style:solid;border-color:#fff}.styles__icon___zZ1G4{font-size:18px;padding-left:15px;cursor:pointer;color:#1273fa}", ""]);

// exports
exports.locals = {
	"wrapper": "styles__wrapper___50hIS",
	"hidden": "styles__hidden___fGQzS",
	"left": "styles__left___JCg7h",
	"right": "styles__right___GKx0Q",
	"middle": "styles__middle___Xg4GT",
	"body": "styles__body___bl1nm",
	"icon": "styles__icon___zZ1G4"
};