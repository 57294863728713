import React, {useState} from 'react';

import {Anchor, ArrowLeftSVG, Button, CogHollowSVG, Icon} from '@newsela/angelou';
import {heapSignOut} from '@newsela/snitch';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {
  accountOptionAnchorCss, accountOptionButtonCss,
  accountOptionsContainerCss,
  accountOptionsTitleCss,
  accountOptionsUnorderedListCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/UserProfileMenu/UserProfilePopout/PopoutAccountOptions/PopoutAccountOptions.styles';
import {deleteSessionRequest} from 'static/three-oh/src/modules/actions/sessionActions';
import {getUserProperties} from 'static/three-oh/src/modules/selectors/userSelectors';


export const ACCOUNT_SETTINGS_URL = '/account/settings/';

const PopoutAccountOptions = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSignOutLoading, setIsSignOutLoading] = useState(false);

  const {isImpersonated} = useSelector((state) => getUserProperties(state));

  const signOut = () => {
    setIsSignOutLoading(true);

    if (isImpersonated) {
      history.push('/impersonate-stop');
      return;
    }

    heapSignOut();
    sessionStorage.removeItem('recentSearchTerms');
    dispatch(deleteSessionRequest());
    history.push('/signin');
  };

  return (
    <div className={accountOptionsContainerCss}>
      <div className={accountOptionsTitleCss}>Manage account</div>

      <ul className={accountOptionsUnorderedListCss}>
        <li>
          <Anchor href={ACCOUNT_SETTINGS_URL} __cssFor={accountOptionAnchorCss}>
            <Icon SvgComponent={CogHollowSVG} />
            Settings
          </Anchor>
        </li>

        <li>
          <Button
            onClick={signOut} __cssFor={accountOptionButtonCss}
            flavor={Button.flavors.incognito}
            disabled={isSignOutLoading}
          >
            <Icon SvgComponent={ArrowLeftSVG} />
            <>
              {isImpersonated && 'Stop impersonating'}
              {!isImpersonated && 'Sign out'}
            </>
          </Button>
        </li>
      </ul>
    </div>
  );
};

PopoutAccountOptions.displayName = 'PopoutAccountOptions';
export {PopoutAccountOptions};
