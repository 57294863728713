import {delay} from 'redux-saga';
import {takeLatest, call, put} from 'redux-saga/effects';

import {AssignmentApi} from 'static/three-oh/src/modules/apis';

import * as actions from './../actions/assignmentActions';
import * as types from './../constants/assignmentActionTypes';

function * getAssignmentsV3(action) {
  try {
    const response = yield call(AssignmentApi.getListV3, action.queryParams);
    yield put(actions.loadAssignmentsSuccessV3(response.data));
  } catch (error) {
    yield put(actions.loadAssignmentsFailure(error));
  }
}

/**
 This saga runs when a teacher launches Newsela external tool from Canvas.
 It polls assignments API every N seconds to display the most up-to-date
 list of assignments without forcing teacher to re-open the tool.
 */
function * pollAssignments(action, isPolling = false) {
  while (true) {
    try {
      if (!isPolling) {
        yield put(actions.initialAssignmentLoadingRequest());
        isPolling = true;
      }
      const response = yield call(AssignmentApi.getListV3, action.params);
      yield put(actions.loadAssignmentsSuccessV3(response.data));
    } catch (error) {
      yield put(actions.loadAssignmentsFailure(error));
    } finally {
      yield call(delay, action.delay || 5000);
      yield call(pollAssignments, action, isPolling);
    }
  }
}

export function * watchGetAssignmentsV3() {
  yield takeLatest(types.ASSIGNMENTS_LOAD_REQUEST_V3, getAssignmentsV3);
}

export function * watchStartAssignmentsPolling() {
  yield takeLatest(types.START_ASSIGNMENTS_POLLING, pollAssignments);
}
