import React from 'react';

import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {css, cx} from 'emotion';
import PropTypes from 'prop-types';

import {
  closeCssObject,
  leftSideCssObject,
  removeButtonStyleObject,
} from './styles';
import Message, {messageLevels, transitionDirections} from '../Message';


const messageLevelTypes = Object.values(messageLevels);
const transitionTypes = Object.values(transitionDirections);
const propTypes = {
  /**
   * text to be displayed
   */
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,

  /**
   * cta text displayed on the right side
   */
  ctaText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  /**
   * The level of urgency for the message.
   * this will control the color
   */
  level: PropTypes.oneOf(messageLevelTypes).isRequired,
  /**
   * action fired when the x is clicked
   */
  onClose: PropTypes.func,
  /**
   * onClick function to be called when you click the Call
   * to action
   */
  ctaOnClick: PropTypes.func.isRequired,
  /**
   * direction the component slides in.
   */
  transitionDirection: PropTypes.oneOf(transitionTypes),
  /**
   * classnames appended to the top level container
   */
  additionalClassnames: PropTypes.array,

  /**
   * hides the close button
   */
  hideCloseIcon: PropTypes.bool,

};

class MessageCta extends React.Component {
  /**
   * Create the Left side of the message
   */
  createLeftSide = () => {
    const {
      hideCloseIcon,
      text,
      onClose,
    } = this.props;
    const closeClass = cx(
      css(removeButtonStyleObject),
      css(closeCssObject)
    );
    return (
      <div
        className={css(leftSideCssObject)}
      >
        {
          !hideCloseIcon &&
            <button
              className={closeClass}
              onClick={() => {
                if (onClose) {
                  onClose();
                }
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          }
        {text}
      </div>
    );
  }

  /**
   * create the Cta portion of the message
   * recieves a className string and a css Obj from
   * the Message component's __renderRightSide. Uses
   * this information to set the padding on the cta
   * button
   */
  createCta = (className, cssObj) => {
    const {
      ctaText,
      ctaOnClick,
    } = this.props;
    const myClass = css({
      ...cssObj,
      paddingLeft: cssObj.marginLeft,
      borderLeft: ctaText ? '1px solid;' : null,
      flex: '0 0 auto',
    });
    return (
      <button
        className={cx(css(removeButtonStyleObject), css(myClass))}
        onClick={() => {
          if (ctaOnClick) {
            ctaOnClick();
          }
        }}
      >
        {ctaText}
      </button>
    );
  }

  /**
   * compose the <Message> component and render
   */
  render() {
    const {
      level,
      transitionDirection,
      additionalClassnames,
    } = this.props;
    return (
      <Message
        additionalClassnames={additionalClassnames}
        text={this.createLeftSide()}
        level={level}
        transitionDirection={transitionDirection}
        __renderRightSide={this.createCta}
      />
    );
  }
}

MessageCta.propTypes = propTypes;
MessageCta.defaultPropTypes = {
  additionalClassnames: [],
  hideCloseIcon: false,
  level: messageLevels.info,
};

export default MessageCta;
