import React, {useCallback, useRef, useState} from 'react';


import {
  ArticleEditSVG,
  BookmarkHollowSVG,
  Button,
  DetailsSVG,
  ErrorFilledSVG,
  Icon,
  Logo,
  Modal
} from '@newsela/angelou';
import {withTrackEvent, actionNames} from '@newsela/snitch';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {ReactComponent as CloseIcon} from 'static/images/icons/close.svg';
import {ReactComponent as NewselaPremiumExperienceSVG} from 'static/images/trials/NewselaPremiumExperience.svg';
import {PREMIUM_TRIAL_CAMPAIGN_SLUG} from 'static/three-oh/src/modules/constants/trialLicenseCampaigns';

import TrialCampaignApi from '../../modules/apis/TrialCampaign/TrialCampaignApi.js';

import {
  baseStyles,
  ctaButtonContainer,
  ctaButtonCss,
  closeButtonCss,
  errorContainer,
  errorIconCss,
  footerContainer,
  headerContainer,
  headingCss,
  heroSvgCss,
  iconCss,
  listCss,
  listItemCss,
  scrollableContainer,
  svgContainer,
  textContainer
} from './styles.jsx';

const ButtonWithTrackEvent = withTrackEvent(Button);

const LABEL_ID = 'trial-modal';

const useRefWithCallback = (onMount, onUnmount) => {
  const nodeRef = useRef(null);

  const setRef = useCallback((node) => {
    if (nodeRef.current) {
      onUnmount(nodeRef.current);
    }

    nodeRef.current = node;
    if (nodeRef.current) {
      onMount(nodeRef.current);
    }
  }, [onMount, onUnmount]);

  return setRef;
};

const trialModalProps = {
  ariaProps: PropTypes.object,
  closeModal: PropTypes.func,
  dataProps: PropTypes.objectOf(PropTypes.string),
  onBackgroundClick: PropTypes.func,
};

const TrialModal = (props) => {
  const {
    ariaProps,
    closeModal,
  } = props;

  const [hasError, setHasError] = useState(false);

  const scrollRef = useRefWithCallback(disableBodyScroll, enableBodyScroll);

  const screenSize = useSelector((state) => state.v2.ui?.screenSize?.size);

  const addUserToCampaignTrial = async() => {
    try {
      const response = await TrialCampaignApi.post({
        slug: PREMIUM_TRIAL_CAMPAIGN_SLUG
      });
      if (response.error) {
        setHasError(true);
      } else {
        closeModal();
        window.location.href = '/';
      }
    } catch {
      setHasError(true);
    }
  };

  const handleClick = () => {
    setHasError(false);
    addUserToCampaignTrial();
  };

  return (
    <Modal
      {...props}
      __cssFor={{
        root: {
          zIndex: 14
        }
      }}
      ariaProps={{
        'aria-labelledby': LABEL_ID,
        ...ariaProps
      }}
      onClick={closeModal}
    >
      <div className={baseStyles}>
        <div className={svgContainer}>
          <NewselaPremiumExperienceSVG aria-hidden className={heroSvgCss} />
        </div>
        <div className={textContainer}>
          <div className={headerContainer}>
            <Logo __overrideHeight="24px" ariaProps={{role: 'img'}} />
            <ButtonWithTrackEvent
              __cssFor={{root: closeButtonCss}}
              ariaProps={{'aria-label': 'close'}}
              flavor={Button.flavors.incognito}
              onClick={closeModal}
              actionName={actionNames.cancel}
              streamProperties={{
                description: 'Close trial modal',
                action_name: actionNames.cancel,
                component_type: 'Button',
                screen_size: screenSize,
              }}
            >
              <CloseIcon />
            </ButtonWithTrackEvent>
          </div>
          <div
            className={scrollableContainer}
            ref={scrollRef}
            tabIndex="0"
          >
            <h1 id={LABEL_ID} className={headingCss}>
              Unlock more of what{'\n'}
              Newsela has to offer
            </h1>
            <p>For <b>45 days</b> you'll have access to Newsela's 3 premium products for ELA, social studies, and science. </p>
            <ul role="list" className={listCss}>
              <li className={listItemCss}>
                <Icon
                  SvgComponent={DetailsSVG}
                  isDecorative
                  __cssFor={{root: iconCss}}
                />
                Access teaching resources like curations, unit guides, and graphic organizers.
              </li>
              <li className={listItemCss}>
                <Icon
                  SvgComponent={ArticleEditSVG}
                  isDecorative
                  __cssFor={{root: iconCss}}
                />
                Create more impactful assignments and see insights into student work.
              </li>
              <li className={listItemCss}>
                <Icon
                  SvgComponent={BookmarkHollowSVG}
                  isDecorative
                  __cssFor={{root: iconCss}}
                />
                Explore over 15,000 premium texts and videos with your students.
              </li>
            </ul>
            <p>Love it? You can request a trial for your entire school at any time—without ever sharing personal payment information.</p>
          </div>
          <div className={footerContainer}>
            <div className={ctaButtonContainer}>
              <ButtonWithTrackEvent
                __cssFor={{root: ctaButtonCss}}
                flavor={Button.flavors.primary}
                size={Button.sizes.lg}
                onClick={handleClick}
                actionName={actionNames.create}
                streamProperties={{
                  description: 'Add user to campaign trial',
                  action_name: actionNames.create,
                  component_type: 'Button',
                  screen_size: screenSize,
                }}
              >
                Activate trial
              </ButtonWithTrackEvent>
            </div>
            {
              hasError &&
                <div className={errorContainer} aria-live="polite">
                  <Icon
                    SvgComponent={ErrorFilledSVG}
                    isDecorative
                    __cssFor={{root: errorIconCss}}
                  />An error has occurred.
                </div>
            }
          </div>
        </div>
      </div>
    </Modal>
  );
};

TrialModal.propTypes = trialModalProps;

export default TrialModal;
