import React from 'react';

import PropTypes from 'prop-types';

import styles from './styles.scss';

const propTypes = {
  logo: PropTypes.node,
  logoClassNames: PropTypes.string,
  title: PropTypes.string.isRequired,
};

const UnfocusedCardDefaultLayout = (props) => {
  return (
    <div
      className={styles.contents}
      data-qa-selector="create_class_via_newsela_button"
    >
      <div className={styles.cardTitle}>{props.title}</div>
      {React.cloneElement(props.logo, {className: styles.cardLogo + ' ' + props.logoClassNames})}
    </div>
  );
};

UnfocusedCardDefaultLayout.propTypes = propTypes;
export default UnfocusedCardDefaultLayout;

