import React from 'react';

import classNames from 'classnames/bind';
import PropTypes from 'prop-types';



import withTrackEvent from 'static/three-oh/src/components/with/WithTrackEvent';
import TrackEventProps from 'static/three-oh/src/components/with/WithTrackEventConstants';
import forwardRefToDOMElement from 'static/three-oh/utils/forwardRefToDOMElement';
import {toSnakeCase} from 'static/three-oh/utils/stringUtils';

import AngelouProps from '../../propTypes/AngelouPropTypes';

import styles from './styles.scss';

const propTypes = {

  ...AngelouProps,
  /**
   * There are three
   * types of button: Standard,
   * Rounded, and Flat
   */
  standard: PropTypes.bool,
  rounded: PropTypes.bool,
  flat: PropTypes.bool,

  /**
  * A button of any type
  * can take on a primary style,
  * or a secondary style. A user
  * can also pass in a className
  * for additional customized
  * styles
  * */
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  danger: PropTypes.bool,
  selected: PropTypes.bool,
  className: PropTypes.string,

  /**
  * A button can have an icon,
  * a label, or both
  * */
  icon: PropTypes.node,
  label: PropTypes.node,
  labelPosition: PropTypes.oneOf(['before', 'after']),

  /**
  * Since Rounded buttons are most
  * appropriate for tools and auxiliary
  * actions, they support "toggleable"
  * functionality. IE: a "Save" button
  * in a toolbar can be toggled on or off.
  *
  * Specifying any of these props related to
  * toggling on a Standard button or Flat button
  * will have no effect.
  * */
  toggleable: PropTypes.bool,
  toggled: PropTypes.bool,
  onToggle: PropTypes.func,

  /**
  * We hijack onClick to maintain
  * on/off state if toggleable is true
  * */
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,

  /**
   * Props that are passed directly
   * to the HTML <button> element.
   */
  buttonElementProps: PropTypes.object,

  /**
  * Buttons may be disabled.
  * */
  disabled: PropTypes.bool,

  /**
   * dataQaSelector specific data-qa selector
   */
  dataQaSelector: PropTypes.string,

  /**
   * aria prop to be passed to the button
   */
  role: PropTypes.string,

  /**
   * aria prop passed to the button
   */
  ariaLabel: PropTypes.string,

  /**
   * aria expanded is passed to the button
   */
  ariaExpanded: PropTypes.bool,

  /**
   * Tracking event props passed from withTrackEvent HOC.
   */
  ...TrackEventProps,
};

const defaultProps = {
  labelPosition: 'after',
  forwardedRef: null,
};

const generateCSSClassName = classNames.bind(styles);

class Button extends React.Component {
  constructor(props) {
    super(props);
    this.getCSSClassForButtonType = this.getCSSClassForButtonType.bind(this);
    this.getCSSClassForButtonSubType = this.getCSSClassForButtonSubType.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.renderIcon = this.renderIcon.bind(this);
    this.renderLabel = this.renderLabel.bind(this);
    this.state = {
      toggled: this.props.toggled
    };
  }

  static getDerivedStateFromProps(props, state) {
    /**
     * If a button is toggleable, state.toggled
     * is switched back and forth in handleClick().
     * However, a user can override that by passing
     * in a prop to props.toggled.
     *
     * We need to make sure the internal
     * state.toggled falls back to props.toggled
     * when necessary.
     * */
    if (props.toggled != state.toggled) {
      return {toggled: props.toggled};
    }
    return null;
  }

  getCSSClassForButtonType() {
    if (this.props.flat) {
      return styles.flat;
    } else if (this.props.rounded) {
      return styles.rounded;
    } else {
      return styles.standard;
    }
  }

  getCSSClassForButtonSubType() {
    if (this.props.danger) {
      return styles.danger;
    } else if (this.props.selected) {
      return styles.selected;
    } else if (this.props.secondary) {
      return styles.secondary;
    } else {
      return styles.primary;
    }
  }

  handleClick() {
    const callBackWithToggleState = () => {
      if (this.props.onToggle) {
        this.props.onToggle(this.state.toggled);
      }
    };
    if (this.props.toggleable) {
      this.setState(
        (prevState) => ({toggled: !prevState.toggled}),
        callBackWithToggleState
      );
    }

    if (this.props.onClick) {
      this.props.onClick.apply(null, arguments);
    }
  }

  handleKeyDown = (event) => {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(event);
    }
  };

  renderIcon() {
    return this.props.icon &&
      <div key="icon" className={styles.icon}>{this.props.icon}</div>;
  }

  renderLabel() {
    return this.props.label &&
      <div key="label">{this.props.label}</div>;
  }

  render() {
    const {className: userClassName} = this.props;

    const type = this.getCSSClassForButtonType();
    const subType = this.getCSSClassForButtonSubType();

    const conditionalCSSClasses = {
      toggleable: this.props.toggleable,
      toggled: this.state.toggled,
      iconOnly: this.props.icon && !this.props.label,
    };

    const className = generateCSSClassName(
      styles.button,
      type,
      subType,
      conditionalCSSClasses,
      userClassName
    );

    const nodes = [this.renderIcon(), this.renderLabel()];

    const buttonContents = this.props.labelPosition === 'after' ?
      nodes : nodes.reverse();

    const additionalClassNames = this.props.additionalClassNames ?
      this.props.additionalClassNames.join(' ') : '';

    return (
      <button
        ref={this.props.forwardedRef}
        role={this.props.role}
        aria-label={this.props.ariaLabel || this.props.label || ''}
        className={`${className} ${additionalClassNames}`}
        disabled={this.props.disabled}
        onClick={this.handleClick}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        onKeyDown={this.handleKeyDown}
        {...this.props.buttonElementProps}
        data-qa-selector={toSnakeCase(this.props.dataQaSelector || this.props.label)}
        aria-expanded={this.props.ariaExpanded}
      >
        {buttonContents}
      </button>
    );
  }
}
Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

const ButtonWithTrackEvent = withTrackEvent(Button);
const ButtonWithRef = forwardRefToDOMElement(Button);
export {Button as default, ButtonWithTrackEvent, ButtonWithRef, withTrackEvent};
