import {camelCase, isNil, forEach, size, keyBy, chunk} from 'lodash-es';
import {takeEvery, call, put, fork} from 'redux-saga/effects';

import {genericModelActions as actions} from '../actions';
import {
  ConstructedResponseAnswerApi,
  SearchServiceApi,
  StudentQuizApi,
  StudentVocabularyActivityApi,
  StudentVocabularyAnswerApi,
} from '../apis';
import {genericModelActionTypes as types} from '../constants';

/**
 * The `apiModelMapping` lookup table specifies how to build API calls to fetch models.
 *
 * The requested PKs received by the saga are first converted into a series of query string arguments
 * named after the `requestPrimaryKeyParameter` property (default: 'id').
 *
 *    action = {type:'FETCH_MODELS_BY_PK', payload: {modelName: 'fooModel', primaryKeys: [10,20,30]}}
 *    apiModelMapping.fooModel.requestPrimaryKeyParameter = 'foo_id';
 *
 *    > ?foo_id=10&foo_id=20&foo_id=30
 *
 * If an optional `extraGetQueryParameters` is specified, it will supplement the query string.
 *
 *    apiModelMapping.fooModel.extraGetQueryParameters = 'show_archived=1';
 *
 *    > ?foo_id=10&foo_id=20&foo_id=30&show_archived=1
 *
 * The query string is fed through the models `getMethod` which is expected to return a JSON array of objects.
 *
 *    apiModelMapping.fooModel.getMethod = FooApi.get;
 *
 *    > FooApi.get("foo_id=10&foo_id=20&foo_id=30&show_archived=1")
 *    > [{"GUID": 10, "value": "foo1"}, {"GUID": 20, "value": "foo2"}, {"GUID": 30, "value": "foo3"}]
 *
 * The response is converted to an object composed of keys matching the `responsePrimaryKeyProperty` (default: 'id')
 * and placed on the the redux state under the `models` key.
 *
 *    apiModelMapping.fooModel.responsePrimaryKeyProperty = 'GUID';
 *
 *    > state.models.fooModel = {
 *    >   10: {"GUID": 10, "value": "foo1"}},
 *    >   20: {"GUID": 20, "value": "foo2"},
 *    >   30: {"GUID": 30, "value": "foo3"}
 *    > }
 *
 * */

const apiModelMapping = {
  articleHeaderTile: {
    getMethod: SearchServiceApi.get,
    requestPrimaryKeyParameter: 'object_ids',
    responsePrimaryKeyProperty: 'header_id',
    extraGetQueryParameters: 'page_size=50'
  },
  constructedResponseAnswer: {
    getMethod: ConstructedResponseAnswerApi.get,
  },
  studentQuiz: {
    getMethod: StudentQuizApi.get
  },
  studentVocabularyAnswer: {
    getMethod: StudentVocabularyAnswerApi.get
  },
  studentVocabularyActivity: {
    getMethod: StudentVocabularyActivityApi.get,
    delimiter: ',',
    requestPrimaryKeyParameter: 'ids'
  },
};

function * watchGetModelsByPrimaryKeys() {
  yield takeEvery(types.FETCH_MODELS_BY_PK, function * (action) {
    const {payload} = action;
    const modelName = camelCase(payload.modelName);
    const primaryKeys = payload.primaryKeys;

    if (isNil(modelName) || isNil(primaryKeys)) {
      return;
    }

    const modelGetMethod = apiModelMapping[modelName].getMethod;
    const modelQueryParameter = apiModelMapping[modelName].requestPrimaryKeyParameter || 'id';
    const modelPrimaryKey = apiModelMapping[modelName].responsePrimaryKeyProperty || 'id';
    const modelExtraQueryParameters = apiModelMapping[modelName].extraGetQueryParameters || '';
    const modelDelimiter = apiModelMapping[modelName].delimiter;

    function * getModelChunk(primaryKeyChunk) {
      const queryParams = new URLSearchParams(modelExtraQueryParameters);
      if (modelDelimiter) {
        queryParams.append(modelQueryParameter, primaryKeyChunk.join(modelDelimiter));
      } else {
        forEach(primaryKeyChunk, (pk) => {
          queryParams.append(modelQueryParameter, pk);
        });
      }

      try {
        const models = yield call(modelGetMethod, queryParams.toString());
        if (size(models)) {
          const modelsByPrimaryKey = keyBy(models, modelPrimaryKey);
          yield put(actions.storeModels({modelName, items: modelsByPrimaryKey}));
        }
      } catch (error) {
        // TODO: retry?
      }
    }

    // Request models fifty at a time in parallel.
    for (const eachChunk of chunk(primaryKeys, 50)) {
      yield fork(getModelChunk, eachChunk);
    }
  });
}


export {watchGetModelsByPrimaryKeys};
