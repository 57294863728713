import React from 'react';

import classNames from 'classnames/bind';
import {cx, css} from 'emotion';
import {get} from 'lodash-es';
import PropTypes from 'prop-types';

import SCREENSIZE, {screenSizeEnum} from '../../constants/ScreenSize';
import AngelouProps from '../../propTypes/AngelouPropTypes';
import withWindowSize from '../with/WithWindowSize';

import styles from './styles.scss';

const generateCSSClassName = classNames.bind(styles);

const propTypesBar = {
  ...AngelouProps,
  /**
   * The label to display within the bar.
   */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  /**
   * Hide the label when the screen is smaller than given size.
   */
  hideLabelAtSize: PropTypes.string,

  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),

  dataQaSelector: PropTypes.string,

  /**
   * the breakpoint where the primary controls will break from the
   * rest of the toolbar.
   *
   * A value of -1 means the primary controls will not break from
   * the rest of the toolbar.
   */
  detachBelowSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  // TODO: Sticky prop
  // TODO: Enable light/dark style choice prop.

  bottomBorder: PropTypes.bool,
  centered: PropTypes.bool,
  additionalClassNames: PropTypes.arrayOf(PropTypes.string)
};

/**
 * @deprecated We will be moving away from using this components.
 * Instead, use the ContentLayout component and pass in your actions.
 *
 *
 * The Toolbar has primary and secondary zones for displaying
 * child components. The zones behave differently according
 * to screen size. It expects to have a single child of each kind.
 */
class Bar extends React.Component {
  renderLabel() {
    const hideLabelAtSize = get(this.props, 'hideLabelAtSize');
    const hideLabel = hideLabelAtSize ? screenSizeEnum[this.props.size] > screenSizeEnum[this.props.hideLabelAtSize] : false;

    if (this.props.label && !hideLabel) {
      return (
        <div className={styles.label}>{this.props.label}</div>
      );
    }
  }

  render() {
    const currentSize = screenSizeEnum[this.props.size];
    const detachedValue = screenSizeEnum[this.props.detachBelowSize];

    const barClasses = {
      bar: true,
      barBorder: this.props.bottomBorder,
      barLargeScreen: this.props.size === SCREENSIZE.large,
      barMidSizeScreens: this.props.size === SCREENSIZE.small || SCREENSIZE.medium,
      barWithOutPrimary: currentSize <= detachedValue && this.props.detachBelowSize !== -1,
    };

    const className = classNames(generateCSSClassName(
      barClasses,
    ), ...this.props.additionalClassNames);

    const children = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, {detachBelowSize: this.props.detachBelowSize}));

    return (
      <div
        className={cx(className, css`${this.props.centered ? 'justify-content: center;' : ''}`)}
        data-qa-selector={this.props.dataQaSelector || 'toolbar'}
      >
        {this.renderLabel()}
        {children}
      </div>
    );
  }
}


const propTypesPrimaryControls = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  size: PropTypes.string,
  detachBelowSize: PropTypes.string
};

class PrimaryControls extends React.Component {
  render() {
    const currentSize = screenSizeEnum[this.props.size];
    const detachedValue = screenSizeEnum[this.props.detachBelowSize];
    const primaryControlClasses = {
      primaryControlsAttached: currentSize > detachedValue,
      primaryControlsIndependent: currentSize <= detachedValue,
    };

    const className = generateCSSClassName(
      primaryControlClasses,
    );

    return (
      <div className={className}>
        {React.Children.map(this.props.children, (child, i) => {
          return (
            <div className={styles.primaryControlElement} key={`primary-${i}`}>
              {child}
            </div>
          );
        })}
      </div>
    );
  }
}


const propTypesSecondaryControls = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
};

class SecondaryControls extends React.Component {
  render() {
    return (
      <div
        className={styles.secondaryControls}
        data-qa-selector="secondary_controls"
      >
        {this.props.children}
      </div>
    );
  }
}

Bar.propTypes = propTypesBar;
PrimaryControls.propTypes = propTypesPrimaryControls;
SecondaryControls.propTypes = propTypesSecondaryControls;

PrimaryControls.defaultProps = {
  detachBelowSize: SCREENSIZE.xsmall,
};

Bar.defaultProps = {
  additionalClassNames: [],
  detachBelowSize: SCREENSIZE.xsmall,
  bottomBorder: true,
};

const barWithSize = withWindowSize(Bar);
const primaryWithSize = withWindowSize(PrimaryControls);

export {
  barWithSize as Bar,
  primaryWithSize as PrimaryControls,
  SecondaryControls,
};
