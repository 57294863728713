import {forEach, get, find} from 'lodash-es';

export const HEADER_CONTENT_TYPE = 'articleheader';
export const TEXT_SET_CONTENT_TYPE = 'textset';
export const STANDARD_CONTENT_TYPE = 'standard';
export const VIDEO_CONTENT_TYPE = 'video';
export const AUDIO_CONTENT_TYPE = 'audiotrack';
export const EXTERNAL_LINK_CONTENT_TYPE = 'externallink';

export const enrichContentWithAContentType = (objects = [], content_type) => {
  forEach(objects, (object) => {
    object.content_type = content_type;
  });
};

export const enrichContentWithADateToOrderByProperty = (objects = [], date_to_order_by) => {
  forEach(objects, (object) => {
    object.date_to_order_by = object[date_to_order_by];
  });
};

export const enrichBookmarkedContentWithADateBookmarkedProperty = (objects = [], contentLikes) => {
  forEach(objects, (object) => {
    const bookmarkedContentObject = find(contentLikes, ['content_id', object.content_id]);
    object.date_bookmarked = get(bookmarkedContentObject, 'date_created');
  });
};
