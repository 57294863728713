import React from 'react';

import {css} from 'emotion';
import PropTypes from 'prop-types';

import {
  mobileFullWidthCss,
  sectionContainerCss,
  sectionTitleCss,
  unorderedListCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/PopoutMenuSection/PopoutMenuSection.styles';

const propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
};

const PopoutMenuSection = ({title, children, width}) => {
  return (
    <div className={css({...sectionContainerCss, width, ...mobileFullWidthCss})} data-testid="popout-menu-section">
      {title && <div className={sectionTitleCss}>{title}</div>}

      <ul className={unorderedListCss}>
        {children}
      </ul>
    </div>
  );
};

PopoutMenuSection.propTypes = propTypes;
PopoutMenuSection.displayName = 'PopoutMenuSection';
export {PopoutMenuSection};
