import React from 'react';

import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import forwardRefToDOMElement from 'static/three-oh/utils/forwardRefToDOMElement';


import styles from './styles.scss';

const defaultProps = {
  type: 'text',
  onBlur: () => null
};

const propTypes = {

  /*
  * An input field can be disabled.
  */
  disabled: PropTypes.bool,

  forwardedRef: PropTypes.object,

  /*
  * An input field can be styled by passing in a className.
  */
  className: PropTypes.string,

  /*
  * An input field can have a placeholder, which will display if there's
  * no text in the input field.
  */
  placeholder: PropTypes.string,

  /*
  * An input field must have a label and a name.
  */
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,

  /*
  * An input field can have an ID.
  */
  id: PropTypes.string,

  /*
  * HTML attribute used to select DOM element for integration tests.
  */
  qaSelector: PropTypes.string,


  /*
  * We need to be able to let consuming components react to changes in this
  * component's state; therefore, let's expose properties for the input
  * field value, onBlur and onChange.
  */
  onChange: PropTypes.func,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  errorMessage: PropTypes.string,
  /*
  * A parameter that would allow us to obfuscate passwords inputs
  */
  type: PropTypes.string,
  inputAdornment: PropTypes.node,
  errorMessageAriaProps: PropTypes.object,
};

const generateCSSClassName = classNames.bind(styles);


class UNSTABLE_InputField extends React.PureComponent {
  getClassName = () => {
    return generateCSSClassName(
      styles.inputField,
      this.props.className,
    );
  };

  onChange = (event) => {
    this.props.onChange(event);
  }

  onBlur = (event) => {
    this.props.onBlur(event);
  }

  renderError = () => {
    return (
      <div
        className={`${styles.errorAlert} ${styles.singleLineErrorAlert}`}
      >
        {this.props.errorMessage}
      </div>
    );
  }

  render() {
    const {
      id,
      name,
      qaSelector,
      placeholder,
      value,
      type,
      errorMessage,
      forwardedRef,
      inputAdornment,
      errorMessageAriaProps,
    } = this.props;
    const classname = this.getClassName();
    return (
      <div
        className={styles.container}
      >
        <div
          className={styles.inputWrapper}
        >
          <input
            data-qa-selector={qaSelector}
            className={classname}
            id={id}
            name={name}
            onChange={this.onChange}
            placeholder={placeholder}
            onBlur={this.onBlur}
            value={value}
            type={type}
            ref={forwardedRef}
          />
          {inputAdornment}
        </div>
        <span {...errorMessageAriaProps}>
          {
            errorMessage &&
            this.renderError()
          }
        </span>
      </div>
    );
  }
}

UNSTABLE_InputField.propTypes = propTypes;
UNSTABLE_InputField.defaultProps = defaultProps;
export default forwardRefToDOMElement(UNSTABLE_InputField);
