import initialState from './initialState';
import {signUpActionTypes as types} from '../constants';

function signUpStatus(state = initialState.signUpStatus, action) {
  switch (action.type) {
    case types.SIGN_UP_CREATE_USER_REQUEST:
      return {...state, isError: false, isLoading: true, isSuccess: false};

    case types.SIGN_UP_CREATE_EMAIL_REQUEST:
      return {...state, isError: false, isLoading: true, isSuccess: false};

    case types.SIGN_UP_CREATE_ROLE_CREATION_REQUEST:
      return {...state, isError: false, isLoading: true, isRoleCreationInProgress: true, isRoleCreationSuccess: false};

    case types.SIGN_UP_CREATE_UPDATE_USER_REQUEST:
      return {...state, isError: false, isLoading: true, isSuccess: false};

    case types.SIGN_UP_LOAD_ROLE_CREATION_SUCCESS:
      return {...state, isError: false, isLoading: false, isRoleCreationInProgress: false, isRoleCreationSuccess: true};

    case types.SIGN_UP_LOAD_SUCCESS:
      return {...state, isError: false, isLoading: false, isSuccess: true};

    case types.SIGN_UP_LOAD_FAILURE:
      return {...state, isError: true, isLoading: false, isSuccess: false};

    default:
      return state;
  }
}

export default signUpStatus;
