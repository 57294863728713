import {css} from 'emotion';

export const grid = css`
  display: grid;
  height: 100%;
  width: auto;
`;

export const cell = (xPos = 'center', yPos = 'center') => css`
  background-size: cover;
  background-position: ${xPos} ${yPos};
`;

export default {
  grid,
  cell
};
