import * as types from 'modulesV2/constants';

const initialState = {
  context: {},
};

function context(state = initialState, action) {
  switch (action.type) {
    case types.SET_READ_PAGE_CONTEXT:
      return action.context;
    default:
      return state;
  }
}

export default context;
