import React, {useState, useMemo} from 'react';

import {Button, ChevronDownSVG, ChevronUpSVG, Icon} from '@newsela/angelou';
import {useSelector} from 'react-redux';

import {
  getContentLibraryButtonCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/ResourceMenus/ContentLibraryMenu/ContentLibraryMenu.styles';
import {
  ContentLibraryPopout
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/ResourceMenus/ContentLibraryMenu/ContentLibraryPopout';
import {
  NavButtonWithPopout
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/NavButtonWithPopout/NavButtonWithPopout';

import {getUserProperties} from '../../../../../../modules/selectors/userSelectors';
import {getNavMenuButtonAriaProps} from '../../shared/NavButtonWithPopout/NavButtonWithPopout.utils';

const MENU_ID = 'nav-content-library-menu';

const ContentLibraryMenu = () => {
  const {
    isStudent,
  } = useSelector((state) => getUserProperties(state));

  const [isOpen, setIsOpen] = useState(false);

  const buttonAriaProps = useMemo(
    () => getNavMenuButtonAriaProps({
      isOpen,
      id: MENU_ID,
    }), [isOpen]);


  const onClickButton = () => setIsOpen((isOpen) => !isOpen);

  const buttonText = isStudent ?
    'Library' :
    'Content library';

  return (
    <NavButtonWithPopout
      id={MENU_ID}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      position={{top: '4rem', left: 0}}
      rootCustomCss={{display: 'flex'}}
      ButtonComponent={(
        <Button
          onClick={onClickButton}
          ariaProps={buttonAriaProps}
          flavor={Button.flavors.incognito}
          __cssFor={getContentLibraryButtonCss(isOpen, isStudent)}
          dataProps={{'data-qa-selector': 'global-nav-content-library-menu'}}
        >
          {buttonText}

          {isOpen ? <Icon SvgComponent={ChevronUpSVG} /> : <Icon SvgComponent={ChevronDownSVG} />}
        </Button>
      )}
      PopoutComponent={<ContentLibraryPopout />}
    />
  );
};

ContentLibraryMenu.displayName = 'ContentLibraryMenu';
export {ContentLibraryMenu};
