import {
  watchInitialization,
  watchPostStudentQuiz,
  watchPostStudentWriteResponse,
  watchSaveWriteReview,
} from './readSagas';

const readSagas = [
  watchInitialization,
  watchPostStudentQuiz,
  watchPostStudentWriteResponse,
  watchSaveWriteReview,
];

export default readSagas;
