import {constants, typographyStyles} from '@newsela/angelou';
import {css} from 'emotion';

export const popoutContainerCss = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  width: '20rem',
  padding: '1rem 0.5rem 1.25rem 0.5rem',
  borderRadius: '0.5rem',
  background: constants.colors.ui.white[50],
  boxShadow: '0px 2px 6px 0px rgba(29, 29, 29, 0.16)',
});

export const supportTitleCss = css({
  ...typographyStyles.fontFamily.circular,
  ...typographyStyles.fontSize.textM,
  marginLeft: '0.5rem',
  fontWeight: 700,
  lineHeight: '1.5rem',
  color: constants.colors.ui.neutral[700],
});

export const supportCenterUnorderedListCss = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',

  li: {
    height: '2rem',
  },
});
