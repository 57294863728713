import {keyBy} from 'lodash-es';

import * as types from './../constants/assignmentActionTypes';

const initialState = {
  assignments: {
    dateRequested: null,
    dateLoaded: null,
    isLoading: false,
    items: {},
  },
};

function assignments(state = initialState.assignments, action) {
  switch (action.type) {
    // Why do we need a special hading for the very first assignments request?
    // It lets us gracefully handle that initial assignments polling request
    // from Canvas external tool without transition into loading state for all
    // the subsequent requests, so we can avoid displaying <Loading> spinner
    // every N seconds between the assignment polls.
    case types.INITIAL_ASSIGNMENT_LOADING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.ASSIGNMENTS_LOAD_REQUEST:
      return {
        ...state,
        dateRequested: Date.now(),
        isLoading: !action.isPolling,
        dateLoaded: null
      };

    case types.ASSIGNMENTS_LOAD_SUCCESS:
      return {
        ...state,
        dateLoaded: Date.now(),
        isLoading: false,
        items: {
          ...action.assignments[0],
          ...keyBy(action.assignments, 'id')
        },
      };

    case types.ASSIGNMENTS_LOAD_SUCCESS_V3:
      return {
        ...state,
        dateLoaded: Date.now(),
        isLoading: false,
        itemsV3: {
          ...action.assignments
        },
      };

    case types.ASSIGNMENTS_LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };

    default:
      return state;
  }
}

export default assignments;
