import React from 'react';

import {get, replace, toUpper} from 'lodash-es';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const propTypes = {
  article: PropTypes.object.isRequired,
  style: PropTypes.object,
};

const defaultProps = {
  style: {},
};

const CategoryIndicator = (props) => {
  let slug;
  let displayName;
  const displayCategory = props.article.category;
  if (typeof displayCategory == 'object' && displayCategory != null) {
    // Data is from the Article Header API.
    slug = get(props.article, 'primary_category.slug');
    displayName = get(props.article, 'primary_category.short_name');
  } else {
    // Data is from the Legacy Search API, in the header tile shape.
    if (displayCategory) {
      // This is a news article.
      slug = displayCategory;
      displayName = displayCategory;
    } else {
      // This is a library article.
      slug = get(props.article, 'display_subject');
      displayName = get(props.article, 'display_short_subject', 'ARTICLE');
    }
  }
  displayName = replace(displayName, 'and', '&');
  displayName = replace(displayName, '-', ' ');
  displayName = toUpper(displayName);
  return (
    <div
      className={styles.category + ' ' + styles[slug]}
      style={props.style}
    >
      {displayName}
    </div>
  );
};

CategoryIndicator.propTypes = propTypes;
CategoryIndicator.defaultProps = defaultProps;

export default CategoryIndicator;
