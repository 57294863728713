import React from 'react';

import {LoadingSpinner} from '@newsela/angelou';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';


const LazyDistrictReport = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "District-Report" */
      './DistrictReport'
    ),
  loading: LoadingSpinner,
});

const LazyBulkProcessingStatus = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Bulk-Processing-Status" */
      './BulkProcessingStatus'
    ),
  loading: LoadingSpinner,
});

const DashRoutes = [
  <Route
    key="District-Report"
    exact path={['/dash/district-report']}
    component={LazyDistrictReport}
  />,
  <Route
    key="Bulk-Processing-Status"
    exact path={['/dash/bulk-status']}
    component={LazyBulkProcessingStatus}
  />,
];

export default DashRoutes;
