import React, {useState} from 'react';

import {useSelector} from 'react-redux';

import {
  NavButtonWithPopout
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/NavButtonWithPopout';
import {
  UserProfileButton
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/UserProfileMenu/UserProfileButton';
import {
  UserProfilePopout
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/UserProfileMenu/UserProfilePopout';
import {
  UserProfileUnauthenticatedItems
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/UserProfileMenu/UserProfileUnauthenticatedItems';

import {getUser} from '../../../../../modules/selectors/userSelectors';

export const USER_PROFILE_MENU_ID = 'nav-user-profile-menu';

const UserProfileMenu = () => {
  const user = useSelector((state) => getUser(state));
  const [isOpen, setIsOpen] = useState(false);

  if (!user?.is_authenticated) return <UserProfileUnauthenticatedItems />;

  return (
    <NavButtonWithPopout
      id="nav-user-profile-menu"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      position={{top: '3.4rem', right: '0rem'}}
      ButtonComponent={<UserProfileButton isOpen={isOpen} setIsOpen={setIsOpen} />}
      PopoutComponent={<UserProfilePopout />}
    />
  );
};

UserProfileMenu.displayName = 'UserProfile';
export {UserProfileMenu};
