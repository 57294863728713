import {keyBy, forEach, keys} from 'lodash-es';

import * as types from 'modulesV2/constants/';

const initialState = {
  // byId holds a map of emails mapped by email ID.
  byId: {},
  // Latest API error
  error: null,
  // isLoading is true is there any unfinished API calls in progress
  isLoading: false,
};

function userEmails(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_USER_EMAILS_SUCCESS:
      return {
        byId: {
          ...state.byId,
          ...keyBy(action.data, 'id'),
        },
        error: null,
        isLoading: false,
      };
    case types.UPDATE_EMAIL_SUCCESS:
      return {
        byId: {
          ...state.byId,
          [action.data.id]: {
            ...state.byId[action.data.id],
            ...action.data,
          },
        },
        error: null,
        isLoading: false,
      };
    case types.CREATE_EMAIL_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.id]: action.data,
        },
        error: null,
        isLoading: false,
      };
    case types.FETCH_USER_EMAILS_ERROR:
    case types.UPDATE_EMAIL_ERROR:
    case types.CREATE_EMAIL_ERROR:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case types.UPDATE_EMAILS_IN_STORE_ONLY: {
      /**
       * handles multi-email action. Action example
       * {
       *   type: types.UPDATE_EMAILS_IN_STORE_ONLY,
       *   data: {
       *     id_1: {is_primary: true}
       *     id_2: {is_primary: false}
       *   }
       * }
       */
      const newState = {...state};
      forEach(keys(action.data), (id) => {
        newState.byId[id] = {
          ...newState.byId[id],
          ...action.data[id],
        };
      });
      return newState;
    }
    default:
      return state;
  }
}

export default userEmails;
