/* eslint-disable */
import React from 'react';

import {Icon, SearchSVG} from '@newsela/angelou';
import PropTypes from 'prop-types';

import {
  expandedSearchBarVisibilityCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SearchMenu/ExpandedSearchInput/ExpandedSearchInput.styles';
import {
  absoluteSearchIconCss,
  inputFieldCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SearchMenu/SearchMenu.styles';
import {SEARCH_MENU_ID} from '../SearchMenu';

const propTypes = {
  inputValue: PropTypes.string.isRequired,
  onFocus: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
};

const ExpandedSearchInput = ({inputValue, onFocus, onChange, onKeyDown}) => {
  return (
    <div className={expandedSearchBarVisibilityCss}>
      <Icon SvgComponent={SearchSVG} __cssFor={absoluteSearchIconCss} />
      <input
        tabIndex={0}
        data-testid="expanded-search-input"
        data-qa-selector="global-nav-search"
        autoComplete="off"
        value={inputValue}
        onFocus={onFocus}
        onClick={onFocus}
        onChange={onChange}
        onKeyDown={onKeyDown}
        className={`nav-search-suggestions ${inputFieldCss} ${SEARCH_MENU_ID}`}
        placeholder="Search for texts and more"
      />
    </div>
  );
};

ExpandedSearchInput.propTypes = propTypes;
ExpandedSearchInput.displayName = 'ExpandedSearchInput';
export {ExpandedSearchInput};
