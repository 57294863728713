import * as types from 'modulesV2/constants';

// toggle answer display when teacher is in presentation mode.
export function setRevealAnswer(isRevealAnswer) {
  return {type: types.SET_REVEAL_ANSWER, isRevealAnswer};
}

// power words have an id and a selectedAnswerId
// quiz is an object where each key is the question id
export function addActiveArticleActivity({articleId, quizQuestions = {}, writePrompt, powerWords = []}) {
  return {
    type: types.ADD_ACTIVE_ARTICLE_ACTIVITY,
    articleId,
    quizQuestions,
    writePrompt,
    powerWords,
  };
}

export function updateActiveArticleQuiz({articleId, quizQuestion}) {
  return {
    type: types.UPDATE_ACTIVE_ARTICLE_QUIZ,
    articleId,
    quizQuestion,
  };
}
