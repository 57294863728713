import React, {useEffect, useState} from 'react';

import {Anchor, Table as AngelouTable, BradburyBadge, Image} from '@newsela/angelou';
import {
  filter,
  find,
  first,
  forEach,
  get,
  groupBy,
  head,
  includes,
  isUndefined,
  map,
  reject,
  uniq,
  upperFirst
} from 'lodash-es';
import PropTypes from 'prop-types';

import {lyceumOrigin} from 'static/three-oh/src/constants/lyceum';
import {getAuxImage} from 'static/three-oh/src/containers/MultiCardContainer/ExternalLink_aux.jsx';
import {hasAssignmentGroups} from 'static/three-oh/src/modules/utils/assignmentHelpers';
import {isObjectArchived} from 'static/three-oh/src/modules/utils/dateHelpers';
import {appendGradingModeParams, buildGradingPageUrlForAssignmentTable} from 'static/three-oh/src/routes/Read/components/GradingMode/utils';
import {shapeImgixParams} from 'static/three-oh/utils/shapeImgixParams';
import {buildStudentActivityReviewUrl} from 'utils/buildStudentActivityReviewUrl';
import {getIdKey} from 'utils/getIdKey';
import {getLastNameFirstName} from 'utils/studentHelpers';

import styles from './styles';

const propTypes = {
  assignment: PropTypes.object,
  articleHeader: PropTypes.object,
  articleHeaders: PropTypes.array,
  assignmentGroup: PropTypes.object,
  checksForUnderstandingSubmissions: PropTypes.array,
  classroom: PropTypes.object,
  hasChecksForUnderstandingColumn: PropTypes.bool,
  headerHasPowerWords: PropTypes.bool,
  measures: PropTypes.array,
  notifications: PropTypes.array,
  students: PropTypes.array,
  userHasPowerWordsLicense: PropTypes.bool,
  user: PropTypes.object,
  impersonatedUser: PropTypes.object,
  isInReviewMode: PropTypes.object,
  isGradingPageEnabled: PropTypes.bool
};

const districtAdvisoryBadge = () => {
  return (
    <div className={styles.advisoryBadge}>
      <BradburyBadge dataProps={{'data-qa-selector': 'cat_label'}}/>
    </div>
  );
};

export const getCanvasUrl = (isInReviewMode, articleHeader) => {
  // This branch is only executed inside Canvas Speed Grader, which cannot load View page yet.
  // We have to manually build a read page URL for the article until the view page
  // can be loaded in an iframe from Speed Grader.
  if (isInReviewMode) {
    // When we don't have a valid article header to build a URL from,
    // it's better not to build any URL to avoid sending teachers to non-existent pages.
    if (!articleHeader || !articleHeader?.articles) return null;
    const articleLevel = articleHeader.articles[0].id;
    return `read/${articleHeader.slug}/id/${articleLevel}/?preventRedirect=true`;
  }
  return articleHeader?.url;
};

export const getChecksForUnderstandingMeasure = (
  checksForUnderstandingSubmissions = [],
  currentUserId,
  articleContentId,
) => {
  if (!currentUserId || !articleContentId) return '-';

  // Checks whether or not the student has a Checks for Understanding submission for the current level
  const hasSubmissions = checksForUnderstandingSubmissions.some(
    (submission) =>
      currentUserId === submission.user_id &&
      submission?.submissions.some(
        (attempt) => attempt.article_level_id === articleContentId
      )
  );
  return hasSubmissions ? 'View CFU' : '-';
};

const AssignmentReviewTable = (props) => {
  const {
    articleHeader,
    articleHeaders,
    checksForUnderstandingSubmissions,
    hasChecksForUnderstandingColumn,
    headerHasPowerWords,
    measures,
    notifications,
    students,
    userHasPowerWordsLicense,
    assignment,
    assignmentGroup,
    classroom,
    impersonatedUser,
    user,
    isInReviewMode,
    isGradingPageEnabled
  } = props;

  const [displayArchived, setDisplayArchived] = useState(null);
  const [studentIdKey, setStudentIdKey] = useState('id');

  useEffect(() => {
    const isSmallGroupAssignment = hasAssignmentGroups(assignment?.assignment_groups);

    setStudentIdKey(getIdKey(isSmallGroupAssignment));
  }, []);

  useEffect(() => {
    setDisplayArchived(Boolean(assignment) && isObjectArchived(classroom));
  }, [assignment, classroom]);

  const getFirstNotificationValues = (notifications) => {
    const firstNotification = head(notifications);
    const articleHeaderSlug = get(firstNotification, 'data.header.slug');
    const articleId = get(firstNotification, 'data.article.id');
    const articleContentId = get(firstNotification, 'data.article.content_id');
    const studentIntegerId = get(firstNotification, 'data.student.id');
    const studentUniqueId = get(firstNotification, 'data.student.unique_id');


    return {
      articleContentId,
      articleHeaderSlug,
      articleId,
      studentIntegerId,
      studentUniqueId,
    };
  };

  const getStudentCellUrl = (notifications) => {
    const {
      articleHeaderSlug,
      articleId,
      studentIntegerId,
      studentUniqueId,
    } = getFirstNotificationValues(notifications);
    const assignmentContentId = assignment.content_id;
    const assignmentId = assignment?.assignment_id;
    const assignmentGroupId = assignmentGroup?.group_id;
    const classroomId = classroom?.id;
    const assignmentUserId = (impersonatedUser) ? impersonatedUser.id : user.id;

    return buildStudentActivityReviewUrl({
      articleHeaderSlug,
      articleId,
      studentIntegerId,
      studentUniqueId,
      assignmentContentId,
      assignmentGroupId,
      assignmentId,
      classroomId,
      assignmentUserId
    });
  };

  const getRelevantNotifications = (measure, notifications) => {
    // Returns only notifications relevant to the specified measure,
    return (typeof measure.isRelevantNotification !== 'function'
      ? notifications
      : notifications.filter(measure.isRelevantNotification)
    );
  };

  const isInactiveSgaStudent = (studentUniqueId) => {
    return Boolean(assignmentGroup?.inactive_users?.find(({unique_id}) => unique_id === studentUniqueId));
  };



  const renderActivityCell = (cell, measure) => {
    const notifications = get(cell, 'row.original.notifications');
    const studentId = notifications?.[0].data.user.student.unique_id;

    if (isUndefined(notifications) && measure?.type !== 'checks-for-understanding-activity') { return measure?.dataTableValue([notifications]); }
    const relevantNotifications = getRelevantNotifications(measure, notifications);

    const {
      articleContentId,
      articleHeaderSlug,
      articleId,
      studentIntegerId,
      studentUniqueId
    } = getFirstNotificationValues(notifications);

    let measureValue = '-';

    switch (measure.type) {
      case 'write-activity':
        measureValue = measure.dataTableValue(
          relevantNotifications,
          assignment.content_id
        );
        break;
      case 'checks-for-understanding-activity':
        measureValue = getChecksForUnderstandingMeasure(
          checksForUnderstandingSubmissions,
          studentId,
          articleContentId,
        );
        break;
      default:
        measureValue = measure.dataTableValue(relevantNotifications);
    }


    if (measureValue === '-') return measureValue;


    let activityId;
    let cellNotification;
    if (measure.type === 'quiz-activity') {
      cellNotification = find(relevantNotifications, 'data.quiz.id');
      activityId = get(cellNotification, 'data.quiz.id');
    } else if (measure.type === 'power-words-activity') {
      cellNotification = find(relevantNotifications, 'data.activity.id');
      activityId = get(cellNotification, 'data.activity.id');
    } else if (measure.type === 'write-activity') {
      cellNotification = find(relevantNotifications, 'data.response.prompt.id');
      activityId = get(cellNotification, 'data.response.prompt.id');
    } else if (measure.type === 'annotations') {
      cellNotification = relevantNotifications.find((notification) => notification.type === 'student-annotation');
    } else if (measure.type === 'checks-for-understanding-activity') {
      cellNotification = {
        type: 'checks-for-understanding-activity',
        data: {article: {content_id: articleContentId}}
      };
    }


    const cellUrl =
    measure.getCellUrl({
      articleHeaderSlug,
      articleId,
      studentIntegerId,
      studentUniqueId,
      activityId
    });

    if (displayArchived || isInactiveSgaStudent(studentUniqueId)) {
      const label = isGradingPageEnabled ? 'Score Now' : 'Grade Now';
      return measureValue === label ? '-' : `${measureValue}`;
    }

    // Use articleHeaders since articleHeader is not available when switching to student view.
    const rowArticleHeader = articleHeaders.find((header) => header.slug === articleHeaderSlug);

    const gradingData = {
      articleHeader: rowArticleHeader,
      headerId: rowArticleHeader?.id,
      assignmentId: props.assignment?.content_id,
      cellNotification,
      classroomId: props.classroom?.id,
      assignmentGroupId: props.assignment?.assignment_groups?.[0]?.group_id,
    };

    return (
      <Anchor
        href={buildGradingPageUrlForAssignmentTable(`${cellUrl.pathname}?${cellUrl.search}`, gradingData, isInReviewMode)}
        ariaProps={{
          'aria-label': `${measureValue}, View ${measure.type}`
        }}
      >
        {measureValue}
      </Anchor>
    );
  };

  const getTableColumns = () => {
    const dataLength = getTableData()?.length;
    const columns = [
      {
        accessor: (articleHeader) ? 'student' : 'article',
        Header: () => (articleHeader) ? <span aria-label={`${dataLength} students`}>Student ({dataLength})</span> : <span aria-label={`${dataLength} titles`}>Title ({dataLength})</span>,
        Cell: (args) => {
          const notifications = get(args.cell, 'row.original.notifications');
          const nonArticleContent = get(args.cell, 'row.original.non-article-content');
          if (articleHeader) {
            const student_name = get(args.cell, 'row.original.student');
            if (isUndefined(notifications)) return student_name;
            const cellUrl = getStudentCellUrl(notifications);
            const {studentUniqueId} = getFirstNotificationValues(notifications);
            const displayAsText = displayArchived ||
              isGradingPageEnabled ||
              isInactiveSgaStudent(studentUniqueId);

            if (displayAsText) {
              return <div className={styles.studentName}>{student_name}</div>;
            }

            return (
              <Anchor
                href={appendGradingModeParams(
                  `${cellUrl.pathname}activities/?${cellUrl.search}`,
                  isGradingPageEnabled,
                  isInReviewMode
                )}
                dataProps={{
                  'data-qa-selector': 'student_name_link',
                  id: 'student_name_link'
                }}
                __cssFor={{
                  root: `
                    padding: 0;
                  `
                }}
              >
                <div className={styles.studentName}>{student_name}</div>
              </Anchor>
            );
          } else {
            if (notifications) {
              const articleHeaderId = first(notifications)?.data.header.id;
              const articleHeader = find(articleHeaders, ['id', articleHeaderId]);
              const CATContent = articleHeader?.veil?.reason === 'DISTRICT_ADVISORY';
              const altText = (articleHeader?.image_alt_text !== '' && articleHeader?.image_alt_text !== 'An image.') ? articleHeader?.image_alt_text : `article ${articleHeader?.short_title} image.`;
              return (
                <Anchor
                  href={getCanvasUrl(isInReviewMode, articleHeader)}
                  __classNameFor={{
                    root: styles.contentLink
                  }}
                  dataProps={{
                    'data-qa-selector': 'article_image_link',
                    id: `article_image_link_${articleHeaderId}`
                  }}
                >
                  <div className={styles.articleImageContainer}>
                    <Image
                      alt={altText}
                      src={articleHeader?.image?.url}
                      imgixParams={shapeImgixParams(articleHeader?.image)}
                      __css={{
                        width: '110px',
                        height: 'auto',
                        position: 'relative',
                        alignSelf: 'stretch',
                        marginRight: '15px',
                        objectFit: 'cover',
                      }}
                    />
                    {CATContent ? districtAdvisoryBadge() : null}</div>
                  <div className={styles.articleHeaderTitle}>{articleHeader?.short_title}</div>
                </Anchor>
              );
            } else {
              const newselaGraphicSrc = getAuxImage(nonArticleContent.content_id);
              const srcImage = nonArticleContent?.image?.url || newselaGraphicSrc;
              const CATContent = nonArticleContent?.veil?.reason === 'DISTRICT_ADVISORY';
              let contentUrl;
              switch (nonArticleContent.content_type) {
                case ('audiotrack'):
                  contentUrl = `/audio-tracks/${nonArticleContent.content_id}`;
                  break;
                case ('interactivevideolink'):
                  contentUrl = `${lyceumOrigin}/view/${nonArticleContent?.content_id}`;
                  break;
                case ('video'):
                  contentUrl = `/videos/${nonArticleContent.content_id}`;
                  break;
                case ('externallink'):
                  contentUrl = nonArticleContent.url;
                  break;
              }
              const gradingData = {
                articleHeader: articleHeaders[0],
                assignmentId: props.assignment?.content_id,
                notifications,
                classroomId: props.classroom?.id,
              };

              return (
                <Anchor
                  // Disable the link to avoid stranding users on a page that doesn't
                  // support review mode.
                  href={isInReviewMode ? '#' : buildGradingPageUrlForAssignmentTable(contentUrl, gradingData)}
                  __classNameFor={{
                    root: styles.contentLink
                  }}
                  dataProps={{
                    'data-qa-selector': 'content_image_link',
                    id: `content_image_link_${nonArticleContent.content_id}`
                  }}
                >
                  {
                  srcImage ?
                    <div className={styles.nonArticleContentImageContainer}>
                      {
                      CATContent ? districtAdvisoryBadge() : null
                      }
                      <img className={styles.articleImage} src={srcImage} />
                    </div>
                    :
                    null
                  }
                  <span className={styles.articleHeaderTitle}>{nonArticleContent.title}</span>
                </Anchor>
              );
            }
          }
        },
      }, {
        accessor: 'content-views',
        Header: () => <span>Article View<br/>Date</span>
      }, {
        accessor: 'time-on-article',
        Header: () => <span>Time on<br/>Article</span>
      }, {
        accessor: 'article-level',
        Header: () => <span>Article<br/>Level</span>
      }
    ];

    const annotationMeasure = find(measures, {type: 'annotations'});
    if (annotationMeasure) {
      const annotationTableObject = {
        accessor: 'annotations',
        Header: () => <span>Annotations</span>,
      };
      if (isGradingPageEnabled) {
        annotationTableObject.Cell = ({cell}) => {
          return renderActivityCell(cell, annotationMeasure);
        };
      }
      columns.push(annotationTableObject);
    }

    const checksForUnderstandingMeasure = find(measures, {
      type: 'checks-for-understanding-activity'
    });

    if (
      hasChecksForUnderstandingColumn
    ) {
      columns.push({
        accessor: 'checks-for-understanding-activity',
        Header: () => (
          <span>
            Checks for <br />
            Understanding
          </span>
        ),

        Cell: ({cell}) => {
          return renderActivityCell(cell, checksForUnderstandingMeasure);
        }
      });
    }

    if (userHasPowerWordsLicense && headerHasPowerWords) {
      const powerWordsMeasure = filter(measures, {type: 'power-words-activity'})[0];
      columns.push({
        accessor: 'power-words-activity',
        Header: () => <span>Power<br/>Words Score</span>,
        Cell: ({cell}) => {
          return renderActivityCell(cell, powerWordsMeasure);
        }
      });
    }

    const quizActivityMeasures = filter(measures, {type: 'quiz-activity'});

    let quizStartIndex = 4;
    if (quizActivityMeasures.length > 0) {
      forEach(quizActivityMeasures, (quizActivityMeasure) => {
        columns.splice(quizStartIndex, 0,
          {
            accessor: quizActivityMeasure.key,
            Header: () => (quizActivityMeasure.dataTableColumnName.length === 1) ? <span>{quizActivityMeasure.dataTableColumnName[0]}</span> : <span>{quizActivityMeasure.dataTableColumnName[0]}<br/>{quizActivityMeasure.dataTableColumnName[1]}</span>,
            Cell: ({cell}) => {
              return renderActivityCell(cell, quizActivityMeasure);
            }
          });
        quizStartIndex++;
      });
    }

    const writeActivityMeasures = filter(measures, {type: 'write-activity'});

    if (writeActivityMeasures.length > 0) {
      let writeStartIndex = quizStartIndex;
      forEach(writeActivityMeasures, (writeActivityMeasure) => {
        columns.splice(writeStartIndex, 0,
          {
            accessor: writeActivityMeasure.key,
            Header: () => (writeActivityMeasure.dataTableColumnName.length === 1) ? <span>{writeActivityMeasure.dataTableColumnName[0]}</span> : <span>{writeActivityMeasure.dataTableColumnName[0]}<br/>{writeActivityMeasure.dataTableColumnName[1]}</span>,
            Cell: ({cell}) => {
              return renderActivityCell(cell, writeActivityMeasure);
            }
          }
        );
        writeStartIndex++;
      });
    }

    if (!articleHeader) {
      columns.splice(1, 0, {
        accessor: 'content-type',
        Header: () => <span>Content <br/> Type</span>,
      });
    }
    return columns;
  };

  const getTableData = () => {
    const groupedNotificationsData = (articleHeader) ?
    // If the summary cards are grouped by "Article", each row in the data
    // table will reflect a student's work at a particular article level. We
    // group the notifications data by two fields: student id & article level.
      groupBy(notifications, function(notification) {
        return notification.data.user.student[studentIdKey] + '-' + notification.data.article.grade_level;
      }) :
      // Else, if the summary cards are grouped by "Student", each row will
      // reflect an article header that the student has done work in.  Here, we group
      // the notifications data by two fields: header id and article level.
      groupBy(notifications, function(notification) {
        return notification.data.header.id + '-' + notification.data.article.grade_level;
      });

    // Shape the resultant notifications data structure into an array for mapping.
    // Each grouping will represent a row.
    const rowsOfStudentOrArticleNotifications = [];
    for (const identifier in groupedNotificationsData) {
      rowsOfStudentOrArticleNotifications.push(groupedNotificationsData[identifier]);
    }

    const tableData = map(rowsOfStudentOrArticleNotifications, (notifications) => {
      const tableRowData = {};
      if (articleHeader) {
        const studentId = first(notifications).data.user.student[studentIdKey];
        const student = find(students, [studentIdKey, studentId]);

        tableRowData['student'] = getLastNameFirstName(student);
        tableRowData['notifications'] = notifications;
      } else {
        const articleHeaderId = first(notifications).data.header.id;
        const articleHeader = find(articleHeaders, ['id', articleHeaderId]);

        tableRowData['article'] = articleHeader?.short_title;
        tableRowData['notifications'] = notifications;
      }

      tableRowData['content-type'] = 'Article';

      forEach(measures, (measure) => {
        if (measure.type !== 'content-type' && measure.type !== 'checks-for-understanding-activity') {
          const relevantNotifications = getRelevantNotifications(measure, notifications);
          const measureValue = (
            measure.type === 'write-activity' ?
              measure.dataTableValue(relevantNotifications, assignment.content_id) :
              measure.dataTableValue(relevantNotifications)
          );
          tableRowData[measure.key] = measureValue;
        }
      });

      return tableRowData;
    });

    addStudentsWithNoNotificationsToTable(tableData);
    addContentWithNoNotificationsToTable(tableData);
    return articleHeader ? tableData.filter((row) => row.student) : tableData;
  };

  const addContentWithNoNotificationsToTable = (tableData) => {
    const assignmentMembers = get(props, 'assignment.assignment_members');
    /**
     * Since interactive video quiz results are displayed in a separate table,
     * exclude them in this filter.
     */
    const nonArticleAndVideoContent = assignmentMembers.filter(({content}) => content.content_type !== 'articleheader' && content.content_type !== 'interactivevideolink');

    if (nonArticleAndVideoContent && !articleHeader) {
      forEach(nonArticleAndVideoContent, (content) => {
        const tableRowData = {};
        tableRowData['non-article-content'] = content.content;
        forEach(measures, (measure) => {
          if (measure.key === 'content-views') {
            tableRowData[measure.key] = 'Data coming soon';
          } else if (measure.key === 'content-type') {
            let contentType = upperFirst(content.content.content_type);
            switch (content.content.content_type) {
              case 'externallink':
                contentType = 'External Link';
                break;
              case 'audiotrack':
                contentType = 'Audio';
                break;
            }
            tableRowData[measure.key] = contentType;
          } else {
            tableRowData[measure.key] = '-';
          }
        });

        tableData.push(tableRowData);
      });
    }
  };

  const addStudentsWithNoNotificationsToTable = (tableData) => {
    const studentIdsWithNotifications = (
      uniq(map(notifications, `data.user.student[${studentIdKey}]`))
    );

    const studentsWithNoNotifications = (
      reject(students, (student) => {
        return includes(studentIdsWithNotifications, student[studentIdKey]);
      })
    );

    if (studentsWithNoNotifications.length && articleHeader) {
      forEach(studentsWithNoNotifications, (student) => {
        const tableRowData = {};

        tableRowData['student'] = getLastNameFirstName(student);

        forEach(measures, (measure) => {
          tableRowData[measure.key] = '-';
        });

        tableData.push(tableRowData);
      });
    }
  };

  return (
    notifications.length || assignment?.assignment_members?.length ?
      <AngelouTable
        columns={getTableColumns()}
        data={getTableData()}
        title={(articleHeader) ? 'Student Data' : 'Content Data'}
        generateTotalResultsString={() => {
          return null;
        }}
        initialState={{
          sortBy: [{id: (articleHeader) ? 'student' : 'article', desc: false}]
        }}
        __css={styles.dataTable}
      /> : null
  );
};

AssignmentReviewTable.propTypes = propTypes;

export default AssignmentReviewTable;

