import {map, includes} from 'lodash-es';

import * as types from 'modulesV2/constants';

function rosterSyncReducer(state = {}, action) {
  switch (action.type) {
    case types.REQUEST_ALL_ROSTER_SYNC_INFO:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case types.REQUEST_ALL_ROSTER_SYNC_INFO_FAIL:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case types.REQUEST_ALL_ROSTER_SYNC_INFO_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        items: [...action.data],
      };
    case types.SYNC_ROSTER_REQUEST:
      return {
        ...state,
        latestInitiatedSync: action.authProvider,
        // As soon as roster sync was requested, remove `time_completed`
        // on the roster item which indicates that the sync is in progress.
        items: [
          ...map(state.items, (item) => {
            if (item.provider === action.authProvider) {
              return {
                ...item,
                time_completed: null,
              };
            }
            return item;
          })
        ],
      };
    case types.SYNC_ROSTER_REQUEST_SUCCESS:
      return {
        ...state,
        syncingRosterId: action.id,
      };
    case types.CLEAR_LATEST_SYNC_PROVIDER_INFO:
      return {
        ...state,
        syncingRosterId: null,
      };
    case types.ROSTER_SYNC_IN_PROGRESS: {
      // Remove `time_completed` from a give sync info to imitate 'syncing'.
      // This is required to counter racing condition on the backend
      // between POST and subsequent GET.
      const isFirstSyncEver = !includes(map(state.items, 'provider'), action.authProvider);
      // This tricks is to treat the very first sync as if it already existed in the database.
      const stubRosterInfo = isFirstSyncEver ?
          [{
            id: action.id,
            provider: action.authProvider,
            has_failures: null,
          }] : [];
      return {
        ...state,
        items: [
          ...stubRosterInfo,
          ...map(state.items, (item) => {
            return {
              ...item,
              time_completed: item.provider === action.authProvider ? null : item.time_completed
            };
          }),
        ]
      };
    }
    default:
      return state;
  }
}

export default rosterSyncReducer;
