import * as types from '../constants/studiesActionTypes';

export function requestStudies() {
  return {
    type: types.STUDIES_REQUEST
  };
}

export function requestStudiesSuccess(data) {
  return {
    type: types.STUDIES_SUCCESS,
    studies: data,
    receivedAt: Date.now()
  };
}

export function requestStudiesFailure() {
  return {
    type: types.STUDIES_FAILURE,
    studies: {},
    receivedAt: Date.now()
  };
}
