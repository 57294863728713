import React from 'react';

import {Combobox} from '@newsela/angelou';
import PropTypes from 'prop-types';

import {COUNTRIES} from 'constants/locations';

const propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onSelectedItemChange: PropTypes.func,
  selectedItem: PropTypes.object,
  touched: PropTypes.bool,
  __css: PropTypes.any,
  dataQaSelector: PropTypes.string,
};

const defaultProps = {
  label: 'Country',
  name: 'country',
  dataQaSelector: 'country'
};

/*
 * This component is a country combobox using Angelou's combobox.
 * This can be used in html forms and can also be used with Formik.
 */
const CountryCombobox = (props) => {
  const {selectedItem, touched, error, onBlur, onSelectedItemChange, __css, name, label, dataQaSelector} = props;

  const itemToString = (item) => item ? item.name : '';

  return (
    <Combobox
      items={COUNTRIES}
      label={label}
      itemToString={itemToString}
      onSelectedItemChange={(e) => {
        onSelectedItemChange && onSelectedItemChange(e);
      }}
      selectedItem={selectedItem}
      createInputProps={() => ({
        onBlur: () => {
          onBlur && onBlur();
        },
        name: name,
        'data-qa-selector': dataQaSelector
      })}
      isRequired
      touched={touched}
      error={error}
      __cssFor={{
        root: __css
      }}
    />
  );
};

CountryCombobox.propTypes = propTypes;
CountryCombobox.defaultProps = defaultProps;

export default CountryCombobox;
