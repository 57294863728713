/**
 * Mapping between social auth provider and a corresponding
 * roster provider. Most of the auth and social providers match,
 * but not always. Having an explicit mapping is helpful as the number
 * of authentication and roster provider grows and they could potentially
 * be different.
 *
 * key   - auth provider
 * value - roster provider
 */
export const AUTH_TO_ROSTER_PROVIDER_MAPPING = {
  'google-oauth2': 'google_classroom',
  clever: 'clever',
  canvas: 'canvas',
  classlink: 'classlink',
};

/**
 * Mapping between internal authentication provider
 * and User-Friendly name for it.
 */
export const AUTH_PROVIDER_NAME_MAPPING = {
  'google-oauth2': 'Google',
  clever: 'Clever',
  canvas: 'Canvas',
  classlink: 'Classlink',
  forms: 'Newsela',
};

export const AUTH_PROVIDER_NAME_MAPPING_ONBOARDING = {
  'google-oauth2': 'Google Classroom',
  clever: 'Clever',
  canvas: 'Canvas',
  classlink: 'Classlink',
  forms: 'Newsela'
};

export const AUTH_PROVIDER_SUPPORT_DOCUMENTS = {
  'google-oauth2': 'https://support.newsela.com/item/supportArticle/ways-to-create-classes#google',
  clever: 'https://support.newsela.com/item/supportArticle/ways-to-create-classes#clever',
  canvas: 'https://support.newsela.com/item/supportArticle/ways-to-create-classes#canvas',
  classlink: 'https://support.newsela.com/hc/en-us/articles/360018860251-System-Admin-Instructions-for-ClassLink',
};
