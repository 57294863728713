import React from 'react';

import {Anchor, Icon, LineClamp, QuestionSVG, SearchSVG} from '@newsela/angelou';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

import {
  flexColumnCss,
  flexRowCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SearchMenu/SearchSuggestionsPopout/SearchSuggestionsPopout.styles';
import {
  getHighlightedSuggestionText
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SearchMenu/SearchSuggestionsPopout/SearchSuggestionsPopout.utils';
import {
  essentialQuestionTextCss,
  searchSuggestionAnchorCss,
  searchSuggestionIconCss,
  suggestionsUnorderedListCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SearchMenu/SearchSuggestionsPopout/SuggestionsList/SuggestionsList.styles';

export const SEARCH_WITH_NEEDLE_URL = '/search?needle=';

export const isQuestionSuggestion = (category) => category === 'eq';

export const focusSuggestion = ({index, increment}) => {
  const suggestionAnchors = document.querySelectorAll('.search-suggestion-anchor');
  if (increment) {
    suggestionAnchors[index + 1]?.focus();
  } else {
    suggestionAnchors[index - 1]?.focus();
  }
};

const propTypes = {
  inputValue: PropTypes.string.isRequired,
  suggestions: PropTypes.array.isRequired,
  setShowSuggestions: PropTypes.func.isRequired,
};

const SuggestionsList = ({inputValue, suggestions, setShowSuggestions}) => {
  const {push} = useHistory();

  const onKeyDown = (event, suggestion, index) => {
    if (event.key === 'Escape') {
      setShowSuggestions(false);
    }
    if (event.key === 'Enter') {
      setShowSuggestions(false);
      push(`/search/?needle=${suggestion}`);
    }
    const isNotLastSuggestion = index < suggestions.length;
    if (event.key === 'ArrowDown' && isNotLastSuggestion) {
      event.preventDefault();
      focusSuggestion({index, increment: true});
    }
    const isNotFirstSuggestion = index >= 0;
    if (event.key === 'ArrowUp' && isNotFirstSuggestion) {
      event.preventDefault();
      focusSuggestion({index, increment: false});
    }
  };

  return (
    <ul className={suggestionsUnorderedListCss}>
      {suggestions.map(({suggestion, category}, index) => {
        const isQuestion = isQuestionSuggestion(category);
        return (
          <li key={index}>
            <Anchor
              href={`${SEARCH_WITH_NEEDLE_URL}${suggestion}`}
              onKeyDown={(event) => onKeyDown(event, suggestion, index)}
              onClick={() => setShowSuggestions((show) => !show)}
              __cssFor={searchSuggestionAnchorCss}
              __classNameFor={{root: 'search-suggestion-anchor'}}
            >
              {isQuestion
                ? <Icon SvgComponent={QuestionSVG} __cssFor={searchSuggestionIconCss} />
                : <Icon SvgComponent={SearchSVG} __cssFor={searchSuggestionIconCss} />}

              <div className={flexColumnCss}>
                <div className={flexRowCss}>
                  {isQuestion
                    ? <div data-testid="question-text"><LineClamp lines={2} text={suggestion} /></div>
                    : <div data-testid="highlighted-text">{getHighlightedSuggestionText(suggestion, inputValue)}</div>}
                </div>

                {isQuestionSuggestion(category) && <div className={essentialQuestionTextCss}>Essential question</div>}
              </div>
            </Anchor>
          </li>
        );
      })}
    </ul>
  );
};

SuggestionsList.propTypes = propTypes;
SuggestionsList.displayName = 'SuggestionsList';
export {SuggestionsList};
