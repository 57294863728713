exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__color-quiz-correct___oSrxY{background-color:#52A821}.styles__color-quiz-incorrect___eWFrl{background-color:#D93F31}.styles__color-science___3ZRPj{background-color:#55bca6}.styles__color-kids___OURKR{background-color:#5908f7}.styles__color-money___B-ZoD{background-color:#fa0045}.styles__color-law___xCWmX{background-color:#002764}.styles__color-health___o5Vzm{background-color:#03a43c}.styles__color-arts___TBYdB{background-color:#E2C925}.styles__color-war-and-peace___On3Ie{background-color:#E76A0E}.styles__color-sports___vdaCr{background-color:#000000}.styles__color-opinion___WWRva{background-color:#0027f5}.styles__color-text-set___GWwwU{background-color:#106FF3}.styles__color-object-gray___ZZKlJ{background-color:#878787}.styles__color-light-gray___4kQvx{background-color:#F8F8F8}.styles__color-middle-gray___YTyvY{background-color:#BCBCBE}.styles__color-newsela-black___kXG-q{background-color:#333333}.styles__color-dark-bg___jCXjK{background-color:#031E3E}.styles__color-newsela-blue___3Pe6m{background-color:#0768fd}.styles__icon___-Ynw9{color:#333;font-size:1rem}", ""]);

// exports
exports.locals = {
	"color-quiz-correct": "styles__color-quiz-correct___oSrxY",
	"color-quiz-incorrect": "styles__color-quiz-incorrect___eWFrl",
	"color-science": "styles__color-science___3ZRPj",
	"color-kids": "styles__color-kids___OURKR",
	"color-money": "styles__color-money___B-ZoD",
	"color-law": "styles__color-law___xCWmX",
	"color-health": "styles__color-health___o5Vzm",
	"color-arts": "styles__color-arts___TBYdB",
	"color-war-and-peace": "styles__color-war-and-peace___On3Ie",
	"color-sports": "styles__color-sports___vdaCr",
	"color-opinion": "styles__color-opinion___WWRva",
	"color-text-set": "styles__color-text-set___GWwwU",
	"color-object-gray": "styles__color-object-gray___ZZKlJ",
	"color-light-gray": "styles__color-light-gray___4kQvx",
	"color-middle-gray": "styles__color-middle-gray___YTyvY",
	"color-newsela-black": "styles__color-newsela-black___kXG-q",
	"color-dark-bg": "styles__color-dark-bg___jCXjK",
	"color-newsela-blue": "styles__color-newsela-blue___3Pe6m",
	"icon": "styles__icon___-Ynw9"
};