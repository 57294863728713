exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__verticalContentCard___5oz14{display:grid;position:relative;display:-ms-grid;-ms-grid-row:1;flex:1 1 auto;height:100%}.styles__visual___abjpi{height:auto;width:auto}.styles__cardFitContent___P2bMI{height:fit-content}.styles__cardContainer___VNCRK{border-radius:4px;display:grid;display:-ms-grid;grid-template-rows:1fr auto;-ms-grid-rows:1fr auto;-ms-grid-columns:1fr}.styles__cardContainerFill___-M0YW{width:100%;height:100%}", ""]);

// exports
exports.locals = {
	"verticalContentCard": "styles__verticalContentCard___5oz14",
	"visual": "styles__visual___abjpi",
	"cardFitContent": "styles__cardFitContent___P2bMI",
	"cardContainer": "styles__cardContainer___VNCRK",
	"cardContainerFill": "styles__cardContainerFill___-M0YW"
};