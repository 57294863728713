import CommonMark from 'commonmark';
import {omit, forEach} from 'lodash-es';
import {takeEvery, call, put} from 'redux-saga/effects';

import {ArticleHeaderApi} from './../apis';
import * as types from './../constants/articleHeaderActionTypes';
import * as articleActions from '../actions/articleActions';
import * as actions from '../actions/articleHeaderActions';

function _getParagraphTextFromNode(node) {
  // This function takes a paragraph node from CommonMark and extracts out
  // the text into a string.
  let event = null; let text = '';
  const walker = node.walker();
  while ((event = walker.next())) {
    if (event.entering && event.node.type == 'Text') {
      text += event.node.literal;
    }
  }
  return text;
}

function enrichArticlesWithParagraphData(articleData) {
  // For each article within the list of articles we get back on an article
  // header, let's lift out the paragraphs. This becomes helpful for paragraph
  // selection questions.
  for (const article of articleData) {
    article.paragraphs = [];

    // Create a CommonMark parser and, based off of the article text, get a
    // AST walker.
    const parser = new CommonMark.Parser();
    const ast = parser.parse(article.text);
    const walker = ast.walker();
    let event = null; let node = null;

    // Walk the AST tree - upon finding a paragraph node, send it to a
    // function to extract the text and add it to the article's
    // paragraphs array.
    while ((event = walker.next())) {
      node = event.node;
      if (event.entering && node.isContainer && node.type == 'Paragraph') {
        article.paragraphs.push(_getParagraphTextFromNode(node));
      }
    }
  }

  // Return the article data with the enriched paragraph information.
  return articleData;
}

function * getArticleHeader(action) {
  const {identifier, params, headerArticleId} = action;
  try {
    let headerData = yield call(ArticleHeaderApi.getBySlug, identifier, {
      image_size: 885,
      ...params
    });

    const articleData = enrichArticlesWithParagraphData(headerData.articles);
    headerData = omit(headerData, 'articles');

    let articleLevelContentId;

    forEach(articleData, (article) => {
      // find article level content id based on articleId from URL params
      if (article.id === Number(headerArticleId)) {
        articleLevelContentId = article.content_id;
      }
      // Append the header id, slug and story type onto each of the articles.
      article.header = {
        id: headerData.id,
        slug: headerData.slug,
        story: {
          type: headerData.story.type,
        },
      };
    });

    // we need to pass the article level content_id for tracking purposes
    headerData.articleLevelContentId = articleLevelContentId;

    yield put(actions.requestArticleHeaderSuccess(headerData));
    try {
      yield put(articleActions.requestArticlesSuccess(articleData));
    } catch (error) {
      yield put(articleActions.requestArticlesFailure(error));
    }
  } catch (error) {
    yield put(actions.requestArticleHeaderFailure(error));
  }
}

export function * watchGetArticleHeader() {
  yield takeEvery(types.ARTICLE_HEADER_LOAD_REQUEST, getArticleHeader);
}
