import {isString, forEach, isArray} from 'lodash-es';

import APIFetcher from '../APIFetcher';

class ArticleHeaderClassroomApi {
  static get(queryParams) {
    if (isString(queryParams)) {
      return APIFetcher.get(`article-header-classroom/?${queryParams}`);
    }

    /**
     * The `classroom_ids` and `header_ids` array parameters should be converted to a CSV before being serialized.
     *
     * CORRECT:
     *  /article-header-classroom/?classroom_ids=1166234,1166233
     *
     * INCORRECT:
     *  /article-header-classroom/?classroom_ids=1166234&classroom_ids=1166233
     *
     */
    const paramsToConvertToCsv = ['classroom_ids', 'header_ids'];
    const convertedQueryParams = {...queryParams};
    forEach(paramsToConvertToCsv, (paramName) => {
      if (isArray(convertedQueryParams[paramName])) {
        convertedQueryParams[paramName] = convertedQueryParams[paramName].join(',');
      }
    });
    const params = APIFetcher.stringifyParams(convertedQueryParams);
    return APIFetcher.get(`article-header-classroom/?${params}`);
  }
}

export default ArticleHeaderClassroomApi;
