import * as types from 'modulesV2/constants';

export function requestRosterChoice() {
  return {
    type: types.REQUEST_ROSTER_CHOICE,
  };
}

export function requestRosterChoiceSuccess(data) {
  return {
    type: types.REQUEST_ROSTER_CHOICE_SUCCESS,
    data,
  };
}

export function requestRosterChoiceFailure(error) {
  return {
    type: types.REQUEST_ROSTER_CHOICE_FAILURE,
    error,
  };
}

export function updateRosterChoice(authProvider, id) {
  return {
    type: types.ROSTER_CHOICE_UPDATE,
    authProvider,
    id,
  };
}

export function createRosterChoiceAndSync(authProvider, classrooms = null) {
  return {
    type: types.ROSTER_CHOICE_CREATE_AND_SYNC,
    authProvider,
    classrooms,
  };
}

export function createRosterChoice(authProvider) {
  return {
    type: types.ROSTER_CHOICE_CREATE,
    authProvider,
  };
}
