import {binderActionTypes as types} from '../constants';

export function requestInitialBinderData(currentUserId, impersonatedUserId) {
  return {type: types.REQUEST_INITIAL_BINDER_DATA, currentUserId, impersonatedUserId};
}

export function storeBinderImpersonatedUser(user) {
  return {type: types.STORE_BINDER_IMPERSONATED_USER, payload: user};
}

export function setEnabledFilters(payload) {
  return {type: types.SET_ENABLED_FILTERS, payload};
}

export function unloadBinderData() {
  return {type: types.UNLOAD_BINDER_DATA};
}
