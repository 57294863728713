import React from 'react';

import {Button, ChevronLeftSVG, Icon} from '@newsela/angelou';
import PropTypes from 'prop-types';

import {
  backToMenuAnchorCss,
  backToMenuIconCss,
  homeMenuSubItemPopoutContainerCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/HomeMenu/HomeMenu.styles';
import {
  SUPPORT_CENTER_ITEMS
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';
import {
  PopoutMenuSection
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/PopoutMenuSection/PopoutMenuSection';
import {
  SupportCenterItem
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/SupportCenter/SupportCenterItem/SupportCenterItem';

const propTypes = {
  onClickBack: PropTypes.func.isRequired,
};

const HomeMenuSupportPopout = ({onClickBack}) => {
  return (
    <div className={homeMenuSubItemPopoutContainerCss} data-testid="home-classes-popout">
      <Button onClick={onClickBack} __cssFor={backToMenuAnchorCss} flavor={Button.flavors.incognito}>
        <Icon SvgComponent={ChevronLeftSVG} __cssFor={backToMenuIconCss} />
        Back to menu
      </Button>

      <PopoutMenuSection width="19.625rem" title="Support">
        {SUPPORT_CENTER_ITEMS.map(({title, href, openInNewTab, ItemIcon}, index) => (
          <SupportCenterItem title={title} href={href} ItemIcon={ItemIcon} openInNewTab={openInNewTab} key={index} />
        ))}
      </PopoutMenuSection>
    </div>
  );
};

HomeMenuSupportPopout.propTypes = propTypes;
HomeMenuSupportPopout.displayName = 'HomeMenuSupportPopout';
export {HomeMenuSupportPopout};
