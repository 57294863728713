import React from 'react';

import PropTypes from 'prop-types';

import styles from './styles.scss';

const propTypes = {
  gradeLevelLock: PropTypes.number,
  isReadingLevelLocked: PropTypes.bool,
  defaultReadingLevel: PropTypes.number,
};

const Explainer = ({gradeLevelLock, isReadingLevelLocked, defaultReadingLevel}) => {
  const isReadingLevelAssigned = Boolean(gradeLevelLock || defaultReadingLevel);

  const renderReadingLevelMessage = () => {
    if (isReadingLevelAssigned) {
      return (
        <>
          <span className={styles.boldCopy}> All students </span>
          <span> will receive this assignment at the closest available reading level selected.</span>
        </>
      );
    } else {
      return (
        <>
          <span>Newsela will use each student's performance to present the article at the </span>
          <a href="https://support.newsela.com/article/student-reading-level/" target="_blank" rel="noopener noreferrer"> level just right for them. </a>
        </>
      );
    }
  };

  const renderLevelLockMessage = () => {
    if (isReadingLevelLocked) {
      return "can't unlock the level until they submit the quiz.";
    } else {
      return 'will be able to change the level from the default at any time.';
    }
  };


  return (
    <div className={styles.explainer}>
      {renderReadingLevelMessage()}
      <span className={styles.boldCopy}> Students </span>{renderLevelLockMessage()}
    </div>
  );
};


Explainer.propTypes = propTypes;
export default Explainer;
