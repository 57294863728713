import {
  get,
  map,
  max,
  values,
  isFinite,
  filter,
  pickBy,
  forEach,
  some,
  has,
  keyBy
} from 'lodash-es';
import moment from 'moment';
import {createSelector} from 'reselect';

import {classroomSelectors} from 'static/three-oh/src/modules/selectors';

import {getAuthenticatedOrImpersonatedUser, getReadingLevelConfidence} from './binderSelectors';

const DEFAULT_EMPTY_OBJECT = {};

const getStudent = (state, studentId) => {
  return classroomSelectors.getStudentsFromClassrooms(state)[studentId];
};

const getStudentDetails = (state, studentId) => {
  return get(state, ['binderStudentDetails', studentId], DEFAULT_EMPTY_OBJECT);
};

const shapeAggregateData = (reportingAggregates) => {
  const report = {
    avgArticleLevel: get(reportingAggregates, 'avg:grade_level', null),
    articlesViewed: get(reportingAggregates, 'count:header_views', null),
    totalReadingTime: get(reportingAggregates, 'sum:reading_time', null),
    avgQuizScore: get(reportingAggregates, 'avg:quiz_score', null),
    totalQuizzes: get(reportingAggregates, 'count:quizzes', null),
    avgWriteResponseScore: get(reportingAggregates, 'avg:write_response_score', null),
    totalWriteResponses: get(reportingAggregates, 'count:write_response_score', null),
    totalAnnotations: get(reportingAggregates, 'count:annotations', null),
  };

  // Calculate average annotations per header
  if (report.totalAnnotations && report.articlesViewed) {
    report.avgAnnotations = (report.totalAnnotations / report.articlesViewed);
  } else {
    report.avgAnnotations = null;
  }

  // Calculate average reading time per header
  if (report.totalReadingTime && report.articlesViewed) {
    report.avgReadingTime = (report.totalReadingTime / report.articlesViewed);
  } else {
    report.avgReadingTime = null;
  }

  return report;
};

const shapeDetails = (reportSet) => {
  return map(reportSet, (report) => {
    return {
      gradeLevel: get(report, 'article_grade_level', null),
      readingTime: get(report, 'sum:reading_time', null),
      quizScore: get(report, 'min:quiz_score', null),
      writeResponseScore: get(report, 'avg:write_response_score', null),
      totalAnnotations: get(report, 'count:annotations', null),
      articleId: get(report, 'article_id', null),
      headerId: get(report, 'header_id', null),
      headerSlug: get(report, 'header_slug', null),
      articleTitle: get(report, 'article_title', null),
      lastActivityDate: max([
        report['max:date_completed'],
        report['max:date_article_read'],
        report['max:date_quiz_completed'],
        report['max:date_write_response_submitted'],
        report['max:date_annotated'],
      ]),
    };
  });
};

export const getReadingReport = createSelector(
  [getStudentDetails],
  (details) => {
    const overallAggregates = get(details, 'overallAggregates', {});
    const currentReadingLevel = get(details, 'currentReadingLevel', {});
    const overallSkillMastery = get(details, 'skillMastery.overall', {});

    const report = shapeAggregateData(overallAggregates);
    report.readingLevel = currentReadingLevel || {};
    report.readingLevelConfidence = getReadingLevelConfidence(currentReadingLevel.value);
    report.skillMastery = overallSkillMastery;
    return report;
  }
);

// Returns all stored StudentQuizzes matching the currently selected student.
const getFetchedStudentQuizzes = createSelector(
  [getStudentDetails],
  (details) => {
    const studentQuizzesById = get(details, 'studentQuizzes', {});
    const studentQuizzes = values(studentQuizzesById);
    return studentQuizzes;
  }
);

// Returns all stored Write Responses matching the currently selected student.
const getFetchedStudentWriteResponses = createSelector(
  [getStudentDetails],
  (details) => {
    const studentWriteResponsesById = get(details, 'writeResponses', {});
    const writeResponses = values(studentWriteResponsesById);
    return writeResponses;
  }
);

// Returns only StudentQuizzes that are applicable to the current binder filters.
const getStudentQuizzesApplicableToFilter = createSelector(
  getFetchedStudentQuizzes,
  (state, studentId, binderFilters) => binderFilters.since.value,
  (state, studentId, binderFilters) => binderFilters.until.value,
  (state, studentId, binderFilters) => binderFilters.skill.label,
  (studentQuizzes, sinceFilter, untilFilter, skillFilter) => {
    // Begin creating functions to filter StudentQuizzes by date and reading standard.

    // Match StudentQuizzes within the filtered date range.
    const filterByDate = (studentQuiz) => {
      return moment(studentQuiz.date_completed).isBetween(sinceFilter, untilFilter);
    };

    // Match StudentQuizzes that registered any score on the selected standard.
    let filterByReadingStandard = () => true;
    if (skillFilter != 'all') {
      const scoreOnStandard = `score_on_standard_${skillFilter}`;
      filterByReadingStandard = (studentQuiz) => {
        return isFinite(get(studentQuiz, scoreOnStandard));
      };
    }

    // Run the StudentQuizzes through the binder date and standards filters.
    const filteredStudentQuizzes = filter(studentQuizzes, (studentQuiz) => {
      return filterByDate(studentQuiz) && filterByReadingStandard(studentQuiz);
    });

    return filteredStudentQuizzes;
  }
);


// Returns all stored EstimatedGrades matching the currently selected student.
const getFetchedEstimatedGrades = createSelector(
  [getStudentDetails],
  (details) => {
    return get(details, 'estimatedGrades', {});
  }
);

export const getEstimatedGradesApplicableToFilter = createSelector(
  getFetchedEstimatedGrades,
  (state, studentId, binderFilters) => binderFilters.since.value,
  (state, studentId, binderFilters) => binderFilters.until.value,
  (estimatedGrades, sinceFilter, untilFilter) => {
    const pickByDate = (estimatedGrade, key) => {
      return moment(key).isValid() && moment(key).isBetween(sinceFilter, untilFilter);
    };
    const filteredEstimatedGrades = pickBy(estimatedGrades, pickByDate);
    return filteredEstimatedGrades;
  }
);

const getLoadingStatus = createSelector(
  [getStudentDetails],
  (details) => {
    return {
      isLoading: details.isLoading,
      dateLastLoaded: details.dateLastLoaded,
    };
  }
);

export const getClasswork = createSelector(
  [getStudentDetails, getAuthenticatedOrImpersonatedUser, getFetchedStudentQuizzes, getFetchedStudentWriteResponses],
  (details, effectiveUser, studentQuizzes, writeResponses) => {
    const classrooms = get(details, 'classrooms', {});
    const classroomAggregates = get(details, 'classroomAggregates', {});
    const work = get(details, 'studentWork', []);
    const skillMastery = get(details, 'skillMastery', {});

    // Loop through all the classrooms and find the associated work.
    const classwork = [];

    forEach(classrooms, (classroom) => {
      const viewModel = {};
      viewModel.id = `${details.studentId},${classroom.id}`;
      viewModel.classroom = classroom;
      viewModel.aggregates = shapeAggregateData(classroomAggregates[classroom.unique_id]);
      viewModel.details = shapeDetails(filter(work, ['assignment_classroom_id', classroom.unique_id]));
      viewModel.skillMastery = get(skillMastery, classroom.unique_id, {});
      viewModel.foreignClassroom = !some(classroom.teachers, ['unique_id', effectiveUser.teacher.unique_id]);
      // Sort order is my classrooms, other teachers classrooms, and then independent work
      viewModel.sortKey = (viewModel.foreignClassroom ? 2 : 1);
      classwork.push(viewModel);
    });

    // Check for independent work which is represented by assignment_classroom_id=null
    if (has(classroomAggregates, 'null')) {
      const independentWork = {
        id: `${details.studentId},null`,
        classroom: {id: 'null', name: 'Independent & group reading'},
        aggregates: shapeAggregateData(classroomAggregates['null']),
        details: shapeDetails(filter(work, ['assignment_classroom_id', null])),
        skillMastery: get(skillMastery, null, {}),
        foreignClassroom: true,
        sortKey: 3,
      };
      classwork.push(independentWork);
    }

    // Stitch in studentQuizzes to the classwork details.
    const studentQuizzesByArticleId = keyBy(studentQuizzes, 'quiz.article.id');
    const stitchStudentQuizzes = (classwork) => {
      forEach(classwork.details, (detail) => {
        detail.studentQuiz = studentQuizzesByArticleId[detail.articleId];
      });
    };
    forEach(classwork, stitchStudentQuizzes);

    // Stitch in studentWriteResponses to the classwork details.
    const studentWritesById = keyBy(writeResponses, 'id');
    const stitchWriteResponses = (classwork) => {
      forEach(classwork.details, (detail) => {
        const relevantWork = filter(studentWritesById, ['article.id', detail.articleId]);

        /**
         * This is the solution for the bug:
         * https://newsela.atlassian.net/browse/REPORT-1265.
         * The relevant work for each classwork details row
         * should include only those that contains the current classroom id
         **/
        const relevantWriteResponses = filter(relevantWork, ['classroom.id', classwork.classroom.id]);

        /**
         * We exclude foreign classrooms because independent work can contain
         * multiple clasroom ids
         **/
        detail.writeResponses = classwork.foreignClassroom ? relevantWork : relevantWriteResponses;
      });
    };
    forEach(classwork, stitchWriteResponses);

    return classwork;
  }
);


const buildViewModel = createSelector(
  [
    getStudent,
    getLoadingStatus,
    getReadingReport,
    getStudentQuizzesApplicableToFilter,
    getEstimatedGradesApplicableToFilter,
    getClasswork
  ], (
    student,
    loadingStatus,
    readingReport,
    studentQuizzes,
    estimatedGrades,
    classwork
  ) => {
    const model = {
      student,
      ...loadingStatus,
      readingReport,
      studentQuizzes,
      estimatedGrades,
      classwork
    };
    return model;
  }
);


export {
  getStudent,
  buildViewModel,
  getFetchedEstimatedGrades,
  getFetchedStudentQuizzes
};
