import initialState from './initialState';
import {studentBinderActionTypes as types} from '../constants';

function studentBinderTableData(state = initialState.studentBinderTableData, action) {
  switch (action.type) {
    case types.STUDENT_BINDER_DATA_LOAD_REQUEST:
      return {...state, isError: false, isLoading: true};

    case types.STUDENT_BINDER_DATA_LOAD_SUCCESS:
      return {...state, isError: false, isLoading: false, items: action.payload};

    case types.STUDENT_BINDER_DATA_LOAD_FAILURE:
      return {...state, isError: true, isLoading: false, error: action.error};

    default:
      return state;
  }
}

export default studentBinderTableData;
