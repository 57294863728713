import {get, filter, isEmpty, sortBy, head, last, keyBy, first} from 'lodash-es';
import moment from 'moment';

import {ASSIGNMENT_VERSION_MVE} from 'static/three-oh/src/constants/Assignments';
import {lyceumOrigin} from 'static/three-oh/src/constants/lyceum';
import VISIBILITY_MARKER_REASONS from 'static/three-oh/src/constants/visibilityMarkerReasons';
import {isObjectArchived} from 'static/three-oh/src/modules/utils/dateHelpers';

export const ASSIGNMENT_VIEW_TYPE = {
  ALL: 'All',
  ASSIGNED: 'Assigned',
  DRAFT: 'Draft',
};

export const CONTENT_SETTINGS_TYPE = {
  CAT: 'CAT',
  SRC: 'SRC',
};

const SRC_MARKERS = [
  VISIBILITY_MARKER_REASONS.DISTRICT_ADVISORY,
  VISIBILITY_MARKER_REASONS.SCHOOL_ADVISORY,
];

export function getActiveClassroomsAttachedToAssignment(assignment) {
  // Deleting/archiving a classroom doesn't nuke its relationship w/ an
  // assignment. Return an assignment's active classrooms.
  const assignmentClassrooms = get(assignment, assignment.classrooms ? 'classrooms' : 'assignment_classrooms', []);

  return filter(assignmentClassrooms, (classroom) => {
    return !isObjectArchived(classroom) || !isObjectArchived(classroom?.classroom);
  });
}

export function getActiveClassroomsAttachedToAssignmentContent(assignment) {
  // Deleting/archiving a classroom doesn't nuke its relationship w/ an
  // assignment. Return an assignment's active classrooms.
  const assignmentClassrooms = get(assignment, 'assignment_classrooms', []);
  return filter(assignmentClassrooms, ({classroom}) => {
    return !isObjectArchived(classroom);
  });
}

export function getArchivedClassroomsAttachedToAssignmentContent(assignment) {
  // Deleting/archiving a classroom doesn't nuke its relationship w/ an
  // assignment. Return an assignment's archived classrooms.
  const assignmentClassrooms = get(assignment, 'assignment_classrooms', []);

  return filter(assignmentClassrooms, ({classroom}) => {
    return isObjectArchived(classroom);
  });
}

export function findComplementaryArticleForLockedAssignment(assignment, articles) {
  const gradeLevelLock = assignment.grade_level_lock;
  const sortedArticles = sortBy(articles, (article) => article.grade_level);

  // Find the article whose grade-level is closest to the assignment's
  // grade-level-lock (without going over it). If no such article exists,
  // meaning all of the articles' grade-levels are over the grade-level-lock,
  // then select the article w/ the lowest grade level.
  const suitableArticles = filter(sortedArticles, (article) => {
    return article.grade_level <= gradeLevelLock;
  });
  let article = head(sortedArticles);
  if (suitableArticles.length) {
    article = last(suitableArticles);
  }

  return article;
}

export function getAssignedClassroomsStudentIsEnrolledIn(assignment, studentClassrooms) {
  const assignedClassroomsGroupedById = keyBy(assignment.assignment_classrooms, 'classroom.id');

  return filter(studentClassrooms, (studentClassroom) => {
    return assignedClassroomsGroupedById[studentClassroom.classroom.id];
  });
}

export function assignmentClassroomsInfo(assignment) {
  const activeClassrooms = getActiveClassroomsAttachedToAssignmentContent(assignment);
  const archivedClassrooms = getArchivedClassroomsAttachedToAssignmentContent(assignment);
  const allClassrooms = get(assignment, 'assignment_classrooms', []);
  const hasOnlyArchivedClassrooms = allClassrooms.length ? allClassrooms.every(({classroom}) => isObjectArchived(classroom)) : false;
  return {
    activeClassrooms,
    archivedClassrooms,
    allClassrooms,
    hasOnlyArchivedClassrooms
  };
}

function getDelimiter(displayDelimiter, delimiter = ', ') {
  return displayDelimiter ? delimiter : '';
}

export function getFullUserName({first_name: firstName = '', last_name: lastName = ''}) {
  return `${firstName}${firstName && lastName ? ' ' : ''}${lastName}`;
}

export function getSmallGroupUserNames(users = []) {
  return users.map(getFullUserName).join(', ');
}

function getOtherUsersDescription(otherUsersCount, displayDelimiter) {
  if (otherUsersCount === 0) return '';

  const delimiter = getDelimiter(displayDelimiter, ' ');
  const isPlural = otherUsersCount > 1;

  return `${delimiter}and ${otherUsersCount} more student${isPlural ? 's' : ''}`;
}

function getFirstNthUsers(selectedUsers) {
  const firstNthUsers = [];
  const usersWithoutNameValues = [];

  selectedUsers.forEach(function(user) {
    const hasNameValues = user.first_name && user.last_name;
    const arr = hasNameValues ? firstNthUsers : usersWithoutNameValues;

    arr.push(user);
  });

  return {firstNthUsers, usersWithoutNameValues};
}

export function getTruncatedSmallGroupUserNames(users = [], n = users.length) {
  const selectedUsers = users.slice(0, n);
  const {firstNthUsers, usersWithoutNameValues} = getFirstNthUsers(selectedUsers);

  const otherUsers = users.slice(n, users.length);
  const otherUsersCount = otherUsers.length + usersWithoutNameValues.length;

  const firstNthUserNames = getSmallGroupUserNames(firstNthUsers);
  const hasSingleNthUser = firstNthUsers.length === 1;
  const hasNoOtherUsers = otherUsersCount === 0;
  const omitDelimiter = hasSingleNthUser || hasNoOtherUsers;
  const delimiter = getDelimiter(!omitDelimiter);
  const otherUsersDescription = getOtherUsersDescription(otherUsersCount, hasSingleNthUser);

  return `${firstNthUserNames}${delimiter}${otherUsersDescription}`;
}

export function hasAssignmentGroups(assignmentGroups) {
  return !isEmpty(assignmentGroups);
}

export function hasArchivedAssignmentGroups(assignmentGroups, assignment) {
  const draftAssignment = get(assignment, 'is_draft');
  return hasAssignmentGroups(assignmentGroups) && isEmpty(assignmentGroups[0].users) && !draftAssignment;
}

export function getAllAssignmentGroupStudents(assignmentGroups) {
  return assignmentGroups.reduce((students, currentGroup) => {
    if (currentGroup?.users) {
      students.push(currentGroup.users);
    }

    if (currentGroup?.inactive_users) {
      students.push(currentGroup.inactive_users);
    }

    return students;
  }, []).flat();
}

export const isMVEAssignment = (assignment) => assignment.version === ASSIGNMENT_VERSION_MVE;

export function getLinkToAssignment(assignment) {
  return isMVEAssignment(assignment) ? getAssignmentEditURL(assignment) : `/assignment/${assignment.content_id}`;
}

export function shouldDisableAssignmentPrint(assignment, isLiteUser, userProperties) {
  const {canPrintPremiumContent = false} = userProperties;

  const {content_id: assignmentContentId, is_draft: isDraft} = assignment;

  const isAssignmentViewOnly = getIsAssignmentViewOnly(isLiteUser, assignment);
  const isArticleLite = !isAssignmentViewOnly;
  const isPrintDisabledForUser = userUnableToPrintContent(canPrintPremiumContent, isArticleLite);

  const isAssignmentPrintCompatible = isMVEAssignment(assignment) && !isDraft && Boolean(assignmentContentId);

  return isPrintDisabledForUser || !isAssignmentPrintCompatible;
}

export function getPrintUrl({assignment, isLiteUser, userProperties}) {
  const {content_id: assignmentContentId} = assignment;


  const hasInvalidPrintUrl = !assignmentContentId || !window?.location?.origin;
  const isAssignmentPrintDisabled = shouldDisableAssignmentPrint(assignment, isLiteUser, userProperties);

  if (hasInvalidPrintUrl || isAssignmentPrintDisabled) {
    return '';
  }

  return `${window.location.origin}/content/${assignmentContentId}/print/?format=pdf`;
}

export function getAssignmentEditURL(assignment) {
  // If this as an MVE assignment, return the view page url w/selected assignment cuid
  // Otherwise, return the legacy assignment editor page
  if (isMVEAssignment(assignment)) {
    const assignmentMembers = get(assignment, 'assignment_members', []);
    const assignmentHeaders = assignmentMembers.filter((member) => member.content.content_type === 'articleheader');

    if (assignmentHeaders.length) {
      const headerContentId = first(assignmentHeaders).content.content_id;
      return `/content/${headerContentId}/?selectedAssignmentId=${assignment.content_id}`;
    }
  }

  return `${lyceumOrigin}/assignments/${assignment.content_id}/edit`;
}

// A function for building the url path and parameters for sharing or copying an assignment
// (Should differentiate between legacy and mve clone/share urls)
export const constructCopyPathAndParams = (assignmentContentId, assignment = {}, returnUrlParamString = '') => {
  const {version} = assignment;
  const legacyPathAndParams = `assignments/create?copiedAssignmentId=${assignmentContentId}${returnUrlParamString}`;
  const nonMveVersion = !version || version === 'legacy';
  const memberContent = assignment?.assignment_members?.[0]?.content;
  const assignmentEmbedContentId = memberContent?.story_content_id;
  // if any part of the url is undefined for some reason, go to legacy editor:
  if (nonMveVersion || !assignmentEmbedContentId) {
    return legacyPathAndParams;
  }
  return `view/${assignmentEmbedContentId}?copiedAssignmentId=${assignmentContentId}&selectedAssignmentId=null${returnUrlParamString}`;
};

export const getIsContentStudentRestricted = (content = {}) => { // expects either content api object or langston data (content-document api)
  // the following is temporary logic used to trigger the true and false branches of user stories surrounding SRC
  // should allow QA and devs to control the return value by passing in `isSRC` into the search params. Ex: `?isSRC=true`
  if (typeof window === 'undefined') {
    return;
  }
  const queryParams = {};
  for (const [key, value] of new URLSearchParams(window.location.search)) {
    queryParams[key] = value;
  }

  // content-document object will have `data` key, content api object will not. Will use as discriminator for time being.
  // extract marker from either langston or content api object:
  const {visibility_markers: markers = []} = content.data || content;
  const isStudentRestricted = markers.some((marker = {}) => SRC_MARKERS.includes(marker.reason));

  return isStudentRestricted || queryParams.isSRC;
};

export const getIsMultiAssetAssignment = (assignment = {}) => {
  return assignment?.assignment_members.length > 1;
};

export const getIsAssignmentViewOnly = (isLiteUser, assignment = {}) => {
  return (isLiteUser && getIsMultiAssetAssignment(assignment)) || getAssignmentContainsExpired(isLiteUser, assignment);
};
export const getAssignmentContainsExpired = (isLiteUser, assignment = {}) => { // expects assignment object
  const currentTime = moment();
  const expiredLiteMembers = assignment.assignment_members?.filter((member) => {
    if (member.content.date_lite_product_grant_expires) {
      const dateContentExpires = moment(member.content.date_lite_product_grant_expires);
      return dateContentExpires.isBefore(currentTime);
    }
  });
  const veiledMembers = assignment.assignment_members?.filter((member) => {
    if (member.content.visibility_markers?.length > 0) {
      return member.content.visibility_markers.some((marker) => {
        return marker.reason === 'PREMIUM_CONTENT' && marker.veil;
      });
    }
  });
  const containsExpiredMembers = (isLiteUser && expiredLiteMembers?.length > 0) || veiledMembers?.length > 0;
  return containsExpiredMembers;
};

export const getGradeBandRanges = (visibility_markers = []) => {
  // Expects array of visibility_markers
  // Returns array of ranges in the following format: ['min-max', 'min-max', 'min-max']
  const sortedGroups = visibility_markers.sort((curr, next) => curr.grade_minimum - next.grade_minimum);

  return sortedGroups.map((group) => {
    const {grade_minimum: minGrade, grade_maximum: maxGrade} = group;
    if (minGrade === maxGrade) {
      return `${minGrade || 'K'}`;
    }
    return `${minGrade || 'K'}-${maxGrade || 'K'}`;
  });
};

export const getAllRestrictedBandsCopy = (visibility_markers = []) => {
  // Expects array of visibility_markers
  // Returns copy with list of restricted grades, calculates correct punctuation and wording based on input
  const grades = getGradeBandRanges(visibility_markers);
  let [first, ...rest] = grades;
  if (!first) {
    return null;
  }
  const [last] = rest.splice(-1);
  const csv = rest.join(', ') ? `, ${rest.join(', ')}` : '';
  if (first.length === 1 && !last) {
    first = `grade ${first}`;
  } else {
    first = `grades ${first}`;
  }
  return `This advisory applies to ${first}${last ? `${csv || ''} and ${last}.` : '.'}`;
};

export const getConSetVisibilityMarkers = (content = []) => {
  // Expects array of content from either Content API or Langston
  // Returns an array of visibility markers
  const contentWithAdvisoryMarkers = content.filter((c) => getIsContentStudentRestricted(c)).map((c) => c.data || c);
  const markers = contentWithAdvisoryMarkers.map((content) => {
    // TODO: Switch to constants after they are are introduced.
    return content.visibility_markers?.filter((marker) => marker.reason === 'DISTRICT_ADVISORY' || marker.reason === 'SCHOOL_ADVISORY');
  });
  return markers.flat();
};

export const determineSRCState = (contentItems = []) => {
  // utility for SRC, student restricted content, for Content Settings. This should not be used for CAT.
  const containsSRC = contentItems.some((item = {}) => getIsContentStudentRestricted(item));
  const containsUnconsentedSRC = contentItems.some((item = {}) => getIsContentStudentRestricted(item) && !item?.user_content_settings_override);
  const unconsentedSRCContent = contentItems.filter((item = {}) => getIsContentStudentRestricted(item) && !item?.user_content_settings_override);
  const SRCState = {
    containsSRC,
    containsUnconsentedSRC,
    unconsentedSRCContent
  };

  // the following is temporary logic used to trigger desired SRCState to test/qa user stories surrounding SRC
  // this should allow QA and devs to control the return value by passing in memberNeedsConsent={assetContentId} (found in contentItem.content_id)
  if (typeof window === 'undefined') {
    return SRCState;
  }
  const queryParams = {};
  for (const [key, value] of new URLSearchParams(window.location.search)) {
    queryParams[key] = value;
  }

  if (queryParams.memberNeedsConsent) {
    const mockState = {
      containsSRC: contentItems.some((item = {}) => item?.content_id === queryParams.memberNeedsConsent),
      containsUnconsentedSRC: contentItems.some((item = {}) => item?.content_id === queryParams.memberNeedsConsent && !item?.user_content_settings_override),
      unconsentedSRCContent: contentItems.filter((item = {}) => item?.content_id === queryParams.memberNeedsConsent && !item?.user_content_settings_override)
    };
    return mockState;
  }

  return SRCState;
};

export const determineSRCStateForMembers = (assignmentMembers = []) => {
  const contentItems = assignmentMembers.map((member) => member.content);
  const state = determineSRCState(contentItems);
  state.unconsentedSRCContent = state.unconsentedSRCContent.map((content) => ({content}));
  return state;
};

export const userUnableToPrintContent = (canPrintPremiumContent, isArticleLite = false) => {
  if (isArticleLite) {
    return false;
  }

  return !canPrintPremiumContent;
};
