import {call, fork, put, take, takeEvery, takeLatest} from 'redux-saga/effects';

import {UserApi} from 'static/three-oh/src/modules/apis';

import * as types from './../constants/userActionTypes';
import * as actions from '../actions/userActions';


/*
 * Retreives the current user's data and records data in heap analytics
 */
export function * getCurrentUser() {
  try {
    const data = yield call(UserApi.getCurrentUser);
    yield put(actions.loadUserSuccess(data));
  } catch (error) {
    yield put(actions.loadUserFailure(error));
  }
}

/*
 * Creates a new user
 */
export function * createNewUser(userInfo) {
  try {
    const result = yield call(UserApi.persistUser, userInfo);

    yield put(actions.loadUserSuccess(result));
  } catch (error) {
    yield put(actions.loadUserFailure(error));
  }
}

export function * watchGetCurrentUser() {
  yield take(types.USER_LOAD_REQUEST);
  yield call(getCurrentUser);
}

export function * watchCreateNewUser() {
  yield takeLatest(types.USER_CREATE_REQUEST, function * init(action) {
    const payload = {
      ...action.payload.additionalUserData,
      ...action.payload.userInfo
    };
    yield fork(createNewUser, payload);
  });
}

export function * watchRefreshCurrentUser() {
  yield takeEvery(types.USER_REFRESH_REQUEST, getCurrentUser);
}
