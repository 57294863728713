export const FREE_LEARN_MORE_CTA = {
  message: 'Learn more',
  url: '/about/solutions'
};

export const FREE_UNLOCK_INSIGHTS_CTA = {
  message: 'Unlock insights on student activity with a Newsela Subscription',
  url: '/about/solutions'
};

export const FREE_TRIAL_CTA = {
  message: 'Claim now',
  url: '/trial?campaign_title=30-day-trial'
};
