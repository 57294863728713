import React, {useState} from 'react';

import {Button, DownloadSVG, Toast} from '@newsela/angelou';
import PropTypes from 'prop-types';

import globalToasterRef from 'constants/globalToasterRef';
import withTrackEvent from 'static/three-oh/src/components/with/WithTrackEvent';
import {ReportingApi} from 'static/three-oh/src/modules/apis';

import styles from './styles';

const propTypes = {
  type: PropTypes.oneOf(['work', 'student_work']).isRequired,
  userProperties: PropTypes.object,
  label: PropTypes.string,
  reportingFilters: PropTypes.object.isRequired,
  path: PropTypes.string,
  hasActivityToReport: PropTypes.bool,
  showMessage: PropTypes.func,
  students: PropTypes.array.isRequired,
  isContentLocked: PropTypes.bool
};

const ButtonWithTrackEvent = withTrackEvent(Button);

const ReportDownloadButton = (props) => {
  const {hasActivityToReport = true, userProperties, type, label = 'Download Data', reportingFilters, showMessage, students, path} = props;
  const isFreeUser = userProperties?.isFreeUser;
  const [loading, setLoading] = useState(false);

  const handleDownload = async() => {
    // Only provide a link to download data if user is PRO.
    if (!isFreeUser) {
      try {
        setLoading(true);
        let csvContent = null;
        if (!hasActivityToReport) {
          const {data} = ReportingApi.getNoDataCsvReportAsText(students);
          csvContent = data;
        } else {
          const {data, error} = await ReportingApi.getCsvReportAsText(type, {filters: reportingFilters});
          if (error) {
            setLoading(false);
            globalToasterRef.current.displayToast({
              showIcon: true,
              statusColor: Toast.statusColor.danger,
              children: <span>There was an error while creating your CSV file. Click <strong>Download data</strong> button to try again</span>,
            });
          }
          csvContent = data;
        }
        if (csvContent) {
          setLoading(false);
          const blob = new Blob([csvContent], {type: 'text/csv'});
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = ReportingApi.buildFilenameFromObject(type, {filters: reportingFilters});
          link.click();
        }
      } catch (error) {
        globalToasterRef.current.displayToast({
          showIcon: true,
          statusColor: Toast.statusColor.danger,
          children: <span>There was an error while creating your CSV file. Click <strong>Download data</strong> button to try again</span>,
        });
      }
    } else if (isFreeUser) {
      showMessage();
    }
  };

  const loadingText = (
    <div className={styles.ellipsisAnimation}>
      Loading
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </div>
  );

  const isAssignments = path?.includes('assignments');
  return (
    <div aria-hidden={props.isContentLocked}>
      <ButtonWithTrackEvent
        flavor={isAssignments ? Button.flavors.secondary : Button.flavors.primary}
        dataProps={{
          'data-qa-selector': 'download_data'
        }}
        ariaProps={{
          tabindex: props.isContentLocked ? '-1' : null
        }}
        icon={{
          SvgComponent: DownloadSVG,
          alignment: Button.iconAlignments.left
        }}
        __classNameFor={{
          root: isAssignments ? styles.reportDownloadButton : null
        }}
        eventProperties={{
          description: 'Download assignment data',
          hasActivityToReport: hasActivityToReport,
          type: type,
          reportingFilters: reportingFilters
        }}
        actionName="Download"
        legacyEventName="Assignment-Download"
        streamProperties={{
          description: 'Download assignment data.',
          report_type: type,
          report_filters: reportingFilters,
        }}
        onClick={handleDownload}
        disabled={loading}
      >
        {loading ? loadingText : label}
      </ButtonWithTrackEvent>
    </div>
  );
};

ReportDownloadButton.propTypes = propTypes;

export default ReportDownloadButton;
