import * as types from 'modulesV2/constants';

export const initializeAssignmentReview = (options) => {
  return {
    ...options,
    type: types.CANVAS_APP_LOAD_REVIEW_PAGE,
  };
};

export const assignmentReviewLoadSuccess = () => {
  return {type: types.CANVAS_APP_LOAD_REVIEW_PAGE_SUCCESS};
};

export const assignmentReviewPageLoadFailure = (error) => {
  return {type: types.CANVAS_APP_LOAD_REVIEW_PAGE_FAILED, error};
};

export const studentLoadSuccess = (data) => {
  return {type: types.CANVAS_APP_LOAD_STUDENT_SUCCESS, data};
};

export const assignmentLoadRequest = (id) => {
  return {type: types.CANVAS_APP_LOAD_ASSIGNMENTS_REQUEST, id};
};

export const assignmentLoadSuccess = (data) => {
  return {type: types.CANVAS_APP_LOAD_ASSIGNMENTS_SUCCESS, data};
};

export const assignmentLoadFailure = (error) => {
  return {type: types.CANVAS_APP_LOAD_ASSIGNMENTS_FAILURE, error};
};

export const articleHeadersLoadSuccess = (data) => {
  return {type: types.CANVAS_APP_LOAD_ARTICLE_HEADER_SUCCESS, data};
};

export const studentQuizLoadSuccess = (data) => {
  return {type: types.CANVAS_APP_LOAD_STUDENT_QUIZZ_SUCCESS, data};
};

export const articleWordLoadSuccess = (data) => {
  return {type: types.CANVAS_APP_LOAD_ARTICLE_WORDS_SUCCESS, data};
};
