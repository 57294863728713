import {
  NAVBAR_BREAKPOINTS
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/shared/constants';

export const getContainerPositionCss = (isSubNavMenu) => ({
  position: isSubNavMenu ? 'initial' : 'relative',

  [`@media (max-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
    position: 'inherit',
  },
});

export const absoluteContainerCss = {
  position: 'absolute',
  zIndex: 13,

  [`@media (max-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
    top: '4rem',
    left: 0,
    right: 0,
  },
};

export const subNavMenuPositionCss = {
  [`@media (max-width: ${NAVBAR_BREAKPOINTS.sm}px)`]: {
    top: '0 !important',
    left: 0,
    right: 0,
  },
};
