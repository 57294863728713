import {constants, typographyStyles} from '@newsela/angelou';
import {css} from 'emotion';

const lockIcon = css`
  margin-right: 1.2em;
  width: 10%;
`;

const formControl = css`
  width: 100%;
`;

const focusedTabbing = css`
  .user-is-tabbing &:focus-within {
    outline: 3px solid highlight;
    outline: 3px solid -webkit-focus-ring-color;
  }
`;

const gradeAndLexileLevels = css`
  display: flex;
  width: 300px;
  justify-content: space-between;
  align-items: center;
`;

const body = css`
  max-width: 400px;
`;

const middle = css`
  padding: 1.6em .9em;
`;

const boldCopy = css`
  font-weight: bold;
`;

const externalLinkIcon = css({
  width: '16px',
  height: '16px',
  color: constants.colors.ui.white['500']
});

const linkButtonStyles = css({
  backgroundColor: constants.colors.status.info[500],
  ':hover, :focus': {
    backgroundColor: constants.colors.status.info[600]
  },
  transition: 'all 0.12s',
  boxSizing: 'border-box',
  border: '0.2em solid transparent',
  cursor: 'pointer',
  padding: '0.5rem 1rem',
  textDecoration: 'none'
});

const linkButtonTextStyles = css({
  fontFamily: 'Circular, Helvetica, Arial,sans-serif',
  fontWeight: '700',
  ...typographyStyles.heading6Obj,
  lineHeight: '0.875rem',
  color: 'white',
  marginLeft: '10px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
});

export default {
  linkButtonStyles,
  linkButtonTextStyles,
  externalLinkIcon,
  lockIcon,
  formControl,
  gradeAndLexileLevels,
  body,
  focusedTabbing,
  middle,
  boldCopy,
};
