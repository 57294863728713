import {takeEvery, call, put, select} from 'redux-saga/effects';

import {SearchServiceApi} from 'static/three-oh/src/modules/apis';
import {getUserProperties} from 'static/three-oh/src/modules/selectors/userSelectors';

import * as actions from './../actions/recentNewsActions';
import * as types from './../constants/recentNewsActionTypes';

function * getRecentNews(action) {
  try {
    const userProperties = yield select(getUserProperties);
    const userMaturityBandPreferences = userProperties.getPreferredMaturityBands();
    const queryParams = {
      page_size: 13,
      rule: 'latest',
      format: 'full',
    };
    if (Array.isArray(userMaturityBandPreferences) && userMaturityBandPreferences.length) {
      queryParams.content_maturities = userMaturityBandPreferences;
    }

    if (action.excludeId) {
      queryParams.ids_to_exclude = action.excludeId;
    }
    const response = yield call(SearchServiceApi.get, queryParams);

    yield put(actions.recentNewsRequestSuccess(response.data));
  } catch (error) {
    yield put(actions.recentNewsRequestFailure(error));
  }
}
export function * watchGetRecentNews() {
  yield takeEvery(types.RECENT_NEWS_LOAD_REQUEST, getRecentNews);
}
