import * as types from 'modulesV2/constants';

export default function page(state = {error: null}, action) {
  switch (action.type) {
    case types.CANVAS_APP_LOAD_REVIEW_PAGE:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.CANVAS_APP_LOAD_REVIEW_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case types.CANVAS_APP_LOAD_REVIEW_PAGE_FAILED:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    default:
      return state;
  }
}
