import React from 'react';

import {LoadingSpinner} from '@newsela/angelou';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';

import {unitRoot} from 'static/three-oh/src/modules/constants/routePaths';

const LazyUnitsContainer = Loadable({
  loader: () => import(
    /* webpackChunkName: "UnitsContainer" */
    './containers/UnitsContainer'
  ),
  loading: LoadingSpinner,
});

// There are multiple different routes which ultimately render the UnitsContainer.
// All TextSet types ('unit', 'topic', and 'text_set') get handled by the UnitsContainer.

const UnitsRoute = [
  <Route
    key="units"
    exact
    path={`${unitRoot}/:unitId(\\d+)/(subtopics)?/:subtopicId(\\d+)?/(text-sets)?/:textSetId(\\d+)?`}
    component={LazyUnitsContainer}
  />,
  <Route
    key="subtopics"
    exact
    path={'/subtopics/:subtopicId(\\d+)/(text-sets)?/:textSetId(\\d+)?'}
    component={LazyUnitsContainer}
  />,
];

export default UnitsRoute;
