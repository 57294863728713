import APIFetcher from '../APIFetcher';

class PrincipalApi {
  static createPrincipal(payload) {
    return APIFetcher.post('principal/', {payload});
  }

  static persistPrincipal(payload) {
    const url = `principal/${payload.id + '/' || ''}`;
    return APIFetcher.post(url, {payload});
  }

  static getPrincipal(principalId) {
    const url = `principal/${principalId}`;
    return APIFetcher.get(url);
  }
}

export default PrincipalApi;
