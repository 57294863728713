import {get, filter, map, clone, reject, flatMap, find} from 'lodash-es';
import {createSelector} from 'reselect';

import {getClassrooms} from 'static/three-oh/src/modules/selectors/classroomSelectors';
import {getContentHides} from 'static/three-oh/src/modules/selectors/contentHideSelectors';
import {applyTo, applier} from 'utils/lodashHelpers';

// NOTE: Previously, an assignment was defined as an ArticleHeaderClassroom with
// a `date_assigned` value. This was changed when the concept of assignment was
// promoted to its own model, Assignment.
const getAllArticleHeaderClassrooms = (state) => get(state, 'articleHeaderClassrooms.items', null);

export const getAssignedArticleHeaderClassrooms = createSelector(
  getAllArticleHeaderClassrooms,
  (ahcs) => {
    return filter(ahcs, 'date_assigned');
  }
);

export const getHiddenArticleHeaderClassrooms = createSelector(
  [getAllArticleHeaderClassrooms, getContentHides],
  (ahcs, contentHides) => {
    return applyTo(ahcs)(
      (target) => filter(target, (ahc) => {
        return find(contentHides, ({content_id, classroom_id}) => {
          return content_id == ahc.header?.content_id && classroom_id == ahc.classroom?.id;
        });
      }),
      applier(reject)('date_assigned')
    );
  }
);

export const getArticleHeaderClassroomsWithRelevantEnrichment = createSelector(
  [getAssignedArticleHeaderClassrooms, getClassrooms],
  (ahcs, classrooms) => {
    // This will enrich each assignment's teacher data with the data that
    // currently lives on state for the classroom that matches the assignment.
    // NOTE: This assumes that the state has classrooms loaded!
    return map(ahcs, (ahc) => {
      const enrichedAssignment = clone(ahc);
      // Enrich the Assignment's Teacher information
      enrichedAssignment.teacher = applyTo(classrooms)(
        applier(flatMap)('teachers'),
        applier(find)(['id', enrichedAssignment.assigned_by.id])
      );
      return enrichedAssignment;
    });
  }
);
