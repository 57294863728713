import APIFetcher from '../APIFetcher';

class StudentAssignmentViewRecordApi {
  static get() {
    return APIFetcher.get('student-assignment-view-record/');
  }

  static post(payload) {
    return APIFetcher.post('student-assignment-view-record/', {payload});
  }
}

export default StudentAssignmentViewRecordApi;
