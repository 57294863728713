import {
  constants,
  mediaQueryStyles,
  typographyStyles,
} from '@newsela/angelou';
import {css} from 'emotion';

const {mediaQuery} = mediaQueryStyles;

const dataTable = `
  :after {
    border: none !important;
  }
  table {
    width: 100%;
  }
  h2 {
    font-size: 1rem;
    padding-left: 1rem;
  }

  thead {
    th:first-of-type {
      width: 160px;
      ${mediaQuery.between('767px', constants.breakpoints.BreakpointNames.lg)} {
        width: 200px;
      }
      ${mediaQuery.downTo(constants.breakpoints.BreakpointNames.lg)} {
        width: 250px;
      }
      text-align: left;
      padding-left: 1rem;
    }
    th {
      width: 7.5em;
      padding-top: 0.875rem;
      padding-bottom: 1rem;
    }
  }

  td:first-of-type {
    text-align: left;
    padding-left: 1rem;
  }

  td {
    text-align: center;
    height: 3.75rem;
  }
`;

const contentLink = css`
  width: 200px;
  > div {
    display: flex;
    max-height: 67px;
  }
`;

const advisoryBadge = css`
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 10;
`;

const articleImageContainer = css`
  position: relative;
  height: 60px;
`;

const articleImage = css`
  width: 110px;
  height: auto;
  position: relative;
  align-self: stretch;
  margin-right: 15px;
  object-fit: cover;
`;

const articleHeaderTitle = css(
  `
  max-height: 70px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`,
  typographyStyles.textXSObj
);

const nonArticleContentImageContainer = css`
  position: relative;
`;

const studentName = css(typographyStyles.textSObj);

export default {
  dataTable,
  contentLink,
  advisoryBadge,
  articleImage,
  articleImageContainer,
  articleHeaderTitle,
  studentName,
  nonArticleContentImageContainer,
};
