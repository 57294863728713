import {takeLatest, call, put, all} from 'redux-saga/effects';

import {buildSearchParamsForSearchApiRequest} from 'modulesV2/utils/queryStringUtils';
import {
  TextSetApi,
} from 'static/three-oh/src/modules/apis';
import SearchServiceApi from 'static/three-oh/src/modules/apis/SearchServiceApi';
import * as yourContentPageActions from 'static/three-oh/src/modulesV2/actions/yourContentPageActions';
import {
  INITIALIZE_THE_YOUR_CONTENT_PAGE,
  BOOKMARKED_ARTICLE_HEADERS_FETCH_REQUEST,
  TEXT_SETS_FETCH_REQUEST,
  BOOKMARKED_STANDARDS_FETCH_REQUEST,
} from 'static/three-oh/src/modulesV2/constants';

// Hydrate the 'Your Content' container with all of the data it needs to
// render the user's content.
function * initializeTheYourContentPage() {
  yield all([
    // Fetch bookmarked article-headers.
    put(yourContentPageActions.bookmarkedArticleHeadersFetchRequest()),

    // Fetch text-sets.
    put(yourContentPageActions.textSetsFetchRequest()),

    // Fetch bookmarked standards.
    put(yourContentPageActions.bookmarkedStandardsFetchRequest()),
  ]);
}

function * fetchBookmarkedArticleHeaders() {
  try {
    const content_type = 'header';
    const rule = 'saved-by-me';
    const page_size = 500;
    const queryParams = buildSearchParamsForSearchApiRequest(content_type, rule, page_size);
    const response = yield call(SearchServiceApi.get, queryParams);
    yield put(yourContentPageActions.bookmarkedArticleHeadersFetchSuccess(response.data));
  } catch (error) {
    yield put(yourContentPageActions.bookmarkedArticleHeadersFetchFailure(error));
  }
}

function * fetchTextSets() {
  // We fetch *all* of the user's text-sets here, using three types of 'rules'
  // to fetch text-sets that are (1) bookmarked by the user (2) created by the
  // user and (3) text-sets that are accessible by the user via the user's
  // school.
  const rules = ['saved-by-me', 'mine', 'created-in-school'];
  for (const rule of rules) {
    try {
      const params = {
        include_collections: true,
        types: 'text_set,topic,unit,category,row',
        rule: rule,
        page_size: 1000, // Maximum number of text sets received from the API
      };
      // We fetch text-sets from the Object Api's TextSet endpoint because the
      // Search Api does not accept the following rules: 'mine' &
      // 'created-in-school'.
      const textSets = yield call(TextSetApi.get, params);
      yield put(yourContentPageActions.textSetsFetchSuccess(textSets, rule));
    } catch (error) {
      yield put(yourContentPageActions.textSetsFetchFailure(error, rule));
    }
  }
}

function * fetchBookmarkedStandards() {
  try {
    const content_type = 'standard';
    const rule = 'saved-by-me';
    const page_size = 500;
    const queryParams = buildSearchParamsForSearchApiRequest(content_type, rule, page_size);
    const response = yield call(SearchServiceApi.get, queryParams);
    yield put(yourContentPageActions.bookmarkedStandardsFetchSuccess(response.data));
  } catch (error) {
    yield put(yourContentPageActions.bookmarkedStandardsFetchFailure(error));
  }
}

// --- Watchers ---- //
function * watchInitializeYourContentPage() {
  yield takeLatest(INITIALIZE_THE_YOUR_CONTENT_PAGE, initializeTheYourContentPage);
}

function * watchBookmarkedArticleHeadersFetchRequest() {
  yield takeLatest(BOOKMARKED_ARTICLE_HEADERS_FETCH_REQUEST, fetchBookmarkedArticleHeaders);
}

function * watchBookmarkedStandardsFetchRequest() {
  yield takeLatest(BOOKMARKED_STANDARDS_FETCH_REQUEST, fetchBookmarkedStandards);
}

function * watchTextSetsFetchRequest() {
  yield takeLatest(TEXT_SETS_FETCH_REQUEST, fetchTextSets);
}

export {
  watchInitializeYourContentPage,
  watchBookmarkedArticleHeadersFetchRequest,
  watchTextSetsFetchRequest,
  watchBookmarkedStandardsFetchRequest,
};
