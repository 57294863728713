import React from 'react';

import PropTypes from 'prop-types';

import {toSnakeCase} from 'static/three-oh/utils/stringUtils';

import styles from './styles.scss';

const propTypes = {
  /**
   * The title's copy.
   */
  copy: PropTypes.string.isRequired,

  /**
   * Optional subtitle to be displayed below the copy.
   */
  subtitle: PropTypes.string,
};

const ContentRowTitle = (props) => {
  return (
    /*
    * Header tag needed for a11y
    * All section titles on a page need to
    * be given a semantic header tag
    * with only one h1 used on a page
    * at a time. Which non-h1 header
    * we use when will need to be further
    * defined.
    *
    * Further, we should separate links
    * from headings. Ideally, the below
    * would just title its corresponding
    * sections, with a separate link for
    * viewing more articles of its kind
    */
    <div className={styles.header} aria-label={props.copy}>
      <h2
        className={styles.header_tag}
        data-qa-selector={toSnakeCase(props.copy)}
      >
        {props.copy}
      </h2>
      {
        props.subtitle &&
        <div
          className={styles.subtitle}
        >
          {props.subtitle}
        </div>
      }
    </div>
  );
};

ContentRowTitle.propTypes = propTypes;

export default ContentRowTitle;
