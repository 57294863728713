import React from 'react';

import DOMPurify from 'dompurify';
import {css} from 'emotion';
import {isEmpty} from 'lodash-es';
import PropTypes from 'prop-types';
import RichTextEditor from 'react-rte';

import SafeUrlDecorator from './SafeUrlDecorator';
import {generateEditorCss} from './style';

const propTypes = {
  'data-qa-selector': PropTypes.string,
  handleChange: PropTypes.func,
  initialValue: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  toolbarConfig: PropTypes.objectOf(PropTypes.array),
};

const newlineAndSpacePattern = /\n+\s*/g;

/**
 * TextEditor component allows users to write rich text in fields.
 * It wraps around the react-rte library. This component requires an
 * internal state for its value which is a special value object within RichTextEditor.
 */

class TextEditor extends React.Component {
  constructor(props) {
    super(props);
    const value = isEmpty(props.initialValue) ? (
      RichTextEditor.EditorValue.createEmpty(SafeUrlDecorator)
    ) : RichTextEditor.EditorValue.createFromString(
      DOMPurify.sanitize(props.initialValue.replaceAll('\n', '<br>')), 'html', SafeUrlDecorator
    );

    this.state = {value};
  }

  handleChange = (value) => {
    this.setState({value});

    if (this.props.handleChange) {
      this.props.handleChange(
        // Remove prettified HTML formatting and sanitize.
        DOMPurify.sanitize(value.toString('html').replace(newlineAndSpacePattern, ''))
      );
    }
  }

  /**
   * Before an input gets added to the editorState, we want to make sure
   * that we haven't reached the maximum.
   */
  handleBeforeInput = (chars, editorState) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    if (currentContentLength > this.props.maxLength - 1) {
      return 'handled';
    }
  }

  /**
   * If you paste something in, we also want to make sure that we haven't
   * reached the maximum.
   */
  handlePastedText = (pastedText, html, editorState) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    if (currentContentLength + pastedText.length > this.props.maxLength - 1) {
      return 'handled';
    }
  }

  render() {
    const {placeholder, toolbarConfig, readOnly} = this.props;
    const editorCss = generateEditorCss({readOnly});
    return (
      <RichTextEditor
        toolbarClassName={css({
          'div[class^="InputPopover__checkOption"]': {
            display: 'none'
          }
        })}
        className={css(editorCss)}
        data-qa-selector={this.props['data-qa-selector']}
        handleBeforeInput={this.handleBeforeInput}
        handlePastedText={this.handlePastedText}
        onChange={this.handleChange}
        placeholder={placeholder}
        toolbarConfig={toolbarConfig}
        readOnly={readOnly}
        value={this.state.value}
      />
    );
  }
}

export const toolbarConfig = {
  display: [
    'INLINE_STYLE_BUTTONS',
    'BLOCK_TYPE_BUTTONS',
    'LINK_BUTTONS',
    'BLOCK_TYPE_DROPDOWN',
    'HISTORY_BUTTONS',
  ],
  INLINE_STYLE_BUTTONS: [
    {label: 'Bold', style: 'BOLD'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'},
  ],
  BLOCK_TYPE_DROPDOWN: [
    {label: 'Normal', style: 'unstyled'},
    {label: 'H1', style: 'header-one'},
    {label: 'H2', style: 'header-two'},
    {label: 'H3', style: 'header-three'},
    {label: 'H4', style: 'header-four'},
    {label: 'H5', style: 'header-five'},
    {label: 'H6', style: 'header-six'},
  ],
  BLOCK_TYPE_BUTTONS: [
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'},
    {label: 'Blockquote', style: 'blockquote'},
  ],
};

TextEditor.defaultProps = {
  initialValue: '',
  maxLength: 500,
  toolbarConfig
};

TextEditor.propTypes = propTypes;
export default TextEditor;
