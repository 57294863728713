
import {constants, typographyStyles} from '@newsela/angelou';

const color = constants.colors.ui.core[600];

const textStyle = {
  color: color,
  ...typographyStyles.textSObj,
  fontWeight: 'bold',
  '&:hover': {
    textDecoration: 'underline',
  }
};

export default {
  textStyle,
};
