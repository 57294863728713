
import {constants} from '@newsela/angelou';
import {css} from 'emotion';

const coreBlue = constants.colors.ui.core[600];
const brandTertiary = constants.colors.brand.bisque[500];
const white = constants.colors.ui.white[100];

export const veilContainer = css`
  background-color: ${coreBlue};
  color: ${white};
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1.4em;  
`;

export const studentsTabVeil = css`
  margin-top: 1em;
`;

export const firstSentence = css`
  font-weight: bold;
  margin-top: 1em;
`;

export const learnMoreText = css`
  color: ${brandTertiary};
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
`;

export const copySection = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1em;
`;

export const mockDataTable = css`
  width: 97%;
  border-radius: 1em;
  margin-bottom: 1em;
`;
